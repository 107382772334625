<template>
  <v-menu
    max-width="200"
    open-on-click
    :attach="$el"
    offset-y
    bottom
    left
    tile
    z-index="4"
    content-class="tray-products__menu"
  >
    <template #activator="{ on, attrs }">
      <div class="tray-products__menu__activator" v-bind="attrs" v-on="on">
        <icons-settings-kebab />
      </div>
    </template>
    <v-list dense>
      <v-list-item dense @click="$emit('onProductSettings')">
        <v-list-item-title>
          <icons-settings-line :width="20" :height="20" />

          Product settings
        </v-list-item-title>
      </v-list-item>

      <v-list-item dense @click="$emit('onOrdersHistory')">
        <v-list-item-title>
          <icons-history :width="20" :height="20" />

          Transactions history
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="superAdmin || masterAccount" dense @click="$emit('onAssignUsers')">
        <v-list-item-title>
          <icons-sidebar-sub-account :width="18" :height="19" color="#95979D" />

          Access to users
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

export default {
  name: 'TrayProductOptions',
  mixins: [
    superAdmin,
    masterAccount,
  ],
}
</script>

<style lang="scss">
.tray-products__menu {
  padding: 0px !important;

  .v-list {
    padding: 17px 0px;
  }

  .v-list-item {
    padding: 0px 17px !important;
    color: #16181f !important;
    cursor: pointer;

    &--dense {
      min-height: 30px;
    }
    &__icon {
      margin: 3px 16px 3px 0px !important;
    }

    &__title {
      font-family: 'Lato-Bold', sans-serif !important;
      font-size: 14px !important;
      letter-spacing: 0 !important;
      line-height: 30px !important;
      display: flex;
      align-items: center;
      gap: 8px;

      &--regular {
        font-family: 'Lato-Regular', sans-serif !important;
      }
    }
  }

  &__activator {
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    cursor: pointer;
    transition: background-color ease-in-out 0.2s;

    &:hover {
      background-color: #F4F4F4;
    }
  }
}
</style>
