<template>
  <div
    class="recent-notes dashboard-card dashboard-card--small"
    :class="{
      'recent-notes--has-note': items.length,
      'order-2': !$vuetify.breakpoint.lgAndUp,
      'order-3': $vuetify.breakpoint.lgAndUp,
    }"
    :style="items.length && `background-color: ${currentNoteColor}`"
  >
    <div class="recent-notes__title">
      Recent thank-you notes

      <div class="recent-notes__arrows" v-if="items.length > 1">
        <div
          class="arrow"
          :class="{ 'arrow--disabled': currentNoteIndex === 0 }"
          @click="currentNoteIndex !== 0 ? currentNoteIndex -= 1 : null"
        >
          <icons-arrow-left />
        </div>
        <div class="number-of-items">
          {{ currentNoteIndex + 1 }}
          <span>
            /{{ items.length }}
          </span>
        </div>
        <div
          class="arrow"
          :class="{ 'arrow--disabled': currentNoteIndex === (items.length - 1) }"
          @click="currentNoteIndex !== (items.length - 1) ? currentNoteIndex += 1 : null"
        >
          <icons-arrow-left style="transform: rotate(-180deg);" />
        </div>
      </div>
    </div>

    <v-divider />

    <template v-if="!loading">
      <div class="recent-notes__notes" v-if="items.length">
        {{ currentNoteMessage }}

        <div class="recent-notes__notes--from">
          From: {{ currentNoteFrom }}
        </div>
      </div>

      <div class="recent-notes__empty" v-else>
        <icons-empty-thank-you-notes />

        No thank-you notes arrived in past 30 days
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'

export default {
  name: 'TheRecentThankYouNotes',
  data: () => ({
    loading: false,
    currentNoteIndex: null,
    items: []
  }),
  computed: {
    currentNoteData () {
      const { items, currentNoteIndex } = this
      return items?.[currentNoteIndex] ?? null
    },
    currentNoteMessage () {
      return this.currentNoteData?.message ?? null
    },
    currentNoteFrom () {
      const { currentNoteData: { sender, date } } = this
      let result = ''

      if (sender) result += sender
      if (date) result += `, ${new Intl.DateTimeFormat('en', { dateStyle: 'medium' }).format(new Date(date))}`

      return result
    },
    currentNoteColor () {
      return this.currentNoteData?.color ?? '#007E76'
    },
  },
  created () {
    this.loading = true
    Api.get('/customer/dashboard/thank-you-notes?paginate=false')
      .then(({ data }) => {
        this.items = data;
        this.currentNoteIndex = 0;
      })
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.recent-notes {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &--has-note {
    & > .recent-notes__title {
      color: #fff;
    }

    & > hr {
      border-color: #fff;
      opacity: 0.2;
    }
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #95979D;
    text-transform: uppercase;
  }

  &__arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 8px;

    & > .number-of-items {
      font-family: 'Lato-Bold', sans-serif;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 3.5px;
      color: #fff;

      & > span {
        opacity: 0.3;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
      }
    }

    & > .arrow {
      cursor: pointer;
      color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;

      &--disabled {
        opacity: 0.3;
        cursor: default;
      }
    }
  }

  &__notes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    color: #fff;
    height: 100%;
    font-size: 20px;
    line-height: 27px;
    font-family: 'Lato-Italic', sans-serif;
    letter-spacing: 0.32px;

    &--from {
      font-size: 16px;
      line-height: 19px;
      color: inherit;
      opacity: 0.7;
      font-family: inherit;
      letter-spacing: inherit;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 28px;
    align-items: center;
    justify-content: center;

    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }
}
</style>
