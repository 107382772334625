<template>
  <div
    class="common-badge"
    :style="`
      --color: ${color};
      --width: ${width}px;
      --height: ${height}px;
    `"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    color: {
      type: String,
      required: false,
      default: '#219358'
    },
    width: {
      type: [Number, String],
      required: false,
      default: 22
    },
    height: {
      type: [Number, String],
      required: false,
      default: 22
    }
  }
}
</script>

<style lang="scss" scoped>
.common-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato-Bold', sans-serif;
  color: #fff;
  line-height: 18px;
  padding: 0 4px;
  font-size: 14px;
  background: var(--color);
  border-radius: 24px;
  min-width: var(--width);
  min-height: var(--height);
}
</style>
