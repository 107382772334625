<template>
  <div class="link-type-more-info__tooltip">
    <div class="link-type-more-info__content-item">
      <div class="link-type-more-info__label">
        Recommended for:
      </div>
      <span class="link-type-more-info__text">
        {{ recommendedForText }}

        <span @click="showInfoPanel = true">Read more</span>
      </span>
    </div>

    <v-navigation-drawer
      v-model="showInfoPanel"
      app
      temporary
      right
      width="950px"
      class="right-side-panel"
    >
      <panel-header :show.sync="showInfoPanel">{{ panelTitle }}</panel-header>

      <div class="link-type-more-info__container">
        <div class="link-type-more-info__content-item">
          <div class="link-type-more-info__label">
            Recommended for:
          </div>

          <span class="link-type-more-info__text">
            {{ recommendedForText }}
          </span>
        </div>
        <div class="link-type-more-info__content-item">
          <div class="link-type-more-info__label">
            How it works:
          </div>

          <span class="link-type-more-info__text" v-html="howItWorksText" />
        </div>
        <div
          v-if="whyEmailAddressText"
          class="link-type-more-info__content-item"
        >
          <div class="link-type-more-info__label">
            Why add Email addresses?
          </div>

          <span class="link-type-more-info__text">{{ whyEmailAddressText }}</span>
        </div>
        <div class="link-type-more-info__content-item">
          <div class="link-type-more-info__label">
            Pros:
          </div>

          <ul class="link-type-more-info__list">
            <li v-for="pros in linkTypePros" :key="pros">{{ pros }}</li>
          </ul>
        </div>
        <div class="link-type-more-info__content-item">
          <div class="link-type-more-info__label">
            Cons:
          </div>

          <ul class="link-type-more-info__list">
            <li v-for="cons in linkTypeCons" :key="cons">{{ cons }}</li>
          </ul>
        </div>

        <iframe
          id="your-own-collection-video"
          src="https://www.youtube.com/embed/Hbuts73habE?si=jUvLn-BaSGpHZxHo&controls=0"
          title="Custom eGift Collections intro"
          frameborder="0"
          autoplay="1"
          allow="autoplay; fullscreen;"
          allowfullscreen
          scrolling="no"
        />

        <cg-button
          class="link-type-more-info__button"
          outlined
          @click="showInfoPanel = false"
        >
          Ok, back to send campaign
        </cg-button>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import PanelHeader from '@/components/PanelHeader.vue'
import { CgButton } from '@corporategift/design-system'

export default {
  name: 'LinkTypeMoreInfo',
  components: {
    PanelHeader,
    CgButton,
  },
  props: {
    linkType: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    showInfoPanel: false,
  }),
  computed: {
    recommendedForText () {
      const { linkType } = this

      if (linkType === 'standard_link') {
        return 'When you have fewer than 10 recipients and want to offer a personal greeting.'
      }
      if (linkType === 'single_link_restricted') {
        return 'When you have more than 10 recipients, want a personal greeting, and prefer sharing just one link with all.'
      }
      if (linkType === 'single_link_unrestricted') {
        return 'When you don’t know your recipients and want to share one link or QR code but with limited redemptions.'
      }

      return ''
    },
    howItWorksText () {
      const { linkType } = this

      if (linkType === 'standard_link') {
        return "Each gift-link is tied to the recipient's name and optionally their email.<br /> After creating the gift links, you'll receive a list with each recipient's name and their personal gift-link."
      }
      if (linkType === 'single_link_restricted') {
        return "Create and share one link with all recipients. The email address entered by the recipient must match the one you provided.<br /> This option allows you to easily share a single link, with a personal greeting for each recipient. To redeem, recipients must use the email address you've specified."
      }
      if (linkType === 'single_link_unrestricted') {
        return "Share the same single link with all your anonymous recipients, or use a QR code. Just set the total number of redemptions allowed.<br /> Enjoy the ease of sending/sharing a single link, but anyone with the link can redeem (limited by the number of redemptions you set). The gift recipient will need to enter an email address, and each email address is limited to one redemption."
      }

      return ''
    },
    whyEmailAddressText () {
      const { linkType } = this

      if (linkType === 'standard_link') {
        return "Emails are optional, but adding them allows you to send bulk reminders to all recipients who haven’t redeemed their gift."
      }
      if (linkType === 'single_link_restricted') {
        return null
      }
      if (linkType === 'single_link_unrestricted') {
        return null
      }

      return null
    },
    linkTypePros () {
      const { linkType } = this

      if (linkType === 'standard_link') {
        return [
          'Personal - each recipient will be welcomed with a personal greeting.',
          "You don't need to have recipient's email address.",
          'Recipients can redeem without entering their email.'
        ]
      }
      if (linkType === 'single_link_restricted') {
        return [
          'Personalized experience with a greeting for each recipient. e.g., "Dear [name]."',
          "Send/share one link with all recipients.",
        ]
      }
      if (linkType === 'single_link_unrestricted') {
        return [
          'No need for recipient email addresses.',
          "Send/share one link with all recipients.",
          'Use a QR code for easy sharing.',
        ]
      }

      return []
    },
    linkTypeCons () {
      const { linkType } = this

      if (linkType === 'standard_link') {
        return [
          'You must match each link to the correct recipient when sending/sharing, which can be time-consuming for a large number of recipients.',
        ]
      }
      if (linkType === 'single_link_restricted') {
        return [
          'You need recipient email addresses.',
          'Recipients must use the specific email you’ve provided to redeem the gift.',
        ]
      }
      if (linkType === 'single_link_unrestricted') {
        return [
          'Not personal - recipients will not be welcomed with a personal greeting.',
          'Can be redeemed by anyone with the link (limited to one redeem per email).',
        ]
      }

      return []
    },
    panelTitle () {
      const { linkType } = this

      if (linkType === 'standard_link') {
        return 'Create a personal link for each recipient'
      }
      if (linkType === 'single_link_restricted') {
        return 'Create a single link with verified email'
      }
      if (linkType === 'single_link_unrestricted') {
        return 'Create a generic link (anyone can redeem)'
      }

      return 'Create a personal link for each recipient'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/common/right-side-panel.scss';

.link-type-more-info {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__tooltip {
    padding: 14px;
    background-color: #F6F5FF;
    width: 374px;
    margin-left: 20px;
    position: relative;

    &::before {
      content: '';
      background-color: inherit;
      width: 20px;
      height: 20px;
      position: absolute;
      transform: rotate(45deg) translate(0, -50%);
      z-index: -1;
      left: -16px;
      top: 50px;
    }
  }

  &__expanded {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__content-item {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-family: 'Lato-Bold', 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #222325;
  }

  &__text {
    font-family: 'Lato-Regular', 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #222325;

    & > span {
      font-family: 'Lato-Regular', 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      cursor: pointer;
      color: #219358;
    }
  }

  &__list > li {
    font-family: 'Lato-Regular', 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #222325;

    &::marker {
      font-size: 24px;
    }
  }

  &__button {
    border-radius: 100px;
    width: fit-content;
  }
}

#your-own-collection-video {
  aspect-ratio: 16 / 9;
  border-radius: 16px;
  max-width: 600px;
}
</style>