<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 23"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.03 0.718657L15.8846 2.9791C15.9778 3.01212 16.0381 3.10327 16.0325
        3.20266V9.88462L20.7146 12.0705C20.8078 12.1036 20.8681 12.1947 20.8625
        12.2941V19.1499C20.8681 19.2493 20.8078 19.3405 20.7146 19.3735L15.86
        21.6339H15.6382L10.9314 19.448L6.22464 21.6339H6.00286L1.14822 19.3735C1.05505 19.3405
        0.994766 19.2493 1.00036 19.1499V12.2941C0.994766 12.1947 1.05505 12.1036 1.14822
        12.0705L5.83036 9.88462V3.20266C5.82477 3.10327 5.88505 3.01212 5.97822 2.9791L10.8329
        0.718657C10.8962 0.693716 10.9666 0.693716 11.03 0.718657ZM10.3154 12.3189L6.05214
        10.3317L1.83822 12.2941L6.10143 14.2565L10.3154 12.3189ZM10.685 5.61214V11.9463L6.32322
        9.90946V3.57526L10.685 5.61214ZM15.5396 3.57526L11.1779 5.61214V11.9463L15.5396
        9.90946V3.57526ZM6.34786 14.7036L10.685 12.6915V19.0009L6.34786 21.0129V14.7036ZM15.515
        14.7036L11.1779 12.6915V19.0009L15.515 21.0129V14.7036ZM15.7614 14.2565L11.5475
        12.3189L15.8107 10.3317L20.0246 12.2941L15.7614 14.2565ZM15.1946 3.20266L10.9314
        1.21546L6.66822 3.20266L10.9314 5.18986L15.1946 3.20266ZM1.49322 12.6667L5.855
        14.7036V21.0129L1.49322 19.0009V12.6667ZM16.0079 14.7036V21.0129L20.3696
        19.0009V12.6667L16.0079 14.7036Z"
      fill="currentColor"
    />
    <path
      d="M15.8846 2.9791L15.7791 3.20573L15.7899 3.21076L15.8011 3.21473L15.8846 2.9791ZM11.03
        0.718657L11.1355 0.492021L11.1286 0.488807L11.1215 0.486019L11.03 0.718657ZM16.0325
        3.20266L15.7825 3.18859V3.20266H16.0325ZM16.0325 9.88462H15.7825V10.0438L15.9267
        10.1111L16.0325 9.88462ZM20.7146 12.0705L20.6089 12.2971L20.6198 12.3022L20.6311
        12.3062L20.7146 12.0705ZM20.8625 12.2941L20.6125 12.28V12.2941H20.8625ZM20.8625
        19.1499H20.6121L20.6129 19.164L20.8625 19.1499ZM20.7146 19.3735L20.6311 19.1379L20.6199
        19.1418L20.6091 19.1469L20.7146 19.3735ZM15.86 21.6339V21.8839H15.9154L15.9655
        21.8606L15.86 21.6339ZM15.6382 21.6339L15.5329 21.8607L15.583
        21.8839H15.6382V21.6339ZM10.9314 19.448L11.0367 19.2213L10.9314 19.1724L10.8261
        19.2213L10.9314 19.448ZM6.22464 21.6339V21.8839H6.27986L6.32995 21.8607L6.22464
        21.6339ZM6.00286 21.6339L5.89733 21.8606L5.94751 21.8839H6.00286V21.6339ZM1.14822
        19.3735L1.25374 19.1469L1.24295 19.1418L1.23174 19.1379L1.14822 19.3735ZM1.00036
        19.1499L1.25036 19.164V19.1499H1.00036ZM1.00036 12.2941H1.25075L1.24996 12.2801L1.00036
        12.2941ZM1.14822 12.0705L1.23174 12.3062L1.24308 12.3022L1.25397 12.2971L1.14822
        12.0705ZM5.83036 9.88462L5.93612 10.1111L6.08036 10.0438V9.88462H5.83036ZM5.83036
        3.20266H6.08075L6.07996 3.18861L5.83036 3.20266ZM5.97822 2.9791L6.06174 3.21473L6.07295
        3.21076L6.08374 3.20573L5.97822 2.9791ZM10.8329 0.718657L10.7413 0.486018L10.7342
        0.488806L10.7273 0.492021L10.8329 0.718657ZM6.05214 10.3317L6.15777 10.1051L6.05219
        10.0559L5.94661 10.1051L6.05214 10.3317ZM10.3154 12.3189L10.4198 12.5461L10.9104
        12.3205L10.421 12.0923L10.3154 12.3189ZM1.83822 12.2941L1.73268 12.0675L1.24318
        12.2954L1.73368 12.5212L1.83822 12.2941ZM6.10143 14.2565L5.9969 14.4836L6.10137
        14.5316L6.20587 14.4836L6.10143 14.2565ZM10.685 11.9463L10.5792 12.1729L10.935
        12.339V11.9463H10.685ZM10.685 5.61214H10.935V5.45297L10.7908 5.38562L10.685
        5.61214ZM6.32322 9.90946H6.07322V10.0686L6.21744 10.136L6.32322 9.90946ZM6.32322
        3.57526L6.429 3.34874L6.07322 3.1826V3.57526H6.32322ZM11.1779 5.61214L11.0721
        5.38562L10.9279 5.45297V5.61214H11.1779ZM15.5396 3.57526H15.7896V3.1826L15.4339
        3.34874L15.5396 3.57526ZM11.1779 11.9463H10.9279V12.339L11.2836 12.1729L11.1779
        11.9463ZM15.5396 9.90946L15.6454 10.136L15.7896 10.0686V9.90946H15.5396ZM10.685
        12.6915H10.935V12.3L10.5798 12.4648L10.685 12.6915ZM6.34786 14.7036L6.24265 14.4768L6.09786
        14.544V14.7036H6.34786ZM10.685 19.0009L10.7902 19.2277L10.935
        19.1605V19.0009H10.685ZM6.34786 21.0129H6.09786V21.4045L6.45307 21.2397L6.34786
        21.0129ZM11.1779 12.6915L11.2831 12.4648L10.9279 12.3V12.6915H11.1779ZM15.515
        14.7036H15.765V14.544L15.6202 14.4768L15.515 14.7036ZM11.1779
        19.0009H10.9279V19.1605L11.0727 19.2277L11.1779 19.0009ZM15.515 21.0129L15.4098
        21.2397L15.765 21.4045V21.0129H15.515ZM11.5475 12.3189L11.4419 12.0923L10.9524
        12.3205L11.4431 12.5461L11.5475 12.3189ZM15.7614 14.2565L15.657 14.4836L15.7615
        14.5316L15.866 14.4836L15.7614 14.2565ZM15.8107 10.3317L15.9163 10.1051L15.8107
        10.0559L15.7051 10.1051L15.8107 10.3317ZM20.0246 12.2941L20.1292 12.5212L20.6197
        12.2954L20.1302 12.0675L20.0246 12.2941ZM10.9314 1.21546L11.0371 0.988864L10.9314
        0.939632L10.8258 0.988864L10.9314 1.21546ZM15.1946 3.20266L15.3003 3.42925L15.7864
        3.20266L15.3003 2.97606L15.1946 3.20266ZM6.66822 3.20266L6.56259 2.97606L6.07648
        3.20266L6.56259 3.42925L6.66822 3.20266ZM10.9314 5.18986L10.8258 5.41645L10.9314
        5.46568L11.0371 5.41645L10.9314 5.18986ZM5.855 14.7036H6.105V14.5444L5.96078 14.4771L5.855
        14.7036ZM1.49322 12.6667L1.599 12.4402L1.24322 12.274V12.6667H1.49322ZM5.855 21.0129L5.75028
        21.2399L6.105 21.4036V21.0129H5.855ZM1.49322 19.0009H1.24322V19.1609L1.3885 19.2279L1.49322
        19.0009ZM16.0079 21.0129H15.7579V21.4036L16.1126 21.2399L16.0079 21.0129ZM16.0079
        14.7036L15.9021 14.4771L15.7579 14.5444V14.7036H16.0079ZM20.3696 19.0009L20.4744
        19.2279L20.6196 19.1609V19.0009H20.3696ZM20.3696 12.6667H20.6196V12.274L20.2639
        12.4402L20.3696 12.6667ZM15.9902 2.75246L11.1355 0.492021L10.9245 0.945293L15.7791
        3.20573L15.9902 2.75246ZM16.2821 3.2167C16.2939 3.00754 16.167 2.81396 15.9682
        2.74346L15.8011 3.21473C15.7886 3.21029 15.7823 3.19899 15.7829 3.18861L16.2821
        3.2167ZM16.2825 9.88462V3.20266H15.7825V9.88462H16.2825ZM20.8204 11.844L16.1383
        9.65809L15.9267 10.1111L20.6089 12.2971L20.8204 11.844ZM21.1121 12.3081C21.1239 12.099
        20.997 11.9054 20.7982 11.8349L20.6311 12.3062C20.6186 12.3017 20.6123 12.2904 20.6129
        12.2801L21.1121 12.3081ZM21.1125 19.1499V12.2941H20.6125V19.1499H21.1125ZM20.7982
        19.6091C20.997 19.5386 21.1239 19.3451 21.1121 19.1359L20.6129 19.164C20.6123 19.1536
        20.6186 19.1423 20.6311 19.1379L20.7982 19.6091ZM15.9655 21.8606L20.8202 19.6001L20.6091
        19.1469L15.7545 21.4073L15.9655 21.8606ZM15.6382
        21.8839H15.86V21.3839H15.6382V21.8839ZM10.8261 19.6748L15.5329 21.8607L15.7435
        21.4072L11.0367 19.2213L10.8261 19.6748ZM6.32995 21.8607L11.0367 19.6748L10.8261
        19.2213L6.11934 21.4072L6.32995 21.8607ZM6.00286
        21.8839H6.22464V21.3839H6.00286V21.8839ZM1.04269 19.6001L5.89733 21.8606L6.10839
        21.4073L1.25374 19.1469L1.04269 19.6001ZM0.750753 19.1359C0.738984 19.3451 0.865818 19.5386
        1.06469 19.6091L1.23174 19.1379C1.24428 19.1423 1.25055 19.1536 1.24996 19.164L0.750753
        19.1359ZM0.750359 12.2941V19.1499H1.25036V12.2941H0.750359ZM1.06469 11.8349C0.865817
        11.9054 0.738984 12.099 0.750753 12.3081L1.24996 12.2801C1.25055 12.2904 1.24428 12.3017
        1.23174 12.3062L1.06469 11.8349ZM5.7246 9.65809L1.04246 11.844L1.25397 12.2971L5.93612
        10.1111L5.7246 9.65809ZM5.58036 3.20266V9.88462H6.08036V3.20266H5.58036ZM5.89469
        2.74346C5.69582 2.81395 5.56898 3.00753 5.58075 3.2167L6.07996 3.18861C6.08055 3.199
        6.07428 3.21029 6.06174 3.21473L5.89469 2.74346ZM10.7273 0.492021L5.87269 2.75246L6.08374
        3.20573L10.9384 0.945293L10.7273 0.492021ZM11.1215 0.486019C10.9993 0.437928 10.8635
        0.437929 10.7413 0.486018L10.9244 0.951296C10.929 0.949503 10.9339 0.949503 10.9385
        0.951295L11.1215 0.486019ZM5.94652 10.5583L10.2097 12.5455L10.421 12.0923L6.15777
        10.1051L5.94652 10.5583ZM1.94375 12.5207L6.15768 10.5584L5.94661 10.1051L1.73268
        12.0675L1.94375 12.5207ZM6.20596 14.0294L1.94275 12.067L1.73368 12.5212L5.9969
        14.4836L6.20596 14.0294ZM10.2109 12.0918L5.99699 14.0293L6.20587 14.4836L10.4198
        12.5461L10.2109 12.0918ZM10.935 11.9463V5.61214H10.435V11.9463H10.935ZM6.21744
        10.136L10.5792 12.1729L10.7908 11.7198L6.429 9.68294L6.21744 10.136ZM6.07322
        3.57526V9.90946H6.57322V3.57526H6.07322ZM10.7908 5.38562L6.429 3.34874L6.21744
        3.80178L10.5792 5.83866L10.7908 5.38562ZM11.2836 5.83866L15.6454 3.80178L15.4339
        3.34874L11.0721 5.38562L11.2836 5.83866ZM11.4279
        11.9463V5.61214H10.9279V11.9463H11.4279ZM15.4339 9.68294L11.0721 11.7198L11.2836
        12.1729L15.6454 10.136L15.4339 9.68294ZM15.2896
        3.57526V9.90946H15.7896V3.57526H15.2896ZM10.5798 12.4648L6.24265 14.4768L6.45307
        14.9304L10.7902 12.9183L10.5798 12.4648ZM10.935
        19.0009V12.6915H10.435V19.0009H10.935ZM6.45307 21.2397L10.7902 19.2277L10.5798
        18.7741L6.24265 20.7862L6.45307 21.2397ZM6.09786
        14.7036V21.0129H6.59786V14.7036H6.09786ZM11.0727 12.9183L15.4098 14.9304L15.6202
        14.4768L11.2831 12.4648L11.0727 12.9183ZM11.4279
        19.0009V12.6915H10.9279V19.0009H11.4279ZM15.6202 20.7862L11.2831 18.7741L11.0727
        19.2277L15.4098 21.2397L15.6202 20.7862ZM15.265
        14.7036V21.0129H15.765V14.7036H15.265ZM11.4431 12.5461L15.657 14.4836L15.8659
        14.0293L11.6519 12.0918L11.4431 12.5461ZM15.7051 10.1051L11.4419 12.0923L11.6531
        12.5455L15.9163 10.5583L15.7051 10.1051ZM20.1302 12.0675L15.9163 10.1051L15.7052
        10.5584L19.9191 12.5207L20.1302 12.0675ZM15.866 14.4836L20.1292 12.5212L19.9201
        12.067L15.6569 14.0294L15.866 14.4836ZM10.8258 1.44205L15.089 3.42925L15.3003
        2.97606L11.0371 0.988864L10.8258 1.44205ZM6.77384 3.42925L11.0371 1.44205L10.8258
        0.988864L6.56259 2.97606L6.77384 3.42925ZM11.0371 4.96326L6.77384 2.97606L6.56259
        3.42925L10.8258 5.41645L11.0371 4.96326ZM15.089 2.97606L10.8258 4.96326L11.0371
        5.41645L15.3003 3.42925L15.089 2.97606ZM5.96078 14.4771L1.599 12.4402L1.38744
        12.8932L5.74922 14.9301L5.96078 14.4771ZM6.105
        21.0129V14.7036H5.605V21.0129H6.105ZM1.3885 19.2279L5.75028 21.2399L5.95972
        20.7859L1.59793 18.7739L1.3885 19.2279ZM1.24322
        12.6667V19.0009H1.74322V12.6667H1.24322ZM16.2579
        21.0129V14.7036H15.7579V21.0129H16.2579ZM20.2649 18.7739L15.9031 20.7859L16.1126
        21.2399L20.4744 19.2279L20.2649 18.7739ZM20.1196
        12.6667V19.0009H20.6196V12.6667H20.1196ZM16.1136 14.9301L20.4754 12.8932L20.2639
        12.4402L15.9021 14.4771L16.1136 14.9301Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Inventory extends Vue {
  @Prop({ default: 22 }) readonly width!: number | string;

  @Prop({ default: 23 }) readonly height!: number | string;
}
</script>
