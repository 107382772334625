<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="600px"
    class="add-ach-panel"
  >
    <panel-header :show.sync="show">
      Add new ACH
    </panel-header>

    <div v-if="showForm" class="add-ach-panel__content-wrapper">
      <add-bluesnap-ach-form
        v-if="userIsUsingBlueSnap"
        v-bind="{ ...$props }"
        @onAddAch="onSuccess"
      />

      <add-ach-from
        v-else
        v-bind="{ ...$props }"
        @onAddAch="onSuccess"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'
import panelVModel from '@/mixins/panelVModel'

import PanelHeader from '@/components/PanelHeader.vue'
import AddAchFrom from './AddAchForm.vue'
import AddBluesnapAchForm from './AddBluesnapAchForm.vue'

export default {
  name: 'AddAchPanel',
  mixins: [
    panelVModel,
    userIsUsingBlueSnap,
  ],
  components: {
    AddBluesnapAchForm,
    PanelHeader,
    AddAchFrom,
  },
  data: () => ({
    showForm: false,
  }),
  watch: {
    show: function (val) {
      if (val) {
        this.showForm = true
      } else {
        setTimeout(() => { this.showForm = false }, 200)
      }
    }
  },
  methods: {
    onSuccess () {
      this.show = false;
      this.$emit('onAddAch');
    },
  },
}
</script>

<style lang="scss" scoped>
.add-ach-panel {
  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 50px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
</style>
