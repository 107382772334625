export default {
  computed: {
    userIsIndividualCgElite () {
      const { isElite, companyId } = this.$store.state.header?.headerData ?? {
        isElite: false,
        companyId: 1,
      }

      return (isElite && companyId === 1) ?? false
    }
  },
}
