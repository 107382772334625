<template>
  <div class="upload-csv">
    <div
      class="upload-csv__drag-drop-area"
      v-if="selectedFile === null"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        name="upload-csv"
        id="upload-csv"
        @change="onChange"
        ref="file"
        accept=".csv, .tsv"
      >

      <label for="upload-csv">
        <icons-upload
          :width="36"
          :height="36"
          color="currentColor"
        />
        Upload or drag CSV
      </label>
    </div>

    <div
      class="upload-csv__drag-drop-area upload-csv__drag-drop-area--selected"
      v-else
    >
      <div class="d-flex">
        <icons-check-circle
          :width="25"
          :height="25"
          filled
          :check-stroke-width="2"
          class="mr-4"
        />
        {{ selectedFile.name }}
      </div>
      <div class="d-flex pointer" @click="remove()">
        <icons-close :width="14" :height="14" color="#000" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheUploadCsv',
  props: {
    value: {
      type: File,
      required: false,
      default: null
    }
  },
  computed: {
    selectedFile: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onChange () {
      this.selectedFile = this.$refs.file.files[0]
    },
    remove () {
      this.selectedFile = null
    },
    dragover (event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('upload-csv__drag-drop-area--file-hover')) {
        event.currentTarget.classList.add('upload-csv__drag-drop-area--file-hover')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('upload-csv__drag-drop-area--file-hover')
    },
    drop (event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('upload-csv__drag-drop-area--file-hover')
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-csv {
  display: flex;
  text-align: center;
  width: 100%;

  &__drag-drop-area {
    display: grid;
    place-items: center;
    background-color: #fff;
    border: 1px dashed #95979D;
    min-width: 440px;
    min-height: 80px;
    cursor: pointer;

    &:hover {
      background-color: #F4FFFE;
    }

    &--file-hover {
      background-color: #F4FFFE;
    }

    &--selected {
      display: flex;
      padding: 24px;
      border: none;
      background-color: #D8F1E4;
      justify-content: space-between;
      cursor: default;

      &:hover {
        background-color: #D8F1E4;
      }

      div {
        display: inline-flex;
      }
    }

    input {
      width: 1px;
      height: 1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
    }

    label {
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
      padding: 22px;
      font-family: 'Lato-Italic', sans-serif !important;
      font-size: 15px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      cursor: inherit;
      color: #95979D;
    }
  }
}
</style>
