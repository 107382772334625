<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="850px"
    class="gift-options-panel"
  >
    <close-panel-button
      @close="() => show = false"
      text="Close"
    />

    <v-container class="px-9 py-0" v-if="gift && show">
      <v-row>
        <v-col cols="12" class="gift-options-panel__title">
          <span class="gift-options-panel__title--bold">
            {{ gift.name }}
          </span>
          /
          <span>
            Gift options ({{ availableItemsInGift.length }})
          </span>
        </v-col>

        <v-col cols="12">
          <v-row no-gutters justify="space-between">
            <!-- gift type -->
            <v-col
              cols="8"
              class="gift-options-panel__subtitle d-flex align-center"
              v-if="gift.egift_settings.egift_type === 'single'"
            >
              <v-img
                max-width="18"
                height="18"
                class="mr-2"
                src="/images/Single-item-icon.svg"
              />
              <span>
                Single Choice eGift - Recipient may pick 1 item
              </span>
            </v-col>
            <v-col
              cols="8"
              class="gift-options-panel__subtitle d-flex align-center"
              v-else-if="gift.egift_settings.egift_type === 'multiple'"
            >
              <v-img
                max-width="20"
                height="20"
                class="mr-2"
                src="/images/Multiple-items-icon.svg"
              />
              <span>
                Multiple Choice eGift - Recipient may pick {{ gift.egift_settings.max_allowed_selection }} items
              </span>
            </v-col>
            <v-col
              cols="8"
              class="gift-options-panel__subtitle d-flex align-center"
              v-else
            >
              <v-img
                max-width="23"
                height="20"
                class="mr-2"
                src="/images/GiftCoins-items-icon.svg"
              />
              <span>
                GiftCoin - Recipient may pick up items up to ${{ gift.egift_settings.coin_value }} total value
              </span>
            </v-col>
            <!-- sort by -->
            <v-col
              cols="4"
              class="d-flex justify-end"
              v-if="giftItems.length > 1"
            >
              <common-select
                v-model="sortBy"
                :items="setSortByOptions"
                placeholder="Sort by"
                height="30"
                class="options-sort-by"
                style="max-width: 220px"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <template v-for="(product, index) in giftItems">
              <v-col cols="4" :key="index">
                <product-card
                  :item="product"
                  :isPrepaid="campaignIsPrepaidFromInventory"
                />
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import ProductCard from '../TheGiftOptionsPanelProduct.vue'

import panelVModel from '@/mixins/panelVModel'
import gift from './mixins/gift'

export default {
  name: 'GiftOptionsPanel',
  components: {
    ProductCard
  },
  mixins: [
    panelVModel,
    gift
  ],
  data: () => ({
    sortBy: null,
    selectedGift: {},
  }),
  computed: {
    availableItemsInGift () {
      const { selectedGift } = this
      const settings = selectedGift?.egift_settings || {}
      const items = selectedGift?.collection?.items || []
      if (settings?.egift_type === 'coin') {
        return items.filter(item => item.product.price <= Number(settings?.coin_value || 0))
      }
      return items
    },
    campaignIsPrepaidFromInventory () {
      return !!Number(this.selectedGift.is_prepaid)
    },
    setSortByOptions () {
      const { availableItemsInGift, campaignIsPrepaidFromInventory } = this
      const productsInventory = !campaignIsPrepaidFromInventory
        ? availableItemsInGift.map(item => item.product.inventory.qty)
        : []

      const options = [
        { value: 'price-high', text: 'Price: Low to High' },
        { value: 'price-low', text: 'Price: High to Low' }
      ]

      if (
        !productsInventory.every((item) => item === 0) &&
        !productsInventory.every((item) => item > 0)
      ) {
        options.push({ value: 'source-inventory', text: 'Source: My inventory first' })
        options.push({ value: 'source-catalog', text: 'Source: Catalog products first' })
      }

      return options
    },
    giftItems () {
      const { sortBy, availableItemsInGift } = this

      return availableItemsInGift.sort((a, b) => {
        if (sortBy === 'price-high') return a.product.price - b.product.price
        if (sortBy === 'price-low') return b.product.price - a.product.price
        if (sortBy === 'source-inventory') {
          if (a.product.inventory.qty && b.product.inventory.qty) return 0
          if (a.product.inventory.qty && !b.product.inventory.qty) return -1
          if (!a.product.inventory.qty && b.product.inventory.qty) return 1
          return 0
        }
        if (sortBy === 'source-catalog') {
          if (!a.product.inventory.qty && !b.product.inventory.qty) return 0
          if (a.product.inventory.qty && !b.product.inventory.qty) return 1
          if (!a.product.inventory.qty && b.product.inventory.qty) return -1
          return 0
        }
        return 0
      })
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.selectedGift = cloneDeep(this.gift)
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  }
}
</script>

<style lang="scss">
.options-sort-by {
  .v-input__slot {
    padding: 0 4px !important;
  }
}
</style>

<style lang="scss" scoped>
.gift-options-panel {
  &__title {
    font-size: 30px;
    line-height: 36px;
    font-family: 'Lato-Light', sans-serif;
    color: #000;

    span {
      font-family: inherit;
    }

    &--bold {
      font-family: 'Lato-Bold', sans-serif !important;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-family: 'Lato-Italic', sans-serif;
    line-height: 19px;
    color: #a1a1a1;

    span {
      font-family: inherit;
    }
  }

  .row {
    & > .col-12 {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
}
</style>
