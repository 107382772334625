<template>
  <common-select
    v-model="selectedEntites"
    :items="items"
    v-bind="$attrs"
    menuContentClass="select-multiple-entities__select--menu"
    multiple
    class="select-multiple-entities__select"
    :class="{ 'select-multiple-entities__select--chips': chips }"
    :chips="chips"
    ref="select-entity"
    :item-text="itemText"
    :item-value="itemValue"
  >
    <template #selection="{ index, item, parent }">
      <template v-if="badge && !chips">
        <div v-if="index === 0" class="select-multiple-entities__selected">
          <div>
            {{ selectedEntites.length }}
          </div>
          {{ badgeSuffix }}
        </div>
      </template>

      <template v-if="chips && !badge">
        <v-chip
          class="select-multiple-entities__chip"
          v-if="item === Object(item)"
          small
        >
          {{ item[itemText] }}

          <div class="d-flex align-center pointer" @click="parent.selectItem(item)">
            <icons-close :width="10" :height="11" />
          </div>
        </v-chip>
      </template>
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item
        class="select-multiple-entities__select--item"
        :ripple="false"
        :aria-selected="attrs['aria-selected']"
        v-on="on"
      >
        <div class="select-multiple-entities__select--item--icon" />
        <div class="select-multiple-entities__select--item--name">
          {{ item[itemText] }}
        </div>
      </v-list-item>
    </template>

    <template #append-item>
      <div class="select-multiple-entities__actions">
        <div class="select-multiple-entities__action" @click="selectedEntites = []">
          Clear
        </div>
      </div>
    </template>
  </common-select>
</template>

<script>
export default {
  name: 'SelectMultipleEntities',
  props: {
    value: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      required: false,
      default: 'text',
    },
    itemValue: {
      type: String,
      required: false,
      default: 'value',
    },
    badge: {
      type: Boolean,
      required: false,
      default: false,
    },
    badgeSuffix: {
      type: String,
      required: false,
      default: null,
    },
    chips: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    // used to apply button
    // https://app.asana.com/0/1204252080832108/1206499445914896/f
    selectedEntitiesHelper: [],
  }),
  computed: {
    selectedEntites: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    selectedEntites: {
      immediate: true,
      handler: function (val) {
        this.selectedEntitiesHelper = [...val]
      }
    }
  },
}
</script>

<style lang="scss">
.select-multiple-entities__select {
  .v-select__slot {
    padding: 0px;
  }

  &--item {
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    gap: 8px;

    &:hover {
      background-color: #F4F4F4;
    }

    &--name {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 37px;
      color: #000;
    }

    &--icon {
      display: flex;
      width: 26px;
      height: 26px;
      min-width: 26px;
      min-height: 26px;
      border-radius: 50%;
      border: 1px solid #DADADA;
      background: #fff;
      position: relative;
    }

    &[aria-selected="true"] {
      background-color: #EDFCFB;

      & > .select-multiple-entities__select--item--icon {
        border: none;
        background-color: #42B77A;

        &::after {
          position: absolute;
          left: 50%;
          top: 48%;
          transform: translate(-50%,-50%) rotate(45deg);
          content: ' ';
          width: 6px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &--menu {
    .v-list {
      padding: 0;
      padding-top: 4px !important;

      > .v-list-item {
        padding: 2px 20px !important;
        max-height: unset !important;
        color: #16181F;

        &::before {
          content: none;
        }
      }
    }
  }

  &--chips > .v-input__control > .v-input__slot {
    min-height: 40px !important;
    height: auto !important;

    & > .v-select__slot {
      & > .v-select__selections {
        min-height: 20px !important;
      }

      & > .v-input__append-inner,
      & > .v-input__append-inner {
        padding-top: 8px;
        align-self: flex-start !important;  
      }
    }
  }
}

.select-multiple-entities__actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 12px 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
  justify-content: space-between;
  gap: 20px;
}

.select-multiple-entities__action {
  font-family: 'Lato-Regular', sans-serif;
  cursor: pointer;
  color: #42B77A;
  font-size: 14px;
  line-height: 18px;
}

.select-multiple-entities__selected {
  font-family: 'Lato-Regular', sans-serif;
  color: #222325;
  display: flex;
  align-items: baseline;
  gap: 4px;
  font-size: 15px;
  line-height: 22px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lato-Bold', sans-serif;
    color: #fff;
    line-height: 1;
    padding-top: 2px;
    font-size: 14px;
    background: #219358;
    border-radius: 24px;
    min-width: 22px;
    min-height: 22px;
  }
}

.select-multiple-entities__chip {
  background: #EDFCFB !important;
  height: 26px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  
  & > .v-chip__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;

    color: #62646A;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 12px;
    line-height: 14px;
  }

  &::before {
    content: none;
  }
}
</style>
