<template>
  <v-popover
    :open.sync="showResults"
    placement="bottom-start"
    trigger="click"
    :disabled="!search"
    :style="`flex: 0 1 ${maxWidth}px`"
    popoverBaseClass="popover search-input-tooltip-popover"
    @show="updateInputWidth"
    @hide="hidePopper()"
  >
    <common-input
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      placeholder="Product / SKU# / Kit  name / associated campaigns..."
      style="width: 364px;"
      :height="height"
      filter-style
      clearable
      @focus="inFocusMode = true"
      @blur="inFocusMode = false"
      @click:clear="clearFilters"
      @keyup.delete="handleDeleteButton"
    />
    <div
      :style="`--min-width: ${inputWidth}px; --max-width: ${maxWidth}px`"
      class="search-input-tooltip"
      slot="popover"
    >
      <template v-if="!loading">
        <template v-if="products.length || campaigns.length">
          <ul v-if="campaigns.length">
            <li>Campaigns</li>
            <li
              class="search-input-tooltip__item-campaign"
              :class="{ 'search-input-tooltip__item--selected': isCampaignSelected(campaign) }"
              v-for="campaign in campaigns"
              :key="campaign.id"
              @click="handleCampaignClick(campaign)"
            >
              <icons-campaigns :height="22" :width="18" style="min-width: 18px" />
              {{ campaign.name }}
            </li>
          </ul>
          <ul v-if="products.length">
            <li>Products</li>
            <li
              class="search-input-tooltip__item-product"
              :class="{ 'search-input-tooltip__item--selected': isProductSelected(product) }"
              v-for="product in products"
              :key="product.id"
              @click="handleProductClick(product)"
            >
              <img :src="getProductImage(product)" :alt="product.name">

              <div>
                <div>{{ product.name }}</div>
                <div>{{ product.sku }}</div>
              </div>
            </li>
          </ul>
        </template>
        <div v-else class="search-input-tooltip__empty">
          No products found
        </div>
      </template>

      <common-loader v-else />
    </div>
  </v-popover>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'

export default {
  name: 'SearchByAssociatedDataInput',
  props: {
    campaignId: {
      type: Number,
      required: false,
      default: null,
    },
    productId: {
      type: Number,
      required: false,
      default: null,
    },
    initialSearch: {
      type: String,
      required: false,
      default: null,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 364,
    },
    height: {
      type: Number,
      required: false,
      default: 30,
    },
    hasTransactions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    search: null,
    inFocusMode: false,
    loading: false,
    showResults: false,
    products: [],
    campaigns: [],
    inputWidth: 364,
  }),
  watch: {
    search: function (val) {
      this.$emit('update:initialSearch', val)

      if (val?.length < 3) { return }

      if (val && val !== this.initialSearch) {
        this.updateInputWidth()
        this.loading = true
        this.loadData()
      }
    },
    initialSearch: {
      immediate: true,
      handler: function (val) {
        this.search = val
      }
    },
  },
  methods: {
    updateInputWidth () {
      this.inputWidth = this.$el?.clientWidth || 364;
    },
    isCampaignSelected ({ id }) {
      return this.campaignId === id
    },
    isProductSelected ({ id }) {
      return this.productId === id
    },
    getProductImage ({ image_url: image }) {
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : image
    },
    hidePopper () {
      if (this.inFocusMode) {
        this.$nextTick(() => { this.showResults = true; })
      }
    },
    loadData: debounce(function () {
      const { search, hasTransactions } = this

      Api.post('/customer/purchased-inventory/search', {
        q: search,
        has_transactions: hasTransactions,
      })
        .then(({ products, campaigns }) => {
          this.products = products ?? []
          this.campaigns = campaigns ?? []
        })
        .catch(() => {
          this.products = []
          this.campaigns = []
        })
        .finally(() => (this.loading = false))
    }, 600),
    clearFilters () {
      this.$emit('update:initialSearch', null)
      this.$emit('update:campaignId', null)
      this.$emit('update:productId', null)
    },
    handleDeleteButton () {
      if (!this.search) { this.clearFilters() }
    },
    handleCampaignClick({ id, name }) {
      this.search = name
      this.showResults = false
      this.$emit('update:initialSearch', name)
      this.$emit('update:campaignId', id)
      this.$emit('update:productId', null)
    },
    handleProductClick({ id, name }) {
      this.search = name
      this.showResults = false
      this.$emit('update:initialSearch', name)
      this.$emit('update:campaignId', null)
      this.$emit('update:productId', id)
    },
  },
}
</script>

<style lang="scss">
.search-input-tooltip {
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: #fff;
  max-height: 300px;
  min-width: var(--min-width);
  max-width: var(--max-width);
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  color: #16181F;
  overflow-y: auto;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:has(ul) {
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 2px;
    flex-direction: column;

    & > li {
      padding: 2px 14px;

      &:first-child {
        font-family: 'Lato-Bold', sans-serif;
        height: 36px;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #95979D;
      }

      &:not(:first-child) {
        cursor: pointer;

        &:hover {
          background: #F4F4F4;
        }
      }
    }

    &:nth-child(2) {
      border-top: 1px solid #D3D2D2;
    }
  }

  &__item--selected {
    background: #EDFCFB;
  }

  &__item-campaign {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: nowrap;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    color: #222325;
    min-height: 48px;
    align-items: center;
  }

  &__item-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;

    & > img {
      object-fit: contain;
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-family: 'Lato-Regular', sans-serif;

      & > div {
        &:first-child {
          font-size: 14px;
          color: #222325;
        }

        &:last-child {
          font-size: 13px;
          color: #95979D;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.search-input-tooltip-popover {
  z-index: 5;
}
</style>
