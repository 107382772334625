<template>
  <v-textarea
    v-model="modelValue"
    v-bind="$attrs"
    v-on="$listeners"
    class="common-textarea"
    color="black"
    outlined
    solo
    flat
    dense
    auto-grow
    :hide-details="hideDetails"
    :validate-on-blur="validateOnBlur"
  >
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot" />
      </template>
    </template>
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: 'CommonTextarea',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: true
    },
    validateOnBlur: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.common-textarea {
  border-radius: 0;
}
</style>
