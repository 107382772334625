<template>
  <date-range-picker
    ref="picker"
    :opens="right ? 'left' : 'right'"
    :minDate="minDate" :maxDate="maxDate"
    :singleDatePicker="singleDatePicker"
    :timePicker="timePicker"
    :timePicker24Hour="timePicker24Hour"
    :showWeekNumbers="showWeekNumbers"
    :showDropdowns="showDropdowns"
    :autoApply="autoApply"
    v-model="range"
    :linkedCalendars="linkedCalendars"
    :append-to-body="true"
    class="my-date-range-picker"
    :ranges="dateRanges"
    :locale-data="customOptions"
    @update="updateValues"
  >
    <template #input>
      <common-input
        v-model="rangeValue"
        placeholder="Date range"
        :height="height"
        disabled
        filter-style
      >
        <template #prepend-inner>
          <icons-calendar />
        </template>
      </common-input>
    </template>
    <template #ranges="ranges">
      <div class="ranges">
        <ul>
          <li
            v-for="(range, name) in ranges.ranges" :key="name"
            @click="ranges.clickRange(range)"
            v-on:click="setRange(name)"
            :class="name === selectedRange ? 'active' : ''"
          >
            <span class="f14">{{name}}</span>
            <span
              class="f14 ml-1"
              v-if="name === 'This month' || name === 'Last month'">
              ({{getMonth(range[0].toDateString())}})
            </span>
          </li>
        </ul>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'my-date-range-picker',
  components: { DateRangePicker },
  props: {
    dateRange: {
      type: Object,
      required: false,
      default: () => ({ start: null, end: null })
    },
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data: () => {
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0)

    let diff = today.getDate() - today.getDay()
    const sunday = new Date(today.setDate(diff))
    diff = today.getDate() - today.getDay() + 6
    const satuaday = new Date(today.setDate(diff))

    diff = today.getDate() - today.getDay() - 7
    const lastSunday = new Date(today.setDate(diff))
    diff = today.getDate() - today.getDay() + 6
    const lastSatuaday = new Date(today.setDate(diff))

    today = new Date()

    return ({
      checked: false,
      minDate: '01/01/1900',
      maxDate: '01/01/2900',
      singleDatePicker: false,
      timePicker: false,
      timePicker24Hour: false,
      showWeekNumbers: false,
      showDropdowns: false,
      autoApply: true,
      linkedCalendars: true,
      range: {
        start: '',
        end: ''
      },
      rangeValue: '',
      dateRanges: {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'This week': [sunday, satuaday],
        'Last week': [lastSunday, lastSatuaday],
        'This month': [new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 0)],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Last year': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)],
        Clear: ['', '']
      },
      customOptions: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        firstDay: 0
      },
      selectedRange: ''
    })
  },
  computed: {
    inputModel () {
      const { range } = this

      const values = Object.values(range)

      if (values?.some((item) => item)) {
        return values.map((value) => this.filterToDate(value))?.join(' - ')
      }

      return null
    },
  },
  methods: {
    filterToDate (val) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
      ]
      if (val === null) {
        return
      }
      const arr = String(val).split(' ')
      return ((months.findIndex(month => month === arr[1]) + 1) + '/' + arr[2] + '/' + arr[3])
    },
    getMonth (val) {
      const monthIdxs = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
      ]
      const months = [
        'January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September',
        'October', 'November', 'December'
      ]
      return months[monthIdxs.findIndex(idx => idx === String(val).split(' ')[1])]
    },
    setRange (name) {
      this.selectedRange = name
    },
    clearSelectedDates () {
      this.range = {
        endDate: null,
        startDate: null
      }
      // clear css clases
      this.setRange('')
    },
    convertToRangeParam (value) {
      let date = this.filterToDate(value)
      const dateArray = date.split('/')
      date = dateArray[1] + '-' + (dateArray[0].length > 1 ? dateArray[0] : '0' + dateArray[0]) + '-' + dateArray[2]
      return date
    },
    updateValues (value) {
      let range
      if (value.startDate === null) {
        range = null
      } else {
        range = {
          start: this.convertToRangeParam(value.startDate),
          end: this.convertToRangeParam(value.endDate)
        }
      }
      this.$emit('setDateRange', range)
    }
  },
  watch: {
    dateRange (value) {
      if (value === undefined || value === null || value.start === undefined || value.start === null || value.start.length < 1) {
        this.rangeValue = ''
      } else if (value.start.length > 0) {
        let date = value.start.replace(/-/g, '/').split('/')
        this.rangeValue = date[1] + '/' + date[0] + '/' + date[2].substring(2, 5)
        date = value.end.replace(/-/g, '/').split('/')
        this.rangeValue += ' - ' + date[1] + '/' + date[0] + '/' + date[2].substring(2, 5)
      }
    }
  },
  created () {
    if (this.dateRange !== undefined && this.dateRange.start !== undefined && this.dateRange.start !== null) {
      let date = this.dateRange.start.split('-')
      this.rangeValue = date[1] + '/' + date[0] + '/' + date[2].substring(2, 5)
      date = this.dateRange.end.split('-')
      this.rangeValue += ' - ' + date[1] + '/' + date[0] + '/' + date[2].substring(2, 5)
    } else {
      this.rangeValue = ''
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.rangeValue) {
        const [start, end] = this.rangeValue.split(' - ')

        const convertDate = (date) => {
          const [day, month, year] = date.split('/')
          const newDate = [month, day, year].join('/')

          return new Date(newDate) ?? date
        }

        this.range = {
          startDate: convertDate(start),
          endDate: convertDate(end),
        }
      }
    }, 0)
  }
}
</script>

<style lang="scss">
.my-date-range-picker {
  .reportrange-text {
    padding: 0;
    border: none;
    min-width: 210px;

    & .v-input--is-disabled {
      color: #000;
      cursor: pointer;

      &:not(.common-input--filter) > .v-input__control > .v-input__slot {
        background: #fff !important;
      }

      & input {
        color: #000;
      }
    }
  }
}
// TODO refactor
body > .daterangepicker {
  top: auto;
  min-width: 790px !important;

  &::before,
  &::after {
    display: none;
  }

  .ranges {
    ul {
      width: 195px;
      padding: 10px 13px 0 7px;
    }

    li {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 26px;
      border-radius: 13px;
      color: rgba(0,0,0,0.54);

      &:hover {
        background: #f6f6f6;
      }
    }

    li:last-child {
      position: absolute;
      left: 7px;
      bottom: 20px;
      color: #219358;

      &:hover {
        background: none;
      }

      &.active {
        background: none;
      }
    }

    .active {
      background: #ebebeb;
      color: black;
    }
  }

  .drp-calendar {
    max-width: 295px;
    width: 295px;
  }

  .left {
    border-right: 1px solid #D8D8D8;
    padding: 10px 0 10px 15px;

    .next {
      visibility: hidden;
    }
  }

  .right {
    .prev {
      visibility: hidden;
    }
  }

  .calendar-table {
    td, th {
      min-width: 38px;
      height: 38px;
      font-size: 14px!important;
    }

    td:hover {
      background: #f8f8f8;
    }

    td.active {
      background: #198b85;
    }

    .in-range {
      background: #dff3f2;
    }

    .off {
      visibility: hidden;
    }
  }
}
</style>
