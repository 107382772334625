<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M12 13C12 13.5304 11.7893 14.0391 11.4142 14.4142C11.0391 14.7893 10.5304 15 10
        15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13V6C1 5.46957
        1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4V5C2.73478 5 2.48043 5.10536
        2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6V13C2 13.2652 2.10536 13.5196 2.29289
        13.7071C2.48043 13.8946 2.73478 14 3 14H10C10.2652 14 10.5196 13.8946 10.7071
        13.7071C10.8946 13.5196 11 13.2652 11 13H12Z"
      :fill="color"
    />
    <path
      d="M6 2C5.73478 2 5.48043 2.10536 5.29289 2.29289C5.10536 2.48043 5 2.73478 5 3V10C5 10.2652
        5.10536 10.5196 5.29289 10.7071C5.48043 10.8946 5.73478 11 6 11H13C13.2652 11 13.5196
        10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10V3C14 2.73478 13.8946 2.48043
        13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H6ZM6 1H13C13.5304 1 14.0391 1.21071 14.4142
        1.58579C14.7893 1.96086 15 2.46957 15 3V10C15 10.5304 14.7893 11.0391 14.4142
        11.4142C14.0391 11.7893 13.5304 12 13 12H6C5.46957 12 4.96086 11.7893 4.58579
        11.4142C4.21071 11.0391 4 10.5304 4 10V3C4 2.46957 4.21071 1.96086 4.58579 1.58579C4.96086
        1.21071 5.46957 1 6 1V1Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Copy',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 16
    },
    height: {
      type: [Number, String],
      required: false,
      default: 16
    },
    color: {
      type: String,
      required: false,
      default: '#222325'
    }
  }
}
</script>
