<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 16"
    fill="none"
    :height="height"
    :width="width"
  >
    <g clip-path="url(#clip0_1588_26867)">
      <path
        d="M14.8632 0.636878C14.9281 0.701877 14.9724 0.78451 14.9908 0.874513C15.0091 0.964516
          15.0006 1.05792 14.9663 1.14313L9.51098 14.7809C9.46291 14.901 9.38263 15.0056 9.27898
          15.083C9.17533 15.1604 9.05234 15.2077 8.92353 15.2197C8.79472 15.2318 8.66509 15.208
          8.54891 15.1511C8.43273 15.0942 8.33451 15.0063 8.26504 14.8972L5.28567 10.2144L0.602855
          7.235C0.493466 7.16561 0.405372 7.06735 0.348296 6.95106C0.291219 6.83477 0.267378 6.70497
          0.279404 6.57599C0.291429 6.44701 0.338853 6.32385 0.416444 6.22012C0.494034 6.11638
          0.598774 6.0361 0.719105 5.98813L14.3569 0.534691C14.4421 0.50039 14.5355 0.491877 14.6255
          0.510211C14.7155 0.528545 14.7982 0.572917 14.8632 0.637816V0.636878ZM6.22129
          9.94063L8.80973 14.0075L13.2469 2.915L6.22129 9.94063ZM12.5841 2.25219L1.49161
          6.68938L5.55942 9.27688L12.585 2.25219H12.5841Z"
        fill="#95979D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1588_26867">
        <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Send',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 16
    },
    height: {
      type: [Number, String],
      required: false,
      default: 16
    }
  }
}
</script>

<style>

</style>