import { Component, Mixins, InjectReactive } from 'vue-property-decorator'

import { PanelType, PaymentType, RootSteps } from '../../types'
import type { IRequestBody, IRequestResponse } from '../../types/sendCampaign'

import Api from '@/axios/api'
import createCookieForSameDomain from '@/utils/createCookieForSameDomain'
import { purchase } from '@/plugins/googleTagManager'

import campaignShippingCostProp from '../mixins/campaignShippingCostProp'
import campaignTotalCostProp from '../mixins/campaignTotalCostProp'
import ShowErrorMessage from '../mixins/showErrorMessage'
import GetRequestBody from '../mixins/getRequestBody'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import UserInfo from '@/mixins/userInfo'

interface ErrorData {
  errors: string[];
  message: string;
}

@Component
export default class SendCampaign extends Mixins(
  campaignShippingCostProp,
  campaignTotalCostProp,
  ShowErrorMessage,
  GetRequestBody,
  panelTypeProp,
  campaignProp,
  UserInfo,
) {
  loading = false;

  @InjectReactive('refreshTokenFn') refreshTokenFn!: Function | null;

  sendCampaign(): void {
    const {
      panelType,
      campaign,
      userEmail,
      userId,
      numberOfRecipients,
      campaignTotalCost,
      campaignShippingCost,
    } = this

    const requestBody = this.getRequestBody()
    
    this.loading = true;

    const selectedPaymentMethod = requestBody?.payment?.[0]?.method

    const GAPaymentMethod = selectedPaymentMethod === PaymentType.CC ? 'credit card'
      : selectedPaymentMethod === PaymentType.BE ? 'cg credit'
      : selectedPaymentMethod === PaymentType.ACH ? 'ach'
      : selectedPaymentMethod === PaymentType.PO ? 'purchase order'
      : selectedPaymentMethod

    const apiEndpoint: string = panelType === PanelType.Link ? 'link' : 'email'

    Api.post<IRequestBody, IRequestResponse>(`/campaigns/send-egift/${campaign.entity_id}/${apiEndpoint}`, requestBody)
      .then(({ success, data, errors }) => {
        if (success) {
          if (panelType === PanelType.Link) { createCookieForSameDomain('last_created_gift_id', data.gift_id) }

          purchase({
            transactionId: data.order_number,
            shipping: campaignShippingCost?.toFixed(2),
            value: campaignTotalCost?.toFixed(2),
            checkoutType: 'campaign',
            paymentType: GAPaymentMethod,
            coupon: undefined,
            tax: undefined,
            email: userEmail,
            userId: userId,
            parentAccount: null,
            items: campaign.collection?.items?.map((item) => ({
              'item_id': data.order_number,
              'item_name': item.product.name,
              'item_category': 'egift_campaign',
              'price': item.product.price?.toFixed(2),
              'quantity': numberOfRecipients ||  1,
              'currency': 'USD',
            })),
          })
          this.$emit('update:successResponse', data)
          this.$emit('update:step', RootSteps.Success)
        } else {
          this.showErrorMessage(errors?.[0])
        }
      })
      .catch((err) => {
        const { errors, message }: ErrorData = err?.response?.data
        this.showErrorMessage(errors?.[0] || message)

        if (this.refreshTokenFn) {
          this.refreshTokenFn();
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
