<template>
    <v-navigation-drawer
      v-model="show"
      bottom
      app
      temporary
      tag="aside"
      class="bottom-side-panel"
      :class="{
        'bottom-side-panel--fullscreen': fullscreen,
      }"
    >
      <panel-header :show.sync="show">
        <slot name="title" />
      </panel-header>

      <div class="bottom-side-panel__content-wrapper">
        <slot />
      </div>
    </v-navigation-drawer>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

import PanelHeader from '@/components/PanelHeader.vue'

export default {
  name: 'CommonBottomPanel',
  mixins: [panelVModel],
  components: { PanelHeader },
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/common/bottom-side-panel.scss';
</style>
