import { render, staticRenderFns } from "./AchItem.vue?vue&type=template&id=55a2c20f&scoped=true"
import script from "./AchItem.vue?vue&type=script&lang=js"
export * from "./AchItem.vue?vue&type=script&lang=js"
import style0 from "./AchItem.vue?vue&type=style&index=0&id=55a2c20f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a2c20f",
  null
  
)

export default component.exports