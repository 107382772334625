<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M2.42545 6.87051V8.56463H16.4526V6.87051H2.42545ZM0.742188 5.17639H18.1359V10.2587H0.742188V5.17639Z"
      fill="#95979D"
    />
    <path
      d="M2.98697 10.2588V18.1647H15.8919V10.2588H2.98697ZM2.42588 8.5647H16.453C17.0728 8.5647
        17.5752 9.07035 17.5752 9.69411V18.7294C17.5752 19.3532 17.0728 19.8588 16.453
        19.8588H2.42588C1.80612 19.8588 1.30371 19.3532 1.30371 18.7294V9.69411C1.30371
        9.07035 1.80612 8.5647 2.42588 8.5647Z"
      fill="#95979D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.38727 6.46608C9.18285 6.46608 9.029 6.41465 8.87428 6.26079L4.92292 3.02771C4.56378
        2.72 4.51236 2.20658 4.8205 1.84744C5.1282 1.48788 5.64119 1.43731 6.00033
        1.74502L9.95125 4.97767C10.3113 5.28624 10.3627 5.7988 10.0545 6.15837C9.90026 6.36279
        9.64398 6.46608 9.38727 6.46608Z"
      fill="#95979D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.92617 6.46608C8.6686 6.46608 8.46418 6.36279 8.30989 6.15837C8.00132 5.7988 8.05318
        5.28624 8.41275 4.97767L12.3641 1.74502C12.7228 1.43731 13.2371 1.48788 13.5444
        1.84744C13.8525 2.20658 13.8011 2.72 13.4411 3.02771L9.43916 6.26079C9.28444 6.41465
        9.13189 6.46608 8.92617 6.46608Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'GiftAlternative',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 19
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    }
  }
}
</script>
