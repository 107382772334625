<template>
  <div class="my-users-inventory-limits">
    <div>
      <div>Item name</div>
      <div>Qty limit</div>
      <div>Period</div>
      <div></div>
    </div>

    <div
      v-for="inventoryLimit in inventoryLimits"
      :key="inventoryLimit.id"
      class="my-users-inventory-limits__item"
    >
      <div class="my-users-inventory-limits__item-name">
        {{ inventoryLimit.name }}
      </div>

      <template v-if="inventoryLimit.id !== editedItemId">
        <div>{{ inventoryLimit.spend_limit }}</div>
        <div>{{ getInventoryItemPeriodName(inventoryLimit) }}</div>
        <div class="my-users-inventory-limits__item-options">
          <icons-edit
            :width="13"
            :height="13"
            color="#9B9B9B"
            @click.native.stop="editInventoryLimit(inventoryLimit)"
          />

          <icons-delete
            :width="13"
            :height="17"
            color="#9B9B9B"
            @click.native.stop="removeItem(inventoryLimit)"
          />
        </div>
      </template>

      <template v-else>
        <common-input
          v-model.number="form.spend_limit"
          type="number"
          :min="1"
          ref="edit-form-limit"
          placeholder="Qty limit"
          :rules="[rules.required, rules.numberGreaterThanZero]"
          :height="40"
        />

        <common-select
          v-model="form.period"
          :items="periodOptions"
          item-text="text"
          item-value="value"
          placeholder="Period"
          ref="edit-form-period"
          :rules="[rules.required]"
          class="my-users-inventory-limits__select"
          :height="40"
        />

        <div class="my-users-inventory-limits__item-options">
          <icons-close-circle
            :width="20"
            :height="20"
            color="#9B9B9B"
            @click.native.stop="cancelEditing()"
          />

          <icons-check-circle
            :checkStrokeWidth="1.5"
            :outlineStrokeWidth="1"
            outline-color="#42B77A"
            outline-check-color="#42B77A"
            :width="20"
            :height="20"
            @click.native.stop="saveChanges()"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyUserInventoryLimitsList',
  props: {
    inventoryLimits: {
      type: Array,
      required: true
    },
    periodOptions: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    editedItemId: null,
    form: {
      spend_limit: null,
      period: null,
    },
    rules: {
      required: v => !!v || '',
      numberGreaterThanZero: v => v > 0 || ''
    },
  }),
  methods: {
    getInventoryItemPeriodName ({ period }) {
      if (period === null) return null

      const timeItem = this.periodOptions.find((item) => item.value === period)
      return timeItem?.text ?? null
    },
    editInventoryLimit ({ id, spend_limit, period }) {
      const { periodOptions } = this

      this.editedItemId = id
      this.form = {
        spend_limit: spend_limit ?? null,
        period: period ?? periodOptions?.[0]?.value ?? null,
      }
    },
    removeItem ({ id }) {
      this.$emit('removeItem', { id })
    },
    cancelEditing () {
      this.editedItemId = null
      this.form = {
        spend_limit: null,
        period: null,
      }
    },
    saveChanges () {
      const { editedItemId, form } = this
      const limitInput = this.$refs['edit-form-limit'][0] ?? this.$refs['edit-form-limit'],
        periodInput = this.$refs['edit-form-period'][0] ?? this.$refs['edit-form-period']

      const validationResult = [limitInput, periodInput]
        .map((el) => el?.$children?.[0])
        .filter((input) => !input?.validate(true))

      if (validationResult.length !== 0) { return }

      this.$emit('updateItem', {
        id: editedItemId,
        ...form,
      })

      this.cancelEditing()
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-inventory-limits {
  display: flex;
  flex-direction: column;
  
  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: 200px 100px 110px 60px;
    padding: 10px 20px;
    gap: 10px;
    border-bottom: 1px solid #D3D2D2;

    &:first-of-type {
      border-top: 1px solid #D3D2D2;
      text-transform: uppercase;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 11px;
      line-height: 13px;
      color: #000000;

      & > div {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
    }
  }

  &__item {
    min-height: 65px;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    color: #000000;
  }

  &__item-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 12px;
    color: #222325;
  }

  &__item-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;

    & > svg {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__select {
    &::v-deep .v-input__append-inner {
      padding: 0 !important;
    }

    &::v-deep .v-input__icon {
      padding: 0 !important;
    }
  }
}
</style>
