import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

import ApiM from '@/axios/apiM'

import EGiftOrdersStore from './e-gift-orders.store'
import EGiftOrderStore from './e-gift-order.store'
import Users from './users'
import BudgetEntities from './budgetEntities.store'
import Inventory from './inventory'
import Orders from './orders'
import NewOrders from './newOrders'
import TransactionsHistory from './transactionsHistory'
import NewApprovableOrders from './approvableOrders'
import ApprovableOrders from './approvable-orders'
import Customers from './customers.store'
import Tooltips from './tooltips'
import ApiTokens from './apiTokens.store'
import Snackbar from './snackbar'
import NewCampaignStore from './newCampaign'
import App from './app.store'

import SurveyMonkey from './surveyMonkey.store'
import Hubspot from './integrations/hubspot.store'

Vue.use(Vuex, VueAxios, axios)

export default new Vuex.Store({
  modules: {
    'e-gift-orders': EGiftOrdersStore,
    'e-gift-order': EGiftOrderStore,
    users: Users,
    budgetEntities: BudgetEntities,
    inventory: Inventory,
    orders: Orders,
    'new-orders': NewOrders,
    'transactions-history': TransactionsHistory,
    customers: Customers,
    'approvable-orders': ApprovableOrders,
    'new-approvable-orders': NewApprovableOrders,
    tooltips: Tooltips,
    apiTokens: ApiTokens,
    surveyMonkey: SurveyMonkey,
    hubspot: Hubspot,
    snackbar: Snackbar, // DEPRECATED USE this.$cgToast
    newCampaign: NewCampaignStore,
    app: App
  },
  state: {
    // search
    selectedOrders: [], // TODO debug and remove?
    // spend limit
    cgCreditAddFundMode: null, // TODO debug and remove?
    downloadingCSV: undefined
  },
  actions: {
    async downloadInvoice ({ commit }, orderId) {
      await ApiM.get(
        `/cg/download/download/order_id/${Number(orderId)}`,
        { responseType: 'arraybuffer' }
      ).then(resp => {
        if (!resp.message) {
          commit('downloadPDF', resp)
        }
      })
    }
  },
  mutations: {
    downloadingCSV (state, data) {
      state.downloadingCSV = data
    },
    downloadCSV (state, data) {
      const csvFile = data
      const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'report.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      state.downloadingCSV = false
    },
    downloadPDF (state, data) {
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, 'EPrescription')
    }
  }
})
