<template>
  <div class="footer-payment-methods">
    <img :src="largeFormatUrl" alt="Payment methods">
    <img :src="smallFormatUrl" alt="Payment methods">
  </div>
</template>

<script>
export default {
  name: 'TheFooterPaymentMethods',
  props: {
    paymentData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    cfPath: 'https://cf.corporategift.com',
  }),
  computed: {
    largeFormatUrl () {
      const { paymentData } = this
      return paymentData?.data?.attributes.url ?? null
    },
    smallFormatUrl () {
      const { paymentData } = this
      return paymentData?.data?.attributes.formats?.thumbnail?.url ?? null
    },
  }
}
</script>

<style lang="scss" scoped>
.footer-payment-methods {
  width: 100%;

  & > img {
    max-width: 834px;

    &:first-child {
      display: block;
    }

    &:last-child {
      display: none;
    }
  }

  @media (max-width: 639px) {
    & > img {
      max-width: 100%;

      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
}
</style>
