<template>
  <div class="send-egift-panel-greeting">
    <div class="send-egift-panel-greeting__wrapper">
      <div class="send-egift-panel-greeting__content">
        <input
          v-model="greetingForm.title"
          id="greeting-title"
          type="text"
          v-tooltip="{
            offset: 8,
            container: $el,
            autoHide: false,
            placement: 'top',
            trigger: 'manual',
            show: showGreetingTagTooltip,
            classes: 'send-egift-panel-greeting__tooltip',
            content: getTooltipTextBySlug('egift_collection_greeting_dynamic_tags'),
          }"
          placeholder="Hi @first name"
          @focus="handleTitleFocus"
          @blur="handleTitleBlur"
        >

        <v-popover
          :offset="8"
          placement="top"
          :container="$el"
          :show="showGreetingContentTooltip"
        >
          <textarea
            v-model="greetingForm.content"
            id="greeting-content"
            placeholder="Have a wonderful day.
            Choose your favorite gift from this curated collection and enjoy!..."
            @input="resizeTextArea"
            @focus="handleContentFocus"
            @blur="handleContentBlur"
          />

          <template slot="popover">
            <div class="send-egift-panel-greeting__tooltip">
              Use prepopulate
              <a role="button" @click="showGreetingIdeasStep()">greeting messages</a>

              <div class="tooltip-arrow" />
            </div>
          </template>
        </v-popover>

        <input
          v-model="greetingForm.from"
          id="greeting-from"
          type="text"
          placeholder="Yours truly"
          @focus="handleSelectedArea"
          @blur="selectedAreaId = null"
        >

        <greeting-upload-logo
          v-model="greetingLogo"
        />
      </div>

      <greeting-mention-menu
        :text-area-id="selectedAreaId"
        @changeInput="updateSelectedAreaMessage"
      />
    </div>

    <common-button width="200" height="44" @click="saveChanges()">
      Save changes
    </common-button>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'

import UserInfo from '@/mixins/userInfo'
import formProp from '../mixins/formProp'
import recipientsProp from '../mixins/recipientsProp'
import GreetingHelperProp from '../mixins/greetingHelperProp'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import GreetingUploadLogo from '../components/GreetingUploadLogo.vue'
import GreetingMentionMenu from '../components/GreetingMentionMenu.vue'

@Component({
  mixins: [getTooltipTextBySlug],
  components: {
    GreetingUploadLogo,
    GreetingMentionMenu,
  },
})
export default class SendEgiftPanelGreeting extends Mixins(
  UserInfo,
  formProp,
  recipientsProp,
  GreetingHelperProp,
) {
  greetingForm = {
    title: null as string | null,
    content: null as string | null,
    from: null as string | null,
  }

  greetingLogo: string | null = null;

  selectedAreaId: string | null = null;

  showGreetingTagTooltip = false;
  
  showGreetingContentTooltip = false;

  get giftGreetingMessage (): string | null {
    return this.form.greeting ?? null
  }

  get giftGreetingLogo (): string | null {
    return this.form.logoUrl ?? null
  }

  mounted (): void {
    const {
      giftGreetingMessage,
      giftGreetingLogo,
      greetingHelper: {
        message: greetingHelperMessage,
        logoUrl: greetingHelperLogo
      }
    } = this

    if (greetingHelperMessage || giftGreetingMessage) {
      const splittedMessage = greetingHelperMessage
        ? greetingHelperMessage?.split('\n\n')
        : giftGreetingMessage?.split('\n\n') ?? []

      const newTitle = splittedMessage?.shift() ?? null
      const newFrom = splittedMessage?.pop() ?? null
      const rest = splittedMessage?.join('\n\n') ?? null

      this.greetingForm = {
        title: newTitle,
        content: rest,
        from: newFrom,
      }

      setTimeout(() => { this.resizeTextArea() }, 0)
    }

    if (greetingHelperLogo || giftGreetingLogo) {
      this.greetingLogo = greetingHelperLogo || giftGreetingLogo
    }

    this.setGreetingTitleAndFrom()

    // focus greeting title on mounted
    setTimeout(() => {
      const formTitle: HTMLInputElement | null = document.querySelector('#greeting-title')

      if (formTitle) { formTitle.focus() }
    }, 200)
  }

  setGreetingTitleAndFrom () {
    const { numberOfRecipients, recipients, greetingForm, userFirstName } = this
    const { title, from } = greetingForm
    const [firstRecipient] = recipients
    const { firstName } = firstRecipient ?? {}

    if (
      !title ||
      (numberOfRecipients === 1 && title?.trim() === 'Hi @first_name') ||
      (numberOfRecipients > 1 && title?.trim() === `Hi ${firstName || ''}`)
    ) {
      let newTitle = 'Hi '
      newTitle += numberOfRecipients === 1 ? firstName : '@first_name'

      this.greetingForm.title = newTitle
    }

    if (!from) {
      let newFrom = 'Yours'

      newFrom += userFirstName ? `, ${userFirstName}` : ' truly'

      this.greetingForm.from = newFrom
    }
  }

  resizeTextArea (): void {
    const textarea = this.$el?.querySelector('textarea')

    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }

  handleSelectedArea (event: FocusEvent): void {
    this.selectedAreaId = (event.target as HTMLInputElement | HTMLTextAreaElement)?.id ?? null
  }

  updateSelectedAreaMessage ({ id, message }: { id: string, message: string }): void {
    if (id.includes('title')) {
      this.greetingForm.title = message
    } else if (id.includes('content')) {
      this.greetingForm.content = message
      this.resizeTextArea()
    } else if (id.includes('from')) {
      this.greetingForm.from = message
    } else {
      console.error(`Can't handle form with id: ${id}`)
    }
  }

  handleTitleFocus (event: FocusEvent): void {
    this.handleSelectedArea(event)
    setTimeout(() => {
      this.showGreetingTagTooltip = true
    }, 200)
  }

  handleTitleBlur (): void {
    this.selectedAreaId = null
    this.showGreetingTagTooltip = false
  }

  handleContentFocus (event: FocusEvent): void {
    this.handleSelectedArea(event)
    setTimeout(() => {
      this.showGreetingContentTooltip = true
    }, 100)
  }

  handleContentBlur (): void {
    this.selectedAreaId = null
    setTimeout(() => {
      this.showGreetingContentTooltip = false
    }, 200)
  }

  showGreetingIdeasStep (): void {
    const { greetingForm, greetingLogo } = this

    this.$emit('update:greetingHelper', {
      message: Object.values(greetingForm).join('\n\n'),
      logoUrl: greetingLogo,
    })
    this.$emit('update:step', RootSteps.GreetingIdeas)
  }

  saveChanges (): void {
    const { greetingForm, greetingLogo } = this

    this.$emit('update:greetingHelper', { message: null, logoUrl: null })
    this.$emit('update:greeting', Object.values(greetingForm).join('\n\n'))
    this.$emit('update:logoUrl', greetingLogo)
    this.$emit('update:step', RootSteps.Review)
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-greeting {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;

  &__wrapper {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    max-width: 520px;
    min-height: 300px;
    gap: 14px;
    padding: 30px;
    background-color: #FAFAFA;
  }

  &__content {
    display: flex;
    flex: 1 1;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #D3D2D2;
    padding: 20px;
    gap: 40px;

    & > .v-popover {
      width: 100%;
    }

    & > input {
      padding: 10px 0;
    }

    & textarea {
      min-height: 100px;
      padding: 10px;
      resize: none;
      overflow: hidden;
    }

    & > input,
    & textarea {
      width: 100%;
      outline: none;
      font-family: 'Lato-Italic', sans-serif;
      border: 2px solid transparent;
      box-sizing: border-box;
      text-align: center;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      color: #000;
      letter-spacing: 0.293333px;

      &::placeholder {
        color: #acacac;
      }

      &:hover,
      &:focus {
        border-color:#A7DAD8;
      }
    }
  }
}
</style>

<style lang="scss">
.send-egift-panel-greeting__tooltip {
  padding: 14px;
  background: #fff;
  color: #16181F;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  max-width: 420px;
  line-height: 17px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  & a,
  & span {
    color: #42B77A !important;

    &:hover {
      color: #219358 !important;
    }
  }

  & > .tooltip-arrow {
    bottom: -5px;
    width: 10px;
    height: 10px;
    position: absolute;
    left: calc(50% - 5px);
    transform: rotate(45deg);
    background: inherit;
  }
}
</style>
