<template>
  <div class="text-with-tooltip__wrapper">
    <popper
      :disabled="disableTooltip"
      :options="{
        placement: 'top',
        modifiers: { offset: { offset: '0, 2px' } }
      }"
    >
      <div
        v-bind="$attrs"
        slot="reference"
        :class="{ 'text-with-tooltip__content--green': makeActivatorTextGreen }"
        class="text-with-tooltip__content"
      >
        <slot />
      </div>

      <div class="popper text-with-tooltip__tooltip">
        <slot name="tooltip" />
      </div>
    </popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'

export default {
  name: 'CommonTextWithTooltip',
  components: { Popper },
  props: {
    makeActivatorGreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    element: null
  }),
  computed: {
    disableTooltip () {
      const { element } = this

      if (element) { return element.offsetWidth === element.scrollWidth }
      return true
    },
    makeActivatorTextGreen () {
      const { disableTooltip, makeActivatorGreen } = this

      return !disableTooltip && makeActivatorGreen
    },
  },
  mounted () {
    const contentElement = this.$el?.querySelector('.text-with-tooltip__content')
    this.element = contentElement
  }
}
</script>

<style lang="scss" scoped>
.text-with-tooltip {
  &__wrapper {
    width: auto;
  }

  &__content {
    text-overflow: ellipsis;
    overflow : hidden;
    white-space: nowrap;

    &--green {
      color: #00a86b;
    }
  }

  &__tooltip {
    padding: 16px;
    max-width: 350px;
  }
}
</style>
