var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recent-orders dashboard-card",class:{
    'dashboard-card--small': _vm.$vuetify.breakpoint.xsOnly,
    'dashboard-card--large': (!_vm.adminAccount || !_vm.hasApprovalOrders) && _vm.$vuetify.breakpoint.mdAndUp,
    'dashboard-card--extra-large': ((_vm.adminAccount && _vm.hasApprovalOrders) ||
      ((_vm.superAdmin || _vm.masterAccount || _vm.adminAccount) && !_vm.hasApprovalOrders && _vm.hasInventory) ||
      !_vm.hasCgCredits) && _vm.$vuetify.breakpoint.mdAndUp,
    'order-5': !_vm.adminAccount && _vm.userIsCgElite || _vm.marketplaceUser,
    'order-8': _vm.adminAccount && _vm.userIsCgElite,
  }},[_c('div',{staticClass:"recent-orders__title"},[_vm._v(" Recent Orders "+_vm._s(_vm.items.length ? '(all account users)' : '')+" ")]),(!_vm.loading && _vm.items.length)?[_c('v-data-table',{attrs:{"headers":_vm.$vuetify.breakpoint.mdAndUp ? _vm.headers : _vm.mobileHeaders,"items":_vm.items,"hide-default-footer":"","items-per-page":4,"mobile-breakpoint":0},on:{"click:row":(item) => _vm.goToProductPage(item)},scopedSlots:_vm._u([{key:"item.recipient",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getRecipientName(item))+" ")]}},{key:"item.price",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("priceFilter")(item.price))+" ")]}},{key:"item.status",fn:function({ item }){return [_c('update-status-sheet',{staticClass:"recent-order-status",attrs:{"color":_vm.getOrderStatusColor(item),"text-color":_vm.getOrderStatusTextColor(item)}},[(_vm.getOrderStatus(item) === 'Active')?_c('icons-check-circle',{attrs:{"filled":"","height":14,"width":14}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getOrderStatus(item))+" ")],1)]}}],null,false,4015593604)}),_c('div',{staticClass:"recent-orders__actions"},[_c('router-link',{attrs:{"to":{ name: 'Orders' }}},[_vm._v(" All orders ")])],1)]:(!_vm.loading && !_vm.items.length)?[_c('div',{staticClass:"recent-orders__empty"},[_c('icons-empty-recent-orders'),_vm._v(" You don’t have any orders ")],1)]:_c('common-loader')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }