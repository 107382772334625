export default {
  data: () => ({
    scrollOverflow: {
      left: false,
      right: true,
    },
  }),
  mounted () {
    this.$refs?.scroller?.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    this.$refs?.scroller?.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    scrollHandler (e) {
      const scrollWidth = (e.currentTarget.scrollWidth ?? 0) - (e.currentTarget.offsetWidth ?? 0)

      const scrollDiff = scrollWidth - e.currentTarget.scrollLeft

      if (scrollDiff === 0) {
        this.scrollOverflow = {
          left: true,
          right: false,
        }
      } else if (scrollDiff === scrollWidth) {
        this.scrollOverflow = {
          left: false,
          right: true,
        }
      } else {
        this.scrollOverflow = {
          left: true,
          right: true,
        }
      }
    },
  },
}