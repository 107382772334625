export default () => ({
  inventoryList: [],
  loading: false,
  currentPage: 1,
  totalPages: 1,
  loadingPage: false,
  filters: {
    campaignId: null,
    productId: null,
    search: null,
    filter_by: null,
  },
})
