<template>
  <div class="send-egift-panel-recipients">
    <div class="send-egift-panel-recipients__subtitle">
      Have a file? Switch to
      <a
        role="button"
        tabindex="0"
        @keypress="showBulkOptions"
        @click="showBulkOptions"
      >
        Bulk Import
      </a>
    </div>

    <div
      v-if="showUnrestrictedGiftLinkMethod"
      class="send-egift-panel-recipients__unrestricted-gift-link"
    >
      <common-checkbox v-model="unrestrictedGiftLink">
        Unrestricted gift link (specify only number of recipients)

        <unrestricted-gift-link-tooltip />
      </common-checkbox>

      <template v-if="unrestrictedGiftLink">
        <common-input
          v-model.number="unrestrictedGiftLinkNumber"
          class="send-egift-panel-recipients__unrestricted-gift-link-input"
          type="number"
          min="1"
          suffix="Recipients"
          style="max-width: 140px"
        />

        <div class="send-egift-panel-recipients__subtitle">
          Enter the maximum number of recipients that can redeem this eGift.
          With this method, we will not validate recipients by name or email
        </div>
      </template>

      <v-divider v-else />
    </div>

    <send-egift-panel-recipients-manually
      v-if="showManuallyMethod"
      v-bind="{ ...$props }"
      v-on="$listeners"
      ref="recipients-manually"
    />

    <div class="send-egift-panel-recipients__actions">
      <common-button
        :height="44"
        :width="200"
        :disabled="disableContinueButton"
        @click="handleContinueButton"
      >
        Continue
      </common-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps, RecipientMethod, PanelType, LinkType } from '../../types'
import type { VuetifyForm } from '../../types';

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import recipientMethodProp from '../mixins/recipientMethodProp'

import SendEgiftPanelRecipientsManually from '../recipients/SendEgiftPanelRecipientsManually.vue'
import UnrestrictedGiftLinkTooltip from '@/components/myCampaign/panels/sendEgiftPanel/TheUnrestrictedGiftLinkCheckboxTooltip.vue'

@Component({
  components: {
    SendEgiftPanelRecipientsManually,
    UnrestrictedGiftLinkTooltip,
  },
})
export default class SendEgiftPanelRecipients extends Mixins(
  panelTypeProp,
  recipientsProp,
  recipientMethodProp,
) {
  get showManuallyMethod (): boolean {
    return this.recipientMethod === RecipientMethod.Manually
  }

  get showUnrestrictedGiftLinkMethod (): boolean {
    const { panelType, recipientMethod } = this

    if (panelType !== PanelType.Link) { return false }

    return [RecipientMethod.Manually, RecipientMethod.UnrestrictedLink].includes(recipientMethod)
  }

  get unrestrictedGiftLink (): boolean {
    return this.recipientMethod === RecipientMethod.UnrestrictedLink
  }

  set unrestrictedGiftLink (val: boolean) {
    this.$emit(
      'update:recipientMethod',
      val ? RecipientMethod.UnrestrictedLink : RecipientMethod.Manually
    )

    if (val) { setTimeout(() => { this.$el.querySelector('input')?.focus() }, 0) }
  }

  get unrestrictedGiftLinkNumber (): number {
    return this.singleGiftLinkRecipients
  }

  set unrestrictedGiftLinkNumber (val: number) {
    this.$emit('update:singleGiftLinkRecipients', val || 0)
  }

  get disableContinueButton (): boolean {
    const { unrestrictedGiftLink, unrestrictedGiftLinkNumber } = this

    return unrestrictedGiftLink && unrestrictedGiftLinkNumber <= 0
  }

  mounted () {
    const { recipientMethod } = this

    if (![RecipientMethod.UnrestrictedLink, RecipientMethod.Manually].includes(recipientMethod)) {
      this.$emit('update:recipientMethod', RecipientMethod.Manually)
    }
  }

  handleContinueButton (): void {
    const { unrestrictedGiftLink } = this

    if (unrestrictedGiftLink) {
      this.$emit('update:recipients', [])
      this.$emit('update:linkType', LinkType.UnrestrictedLink)
      this.$emit('update:step', RootSteps.Review)
    }

    const result = (this.$refs['recipients-manually'] as VuetifyForm)?.validate()

    if (result) {
      this.$emit('update:linkType', LinkType.StandardLink)
      this.$emit('update:step', RootSteps.Review)
    }
  }

  showBulkOptions (): void {
    this.$emit('update:step', RootSteps.RecipientBulkOptions)
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-recipients {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 45px;

  &__subtitle {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;

    & > a {
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      cursor: pointer;
      color: #42B77A !important;

      &:hover {
        color: #219358 !important;
      }
    }
  }

  &__unrestricted-gift-link {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__unrestricted-gift-link-input::v-deep {
    .v-input__slot .v-text-field__suffix {
      font-family: 'Lato-Italic', sans-serif;
      font-size: 15px;
      color: #a1a1a1;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
</style>
