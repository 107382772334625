import { createNamespacedHelpers } from 'vuex'

const { mapState, mapMutations } = createNamespacedHelpers('transactions-history')

export default {
  computed: {
    ...mapState([
      'filters',
      'filtersItems',
    ]),
    search: {
      get () { return this.filters.search },
      set (val) { this.setFiltersSearch(val) },
    },
    dateRange: {
      get () { return this.filters.dateRange },
      set (val) { this.setFiltersDateRange(val) },
    },
    types: {
      get () { return this.filters.types },
      set (val) { this.setFiltersTypes(val) },
    },
    userId: {
      get () { return this.filters.userId },
      set (val) { this.setFiltersUserId(val) },
    },
    orderId: {
      get () { return this.filters.orderId },
      set (val) { this.setFiltersOrderId(val) },
    },
    transactionTypes () {
      return this.filtersItems?.transactionTypes ?? []
    },
    users () {
      const { userId, filtersItems: { users } } = this
      const result = []

      if (userId) { result.push({ id: null, name: 'All' }) }
      result.push(...users)

      return result ?? []
    },
  },
  methods: {
    ...mapMutations([
      'setFiltersSearch',
      'setFiltersDateRange',
      'setFiltersTypes',
      'setFiltersUserId',
      'setFiltersOrderId',
    ])
  }
}