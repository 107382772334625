<template>
  <v-container v-if="info.length">
    <v-row class="mx-0 justify-space-between my-4 product-card pl-7" style="background-color: #fbfbfb">
      <v-col cols="12" class="d-flex flex-column product-card_product-info my-3 pa-0">
        <h3 class="f14 gray0 lato-bold mb-4">Approval request info</h3>

        <v-row no-gutters class="align-center mb-1" v-for="(item, i) in info" :key="i">
          <v-col cols="2" class="f14 lato-bold pr-6">
            {{ capitalizeFirstLetter(item.label) }}:
          </v-col>
          <v-col cols="6" class="f14">
            <v-img
              v-if="optionValueIsAFile(takeItemValue(item))"
              :src="getOptionFileLink(takeItemValue(item))"
              :height="150"
              :width="150"
              contain
              alt=""
            />

            <template v-else>{{ takeItemValue(item) }}</template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils'

export default {
  name: 'AdditionalInfo',
  props: {
    info: Array
  },
  methods: {
    capitalizeFirstLetter,
    takeItemValue (item) {
      return item.label.includes('date')
        ? this.$options.filters.dateFilter(item.value)
        : item.value
    },
    optionValueIsAFile (value) {
      return value?.startsWith('http') || value?.startsWith('media/') || value?.match(/^(\S*\.[^\\]+)$/)
    },
    getOptionFileLink (value) {
      const serverUrl = 'https://ecs-stage-cloudfront-stage-us-west-2.s3.us-west-2.amazonaws.com/'
      return value?.startsWith('http') ? value : `${serverUrl}${value}`
    },
  }
}
</script>
