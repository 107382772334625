<template>
  <div class="send-egift-panel-refund-policy">
    <template v-if="!loading">
      <span v-html="content" class="send-egift-panel-refund-policy__policy-text" />
    </template>

    <common-loader v-else style="padding: 100px 0" />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

import ApiCms from '@/axios/apiCMS'

interface IEgiftRefundPolicy {
  attributes: {
    title: string | null,
    text: string | null,
  }
}

@Component
export default class SendEgiftPanelRefundPolicy extends Vue {
  loading = false;

  title: string | null = null;

  content: string | null = null;

  mounted () {
    const { title, content } = this

    if (!title || !content) {
      this.loading = true

      ApiCms.get('/collections-egift-refund-policy?populate=*')
        .then(({ data }: { data: IEgiftRefundPolicy }) => {
          this.title = data?.attributes?.title
          this.content = data?.attributes?.text
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-refund-policy {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 40px;

  &__policy-text {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000000;

    &::v-deep > a {
      color: #42B77A !important;
      text-decoration: none !important;
    }
  }
}
</style>
