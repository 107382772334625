<template>
  <v-alert color="#FAE3E3" height="65" tile class="px-10 ma-0">
    <div class="d-flex align-center justify-space-between">
      <span class="f16 gray4 mr-5">
        <slot>
          You haven't saved your changes. Exit anyway?
        </slot>
      </span>
      <div>
        <common-button
          class="mr-3 lato-bold font-weight-bold f15"
          style="background-color: #fff;"
          height="36"
          @click="$emit('closePanel')"
          outlined
        >
          <slot name="exit">
            Exit
          </slot>
        </common-button>

        <common-button
          class="lato-bold font-weight-bold f15"
          height="36"
          @click="$emit('saveChanges')"
        >
          <slot name="save">
            Save
          </slot>
        </common-button>
      </div>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'UnsavedChangesAlert'
}
</script>
