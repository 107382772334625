<template>
  <div class="account-auth-page__container">
    <cg-loader v-if="loading" style="height: unset" />

    <div v-else class="account-auth-page__form">
      <h1>Log in with your single sign-on credentials</h1>

      <span>
        Enter your work email to login to your account via your company’s SSO.
      </span>

      <cg-form ref="form" @submit="onSubmit">
        <cg-input
          v-model="form.email"
          type="email"
          :validation="[required(), email()]"
          aria-autocomplete="none"
          placeholder="Enter your email address"
        >
          <template #label>Email address</template>
        </cg-input>

        <cg-button type="submit" :disabled="!form.email">Log in</cg-button>
      </cg-form>
    </div>
  </div>
</template>

<script>
import syncEmail from '@/mixins/auth/syncEmail'
import { signInWithSSOAction } from '@/services/authService'
import authRedirectModule from '@/mixins/auth/authRedirectModule'
import { required, email } from '@corporategift/design-system/validations'

import { CgButton, CgForm, CgInput, CgLoader } from '@corporategift/design-system'

export default {
  name: 'SSOPage',
  mixins: [
    syncEmail,
    authRedirectModule,
  ],
  components: {
    CgLoader,
    CgInput,
    CgButton,
    CgForm,
  },
  data: () => ({
    form: {
      email: '',
    },
    loading: false,
  }),
  methods: {
    required,
    email,
    onSubmit () {
      if (this.$refs.form.validate()) {
        const { form } = this

        this.loading = true
        signInWithSSOAction(form)
          .then(({ url }) => {
            window.location.href = url
          })
          .catch((e) => {
            this.$cgToast.error(e, { html: true })
            this.loading = false
          })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./src/css/auth-page.scss";
</style>
