<template>
  <div class="the-upload-logo__wrapper">
    <input
      type="file"
      accept=".jpg, .png"
      name="upload-file"
      id="upload-file"
      ref="file"
      @change="onChange"
    >

    <div v-if="selectedFile && !loadingLogo" class="the-upload-logo__options">
      <icons-close-circle-filled :width="21" :height="21" @click.native="removeFile()" />
    </div>

    <label
      for="upload-file"
      class="the-upload-logo__content"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <logo-placeholder-icon v-if="!selectedFile && !loadingLogo" />

      <common-loader v-else-if="loadingLogo" />

      <img v-else :alt="selectedFileName" :src="logoPreview" />
    </label>

    <div class="the-upload-logo__name">
      {{ selectedFile ? selectedFileName : 'Upload logo / artwork' }}
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'

import LogoPlaceholderIcon from './icons/TheLogoPlaceholderIcon.vue'

export default {
  name: 'TheUploadLogo',
  components: {
    LogoPlaceholderIcon,
  },
  props: {
    value: {
      type: [File, String],
      required: false,
      default: null
    }
  },
  data: () => ({
    logoPreview: null,
    loadingLogo: false,
  }),
  computed: {
    selectedFile: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    selectedFileName () {
      return this.selectedFile?.name || 'Image file'
    }
  },
  watch: {
    selectedFile: {
      immediate: true,
      handler: function (val) {
        if (val && typeof val === 'object') {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            const { result } = reader
            this.logoPreview = result
          })
          reader.readAsDataURL(val)
          this.loadingLogo = true
          const formData = new FormData()
          formData.append('logo', val, val?.name)
          Api.post('/campaigns/send-egift/set-tmp-picture', formData)
            .then(({ data }) => {
              this.$emit('update:previewLogo', data?.url || null)
            })
            .catch(({ response: { data } }) => {
              const message = data?.message || 'The logo failed to upload.'
              this.$cgToast.error(message)
              this.removeFile()
            })
            .finally(() => (this.loadingLogo = false))
        }

        if (val && typeof val === 'string') {
          this.logoPreview = val
          this.$emit('update:previewLogo', val || null)
        }
      }
    },
  },
  methods: {
    removeFile () {
      this.selectedFile = null
      this.logoPreview = null
      this.$refs.file.value = null
    },
    onChange () {
      const file = this.$refs.file.files[0]
      if (['image/jpeg', 'image/png'].includes(file?.type)) this.selectedFile = file
    },
    dragover (event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('the-upload-logo__content--file-hover')) {
        event.currentTarget.classList.add('the-upload-logo__content--file-hover')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('the-upload-logo__content--file-hover')
    },
    drop (event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('the-upload-logo__content--file-hover')
    },
  },
}
</script>

<style lang="scss" scoped>
.the-upload-logo {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 200px;
    max-width: 200px;
    min-height: 240px;
    max-height: 240px;

    &:hover {
      & > .the-upload-logo__options {
        height: 38px;
        padding: 8px;
      }
    }

    & > input {
      width: 1px;
      height: 1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
    }
  }

  &__options {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 0 8px;
    background: rgba(255, 255, 255, 0.8);
    transition: height ease-in-out 0.08s;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    z-index: 1;

    & > svg {
      cursor: pointer;
    }
  }

  &__content {
    min-width: 200px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1.4px dashed #95979D;
    background-color: #FAFAFA;
    transition: box-shadow ease-in-out 0.3s;

    & > img {
      min-width: 200px;
      max-width: 200px;
      min-height: 200px;
      max-height: 200px;
      object-fit: contain;
      background-color: #fff;
      border: 1px solid #D3D2D2;
    }

    &:has(img),
    &:has(.common-loader) {
      pointer-events: none;
      cursor: default;
      border-color: #fff;
    }

    &:hover,
    &--file-hover {
      border-style: solid;
      border-width: 3px;
      border-color: #A7DAD8;
      box-shadow: 0px 0px 26.0811px rgba(0, 0, 0, 0.15);
    }
  }

  &__name {
    color: #62646A;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 25px;
    text-align: center;
  }
}
</style>
