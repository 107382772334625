<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 18"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.5"
    >
      <g
        id="E-gift-large-line-in-table"
        transform="translate(-1092.000000, -25.000000)"
        fill="#000000"
        fill-rule="nonzero"
      >
        <g transform="translate(0.000000, 2.000000)" id="Group">
          <g transform="translate(900.000000, 23.000000)">
            <g transform="translate(192.000000, 0.250000)" id="Link">
              <path
                d="M15.6953372,1.37673514 C13.9548129,-0.458911714 11.1331237,-0.458911714
                  9.39308912,1.37673514 L6.90644877,3.99893039 C7.10330018,3.97634247
                  7.30220791,3.96663243 7.50212748,3.96663243 C8.13142531,3.96663243
                  8.74335869,4.07210008 9.32268479,4.27542575 L10.7317505,2.78947838
                  C11.2157028,2.27839237 11.8592969,1.99755848 12.5436909,1.99755848
                  C13.2281502,1.99755848 13.8717443,2.27839237 14.3556966,2.78947838
                  C14.8396814,3.29949698 15.1057948,3.97737546 15.1057948,4.70042977
                  C15.1057948,5.42241666 14.8396814,6.10136256 14.3556966,6.61138115
                  L11.598765,9.51978181 C11.1148454,10.0309023 10.4712186,10.3116673
                  9.78682458,10.3116673 C9.10138607,10.3116673 8.45883643,10.0309023
                  7.97439461,9.51978181 C7.73876696,9.27227886 7.5552,8.98390419
                  7.42872028,8.66970491 C7.11456096,8.68798873 6.82083424,8.82571992
                  6.59643473,9.06241096 L5.86206897,9.83814745 C6.06300037,10.2308535
                  6.32105165,10.6010061 6.63521096,10.933558 C8.37524557,12.7692738
                  11.1974244,12.7692738 12.9379486,10.933558 L15.6953698,8.02408996
                  C17.4348822,6.1885464 17.4348822,3.212382 15.6953372,1.37673514 Z
                "
                id="Path"
              />
              <path
                d="M9.52650632,13.0325459 C8.89623119,13.0325459 8.27822738,12.9249533
                  7.68574527,12.7130046 L6.26713669,14.2096257 C5.7837256,14.7206989
                  5.14023271,15.0014725 4.45591163,15.0014725 C3.77159055,15.0014725
                  3.12910939,14.7206989 2.64465393,14.2096257 C2.1602311,13.6996197
                  1.89404822,13.0208054 1.89404822,12.2988507 C1.89404822,11.576896
                  2.1602311,10.8979783 2.64465393,10.3870083 L5.40129168,7.47891053
                  C5.8857145,6.96893901 6.52822829,6.68813103 7.21254938,6.68813103
                  C7.89788219,6.68813103 8.54039598,6.96893901 9.02426399,7.47891053
                  C9.25989916,7.72742352 9.44387083,8.01577159 9.57092453,8.32990743
                  C9.88606209,8.31269262 10.1802797,8.17390683 10.4046553,7.93723762
                  L11.137931,7.16260562 C10.937021,6.76879963 10.6784751,6.39871565
                  10.3638597,6.06626325 C8.62401057,4.23078581 5.80213255,4.23078581
                  4.06228341,6.06626325 L1.30564566,8.9754628 C-0.43521522,10.8110091
                  -0.43521522,13.7867955 1.30564566,15.6233403 C3.04549481,17.4588866
                  5.86639372,17.4588866 7.60621024,15.6233403 L10.0884733,13.0046579
                  C9.90339204,13.0228711 9.71625464,13.0326491 9.52755068,13.0326491
                  L9.52650632,13.0325459 L9.52650632,13.0325459 Z
                "
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Link',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 19
    },
    height: {
      type: [Number, String],
      required: false,
      default: 19
    }
  }
}
</script>
