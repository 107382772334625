export default function getOrderApprovalStatus (status) {
  switch (status) {
    case 'declined':
      return 'Declined';
    case 'approved':
      return 'Approved';
    case 'auto_approved':
      return 'Auto-approved';
    default:
      return null;
  }
}
