var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popper',{ref:"popper",attrs:{"options":{
    placement: 'top',
    modifiers: {
      offset: {
        offset: '0,6px',
      },
      preventOverflow: {
        padding: {
          left: _vm.getSidebarWitdth(),
          altAxis: true,
        },
      },
      rootBoundary: 'main',
    }
  }},on:{"show":_vm.onPopperShow}},[(_vm.variants.length <= 1)?_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"min-width":"261px","z-index":"5"},style:(!_vm.productHasEmptyInventory && 'display: none')},[(_vm.productHasEmptyInventory)?[_vm._v(" Out of stock. "),_c('br'),_vm._v(" We recommend refilling stock 2-4 weeks before estimated expiry to allow for production, customization and shipping. ")]:_vm._e()],2):_c('tray-variants-tooltip',{attrs:{"variants":_vm.variants}}),_c('tray-badge',{class:{ 'pointer': _vm.variants.length > 1 },attrs:{"slot":"reference","color":_vm.productHasEmptyInventory ? 'red' : 'green'},slot:"reference"},[_vm._v(" "+_vm._s(_vm._f("maxZeroFilter")(_vm.qty))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }