
export default {
  showSnackbar ({ commit }, { message, type, timeout }) {
    commit('setShow', true)
    commit('setMessage', message)
    commit('setType', type || 'success')
    commit('setTimeout', timeout || 3000)
  },
  clearSnackbar ({ commit }) {
    commit('resetSnackbar')
  }
}
