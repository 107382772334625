<template>
  <div class="send-egift-panel-recipients-manually">

    <v-form ref="form">
      <table class="send-egift-panel-recipients-manually__table">
        <thead>
          <tr>
            <th></th>
            <th>First name</th>
            <th>Last name</th>
            <th>Email <template v-if="isSelectedGiftLink">(Optional)</template></th>
            <th>Send date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(recipient, index) in egiftRecipients" :key="index">
            <td>{{ index + 1 }}</td>
            <!-- First name -->
            <td>
              <common-input
                v-model="recipient.firstName"
                :rules="firstNameValidation"
                :height="40"
              />
            </td>
            <!-- Last name -->
            <td>
              <common-input
                v-model="recipient.lastName"
                :rules="lastNameValidation"
                :height="40"
              />
            </td>
            <!-- Email -->
            <td>
              <common-input
                v-model="recipient.email"
                :rules="emailValidation"
                type="email"
                :error="isEmailDuplicated(recipient.email)"
                :height="40"
              />
            </td>
            <!-- Send date -->
            <td>
              <single-date-picker
                v-model="recipient.sendDate"
                :id="`recipient-send-date-${index}`"
                style="height: 40px"
                @change="(val) => handleSendDateChange(val)"
                picker-style="--picker-margin-top: 3px;"
                show-checkbox
                :height="40"
                :checkboxValue.sync="setSendDateForAllRecipients"
              />
            </td>
            <!-- Options -->
            <td>
              <icons-delete
                @click.native="deleteRecipient(index)"
                @keypress.native="deleteRecipient(index)"
                :tabindex="egiftRecipients.length <= 1 ? -1 : 0"
                :disabled="egiftRecipients.length <= 1"
                :width="14"
                :height="17"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>

    <div
      class="send-egift-panel-recipients-manually__link"
      tabindex="0"
      @keypress="addNewRecipient()"
      @click="addNewRecipient()"
    >
      + Add another recipient
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Component, Watch } from 'vue-property-decorator'
import type { IRecipient, VuetifyForm } from '../../types';

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import takeCurrentDate from '../mixins/takeCurrentDate'
import recipientValidations from '../mixins/recipientValidations'

import SingleDatePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

@Component({
  components: {
    SingleDatePicker,
  },
})
export default class SendEgiftPanelRecipientsManually extends Mixins(
  panelTypeProp,
  recipientsProp,
  takeCurrentDate,
  recipientValidations,
) {
  setSendDateForAllRecipients = false

  sendDateForNewRecipient: string | null = null

  get egiftRecipients (): Array<IRecipient> {
    return this.recipients
  }

  set egiftRecipients (val: Array<IRecipient>) {
    this.$emit('update:recipients', val)
  }

  get isEveryEmailUnique (): boolean {
    const { egiftRecipients } = this

    const emails = egiftRecipients?.map((recipient) => recipient.email).filter((email) => email) ?? []

    return new Set(emails).size === emails.length
  }

  @Watch('setSendDateForAllRecipients')
  onValueChange (val: boolean) {
    if (!val) { this.sendDateForNewRecipient = null }
  }

  created () {
    if (!this.egiftRecipients.length) {
      this.addNewRecipient();
    }
  }

  addNewRecipient () {
    this.egiftRecipients.push({
      firstName: null,
      lastName: null,
      email: null,
      sendDate: this.sendDateForNewRecipient || this.takeCurrentDate(),
    })
  }

  deleteRecipient (index: number): void {
    if (this.egiftRecipients.length <= 1) { return }

    this.egiftRecipients.splice(index, 1)
  }

  handleSendDateChange (sendDate: string): void {
    const { setSendDateForAllRecipients, egiftRecipients } = this
    if (setSendDateForAllRecipients) {
      this.sendDateForNewRecipient = sendDate

      egiftRecipients.forEach((recipient) => {
        recipient.sendDate = sendDate
      })
    }
  }

  isEmailDuplicated (emailToCheck: string): boolean {
    const { egiftRecipients } = this

    if (!emailToCheck) return false

    const emails = egiftRecipients?.map((recipient) => recipient.email) ?? []
    return emails.filter((email) => email === emailToCheck).length !== 1
  }

  validate (): boolean {
    if (!this.isEveryEmailUnique) { return false }

    return (this.$refs['form'] as VuetifyForm)?.validate() ?? false;
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-recipients-manually {
  display: flex;
  flex-direction: column;
  gap: 45px;

  &__table {
    border: none;

    & > thead > tr > th,
    & > tbody > tr > td {
      padding: 0 7px;

      &:first-child,
      &:last-child {
        min-width: 14px;
        width: 14px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        min-width: 145px;
        width: 145px;
        max-width: 145px;
      }

      &:nth-child(4) {
        min-width: 200px;
        width: 100%;
      }
    }

    & > thead > tr > th {
      text-align: start;
      font-family: 'Lato-Bold', sans-serif;
      background: #fff;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      padding-bottom: 10px;
      color: #222325;
    }

    & > tbody > tr {
      background: #fff;
      cursor: default;
      position: relative;

      &:hover {
        background: #fff !important;

        & > td {
          background: #fff !important;
        }
      }

      & > td {
        padding-bottom: 30px;

        &:first-child {
          color: #000;
          font-family: 'Lato-Bold', sans-serif;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
        }

        &:last-child > svg {
          cursor: pointer;

          &:focus {
            outline: none;
            opacity: 0.6;
          }

          &[disabled] {
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }
  }

  &__link {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 0 1 auto;
    width: fit-content;

    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #42B77A;

    &:hover {
      color: #219358;
    }
  }
}
</style>
