<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 30"
    fill="none"
    :width="width"
    :height="height"
  >
    <g clip-path="url(#clip0_4911_72426)">
      <path
        d="M25.5003 10H22.167V11.6667H25.5003V13.3333H27.167V11.6667C27.167 11.2246 26.9914 10.8007 26.6788 10.4882C26.3663 10.1756 25.9424 10 25.5003 10Z"
        :fill="color"
      />
      <path d="M25.5 15H27.1667V20H25.5V15Z" :fill="color"/>
      <path
        d="M25.4997 25H23.833V26.6666H25.4997C25.9417 26.6666 26.3656 26.491 26.6782 26.1785C26.9907 25.8659 27.1663 25.442 27.1663 25V21.6666H25.4997V25Z"
        :fill="color"
      />
      <path
        d="M20.4997 18.3334V5.00004C20.4997 4.55801 20.3241 4.13409 20.0115 3.82153C19.699 3.50897 19.275 3.33337 18.833 3.33337H5.49967C5.05765 3.33337 4.63372 3.50897 4.32116 3.82153C4.0086 4.13409 3.83301 4.55801 3.83301 5.00004V18.3334C3.83301 18.7754 4.0086 19.1993 4.32116 19.5119C4.63372 19.8244 5.05765 20 5.49967 20H18.833C19.275 20 19.699 19.8244 20.0115 19.5119C20.3241 19.1993 20.4997 18.7754 20.4997 18.3334ZM5.49967 5.00004H18.833V18.3334H5.49967V5.00004Z"
        :fill="color"
      />
      <path d="M17.167 25H22.167V26.6667H17.167V25Z" :fill="color"/>
      <path
        d="M12.1667 21.6666H10.5V25C10.5 25.442 10.6756 25.8659 10.9882 26.1785C11.3007 26.491 11.7246 26.6666 12.1667 26.6666H15.5V25H12.1667V21.6666Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_4911_72426">
        <rect width="30" height="30" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CopyPaste',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 30
    },
    height: {
      type: [Number, String],
      required: false,
      default: 30
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    }
  },
}
</script>
