<template>
  <div class="available-credits g20">
    {{ showOnHold ? 'Total on-hold credits:' : 'Total Available Credits:' }}

    <span>
      {{
        showOnHold
          ? summary.on_hold
          : summary.balance
        | priceFilter({
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 4
        })
      }}
    </span>
  </div>
</template>

<script>
import { entitesSummary } from './mixins'

export default {
  name: 'TheTableTotalInfo',
  mixins: [
    entitesSummary
  ],
  props: {
    showOnHold: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.available-credits {
  font-family: 'Lato-Bold', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #919191;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;

  span {
    font-family: 'Lato-Light', sans-serif;
    font-size: 25px;
    line-height: 30px;
    color: #16181F;
  }
}
</style>
