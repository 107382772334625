<template>
  <review-field
    ref="review-field"
    :editable="userCanOverrideSettings"
    :disabled="!userCanOverrideSettings"
    :edit-mode-as-column="true"
    :allow-to-click-label-in-edit-mode="true"
    @onLabelClick="({ value }) => inEditMode = value"
  >
    <template #label>
      Recipient settings

      <review-save-cancel-icons
        v-if="inEditMode"
        class="review-fields-settings__options"
        @onSave="handleOnSave"
        @onCancel="handleOnCancel"
      />
    </template>

    <template #review-mode>
      <div class="review-fields-settings__info">
        <div v-for="(settings, index) in giftSettingsData" :key="index">
          <icons-check-circle
            v-if="settings.value"
            :width="17"
            :height="17"
            outline-color="#42B77A"
            outline-check-color="#42B77A"
            :check-stroke-width="2"
            :outline-stroke-width="1"
          />

          <icons-minus-circle
            v-else
            :width="17"
            :height="17"
            color="#FF5A60"
          />

          {{ settings.name }}
        </div>
      </div>
    </template>

    <template #edit-mode>
      <div class="review-fields-settings__edit-mode">
        <common-checkbox v-model="canUpgradeHelper" :disabled="!canOverrideUpgrade">
          Recipient can exchange gift
        </common-checkbox>

        <common-checkbox v-model="canReEgiftHelper" :disabled="!canOverrideReEgift">
          Recipient can Re-gift
        </common-checkbox>
      </div>
    </template>
  </review-field>
</template>

<script lang="ts">
import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import type { IReviewField, ICampaignSettings } from '../../types';

import campaignProp from '../mixins/campaignProp'

import ReviewField from './ReviewField.vue'
import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'

@Component({
  components: {
    ReviewField,
    ReviewSaveCancelIcons,
  },
})
export default class ReviewFieldsSettings extends Mixins(campaignProp) {
  @Prop({ required: true, default: false }) canUpgrade!: boolean;

  @Prop({ required: true, default: false }) canReEgift!: boolean;

  inEditMode = false;

  canUpgradeHelper = false;

  canReEgiftHelper = false;

  get giftSettingsData (): Array<{ name: string, value: boolean }> {
    return [
      {
        name: 'Exchange',
        value: this.canUpgrade,
      },
      {
        name: 'Regift',
        value: this.canReEgift,
      },
    ]
  }

  get campaignSettings(): ICampaignSettings {
    return this.campaign?.egift_settings ?? {}
  }

  get canOverrideReEgift (): boolean {
    return this.campaignSettings.can_override_is_regift_enabled ?? false
  }

  get canOverrideUpgrade (): boolean {
    return this.campaignSettings.can_override_is_upgrade_enabled ?? false
  }

  get userCanOverrideSettings (): boolean {
    const { canOverrideReEgift, canOverrideUpgrade } = this

    return [canOverrideReEgift, canOverrideUpgrade].includes(true)
  }

  @Watch('inEditMode', { immediate: false })
  onEditModeChange (newVal: boolean) {
    if (newVal) {
      this.canReEgiftHelper = this.canReEgift;
      this.canUpgradeHelper = this.canUpgrade;
    }
  }

  handleOnSave (): void {
    this.$emit('update:canUpgrade', this.canUpgradeHelper)
    this.$emit('update:canReEgift', this.canReEgiftHelper)

    this.handleOnCancel()
  }

  handleOnCancel (): void {
    const refElement = this.$refs?.['review-field']

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
</script>

<style lang="scss" scoped>
.review-fields-settings {
  &__info {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;

      font-family: 'Lato-Regular', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
    }
  }

  &__options {
    margin-top: 0;
    margin-left: auto;
    pointer-events: all;
  }

  &__edit-mode {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
