<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.78405 13.2673C9.38557 13.2673 9.06284 13.5901 9.06284 13.9886C9.06284 14.387 9.38557
        14.7105 9.78405 14.7105C10.1825 14.7105 10.5053 14.387 10.5053 13.9886C10.5053 13.5901
        10.1825 13.2673 9.78405 13.2673ZM9.78405 5.69083C8.29617 5.69083 7.07799 6.90901 7.07799
        8.39689C7.0742 8.69537 7.31284 8.94159 7.61208 8.94537C7.91057 8.94992 8.15602 8.71128
        8.16057 8.41204C8.16057 8.40674 8.16057 8.40219 8.16057 8.39689C8.16057 7.49386 8.88102
        6.7734 9.78405 6.7734C10.6871 6.7734 11.4075 7.49386 11.4075 8.39689C11.4075 8.95825
        11.181 9.34159 10.8098 9.73249C10.4386 10.1242 9.9242 10.4779 9.44617 10.8605C9.31738
        10.9628 9.24314 11.1189 9.24314 11.2833V12.0045C9.2386 12.3037 9.47723 12.5492 9.77647
        12.5537C10.075 12.5575 10.3212 12.3189 10.325 12.0196C10.325 12.0151 10.325 12.0098 10.325
        12.0045V11.5476C10.7197 11.2469 11.1825 10.9158 11.5992 10.4772C12.0848 9.96507 12.4901
        9.27492 12.4901 8.39689C12.4901 6.90901 11.2719 5.69083 9.78405 5.69083ZM9.78405
        3.16583C13.6757 3.16583 16.8189 6.30901 16.8189 10.2007C16.8189 14.0923 13.6757 17.2355
        9.78405 17.2355C5.89238 17.2355 2.7492 14.0923 2.7492 10.2007C2.7492 6.30901 5.89238
        3.16583 9.78405 3.16583ZM9.78405 2.08325C5.30753 2.08325 1.66663 5.72416 1.66663
        10.2007C1.66663 14.6772 5.30753 18.3181 9.78405 18.3181C14.2606 18.3181 17.9015 14.6772
        17.9015 10.2007C17.9015 5.72416 14.2606 2.08325 9.78405 2.08325Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Question',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24
    },
    color: {
      type: String,
      required: false,
      default: '#42B77A'
    }
  }
}
</script>
