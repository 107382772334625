<template>
  <v-container>
    <common-loader v-if="loading" style="padding: 300px 0" />

    <v-row v-else class="flex-column">
      <v-col cols="12" class="f40 lato-light font-weight-light auto-reload-title pt-6">
        Payment info
      </v-col>

      <v-col cols="12" class="lato-bold f16 black1 pt-6">
        My saved credit cards:
      </v-col>

      <v-col cols="12">
        <span class="f14 green2 pointer" @click="() => showAddCreditCardPanel = true">
          + Add new credit card
        </span>
      </v-col>

      <v-col cols="6" xl="4" class="pr-0">
        <div class="row flex-column no-gutters g10">
          <credit-card-item
            v-for="card in userCreditCards"
            :key="card.token"
            :data="card"
            @updateDefaultCard="updateDefaultCardInfo"
            @deletePaymentMethod="setItemToDeleteAndShowModal"
          />
        </div>
      </v-col>

      <template>
        <v-col cols="6" xl="4" class="pr-0 pt-10 pb-0">
          <v-divider />
        </v-col>

        <v-col cols="12" class="lato-bold f16 black-10 pt-4">
          My saved ACH:
        </v-col>

        <v-col cols="12">
          <span class="f14 green2 pointer" @click="() => showAddAchPanel = true">
            + Add new ACH account
          </span>
        </v-col>

        <v-col cols="6" xl="4" class="pr-0">
          <div class="row flex-column no-gutters g10">
            <ach-item
              v-for="ach in userAchAccounts"
              :key="ach.token"
              :data="ach"
              @deletePaymentMethod="setItemToDeleteAndShowModal"
            />
          </div>
        </v-col>
      </template>
    </v-row>

    <add-credit-card-panel
      v-model="showAddCreditCardPanel"
      :force-save-as-default="!userCreditCards.length"
      @onAddCard="fetchData"
    />

    <add-ach-panel
      v-model="showAddAchPanel"
      @onAddAch="fetchData"
    />

    <the-delete-card-ach-modal
      v-model="showDeleteModal"
      :selectedItem="selectedItem"
      @onACHRemoved="removeAch"
      @onCardRemoved="removeCard"
      @onBluesnapCardRemoved="removeBluesnapCard"
      @onBluesnapACHRemoved="removeBluesnapAch"
    />
  </v-container>
</template>

<script>
import Api from '@/axios/api'

import AchItem from '@/components/payment/AchItem.vue'
import CreditCardItem from '@/components/payment/CreditCardItem.vue'
import TheDeleteCardAchModal from '@/components/paymentInfo/TheDeleteCardAchModal'
import AddCreditCardPanel from '@/components/payment/AddCreditCardPanel.vue'
import AddAchPanel from '@/components/payment/AddAchPanel.vue'

import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'

export default {
  name: 'PaymentInfo',
  mixins: [userIsUsingBlueSnap],
  components: {
    TheDeleteCardAchModal,
    AddCreditCardPanel,
    CreditCardItem,
    AchItem,
    AddAchPanel,
  },
  data: () => ({
    loading: false,
    abortController: null,
    userCreditCards: [],
    userAchAccounts: [],
    showAddCreditCardPanel: false,
    showAddAchPanel: false,
    showDeleteModal: false,
    selectedItem: null,
  }),
  created () {
    this.fetchData()
  },
  watch: {
    userIsUsingBlueSnap: 'fetchData'
  },
  methods: {
    fetchData () {
      const { userIsUsingBlueSnap, abortController } = this
      if (abortController) {
        abortController?.abort()        
        this.abortController = null
      }

      const gatewayUrl = userIsUsingBlueSnap ? '/bluesnap/saved-sources' : '/braintree/saved-sources'
      this.loading = true
      this.abortController = new AbortController();

      Api.get(gatewayUrl, { signal: this.abortController.signal })
        .then((response) => {
          this.loading = false
          this.userCreditCards = response?.ccSource || []
          this.userAchAccounts = response?.achSource || []
        })
        .catch(() => (this.loading = false))
        .finally(() => (this.abortController = null))
    },
    updateDefaultCardInfo ({ cardType, cardLastFourDigits }) {
      this.userCreditCards = this.userCreditCards.filter((card) => {
        card.isDefault =
          (card.cardType === cardType && card.cardLastFourDigits === cardLastFourDigits) || false
        return card
      })
    },
    setItemToDeleteAndShowModal (selectedItem) {
      this.selectedItem = selectedItem
      this.showDeleteModal = true
    },
    removeCard ({ token, isDefault }) {
      this.userCreditCards = this.userCreditCards.filter((userCard) => userCard.token !== token)
      // set last card as default if removing default card
      if (isDefault) { this.updateDefaultCardInfo(this.userCreditCards?.at(-1)) }
    },
    removeAch ({ token }) {
      this.userAchAccounts = this.userAchAccounts.filter((userAch) => userAch.token !== token)
    },
    //#region bluesnap
    removeBluesnapCard ({ cardLastFourDigits, is_default: isDefault }) {
      this.userCreditCards = this.userCreditCards
        .filter((userCard) => userCard.cardLastFourDigits !== cardLastFourDigits)
      // set last card as default if removing default card
      if (isDefault) { this.updateDefaultCardInfo(this.userCreditCards?.at(-1)) }
    },
    removeBluesnapAch ({ publicRoutingNumber, publicAccountNumber }) {
      this.userAchAccounts = this.userAchAccounts
        .filter((userAch) => userAch.publicRoutingNumber !== publicRoutingNumber
          && userAch.publicAccountNumber !== publicAccountNumber)
    },
    //#endregion
  }
}
</script>

<style lang="scss" scoped>
.payment-info-card {
  background-color: #F5F5F5;
}
</style>
