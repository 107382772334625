<template>
  <v-sheet>
    <common-loader v-if="loading" style="padding: 200px 0" />
    <v-data-table
      v-else
      :headers="headers"
      :items="items"
      show-expand
      item-key="id"
      show-select
      @click:row="handleRowClick"
      v-model="selected"
      :custom-sort="customSort"
      :items-per-page="items.length"
      hide-default-footer
      @toggle-select-all="selectAll"
      class="e-cg-data-table mt-1 v-data-table__fixed-selected"
    >
      <template #item.customer_name="{ item }">
        <div class="d-flex flex-column g10 h100 py-3">
          {{item.customer_name}}

          <update-status-sheet
            v-if="showApprovalBadge(item)"
            :color="getApprovalBadgeColors(item).color"
            :text-color="getApprovalBadgeColors(item).textColor"
            v-tooltip="{
              content: getApprovalOrderTooltip(item),
              ...standardTooltipProps,
              trigger: getApprovalStatus(item) === 'Auto-approved' ? 'manual' : 'hover',
            }"
          >
            {{ getApprovalStatus(item) }}
          </update-status-sheet>

          <update-status-sheet
            v-if="item.is_automated"
            color="#E5DFFF"
            text-color="#4E3AAF"
          >
            Automated
          </update-status-sheet>
        </div>
      </template>
      <template #item.number="{ item }">
        <div class="d-flex h100 py-3">
          {{item.number}}
        </div>
      </template>
      <template #item.name="{ item }">
        <div class="break-word flex-column d-flex h100 py-3 g10">
          {{item.name}}

          <update-status-sheet
            v-if="item.is_pre_production"
            color="#ECD884"
            text-color="#222325"
            v-tooltip="{
              content: `Items will be ordered and produced once all eGift recipients have made their selections,
                or when the campaign ends. Track redemptions and details through the &quot;My eGift orders&quot;
                on your dashboard`,
              ...standardTooltipProps,
            }"
          >
            Zero waste
          </update-status-sheet>
        </div>
      </template>
      <template #item.description="{ item }">
        <div class="d-flex h100 py-3" v-if="item.campaign">
          {{ item.campaign }}
        </div>
      </template>
      <template #item.date="{ item }">
        <div class="d-flex h100 py-3">
          <div class="d-flex flex-column mr-2">
            <b>Created</b>
            <b>Send</b>
            <b>Expiration</b>
          </div>
          <dl class="d-flex flex-column">
            <dd>{{item.created_at | date}}</dd>
            <dd>{{item.delivered_at | date}}</dd>
            <dd>{{item.expires_at | date}}</dd>
          </dl>
        </div>
      </template>
      <template #item.costs="{ item }">
        <div class="d-flex justify-space-between h100 py-3">
          <div class="d-flex flex-column mr-2" style="max-width: 100px">
            <b>Est.</b>
            <b>Actual</b>
            <b v-if="item.refunded" class="d-flex align-center" style="gap: 8px; height: 20px">
              Refunded
              <common-tooltip top :max-width="250" :activator-size="18">
                Amount refunded to {{ item.refunded_to === 'cg_credits' ? 'CG Credits' : 'credit card' }}
              </common-tooltip>
            </b>
          </div>
          <div class="d-flex flex-column text-right">
            <span>${{item.estimated | numberWithSeparator(',')}}</span>
            <span>${{item.total | numberWithSeparator(',')}}</span>
            <span v-if="item.refunded">${{item.refunded | numberWithSeparator(',')}}</span>
          </div>
        </div>
      </template>
      <template #item.status="{ item }">
        <div class="d-flex h100 py-3">
          <div class="d-flex">
            <div class="d-flex" style="word-break: break-word;">
              <span v-if="getApprovalStatus(item) === 'Declined'">
                <update-status-sheet
                  :color="getApprovalBadgeColors(item).color"
                  :text-color="getApprovalBadgeColors(item).textColor"
                >
                  Declined
                </update-status-sheet>
              </span>

              <span v-else>
                <template v-if="['Closed', 'Expired'].includes(item.status)">
                  <update-status-sheet
                    :outlined="item.status === 'Expired'"
                    class="mt-1"
                    :color="item.state === 'Expired' ? '#FA5E5E' : '#A7DAD8'"
                    :text-color="item.state === 'Expired' ? '#FA5E5E' : '#004642'"
                  >
                    {{ item.status.toUpperCase() }}
                  </update-status-sheet>
                </template>
                <template v-else>
                  {{ item.status !== 'Started checkout' ? item.status : 'Partially redeemed' }}
                </template>
                <span
                  v-if="item.recipients_count > 1 && [
                    'Complete redeemed',
                    'Started checkout',
                    'Partially opened'
                  ].includes(item.status)"
                >
                  <span
                    :class="item.recipients_count !== item.recipients_redeem_count ?
                      item.status === 'Started checkout' ?
                      'status-green' :
                      'status-red' :
                    ''"
                  >{{ item.recipients_redeem_count }}</span>/{{ item.recipients_count }}
                </span>

                <template v-if="['Closed', 'Expired'].includes(item.state)">
                  <update-status-sheet
                    :outlined="item.state === 'Expired'"
                    class="mt-1"
                    :color="item.state === 'Expired' ? '#FA5E5E' : '#A7DAD8'"
                    :text-color="item.state === 'Expired' ? '#FA5E5E' : '#004642'"
                  >
                    {{ item.state.toUpperCase() }}
                  </update-status-sheet>
                </template>
              </span>
            </div>
            <template v-if="getApprovalStatus(item) !== 'Declined'">
              <!-- If state !== 'Closed' && !== 'Canceled'  -->
              <v-tooltip
                top
                z-index="4"
                content-class="v-tooltip__content--bottom e-gift-order-status elevation-3"
                v-if="!['Canceled', 'Closed', 'Expired'].includes(item.state)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    class="ml-2"
                    src="/images/question-icon.svg"
                    max-width="20"
                    height="21"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <div class="d-flex flex-column">
                  <tooltip
                    v-if="!['Not Paid'].includes(item.status)"
                    :item="item"
                  />
                  <v-divider v-if="!['Not Paid'].includes(item.status)"/>

                  <span
                    v-if="item.is_generate_links !== 1"
                    class="break-word px-2 pt-1"
                  >
                    {{
                      tooltips.filter(tip => tip.status === item.status)[0] !== undefined
                        ? tooltips.filter(tip => tip.status === item.status)[0].tooltip
                        : ''
                    }}
                  </span>
                  <span
                    v-else
                    class="break-word"
                  >
                    The sender has chosen to use a dedicated link outside CorporateGift’s emailing system
                  </span>
                </div>
              </v-tooltip>
              <!-- If state === 'Closed' || 'Expired -->
              <v-tooltip
                top
                z-index="4"
                content-class="v-tooltip__content--bottom e-gift-order-status elevation-3"
                v-if="['Closed', 'Expired'].includes(item.state)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-img
                    class="ml-2"
                    src="/images/question-icon.svg"
                    max-width="20"
                    height="21"
                    style="margin-right: -28px"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <div class="pa-6 text-left" style="min-width: 400px; max-width: 400px;">
                  <div class="d-flex align-end">
                    <div class="d-block close-btn mr-16 text-center">
                      <v-img
                        class="mb-4"
                        src="/images/cancelled-stage.svg"
                        width="40"
                        height="40"
                      />
                      <span class="f10 lato-bold text-uppercase">
                        {{ item.state }}
                      </span>
                    </div>
                    <div>
                      <dl class="d-flex align-center mb-5">
                        <dt class="f15 mr-4 close-order__list">Recipients redeemed:</dt>
                        <dd class="f15 lato-bold font-weight-bold">{{ item.recipients_redeem_count }}</dd>
                      </dl>
                      <dl class="d-flex align-center mb-5">
                        <dt class="f15 mr-4 close-order__list">Recipients canceled:</dt>
                        <dd class="f15 lato-bold font-weight-bold">{{ item.recipients_canceled_count }}</dd>
                      </dl>
                      <dl v-if="item.refunded" class="d-flex align-center">
                        <dt class="f15 mr-4 close-order__list">Credits refunded:</dt>
                        <dd class="f15 lato-bold font-weight-bold">{{ Number(item.refunded) | priceFilter }}</dd>
                      </dl>
                    </div>
                  </div>

                  <v-divider class="my-5" />

                  <span class="gray5">
                    {{ `E-Gift ${ item.state === 'Closed' ? 'closed' : 'expired' } on ${getClosedDate(item.closed_at)}` }}
                  </span>
                </div>
              </v-tooltip>
            </template>
          </div>
        </div>
      </template>
      <template v-slot:item.data-table-expand="{ item }">
        <div class="h100 d-flex text-right justify-space-between align-center">
          <div>
            <popper
              :options="{
                placement: 'bottom',
                modifiers: { offset: { offset: '0,10px' } }
              }">
              <div class="popper popper-hint">
                <span class="px-2">Preview E-Gift</span>
              </div>
              <v-img
                slot="reference"
                src="/images/eye-icon.svg"
                max-width="23"
                height="13"
                @click.stop="item.is_campaign
                  ? showCampaignPreview(item.preview_url)
                  : showPreview(item.preview_url, item.gift_id)"
              />
            </popper>
          </div>
          <img src="/images/drop down arrow@1x.svg" alt="go">
        </div>
      </template>
      <template #item.recipients="{ item }">
        <div class="d-flex h100 py-3">
          {{ item.recipients === 0 ? '—' : item.recipients > 0 ? `${item.recipients} Recipients` : item.recipients }}
        </div>
      </template>
    </v-data-table>
    <div
      v-if="items.length === 0 && !loading"
      class="d-flex justify-center py-5">
      <span class="f20 font-weight-bold">No Results</span>
    </div>
  </v-sheet>
</template>

<script>
import getOrderApprovalStatus from '@/utils/getOrderApprovalStatus'
import standardTooltipProps from '@/mixins/standardTooltipProps'

import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import UpdateStatusSheet from '@/components/UpdateStatusSheet'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('e-gift-orders')

export default {
  name: 'data-table',
  mixins: [standardTooltipProps],
  components: {
    popper: Popper,
    UpdateStatusSheet,
  },
  data: () => ({
    prevSortStatus: 0,
    pageCount: 1,
    page: 1,
    headers: [
      {
        text: 'GIFT GIVER',
        align: 'start',
        filterable: true,
        value: 'customer_name',
        sortable: false,
        width: '300px',
        class: 'gift_giver'
      },
      {
        text: 'ORDER#',
        value: 'number',
        sortable: false,
        width: '130px'
      },
      {
        text: 'ORDER NAME',
        value: 'name',
        sortable: false,
        width: '200px'
      },
      {
        text: 'DESCRIPTION',
        value: 'description',
        sortable: false,
        width: '200px'
      },
      {
        text: 'DATE',
        value: 'date',
        width: '200px'
      },
      {
        text: 'RECIPIENTS',
        value: 'recipients',
        sortable: false,
        width: '200px'
      },
      {
        text: 'TOTAL COST',
        value: 'costs',
        sortable: false,
        width: '210px'
      },
      {
        text: 'STATUS',
        value: 'status',
        class: 'header-status',
        width: '300px',
        sortable: false
      },
      {
        text: '',
        sortable: false,
        value: 'data-table-expand',
        width: '100px'
      }
    ],
    selected: [],
    tooltips: [
      {
        status: 'Created',
        tooltip: 'E-Gift created. Email invitation is scheduled to send at a future date'
      },
      {
        status: 'Not Paid',
        tooltip: 'E-Gift is pending until payment is approved'
      },
      {
        status: 'Paid, not sent',
        tooltip: 'E-Gift created. Email invitation is scheduled to send at a future date'
      },
      {
        status: 'Sent, not opened',
        tooltip: 'E-Gift invitation email has been sent, recipient has not opened the email.'
      },
      {
        status: 'Opened',
        tooltip: 'Recipient opened the E-Gift invitation but has not redeemed the gift.'
      },
      {
        status: 'Started Checkout',
        tooltip: 'At this time, not all recipients have redeemed their E-Gifts from this order.'
      },
      {
        status: 'Redemption Complete',
        tooltip: 'All recipients have successfully redeemed their E-Gifts from this order.'
      }
    ],
    nextable: true,
    hideTooltip: true
  }),
  methods: {
    handleRowClick (e, v, m) {
      if (this.nextable === true) {
        this.$emit('loadEgiftOrderPage', e.id)
      } else {
        this.nextable = true
      }
    },
    customSort (items, index, isDesc) {
      if (isDesc[0] !== this.prevSortStatus && isDesc[0] !== undefined) {
        const sortParams = {
          column: index[0] === undefined ? 'date' : index[0],
          isDesc: isDesc[0] ? 1 : 0
        }
        this.$emit('setSortParams', sortParams)
      }
      this.prevSortStatus = isDesc[0]
      return items
    },
    selectAll () {
      this.$emit('selectAll')
    },
    showCampaignPreview (apiLink) {
      window.open(apiLink, '_blank')
    },
    showPreview (apiLink, giftId) {
      const [url, base64] = apiLink.split('?preview=')
      let decodedB64 = atob(base64).replace('productsChecked=', `productsChecked=${giftId}`)
      decodedB64 += `&gift_id=${giftId}`
      const newUrl = `${url}?preview=${btoa(decodedB64)}`

      window.open(newUrl, '_blank')
    },
    statusTooltipWidth (item) {
      if (item.is_generate_links === 1) {
        return '375'
      } else if (item.status === 'Created') {
        return '615'
      } else {
        return '495'
      }
    },
    ...mapActions([
      'load'
    ]),
    getApprovalStatus ({ approval }) {
      if (!approval) { return null }

      return getOrderApprovalStatus(approval?.status)
    },
    getApprovalOrderTooltip ({ approval }) {
      if (!approval) { return null }
      const status = getOrderApprovalStatus(approval?.status)

      return `${status} by ${approval?.decided_by}`
    },
    showApprovalBadge ({ approval }) {
      if (!approval) { return false }
      const status = getOrderApprovalStatus(approval?.status)

      return ['Declined', 'Approved', 'Auto-approved'].includes(status)
    },
    getApprovalBadgeColors ({ approval }) {
      const status = approval?.status;

      if (status === 'declined') {
        return {
          color: '#FAE3E3',
          textColor: '#9D1216',
        }
      } else {
        return {
          color: '#D8F1E4',
          textColor: '#07703A',
        }
      }
    },
  },
  computed: {
    ...mapState([
      'items', 'loading'
    ]),
    getClosedDate () {
      return date => (new Date(date).toDateString())
    }
  },
  watch: {
    selected: function (val) {
      this.$emit('childToParent', this.selected)
    }
  },
  created () {
    this.load()
  }
}
</script>

<style lang="scss">
.filter-option {
  font-size: 13px;

  .v-label {
    color: #000;
  }
}
.e-gift-order-status {
  background: #fff !important;
  border: none !important;
  padding: 8px 16px;
  opacity: 1 !important;

  &::after {
    background: #fff !important;
  }
}

.e-cg-data-table {
  overflow: auto;

  .v-data-table__wrapper {
    padding-top: 200px;
    margin-top: -200px;
  }

  table {
    border-bottom: 1px solid #DFF3F2;
    min-width: 1350px!important;
  }

  .text-start {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    .v-data-table-header__icon {
      padding-left: 10px;
      margin-top: -5px;
      right: 0;
    }
  }

  .v-data-table-header {
    tr {
      th {
        position: relative;
      }

      th:first-child {
        border-right: none;
        width: 50px!important;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1!important;
        background: white;
        position: absolute;
      }

      th:nth-child(2) {
        padding-left: 70px!important;
      }

      th:nth-last-child(2) {

        .v-data-table-header__icon {
          right: 80px;
        }
      }

      th:last-child {
        z-index: 1!important;
        position: absolute;
        right: 0;
        background: white;
      }

      .header-status {
        border-right: none;
      }
    }
  }

  tbody {

    tr {
      position: relative;
      td:first-child,
      td:last-child {
        background: white;
      }

      &:hover {

        td:first-child,
        td:last-child {
          background: #f8f8f8!important;
        }
      }
    }

    tr:nth-child(even) {

      td:first-child,
      td:last-child {
        background: #fbfbfb;
      }
    }

    td {
      height: 105px!important;
    }

    td:first-child {
      border-right: none;
      width: 50px!important;
      display: flex;
      justify-content: center;
      height: 100% !important;
      align-items: center;
      z-index: 1!important;
      position: absolute;
    }

    td:nth-child(2) {
      padding-left: 70px!important;
    }
    td:nth-last-child(2) {
      overflow: visible;
      white-space: pre-wrap!important;
      padding-right: 120px !important;
    }

    td:last-child {
      border-right: none;
      position: absolute;
      height: 100% !important;
      width: 100px;
      right: 0;

      .popper {
        width: 120px;
      }
    }
  }
}

.status-red {
  color: #F15B5B;
}

.status-green {
  color: #42B77A;
}

.mdi-checkbox-marked {
  color: #42B77A!important;
}

.v-data-table__selected {
  background: #DFF3F2!important;
}

.v-data-table__fixed-selected .v-data-table__selected {
  td:first-child,
  td:last-child {
    background: #DFF3F2!important;
  }
}

.v-data-table-header__icon {
  // transform: none!important;
  font-size: 20px!important;
  transform: rotate(90deg)!important;

  &::before {
    content: '\F0174';
  }
}

.theme--light.v-data-table .v-data-table-header th.sortable.active.asc .v-data-table-header__icon {

  &::before {
    color: rgba(0, 0, 0, 0.87)!important;
  }

  &::after {
    color: rgba(0, 0, 0, 0.38)!important;
  }
}

.theme--light.v-data-table .v-data-table-header th.sortable.active.desc .v-data-table-header__icon {
  &::before {
    color: rgba(0, 0, 0, 0.38)!important;
  }

  &::after {
    color: rgba(0, 0, 0, 0.87)!important;
  }
}

.order_name_width{
  width: calc(36% - 205px);
}

.order-status {
  display: flex;

  &::after {
    content: '';
    display: flex;
    width: 20px;
    height: 21px;
    background: url('@/assets/temp-images/question-icon.svg');
    margin-left: 5px;
  }
}

.close-order__list {
  min-width: 150px;
}
</style>
