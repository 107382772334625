export default function getRecaptchaToken () {
  return new Promise((resolve, reject) => {
    const gRecaptcha = window.grecaptcha.enterprise

    gRecaptcha.ready(() => {
      try {
        gRecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'SUBMIT' })
          .then((token) => resolve(token))
          .catch((error) => reject(error))
      } catch (error) {
        reject(error);
      }
    })
  })
}