<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5559 11.1902C13.257 11.1902 13.0151 11.4328 13.0151 11.731C13.0151 12.0299 13.257
        12.271 13.5559 12.271C15.4044 12.271 16.799 13.8111 16.799 15.6596C16.799 15.7215 16.7873
        15.781 16.7623 15.8357C16.6417 16.1089 16.7638 16.4282 17.037 16.5495C17.1082 16.5816
        17.1825 16.5956 17.2561 16.5956C17.4635 16.5956 17.6607 16.4767 17.7507 16.2748C17.8368
        16.0807 17.8806 15.8741 17.8806 15.6596C17.8806 13.2156 16.0008 11.1902 13.5559
        11.1902ZM8.81557 3.4267C9.20296 3.19739 9.61539 3.08157 10.0419 3.08157C11.6807 3.08157
        13.015 4.80957 13.015 6.93278C13.015 7.31391 12.9728 7.688 12.889 8.04487C12.821 8.336
        13.0017 8.62635 13.2921 8.69443C13.3336 8.70461 13.375 8.7093 13.4157 8.7093C13.6615
        8.7093 13.883 8.54104 13.9417 8.29217C14.0442 7.85391 14.0966 7.39687 14.0966
        6.93278C14.0966 4.21322 12.2778 2 10.0419 2C9.42757 2 8.81322 2.17217 8.2654 2.49617C8.0087
        2.648 7.9234 2.97983 8.07522 3.23652C8.22783 3.494 8.55966 3.57852 8.81557 3.4267ZM11.2437
        13.3533C10.9448 13.3533 10.703 13.5951 10.703 13.8933C10.703 14.1923 10.9448 14.4341
        11.2437 14.4341C13.0915 14.4341 14.5957 15.9375 14.5957 17.786C14.5957 18.0239 14.4016
        18.218 14.1629 18.218H1.51278C1.27487 18.218 1.08078 18.0239 1.08078 17.786C1.08078 15.9375
        2.58496 14.4341 4.43191 14.4341C4.73087 14.4341 4.9727 14.1923 4.9727 13.8933C4.9727
        13.5951 4.73087 13.3533 4.43191 13.3533C1.98783 13.3533 0 15.3411 0 17.786C0 18.6203
        0.679304 19.2996 1.51278 19.2996H14.1629C14.9971 19.2996 15.6772 18.6203 15.6772
        17.786C15.6772 15.3411 13.6886 13.3533 11.2437 13.3533ZM7.83861 5.10852C9.47739 5.10852
        10.8117 6.83652 10.8117 8.96052C10.8117 11.0837 9.47739 12.8125 7.83861 12.8125C6.19905
        12.8125 4.86548 11.0837 4.86391 8.96052C4.86391 6.83652 6.19826 5.10852 7.83861
        5.10852ZM7.83861 13.8933C10.0745 13.8933 11.8925 11.6809 11.8925 8.96052C11.8925 6.24018
        10.0745 4.02774 7.83861 4.02774C6.73278 4.02774 5.69974 4.556 4.93122 5.51626C4.19087
        6.44209 3.78391 7.66452 3.78391 8.96052C3.78391 11.6809 5.6027 13.8933 7.83861
        13.8933ZM15.5559 9.19019C15.257 9.19019 15.0151 9.43279 15.0151 9.73097C15.0151 10.0299
        15.257 10.271 15.5559 10.271C17.4044 10.271 18.799 11.8111 18.799 13.6597C18.799 13.7215
        18.7873 13.781 18.7623 13.8358C18.6417 14.1089 18.7638 14.4282 19.037 14.5495C19.1082 14.5816
        19.1825 14.5957 19.2561 14.5957C19.4635 14.5957 19.6607 14.4767 19.7507 14.2748C19.8368
        14.0807 19.8806 13.8741 19.8806 13.6597C19.8806 11.2156 18.0008 9.19019 15.5559
        9.19019ZM10.8156 1.4267C11.203 1.19739 11.6154 1.08157 12.0419 1.08157C13.6807 1.08157 15.015
        2.80957 15.015 4.93278C15.015 5.31391 14.9728 5.688 14.889 6.04487C14.821 6.336 15.0017
        6.62635 15.2921 6.69443C15.3336 6.70461 15.375 6.7093 15.4157 6.7093C15.6615 6.7093 15.883
        6.54104 15.9417 6.29217C16.0442 5.85391 16.0966 5.39687 16.0966 4.93278C16.0966 2.21322
        14.2778 0 12.0419 0C11.4276 0 10.8132 0.172174 10.2654 0.496174C10.0087 0.648 9.9234 0.979826
        10.0752 1.23652C10.2278 1.494 10.5597 1.57852 10.8156 1.4267Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddGroup',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20,
    },
    color: {
      type: String,
      required: false,
      default: '#95979D',
    },
  },
};
</script>
