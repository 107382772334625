const VULNERABLE_HTML_TAGS = [
  '<script',
  '<alert',
  '<a',
  '<img',
  '<style',
  '<iframe',
  '<svg',
  '<div',
  '<scr',
  '<body',
  '![XSS]',
  '<?xml',
  '<xml',
  '<meta',
  '<bgsound',
]

export default {
  methods: {
    checkIfStringContainsNotAllowedTag (val) {
      if (!val) { return true }

      const parsedVal = val?.toLowerCase() || ''

      if (VULNERABLE_HTML_TAGS.some((tag) => parsedVal.includes(tag))) {
        return 'Text contains not-allowed HTML tag'
      }

      return true
    }
  },
}
