<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 26"
    fill="none"
    :width="width"
    :height="height"
  >
    <rect id="square-helper" y="0.25" width="25" height="25" rx="2" fill="white"/>
    <path d="M12.75 7.75H21.5V9H12.75V7.75Z" fill="#95979D"/>
    <path d="M12.75 16.5H21.5V17.75H12.75V16.5Z" fill="#95979D"/>
    <path
      d="M9 11.5H5.25C4.91858 11.4997 4.60083 11.3679 4.36648 11.1335C4.13213 10.8992 4.00033 10.5814
        4 10.25V6.5C4.00033 6.16858 4.13213 5.85083 4.36648 5.61648C4.60083 5.38213 4.91858 5.25033
        5.25 5.25H9C9.33142 5.25033 9.64917 5.38213 9.88352 5.61648C10.1179 5.85083 10.2497 6.16858
        10.25 6.5V10.25C10.2497 10.5814 10.1179 10.8992 9.88352 11.1335C9.64917 11.3679 9.33142
        11.4997 9 11.5ZM5.25 6.5V10.25H9.00063L9 6.5H5.25Z"
      fill="#95979D"
    />
    <path
      d="M9 20.25H5.25C4.91858 20.2497 4.60083 20.1179 4.36648 19.8835C4.13213 19.6492 4.00033 19.3314
        4 19V15.25C4.00033 14.9186 4.13213 14.6008 4.36648 14.3665C4.60083 14.1321 4.91858 14.0003
        5.25 14H9C9.33142 14.0003 9.64917 14.1321 9.88352 14.3665C10.1179 14.6008 10.2497 14.9186
        10.25 15.25V19C10.2497 19.3314 10.1179 19.6492 9.88352 19.8835C9.64917 20.1179 9.33142
        20.2497 9 20.25ZM5.25 15.25V19H9.00063L9 15.25H5.25Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'InventoryListView',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 25
    },
    height: {
      type: [Number, String],
      required: false,
      default: 25
    },
  },
}
</script>
