<template>
  <common-autocomplete
    v-model="modelValue"
    :items="filteredItems"
    v-bind="$attrs"
    :disable-attach="showCountyCodeAsText"
    v-on="$listeners"
    item-text="text"
  >
    <template v-slot:selection="data">
      {{ data.item[showCountyCodeAsText ? 'value' : 'text'] }}
    </template>

    <template v-if="loading" #no-data>
      <common-loader style="padding: 40px 0" />
    </template>
  </common-autocomplete>
</template>

<script>
import Api from '@/axios/api'

import CommonAutocomplete from './Autocomplete.vue'

export default {
  name: 'CommonCountrySelect',
  components: {
    CommonAutocomplete
  },
  data: () => {
    const countriesList = localStorage.getItem('countriesList')

    return ({
      items: countriesList ? JSON.parse(countriesList) : [],
      loading: false,
    })
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    showCountyCodeAsText: {
      type: Boolean,
      required: false,
      default: false,
    },
    // array of allowed countries codes, if empty show all
    allowedCountries: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    filteredItems () {
      const { items, allowedCountries } = this

      if (allowedCountries.length) {
        return items?.filter(({ value }) => allowedCountries?.includes(value)) ?? []
      }

      return items ?? []
    }
  },
  mounted () {
    if (!this.items.length) { this.loadCountriesList() }
  },
  methods: {
    loadCountriesList () {
      this.loading = true

      Api.get('/shared/data/countries')
        .then(({ data }) => {
          this.items = data || []
          localStorage.setItem('countriesList', JSON.stringify(data))
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>
