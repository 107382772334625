<template>
  <tray>
    <div class="d-flex">
      <tray-badge-inventory-qty
        :qty="qty"
        :minQty="minQty"
        :variants="variants"
      />

      <tray-variants
        v-if="superAdmin || masterAccount"
        :variants="variants"
        showInProduction
        :showVariantsTooltip="variants.length > 1"
      >
        <tray-badge
          v-if="inProduction > 0"
          color="yellow"
          :class="variants.length > 1 && 'pointer'"
        >
          {{ inProduction | maxZeroFilter }}
        </tray-badge>
      </tray-variants>

      <tray-variants
        v-if="superAdmin || masterAccount"
        :variants="variants"
        showOnHold
        :showVariantsTooltip="variants.length > 1"
      >
        <tray-badge
          v-if="onHold > 0"
          color="grey"
          class="pointer"
          :aria-disabled="(!superAdmin && !masterAccount)"
          v-tooltip="{
            content: 'Click to view where the on-hold items are',
            ...iconTooltipProps,
            offset: 8,
            classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
          }"
          @click.native="$emit('onHoldUnits')"
        >
          {{ onHold | maxZeroFilter }}
        </tray-badge>
      </tray-variants>

      <tray-badge
        v-if="!superAdmin && !masterAccount && balance !== null"
        color="red"
        outlined
        v-tooltip="{
          content: tooltipText,
          ...iconTooltipProps,
          offset: 8,
          placement: 'top',
          classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
        }"
      >
        {{ balance | maxZeroFilter }}
      </tray-badge>

    </div>
    <tray-product-options
      v-if="superAdmin || masterAccount"
      @onProductSettings="$emit('productSettings')"
      @onOrdersHistory="$emit('ordersHistory')"
      @onAssignUsers="$emit('assignUsers')"
    />
  </tray>
</template>

<script>
import iconTooltipProps from './inventoryStatus/mixins/iconTooltipProps'
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'
import maxZeroFilter from '@/mixins/maxZeroFilter'

import Tray from './InventoryCardTray/Tray'
import TrayVariants from './InventoryCardTray/TrayVariants'
import TrayBadge from './InventoryCardTray/TrayBadge'
import TrayProductOptions from './InventoryCardTray/TrayProductOptions'
import TrayBadgeInventoryQty from './InventoryCardTray/TrayBadgeInventoryQty'

export default {
  name: 'InventoryCardTray',
  mixins: [
    superAdmin,
    masterAccount,
    maxZeroFilter,
    iconTooltipProps,
  ],
  components: {
    TrayBadgeInventoryQty,
    TrayProductOptions,
    TrayVariants,
    TrayBadge,
    Tray
  },
  props: {
    qty: {
      type: Number,
      required: true
    },
    onHold: {
      type: Number,
      required: false,
      default: 0
    },
    inProduction: {
      type: Number,
      required: false,
      default: 0
    },
    minQty: {
      type: Number,
      required: true
    },
    balance: {
      type: Number,
      required: false,
      default: 0
    },
    balancePeriod: {
      type: String,
      required: false,
    },
    variants: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    tooltipText () {
      const { balance, balancePeriod } = this
      return `Balance: ${balance} units ${balancePeriod}`
    },
  },
}
</script>
