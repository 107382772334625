export default {
  setShow (state, value) {
    state.show = value
  },
  setMessage (state, message) {
    state.message = message
  },
  setType (state, type) {
    state.type = type
  },
  setTimeout (state, timeout) {
    state.timeout = timeout
  },
  resetSnackbar (state) {
    state.show = false
    state.message = null
    state.type = 'success'
    state.timeout = 3000
  }
}
