<template>
  <div
    class="recent-orders dashboard-card"
    :class="{
      'dashboard-card--small': $vuetify.breakpoint.xsOnly,
      'dashboard-card--large': (!adminAccount || !hasApprovalOrders) && $vuetify.breakpoint.mdAndUp,
      'dashboard-card--extra-large': ((adminAccount && hasApprovalOrders) ||
        ((superAdmin || masterAccount || adminAccount) && !hasApprovalOrders && hasInventory) ||
        !hasCgCredits) && $vuetify.breakpoint.mdAndUp,
      'order-5': !adminAccount && userIsCgElite || marketplaceUser,
      'order-8': adminAccount && userIsCgElite,
    }"
  >
    <div class="recent-orders__title">
      Recent Orders {{ items.length ? '(all account users)' : '' }}
    </div>
    <template v-if="!loading && items.length">
      <v-data-table
        :headers="$vuetify.breakpoint.mdAndUp ? headers : mobileHeaders"
        :items="items"
        hide-default-footer
        :items-per-page="4"
        :mobile-breakpoint="0"
        @click:row="(item) => goToProductPage(item)"
      >
        <template #item.recipient="{ item }">
          {{ getRecipientName(item) }}
        </template>

        <template #item.price="{ item }">
          {{ item.price | priceFilter }}
        </template>

        <template #item.status="{ item }">
          <update-status-sheet
            class="recent-order-status"
            :color="getOrderStatusColor(item)"
            :text-color="getOrderStatusTextColor(item)"
          >
            <icons-check-circle
              v-if="getOrderStatus(item) === 'Active'"
              filled
              :height="14"
              :width="14"
            />

            {{ getOrderStatus(item) }}
          </update-status-sheet>
        </template>
      </v-data-table>
      <div class="recent-orders__actions">
        <router-link :to="{ name: 'Orders' }">
          All orders
        </router-link>
      </div>
    </template>

    <template v-else-if="!loading && !items.length">
      <div class="recent-orders__empty">
        <icons-empty-recent-orders />

        You don’t have any orders
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'

import superAdmin from '@/mixins/superAdmin'
import adminAccount from '@/mixins/adminAccount'
import userIsCgElite from '@/mixins/userIsCgElite'
import masterAccount from '@/mixins/masterAccount'
import marketplaceUser from '@/mixins/marketplaceUser'

import UpdateStatusSheet from '@/components/UpdateStatusSheet'

export default {
  name: 'TheRecentOrders',
  mixins: [
    superAdmin,
    adminAccount,
    masterAccount,
    userIsCgElite,
    marketplaceUser
  ],
  components: { UpdateStatusSheet },
  props: {
    hasApprovalOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasInventory: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasCgCredits: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: "ORDER#",
        align: "start",
        value: "number",
        sortable: false,
        filterable: false,
      },
      {
        text: "TYPE",
        align: "start",
        value: "type",
        sortable: false,
        filterable: false,
      },
      {
        text: "GIVER",
        align: "start",
        value: "from",
        sortable: false,
        filterable: false,
      },
      {
        text: "RECIPIENT",
        align: "start",
        value: "recipient",
        sortable: false,
        filterable: false,
      },
      {
        text: "ORDER TOTAL",
        align: "start",
        value: "price",
        sortable: false,
        filterable: false,
      },
      {
        text: "STATUS",
        align: "start",
        value: "status",
        sortable: false,
        filterable: false,
      }
    ],
    mobileHeaders: [
      {
        text: "ORDER#",
        align: "start",
        value: "number",
        sortable: false,
        filterable: false,
      },
      {
        text: "TYPE",
        align: "start",
        value: "type",
        sortable: false,
        filterable: false,
      },
      {
        text: "STATUS",
        align: "start",
        value: "status",
        sortable: false,
        filterable: false,
      }
    ],
    items: [],
  }),
  watch: {
    items: function (val) {
      this.$emit('numberOfOrders', val.length)
    }
  },
  created () {
    this.loading = true
    Api.get('/customer/dashboard/recent-orders')
      .then(({ data }) => {
        this.items = data
      })
      .finally(() => (this.loading = false))
  },
  methods: {
    getOrderStatus ({ status, type }) {
      if (type === 'E-Gift') {
        return [
          'canceled',
          'bounced',
          'expired',
          'closed',
          'redemption complete',
          'complete redeemed',
        ].includes(status?.toLowerCase()) ? 'Closed' : 'Active'
      } else {
        return status
      }
    },
    getOrderStatusTextColor (order) {
      const parsedStatus = this.getOrderStatus(order)?.toLowerCase()

      switch (parsedStatus) {
        case 'active':
          return '#07703A';
        case 'closed':
          return '#9E040A';
        case 'pending':
          return '#795E04';
        case 'processing':
          return '#222325';
        case 'shipped':
          return '#65446D';
        case 'delivered':
          return '#07703A';
        case 'complete':
          return '#07703A';
        default:
          return '#222325';
      }
    },
    getOrderStatusColor (order) {
      const parsedStatus = this.getOrderStatus(order)?.toLowerCase()

      switch (parsedStatus) {
        case 'active':
          return '#D8F1E4';
        case 'closed':
          return '#FAE3E3';
        case 'pending':
          return '#FCEBA4';
        case 'processing':
          return '#D3D2D2';
        case 'shipped':
          return '#DFDDEE';
        case 'delivered':
          return '#D8F1E4';
        case 'complete':
          return '#D8F1E4';
        default:
          return '#D3D2D2';
      }
    },
    getRecipientName (order) {
      const { ship_to: recipients } = order || {}
      const firstRecipient = recipients?.[0] ?? {}
      return `${firstRecipient?.first_name ?? ''} ${firstRecipient?.last_name ?? ''}`
    },
    goToProductPage ({ type, additional_id: egiftId, id }) {
      if (type === 'E-Gift') {
        this.$router.push({ name: 'EgiftOrder', params: { id: egiftId } })
      } else {
        this.$router.push({ name: 'MyOrder', params: { id } })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.recent-orders {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__title {
    color: #95979D;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
    font-family: 'Lato-Bold', sans-serif;
  }

  &::v-deep > .v-data-table > .v-data-table__wrapper > table  {
    border-color: #D3D2D2;

    & > thead > tr > th {
      height: 30px;
      border-color: #D3D2D2;
      border-right: none;
      border-left: none;
      padding: 0;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #000;
      font-family: 'Lato-Bold', sans-serif;

      &:first-of-type {
        padding-right: 8px;
      }

      &:not(:first-of-type) {
        padding: 0 8px;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    & > tbody > tr > td {
      padding: 0;
      border-color: #D3D2D2;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      font-family: 'Lato-Regular', sans-serif;

      &:first-of-type {
        padding-right: 8px;
      }

      &:not(:first-of-type) {
        padding: 0 8px;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    & > a {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #42B77A !important;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 28px;
    align-items: center;
    justify-content: center;

    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }

  .recent-order-status {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 10px;
    line-height: 14px;
    gap: 8px;
    padding: 0 12px;
    width: fit-content;
    letter-spacing: 0.5px;
  }
}
</style>
