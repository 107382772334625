<template>
  <div class="the-transactions-history">
    <div class="the-transactions-history__filters">
      <search-by-associated-data-input
        has-transactions
        :initialSearch.sync="search"
        :productId.sync="filterProductId"
        :campaignId.sync="filterCampaignId"
      />

      <common-my-date-range-picker
        :dateRange="dateRange"
        @setDateRange="(val) => dateRange = val"
        style="min-width: 220px; max-width: 220px"
      />

      <common-select
        v-model="types"
        :items="transactionTypes"
        multiple
        menuContentClass="transactions-history__multi-select--menu"
        :menu-z-index="4"
        style="max-width: 180px"
        placeholder="Transaction type"
        class="transactions-history__multi-select"
        disable-attach
        filter-style
        :height="30"
      >
        <template #selection="{ index, item }">
          <div v-if="index === 0" class="transactions-history__multi-select--selected">
            {{ types.length > 1 ? 'Transaction type' : item.text }}
          </div>
        </template>

        <template #item="{ item, attrs }">
          <div class="transactions-history__multi-select--item">
            <div
              class="transactions-history__multi-select--item--icon"
              :class="{
                'transactions-history__multi-select--item--icon--selected': attrs['aria-selected'] === 'true'
              }"
            />
            <div class="transactions-history__multi-select--item--name">
              {{ item.text }}
            </div>
          </div>
        </template>

        <template #append-item>
          <div
            class="transactions-history__multi-select--clear"
            @click="types = []"
          >
            Clear
          </div>
        </template>
      </common-select>

      <common-select
        v-model="userId"
        :items="users"
        style="max-width: 180px"
        placeholder="By user"
        item-text="name"
        item-value="id"
        disable-attach
        filter-style
        :height="30"
      />

      <common-input
        v-model="orderId"
        style="max-width: 180px"
        placeholder="Order # / Parent order #"
        filter-style
        clearable
        :height="30"
      />

      <span role="button" v-if="isPageFiltered" @click="resetFilters()">
        Clear
      </span>

      <the-table-download-invoice
        :downloadMethod="downloadCSV"
        wrapperStyles="margin-left: auto"
      />
    </div>

    <v-expand-transition>
      <div class="the-transactions-history__aggregated_view" v-show="showAggregatedView">
        <template v-if="!loadingAggregatedView">
          <the-aggregated-view-products
            v-if="showAggregatedProducts"
            :items="aggregatedProducts"
          />
          <the-aggregated-view-campaigns
            v-if="showAggregatedCampaigns"
            :items="aggregatedCampaigns"
          />
          <the-aggregated-view-users
            v-if="showAggregatedUsers"
            :items="aggregatedUsers"
          />
          <the-aggregated-view-totals />
        </template>

        <common-loader
          v-else
          style="padding: 106px 0; width: 936px"
        />
      </div>
    </v-expand-transition>

    <the-transactions-history-table />
  </div>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'
import { createNamespacedHelpers } from 'vuex'

import transactionsHistoryFilters from './mixins/transactionsHistoryFilters'

import TheAggregatedViewUsers from './TheAggregatedViewUsers.vue'
import TheAggregatedViewTotals from './TheAggregatedViewTotals.vue'
import TheAggregatedViewProducts from './TheAggregatedViewProducts.vue'
import TheAggregatedViewCampaigns from './TheAggregatedViewCampaigns.vue'
import TheTransactionsHistoryTable from './TheTransactionsHistoryTable.vue'
import TheTableDownloadInvoice from '@/components/myCGCredits/TheTableDownloadInvoice.vue'
import SearchByAssociatedDataInput from '@/components/myInventory/SearchByAssociatedDataInput.vue'

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('transactions-history')

export default {
  name: 'TheTransactionsHistoryPage',
  mixins: [transactionsHistoryFilters],
  components: {
    TheAggregatedViewUsers,
    TheAggregatedViewTotals,
    TheTableDownloadInvoice,
    TheAggregatedViewProducts,
    TheAggregatedViewCampaigns,
    TheTransactionsHistoryTable,
    SearchByAssociatedDataInput,
  },
  computed: {
    ...mapState([
      'filters',
      'transactions',
      'aggregatedView',
      'loadingAggregatedView',
    ]),
    ...mapGetters([
      'isPageFiltered',
      'params',
    ]),
    search: {
      get () { return this.filters.search },
      set (val = null) { this.setSearch(val) },
    },
    filterProductId: {
      get () { return this.filters.productId },
      set (val = null) { this.setFiltersProductId(val) },
    },
    filterCampaignId: {
      get () { return this.filters.campaignId },
      set (val = null) { this.setFiltersCampaignId(val) },
    },
    aggregatedProducts () {
      return this.aggregatedView?.products ?? []
    },
    aggregatedUsers () {
      return this.aggregatedView?.users ?? []
    },
    aggregatedCampaigns () {
      return this.aggregatedView?.campaigns ?? []
    },
    showAggregatedView () {
      const { filters: { types }, transactions } = this

      if (!transactions.length) { return false }

      return types.length === 1 && ['out', 'egift_redeem', 'hold', 'in'].includes(types?.at(0))
    },
    showAggregatedUsers () {
      const { aggregatedUsers, filters: { userId } } = this

      if (!aggregatedUsers.length) return false

      return !userId
    },
    showAggregatedProducts () {
      const { aggregatedProducts, filters: { productId } } = this

      if (!aggregatedProducts.length) return false

      return !productId
    },
    showAggregatedCampaigns () {
      const { aggregatedCampaigns, filters: { campaignId } } = this

      if (!aggregatedCampaigns.length) return false

      return !campaignId
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: 'handleFilterParams',
    },
    showAggregatedView: function (val) {
      if (val) { this.getAggregatedView() }
    },
  },
  created () {
    this.getTransactions()
  },
  methods: {
    ...mapMutations([
      'setSearch',
      'setFiltersProductId',
      'setFiltersCampaignId',
    ]),
    ...mapActions([
      'getAggregatedView',
      'getTransactions',
      'resetFilters',
    ]),
    handleFilterParams: debounce(function () {
      this.getTransactions()
    }, 400),
    async downloadCSV () {
      return Api.get('/customer/purchased-inventory/transactions/csv', {
        params: {
          ...this.params,
          page: undefined,
        },
      })
        .then((response) => {
          this.$store.commit('downloadCSV', response)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.the-transactions-history {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;

    & > span[role="button"] {
      margin-left: 10px;
      color: #42B77A;
      font-size: 14px;
      font-family: 'Lato-Regular', sans-serif;
      cursor: pointer;
    }
  }

  &__aggregated_view {
    display: flex;
    width: fit-content;
    flex-direction: row;
    background: #F4F4F4;
    padding: 20px;
    gap: 18px;

    & > div {
      height: 300px;
      width: 300px;
      background: #fff;
    }
  }
}
</style>

<style lang="scss">
.transactions-history__multi-select {
  &--selected {
    display: flex;
    align-items: baseline;
    gap: 4px;
    font-size: 15px;
    font-family: 'Lato-Regular', sans-serif;
    color: #000;
  }

  &--clear {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #42B77A;
    cursor: pointer;
    padding: 14px 20px;
  }

  &--item {
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    gap: 8px;

    &--name {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 155px;

      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 37px;
      color: #000;
    }

    &--icon {
      display: flex;
      width: 26px;
      height: 26px;
      min-width: 26px;
      min-height: 26px;
      border-radius: 50%;
      border: 1px solid #DADADA;
      background: #fff;
      position: relative;

      &--selected {
        border: none;
        background-color: #42B77A;

        &::after {
          position: absolute;
          left: 50%;
          top: 48%;
          transform: translate(-50%,-50%) rotate(45deg);
          content: ' ';
          width: 6px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &--menu {
    .v-list {
      padding: 0;
      padding-top: 10px !important;

      > .v-list-item {
        padding: 2px 20px !important;
        max-height: unset !important;
        min-height: 40px;
        height: 40px;
        color: #222325;

        &--active {
          background: #D8F1E4 !important;

          &::before {
            opacity: 0 !important;
          }
        }
      }
    }
  }
}
</style>
