<template>
  <v-menu
    max-width="200"
    open-on-click
    :attach="$el"
    offset-y
    bottom
    left
    tile
    z-index="4"
    nudge-bottom="10"
    content-class="inventory-status-list-view-item-settings"
  >
    <template #activator="{ on, attrs }">
      <div
        class="inventory-status-list-view-item-settings__activator"
        v-bind="attrs"
        v-on="on"
        v-tooltip="{ content: 'Product settings', ...iconTooltipProps }"
      >
        <icons-settings-kebab />
      </div>
    </template>
    <ul>
      <li v-if="!isKit && (superAdmin || masterAccount)" @click="$emit('onProductSettings')">
        <icons-settings-line :width="20" :height="20" />

        Product settings
      </li>
      <li v-if="superAdmin || masterAccount" @click="$emit('onAssignUsers')">
        <icons-sidebar-sub-account :width="18" :height="19" color="#95979D" />

        Access to users
      </li>
      <li v-if="isKit" @click="$emit('onKitPanel')">
        <icons-settings-line :width="20" :height="20" />

        Kit panel
      </li>
      <li v-if="!isKit && (superAdmin || masterAccount)" @click="$emit('onOrderRefill')">
        <icons-auto-refill :width="20" :height="16" />

        Order refill
      </li>
      <li v-if="!isKit && !hasVariants" @click="$emit('onAddToCart')">
        <icons-add-to-cart-alt :width="23" :height="18" />

        Add to cart
      </li>
      <li @click="$emit('onSendWithEgift')">
        <icons-egift :width="23" :height="16" />

        Send with eGift
      </li>
    </ul>
  </v-menu>
</template>

<script>
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'
import iconTooltipProps from './mixins/iconTooltipProps'

export default {
  name: 'TheListViewItemSettings',
  mixins: [
    superAdmin,
    masterAccount,
    iconTooltipProps,
  ],
  props: {
    isKit: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasVariants: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>

<style lang="scss">
.inventory-status-list-view-item-settings {
  &__activator {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & > ul {
    margin: 0;
    padding: 10px 0;
    list-style: none;
    background-color: #fff;

    & > li {
      gap: 12px;
      display: flex;
      align-items: center;
      padding: 0 14px;
      flex-direction: row;
      flex-wrap: nowrap;
      font-size: 14px;
      line-height: 35px;
      color: #62646A;
      cursor: pointer;
      background-color: inherit;
      font-family: 'Lato-Regular', sans-serif;
      transition: background-color ease-in-out 0.2s;

      &:hover {
        background-color: #FAFAFA;
      }

      &:active {
        background-color: #EDFCFB;
      }
    }
  }
}
</style>
