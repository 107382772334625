export default {
  data: () => ({
    showActionsElevation: false,
  }),
  mounted() {
    window.addEventListener('scroll', this.productsActionsScrollHandler)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.productsActionsScrollHandler)
  },
  methods: {
    productsActionsScrollHandler () {
      const windowScrollY = window?.scrollY ?? 0

      this.showActionsElevation = windowScrollY > 240
    }
  },
}
