<template>
  <v-row class="low-quantity">
    <v-col cols="12">
      <label class="content_grow form_label__custom">
        <input
          v-model="enableAlert"
          :checked="enableAlert"
          type="checkbox"
        />
        <span class="checkmark" />
        <span class="d-flex ml-7 low-quantity__checkbox-text">Enable low quantity alert</span>
      </label>
    </v-col>
    <v-col cols="12" class="low-quantity__sub-text">
      Notify me by email when available quantity drops to
      <v-text-field
        v-model="minQtyToAlert"
        :disabled="!enableAlert"
        dense
        color="black"
        class="ma-0 pl-2"
        type="number"
        hide-details
        tile
        suffix="Units"
      />
    </v-col>
  </v-row>
</template>

<script>
import Api from '@/axios/api'

export default {
  name: 'LowQuantityAlert',
  props: {
    itemId: {
      type: Number,
      required: true
    },
    enabled: {
      type: Boolean,
      required: true
    },
    qty: {
      type: [String, Number],
      required: false,
      default: 0
    }
  },
  computed: {
    enableAlert: {
      get () {
        return this.enabled
      },
      set (isEnabled) {
        this.saveChanges(isEnabled, null)
        this.$emit('update:enabled', isEnabled)
      }
    },
    minQtyToAlert: {
      get () {
        return this.qty
      },
      set (qty) {
        if (this.enabled) this.saveChanges(null, Number(qty))
        this.$emit('update:qty', Number(qty))
      }
    }
  },
  methods: {
    saveChanges (enabled, qty) {
      // TODO refactor
      let isAlertEnabled = enabled
      let alertQty = qty

      if (isAlertEnabled === null) isAlertEnabled = this.enabled
      if (alertQty === null) alertQty = Number(this.qty)

      Api.put(`customer/purchased-inventory/item/${this.itemId}/low-stock`, {
        enabled: isAlertEnabled,
        value: alertQty
      })
    }
  }
}
</script>

<style lang="scss">
.low-quantity {
  &__checkbox-text {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 15px;
    line-height: 18px;
    margin-top: 2px;
    color: #000;
  }

  &__sub-text {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: #000;
  }

  .v-input {
    width: 80px;
    display: inline-block;

    &__slot {
      padding: 0 4px;
      .v-text-field__suffix {
        font-family: 'Lato-Italic', sans-serif;
        font-size: 13px;
        line-height: 16px;
        color: #a1a1a1;
        height: 20px;
        display: flex;
        align-items: flex-end;
      }

      &::before {
        border-image: none !important;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  }
}
</style>
