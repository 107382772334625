<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 6"
    fill="none"
    :width="width"
    :height="height"
  >
    <g opacity="0.5">
      <path
        d="M7.30688 4.5L4.11586 1.00598"
        stroke="black"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M0.800049 4.5L3.99107 1.00598"
        stroke="black"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowUp',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 8
    },
    height: {
      type: [Number, String],
      required: false,
      default: 6
    }
  }
}
</script>
