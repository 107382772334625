import { createNamespacedHelpers } from 'vuex'

const { mapState, mapMutations } = createNamespacedHelpers('users')

export default {
  computed: {
    ...mapState([
      'filters',
      'show',
      'sortOptions',
    ]),
    search: {
      get () { return this.filters.search },
      set (val) { this.setFiltersSearch(val) },
    },
    sort: {
      get () { return this.filters.sort },
      set (val) { this.setFiltersSort(val) },
    },
    showInactiveUsers: {
      get () { return this.show.inactiveUsers },
      set (val) { this.setShowInactiveUsers(val) },
    },
    showSubAccounts: {
      get () { return this.show.subAccounts },
      set (val) { this.setShowSubAccounts(val) },
    },
    showAdmins: {
      get () { return this.show.admins },
      set (val) { this.setShowAdmins(val) },
    },
  },
  methods: {
    ...mapMutations([
      'setFiltersSearch',
      'setFiltersSort',
      'setShowInactiveUsers',
      'setShowSubAccounts',
      'setShowAdmins',
    ])
  }
}
