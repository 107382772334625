<template>
  <v-row no-gutters class="h100">
    <template v-if="products.length === 1">
      <v-col cols="12">
        <product-image
          :image="products[0].product.image"
          :name="products[0].product.name"
          :is-out-of-stock="disabled ? false : products[0].product.qty.is_out_of_stock"
          :is-from-inventory="isProductFromInventory(products[0])"
          hide-border-right
          :height="defaultImageHeight"
        />
      </v-col>
    </template>

    <template v-else-if="products.length === 2 || products.length === 4">
      <template v-for="(product, index) in products">
        <v-col cols="6" :key="index">
          <product-image
            :image="product.product.image"
            :name="product.product.name"
            :is-out-of-stock="disabled ? false : product.product.qty.is_out_of_stock"
            :is-from-inventory="isProductFromInventory(product)"
            :hide-border-right="(index + 1) % 2 === 0"
            :small-inventory-icon="showSmallInventoryIcon"
            :height="products.length === 2 ? defaultImageHeight : alternativeImageHeight"
          />
        </v-col>
      </template>
    </template>

    <template v-else-if="products.length === 3">
      <v-col cols="6">
        <product-image
          :image="products[0].product.image"
          :name="products[0].product.name"
          :is-from-inventory="isProductFromInventory(products[0])"
          :is-out-of-stock="disabled ? false : products[0].product.qty.is_out_of_stock"
          :height="defaultImageHeight"
        />
      </v-col>
      <v-col cols="6">
        <v-row no-gutters>
          <v-col cols="12">
            <product-image
              :image="products[1].product.image"
              :name="products[1].product.name"
              :is-from-inventory="isProductFromInventory(products[1])"
              :is-out-of-stock="disabled ? false : products[1].product.qty.is_out_of_stock"
              hide-border-right
              :small-inventory-icon="showSmallInventoryIcon"
              :height="alternativeImageHeight"
            />
          </v-col>
          <v-col cols="12">
            <product-image
              :image="products[2].product.image"
              :name="products[2].product.name"
              :is-from-inventory="isProductFromInventory(products[2])"
              :is-out-of-stock="disabled ? false : products[2].product.qty.is_out_of_stock"
              hide-border-right
              :small-inventory-icon="showSmallInventoryIcon"
              :height="alternativeImageHeight"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template v-else-if="products.length === 5">
      <v-col cols="6">
        <product-image
          :image="products[0].product.image"
          :name="products[0].product.name"
          :is-from-inventory="isProductFromInventory(products[0])"
          :is-out-of-stock="disabled ? false : products[0].product.qty.is_out_of_stock"
          :height="defaultImageHeight"
        />
      </v-col>
      <v-col cols="6">
        <v-row no-gutters>
          <template v-for="(product, index) in products">
            <v-col cols="6" :key="index" v-if="index !== 0" >
              <product-image
                :image="product.product.image"
                :name="product.product.name"
                :is-from-inventory="isProductFromInventory(product)"
                :is-out-of-stock="disabled ? false : product.product.qty.is_out_of_stock"
                :hide-border-right="(index + 1) === 3 || (index + 1) === 5"
                :height="alternativeImageHeight"
                :small-inventory-icon="showSmallInventoryIcon"
              />
            </v-col>
          </template>
        </v-row>
      </v-col>
    </template>

    <template v-else-if="products.length === 6">
      <template v-for="(product, index) in products">
        <v-col :cols="(index === 2 || index === 3) ? 6 : 3" :key="index">
          <product-image
            :image="product.product.image"
            :name="product.product.name"
            :is-from-inventory="isProductFromInventory(product)"
            :is-out-of-stock="disabled ? false : product.product.qty.is_out_of_stock"
            :hide-border-right="(index + 1) % 3 === 0"
            :height="alternativeImageHeight"
            :small-inventory-icon="showSmallInventoryIcon"
          />
        </v-col>
      </template>
    </template>

    <template v-else>
      <template v-for="(product, index) in products">
        <v-col
          :cols="3"
          :key="index"
          v-if="index < 7 || (products.length <= 8 && index <= 8)"
        >
          <product-image
            :image="product.product.image"
            :name="product.product.name"
            :is-from-inventory="isProductFromInventory(product)"
            :is-out-of-stock="disabled ? false : product.product.qty.is_out_of_stock"
            :hide-border-right="(index + 1) % 4 === 0"
            :height="alternativeImageHeight"
            :small-inventory-icon="showSmallInventoryIcon"
          />
        </v-col>
      </template>

      <empty-image-col v-if="products.length === 7" />

      <multiple-products-col v-if="products.length > 8">
        +{{ products.length - 7 }}
      </multiple-products-col>
    </template>

    <div class="campaign-card-images--disabled" v-if="disabled">
      <div>DISABLED COLLECTION</div>
    </div>
  </v-row>
</template>

<script>
import ProductImage from './ProductImage.vue'
import EmptyImageCol from './common/EmptyImageCol.vue'
import MultipleProductsCol from './common/MultipleProductsCol.vue'

export default {
  name: 'TheCampaignCardImages',
  components: {
    ProductImage,
    EmptyImageCol,
    MultipleProductsCol
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultImageHeight: {
      type: Number,
      required: false,
      default: 180
    },
    // TODO change name?
    alternativeImageHeight: {
      type: Number,
      required: false,
      default: 90
    },
    /**
     * Will show if a product is from the inventory
      */
    showProductsFromInventory: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Will force products to show that they are from inventory
      */
    showAllProductsFromInventory: {
      type: Boolean,
      required: false,
      default: false
    },
    showSmallInventoryIcon: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  methods: {
    isProductFromInventory (product) {
      const { showProductsFromInventory, showAllProductsFromInventory } = this

      if (showAllProductsFromInventory) return true

      if (showProductsFromInventory) {
        return product.product?.inventory?.qty > 0
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-card-images--disabled {
  position: relative;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.4);

  & > div {
    background: hsla(358, 100%, 68%, 1);
    padding: 4px 8px;
    font-family: 'Lato-Bold', sans-serif !important;
    font-size: 10px;
    line-height: 14px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}
</style>
