<template>
  <div class="my-orders-table-item" @click="$router.push(routerParams)">
    <table-product :order="order" />
    <table-details :order="order" />
    <table-status :order="order" />
    <ul class="my-orders-table-item__payment">
      <li v-for="payment in orderPayments" :key="payment">{{ payment }}</li>
    </ul>
    <div class="my-orders-table-item__options">
      <template v-if="!orderRequireApproval">
        <icons-download-file
          @click.native.stop="downloadInvoice()"
          v-tooltip="{ content: 'Download invoice', ...tooltipProps }"
          v-if="showInvoiceIcon"
          color="#919191"
          :height="20"
          :width="20"
        />
        <icons-tracking
          v-if="showTrackingIcon"
          @click.native.stop="showTracking()"
          v-tooltip="{ content: 'Track order', ...tooltipProps }"
        />
        <icons-add-to-cart-alt
          v-if="showBuyAgainIcon"
          @click.native.stop="addToCart()"
          v-tooltip="{ content: 'Buy it again', ...tooltipProps }"
        />
      </template>

      <template v-else>
        <!-- DECLINE -->
        <template>
          <icons-close-circle-filled
            v-if="!loadingDecline"
            @click.native.stop="declineOrder()"
            :aria-disabled="loadingApprove"
            v-tooltip="{ content: 'Decline', ...tooltipProps }"
            :height="20"
            :width="20"
            :color="loadingApprove ? '#919191' : '#FF5A60'"
          />

          <v-progress-circular
            v-else
            @click.native.stop
            indeterminate
            v-tooltip="{ content: 'Decline', ...tooltipProps }"
            color="#919191"
            :width="2"
            :size="20"
          />
        </template>
        <!-- APPROVE -->
        <template>
          <icons-check-circle
            v-if="!loadingApprove"
            @click.native.stop="approveOrder()"
            v-tooltip="{ content: 'Approve', ...tooltipProps }"
            :aria-disabled="loadingDecline"
            :filled-color="loadingDecline ? '#919191' : '#42B77A'"
            :check-stroke-width="2"
            :height="20"
            :width="20"
            filled
          />

          <v-progress-circular
            v-else
            @click.native.stop
            indeterminate
            v-tooltip="{ content: 'Approve', ...tooltipProps }"
            color="#919191"
            :width="2"
            :size="20"
          />
        </template>
      </template>

      <icons-arrow-right :width="20" :height="20" color="#919191" />
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'
import ApiMPDF from '@/axios/apiMPdf'

import TableStatus from './TheMyOrdersTableStatus.vue'
import TableDetails from './TheMyOrdersTableDetails.vue'
import TableProduct from './TheMyOrdersTableProduct.vue'

export default {
  name: 'TheMyOrdersTableItem',
  components: {
    TableStatus,
    TableProduct,
    TableDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderRequireApproval: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loadingApprove: false,
    loadingDecline: false,
    tooltipProps: {
      placement: 'bottom',
      trigger: 'hover',
      classes: 'my-orders-table-item__options-tooltip',
      offset: 12,
    }
  }),
  computed: {
    orderPayments () {
      const { payment_methods } = this.order ?? {}

      return payment_methods.map(({ method, additional_information }) => {
        if (method === 'CG Credits') { return `${method} - ${additional_information?.name}` }
        return method
      })
    },
    routerParams () {
      const { orderRequireApproval } = this
      const { type, id, egift_id } = this.order

      if (type === 'E-Gift' && !orderRequireApproval) {
        return { name: 'EgiftOrder', params: { id: egift_id } }
      } else {
        return { name: 'MyOrder', params: { id } }
      }
    },
    showBuyAgainIcon () {
      return this.order?.type === 'Gift' ?? false
    },
    showTrackingIcon () {
      return !!this.order?.track_url ?? false
    },
    showInvoiceIcon () {
      return !!this.order?.invoice_id ?? false
    }
  },
  methods: {
    downloadInvoice () {
      const { order } = this
      ApiMPDF.get(
        `/cg/download/download/order_id/${Number(order.id)}`,
        { responseType: 'arraybuffer' }
      ).then(resp => {
        if (!resp.message) this.$store.commit('downloadPDF', resp)
      })
    },
    showTracking () {
      const { track_url: trackUrl, shipping_data: shippingData } = this.order

      if (!shippingData || shippingData.length === 1) {
        window.open(trackUrl, '_blank')
      } else {
        this.$emit('shoMultipleTracking', shippingData)
      }
    },
    addToCart () {
      const { order } = this
      Api.post(`/customer/orders/${order.id}/reorder`)
        .then(({ success }) => {
          if (success) window.location.href = `${process.env.VUE_APP_MAGENTO_URL}/cart`
        })
        .catch((e) => {
          console.error(e);
        })
    },
    declineOrder () {
      const { id } = this.order
      this.loadingDecline = true

      this.$store.dispatch('new-approvable-orders/declineOrder', { id })
        .then(() => {
          this.$cgToast.successBold('Order declined')
        })
        .catch((errorMessage) => {
          this.$cgToast.error(errorMessage)
        })
        .finally(() => (this.loadingDecline = false))
    },
    approveOrder () {
      const { id } = this.order
      this.loadingApprove = true

      this.$store.dispatch('new-approvable-orders/approveOrder', { id })
        .then(() => {
          this.$cgToast.successBold('Order approved')
        })
        .catch((errorMessage) => {
          this.$cgToast.error(errorMessage)
        })
        .finally(() => (this.loadingApprove = false))
    },
  }
}
</script>

<style lang="scss" scoped>
.my-orders-table-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 14px;
  gap: 30px;
  background: #fff;
  cursor: pointer;
  border-bottom: 1px solid #D3D2D2;

  &:hover {
    background: #fafafa;

    & > .my-orders-table-item__options {
      background: unset;
    }
  }

  & > div {
    &:nth-child(1) {
      width: 360px;
    }
  }

  & > ul {
    width: 190px;
  }

  &__payment {
    width: 200px;
    font-family: 'Lato-Regular', sans-serif;
    color: #000;
    font-size: 14px;

    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__options {
    width: fit-content;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
    position: sticky;
    justify-content: flex-end;
    right: 14px;
    min-width: 244px;
    background: linear-gradient(
      to left,
      #fff,
      #fff 60%,
      rgba(255, 255, 255, 0.22) 80.05%,
      rgba(255, 255, 255, 0) 98.23%
    );

    & > svg[aria-disabled="true"] {
      pointer-events: none;
      cursor: default;
    }
  }
}
</style>

<style lang="scss">
.my-orders-table-item__options-tooltip {
  padding: 4px 12px;
  background-color: #d2f5f3;
  border-radius: 2px;
  border: 1px solid #b3cac9;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 12px;
  color: #000;
}
</style>
