const importNames = [
  'Ignore',
  'First name',
  'Middle name',
  'Last name',
  'Company',
  'Phone number',
  'Fax',
  'Street Address',
  'Apt',
  'City',
  'State/Region',
  'Country',
  'Postal Code',
  'Group name',
  'Location type',
  'Email Address',
  'Date of Birth',
  'Anniversary'
]

const importValues = [
  'ignore',
  'firstname',
  'middlename',
  'lastname',
  'company',
  'telephone',
  'fax',
  'street',
  'apt',
  'city',
  'region',
  'country',
  'postcode',
  'group',
  'location_type',
  'email_address',
  'dob',
  'anniversary',
]

const importHeaders = importNames.map((name, index) => ({
  text: name,
  value: importValues[index]
}))

export { importHeaders, importNames, importValues }
