<template>
  <div class="favorites-list-item" :class="{ 'favorites-list-item--selected': isSelected }">
    <div class="favorites-list-item__image">
      <v-img :alt="itemName" contain :src="itemImage" v-if="itemImage" />
      <icons-gift-alternative :width="19" :height="20" v-else />
    </div>
    <div class="favorites-list-item__name">
      {{ itemName }} ({{ numberOfProducts }})
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMyFavoritesListItem',
  props: {
    listData: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    imageUrl: 'https://cf.corporategift.com/media/catalog/product',
  }),
  computed: {
    itemName () {
      return this.listData?.name ?? null
    },
    itemImage () {
      const image = this.listData?.image ?? null
      if (image) return this.imageUrl + image
      return null
    },
    numberOfProducts () {
      return this.listData?.products_count ?? 0
    }
  }
}
</script>

<style lang="scss" scoped>
.favorites-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 43px;
  gap: 8px;
  padding: 8px 30px 8px 8px;
  height: 50px;
  background-color: #F4F4F4;
  border: thin solid #D3D2D2;
  cursor: pointer;

  &__image {
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;

    & > .v-image {
      border-radius: inherit;
      width: inherit;
      height: inherit;
    }
  }

  &__name {
    font-size: 15px;
    line-height: 18px;
    font-family: 'Lato-Bold', sans-serif;
    color: #000;
  }

  &--selected {
    background-color: #EDFCFB;
    border-width: 2px;
    border-color: #A7DAD8;
  }

  @media (min-width: 960px) {
    & {
      height: 66px;

      &__name {
        font-size: 16px;
        line-height: 19px;
      }

      &__image {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
