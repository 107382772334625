<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="900px"
  >
    <close-panel-button @close="show = false" text="Back to review e-gift">
      Back to review e-gift
    </close-panel-button>

    <common-loader v-if="loading" style="max-height: 800px" />

    <v-container class="px-9 py-0" v-if="show && !loading">
      <v-row no-gutters>
        <v-col cols="12" class="panel__title pb-4">
          Approval request
        </v-col>

        <v-col cols="12" class="panel__subtitle">
          Please fill these questions and send your order request for approval:
        </v-col>

        <template v-for="(field, index) in formFields">
          <v-col cols="12" class="pt-10" :key="index">
            <label :for="field.code">
              {{ field.label }}
            </label>

            <common-select
              v-if="field.type === 'select'"
              v-model="formValues[field.code]"
              :items="field.options"
              item-text="label"
              item-value="value"
              :id="field.code"
              placeholder="Select an option"
              style="max-width: 458px"
            />

            <v-combobox
              v-if="field.type === 'multiple'"
              v-model="formValues[field.code]"
              multiple
              small-chips
              clearable
              :items="field.options"
              append-icon="mdi-chevron-down"
              color="#62646A"
              outlined
              dense
              item-color="#62646A"
              item-value="value"
              item-text="label"
              hide-details
              style="max-width: 458px"
              class="approval-form-combobox rounded-0 pt-1"
              :menu-props="{
                bottom: true,
                offsetY: true,
                left: true,
                tile: true,
                contentClass: 'approval-form-combobox__select'
              }"
            >
              <template #selection="{ attrs, item, parent, selected }">
                <v-chip
                  class="my-1"
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :input-value="selected"
                  small
                >
                  <span class="pr-2">
                    {{ item.label }}
                  </span>

                  <div class="d-flex align-center pointer" @click="parent.selectItem(item)">
                    <icons-close :width="10" :height="11" />
                  </div>
                </v-chip>
              </template>

              <template #item="{ item, attrs }">
                <div class="approval-form-combobox__select--item">
                  <div
                    class="approval-form-combobox__select--item--icon"
                    :class="{
                      'approval-form-combobox__select--item--icon--selected': attrs['aria-selected'] === 'true'
                    }"
                  />
                  <div class="approval-form-combobox__select--item--name">
                    {{ item.label }}
                  </div>
                </div>
              </template>

              <template #no-data>
                <div class="gray3 f14 px-4 py-2">
                  No data
                </div>
              </template>
            </v-combobox>

            <single-date-range-picker
              v-if="field.type === 'datepicker'"
              v-model="formValues[field.code]"
              :id="field.code"
              :allowPastDates="field.allow_past_dates"
              style="position: relative; margin-top: 8px"
              picker-style="--min-width: 460px;--picker-margin-top: -1px"
            />

            <common-input
              v-if="field.type === 'text'"
              v-model="formValues[field.code]"
              :id="field.code"
              style="max-width: 458px"
              height="36"
            />

            <v-textarea
              v-if="field.type === 'textarea'"
              v-model="formValues[field.code]"
              :id="field.code"
              style="max-width: 458px"
              hide-details
              class="panel__textarea"
              auto-grow
              rows="3"
            />

            <approval-file-input
              v-if="field.type === 'upload'"
              v-model="formValues[field.code]"
              :id="field.code"
            />
          </v-col>
        </template>

        <v-col cols="12" class="py-10">
          <span class="d-block error--text pb-2" v-if="showError">
            Fill required inputs:

            <div class="text-capitalize" v-for="(field, index) in missingFields" :key="index">
              - {{ field.replaceAll('_', ' ') }}
            </div>
          </span>
          <common-button
            width="230"
            height="45"
            @click="sendForApproval()"
          >
            Send for approval
          </common-button>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import ApprovalFileInput from '@/components/myCampaign/common/ApprovalFileInput.vue'
import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

import panelVModel from '@/mixins/panelVModel'
import takeCurrentDate from '@/components/myCampaign/panels/mixins/takeCurrentDate'
import approvalData from '@/assets/fake-data/approval-data.json'

export default {
  name: 'TheApprovalPanel',
  components: {
    SingleDateRangePicker,
    ApprovalFileInput,
  },
  mixins: [
    panelVModel,
    takeCurrentDate
  ],
  props: {
    approvalFromBudgetEntity: {
      type: Boolean,
      required: false,
      default: false
    },
    approvalForm: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    clearData: true,
    defaultFormFields: [],
    formFields: [],
    missingFields: [],
    showError: false
  }),
  computed: {
    formValues: {
      get () {
        return this.approvalForm
      },
      set (val) {
        this.$emit('update:approvalForm', val)
      }
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loading = true
        const params = {}
        if (this.approvalFromBudgetEntity) params.type = 'budget_entity'

        Api.get('/customer/approval-form-fields', { params })
          .then(({ data }) => {
            this.defaultFormFields = data || []
            if (this.clearData) this.setFieldData(this.defaultFormFields)
            this.loading = false
          })
      } else {
        if (this.clearData) Object.assign(this.$data, this.$options.data())
      }
    },
    approvalForm: {
      deep: true,
      immediate: true,
      handler () {
        this.formFields = [];
        this.defaultFormFields.forEach((fieldInfo) => {
          this.formFields.push(fieldInfo)
          if (fieldInfo.type === 'select') {
            const additionalQuestions = fieldInfo?.options?.filter((option) => option.question) || []
            additionalQuestions.forEach((additionalField) => {
              if (this.formValues[fieldInfo.code] === additionalField.value) {
                this.formFields.push(additionalField.question)
              }
            })
          } else if (fieldInfo.type === 'multiple') {
            const additionalQuestions = fieldInfo?.options?.filter((option) => option.question) || []
            additionalQuestions.forEach((additionalField) => {
              const selectedValues = this.formValues[fieldInfo.code].map((answer) => answer.value)
              if (selectedValues.includes(additionalField.value)) {
                this.formFields.push(additionalField.question)
              }
            })
          }
        })
      }
    }
  },
  methods: {
    setFieldData (fields = []) {
      fields.forEach((field) => {
        this.$set(this.formValues, field.code, null)

        if (field.type === 'select') {
          const defaultValue = field.options.filter((option) => option.default)?.[0] || null
          this.$set(this.formValues, field.code, defaultValue?.value ?? null)
        }

        if (field.type === 'datepicker') {
          this.$set(this.formValues, field.code, this.takeCurrentDate() ?? null)
        }

        if (field.type === 'multiple') {
          this.$set(this.formValues, field.code, []) // TODO take default?
        }

        const additionalQuestions = field?.options?.map((option) => option.question)?.filter((question) => question) ?? []
        if (additionalQuestions.length) {
          this.setFieldData(additionalQuestions)
        }
      })
    },
    sendForApproval () {
      const requiredInputs = []
      this.missingFields = []
      this.showError = false
      // TODO debug and refactor - reduce
      this.formFields.forEach((fieldInfo) => {
        if (fieldInfo.required) {
          requiredInputs.push({
            code: fieldInfo?.code ?? undefined,
            name: fieldInfo?.label || fieldInfo?.code
          })
        }
      })

      const filledInputs = requiredInputs
        .map((fieldName) => this.formValues[fieldName?.code] === 0 ? true : !!this.formValues[fieldName?.code]) || []

      if (!filledInputs?.includes(false)) {
        this.loading = true
        this.clearData = false
        this.$emit('sendForApproval')
        this.show = false
        this.showError = false
      } else {
        this.missingFields = requiredInputs
          .filter((inputName, index) => !filledInputs[index])
          .map((inputData) => inputData.name || inputData.code)
        this.showError = true
      }
    }
  }
}
</script>

<style lang="scss">
.panel__select {
  border: 1px solid #d3d2d2;
  width: 100%;
  height: 34px;
  margin: 0;
  max-width: 460px;
  display: block;

  .dropdown-toggle {
    padding: 6px 20px 2px 10px;
    background-image: none !important;

    .caret {
      top: 16px;
    }

    &:hover {
      background: inherit;
    }

    &-placeholder {
      font-family: 'Lato-Bold', sans-serif !important;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 0;
      padding: 8px 20px 8px 10px !important;
      color: #a1a1a1;
      letter-spacing: 0;
      font-style: italic;
    }
  }

  .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% + 2px);
    left: -1px;
    padding: 0;
    box-sizing: border-box;

    a {
      padding-left: 10px !important;

      &:hover {
        color: #9b9b9b;
        background: inherit;
      }
    }
  }
}

.panel__textarea {
  & textarea {
    font-family: 'Lato-Italic', sans-serif !important;
    font-size: 16px;
    line-height: 18px;
    color: #16181f !important;
    overflow-x: hidden;
    padding: 4px 8px;
  }

  & .v-input__slot:before, .v-input__slot:after {
    content: none !important;
  }
}

.approval-form-combobox {
  .v-input__slot {
    padding-left: 0 !important;
  }

  .v-select__selections {
    padding: 0 !important;

    & > .v-chip {
      font-family: 'Lato-Regular', sans-serif !important;
      background: #EDFCFB;
      line-height: 14px;
      font-size: 12px;
      color: #62646A;
    }
  }

  fieldset {
    border-width: 0 !important;
    padding-left: 0 !important;
  }

  &__select {
    .v-list {
      padding: 0 !important;
    }

    .v-list-item {
      padding: 0 !important;
    }

    &--item {
      cursor: pointer;
      display: flex;
      flex: 1 1 auto;
      padding: 0px 16px;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: #F5F5F5;
      }

      &--name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 155px;

        font-family: 'Lato-Regular', sans-serif;
        font-size: 14px;
        line-height: 37px;
        color: #000;
      }

      &--icon {
        display: flex;
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
        border: 1px solid #DADADA;
        background: #fff;
        position: relative;

        &--selected {
          border: none;
          background-color: #42B77A;

          &::after {
            position: absolute;
            left: 50%;
            top: 48%;
            transform: translate(-50%,-50%) rotate(45deg);
            content: ' ';
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.panel__title {
  font-family: 'Lato-Light', 'Lato Light', sans-serif !important;
  font-size: 40px;
  line-height: 44px;
  color: #000;
}

.panel__subtitle {
  font-family: 'Lato-Italic', 'Lato Italic', sans-serif !important;
  font-style: italic;
  font-size: 15px;
  line-height: 18px;
  color: #979797;
}

label {
  font-family: 'Lato-Bold', 'Lato Bold', sans-serif !important;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
