<template>
  <common-textarea
    v-model="inputHelper"
    v-bind="$attrs"
    :rules="rules"
    rows="1"
    row-height="16"
    ref="review-input"
    class="review-textarea"
    @keydown.enter="handleOnSave"
  >
    <template #append>
      <review-save-cancel-icons
        @onSave="handleOnSave"
        @onCancel="handleOnCancel"
      />
    </template>
  </common-textarea>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'

@Component({
  components: {
    ReviewSaveCancelIcons
  },
})
export default class ReviewTextarea extends Vue {
  @Prop({ required: false, default: null }) value!: string | null;

  @Prop({ required: false, default: () => ([]) }) rules!: Array<unknown>;

  inputHelper: string | null = null;

  get modelValue (): string | null {
    return this.value
  }

  set modelValue (val: string | null) {
    this.$emit('input', val)
  }

  mounted () {
    this.inputHelper = this.modelValue
  }

  handleOnSave (e: Event): void {
    if (e) { e.preventDefault(); }
    // @ts-ignore
    const result = this.$refs['review-input']?.$children?.[0]?.validate() ?? true
    if (!result) { return }

    this.modelValue = this.inputHelper
    this.handleOnCancel()
  }

  handleOnCancel (): void {
    this.$emit('exitEditMode')
  }
}
</script>

<style lang="scss" scoped>
.review-textarea {
  &::v-deep textarea {
    margin: 6px 0;
    padding-right: 10px !important;
    line-height: 24px;
    min-height: 24px;
  }
}
</style>
