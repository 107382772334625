<template>
  <div class="add-multiple-users-panel-upload-options">
    <cg-alert type="warning" class="add-multiple-users-panel-upload-options__alert">
      Once you have completed the upload process, you will receive a prompt to allocate your new
      users to budget entities, categories, spending limits, and more. Please note that these
      settings can be modified at a later time for your convenience
    </cg-alert>

    <div class="add-multiple-users-panel-upload-options__cards">
      <multiple-users-upload-options-card
        show-sub-text
        @onButtonClick="onOptionClick(2, 'upload-file')"
      >
        <template #icon>
          <icons-recipients-upload-file />
        </template>
        <template #text>
          Upload a CSV or tab-delimited TXT file
        </template>
        <template #sub-text>
          <a href="https://cf.corporategift.com/resources/sample_recipient_list.csv" download>
            Download our template
          </a>
        </template>
        <template #button>Upload CSV file</template>
      </multiple-users-upload-options-card>

      <multiple-users-upload-options-card @onButtonClick="onOptionClick(3, 'copy-paste')">
        <template #icon>
          <icons-recipients-copy-paste />
        </template>
        <template #text>
          Paste your contacts information from a spreadsheet or other document in each column separately
        </template>
        <template #button>Copy & paste</template>
      </multiple-users-upload-options-card>
    </div>
  </div>
</template>

<script>
import MultipleUsersUploadOptionsCard from '../components/MultipleUsersUploadOptionsCard.vue'
import { CgAlert } from '@corporategift/design-system'

export default {
  name: 'MultipleUsersUploadOptions',
  components: {
    CgAlert,
    MultipleUsersUploadOptionsCard,
  },
  methods: {
    onOptionClick (stepVal, recipientMethod) {
      this.$emit('update:selectedMethod', recipientMethod);
      this.$emit('update:step', stepVal);
    }
  }
}
</script>

<style lang="scss" scoped>
.add-multiple-users-panel-upload-options {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__alert {
    margin-top: -30px;

    &::v-deep > .cg-alert__content {
      font-weight: 400;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
  }
}
</style>
