export default {
  namespaced: true,
  state: {
    userInfoSSO: false,
    userInfoMenus: {},
    hideUserMenus: false,
    hideHeader: false,
    showVideoModal: false,
    videoModalUrl: null,
  },
  mutations: {
    setUserInfoSSO (state, value) {
      state.userInfoSSO = value
    },
    setUserInfoMenus (state, value) {
      state.userInfoMenus = value
    },
    setHideUserMenu (state, value) {
      state.hideUserMenus = value
    },
    setHideHeader (state, value) {
      state.hideHeader = value
    },
    setShowVideoModal (state, value = false) {
      state.showVideoModal = value
    },
    setVideoModalUrl (state, value = null) {
      state.videoModalUrl = value
    },
  },
  actions: {
    setUserInfo ({ commit }, { sso, menus }) {
      if (sso) { commit('setUserInfoSSO', sso) }
      if (menus) { commit('setUserInfoMenus', menus) }
    },
    handleVideoModal ({ commit }, { show = false, url = null }) {
      commit('setShowVideoModal', show)
      commit('setVideoModalUrl', url)
    }
  },
}