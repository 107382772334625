<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 23"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9391 14.0447C16.4887 14.0447 16.1244 14.4089 16.1244 14.8579C16.1244 15.3082 16.4887 15.6724 16.9391 15.6724C19.7228 15.6724 21.9888 17.9366 21.9888 20.7205C21.9888 21.0788 21.6964 21.3711 21.3368 21.3711H2.27906C1.92063 21.3711 1.62824 21.0788 1.62824 20.7205C1.62824 17.9366 3.89432 15.6724 6.67683 15.6724C7.12721 15.6724 7.49153 15.3082 7.49153 14.8579C7.49153 14.4089 7.12721 14.0447 6.67683 14.0447C2.99473 14.0447 0 17.0384 0 20.7205C0 21.9769 1.02339 23 2.27906 23H21.3368C22.5937 23 23.6183 21.9769 23.6183 20.7205C23.6183 17.0384 20.6224 14.0447 16.9391 14.0447ZM11.8091 1.6277C14.278 1.6277 16.2882 4.23014 16.2882 7.42897C16.2882 10.6266 14.278 13.2302 11.8091 13.2302C9.33907 13.2302 7.33001 10.6266 7.32765 7.42897C7.32765 4.23014 9.33789 1.6277 11.8091 1.6277ZM11.8091 14.8579C15.1776 14.8579 17.9165 11.5259 17.9165 7.42897C17.9165 3.33202 15.1776 0 11.8091 0C10.1432 0 8.58685 0.795583 7.42905 2.24178C6.31369 3.63611 5.70059 5.47714 5.70059 7.42897C5.70059 11.5259 8.44065 14.8579 11.8091 14.8579Z"
      fill="#62646A"
    />
    <path
      d="M28.6821 7.4524L24.0004 12.4558L21.2903 9.53597C20.9985 9.22312 20.5215 9.21912 20.2249 9.52703C19.9284 9.83495 19.9246 10.3382 20.2164 10.651L23.4787 14.1463C23.5295 14.1931 23.5866 14.2316 23.6481 14.2606C23.6707 14.2817 23.6958 14.2997 23.7226 14.3142C23.8129 14.3535 23.9095 14.3742 24.0072 14.375C24.1049 14.3745 24.2016 14.3539 24.2918 14.3142C24.3242 14.2961 24.3549 14.2746 24.3832 14.2499C24.4384 14.223 24.4897 14.1882 24.5357 14.1463L29.756 8.57102C29.961 8.37206 30.0456 8.07024 29.9761 7.78566C29.9066 7.50109 29.6943 7.27996 29.4237 7.21026C29.1531 7.14057 28.8681 7.23362 28.6821 7.4524Z"
      fill="#62646A"
    />
  </svg>
</template>

<script>
export default {
  name: 'Approver',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 30
    },
    height: {
      type: [Number, String],
      required: false,
      default: 23
    }
  }
}
</script>
