<template>
  <div class="create-campaign-source-collections">
    <h1>Select gifts from any collection</h1>

    <div class="create-campaign-source-collections__filters">
      <common-input
        v-model="search"
        placeholder="Search collections"
        prepend-inner-icon="mdi-magnify"
        height="36"
        clearable
        style="width: 250px"
        filter-style
      />

      <common-select
        v-model="filterTag"
        :items="availableTags"
        item-text="name"
        item-value="id"
        height="36"
        filter-style
        style="max-width: 250px"
        placeholder="All collections"
      />
    </div>

    <cg-loader v-if="loading" />

    <div v-else class="create-campaign-source-collections__list">
      <collection-item
        v-for="collection in filteredCollections"
        :key="collection.id"
        :item="collection"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import userIsCgElite from '@/mixins/userIsCgElite'

import { CgLoader } from '@corporategift/design-system'
import CollectionItem from '@/components/createCampaign/CreateCampaignSourceCollectionsItem.vue'

const { mapState } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignSourceCollections',
  mixins: [userIsCgElite],
  components: {
    CgLoader,
    CollectionItem
  },
  data: () => ({
    search: '',
    filterTag: 0,
  }),
  computed: {
    ...mapState(['source']),
    loading () { return this.source.loading },
    sourceCollections () { return this.source.collections },
    availableCollections () {
      const { sourceCollections, userIsCgElite } = this
      const simpleCollections = sourceCollections.filter(({ is_branded }) => !is_branded)

      if (userIsCgElite) { return simpleCollections }
      return simpleCollections.filter(({ is_cg_elite_only }) => !is_cg_elite_only)
    },
    availableTags () {
      const { availableCollections } = this
      return availableCollections.reduce((acc, collection) => {
        collection.tags.forEach((tag) => {
          const isTagAlreadyInAcc = acc.find((item) => item.id === tag.id)
          if (!isTagAlreadyInAcc) { acc.push(tag) }
        })

        return acc
      }, [{ id: 0, name: 'All collections' }])
    },
    filteredCollections () {
      const { availableCollections, search, filterTag } = this
      const textToSearch = (search ?? '').toLowerCase()

      let collections = availableCollections
        .filter(({ name }) => name.toLowerCase().indexOf(textToSearch) !== -1)

      if (filterTag !== 0) {
        collections = collections
          .filter(({ tags }) => tags.findIndex((tag) => tag.id === filterTag) !== -1)
      }

      return collections
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('newCampaign/setBreadcrumbs', [
        {
          routeName: 'CreateCampaignSource',
          title: 'Select gifts',
        },
        {
          routeName: 'CreateCampaignSourceCollections',
          title: 'Our collections',
        },
      ])
    })
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-source-collections {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 42px;

  &__filters {
    gap: 16px;
    display: flex;
    flex-direction: row;
    margin-right: auto;

    @media screen and (max-width: 430px) {
      flex-direction: column;
      width: 100%;

      & > div {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 100%;

    @media screen and (max-width: 430px) {
      gap: 24px;
    }
  }

  @media screen and (max-width: 430px) {
    gap: 30px;
  }
}
</style>
