<template>
  <div class="footer-partners-list">
    <a
      :href="item.link"
      v-for="(item, index) in items"
      :key="index"
      target="_blank"
    >
      <img alt="Partner logo" :src="item.url.data.attributes.url" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'TheFooterPartnersList',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    cfPath: 'https://cf.corporategift.com',
  }),
}
</script>

<style lang="scss" scoped>
.footer-partners-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;

  & a {
    width: fit-content;
  }

  & img {
    max-width: 218px;
    object-fit: contain;
  }

  @media (max-width: 639px) {
    flex-direction: row;
    justify-content: space-around;

    & img {
      max-width: 160px;
    }
  }
}
</style>
