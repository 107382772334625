const d = new Date()
const dateString = ('0' + d.getDate()).slice(-2) + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + d.getFullYear()

export const campaignForm = {
  surveyId: '',
  surveyName: '',
  surveyEmailQuestionId: '',
  productId: '',
  activeFrom: dateString,
  activeTo: dateString,
  emailSubject: '',
  giftMessage: '',
  isActive: false,
  budgetEntityId: 3
}
