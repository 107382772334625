import Api from '@/axios/api'

export default {
  getOrders ({ getters, commit, dispatch }) {
    commit('setLoadingOrders',  true)
    commit('setCurrentPage', 1)

    Api.get('/customer/orders', {
      params: getters.params,
    })
    .then(({ data, meta, links }) => {
      commit('setOrders', data)
      commit('setMeta', meta)
      commit('setLinks', links)
    })
    .catch((e) => (console.error(e)))
    .finally(() => {
      commit('setLoadingOrders',  false)
    })

    dispatch('getFiltersItems')
  },
  loadMoreOrders ({ state, getters, commit }) {
    commit('setCurrentPage', state.currentPage + 1)

    return Api.get('/customer/orders', {
      params: {
        page: state.currentPage,
        ...getters.params
      }
    })
    .then(({ data, meta, links }) => {
      commit('addOrders', data)
      commit('setMeta', meta)
      commit('setLinks', links)
    })
    .catch((e) => (console.error(e)))
  },
  getFiltersItems ({ commit, dispatch }) {
    dispatch('getFilter', 'type').then(({ data }) => {
      if (data?.length) commit('setFilterItemsTypes', data)
    })
    dispatch('getFilter', 'status').then(({ data }) => {
      if (data?.length) commit('setFilterItemsStatuses', data)
    })
    dispatch('getFilter', 'user').then(({ data }) => {
      if (data?.length) commit('setFilterItemsUsers', data)
    })
    dispatch('getFilter', 'source').then(({ data }) => {
      if (data?.length) commit('setFilterItemsSources', data)
    })
  },
  getFilter ({ getters }, type) {
    return Api.get('/customer/orders/filter/' + type, {
      params: getters.params
    })
  },
  approveOrder ({ commit }, { id }) {
    return Api.patch(`/customer/orders/${id}/approve`)
      .then(() => {
        commit('header/decreaseNumberOrderForApproval', 1, { root: true })
        commit('removeOrderWithId', id)
      })
      .catch((e) => Promise.reject(e?.response?.data?.message || 'An error occurred, please contact our support'))
  },
  declineOrder ({ commit }, { id }) {
    return Api.patch(`/customer/orders/${id}/decline`)
      .then(() => {
        commit('header/decreaseNumberOrderForApproval', 1, { root: true })
        commit('removeOrderWithId', id)
      })
      .catch((e) => Promise.reject(e?.response?.data?.message || 'An error occurred, please contact our support'))
  },
  resetFilters({ commit }) {
    commit('setFiltersSearch')
    commit('setFiltersDateRange')
    commit('setFiltersType')
    commit('setFiltersStatus')
    commit('setFiltersUser')
    commit('setFiltersSource')
    commit('setFiltersProductId')
    commit('setSearchSku')
  }
}
