<template>
  <div class="my-users-table">
    <my-users-table-header
      :available-items="activeUsers"
      :selected-items="selectedUsersIds"
      @selectAll="handleSelectAll()"
      @unselectAll="handleUnselectAll()"
      @editSelectedItems="handleEditSelectedItems"
    />
    
    <template v-if="!loading">
      <my-users-table-row
        v-for="user in users"
        :key="user.id"
        :item="user"
        :is-selected="selectedUsersIds.includes(user.id)"
        @select="handleSelectRow(user.id)"
        @unselect="handleUnselectRow(user.id)"
        @viewUserCart="handleViewUserCart"
        @editUserData="handleEditUserData"
      />
    </template>

    <common-loader
      v-else
      flex
      minHeightAuto
      relative
      style="padding: 100px 0"
    />

    <div v-if="!loading && showLoadMoreButton" class="my-users-table__load-more">
      <load-more
        :is-visible="showLoadMoreButton"
        :loading="loadingBtn"
        :size="loadMoreNumber"
        @more="showMoreUsers()"
      />
    </div>

    <my-users-cart-panel
      v-model="showUserCartPanel"
      :selected-user-id="editUserIdHelper"
    />

    <my-users-add-edit-user-panel
      v-model="showAddEditPanel"
      :selected-user-id="editUserIdHelper"
      @addMultipleUsers="showAddMultipleUsersPanel = true"
    />

    <my-users-add-multiple-users-panel v-model="showAddMultipleUsersPanel" />

    <my-users-edit-multiple-panel
      v-model="showEditMultiplePanel"
      :selectedUsersIds="selectedUsersIds"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MyUsersTableHeader from './MyUsersTableHeader.vue'
import MyUsersTableRow from './MyUsersTableRow.vue'

import LoadMore from '@/components/LoadMore'
import MyUsersCartPanel from './panels/MyUsersCartPanel.vue'
import MyUsersAddEditUserPanel from './panels/MyUsersAddEditUserPanel.vue'
import MyUsersEditMultiplePanel from './panels/MyUsersEditMultiplePanel.vue'
import MyUsersAddMultipleUsersPanel from './panels/MyUsersAddMultipleUsersPanel.vue'

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('users')

export default {
  name: 'MyUsersTable',
  components: {
    MyUsersAddMultipleUsersPanel,
    MyUsersEditMultiplePanel,
    MyUsersAddEditUserPanel,
    MyUsersTableHeader,
    MyUsersCartPanel,
    MyUsersTableRow,
    LoadMore,
  },
  data: () => ({
    loadingBtn: false,
    editUserIdHelper: null,
    showAddEditPanel: false,
    showUserCartPanel: false,
    showEditMultiplePanel: false,
    showAddMultipleUsersPanel: false,
  }),
  computed: {
    ...mapState([
      'users',
      'loading',
      'selectedIds',
    ]),
    ...mapGetters([
      'loadMoreNumber',
      'showLoadMoreButton',
    ]),
    activeUsers () {
      return this?.users?.filter((user) => (user?.is_active ?? true)) ?? []
    },
    selectedUsersIds: {
      get () { return this.selectedIds },
      set (val) { this.setSelectedIds(val) },
    },
  },
  watch: {
    showAddEditPanel: function (val) {
      if (!val) { this.editUserIdHelper = null }
    }
  },
  methods: {
    ...mapMutations([
      'addSelectedId',
      'setSelectedIds',
      'removeSelectedId',
    ]),
    ...mapActions(['loadMoreUsers']),
    showMoreUsers () {
      this.loadingBtn = true
      this.loadMoreUsers()
        .catch((e) => (console.error(e)))
        .finally(() => (this.loadingBtn = false))
    },
    handleSelectAll () {
      const itemsIds = this.activeUsers?.map((item) => item.id)
      this.selectedUsersIds = itemsIds
    },
    handleUnselectAll () {
      this.selectedUsersIds = []
    },
    handleSelectRow (userId) {
      this.addSelectedId(userId)
    },
    handleUnselectRow (userId) {
      this.removeSelectedId(userId)
    },
    handleViewUserCart (userId) {
      this.editUserIdHelper = userId
      this.showUserCartPanel = true
    },
    handleEditUserData (userId) {
      this.editUserIdHelper = userId
      this.showAddEditPanel = true
    },
    handleEditSelectedItems () {
      this.showEditMultiplePanel = true
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-table {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;

  &__load-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 120px;

    & > button {
      margin: 0 !important;
    }
  }
}
</style>
