<template>
  <v-menu offset-x open-on-hover z-index="4" :close-on-content-click="false">
    <template #activator="{ on, attrs }">
      <v-list-item dense v-bind="attrs" v-on="on">
        <v-list-item-title>
          Save to collections

          <icons-arrow-left
            color="currentColor"
            style="transform: rotate(180deg)"
            :height="12"
          />
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card class="save-to-collection" width="266" rounded="0">
      <div
        class="save-to-collection__new-item"
        :class="{
          'save-to-collection__new-item--active': addNewCollection,
          'save-to-collection__new-item--error': isNewWishlistNameExists
        }"
      >
        <div class="d-flex align-center" @click="addNewCollection = !addNewCollection">
          <icons-plus-circle :width="20" :height="20" />

          <template v-if="!addNewCollection">
            Add new collection
          </template>
        </div>
        <div class="d-flex flex-column" v-if="addNewCollection">
          <div class="d-flex flex-grow-1 align-center">
            <common-input
              v-model="newCollectionName"
              maxlength="30"
              :height="30"
              size="30"
              @keypress.enter="createNewList()"
            />
            <common-button
              :height="30"
              class="px-1"
              :disabled="disableCTAButon"
              :loading="addingNewCollection"
              @click="createNewList()"
            >
              Add
            </common-button>
          </div>
          <div class="save-to-collection__new-item-error" v-if="isNewWishlistNameExists">
            Name already exists
          </div>
        </div>
      </div>

      <div
        :class="{ 'save-to-collection__item--selected': productWishlists.includes(list.id) }"
        class="save-to-collection__item"
        v-for="(list, index) in filteredWishlists"
        :key="index"
        @click="handleWishListClick(list.id)"
      >
        <div class="save-to-collection__item--icon" />
        <div class="save-to-collection__item--name">
          {{ list.name }}
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import Api from '@/axios/api'

export default {
  name: 'TheMyFavoritesProductOptionsSaveToCollection',
  data: () => ({
    addNewCollection: false,
    newCollectionName: null,
    addingNewCollection: false,
  }),
  props: {
    productId: {
      type: [Number, String],
      required: true
    },
    wishlists: {
      type: Array,
      required: false,
      default: () => ([])
    },
    productWishlists: {
      type: Array,
      required: true
    },
    currentWishlistId: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    filteredWishlists () {
      const { wishlists, currentWishlistId } = this

      return wishlists.filter((list) => list.id !== currentWishlistId)
    },
    wishlistsNames () {
      return this.wishlists?.map((item) => item.name) ?? []
    },
    isNewWishlistNameExists () {
      const { wishlistsNames, newCollectionName } = this

      return wishlistsNames?.includes(newCollectionName?.trim()) ?? false
    },
    disableCTAButon () {
      const { newCollectionName, isNewWishlistNameExists } = this

      return !newCollectionName || newCollectionName.length < 2 || isNewWishlistNameExists
    }
  },
  methods: {
    createNewList () {
      if (this.disableCTAButon) return

      this.addingNewCollection = true

      Api.post('/customer/wishlists', {
        name: this.newCollectionName
      }).then(({ data, success }) => {
        if (success) {
          this.$emit('addNewList', data)
          this.addProductToList(data.id, this.productId)
          this.addingNewCollection = false
          this.newCollectionName = null
          this.addNewCollection = false
          this.$cgToast.successBold('Successfully created a new collection')
        }
      })
    },
    handleWishListClick (listId) {
      const { productWishlists, productId } = this
      if (productWishlists.includes(listId)) {
        this.removeProductFromList(listId, productId)
      } else {
        this.addProductToList(listId, productId)
      }
    },
    addProductToList (listId, productId) {
      this.$emit('addProductToList', { listId, productId })

      Api.patch(`/customer/wishlists/${listId}/products/${productId}/attach`)
        .then(({ success }) => {
          if (!success) this.$emit('removeProductFromList', { listId, productId })
        })
        .catch((e) => {
          console.error(e)
          this.$emit('removeProductFromList', { listId, productId })
        })
    },
    removeProductFromList (listId, productId) {
      this.$emit('removeProductFromList', { listId, productId })

      Api.patch(`/customer/wishlists/${listId}/products/${productId}/detach`)
        .then(({ success }) => {
          if (!success) this.$emit('addProductToList', { listId, productId })
        })
        .catch((e) => {
          console.error(e)
          this.$emit('addProductToList', { listId, productId })
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  border-radius: 0;
}

.save-to-collection {
  width: 266px;
  max-height: 280px;
  min-height: 45px;
  overflow-y: auto;
  padding: 14px;
  background-color: #fff;

  &__new-item {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
    color: #42B77A;
    display: flex;
    min-height: 40px;
    align-items: center;
    padding: 0px 8px;
    cursor: pointer;

    & > div > svg {
      transition: transform ease-in-out 0.2s;
      margin-right: 6px;
    }

    &--active {
      cursor: default;

      & > div > svg {
        cursor: pointer;
        transform: rotate(45deg);
      }

      & button {
        font-size: 12px;
      }
    }

    &--error {
      & > div > svg {
        margin-bottom: 20px;
      }
    }
  }

  &__new-item-error {
    font-family: 'Lato-Regular', sans-serif;
    display: flex;
    flex: 1 1;
    padding-top: 4px;
    font-size: 14px;
    color: #FF5A60;
  }

  &__item {
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    padding: 0px 8px;
    align-items: center;
    height: 40px;
    gap: 8px;
    background-color: inherit;

    &:hover {
      background-color: #D8F1E4;
    }

    &--name {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 155px;

      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      color: #16181F;
    }

    &--icon {
      display: flex;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      border-radius: 50%;
      border: 1px solid #DADADA;
      background: #fff;
      position: relative;
    }

    &--selected {
      background-color: #D8F1E4;

      .save-to-collection__item {
        &--icon {
          border: none;
          background-color: #42B77A;

          &::after {
            position: absolute;
            left: 50%;
            top: 48%;
            transform: translate(-50%,-50%) rotate(45deg);
            content: ' ';
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }
  }
}
</style>
