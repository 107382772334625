import { createNamespacedHelpers } from 'vuex'

const { mapState, mapMutations } = createNamespacedHelpers('new-orders')

export default {
  computed: {
    ...mapState([
      'filters',
      'searchSku',
      'filtersItems',
    ]),
    search: {
      get () { return this.filters.search },
      set (val) { this.setFiltersSearch(val) },
    },
    dateRange: {
      get () { return this.filters.dateRange },
      set (val) { this.setFiltersDateRange(val) },
    },
    type: {
      get () { return this.filters.orderType },
      set (val) { this.setFiltersType(val) },
    },
    status: {
      get () { return this.filters.orderStatus },
      set (val) { this.setFiltersStatus(val) },
    },
    user: {
      get () { return this.filters.orderUser },
      set (val) { this.setFiltersUser(val) },
    },
    source: {
      get () { return this.filters.orderSource },
      set (val) { this.setFiltersSource(val) },
    },
    productId: {
      get () { return this.filters.productId },
      set (val) { this.setFiltersProductId(val) },
    },
    productIdSearch: {
      get () { return this.searchSku },
      set (val) { this.setSearchSku(val) },
    },
    sourceItems () {
      const { source, filtersItems } = this
      const items = filtersItems?.sources ?? []

      return [
        source ? { text: 'All sources', value: null } : undefined,
        ...items,
      ]
    },
    statusItems () {
      const { status, filtersItems } = this
      const items = filtersItems?.statuses ?? []

      return [
        status ? { text: 'All statuses', value: null } : undefined,
        ...items,
      ]
    },
    typeItems () {
      const { type, filtersItems } = this
      const items = filtersItems?.types ?? []

      return [
        type ? { text: 'All types', value: null } : undefined,
        ...items,
      ]
    },
    userItems () {
      const { user, filtersItems } = this
      const items = filtersItems?.users ?? []

      return [
        user ? { name: 'All users', id: null } : undefined,
        ...items,
      ]
    },
  },
  methods: {
    ...mapMutations([
      'setFiltersSearch',
      'setFiltersDateRange',
      'setFiltersType',
      'setFiltersStatus',
      'setFiltersUser',
      'setFiltersSource',
      'setFiltersProductId',
      'setSearchSku',
    ])
  }
}