<template>
  <div class="send-egift-panel-recipients-contacts">
    <div class="send-egift-panel-recipients-contacts__header">
      <div>
        <common-input
          v-model="search"
          placeholder="Name, Email"
          autocomplete="search"
          prepend-inner-icon="mdi-magnify"
          style="min-width: 220px; max-width: 220px"
          id="search"
          :height="30"
        />
      </div>
      <div>
        <span>Show:</span>

        <common-toggle v-model="show.contacts" id="contacts-toggle">
          Contacts
          <div class="send-egift-panel-recipients-contacts__badge">
            {{ numberOfContacts }}
          </div>
        </common-toggle>
        <common-toggle v-model="show.groups" id="groups-toggle">
          Groups
          <div class="send-egift-panel-recipients-contacts__badge">
            {{ numberOfGroups }}
          </div>
        </common-toggle>
      </div>
    </div>

    <common-loader v-if="loading" style="padding: 100px 0" />

    <template v-else-if="!loading && !userHasContactsOrGroups">
      <div class="send-egift-panel-recipients-contacts__empty">
        <icons-empty-contacts />

        No contacts yet.
      </div>
    </template>

    <template v-else>
      <div>
        <div
          class="send-egift-panel-recipients-contacts__table-row"
          v-for="(contact) in filteredContacts"
          :key="contact.entity_id"
        >
          <div>
            <address-book-checkbox
              v-model="selectedContacts"
              :input-value="contact"
              :disabled="!contact.email_address"
            />
          </div>
          <div>{{ contact.firstname }} {{ contact.lastname }}</div>
          <div>
            <template v-if="editedContactId === contact.entity_id">
              <common-input
                v-model="editedContactForm.email_address"
                :rules="emailValidation"
                placeholder="Email"
                height="30"
              />
            </template>

            <template v-else-if="contact.email_address">
              {{ contact.email_address }}
            </template>

            <template v-else>
              <span
                class="send-egift-panel-recipients-contacts__missing-email-address"
                @click="editContactInfo(contact)"
              >
                Add email Address
              </span>
            </template>
          </div>
          <div>
            <template v-if="editedContactId === contact.entity_id">
              <icons-close-circle
                @click.native="cancelEditing"
                :height="24"
                :width="24"
                color="#95979D"
              />

              <icons-check-circle
                @click.native="saveChanges"
                :height="24"
                :width="24"
                :check-stroke-width="1.4"
                outlineCheckColor="#42B77A"
                outlineColor="#42B77A"
                fillColor="#fff"
              />
            </template>

            <template v-else>
              <icons-edit :width="15" :height="15" @click.native="editContactInfo(contact)" />
            </template>
          </div>
        </div>

        <div
          class="send-egift-panel-recipients-contacts__table-row"
          v-for="(group) in filteredGroups"
          :key="group.entity_id"
        >
          <div>
            <address-book-checkbox
              v-model="selectedContacts"
              :input-value="group"
            />
          </div>

          <div style="gap: 8px">
            <icons-contacts-group
              :width="17"
              :height="17"
            />
            {{ group.name }}
          </div>
          <div>{{ group.addresses_count }} Contacts</div>
          <div></div>
        </div>
      </div>

      <div
        class="send-egift-panel-recipients-contacts__actions"
        :class="{
          'send-egift-panel-recipients-contacts__actions--sticky': selectedContacts.length
        }"
      >
        <common-button
          :height="44"
          :width="200"
          :disabled="!selectedContacts.length"
          @click="handleContinue()"
        >
          Continue
        </common-button>

        <div v-if="numberOfSelectedContacts">
          Selected: {{ numberOfSelectedContacts }} {{ numberOfSelectedContacts > 1 ? 'recipients' : 'recipient' }}
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import type { IRecipient } from '../../types'

import Api from '@/axios/api'
import recipientsProp from '../mixins/recipientsProp'
import takeCurrentDate from '../mixins/takeCurrentDate'
import recipientValidations from '../mixins/recipientValidations'

import AddressBookCheckbox from '@/components/myCampaign/panels/sendEgiftPanel/recipients/TheRecipientsAddressBookCheckbox.vue'

interface ICampaignContact {
  email_address: string | null;
  entity_id: number;
  firstname: string | null;
  lastname: string | null;
  is_active: boolean;
}

interface ICampaignGroup {
  addresses_count: number;
  entity_id: number;
  name: string | null;
  addresses: Array<ICampaignContact>
}

interface IRequestBody {
  data: {
    addresses: Array<ICampaignContact>,
    groups: Array<ICampaignGroup>,
  }
}

@Component({
  components: {
    AddressBookCheckbox,
  },
})
export default class SendEgiftPanelRecipientsContacts extends Mixins(
  recipientsProp,
  takeCurrentDate,
  recipientValidations,
) {
  search: string | null = null;

  loading = false

  show = {
    contacts: true,
    groups: true,
  }

  editedContactId: number | null = null;

  editedContactForm: ICampaignContact = {
    email_address: null,
    entity_id: 0,
    firstname: null,
    lastname: null,
    is_active: false,
  }

  availableContacts: Array<ICampaignContact> = []

  availableGroups: Array<ICampaignGroup> = []

  selectedContacts: Array<ICampaignContact | ICampaignGroup> = []

  get numberOfContacts (): number {
    return this.availableContacts?.length ?? 0
  }

  get numberOfGroups (): number {
    return this.availableGroups?.filter(({ addresses_count }) => addresses_count > 0)?.length ?? 0
  }

  get userHasContactsOrGroups (): boolean {
    const { numberOfContacts, numberOfGroups } = this

    return !!(numberOfContacts || numberOfGroups)
  }

  get filteredContacts (): Array<ICampaignContact> {
    const { search, show: { contacts }, availableContacts } = this

    if (!contacts) { return [] }

    if (!search) { return availableContacts }

    return availableContacts.filter(({ email_address, firstname, lastname }) => {
      return [firstname, lastname, email_address]
        .filter((val) => val)
        .join(' ')
        .toLowerCase()
        .includes(search?.toLowerCase())
    })
  }

  get filteredGroups (): Array<ICampaignGroup> {
    const { search, show: { groups }, availableGroups } = this

    if (!groups) { return [] }

    if (!search) {
      return availableGroups.filter(({ addresses_count }) => addresses_count > 0)
    }

    return availableGroups.filter(({ name, addresses_count }) =>
      addresses_count > 0 && name?.toLowerCase()?.includes(search?.toLowerCase())
    )
  }

  get numberOfSelectedContacts (): number {
    return this.selectedContacts.reduce((acc, item) => {
      if ('name' in item) {
        acc += item.addresses_count
      } else {
        acc += 1
      }

      return acc
    }, 0)
  }

  mounted () {
    this.loading = true

    Api.get<any, IRequestBody>('/campaigns/send-egift/get-addresses')
      .then(({ data }) => {
        this.availableContacts = data?.addresses ?? []
        this.availableGroups = data?.groups ?? []
      })
      .finally(() => (this.loading = false))
  }

  editContactInfo (contact: ICampaignContact): void {
    this.editedContactId = contact.entity_id

    this.editedContactForm = { ...contact }
  }

  saveChanges (): void {
    const { editedContactId, editedContactForm, availableContacts } = this

    if (!editedContactForm.email_address) { return }

    const recipientIndex = availableContacts
      .findIndex(({ entity_id }) => entity_id === editedContactId)

    if (recipientIndex !== -1) {
      this.$set(this.availableContacts, recipientIndex, { ...editedContactForm, })
    }
    // TODO debug Vue reactivity
    this.availableGroups.forEach((group) => {
      if (group.addresses_count > 0) {
        group.addresses.forEach((contact) => {
          if (contact.entity_id === editedContactId) {
            contact.email_address === editedContactForm.email_address ?? null
          }
        })
      }
    })

    this.cancelEditing()
  }

  cancelEditing (): void {
    this.editedContactId = null

    this.editedContactForm = {
      email_address: null,
      entity_id: 0,
      firstname: null,
      lastname: null,
      is_active: false,
    }
  }

  handleContinue (): void {
    const { selectedContacts } = this
    const currentDate = this.takeCurrentDate()

    const createRecipientObject = ({ firstname, lastname, email_address }: ICampaignContact): IRecipient => ({
      firstName: firstname,
      lastName: lastname,
      email: email_address,
      sendDate: currentDate,
    })

    this.loading = true

    const newRecipients: Array<IRecipient> = selectedContacts.reduce<Array<IRecipient>>((acc, item) => {
      if ('name' in item) {
        item.addresses?.forEach((contact) => {
          acc.push(createRecipientObject(contact))
        })
      } else {
        acc.push(createRecipientObject(item))
      }

      return acc
    }, [])

    this.$emit('update:recipients', newRecipients)
    this.$emit('update:step', RootSteps.ReviewRecipients)
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-recipients-contacts {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 30px;

      & > span {
        font-family: 'Lato-Regular', sans-serif;
        font-size: 13px;
        line-height: 24px;
        color: #95979D;
      }
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
  }

  &__table-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 30px;
    padding: 14px;
    background: #fff;
    border-bottom: 1px solid #D3D2D2;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000000;

    & > div {
      display: flex;

      &:nth-child(1) {
        flex: 0 1 20px;
      }

      &:nth-child(2) {
        flex: 0 1 260px;
      }

      &:nth-child(3) {
        flex: 0 1 300px;
      }

      &:nth-child(4) {
        flex: 0 1 160px;
        justify-content: flex-end;
        gap: 8px;

        & > svg {
          cursor: pointer;
        }
      }
    }

    &:hover {
      background: #FAFAFA;
    }
  }

  &__missing-email-address {
    cursor: pointer;
    color: #f15b5b;
  }

  &__badge {
    min-width: 22px;
    height: 22px;
    background: #D2F5F3;
    border-radius: 48px;
    padding: 4px 6px;

    line-height: 1;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13px;

    display: flex;
    align-items: flex-end;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    color: #007E76;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 10px;
    gap: 20px;
    background: #fff;

    & > div {
      font-family: 'Lato-Light', sans-serif;
      color: #9B9B9B;
      font-size: 16px;
      line-height: 18px;
    }

    &--sticky {
      position: sticky;
      width: 100%;
      left: 0;
      bottom: -50px;
      margin-bottom: -50px;
      padding: 10px 0 50px;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 40px;

    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }
}
</style>
