<template>
  <h3>
    <slot />
  </h3>
</template>

<script>
export default {
  name: 'TheFooterItemTitle',
}
</script>

<style lang="scss" scoped>
h3 {
  padding-top: 12px;
  font-family: 'Lato-Bold', sans-serif;
  text-transform: uppercase;
  border-top: 2px solid #000;
  min-width: 255px;
  width: fit-content;
  font-size: 16px;
  color: #000;

  @media (max-width: 639px) {
    width: 100%;
  }
}
</style>
