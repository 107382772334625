<template>
  <div class="send-egift-panel-review__fields">
    <review-field
      v-if="giftDescription"
      :field-key="fieldsKeys[0]"
      :ref="fieldsKeys[0]"
      :allow-to-click-label-in-edit-mode="true"
      @onLabelClick="handleLabelClick"
    >
      <template #label>
        Description

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_gift_description') }}
        </common-tooltip>
      </template>

      <template #review-mode>
        {{ giftDescription }}
      </template>

      <template #edit-mode>
        <review-textarea
          v-model="giftDescription"
          @exitEditMode="onExitEditMode(fieldsKeys[0])"
        />
      </template>
    </review-field>

    <review-field
      :editable="false"
      @onLabelClick="showRecipientView"
    >
      <template #label>
        {{ numberOfRecipients > 1 ? 'Recipients' : 'Recipient' }}
      </template>

      <template #review-mode>
        {{ recipientText }}
      </template>
    </review-field>

    <review-field v-if="!isSelectedGiftLink" :editable="false" disabled>
      <template #label>
        From

        <common-tooltip
          right
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img
            alt="Email title tooltip"
            :src="getProductImageBySlug('campaigns_email_from')"
          />
        </common-tooltip>
      </template>

      <template #review-mode>
        {{ form.from }}
      </template>
    </review-field>

    <review-field
      v-if="!isSelectedGiftLink"
      :field-key="fieldsKeys[1]"
      :ref="fieldsKeys[1]"
      :allow-to-click-label-in-edit-mode="true"
      @onLabelClick="handleLabelClick"
    >
      <template #label>
        Email subject line

        <common-tooltip
          right
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img
            alt="Email subject line tooltip"
            width="590"
            height="150"
            :src="getProductImageBySlug('campaigns_edit_email_subject')"
          />
        </common-tooltip>
      </template>

      <template #review-mode>
        <template v-if="giftEmailSubjectLine">
          {{ giftEmailSubjectLine }}
        </template>

        <div v-else class="send-egift-panel-review__field-placeholder">
          No saved email subject line
        </div>
      </template>

      <template #edit-mode>
        <review-textarea
          v-model="giftEmailSubjectLine"
          :rules="[v => !!v || '']"
          @exitEditMode="onExitEditMode(fieldsKeys[1])"
        />
      </template>
    </review-field>

    <review-field
      v-if="!isSelectedGiftLink"
      :editable="false"
      @onLabelClick="showGreetingMessageView"
    >
      <template #label>
        Greeting

        <common-tooltip
          right
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img
            alt="Greeting message tooltip"
            width="500"
            height="350"
            :src="getProductImageBySlug('campaigns_greeting_preview')"
          />
        </common-tooltip>
      </template>

      <template #review-mode>
        <template v-if="giftGreetingMessage">
          <span v-html="giftGreetingMessage" />
        </template>

        <div v-else class="send-egift-panel-review__field-placeholder">
          No saved greeting
        </div>
      </template>
    </review-field>

    <review-field
      v-if="isLinkTypeSetToRestricted"
      :field-key="fieldsKeys[6]"
      :ref="fieldsKeys[6]"
      :allow-to-click-label-in-edit-mode="true"
      @onLabelClick="handleLabelClick"
    >
      <template #label>
        Welcome message
      </template>

      <template #review-mode>
        <template v-if="giftWelcomeMessage">
          {{ giftWelcomeMessage }}
        </template>

        <div v-else class="send-egift-panel-review__field-placeholder">
          Enter your details to claim your gift
        </div>
      </template>

      <template #edit-mode>
        <review-textarea
          v-model="giftWelcomeMessage"
          maxLength="450"
          @exitEditMode="onExitEditMode(fieldsKeys[6])"
        />
      </template>
    </review-field>

    <review-field
      :editable="false"
      @onLabelClick="showLogosAndImagesView"
    >
      <template #label>
        Logo

        <common-tooltip
          right
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img
            alt="Email logo tooltip"
            width="476"
            height="280"
            :src="getProductImageBySlug('campaigns_logo_preview')"
          />
        </common-tooltip>
      </template>

      <template #review-mode>
        <img
          v-if="giftLogo"
          :src="giftLogo"
          alt="Logo preview"
          class="send-egift-panel-review__logo-preview"
        >

        <div v-else class="send-egift-panel-review__field-placeholder">
          No saved logo
        </div>
      </template>
    </review-field>

    <review-field
      :field-key="fieldsKeys[2]"
      :ref="fieldsKeys[2]"
      :allow-to-click-label-in-edit-mode="true"
      @onLabelClick="handleLabelClick"
    >
      <template #label>
        Gift giver name

        <common-tooltip
          right
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img
            alt=""
            :src="getProductImageBySlug(isSelectedGiftLink ? 'campaigns_gift_giver_name--link' : 'campaigns_gift_giver_name')"
          />
        </common-tooltip>
      </template>

      <template #review-mode>
        <template v-if="giftGiverName">
          {{ giftGiverName }}
        </template>

        <div v-else class="send-egift-panel-review__field-placeholder">
          No saved gift giver name
        </div>
      </template>

      <template #edit-mode>
        <review-textarea
          v-model="giftGiverName"
          :rules="[v => !!v || '']"
          @exitEditMode="onExitEditMode(fieldsKeys[2])"
        />
      </template>
    </review-field>

    <review-field
      :field-key="fieldsKeys[3]"
      :ref="fieldsKeys[3]"
      :allow-to-click-label-in-edit-mode="true"
      @onLabelClick="handleLabelClick"
    >
      <template #label>
        Gift giver email

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_gift_giver_email') }}
        </common-tooltip>
      </template>

      <template #review-mode>
        <template v-if="giftGiverEmail">
          {{ giftGiverEmail }}
        </template>

        <div v-else class="send-egift-panel-review__field-placeholder">
          No saved gift giver email
        </div>
      </template>

      <template #edit-mode>
        <review-textarea
          v-model="giftGiverEmail"
          :rules="[v => !!v || '']"
          @exitEditMode="onExitEditMode(fieldsKeys[3])"
        />
      </template>
    </review-field>

    <review-field
      v-if="giftGreetingVideo"
      :field-key="fieldsKeys[4]"
      :ref="fieldsKeys[4]"
      :allow-to-click-label-in-edit-mode="true"
      @onLabelClick="handleLabelClick"
    >
      <template #label>
        Video greeting

        <common-tooltip
          right
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img
            alt=""
            width="534"
            height="360"
            :src="getProductImageBySlug('campaigns_video_greeting_link')"
          />
        </common-tooltip>
      </template>

      <template #review-mode>
        <review-video-thumbnail :videoUrl="giftGreetingVideo" />
      </template>

      <template #edit-mode>
        <review-textarea
          v-model="giftGreetingVideo"
          @exitEditMode="onExitEditMode(fieldsKeys[4])"
        />
      </template>
    </review-field>

    <review-field
      v-if="isCalendlyLinkSet"
      :field-key="fieldsKeys[5]"
      :ref="fieldsKeys[5]"
      :allow-to-click-label-in-edit-mode="true"
      @onLabelClick="handleLabelClick"
    >
      <template #label>
        Calendly link

        <common-tooltip
          right
          :top="false"
          :max-width="null"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <img
            alt=""
            width="470"
            height="280"
            :src="getProductImageBySlug('campaigns_calendly_meeting_link')"
          />
        </common-tooltip>
      </template>

      <template #review-mode>
        <a
          :href="giftCalendlyLink"
          @click.stop
          target="_blank"
          rel="noopener noreferrer"
          class="send-egift-panel-review__link send-egift-panel-review__link--calendly"
        >
          {{ giftCalendlyLink }}
        </a>
      </template>

      <template #edit-mode>
        <review-textarea
          v-model="giftCalendlyLink"
          @exitEditMode="onExitEditMode(fieldsKeys[5])"
        />
      </template>
    </review-field>

    <review-fields-settings
      v-bind="{ ...$props }"
      :canReEgift.sync="giftCanReEgift"
      :canUpgrade.sync="giftCanUpgrade"
    />

    <review-fields-link-type
      v-if="isSelectedGiftLink"
      v-bind="{ ...$props }"
      :linkType.sync="giftLinkType"
    />

    <review-fields-payment
      v-if="campaignTotalCost > 0 && !checkoutNeedsApproval"
      v-bind="{ ...$props }"
      v-on="$listeners"
    />

    <review-fields-arrival-date
      v-if="giftShippingArrivalDate && !isPreProduction"
      :arrivalDate.sync="giftShippingArrivalDate"
    />

    <review-field
      v-if="!giftHasReminderEmails && !isSelectedGiftLink"
      :disabled="!canOverrideDisableReminders"
    >
      <template #label>
        Reminder email

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_disable_reminder_emails') }}
        </common-tooltip>
      </template>

      <template #review-mode>
        {{ giftHasReminderEmails ? 'Yes' : 'Disabled' }}
      </template>

      <template #edit-mode>
        <common-checkbox v-model="giftHasReminderEmails">
          Send reminder email to recipients
        </common-checkbox>
      </template>
    </review-field>

    <div class="send-egift-panel-review__divider" />

    <div
      v-if="showMoreOptionsLink"
      class="send-egift-panel-review__link"
      style="padding: 0 10px"
      @click="showMoreOptionsView"
    >
      More options
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps, RecipientMethod, LinkType } from '../../types'
import type { IReviewField } from '../../types'

import personalSpendLimitProp from '../mixins/personalSpendLimitProp'
import campaignTotalCostProp from '../mixins/campaignTotalCostProp'
import getProductImageBySlug from '@/mixins/getProductImageBySlug'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import recipientMethodProp from '../mixins/recipientMethodProp'
import defaultPaymentProp from '../mixins/defaultPaymentProp'
import recipientsProp from '../mixins/recipientsProp'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'

import ReviewField from '../components/ReviewField.vue'
import ReviewTextarea from '../components/ReviewTextarea.vue'
import ReviewFieldsPayment from '../components/ReviewFieldsPayment.vue'
import ReviewFieldsSettings from '../components/ReviewFieldsSettings.vue'
import ReviewVideoThumbnail from '../components/ReviewVideoThumbnail.vue'
import ReviewFieldsLinkType from '../components/ReviewFieldsLinkType.vue'
import ReviewFieldsArrivalDate from '../components/ReviewFieldsArrivalDate.vue'

enum FieldsKeys {
  GiftDescription = 'gift-description',
  GiftEmailSubjectLine = 'gift-email-subject-line',
  GiftGiverName = 'gift-giver-name',
  GiftGiverEmail = 'gift-giver-email',
  GiftGreetingVideo = 'gift-greeting-video',
  GiftCalendlyLink = 'gift-calendly-link',
  GiftWelcomeMessage = 'gift-welcome-message'
}

@Component({
  mixins: [
    getTooltipTextBySlug,
    getProductImageBySlug,
  ],
  components: {
    ReviewField,
    ReviewTextarea,
    ReviewFieldsPayment,
    ReviewFieldsSettings,
    ReviewVideoThumbnail,
    ReviewFieldsLinkType,
    ReviewFieldsArrivalDate,
  }
})
export default class SendEgiftPanelReviewFields extends Mixins(
  personalSpendLimitProp,
  campaignTotalCostProp,
  recipientMethodProp,
  defaultPaymentProp,
  recipientsProp,
  panelTypeProp,
  campaignProp,
  formProp,
) {
  fieldsKeys: Array<string> = Object.values(FieldsKeys)

  fieldsEditModeHelper: Array<{ key: string, value: boolean }> = this.fieldsKeys
    .map((fieldKey) => ({
      key: fieldKey,
      value: false,
    }));
  // object with keys that is set to every field
  // object that is using keys to check if field is in edit mode

  get isPreProduction (): boolean {
    return this.campaign?.is_pre_production ?? false
  }

  get giftDescription (): string | null {
    return this.form.description
  }

  set giftDescription (val: string | null) {
    this.$emit('update:description', val)
  }

  get recipientText (): string {
    const { numberOfRecipients, recipients } = this

    return numberOfRecipients > 1
      ? `${numberOfRecipients} recipients`
      : recipients[0]?.email || '1 Recipient'
  }

  get giftEmailSubjectLine (): string | null {
    return this.form.emailSubjectLine
  }

  set giftEmailSubjectLine (val: string | null) {
    this.$emit('update:emailSubjectLine', val)
  }

  get giftGreetingMessage (): string | null {
    const { greeting } = this.form
    const message = greeting?.replaceAll('\n', '')

    if (!message) { return null }

    return this.form.greeting?.replace(/\n{2,}/g, '<br />') ?? null
  }

  get giftWelcomeMessage (): string | null {
    return this.form?.welcomeMessage ?? null
  }

  set giftWelcomeMessage (val: string | null) {
    this.$emit('update:welcomeMessage', val)
  }

  get giftLogo (): string | null {
    return this.form.logoUrl ?? null
  }

  get giftGiverName (): string | null {
    return this.form.giftGiverName
  }

  set giftGiverName (val: string | null) {
    this.$emit('update:giftGiverName', val)
  }

  get giftGiverEmail (): string | null {
    return this.form.giftGiverEmail
  }

  set giftGiverEmail (val: string | null) {
    this.$emit('update:giftGiverEmail', val)
  }

  get giftGreetingVideo (): string | null {
    return this.form.videoGreeting
  }

  set giftGreetingVideo (val: string | null) {
    this.$emit('update:videoGreeting', val)
  }

  get giftCalendlyLink (): string | null {
    return this.form.calendlyLink
  }

  set giftCalendlyLink (val: string | null) {
    this.$emit('update:calendlyLink', val)
  }

  get isCalendlyLinkSet (): boolean {
    const { form: { actionLinkType }, giftCalendlyLink } = this

    return !!(giftCalendlyLink && actionLinkType === 'calendly');
  }

  get giftCanUpgrade (): boolean {
    return this.form.settings?.recipientCanUpgrade ?? false
  }

  set giftCanUpgrade (val: boolean) {
    this.$emit('update:recipientCanUpgrade', val)
  }

  get giftCanReEgift (): boolean {
    return this.form.settings?.recipientCanReEgift ?? false
  }

  set giftCanReEgift (val: boolean) {
    this.$emit('update:recipientCanReEgift', val)
  }

  get giftLinkType (): LinkType {
    return this.form.linkType
  }

  set giftLinkType (val: LinkType) {
    this.$emit('update:linkType', val)
  }

  get giftShippingArrivalDate (): string | null {
    return this.form.shippingArrivalDate ?? null
  }

  set giftShippingArrivalDate (val: string | null) {
    this.$emit('update:shippingArrivalDate', val)
  }

  get giftHasReminderEmails (): boolean {
    return !this.form.settings.disableEmailReminders ?? false
  }

  set giftHasReminderEmails (val: boolean) {
    this.$emit('update:disableEmailReminders', !val)
  }

  get showMoreOptionsLink (): boolean {
    const {
      videoGreeting,
      calendlyLink,
      shippingArrivalDate,
      description,
      settings: { disableEmailReminders }
    } = this.form

    return !videoGreeting
      || !calendlyLink
      || !shippingArrivalDate
      || !description
      || !disableEmailReminders
  }

  get isLinkTypeSetToRestricted (): boolean {
    return this.giftLinkType === LinkType.SingleLink
  }

  get canOverrideDisableReminders (): boolean {
    return this.campaign?.egift_settings?.can_override_disable_recipient_reminders ?? false
  }

  get checkoutNeedsApproval (): boolean {
    return this.$store.state.header?.headerData?.checkoutNeedsApproval ?? false
  }

  handleLabelClick ({ value, key }: { value: boolean, key: string | null }) {
    const fieldIndex = this.fieldsEditModeHelper.findIndex((item) => item.key === key)

    if (fieldIndex !== -1) {
      const fieldItem = this.fieldsEditModeHelper[fieldIndex]

      this.$set(this.fieldsEditModeHelper, fieldIndex, {
        ...fieldItem,
        value,
      })
    }
  }

  showMoreOptionsView (): void {
    this.$emit('update:step', RootSteps.MoreOptions)
  }

  showRecipientView (): void {
    this.$emit(
      'update:step',
      this.recipientMethod === RecipientMethod.UnrestrictedLink
        ? RootSteps.Recipient
        : RootSteps.ReviewRecipients
    )
  }

  showGreetingMessageView (): void {
    this.$emit('update:step', RootSteps.GreetingMessage)
  }

  showLogosAndImagesView (): void {
    this.$emit('update:step', RootSteps.LogosAndImages)
  }

  onExitEditMode (refName: FieldsKeys): void {
    const refElement = this.$refs?.[refName]

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-review {
  &__fields {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 10px;
  }

  &__logo-preview {
    object-fit: contain;
    aspect-ratio: 1 / 1;
    width: 100px;
    height: 100px;
  }

  &__field-placeholder {
    color: #95979D;
    font-family: 'Lato-Italic', sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: #D3D2D2;
  }

  &__link {
    font-family: 'Lato-Regular', sans-serif;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    color: #42B77A !important;

    &:hover {
      color: #219358 !important;
    }

    &--calendly {
      font-size: inherit;
      line-height: inherit;
    }
  }
}
</style>
