<template>
  <review-field
    ref="review-field"
    :edit-mode-as-column="true"
    :allow-to-click-label-in-edit-mode="true"
    @onLabelClick="({ value }) => inEditMode = value"
  >
    <template #label>
      <div style="display: flex; gap: 8px;">
        Arrival date

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_shipping_dispatch_date') }}
        </common-tooltip>
      </div>

      <review-save-cancel-icons
        v-if="inEditMode"
        class="review-fields-arrival-date__options"
        @onSave="handleOnSave"
        @onCancel="handleOnCancel"
      />
    </template>

    <template #review-mode>
      {{ arrivalDate | date }}
    </template>

    <template #edit-mode>
      <single-date-range-picker
        v-model="arrivalDateHelper"
        placeholder="Choose an arrival date"
        style="position: relative;"
        set-min-date-week-later
        show-remove-item
        height="40"
        picker-style="--min-width: 450px;--picker-margin-top: 3px;"
      />
    </template>
  </review-field>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import type { IReviewField } from '../../types'

import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import ReviewField from './ReviewField.vue'
import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'
import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

@Component({
  mixins: [getTooltipTextBySlug],
  components: {
    ReviewField,
    ReviewSaveCancelIcons,
    SingleDateRangePicker,
  },
})
export default class ReviewFieldsArrivalDate extends Vue {
  @Prop({ required: false, default: null }) arrivalDate!: string | null;

  inEditMode = false;

  arrivalDateHelper: string | null = null;

  @Watch('inEditMode', { immediate: false })
  onEditModeChange (newVal: boolean) {
    if (newVal) {
      this.arrivalDateHelper = this.arrivalDate;
    }
  }

  handleOnSave (): void {
    this.$emit('update:arrivalDate', this.arrivalDateHelper)

    this.handleOnCancel()
  }

  handleOnCancel (): void {
    const refElement = this.$refs?.['review-field']

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
</script>

<style lang="scss" scoped>
.review-fields-arrival-date {
  &__options {
    margin-top: 0;
    margin-left: auto;
    pointer-events: all;
  }
}
</style>
