<template>
  <v-menu
    transition="slide-y-transition"
    bottom
    :close-on-content-click="false"
    nudge-bottom="30"
    tile
    left
    max-height="500"
  >
    <template #activator="{ on, attrs }">
      <div
        class="d-flex pointer align-center g10"
        v-bind="attrs"
        v-on="on"
      >
        <icons-add-contact />
        <span class="campaign-settings__add-users__link">{{ activatorText }}</span>
      </div>
    </template>

    <template>
      <div class="px-2 pt-2" style="background: #fff">
        <common-input
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          height="30"
        />
        <div class="campaign-settings__add-users__link pt-2" @click="selectUnselectAll()">
          {{ areAllUsersSelected ? 'Unselect': 'Select' }} all
        </div>
      </div>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="selectUnselectItem(item)"
          class="px-2 pointer"
        >
          <v-list-item-icon class="mr-2 my-1">
            <icons-check-circle
              height="28"
              width="28"
              :filled="isItemSelected(item)"
              :hideCheck="!isItemSelected(item)"
            />
          </v-list-item-icon>
          <v-list-item-title>
            {{ item | addUserName }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-menu>
</template>

<script>
export default {
  name: 'TheCampaignSettingsPanelAddUsers',
  filters: {
    addUserName: function (item) {
      const { firstname, lastname, email } = item

      let result = ''
      if (firstname) result += `${firstname} `
      if (lastname) result += lastname

      return result || email || ''
    }
  },
  props: {
    activatorText: {
      type: String,
      required: false,
      default: 'Add users'
    },
    value: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    search: null
  }),
  computed: {
    filteredItems () {
      const { search, items } = this
      const searchValue = search?.toLowerCase() || ''

      return items?.filter((item) => {
        const firstName = item?.firstname?.toLowerCase() || ''
        const lastName = item?.lastname?.toLowerCase() || ''
        const email = item?.email?.toLowerCase() || ''

        if (
          firstName.includes(searchValue) ||
          lastName.includes(searchValue) ||
          email.includes(searchValue)
        ) return item
      })
    },
    areAllUsersSelected () {
      const { value, items } = this
      return value.length === items.length
    }
  },
  methods: {
    isItemSelected ({ id }) {
      const { value } = this

      return value?.map((item) => item?.id)?.includes(id) || false
    },
    selectUnselectItem (item) {
      const { value } = this
      const findIndex = value?.findIndex((vItem) => vItem.id === item.id)

      const selectedItems = Object.assign([], value)

      if (findIndex !== -1) {
        selectedItems.splice(findIndex, 1)
        this.$emit('input', selectedItems)
      } else {
        selectedItems.push(item)
        this.$emit('input', selectedItems)
      }
    },
    selectUnselectAll () {
      const { items, areAllUsersSelected } = this

      this.$emit('input', areAllUsersSelected ? [] : items)
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-settings__add-users {
  &__link {
    font-family: 'Lato-Regular', sans-serif !important;
    line-height: 17px;
    color: #42B77A;
    font-size: 14px;
    letter-spacing: 0;
    cursor: pointer;
  }
}
</style>
