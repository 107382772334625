<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M13.5 10.9167V13.6667H2.49996V10.9167H0.666626V13.6667C0.666626 14.675 1.49163 15.5
        2.49996 15.5H13.5C14.5083 15.5 15.3333 14.675 15.3333 13.6667V10.9167H13.5ZM3.41663
        5.41671L4.70913 6.70921L7.08329 4.34421V11.8334H8.91663V4.34421L11.2908 6.70921L12.5833
        5.41671L7.99996 0.833374L3.41663 5.41671Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadFile',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 16
    },
    height: {
      type: [Number, String],
      required: false,
      default: 16
    }
  }
}
</script>
