<template>
  <div class="send-options-container">
    <h1>Select sending option</h1>

    <ul>
      <li @click="sendAsEgift">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="20" fill="#E5DFFF"/>
          <g clip-path="url(#clip0_1_82415)">
            <path d="M28.8177 12.1824C28.9043 12.2691 28.9634 12.3793 28.9879 12.4993C29.0123 12.6193 29.001 12.7438 28.9552 12.8574L21.6815 31.0412C21.6174 31.2013 21.5103 31.3407 21.3721 31.4439C21.2339 31.5471 21.0699 31.6102 20.8982 31.6262C20.7265 31.6423 20.5536 31.6106 20.3987 31.5347C20.2438 31.4589 20.1128 31.3417 20.0202 31.1962L16.0477 24.9524L9.80397 20.9799C9.65812 20.8874 9.54066 20.7564 9.46456 20.6014C9.38845 20.4463 9.35667 20.2732 9.3727 20.1013C9.38873 19.9293 9.45197 19.7651 9.55542 19.6268C9.65887 19.4885 9.79853 19.3814 9.95897 19.3174L28.1427 12.0462C28.2563 12.0005 28.3809 11.9891 28.5009 12.0136C28.6209 12.038 28.7311 12.0972 28.8177 12.1837V12.1824ZM17.2952 24.5874L20.7465 30.0099L26.6627 15.2199L17.2952 24.5874ZM25.779 14.3362L10.989 20.2524L16.4127 23.7024L25.7802 14.3362H25.779Z" fill="#222325"/>
          </g>
          <defs>
            <clipPath id="clip0_1_82415">
              <rect width="20" height="20" fill="white" transform="translate(9 12)"/>
            </clipPath>
          </defs>
        </svg>

        <b>Send eGift</b>

        Email sent via Corporategift.com system
      </li>
      <li @click="sendAsLink">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="20" fill="#AEFAFF"/>
          <path d="M21.2864 18.7133C20.6038 18.031 19.6782 17.6477 18.7131 17.6477C17.748 17.6477 16.8223 18.031 16.1398 18.7133L13.5656 21.2866C12.883 21.9692 12.4995 22.895 12.4995 23.8604C12.4995 24.8257 12.883 25.7515 13.5656 26.4341C14.2482 27.1167 15.174 27.5002 16.1393 27.5002C17.1047 27.5002 18.0305 27.1167 18.7131 26.4341L19.9998 25.1475" stroke="#222325" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.7129 21.2867C19.3955 21.969 20.3211 22.3523 21.2862 22.3523C22.2513 22.3523 23.177 21.969 23.8596 21.2867L26.4337 18.7133C27.1163 18.0307 27.4998 17.1049 27.4998 16.1396C27.4998 15.1742 27.1163 14.2484 26.4337 13.5658C25.7511 12.8832 24.8253 12.4998 23.86 12.4998C22.8946 12.4998 21.9688 12.8832 21.2862 13.5658L19.9996 14.8525" stroke="#222325" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <b>Create gift-link</b>

        You’ll send via your email/SMS/Slack/other
      </li>
    </ul>
  </div>
</template>

<script>
import panelType from '../mixins/panelType'

export default {
  name: 'TheSendOptions',
  mixins: [panelType],
  methods: {
  sendAsEgift () {
    this.$emit('update:panelType', null)
    this.$nextTick(() => {
      this.$emit('update:panelType', 'egift')
      this.$emit('showRecipients')
    })
  },
  sendAsLink () {
    this.$emit('update:panelType', null)
    this.$nextTick(() => {
      this.$emit('update:panelType', 'link')
      this.$emit('showRecipients')
    })
  }
  },
}
</script>

<style lang="scss" scoped>
.send-options-container {
  padding: 16px 36px;
}

h1 {
  font-family: 'Lato-Light', sans-serif;
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  color: #000;
  padding-bottom: 36px;
}

ul {
  display: flex;
  flex-direction: row;
  gap: 30px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;

  & > li {
    padding: 30px 20px;
    text-align: center;
    display: flex;
    flex: 1 1 0px;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    font-size: 15px;
    font-family: 'Lato-Italic', sans-serif;
    color: #62646A;
    // font-style: italic;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    border-radius: 16px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
    transition: background-color ease-in-out 0.36s;

    & > b {
      font-weight: 700;
      font-family: 'Lato', sans-serif;
      color: #222325;
    }

    &:hover {
      background-color: #F6F5FF;
    }
  }
}
</style>
