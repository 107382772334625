<template>
  <div class="send-egift-panel-review">
    <send-egift-panel-review-fields
      v-bind="{ ...$props }"
      v-on="$listeners"
    />
    
    <send-egift-panel-review-summary
      v-bind="{ ...$props }"
      v-on="$listeners"
    />
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'

import campaignShippingCostProp from '../mixins/campaignShippingCostProp'
import personalSpendLimitProp from '../mixins/personalSpendLimitProp'
import campaignTotalCostProp from '../mixins/campaignTotalCostProp'
import recipientMethodProp from '../mixins/recipientMethodProp'
import defaultPaymentProp from '../mixins/defaultPaymentProp'
import approvalDataProps from '../mixins/approvalDataProps'
import recipientsProp from '../mixins/recipientsProp'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'

import SendEgiftPanelReviewSummary from './SendEgiftPanelReviewSummary.vue'
import SendEgiftPanelReviewFields from './SendEgiftPanelReviewFields.vue'

@Component({
  components: {
    SendEgiftPanelReviewSummary,
    SendEgiftPanelReviewFields,
  },
})
export default class SendEgiftPanelReview extends Mixins(
  campaignShippingCostProp,
  personalSpendLimitProp,
  campaignTotalCostProp,
  recipientMethodProp,
  defaultPaymentProp,
  approvalDataProps,
  recipientsProp,
  panelTypeProp,
  campaignProp,
  formProp,
) {}
</script>

<style lang="scss" scoped>
.send-egift-panel-review {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 50px;
  padding-bottom: 220px;
}
</style>
