var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"active-egift-orders dashboard-card dashboard-card--small",class:{
    'order-2': !_vm.hasCgCredits && _vm.$vuetify.breakpoint.lgAndUp,
    'order-3': _vm.adminAccount && !_vm.$vuetify.breakpoint.lgAndUp && !_vm.$vuetify.breakpoint.xsOnly,
    'order-4': (_vm.adminAccount && _vm.$vuetify.breakpoint.lgAndUp) ||
      !_vm.adminAccount && _vm.hasCgCredits ||
      _vm.marketplaceUser || _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('div',{staticClass:"active-egift-orders__title"},[_vm._v(" Active e-Gift Orders "),_c('dashboard-card-tooltip',{attrs:{"offset":"0, 5px"}},[_vm._v(" "+_vm._s(_vm.getTooltipTextBySlug('dashboard_account_active_egift_orders'))+" ")])],1),_c('v-divider'),(!_vm.loading)?[(_vm.activeOrders > 0 || _vm.unredeemedGifts > 0)?_c('div',{staticClass:"active-egift-orders__info"},[_c('div',{staticClass:"info__gits-amount"},[_c('div',{staticClass:"amount-info"},[_c('span',[_vm._v(_vm._s(_vm.activeOrders))]),_c('span',[_vm._v("Active orders")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"amount-info"},[_c('span',[_vm._v(_vm._s(_vm.unredeemedGifts))]),_c('span',[_vm._v("Unredeemed eGifts")])])],1),_c('div',{staticClass:"info__unredeemed"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("priceFilter")(_vm.unredeemedValue))+" ")]),_c('span',[_vm._v(" Total unredeemed value ")])]),_c('div',{staticClass:"info__links"},[_c('router-link',{attrs:{"to":{ name: 'EgiftOrders' }}},[_vm._v("View all eGift orders")])],1)]):_c('div',{staticClass:"active-egift-orders__empty"},[_c('icons-empty-active-egift-orders'),_c('span',[_vm._v(" You have no active eGift orders "),_c('router-link',{attrs:{"to":{ name: 'EgiftOrders' }}},[_vm._v("View all eGift orders")])],1)],1)]:_c('common-loader')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }