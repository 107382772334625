<template>
  <v-dialog
    v-model="show"
    content-class="elevation-0 ma-0"
    width="900"
  >
    <v-container class="explanatory-gif-modal__container">
      <v-row no-gutters>
        <v-col>
          <div class="explanatory-gif-modal__content">
            <h1>{{ altText }}</h1>

            <img :src="hideGif ? null : linkToGif" :alt="altText">
          </div>
        </v-col>
        <v-col>
          <v-icon color="white" class="pointer" x-large @click="show = false">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'ExplanatoryGifModal',
  mixins: [panelVModel],
  props: {
    linkToGif: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: false,
      default: 'Explanatory gif'
    }
  },
  data: () => ({
    hideGif: false,
    timeoutId: null,
  }),
  watch: {
    show: function (val) {
      if (val) {
        this.hideGif = false

        clearTimeout(this.timeoutId)
        this.timeoutId = null
      } else {
        this.timeoutId = setTimeout(() => { this.hideGif = true }, 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.explanatory-gif-modal {
  &__container {
    padding: 0;
    margin: 0;

    & > .row {
      display: flex;
      gap: 20px;
      flex-wrap: nowrap;
      flex-direction: row;

      & > .col {
        flex: 1 1 auto;

        &:nth-child(2) {
          flex: 0 1 40px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    position: relative;
    gap: 20px;
    padding: 30px;
    background-color: #fff;

    & > h1 {
      margin: 0;
      font-family: 'Lato', sans-serif;
      font-weight: 500;
      font-size: 32px;
      color: #000000;
    }

    & > img {
      width: 100%;
      min-width: 550px;
      object-fit: contain;
    }
  }
}
</style>
