export default {
  setLoadingOrders (state, value = false) {
    state.loadingOrders = value
  },
  setOrders (state, orders) {
    state.orders = orders
  },
  addOrders (state, newOrders) {
    state.orders.push(...newOrders)
  },
  setMeta (state, meta) {
    state.meta = {
      currentPage: meta.current_page,
      from: meta.from,
      lastPage: meta.last_page,
      perPage: meta.per_page,
      to: meta.to,
      total: meta.total
    }
  },
  setLinks (state, links) {
    state.links = links
  },
  setCurrentPage (state, value = 1) {
    state.currentPage = value
  },
  setOrderShippingData (state, { data, id }) {
    const index = state.orders?.findIndex((order) => order.id === id)
    state.orders[index].shipping_data = data || []
  },
  // set filters items
  setFilterItemsTypes (state, data = []) {
    state.filtersItems.types = data
  },
  setFilterItemsStatuses (state, data = []) {
    state.filtersItems.statuses = data
  },
  setFilterItemsUsers (state, data = []) {
    state.filtersItems.users = data
  },
  setFilterItemsSources (state, data = []) {
    state.filtersItems.sources = data
  },
  // set filters
  setFiltersSearch (state, data = null) {
    state.filters.search = data
  },
  setFiltersDateRange (state, data = null) {
    if (data) {
      state.filters.dateRange = data
    } else {
      state.filters.dateRange = {
        start: null,
        end: null,
      }
    }
  },
  setFiltersType (state, data = null) {
    state.filters.orderType = data
  },
  setFiltersStatus (state, data = null) {
    state.filters.orderStatus = data
  },
  setFiltersUser (state, data = null) {
    state.filters.orderUser = data
  },
  setFiltersSource (state, data = null) {
    state.filters.orderSource = data
  },
  setFiltersProductId (state, data = null) {
    state.filters.productId = data
  },
  setSearchSku (state, data = null) {
    state.searchSku = data
  },
}
