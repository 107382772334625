<template>
  <div class="d-flex align-center">
    Logo
    <popper :options="{
      placement: 'top',
      modifiers: {
        offset: {
          offset: '0,5px'
        },
      }
    }">
      <div class="popper pa-6 px-4" style="max-width: 200px;">
        <v-img
          :src="logoUrl"
          alt="Logo preview"
          contain
          min-height="50"
          min-width="50"
        />
      </div>

      <div slot="reference" class="d-flex ml-1">
        <icons-question
          width="18"
          height="18"
          class="pointer"
        />
      </div>
    </popper>
  </div>

</template>

<script>
import 'vue-popperjs/dist/vue-popper.css'

import Popper from 'vue-popperjs'

export default {
  name: 'LogoPreview',
  components: {
    Popper
  },
  props: {
    logoUrl: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-image {
  max-width: 175px;
  max-height: 40px;
  min-height: 15px;
}
</style>
