<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 16"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.423 6.68898C16.5728 6.66605 16.7241 6.6543 16.8756 6.65381L16.8752 6.65352C18.601
        6.65352 20 8.08872 20 9.85912C20 11.4223 18.9092 12.7242 17.4659 13.0075C17.3805 13.0445
        17.2866 13.065 17.1881 13.065H13.6615C13.267 13.065 12.9472 12.737 12.9472 12.3323C12.9472
        11.9276 13.267 11.5996 13.6615 11.5996H16.8547C16.8615 11.5994 16.8683 11.5993 16.8752
        11.5993C17.5938 11.5996 18.2347 11.1353 18.4747 10.4404C18.7148 9.74556 18.502 8.97091
        17.9436 8.50688C17.3851 8.04286 16.6022 7.99017 15.9895 8.37539C15.653 8.58592 15.2139
        8.4782 15.0067 8.13425L15.0049 8.12919C14.8997 7.96696 14.8622 7.76832 14.9007 7.57754C15.4284
        4.9185 13.8087 2.30828 11.237 1.67324C8.6652 1.03819 6.06507 2.60642 5.3555 5.22056C5.34439
        5.24713 5.33165 5.27295 5.31736 5.29786C5.28556 5.38716 5.23402 5.46769 5.16679
        5.53313C5.13536 5.56669 5.10082 5.59702 5.06365 5.6237C5.0262 5.64901 4.98647 5.6706 4.94502
        5.68817C4.86077 5.72846 4.7686 5.7483 4.67567 5.74613C4.6479 5.7507 4.61987 5.75346 4.59175
        5.75441C4.49048 5.74348 4.38873 5.73797 4.28691 5.73793C2.70903 5.73793 1.4299 7.05011 1.4299
        8.66876C1.4299 10.2874 2.70903 11.5996 4.28691 11.5996H6.42966C6.82413 11.5996 7.14391
        11.9276 7.14391 12.3323C7.14391 12.737 6.82413 13.065 6.42966 13.065H4.28691C1.94571 13.0671
        0.0366571 11.1404 0.000520447 8.739C-0.0356162 6.33759 1.81455 4.35135 4.1547 4.27932C5.23063
        1.33551 8.20547 -0.397083 11.221 0.163774C14.2366 0.724631 16.4292 3.4183 16.4292
        6.56222C16.4292 6.58509 16.4276 6.60783 16.426 6.63057C16.4247 6.65002 16.4233 6.66946
        16.423 6.68898Z"
      :fill="color"
    />
    <path
      d="M12.4294 9.20024L10.7152 7.88136V15.2085C10.7152 15.6131 10.3955 15.9412 10.001
        15.9412C9.60651 15.9412 9.28673 15.6131 9.28673 15.2085V7.88136L7.57253 9.20024C7.3688
        9.36415 7.09456 9.40213 6.85576 9.2995C6.61696 9.19688 6.451 8.96972 6.42199
        8.70579C6.39298 8.44187 6.50547 8.1825 6.716 8.02791L9.573 5.82978C9.82668 5.63419 10.1759
        5.63419 10.4295 5.82978L13.2865 8.02791C13.4971 8.1825 13.6096 8.44187 13.5805
        8.70579C13.5515 8.96972 13.3856 9.19688 13.1468 9.2995C12.908 9.40213 12.6337 9.36415
        12.43 9.20024H12.4294Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImportFile',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 16,
    },
    color: {
      type: String,
      required: false,
      default: '#95979D',
    },
  },
};
</script>
