<template>
  <div
    class="popper pa-4 pl-5 text-left"
    style="max-width: fit-content; width: auto; min-width: 140px; z-index: 5"
  >
    <v-row no-gutters class="flex-column variants align-center">
      <v-col class="variants__title" cols="12" v-if="showOnHold">
        On hold units
      </v-col>

      <v-col class="variants__title" cols="12" v-else-if="showInProduction">
        Units in production
      </v-col>

      <v-col class="variants__title" cols="12" v-else>
        Available units
      </v-col>

      <template v-for="(variant, index) in variants">
        <v-col :key="index" cols="12">
          <v-row no-gutters class="flex-nowrap align-center">
            <v-col class="variants__size variants--nowrap">
              {{ variant.code }}
            </v-col>
            <v-col
              class="variants__units variants--nowrap"
              :class="{
                'pl-2': getVariantQty(variant) > 0,
                'pl-6': 0 > getVariantQty(variant)
              }"
            >
              {{ getVariantQty(variant) }} {{ 0 > getVariantQty(variant) ? '(back order)' : '' }}
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TrayVariantsTooltip',
  props: {
    variants: {
      type: Array,
      required: true
    },
    showOnHold: {
      type: Boolean,
      required: false,
      default: false
    },
    showInProduction: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getVariantQty (variant) {
      const { showOnHold, showInProduction } = this
      if (showOnHold) return Number(variant.qty_on_hold)
      if (showInProduction) return Number(variant.qty_in_production)

      return Number(variant.qty)
    }
  }
}
</script>
