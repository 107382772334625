<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    :stateless="lockPanel"
    right
    class="edit-group-panel"
    width="850px"
  >
    <close-panel-button
      @close="() => show = false"
      text="Back to Contacts"
    />

    <v-container class="px-10 py-0" v-if="show && isContactAGorup">
      <v-row dense>
        <v-col cols="12" class="f30 lato-light font-weight-light pt-6">
          Edit group
        </v-col>

        <v-col cols="12" class="pb-6 f15 gray2 d-flex align-center" style="gap: 6px">
          {{ group.addresses.length }} contacts in a

          <popper
            :options="{
              placement: 'top',
              modifiers: { offset: { offset: '0, 5px' } },
            }"
            v-if="!editGroupName"
          >
            <div class="popper pa-4 text-center" style="min-width: 80px">
              Edit group name
            </div>
            <div
              class="pointer d-flex align-center"
              slot="reference"
              @click="editGroupName = true"
            >
              <span class="pointer green2">
                {{ group.name }}
              </span>
              <v-img
                src="/images/pen-icon.svg"
                class="ml-2"
                width="13"
                max-width="13"
                height="13"
                max-height="13"
              />
            </div>
          </popper>

          <!-- edit group name with icons and tooltips -->
          <div class="d-flex align-center" style="gap: 8px" v-else>
            <common-input
              v-model="groupName"
              placeholder="Group name"
              height="30"
            />

            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-4 text-center" style="min-width: 80px">
                Save
              </div>
              <v-icon
                slot="reference"
                class="pointer"
                @click="saveEditedName()"
                :disabled="!groupName"
              >
                mdi-check
              </v-icon>
            </popper>

            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-4 text-center" style="min-width: 80px">
                Cancel
              </div>
              <v-icon slot="reference" class="pointer" @click="cancelEditingName()">
                mdi-close
              </v-icon>
            </popper>
          </div>
        </v-col>

        <v-col cols="12" class="pb-3 d-flex" style="gap: 12px">
          <v-menu
            max-width="200"
            open-on-hover
            :attach="$el"
            offset-y
            bottom
            right
            tile
            z-index="4"
          >
            <template #activator="{ on, attrs }">
              <div
                class="d-flex pointer aling-center"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="/images/add-contact-icon.png"
                  width="17"
                  max-width="17"
                  height="17"
                  max-height="17"
                />
                <span class="f14 green2 ml-2">
                  Add contacts
                </span>
              </div>
            </template>
            <v-list dense>
              <v-list-item dense class="pointer" @click="$emit('showEditPanel', null)">
                <v-list-item-title>
                  Add new contact
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense class="pointer" @click="$emit('showImportPanel')">
                <v-list-item-title>
                  Import contacts
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div
            class="d-flex pointer align-center"
            @click="$emit('showDeletePanel', selectedItems)"
            v-if="selectedItems.length"
          >
            <v-img
              src="/images/delete-icon.png"
              width="14"
              max-width="14"
              height="17"
              max-height="17"
            />
            <span class="f14 green2 ml-2">Remove selected contacts</span>
          </div>
        </v-col>

        <v-col cols="12" class="pb-0">
          <the-filter-bar
            hideShowFilter
            :selectItems="['all', 'none']"
            :search.sync="search"
            :sortBy.sync="sortBy"
            :select.sync="select"
            @clearSelected="setSelectedContacts([])"
          />
        </v-col>

        <v-col cols="12" v-if="loading">
          <common-loader />
        </v-col>

        <v-col cols="12" class="pt-0" v-else>
          <contacts-table
            :items="groupContacts"
            :selected.sync="selectedItems"
            v-on="{
              ...$listeners,
              showDeletePanel: (item) => $emit('showDeletePanel', [item]),
            }"
            hide-add-to-group-button
          />
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import TheFilterBar from '@/components/myAddressBook/TheFilterBar'
import ContactsTable from '@/components/myAddressBook/ContactsTable'
import Popper from 'vue-popperjs'

import panelVModel from '@/mixins/panelVModel'
import {
  filterContactBySearch,
  sortContactByValue,
  group,
  isAddressAnGroup
} from '@/components/myAddressBook/mixins'

export default {
  name: 'TheEditGroupPanel',
  components: {
    TheFilterBar,
    ContactsTable,
    Popper
  },
  mixins: [
    panelVModel,
    group,
    filterContactBySearch,
    isAddressAnGroup,
    sortContactByValue
  ],
  props: {
    lockPanel: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    groupName: null,
    editGroupName: false,
    selectedItems: [],
    // filter-bar
    search: null,
    sortBy: null,
    select: null
  }),
  computed: {
    isContactAGorup () {
      return this.isAddressAnGroup(this.group)
    },
    groupContacts () {
      const { group, search, sortBy } = this
      const contacts = group?.addresses || []
      const contactsAfterSearch = this.filterContactBySearch(contacts, search)

      const contactsAfterSort = this.sortContactByValue(contactsAfterSearch, sortBy)
      return contactsAfterSort
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.groupName = this.group?.name
      } else {
        // clear data
        this.editGroupName = false
      }
    },
    select: function (val) {
      const { groupContacts } = this

      if (val === 'none') {
        this.setSelectedContacts([])
      }

      if (val === 'all') {
        this.setSelectedContacts(groupContacts)
      }
    }
  },
  methods: {
    setSelectedContacts (contacts) {
      this.selectedItems = contacts
    },
    saveEditedName () {
      this.editGroupName = false
      this.group.name = this.groupName

      Api.put(`/customer/address-book/groups/${this.group.entity_id}`, {
        name: this.groupName
      })
        .then(({ success, data }) => {
          if (success) {
            this.$emit('updateGroup', data)
          }
        })
    },
    cancelEditingName () {
      this.editGroupName = false
      this.groupName = this.group?.name
    }
  }
}
</script>

<style lang="scss">
.edit-group-panel >.v-menu__content {
  background: #fff;
}
</style>
