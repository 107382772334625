<template>
  <div
    class="users-table-checkbox"
    :class="{ 'users-table-checkbox--selected': selected || partiallySelected }"
    role="checkbox"
    tabindex="0"
    :aria-checked="(selected || partiallySelected) ? 'true' : 'false'"
    @click="handleClick()"
    @keypress.space="handleClick()"
  >
    <svg v-if="selected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.65479 6.38342L8.87503 0.999878" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M3.60996 6.38354L1 3.69202" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel"/>
    </svg>

    <svg v-if="partiallySelected" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.19326 3.80674L8.69127 3.69127" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'MyUsersTableCheckbox',
  props: {
    partiallySelected: {
      type: Boolean,
      required: false,
      default: false, 
    },
    selected: {
      type: Boolean,
      required: false,
      default: false, 
    },
  },
  methods: {
    handleClick () {
      const { selected, partiallySelected } = this

      if (!selected || partiallySelected) {
        this.$emit('select')
      } else {
        this.$emit('unselect')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.users-table-checkbox {
  background: #FFFFFF;
  border: 1px solid #D3D2D2;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--selected {
    background: #42B77A;
    border-color: #42B77A;
  }
}
</style>
