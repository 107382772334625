const genInventoryProductUrl = (productUrl) => {
  if (!productUrl) return undefined

  const url = new URL(productUrl)
  url.searchParams.append('source', 'inventory')

  return url.toString()
}

export default genInventoryProductUrl
