import Api from '@/axios/api'

export default {
  async getOrderById ({ commit, dispatch }, id) {
    await Api.get(`/customer/orders/${id}`).then(response => {
      commit('setOrder', response.data)
      dispatch('getOrdersShippingStatus', { ids: [id], seeDetails: true })

      if (response.data?.recipient) {
        const { recipient, id } = response.data
        dispatch('getRecipientShippingData', {
          id: id || recipient.number,
          index: null
        })
      }

      if (response.data?.recipients?.length) {
        const { recipients } = response.data

        recipients.forEach((recipient, index) => {
          dispatch('getRecipientShippingData', {
            id: recipient.number,
            index
          })
        })
      }
    })
  },
  getOrdersShippingStatus ({ commit }, { ids, seeDetails }) {
    Api.get('/customer/orders/shipping-statuses', { params: { order_ids: ids?.join(',') } })
      .then(({ data }) => {
        data.forEach(({ order_id: id, tracks }) => {
          if (seeDetails) {
            commit('setOrderShippingData', { data: tracks, id: null })
          } else {
            commit('setOrderShippingData', { data: tracks, id })
          }
        })
      })
      .catch(() => {
        ids.forEach((id) => {
          if (seeDetails) {
            commit('setOrderShippingData', { data: null, id: null })
          } else {
            commit('setOrderShippingData', { data: null, id })
          }
        })
      })
  },
  getRecipientShippingData ({ commit }, { id, index }) {
    Api.get(`/customer/orders/${id}/shipping-status`).then(({ data }) => {
      commit('setRecipientShippingData', { data, index })
    }).catch(() => {
      commit('setRecipientShippingData', { data: null, index })
    })
  },
  async getOrderShippingStatus ({ commit }, id) {
    Api.get(`/customer/orders/${id}/shipping-status`)
      .then(resp => commit('setOrderShippingStatus', resp))
  },
}
