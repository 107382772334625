<template>
  <popper
    :options="{
      placement: 'top',
      modifiers: { offset: { offset: '0, 5px' } },
    }"
  >
    <div class="popper pa-3 text-left" style="min-width: 300px; max-width: 480px;">
      {{ getTooltipTextBySlug('campaigns_unrestricted_gift_link') }}
    </div>

    <div slot="reference" class="d-flex ml-2">
      <icons-question width="20" height="20" color="#62646A" class="pointer" />
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs'

import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

export default {
  name: 'TheUnrestrictedGiftLinkCheckboxTooltip',
  mixins: [getTooltipTextBySlug],
  components: { Popper },
}
</script>
