<template>
  <v-container class="px-9 py-0">
    <v-row no-gutters>
      <v-col cols="12" class="mb-4">
        <unsaved-changes-alert
          class="mx-n9"
          v-if="showRecipientsAlert"
          @saveChanges="() => { recipientAlertNewMethod = null; showRecipientsAlert = false }"
          @closePanel="() => { selectedMethod = recipientAlertNewMethod; showBulkMethods = false }"
        >
          <template #default>
            Added recipients will be removed - are you sure?
          </template>

          <template #exit>
            Continue
          </template>

          <template #save>
            Stay
          </template>
        </unsaved-changes-alert>
      </v-col>

      <v-col v-if="showBulkOptions" class="recipients-area__bulk-options" cols="12">
        <span @click="onShowBulkMethods">Other import methods</span>
      </v-col>

      <v-col v-if="showBulkMethods" cols="12">
        <the-recipients-bulk-methods
          @onMethodChange="changeSelectedMethod"
        />
      </v-col>

      <template v-if="!showBulkMethods">
        <v-col cols="12" v-if="selectedMethod === 'manually'">
          <the-recipients-area-select-manually
            v-bind="_props"
            v-on="$listeners"
            @showBulkUpload="onShowBulkMethods"
            @setUnsavedChanges="(val) => selectAreaHasUnsavedChanges = val"
            @showPreview="reviewEGift"
          />
        </v-col>

        <v-col cols="12" v-if="selectedMethod === 'import'">
          <the-recipients-area-import
            v-bind="_props"
            ref="importRecipients"
            @hideNavigation="(val) => hideNavigation = val"
            @showPreview="reviewEGift"
            @hideCloseButton="(val) => $emit('hideDefaultClose', val)"
          />
        </v-col>

        <v-col cols="12" v-if="selectedMethod === 'addressbook'">
          <the-recipients-address-book
            v-bind="_props"
            ref="addressBook"
            @showPreview="reviewEGift"
            @hideNavigation="(val) => hideNavigation = val"
            @hideCloseButton="(val) => $emit('hideDefaultClose', val)"
            @setUnsavedChanges="(val) => selectAreaHasUnsavedChanges = val"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import TheRecipientsAreaSelectManually from './recipients/TheRecipientsAreaSelectManually.vue'
import UnsavedChangesAlert from '@/components/myInventory/Panels/common/UnsavedChangesAlert'
import TheRecipientsAreaImport from './recipients/TheRecipientsAreaImport.vue'
import TheRecipientsAddressBook from './recipients/TheRecipientsAddressBook.vue'
import TheRecipientsBulkMethods from './recipients/TheRecipientsBulkMethods.vue'

import panelType from '../mixins/panelType'
import marketplaceUser from '../mixins/marketplaceUser'

export default {
  name: 'TheRecipientsArea',
  components: {
    TheRecipientsAreaSelectManually,
    TheRecipientsAddressBook,
    TheRecipientsBulkMethods,
    TheRecipientsAreaImport,
    UnsavedChangesAlert
  },
  mixins: [
    panelType,
    marketplaceUser,
  ],
  props: {
    value: {
      type: Array,
      required: true
    },
    linkType: {
      type: String,
      required: false,
      default: null
    },
    numberOfRecipients: {
      type: Number,
      required: false,
      default: null
    },
    shippingType: {
      type: String,
      required: false,
      default: null
    },
    methodOfEnteringRecipients: {
      type: String,
      required: false,
      validator: val => {
        return ['manually', 'import', 'addressbook'].includes(val)
      },
      default: 'manually'
    }
  },
  data: () => ({
    hideNavigation: false,
    selectAreaHasUnsavedChanges: false,
    recipientAlertNewMethod: null,
    showRecipientsAlert: false,
    showBulkMethods: false,
  }),
  computed: {
    selectedMethod: {
      get () {
        return this.methodOfEnteringRecipients
      },
      set (val) {
        this.$emit('update:methodOfEnteringRecipients', val)
      }
    },
    showBulkOptions () {
      const { selectedMethod, panelType, hideNavigation, showBulkMethods } = this

      if (hideNavigation) { return false }
      if (showBulkMethods) { return false }
      if (panelType === 'link' && selectedMethod === 'manually') { return false }

      return true
    },
  },
  watch: {
    selectedMethod: function () {
      this.recipientAlertNewMethod = null
      this.showRecipientsAlert = false
      this.selectAreaHasUnsavedChanges = false
    },
    panelType: function () {
      this.selectedMethod = 'manually'
      this.showBulkMethods = false
      this.$emit('hideDefaultClose', false)
    },
  },
  methods: {
    changeSelectedMethod (newMethod) {
      const { selectAreaHasUnsavedChanges } = this

      if (selectAreaHasUnsavedChanges) {
        this.showRecipientsAlert = true
        this.recipientAlertNewMethod = newMethod
      } else {
        this.selectedMethod = newMethod
        this.showBulkMethods = false
        this.$emit('hideDefaultClose', false)
      }
    },
    onShowBulkMethods () {
      this.$emit('hideDefaultClose', true)
      this.showBulkMethods = true
    },
    backToRecipientMethods (val) {
      this.$refs?.importRecipients?.continueUpload(val)
      this.$refs?.addressBook?.continueReview(val)
      this.showBulkMethods = false
    },
    reviewEGift (data) {
      this.$emit('input', data)
      this.$emit('showPreview')
    }
  }
}
</script>

<style lang="scss">
.recipients-area {
  &__link {
    font-family: 'Lato-Regular', sans-serif !important;
    white-space: nowrap;
    display: flex;
    flex: 0 1;
    gap: 8px;
    align-content: center;
    justify-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: #219358;
  }

  &__title {
    font-family: 'Lato-Light', sans-serif !important;
    font-size: 30px;
    padding-top: 34px;
    line-height: 36px;
    color: #000;
  }

  &__subtitle {
    font-family: 'Lato-Italic', sans-serif !important;
    font-style: italic;
    font-size: 15px;
    line-height: 18px;
    color: #9F9F9F;
  }

  &__bulk-options {
    font-family: 'Lato-Regular', 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #222325;
    margin-bottom: 10px;
    display: flex;

    & > span {
      color: #219358;
      cursor: pointer;
      margin-left: auto;
    }
  }
}
</style>
