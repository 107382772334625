import Api from '@/axios/api'

export default {
  getOrders ({ getters, commit, dispatch }) {
    commit('setLoadingOrders',  true)
    commit('setCurrentPage', 1)

    Api.get('/customer/orders', {
      params: getters.params,
    })
    .then(({ data, meta, links }) => {
      commit('setOrders', data)
      commit('setMeta', meta)
      commit('setLinks', links)

      const ordersIdsWithTracking = data
        .filter((order) => order?.track_url)
        .map((order) => order.id)

      if (ordersIdsWithTracking.length) dispatch('getOrdersShippingStatus', ordersIdsWithTracking)
    })
    .catch((e) => (console.error(e)))
    .finally(() => {
      commit('setLoadingOrders',  false)
    })

    dispatch('getFiltersItems')
  },
  loadMoreOrders ({ state, getters, commit, dispatch }) {
    commit('setCurrentPage', state.currentPage + 1)

    return Api.get('/customer/orders', {
      params: {
        page: state.currentPage,
        ...getters.params
      }
    })
    .then(({ data, meta, links }) => {
      commit('addOrders', data)
      commit('setMeta', meta)
      commit('setLinks', links)

      const ordersIdsWithTracking = data
        .filter((order) => order?.track_url)
        .map((order) => order.id)

      if (ordersIdsWithTracking.length) dispatch('getOrdersShippingStatus', ordersIdsWithTracking)
    })
    .catch((e) => (console.error(e)))
  },
  getOrdersShippingStatus ({ commit }, ids) {
    Api.get('/customer/orders/shipping-statuses', { params: { order_ids: ids?.join(',') } })
      .then(({ data }) => {
        data.forEach(({ order_id: id, tracks }) => {
          commit('setOrderShippingData', { data: tracks, id })
        })
      })
      .catch(() => {
        ids.forEach((id) => {
          commit('setOrderShippingData', { data: null, id })
        })
      })
  },
  getFiltersItems ({ commit, dispatch }) {
    dispatch('getFilter', 'type').then(({ data }) => {
      if (data?.length) commit('setFilterItemsTypes', data)
    })
    dispatch('getFilter', 'status').then(({ data }) => {
      if (data?.length) commit('setFilterItemsStatuses', data)
    })
    dispatch('getFilter', 'user').then(({ data }) => {
      if (data?.length) commit('setFilterItemsUsers', data)
    })
    dispatch('getFilter', 'source').then(({ data }) => {
      if (data?.length) commit('setFilterItemsSources', data)
    })
  },
  getFilter ({ getters }, type) {
    return Api.get('/customer/orders/filter/' + type, {
      params: getters.params
    })
  },
  resetFilters({ commit }) {
    commit('setFiltersSearch')
    commit('setFiltersDateRange')
    commit('setFiltersType')
    commit('setFiltersStatus')
    commit('setFiltersUser')
    commit('setFiltersSource')
    commit('setFiltersProductId')
    commit('setSearchSku')
  }
}
