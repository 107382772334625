<template>
  <div class="account-owner dashboard-card dashboard-card--small order-1">
    <div class="account-owner__title">
      {{ superAdmin ? 'Account Super Admin' : 'Account overview' }}
    </div>

    <v-divider />

    <template v-if="!loading">
      <template v-if="!userIsIndividualCgElite">
        <div class="account-owner__accounts-amount">
          <div class="account-number account-number--admins">
            <div class="account-number__amount"> {{ admins }} </div>
            <div class="account-number__name"> Admins </div>
          </div>

          <v-divider vertical />

          <div class="account-number account-number--users">
            <div class="account-number__amount"> {{ users }} </div>
            <div class="account-number__name"> Users </div>
          </div>
        </div>

        <v-divider />
      </template>

      <div class="account-owner__addons">
        <div class="addon__title" v-if="addonsItems.length">
          Add ons:
        </div>
        <div class="addon__items" v-if="addonsItems.length">
          {{ addonsItems }}
        </div>
        <div class="addon__expiration-time" v-if="expiration">
          <span>Subscription expires:</span>

          {{ subscriptionExpiration }}
        </div>
      </div>

      <v-divider v-if="expiration || addonsItems.length" />

      <div class="account-owner__customer-info" v-if="name">
        <div class="info__image">
          <icons-customer-temp />
        </div>
        <div class="info__name">
          Customer Success Manager

          <a :href="`mailto:${email}`">
            {{ name }}
          </a>
        </div>
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'
import superAdmin from '@/mixins/superAdmin'
import userIsIndividualCgElite from '@/mixins/userIsIndividualCgElite'

export default {
  name: 'TheAccountOwner',
  mixins: [
    superAdmin,
    userIsIndividualCgElite,
  ],
  data: () => ({
    loading: false,
    admins: 0,
    users: 0,
    name: null,
    email: null,
    expiration: null,
    items: []
  }),
  computed: {
    addonsItems () {
      return this.items?.join(', ')
    },
    subscriptionExpiration () {
      return new Intl.DateTimeFormat('en', { dateStyle: 'medium' }).format(this.expiration)
    }
  },
  created () {
    this.loading = true

    Api.get('/customer/dashboard/account-overview')
      .then(({ data }) => {
        this.admins = data.admins_count
        this.users = data.users_count
        this.name = data?.customer_success_manager?.name ?? null
        this.email = data?.customer_success_manager?.email ?? null
        this.expiration = data.subscription_ends_at ? new Date(data.subscription_ends_at) : null
        this.items = data?.addons ?? []
      })
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.account-owner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;

  & > hr {
    border-color: #D3D2D2;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;
    color: #95979D;
    text-transform: uppercase;
  }

  &__accounts-amount {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 24px;
    height: 40px;

    & > hr {
      min-height: 24px;
      max-height: 24px;
      margin: auto 0;
      border-color: #D3D2D2;
    }

    & .account-number {
      display: flex;
      align-items: center;
      min-width: 140px;
      max-width: 140px;
      gap: 8px;
      font-family: 'Lato-Bold', sans-serif;

      &__amount {
        font-family: inherit;
        color: inherit;
        font-size: 40px;
        line-height: 40px;
      }

      &__name {
        font-family: inherit;
        color: inherit;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }

      &--admins {
        color: #38A39C;
        justify-content: flex-end;
      }

      &--users {
        color: #AD84B7;
        justify-content: flex-start;
      }
    }
  }

  &__addons {
    display: flex;
    flex-direction: column;
    gap: 6px;

    & .addon {
      &__title {
        font-family: 'Lato-Bold', sans-serif;
        font-size: 15px;
        line-height: 27px; // ?
        color: #000;
      }

      &__items {
        font-family: 'Lato-Regular', sans-serif;
        font-size: 15px;
        line-height: 27px;
        color: #62646A;
      }

      &__expiration-time {
        font-size: 15px;
        line-height: 27px;
        font-family: 'Lato-Regular', sans-serif;
        color: #62646A;

        & > span {
          font-size: inherit;
          line-height: inherit;
          font-family: 'Lato-Bold', sans-serif;
          color: #000;
        }
      }
    }
  }

  &__customer-info {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    & .info {
      &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 41px;
        height: 41px;
        background-color: #D8F1E4;
      }

      &__name {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Lato-Bold', sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: #000;

        & > a {
          color: #42B77A !important;
          font-family: 'Lato-Regular', sans-serif;
        }
      }
    }
  }
}
</style>
