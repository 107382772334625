<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    :stateless="isPaymentPanelOpen"
    width="800px"
    class="right-side-panel"
  >
    <panel-header :show.sync="show">
      <div class="auto-reload-panel__title">
        <b>{{ selectedEntityName }}</b> / manage auto-reload
      </div>
    </panel-header>

    <cg-loader v-if="loading" />

    <div v-show="!loading" class="right-side-panel__content-wrapper">
      <div class="auto-reload-panel__switch">
        <common-switch v-model="form.active" id="is-auto-reload-active" />

        <span>
          Enable auto reload for budget entity <b>{{ selectedEntityName }}</b>
        </span>
      </div>

      <v-divider />

      <div class="auto-reload-panel__inputs">
        <div class="auto-reload-panel__input-wrapper">
          <label for="limit-amount">When your balance drop to:</label>
          <common-input
            v-model.number="form.limit"
            :disabled="disableForm"
            id="limit-amount"
            type="number"
            prefix="$"
          />
        </div>
        <div class="auto-reload-panel__input-wrapper">
          <label for="refill-amount">Refill Amounts:</label>
          <common-input
            v-model.number="form.amount"
            :disabled="disableForm"
            id="refill-amount"
            type="number"
            prefix="$"
          />
        </div>
      </div>

      <v-divider />

      <div class="auto-reload-panel__input-wrapper" style="gap: 20px">
        <label>Pay with</label>

        <payment-module
          :paymentMethod.sync="payment.method"
          :paymentData.sync="payment.paymentData"
          :availableMethods="availablePaymentMethods"
          ref="paymentModule"
          @update:isPaymentPanelOpen="(val) => isPaymentPanelOpen = val"
        />
      </div>

      <cg-button style="max-width: 200px" @click="saveChanges">
        Save
      </cg-button>

      <div class="auto-reload-panel__terms-and-conditions">
        By clicking “Save” you agree to the CorporateGift.com
        <a href="https://corporategift.com/terms-and-conditions/" target="_blank">
          Terms of Service and Payments Policy.
        </a>
        <br />
        Prepaid credits are non-refundable and non-transferrable.
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import panelVModel from '@/mixins/panelVModel'
import { selectedEntity } from '../mixins'
import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import getPaymentBody from '@/components/payment/paymentModule/utils/getPaymentBody'

import { CgLoader, CgButton } from '@corporategift/design-system'
import PanelHeader from '@/components/PanelHeader.vue'
import PaymentModule from '@/components/payment/PaymentModule.vue'

export default {
  name: 'TheAutoReloadPanel',
  mixins: [
    panelVModel,
    selectedEntity,
  ],
  components: {
    CgLoader,
    CgButton,
    PanelHeader,
    PaymentModule,
  },
  data: () => ({
    loading: false,
    form: {
      active: false,
      amount: null,
      limit: null,
    },
    payment: {
      method: PaymentType.CC,
      paymentData: null,
    },
    isPaymentPanelOpen: false,
    availablePaymentMethods: [
      PaymentType.CC,
      PaymentType.ACH,
      PaymentType.PO,
    ],
    responseData: {}
  }),
  computed: {
    selectedEntityName () {
      return this.selectedEntity?.name ?? null
    },
    disableForm () {
      return !this.form.active
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.fetchData()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      const { id } = this.selectedEntity

      Api.get(`/credit/auto-reloads/${id}`)
        .then(({ data }) => {
          this.responseData = data
          this.setFormData()
        })
        .finally(() => (this.loading = false))
    },
    setFormData () {
      const { amount, limit, active, payment } = this.responseData

      this.form = {
        active,
        limit,
        amount,
      }

      const paymentInfo = payment

      this.payment.method = paymentInfo.method
      delete paymentInfo.method
      delete paymentInfo.deviceData

      if (Object.keys(paymentInfo).length) {
        this.payment.paymentData = {
          ...paymentInfo,
        }
      }
    },
    saveChanges () {
      if (!this.$refs.paymentModule.validate()) { return }

      this.loading = true
      const { payment, form, selectedEntity } = this

      const paymentBody = getPaymentBody(payment)
      const responseBody = {
        active: form.active,
        limit: null,
        amount: null,
        payment: paymentBody,
      };

      if (form.active) {
        responseBody.limit = form.limit
        responseBody.amount = form.amount
      }

      Api.post(`/credit/auto-reloads/${selectedEntity.id}`, responseBody)
        .then(() => {
          this.$cgToast.success('Saved Auto Reload Configuration Successfully!')
          this.$emit('fetchNewData')
          this.show = false
        })
        .catch(({ response }) => {
          this.$cgToast.error(response?.data?.message)
        })
        .finally(() => (this.loading = false))

    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/common/right-side-panel.scss';

.auto-reload-panel {
  &__title {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  &__switch {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;

    & > span {
      font-size: 16px;
      color: #4a4a4a;
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;

      & > b {
        font-family: 'Lato-Bold', sans-serif;
        font-weight: 700;
        color: #000;
      }
    }
  }

  &__inputs {
    display: flex;
    gap: 24px;
    flex-direction: row;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > label {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }
  }

  &__terms-and-conditions {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;

    & > a {
      color: #42B77A !important;
    }
  }
}
</style>
