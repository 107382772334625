<template>
  <div>
    <cg-loader v-if="loading" />

    <div v-show="!loading" class="add-ach-form">
      <cg-form ref="achForm">
        <div class="add-ach-form__double-inputs">
          <cg-input
            v-model="achForm.firstName"
            :validation="[validation.required]"
          >
            <template #label>
              First name
            </template>
          </cg-input>
          <cg-input
            v-model="achForm.lastName"
            :validation="[validation.required]"
          >
            <template #label>
              Last Name
            </template>
          </cg-input>
        </div>

        <cg-select
          v-model="achForm.approverType"
          :items="accountApprovers"
          :validation="[validation.required]"
        >
          <template #label>
            Account type
          </template>
        </cg-select>

        <cg-input
          v-if="['CORPORATE_CHECKING', 'CORPORATE_SAVINGS'].includes(achForm.approverType)"
          v-model="achForm.businessName"
          :validation="[validation.required]"
        >
          <template #label>
            Company name
          </template>
        </cg-input>

        <cg-input
          v-model="achForm.routingNumber"
          :validation="[
            validation.required,
            validation.minLength,
          ]"
          :maxlength="9"
        >
          <template #label>
            Routing number
          </template>
        </cg-input>

        <cg-input
          v-model="achForm.accountNumber"
          :validation="[validation.required]"
          >
          <template #label>
            Account number
          </template>
        </cg-input>
      </cg-form>

      <cg-button v-if="showAddAchButton" @click="onSubmit">
        Add ACH account
      </cg-button>
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'

import { CgForm, CgButton, CgLoader, CgInput, CgSelect } from '@corporategift/design-system'

import { required, minLength } from '@corporategift/design-system/validations'

export default {
  name: 'AddBluesnapAchForm',
  components: {
    CgLoader,
    CgSelect,
    CgButton,
    CgForm,
    CgInput,
  },
  props: {
    showAddAchButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    loading: false,
    accountApprovers: [
      {
        text: 'Consumer checking',
        value: 'CONSUMER_CHECKING',
      },
      {
        text: 'Consumer savings',
        value: 'CONSUMER_SAVINGS',
      },
      {
        text: 'Corporate checking',
        value: 'CORPORATE_CHECKING',
      },
      {
        text: 'Corporate savings',
        value: 'CORPORATE_SAVINGS',
      }
    ],
    achForm: {
      firstName: null,
      lastName: null,
      accountNumber: null,
      routingNumber: null,
      approverType: null,
      businessName: null,
      locations: 'US',
    },
    validation: {
      required: required(),
      minLength: minLength({ min: 9, errorMessage: 'Routing Number should be exactly 9 characters long' }),
    },
  }),
  methods: {
    onSubmit () {
      if (this.$refs.achForm?.validate()) {
        const { achForm } = this
        this.loading = true

        const gRecaptcha = window.grecaptcha.enterprise
        const requestBody = {
          ...achForm,
          companyName: achForm.businessName,
          accountType: achForm.approverType,
        }

        gRecaptcha.ready(() => {
          gRecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'SUBMIT' })
            .then((token) => {
              Api.post('bluesnap/ach', { ...requestBody, recaptcha_token: token })
                .then(() => (this.$emit('onAddAch')))
                .catch(({ response }) => {
                  const errorMessage = response?.data?.message || 'An error occurred, please contact our support'
                  this.$cgToast.error(errorMessage, { html: true })
                })
                .finally(() => (this.loading = false))
            })
            .catch(() => (this.loading = false))
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-ach-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  & > span {
    padding: 20px 0 10px;
    color: #95979D;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    line-height: normal;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
  }

  & button {
    border-radius: 100px;
  }

  &__double-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
  }
}

.cg-loader {
  max-height: 200px;
  margin: 200px auto 0;
}
</style>
