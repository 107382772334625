import { render, staticRenderFns } from "./TheMyOrdersTableDetailsRecipients.vue?vue&type=template&id=5352676f"
import script from "./TheMyOrdersTableDetailsRecipients.vue?vue&type=script&lang=js"
export * from "./TheMyOrdersTableDetailsRecipients.vue?vue&type=script&lang=js"
import style0 from "./TheMyOrdersTableDetailsRecipients.vue?vue&type=style&index=0&id=5352676f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports