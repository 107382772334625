<template>
  <div class="campaign-faq-item" :class="{ 'campaign-faq-item--active': expanded }">
    <div
      class="campaign-faq-item__title"
      tabindex="0"
      @click="expanded = !expanded"
      @keypress.enter="expanded = !expanded"
      @keypress.space="expanded = !expanded"
    >
      {{ title }}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
        fill="none"
        width="14"
        height="14"
      >
        <path d="M12.9843 1.01569L1.2276 12.7724" stroke="currentColor" stroke-width="1.2"/>
        <path d="M1.42505 1.56104L12.8861 13.0221" stroke="currentColor" stroke-width="1.2"/>
      </svg>
    </div>

    <v-expand-transition>
      <div v-show="expanded" class="campaign-faq-item__content-wrapper">
        <span v-html="content" class="campaign-faq-item__content" />

        <iframe
          v-if="hasCorrectVideoLink && expanded"
          :src="getEmbedLink"
          :title="title"
          frameborder="0"
          autoplay="0"
          allowfullscreen
          scrolling="no"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'CreateCampaignFaqItem',
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    content: {
      type: String,
      required: false,
      default: null,
    },
    videoLink: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    hasCorrectVideoLink () {
      const { videoLink } = this

      if (!videoLink) { return false }

      try {
        const url = new URL(videoLink)

        return true
      } catch (e) {
        console.error('Invalid Url')
      }

      return false
    },
    getEmbedLink () {
      const { videoLink } = this

      if (!videoLink) return null

      try {
        const url = new URL(videoLink)

        if (url.hostname.includes('youtu')) {
          const takeYoutubeId = (url) => url.match(/(^|=|\/)([0-9A-Za-z_-]{11})(\/|&|$|\?|#)/)[2]

          return `https://youtube.com/embed/${takeYoutubeId(videoLink)}`
        } else if (url.hostname.includes('loom')) {
          const takeKeyByIndex = (pathname, index, splitBy = '/') => pathname.split(splitBy)[index]

          return `https://www.loom.com/embed/${takeKeyByIndex(url.pathname, [2])}`
        } else {
          return videoLink
        }
      } catch (e) {
        console.error('Invalid Url')
      }

      return null
    }
  },
}
</script>

<style lang="scss" scoped>
.campaign-faq-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E3E3E3;

  &--active {
    & > .campaign-faq-item__title {
      color: #42B77A;

      & > svg {
        transform: rotate(0deg);
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    outline: none;
    flex: 1 1;
    gap: 16px;
    cursor: pointer;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    transition: color ease-in-out 0.12s;

    & > svg {
      display: inline-flex;
      min-width: 14px;
      transition: transform ease-in-out 0.15s;
      transform: rotate(45deg);
    }

    &:hover,
    &:focus {
      color: #42B77A;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    padding-right: 30px;

    & > iframe {
      width: 224px;
      height: 140px;
    }
  }

  &__content {
    font-family: 'Lato-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #4A4A4A;

    &::v-deep > a {
      color: #42B77A;
      cursor: pointer;
      text-decoration: none;
    }

    &::v-deep > b,
    &::v-deep > strong {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
    }
  }
}

</style>
