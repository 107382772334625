<template>
  <review-field
    ref="review-field"
    class="campaigns-payment-styles"
    :editable="false"
    :disable-hover-tooltip="true"
    :review-mode-as-column="showColumnMode"
    @onLabelClick="handleLabelClick"
  >
    <template #label>
      Pay with
    </template>

    <template #review-mode>
      <cg-alert v-if="isSpendLimitInsufficient" type="error" style="max-width: fit-content">
        Order cannot be created. You exceed your personal spend limit
      </cg-alert>

      <payment-module
        v-else
        :paymentMethod.sync="paymentMethod"
        :paymentData.sync="paymentData"
        :splitPaymentData.sync="splitPaymentData"
        :amount="campaignTotalCost"
        :hide-other-payment-methods="true"
        :defaultPaymentMethod="paymentMethod"
        use-split-payment-if-available
        ref="paymentModule"
        :payable-id="campaign.entity_id"
        payable-type="campaign"
        @update:isPaymentPanelOpen="(val) => isPaymentPanelShown = val"
      />
    </template>
  </review-field>
</template>

<script lang="ts">
import { Mixins, Component, Watch, Inject } from 'vue-property-decorator'
import { IAchPayment, ICreditCardPayment, IAddCreditCardForm, PaymentType, IBudgetEntity } from '../../types'

import formProp from '../mixins/formProp'
import PaymentModule from '@/components/payment/PaymentModule.vue'
import campaignProp from '../mixins/campaignProp'
import defaultPaymentProp from '../mixins/defaultPaymentProp'
import campaignTotalCostProp from '../mixins/campaignTotalCostProp'
import personalSpendLimitProp from '../mixins/personalSpendLimitProp'

import ReviewField from './ReviewField.vue'
import { CgAlert } from '@corporategift/design-system'

@Component({
  components: {
    ReviewField,
    PaymentModule,
    CgAlert,
  },
})
export default class ReviewFieldsPayment extends Mixins(
  personalSpendLimitProp,
  campaignTotalCostProp,
  defaultPaymentProp,
  campaignProp,
  formProp,
) {
  isPaymentPanelShown = false

  @Inject() updateRefreshTokenFn!: Function;

  get showColumnMode (): boolean {
    const { method, paymentData } = this.form.payment

    if (method === PaymentType.CC) {
      if (!paymentData) { return true }
      // @ts-ignore
      return paymentData && !Object.hasOwn(paymentData, 'token') && !Object.hasOwn(paymentData, 'cardLastFourDigits')
    }

    return false;
  }

  get paymentMethod (): PaymentType {
    return this.form.payment.method
  }

  set paymentMethod (val: PaymentType) {
    this.$emit('update:paymentMethod', val)
  }

  get paymentData () {
    return this.form.payment.paymentData;
  }

  set paymentData (val: IBudgetEntity | IAchPayment | ICreditCardPayment | IAddCreditCardForm | null) {
    this.$emit('update:paymentData', val)
  }

  get splitPaymentData () {
    return this.form.payment.splitPaymentData;
  }

  set splitPaymentData (val: ICreditCardPayment | null | undefined) {
    this.$emit('update:splitPaymentData', val)
  }

  @Watch('isPaymentPanelShown')
  onPaymentPanelShown (val: boolean) {
    this.$emit('update:isAnotherPanelOpened', val)
  }

  mounted() {
    this.updateRefreshTokenFn(this.refreshPaymentToken)
  }

  refreshPaymentToken () {
    // @ts-ignore
    this.$refs?.paymentModule?.refreshPaymentToken()
  }

  handleLabelClick (): void {
    // @ts-ignore
    this.$refs.paymentModule.showOtherPaymentMethods()
  }
}
</script>

<style lang="scss">
.campaigns-payment-styles {
  .payment-module-ach-container__link,
  .payment-module-cc-container__link {
    display: none;
  }

  .payment-module-ach-container,
  .payment-module-cc-container {
    & .credit-card-item {
      padding: 0;
      border: none !important;
      gap: 24px;
      align-items: flex-start;

      & > img {
        width: 54px;
      }
    }
  }
}
</style>
