<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 19"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="My-Campaigns"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.4"
    >
      <g
        id="My-Campaigns_send_eGift_less-items"
        transform="translate(-813.000000, -634.000000)"
      >
        <g id="greeting" transform="translate(479.000000, 631.000000)">
          <g
            id="greeting-ideas-but"
            transform="translate(335.000000, 4.000000)"
          >
            <g id="Group-5" transform="translate(0.022286, 0.015066)">
              <polygon
                id="Stroke-1"
                stroke="#000000"
                stroke-width="1.197504"
                points="0.250175962 16.9401587 13.6677625 16.9401587 13.6677625 3.59803661
                  0.250175962 3.59803661
                "
              />
              <path
                d="M0,3.52984252 C3.78861538,2.35322835 7.57723077,1.17661417
                  11.3658462,-6.45019665e-16 L11.3658462,3.7565315
                "
                id="Stroke-3"
                stroke="#000000"
                stroke-width="1.197504"
              />
              <polygon
                id="Fill-4"
                fill="#000000"
                points="6.41622692 6.38101693 7.52390962 6.38101693 7.656775 9.1455248 10.3040538
                  8.44062717 10.6584962 9.47595394 8.06661154 10.4452217 9.59516923 12.7250957
                  8.70906346 13.3639343 6.97003462 11.2161783 5.23107308 13.3639343 4.35600577
                  12.7250957 5.86241923 10.423202 3.25949615 9.45393425 3.61393846 8.41860748
                  6.28329423 9.1455248
                "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Greetings',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 14
    },
    height: {
      type: [Number, String],
      required: false,
      default: 17
    }
  }
}
</script>
