<template>
  <div class="integration-install-card">
    <div class="integration-install-card__details">

      <div class="integration-install-card__details-top">
        <div class="integration-install-card__logo">
          <v-img :src="integration.logoSrc" :style="integration.logoStyle"/>
        </div>

        <div class="integration-install-card__details-top-text">
          <p class="integration-install-card__title">{{integration.title}}</p>
          <p class="integration-install-card__description">
            {{integration.description}}
          </p>
        </div>
      </div>

      <div class="integration-install-card__details-bottom">
        <ul class="integration-install-card__details-list" v-for="item in integration.listItems" :key="item">
          <li class="integration-install-card__details-list-item">{{item}}</li>
        </ul>
      </div>

    </div>

    <div class="integration-install-card__actions-container">
      <common-button
        class="integration-install-card__btn"
        :class="{
          'integration-install-card__btn--installed': integration.isInstalled
        }"
        width="155"
        :color="integration.isInstalled? 'green white--text' : 'black white--text'"
        height="40"
        @click="integration.installNowActionHandler"
      >
        {{ integration.isInstalled ? 'Installed' : 'Install Now'}}
      </common-button>

      <a
        class="integration-install-card__link"
        @click="integration.moreDetailsActionHandler">More details</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstallCard',
  props: {
    integration: Object
  },
  data: () => ({})
}
</script>

<style lang="scss" scoped>
  @import "./src/css/integrations/install-card";
</style>