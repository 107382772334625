<template>
  <div class="campaign-automation-how-it-works">
    <div
      tabindex="0"
      @click="onTitleClick"
      @keypress.tab="onTitleClick"
      @keypress.enter="onTitleClick"
      :aria-expanded="expanded ? 'true' : 'false'"
      class="campaign-automation-how-it-works__title"
    >
      How it works?

      <div
        class="campaign-automation-how-it-works__icon"
        :class="{ 'campaign-automation-how-it-works__icon--open': expanded }"
      />
    </div>

    <v-expand-transition>
      <span v-show="expanded" class="campaign-automation-how-it-works__text">
        1. Select the automation you want - your contacts’ birthday or anniversary
        (both are set in your <router-link :to="{ name: 'Contacts' }">contacts</router-link> section)<br>
        2. Select the relevant contacts group/s for this campaign<br>
        3. Set payment method<br>
        4. That’s it!<br>
      </span>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'AutomationCampaignHowItWorks',
  data: () => ({
    expanded: false,
  }),
  methods: {
    onTitleClick () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-automation-how-it-works {
  background-color: #F6F5FF;
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    color: #222325;
    font-family: 'Lato-Bold', sans-serif;
    cursor: pointer;
    line-height: 24px;
    font-size: 18px;
  }

  &__icon {
    position: relative;
    width: 16px;
    height: 16px;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: #000000;
      transition: all ease-in-out 0.26s;
    }

    &::after {
      height: 16px;
      width: 2px;
      left: 50%;
      transform: translateX(-50%);
    }

    &::before {
      height: 2px;
      width: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--open::after {
      transform: rotate(-90deg);
    }
  }

  &__text {
    margin-top: 16px;
    color: #222325;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 24px;

    & > a {
      color: #6E50FF !important;
    }
  }
}
</style>
