const setCookie = (token, cookieActiveDays = 30, name = 'cgToken') => {
  const cookieName = `${name}=${token};`;
  const path = 'path=/;';

  const domainInfo = window.location.host.split('.');
  if (!['staging', 'development', 'corporategift'].includes(domainInfo[0])) domainInfo.splice(0, 1);
  let domain = `domain=.${domainInfo.join('.')};`;
  if (window.location.host.includes('localhost')) domain = '';

  const maxAge = 60 * 60 * 24 * cookieActiveDays;

  const expiration = `max-age=${maxAge};`;

  document.cookie = `${cookieName}${domain}${path}${expiration}; SameSite=None; Secure`;
};

export default setCookie;
