<template>
  <v-container>
    <common-loader v-if="loading" />

    <v-row v-show="!loading && !showOrderNumber">
      <v-col cols="12" class="f40 lato-light">
        Buy new credits
      </v-col>
      <v-col cols="6" xl="4">
        <!-- invoice info -->
        <div class="pa-6 info-area">
          <v-row dense>
            <v-col cols="4" class="info-area__title">
              Credits amount:
            </v-col>
            <v-col cols="8">
              {{ amount | priceFilter({
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0
              }) }}
              CG Credits
            </v-col>
            <v-col cols="4" class="info-area__title">
              Invoice to:
            </v-col>
            <v-col cols="8">
              {{ invoiceData.customerName }}
            </v-col>
            <v-col cols="4" class="info-area__title">
              Customer:
            </v-col>
            <v-col cols="8">
              {{ invoiceData.customerCompany }}
            </v-col>
            <v-col cols="4" class="info-area__title">
              Invoice number:
            </v-col>
            <v-col cols="8">
              {{ invoiceData.number }}
            </v-col>
            <v-col cols="4" class="info-area__title">
              Invoice date:
            </v-col>
            <v-col cols="8">
              {{ invoiceData.dueDate | dateFilter }}
            </v-col>
            <v-col cols="4" class="info-area__title">
              Description:
            </v-col>
            <v-col cols="8">
              {{ invoiceData.description }}
            </v-col>
          </v-row>
        </div>
        <!-- payment methods -->
        <div class="pt-10">
          <label for="payment-method">
            Choose payment method:
          </label>

          <common-radio
            v-model="paymentMethod"
            input-value="bluesnap_ach"
            :disabled="!availablePaymentMethods.includes('bluesnap_ach')"
            labelClass="my-6"
          >
            ACH
          </common-radio>

          <common-radio
            v-model="paymentMethod"
            input-value="bluesnap"
            :disabled="!availablePaymentMethods.includes('bluesnap')"
          >
            Credit card
          </common-radio>
        </div>
      </v-col>

      <!-- ACH -->
      <v-col cols="6" xl="4" v-if="paymentMethod === 'bluesnap_ach'">
        <ach-payment
          ref="ach-payment"
          :firstName.sync="achForm.firstName"
          :lastName.sync="achForm.lastName"
          :type.sync="achForm.type"
          :companyName.sync="achForm.companyName"
          :accountNum.sync="achForm.accountNum"
          :routingNum.sync="achForm.routingNum"
          :errors="errors"
          @addAch="addAchAccount()"
        />
      </v-col>
      <!-- CC -->
      <v-col cols="6" xl="4" v-if="paymentMethod === 'bluesnap'">
        <credit-card-payment
          ref="cc-payment"
          :errors="errors"
          @addCC="addCreditCard()"
        />
      </v-col>
    </v-row>
    <!-- order completed -->
    <v-row align="center" class="flex-column text-center" v-if="!loading && showOrderNumber">
      <v-col cols="6" class="d-flex justify-center">
        <v-img
          src="/images/success_icon.svg"
          alt="Success icon"
          max-width="32"
          min-width="32"
          max-height="32"
          min-height="32"
        />
      </v-col>
      <v-col cols="6" class="f40 lato-light">
        Order completed!
      </v-col>
      <v-col cols="6" class="f20 lato-bold">
        You've added
        {{ amount | priceFilter({
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        }) }}
        to CG Credits
      </v-col>
      <v-col cols="6" class="f15">
        Your order number is # {{ orderNumber }} <br />
        {{
          paymentMethod === 'bluesnap_ach'
            ? "Credits will appear after your ACH payment is processed (typically within 48 hours)."
            : "We'll send you a confirmation email shortly"
        }}
      </v-col>

      <v-col cols="6">
        <div class="account-dashboard-area">
          Monitor your CG Credits in your account dashboard

          <common-button
            width="190"
            class="mt-5"
            @click="goToMyAccount()"
          >
            My Account
          </common-button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/axios/api'
import ApiM from '@/axios/apiM'

import AchPayment from '@/components/dedicatedPayment/AchPayment.vue'
import CreditCardPayment from '@/components/dedicatedPayment/CreditCardPayment.vue'

export default {
  name: 'DedicatedPayment',
  components: {
    AchPayment,
    CreditCardPayment
  },
  data: () => ({
    amount: 0,
    selectedAchAccount: null,
    selectedCC: null,
    paymentMethod: 'bluesnap_ach',
    availablePaymentMethods: [
      'bluesnap_ach',
      'bluesnap'
    ],
    achForm: {
      firstName: null,
      lastName: null,
      companyName: null,
      type: null,
      routingNum: null,
      accountNum: null
    },
    errors: [],
    loading: false,
    showOrderNumber: false,
    invoiceData: {
      customerName: null,
      customerCompany: null,
      number: null,
      dueDate: null,
      description: null
    },
    orderNumber: null,
    isApproved: false,
    accountApprovers: [
      {
        text: 'Consumer checking',
        value: 'CONSUMER_CHECKING'
      },
      {
        text: 'Consumer savings',
        value: 'CONSUMER_SAVINGS'
      },
      {
        text: 'Corporate checking',
        value: 'CORPORATE_CHECKING'
      },
      {
        text: 'Corporate savings',
        value: 'CORPORATE_SAVINGS'
      }
    ]
  }),
  watch: {
    paymentMethod: function () {
      this.errors = []
    }
  },
  created () {
    this.$store.commit('app/setHideUserMenu', true)
    const token = this.$route.params?.token || null
    this.loading = true
    if (token) {
      Api.get(`/credit/prefill-payment/${token}`).then((data) => {
        this.amount = data?.amount || 0
        this.invoiceData = {
          customerName: data?.customer?.name || null,
          customerCompany: data?.customer?.orginasation || null,
          number: data?.invoice_number || null,
          dueDate: data?.due_date || null,
          description: data?.description || null
        }
        // set payment methods
        const paymentMethods = []
        if (Number(data?.is_ach_available)) paymentMethods.push('bluesnap_ach')
        if (Number(data?.is_cc_available)) paymentMethods.push('bluesnap')
        this.availablePaymentMethods = paymentMethods
        this.paymentMethod = paymentMethods[0] || null

        this.$store.commit('headerAuth/setToken', data.token)
        this.loading = false
      }).catch((e) => {
        this.$store.dispatch('headerAuth/logout')
      })
    } else {
      this.$store.dispatch('headerAuth/logout')
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('headerAuth/logout')
  },
  methods: {
    addAchAccount () {
      if (!this.$refs['ach-payment'].validate()) return
      const { accountNum, routingNum, type, firstName, lastName, companyName } = this.achForm
      this.loading = true
      this.errors = []

      Api.post('bluesnap/ach', {
        accountNumber: accountNum,
        routingNumber: routingNum,
        companyName,
        accountType: type,
        firstName,
        lastName
      }).then(() => {
        Api.get('/bluesnap/ach').then((data) => {
          this.selectedAchAccount = data[0] || {}
          this.buyCredits()
        }).catch((e) => this.handleErrors(e))
      }).catch((e) => this.handleErrors(e))
    },
    addCreditCard () {
      if (!this.$refs['cc-payment'].validate()) return
      this.loading = true
      this.errors = []

      this.$refs['cc-payment'].submitCreditCard().then(() => {
        Api.get('/bluesnap/credit-cards').then((data) => {
          this.selectedCC = data[0] || {}
          this.buyCredits()
        }).catch((e) => this.handleErrors(e))
      }).catch((e) => this.handleErrors(e))
    },
    buyCredits () {
      const { paymentMethod, selectedAchAccount, selectedCC, amount } = this
      const data = new FormData()
      data.append('amount', amount)
      data.append('payment_method', paymentMethod)

      // Credit card
      if (paymentMethod === 'bluesnap') {
        const { cardType, cardLastFourDigits } = selectedCC
        data.append('card_type', cardType)
        data.append('card_last_four_digits', cardLastFourDigits)
      }
      // ACH account
      if (paymentMethod === 'bluesnap_ach') {
        const { publicAccountNumber, publicRoutingNumber, accountType } = selectedAchAccount
        data.append('public_account_number', publicAccountNumber)
        data.append('public_routing_number', publicRoutingNumber)
        data.append('type', accountType)
      }

      this.loading = true
      ApiM.post('/customer/credits_api/buy', data).then((response) => {
        this.orderNumber = response?.id || null
        this.isApproved = response?.approved || false
        this.loading = false
        this.showOrderNumber = true
      }).catch((e) => this.handleErrors(e))
    },
    goToMyAccount () {
      window.location.href = `${process.env.VUE_APP_MAGENTO_URL}/customer/account/`
    },
    handleErrors (error) {
      this.loading = false
      console.error(error)
      this.errors.push('Your transaction cannot be completed, please try again later or contact customer support')
    }
  }
}
</script>

<style lang="scss" scoped>
.f15 {
  font-size: 15px !important;
}
label {
  font-size: 15px;
  margin-bottom: 4px;
  font-family: 'Lato-Bold', sans-serif;
}
.info-area {
  background: #EDFCFB;
  width: 435px;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: #000;

  .row--dense > .col {
    padding: 8px 4px !important
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
  }
}
.account-dashboard-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  margin: auto;
  padding: 30px;
  background: #EDFCFB;
}
</style>
