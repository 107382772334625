<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    max-height="400"
    class="rounded-0"
  >
    <div class="modal-content delete-modal">
      <v-icon @click.stop="close" class="white--text close-icon">mdi-close</v-icon>
      <h1 class="f30 lato-light gray3 mt-4" style="font-weight: 300;">Delete API Token</h1>
      <p class="heading mt-6 mr-4 f15 black1"><strong>Do you really want to delete this token?</strong></p>
      <div class="buttons-wrapper mt-4 mb-7">
          <button class="delete button success-group uppercase" @click="destroyToken">Delete</button>
          <button class="cancel button uppercase" @click="close">Close</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('apiTokens')

export default {
  props: {
    value: Boolean,
    token: Object
  },
  methods: {
    ...mapActions([
      'destroy'
    ]),
    destroyToken () {
      this.destroy(this.token)
      this.close()
    },
    close () {
      this.dialog = false
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog {
  top: 50px;

  .delete-modal {
    padding: 20px;
    background-color: white;

    .delete {
      height: 40px;
      width: 127px;
      color: #fff;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      background-color: #000;
      display: inline-block;
      padding: 8px 0;
      border: none;
      outline: none;
    }

    .cancel {
      height: 40px;
      width: 127px;
      border: 2px solid #000;
      background-color: #fff;
      display: inline-block;
      padding: 8px 0;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #000;
      margin-left: 11px;
      outline: none;
    }

    .close-icon {
      position: absolute !important;
      font-size: 50px !important;
      font-weight: 600;
      top: 0;
      right: -80px;
    }
  }
}
</style>
