
export default {
  namespaced: true,

  state: () => ({
    errors: {}
  }),

  mutations: {
    setErrors (state, errors) {
      state.errors = errors
    }
  },

  getters: {
    errors: state => state.errors
  }
}
