import Api from '@/axios/api'

export default {
  async getInventoryList ({ commit, getters }) {
    commit('setLoading', true)

    await Api.get('/customer/purchased-inventory', { params: { ...getters.params, page: 1 } })
      .then(({ data, meta }) => {
        commit('setInventory', data)
        commit('setCurrentPage', 1)
        commit('setTotalPages', meta.last_page)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => (commit('setLoading', false)))
  },
  async loadNextPage ({ commit, getters, state }) {
    commit('setLoadingPage', true)
    commit('setCurrentPage', (state.currentPage + 1))
    Api.get('/customer/purchased-inventory', { params: getters.params })
      .then(({ data }) => {
        commit('updateInventory', data)
        commit('setLoadingPage', false)
      })
      .catch(error => {
        console.error(error)
        commit('setLoadingPage', false)
      })
  },
  async getPermissionList ({ commit }, search = null) {
    return await Api
      .get('customer/purchased-inventory/customer-group/permissions', {
        params: {
          query: search
        }
      })
      .then(({ customers }) => {
        commit('setHistoryList',
          customers.filter(customer => customer.permissions.length > 0)
        )
      })
  },
  async disableKitIfProductIsOOS ({ commit }, { itemId, value = false }) {
    commit('setDisableKitWhenAnyItemIsOOSById', { itemId, value })

    return await Api.post(`customer/purchased-inventory/item/${itemId}/product-settings`, {
      disable_when_child_product_out_of_stock: value
    })
  }
}
