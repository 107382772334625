<template>
  <div>
    <v-container>
      <span class="page-title">My Integrations</span>
    </v-container>

    <v-container>
      <integrations-install-card
        v-for="integration in integrations"
        :key="integration.title"
        :integration="integration"
      ></integrations-install-card>
    </v-container>

    <survey-monkey-settings-panel
      v-if="surveyIsShowSettingsPanel"
      @close="surveySetIsShowSettingsPanel(false)"/>

    <hubspot-settings-panel
      v-if="hubSpotIsShowSettingsPanel"
      @close="hubSpotSetIsShowSettingsPanel(false)"/>
  </div>
</template>

<script>
import HubspotSettingsPanel from '@/components/integrations/hubspot/SettingsPanel'

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'integrations',
  components: {
    HubspotSettingsPanel
  },
  data () {
    return {
      integrations: []
    }
  },
  async created () {
    // await this.surveyFetchCredentials()
    await this.hubspotFetchCredentials()

    // const surveyMonkeyIntegration = {
    //   title: 'Survey Monkey',
    //   logoSrc: '/images/integrations/survey-monkey-logo.svg',
    //   logoStyle: '',
    //   description: `Automate reward fulfillment for survey respondents. Automatically send eGift cards,
    //                     physical swag, or gifts as rewards and incentives.`,
    //   listItems: [
    //     'Send by triggering on survey completion',
    //     'Track the gifts you send and compare with survey engagement',
    //     'Create differential campaigns based on number of questions the respondent has answered'
    //   ],
    //   isInstalled: !!this.surveyCredentials.clientId,
    //   installNowActionHandler: () => {
    //     this.surveySetIsShowSettingsPanel(true)
    //   },
    //   moreDetailsActionHandler: () => {
    //     this.$router.push({ name: 'SurveyMonkey' })
    //   }
    // }

    const HubspotIntegration = {
      title: 'Hubspot',
      logoSrc: '/images/integrations/hubspot-logo.png',
      logoStyle: 'top: 30%',
      description: 'Send gifts directly & automatically from your Hubspot dashboard.',
      listItems: [
        'Track the gifts you send as part of your campaigns',
        'Send by triggering Hubspot events',
        'more…'
      ],
      isInstalled: this.hubspotIsInstalled,
      installNowActionHandler: () => {
        this.hubSpotSetIsShowSettingsPanel(true)
      },
      moreDetailsActionHandler: () => {
        this.$router.push({ name: 'Hubspot' })
      }
    }

    // this.integrations.push(surveyMonkeyIntegration)
    this.integrations.push(HubspotIntegration)
  },
  methods: {
    ...mapMutations({
      hubSpotSetIsShowSettingsPanel: 'hubspot/setIsShowSettingsPanel',
      surveySetIsShowSettingsPanel: 'surveyMonkey/setIsShowSettingsPanel'
    }),
    ...mapActions({
      surveyFetchCredentials: 'surveyMonkey/fetchCredentials',
      hubspotFetchCredentials: 'hubspot/fetchCredentials'
    })
  },
  computed: {
    ...mapState('surveyMonkey', {
      surveyCredentials: 'credentials',
      surveyIsShowSettingsPanel: 'isShowSettingsPanel'
    }),
    ...mapState('hubspot', {
      hubSpotCredentials: 'credentials',
      hubspotIsInstalled: 'isInstalled',
      hubSpotIsShowSettingsPanel: 'isShowSettingsPanel'
    })
  }
}
</script>
