<template>
  <footer>
    <div>
      <item-title>Company</item-title>
      <item-list :items="companyMenus" />
    </div>
    <div>
      <item-title>Gifting solutions</item-title>
      <item-list :items="giftingSolutions" />
    </div>
    <div>
      <item-title>Gifting services</item-title>
      <item-list :items="giftingServices" />
    </div>
    <div>
      <partners-list :items="partners" />
    </div>
    <div>
      <item-title>Merchants</item-title>
      <item-list :items="merchants" />
    </div>
    <div>
      <item-title>Help center</item-title>
      <item-list :items="helpCenter" />
    </div>
    <div>
      <item-title>Get notified</item-title>

      <newsletter-form />
    </div>
    <div><social-list :items="socialSections" /></div>
    <div>
      <item-title>Payment methods</item-title>

      <payments-methods :paymentData="paymentMethods" />
    </div>

    <div>
      <footer-copyright />
    </div>
  </footer>
</template>

<script>
import ApiCms from '@/axios/apiCMS'

import ItemList from './TheFooterItemList.vue'
import ItemTitle from './TheFooterItemTitle.vue'
import SocialList from './TheFooterSocialList.vue'
import FooterCopyright from './TheFooterCopyright.vue'
import PartnersList from './TheFooterPartnersList.vue'
import NewsletterForm from './TheFooterNewsletterForm.vue'
import PaymentsMethods from './TheFooterPaymentMethods.vue'

export default {
  name: 'TheFooter',
  components: {
    ItemList,
    ItemTitle,
    SocialList,
    PartnersList,
    NewsletterForm,
    FooterCopyright,
    PaymentsMethods,
  },
  data: () => ({
    companyMenus: [],
    giftingServices: [],
    giftingSolutions: [],
    helpCenter: [],
    socialSections: [],
    logotypes: [],
    merchants: [],
    paymentMethods: {},
  }),
  computed: {
    partners () {
      const { logotypes } = this
      if (!logotypes.length) return []

      return logotypes.map((item, index) => {
        if (index === 0) item.link = 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FnCSiUAN'
        if (index === 1) item.link = 'https://www.securitymetrics.com/site_certificate?id=1672181&tk=c852c607e06b5cd89acbb7d3a4e42b62'
        return item
      })
    }
  },
  mounted () {
    ApiCms.get('/footer?populate=company_menu,gifting_solution_menu,gifting_services,social_section.icon,merchants,logotypes,logotypes.url,help_center,payment_methods').then((data) => {
      this.companyMenus = data?.data.attributes.company_menu ?? []
      this.giftingServices = data?.data.attributes.gifting_services ?? []
      this.giftingSolutions = data?.data.attributes.gifting_solution_menu ?? []
      this.helpCenter = data?.data.attributes.help_center ?? []
      this.socialSections = data?.data.attributes.social_section ?? []
      this.logotypes = data?.data.attributes.logotypes ?? []
      this.merchants = data?.data.attributes.merchants ?? []
      this.paymentMethods = data?.data.attributes.payment_methods ?? {}
    })
  },
}
</script>

<style lang="scss" scoped>
footer {
  padding: 14px 0;
  max-width: 1140px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px 30px;
  flex: 1 1;

  & > div {
    display: flex;
    flex: 1 1 calc(25% - 30px);
    flex-direction: column;
    order: 1;
    gap: 30px;

    &:last-child {
      width: 100%;
      min-width: 100%;
    }
  }

  @media (max-width: 639px) {
    padding: 14px;

    & > div {
      &:nth-child(4) {
        order: 2;
      }

      &:nth-child(8) {
        order: 4;
      }

      &:nth-child(9) {
        order: 3;
      }

      &:last-child {
        order: 5;
      }
    }
  }
}
</style>
