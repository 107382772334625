<template>
  <v-form ref="approval-form" class="send-egift-panel-approval">
    <span>
      Please fill these questions and send your order request for approval:
    </span>

    <common-loader v-if="loading" style="padding: 100px 0" />

    <template v-else>
      <div
        class="send-egift-panel-approval__input-wrapper"
        v-for="formField in approvalFormFieldsToDisplay"
        :key="formField.code"
      >
        <label :for="formField.code">{{ formField.label }}</label>

        <common-select
          v-if="formField.type === 'select'"
          v-model="formValues[formField.code]"
          :items="formField.options"
          :rules="formField.required ? [rules.required] : []"
          item-text="label"
          item-value="value"
          :height="40"
          :id="formField.code"
          placeholder="Select an option"
        />

        <common-select
          v-else-if="formField.type === 'multiple'"
          v-model="formValues[formField.code]"
          :items="formField.options"
          :rules="formField.required ? [rules.requiredMultiple] : []"
          :id="formField.code"
          placeholder="Select an option"
          menuContentClass="select-multiple-entities__select--menu"
          multiple
          class="select-multiple-entities__select"
          :class="{ 'select-multiple-entities__select--chips': true }"
          disable-attach
          item-color="#62646A"
          item-value="value"
          item-text="label"
        >
          <template #selection="{ attrs, item, parent, selected }">
            <v-chip
              class="select-multiple-entities__chip"
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              small
            >
              {{ item.label }}

              <div class="d-flex align-center pointer" @click="parent.selectItem(item)">
                <icons-close :width="10" :height="11" />
              </div>
            </v-chip>
          </template>

          <template #item="{ item, attrs, on }">
            <v-list-item
              class="select-multiple-entities__select--item"
              :ripple="false"
              :aria-selected="attrs['aria-selected'] === 'true' ? 'true' : 'false'"
              v-on="on"
            >
              <div class="select-multiple-entities__select--item--icon" />
              <div class="select-multiple-entities__select--item--name">
                {{ item.label }}
              </div>
            </v-list-item>
          </template>
        </common-select>

        <single-date-picker
          v-else-if="formField.type === 'datepicker'"
          v-model="formValues[formField.code]"
          :rules="formField.required ? [rules.required] : []"
          :id="formField.code"
          :allowPastDates="formField.allow_past_dates"
          style="position: relative; height: 40px"
          picker-style="--min-width: 460px;--picker-margin-top: -1px"
        />

        <common-input
          v-else-if="formField.type === 'text'"
          v-model="formValues[formField.code]"
          :rules="formField.required ? [rules.required] : []"
          :id="formField.code"
          :height="40"
        />

        <approval-file-input
          v-else-if="formField.type === 'upload'"
          v-model="formValues[formField.code]"
          :rules="formField.required ? [rules.required] : []"
          :id="formField.code"
        />
      </div>

      <div class="send-egift-panel-approval__actions">
        <common-button
          :height="44"
          :width="200"
          @click="handleSendCampaign()"
        >
          Send
        </common-button>
      </div>
    </template>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'
import type { IApprovalForm, VuetifyForm } from '../../types'

import Api from '@/axios/api'
import recipientMethodProp from '../mixins/recipientMethodProp'
import approvalDataProps from '../mixins/approvalDataProps'
import takeCurrentDate from '../mixins/takeCurrentDate'
import recipientsProp from '../mixins/recipientsProp'
import SendCampaign from '../mixins/sendCampaign'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'

import SingleDatePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'
import ApprovalFileInput from '@/components/myCampaign/common/ApprovalFileInput.vue'

enum ApprovalFormFieldType {
  Text = 'text',
  Select = 'select',
  Multiple = 'multiple',
  Datepicker = 'datepicker',
  Upload = 'upload',
}

interface IApprovalFormField {
  type: ApprovalFormFieldType;
  code: string;
  label: string;
  required: boolean;
  options: Array<IApprovalFormFieldOption> | null;
  allow_past_dates: boolean | null;
}

interface IApprovalFormFieldOption {
  label: string;
  value: unknown,
  default: boolean;
  question: IApprovalFormField | null;
}

@Component({
  components: {
    SingleDatePicker,
    ApprovalFileInput,
  },
})
export default class SendEgiftPanelApproval extends Mixins(
  recipientMethodProp,
  approvalDataProps,
  takeCurrentDate,
  recipientsProp,
  panelTypeProp,
  SendCampaign,
  campaignProp,
  formProp,
) {
  loading = false;

  approvalFormFields: Array<IApprovalFormField> = []

  approvalFormFieldsToDisplay: Array<IApprovalFormField> = []

  rules = {
    required: (v: string | null | number) => !!v || '',
    requiredMultiple: (v: Array<unknown>) => !!v.length || ''
  }

  get formValues(): IApprovalForm {
    return this.approvalForm
  }

  set formValues(val: IApprovalForm) {
    this.$emit('update:approvalForm', val)
  }

  @Watch('approvalForm', { immediate: true, deep: true })
  handleApprovalFormChange() {
    const newForm = this.approvalFormFields.reduce<Array<IApprovalFormField>>((acc, field) => {
      acc.push(field)

      if (field.type === ApprovalFormFieldType.Select) {
        const additionalQuestions = field.options?.filter((option) => option.question) || []

        additionalQuestions.forEach((additionalQuestion) => {
          if (this.formValues[field.code] === additionalQuestion.value) {
            acc.push(additionalQuestion.question as IApprovalFormField)
          }
        })
      } else if (field.type === ApprovalFormFieldType.Multiple) {
        const additionalQuestions = field.options?.filter((option) => option.question) || []

        additionalQuestions.forEach((additionalQuestion) => {
          const selectedValues = this.formValues[field.code] as Array<string> ?? []

          if (selectedValues.includes(additionalQuestion.value as string)) {
            acc.push(additionalQuestion.question as IApprovalFormField)
          }
        })
      }

      return acc;
    }, [])

    this.approvalFormFieldsToDisplay = newForm;
  }

  mounted() {
    this.loading = true

    Api.get('/customer/approval-form-fields')
      .then(({ data }: { data: Array<IApprovalFormField> }) => {
        this.approvalFormFields = data
        this.setApprovalForm(this.approvalFormFields)
      })
      .finally(() => (this.loading = false))
  }

  setApprovalForm(formFields: Array<IApprovalFormField>): void {
    formFields?.forEach((field) => {
      this.$set(this.formValues, field.code, null)

      if (field.type === ApprovalFormFieldType.Datepicker) {
        this.$set(this.formValues, field.code, this.takeCurrentDate() ?? null)
      }

      if (field.type === ApprovalFormFieldType.Select) {
        const defaultValue = field.options?.find((option) => option.default)?.value || null
        this.$set(this.formValues, field.code, defaultValue)
      }

      if (field.type === ApprovalFormFieldType.Multiple) {
        this.$set(this.formValues, field.code, [])
      }

      const additionalQuestions = field?.options
        ?.map((option) => option.question)
        ?.filter((question) => question) ?? []

      if (additionalQuestions.length) {
        this.setApprovalForm(additionalQuestions as Array<IApprovalFormField>)
      }
    })
  }

  handleSendCampaign(): void {
    if (!(this.$refs['approval-form'] as VuetifyForm).validate()) {
      return
    }

    this.sendCampaign()
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-approval {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 40px;

  & > span {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;

    & > b {
      font-family: inherit;
      font-weight: 600;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    gap: 10px;

    & > label {
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }
  }

  &__actions {
    background: #fff;
    width: 100%;
  }
}
</style>
