import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

export default {
  mixins: [
    masterAccount,
    superAdmin
  ],
  computed: {
    showTestPaymentMethod () {
      if (this.masterAccount || this.superAdmin) {
        return this.$store.state.header?.headerData?.testPayment ?? false
      }

      return false
    }
  }
}
