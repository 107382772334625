export enum RootSteps {
  Recipient = 1,
  RecipientBulkOptions = 2,
  RecipientBulkCopyPaste = 3,
  RecipientBulkContacts = 4,
  ReviewRecipients = 5,
  Review = 6,
  MoreOptions = 7,
  GreetingMessage = 8,
  LogosAndImages = 9,
  Success = 10,
  RefundPolicy = 11,
  DepositInfo = 12,
  Approval = 13,
  GreetingIdeas = 14,
}
