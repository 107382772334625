<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8573 13.9369C11.5421 13.9369 11.2871 14.1902 11.2871 14.5026C11.2871 14.8158 11.5421
        15.0692 11.8573 15.0692C13.8059 15.0692 15.3922 16.6443 15.3922 18.5809C15.3922 18.8302
        15.1875 19.0335 14.9358 19.0335H1.59534C1.34444 19.0335 1.13976 18.8302 1.13976
        18.5809C1.13976 16.6443 2.72603 15.0692 4.67378 15.0692C4.98905 15.0692 5.24407 14.8158
        5.24407 14.5026C5.24407 14.1902 4.98905 13.9369 4.67378 13.9369C2.09631 13.9369 0 16.0195
        0 18.5809C0 19.455 0.716376 20.1667 1.59534 20.1667H14.9358C15.8156 20.1667 16.5328 19.455
        16.5328 18.5809C16.5328 16.0195 14.4356 13.9369 11.8573 13.9369ZM8.26639 5.29897C9.99461
        5.29897 11.4018 7.10936 11.4018 9.33464C11.4018 11.5591 9.99461 13.3703 8.26639
        13.3703C6.53735 13.3703 5.131 11.5591 5.12935 9.33464C5.12935 7.10936 6.53652 5.29897
        8.26639 5.29897ZM8.26639 14.5026C10.6243 14.5026 12.5415 12.1847 12.5415 9.33464C12.5415
        6.48458 10.6243 4.16666 8.26639 4.16666C7.10021 4.16666 6.01079 4.72011 5.20033
        5.72615C4.41958 6.69612 3.99041 7.97684 3.99041 9.33464C3.99041 12.1847 5.90845
        14.5026 8.26639 14.5026Z"
      fill="#95979D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.2 3.36669H16.8V0.966623C16.8 0.525146 16.4425 0.166656 16 0.166656C15.5577 0.166656
        15.2 0.525146 15.2 0.966623V3.36669H12.8C12.3577 3.36669 12 3.72501 12 4.16666C12 4.6083
        12.3577 4.96662 12.8 4.96662H15.2V7.36669C15.2 7.80833 15.5577 8.16666 16 8.16666C16.4425
        8.16666 16.8 7.80833 16.8 7.36669V4.96662H19.2C19.6425 4.96662 20 4.6083 20 4.16666C20
        3.72501 19.6425 3.36669 19.2 3.36669Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddContact',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 21
    }
  }
}
</script>
