var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"inventory-card",attrs:{"outlined":"","tile":"","min-height":"430","width":"350"}},[_c('inventory-card-tray',{attrs:{"qty":_vm.item.product.stock.qty,"inProduction":_vm.item.product.stock.qty_in_production,"onHold":_vm.item.product.stock.qty_on_hold,"minQty":_vm.item.product.notify_for_qty_below.value,"balance":_vm.item.product.balance,"balancePeriod":_vm.item.product.period,"variants":_vm.productVariants},on:{"productSettings":function($event){return _vm.showProductSettings()},"ordersHistory":function($event){return _vm.showOrdersHistory()},"onHoldUnits":function($event){return _vm.showProductOnHold()},"assignUsers":function($event){return _vm.showAssignUsers()}}}),_c('popper',{attrs:{"options":{
      placement: 'top',
      modifiers: {
        flip: { enabled: false },
        offset: {
          offset: '0,-53px',
        },
      },
    }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"z-index":"4"},style:(!_vm.item.product.flags.is_disabled && 'display: none')},[_vm._v(" Purchasing this item is currently disabled. Click \"Product settings\" to enable purchasing this item again. ")]),_c('product-image',{attrs:{"slot":"reference","altText":_vm.item.product.name,"image":_vm.item.product.image,"height":"240"},slot:"reference"},[(_vm.item.product.flags.is_disabled)?_c('div',{staticClass:"inventory-card__locked"},[_c('v-img',{attrs:{"max-width":"32","width":"32","height":"40","src":"/images/lock_white.svg","alt":"Lock icon"}})],1):_vm._e()])],1),_c('v-card-text',{staticClass:"px-6 pt-4 pb-0",staticStyle:{"height":"121px"}},[(_vm.item.product.product_url)?_c('a',{staticClass:"product-name",attrs:{"href":_vm.productUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]):_c('h1',{staticClass:"product-name"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]),(_vm.productVariants.length > 1)?_c('div',{staticClass:"product-variants"},[_vm._l((Object.keys(_vm.productVariantsOptions)),function(variantOption,index){return _c('update-status-sheet',{key:index,attrs:{"color":"#D2F5F3","textColor":"#004642"}},[_vm._v(" "+_vm._s(_vm.productVariantsOptions[variantOption].length)+" "+_vm._s(variantOption)+" options ")])}),_c('popper',{attrs:{"options":{
          placement: 'top',
          modifiers: {
            offset: {
              offset: '0,3px'
            },
          }
        }}},[_c('div',{staticClass:"popper pa-3 f14 text-left",staticStyle:{"max-width":"200px"}},[_vm._v(" Hover the numbers in the card to see specifications and breakdown ")]),_c('icons-question',{staticClass:"pointer",attrs:{"slot":"reference","width":"20","height":"20","color":"#62646A"},slot:"reference"})],1)],2):_vm._e(),_c('div',{staticClass:"f14 product-description ma-0"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.product.description)}})])]),_c('v-card-actions',{staticClass:"pa-6 pt-3"},[(_vm.superAdmin || _vm.masterAccount)?_c('common-button',{attrs:{"block":"","height":44,"outlined":""},on:{"click":function($event){return _vm.refillProduct()}}},[_vm._v(" Order refill ")]):_vm._e(),_c('inventory-card-send-button',{attrs:{"item":_vm.item}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }