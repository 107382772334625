var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart",class:{
    'chart--small': _vm.isChartSmall,
    'chart--show-on-hold': _vm.showOnHold
  },attrs:{"chart-name":_vm.entity.name}},[_c('v-tooltip',{attrs:{"top":"","nudge-top":(_vm.isChartSmall && _vm.showOnHold) ? 4 : 20,"z-index":"4","content-class":_vm.tooltipContentClass},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"chart__wrapper",style:(`
          --chart-height: ${_vm.chartWrapperHeight}%;
        `)},'div',attrs,false),on),[_c('div',{staticClass:"chart__bar",style:(`
            --chart-color: ${_vm.entity.color};
            --chart-height: ${_vm.chartBalanceHeight}%;
          `),attrs:{"chart-value":_vm.entityBalance}}),(_vm.showOnHold)?_c('div',{staticClass:"chart__bar",style:(`
            --chart-color: #4A4A4A;
            --chart-height: ${_vm.chartOnHoldHeight}%;
          `),attrs:{"chart-value":_vm.entityOnHold}}):_vm._e()])]}}])},[(!_vm.hideTooltip)?_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"content--title",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.entity.name)+" ")]),_c('v-col',{staticClass:"content--credits",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"content--dot",style:(`--dot-color: ${_vm.entity.color}`),attrs:{"cols":"6"}},[_vm._v(" Available: ")]),_c('v-col',{staticClass:"text-right black2",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.entityBalance)+" ")]),_c('v-col',{staticClass:"content--dot",staticStyle:{"--dot-color":"#4A4A4A"},attrs:{"cols":"6"}},[_vm._v(" On hold: ")]),_c('v-col',{staticClass:"text-right black2",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.entityOnHold)+" ")])],1)],1)],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }