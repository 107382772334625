export default {
  methods: {
    showCampaignPreview (
      campaignId,
      defaultLogo,
      recipients = [],
      senderName = null,
      subjectLine = null,
      cardMessage = null,
      videoUrl = null,
      calendlyLink = null,
      canRegift = 0, // TODO change API and this...
      canUpgrade = 0, // TODO change API and this...
      newLogo = null,
      hideEmailStep = false,
      freeFormButton = null,
      freeFormUrl = null,
      linkType = null,
      welcomeMessage = null,
    ) {
      const logoUrl = newLogo ?? defaultLogo
      const queryObject = {
        logo_url: logoUrl,
        can_regift: +canRegift,
        can_upgrade: +canUpgrade,
        hide_email_step: hideEmailStep,
      }

      if (senderName) queryObject.sender = senderName
      if (cardMessage) queryObject.greeting_message = cardMessage.replaceAll('\n', '<br />')
      if (videoUrl) queryObject.video_greeting = videoUrl
      if (calendlyLink) queryObject.calendly_link = calendlyLink
      if (subjectLine) queryObject.email_subject_line = subjectLine

      if (freeFormButton && freeFormUrl) {
        queryObject.free_form_button = freeFormButton
        queryObject.free_form_url = freeFormUrl
      }

      if (recipients.length) {
        queryObject.first_name = recipients[0]?.first_name
        queryObject.last_name = recipients[0]?.last_name || ''
        queryObject.email = recipients[0]?.email || ''
      }

      if (linkType) { queryObject.link_type = linkType }
      if (welcomeMessage) { queryObject.welcome_message = welcomeMessage }

      const queryParams = new URLSearchParams(queryObject)

      window.open(
        `${process.env.VUE_APP_EGIFT_URL}/recipient/preview/c/${btoa(campaignId)}/gift?${queryParams}`,
        '_blank'
      )
    }
  }
}
