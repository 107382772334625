import { Vue, Component } from 'vue-property-decorator'
import type{ LinkType } from '@/components/myCampaign/panels/types'

interface IRecipient {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface IPreviewForm {
  campaignId: number;
  logoUrl?: string | null;
  recipients: Array<IRecipient>;
  senderName?: string;
  subjectLine?: string;
  greetingMessage?: string;
  videoUrl?: string;
  calendlyLink?: string | null;
  freeFormButton?: string | null;
  freeFormUrl?: string | null;
  canRegift?: boolean;
  canUpgrade?: boolean;
  hideEmailStep: boolean;
  linkType?: LinkType;
  welcomeMessage?: string | null;
}

interface IQueryObject {
  logo_url: string | null;
  can_regift: number;
  can_upgrade: number;
  hide_email_step: boolean;
  sender?: string;
  greeting_message?: string;
  video_greeting?: string;
  calendly_link?: string;
  email_subject_line?: string;
  free_form_button?: string;
  free_form_url?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  link_type?: LinkType;
  welcome_message?: string | null;
}

@Component
export default class PreviewCampaign extends Vue {
  previewCampaign ({
    campaignId,
    logoUrl,
    recipients = [],
    senderName,
    subjectLine,
    greetingMessage,
    videoUrl,
    calendlyLink,
    freeFormButton,
    freeFormUrl,
    canRegift = false,
    canUpgrade = false,
    hideEmailStep = false,
    linkType,
    welcomeMessage,
  }: IPreviewForm): void {

    const queryObject: IQueryObject = {
      logo_url: logoUrl || null,
      can_regift: +canRegift,
      can_upgrade: +canUpgrade,
      hide_email_step: hideEmailStep,
    }

    if (senderName) { queryObject.sender = senderName }
    if (greetingMessage) {
      queryObject.greeting_message = greetingMessage.replaceAll('\n', '<br />')
    }
    if (videoUrl) { queryObject.video_greeting = videoUrl }
    if (calendlyLink) { queryObject.calendly_link = calendlyLink }
    if (subjectLine) { queryObject.email_subject_line = subjectLine }
    if (linkType) { queryObject.link_type = linkType }
    if (welcomeMessage) { queryObject.welcome_message = welcomeMessage }

    if (freeFormButton && freeFormUrl) {
      queryObject.free_form_button = freeFormButton
      queryObject.free_form_url = freeFormUrl
    }

    if (recipients.length) {
      queryObject.first_name = recipients[0]?.firstName
      queryObject.last_name = recipients[0]?.lastName || ''
      queryObject.email = recipients[0]?.email || ''
    }

    const queryParams = new URLSearchParams(Object.entries(queryObject))

    window.open(
      `${process.env.VUE_APP_EGIFT_URL}/recipient/preview/c/${btoa(`${campaignId}`)}/gift?${queryParams}`,
      '_blank'
    )
  }
}
