<template>
  <v-dialog
    v-model="show"
    persistent
    width="560"
    content-class="elevation-0"
  >
    <div class="campaign-pre-production-dialog__wrapper">
      <div class="campaign-pre-production-dialog__content">
        <h1>ZeroWaste Campaign: Expect 3-6 Weeks for Shipment</h1>

        <span>
          To significantly reduce waste, we initiate the production of gifts only after all eGift recipients have finalized their selections.
        </span>

        <cg-button @click="show = false">Got It, Thanks!</cg-button>
      </div>

      <v-icon color="white" class="pointer" @click="show = false">
        mdi-close
      </v-icon>
    </div>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

import { CgButton } from '@corporategift/design-system'
export default {
  name: 'PreProductionDialog',
  components: { CgButton },
  mixins: [panelVModel],
}
</script>

<style lang="scss" scoped>
.campaign-pre-production-dialog {
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: fit-content;
    margin-inline: auto;
  }

  &__content {
    gap: 20px;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;

    & > h1 {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }

    & > span {
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      line-height: normal;
      font-size: 15px;
      color: #000;
    }

    & > button {
      border-radius: 100px;
    }
  }
}
</style>
