import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import { createSimpleFunctional } from 'vuetify/es5/util/helpers'

Vue.use(Vuetify)

const dialogBottomTransition = createSimpleFunctional(
  'dialog-bottom-transition'
)

Vue.component('dialog-bottom-transition', dialogBottomTransition)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        black: '#000',
        secondary: '#d8d8d8',
        success: '#00A86B',
        red3: '#F26666', // TODO refactor
        primary: '42B77A',
        anchor: '#000'
      }
    }
  }
})
