<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 18"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="9.5" cy="9" r="8.5" :stroke="color"/>
    <path d="M14.6136 8.90519L4.19664 8.90535" :stroke="color" stroke-width="1.17067"/>
  </svg>
</template>

<script>
export default {
  name: 'UsersUnCheck',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 17
    },
    height: {
      type: [Number, String],
      required: false,
      default: 17
    },
    color: {
      type: String,
      required: false,
      default: '#62646A'
    },
  },
}
</script>
