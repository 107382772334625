const createCookieForSameDomain = (name, value, cookieActiveDays = 30) => {
  const cookieName = `${name}=${value};`;
  const path = 'path=/;';

  const domainInfo = window.location.host.split('.');
  if (!['staging', 'development', 'dev', 'corporategift'].includes(domainInfo[0])) domainInfo.splice(0, 1);
  let domain = `domain=.${domainInfo.join('.')};`;
  if (window.location.host.includes('localhost')) domain = '';

  const maxAge = 60 * 60 * 24 * cookieActiveDays;

  const expiration = `max-age=${maxAge};`;

  document.cookie = `${cookieName}${domain}${path}${expiration}`;
}

export default createCookieForSameDomain
