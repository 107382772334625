<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="{
      right
    }"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 0.583332C27.6188 0.583332 35.4167 8.38125 35.4167 18C35.4167 27.6187 27.6188 35.4167
        18 35.4167C8.38127 35.4167 0.583355 27.6187 0.583355 18C0.583355 8.38125 8.38127 0.583332
        18 0.583332ZM21.4944 12.7861C21.7829 12.4875 21.9424 12.0875 21.9388 11.6724C21.9352
        11.2572 21.7687 10.8601 21.4752 10.5665C21.1816 10.273 20.7845 10.1065 20.3693
        10.1028C19.9542 10.0992 19.5542 10.2588 19.2556 10.5472L12.9223 16.8806C12.6254 17.1775
        12.4587 17.5802 12.4587 18C12.4587 18.4198 12.6254 18.8225 12.9223 19.1194L19.2556
        25.4527C19.5542 25.7412 19.9542 25.9008 20.3693 25.8972C20.7845 25.8935 21.1816 25.727
        21.4752 25.4335C21.7687 25.1399 21.9352 24.7428 21.9388 24.3276C21.9424 23.9125 21.7829
        23.5125 21.4944 23.2139L16.2805 18L21.4944 12.7861Z
      "
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'RoundFilledChevron',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24
    },
    right: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.right {
  transform: rotate(180deg);
}
</style>
