<template>
  <v-container class="my-quotes-page py-4 mb-10">
    <v-row no-gutters>
      <v-col cols="12" class="my-quotes-page__title">
        My Quotes
      </v-col>
      <v-col cols="12">
        <my-quotes-table />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import MyQuotesTable from '@/components/myQuotes/MyQuotesTable.vue'

@Component({
  components: { MyQuotesTable },
})
export default class MyQuotes extends Vue {}
</script>

<style lang="scss" scoped>
.my-quotes-page {
  max-width: 1550px;
  padding: 0 64px;

  @media (min-width: 1700px) {
    margin-left: unset;
    margin-right: unset;
    max-width: 100%;
  }

  & > .row {
    gap: 30px;
    flex-direction: column;
  }

  &__title {
    font-family: 'Lato-Light', sans-serif;
    font-size: 40px;
    color: #000;
  }
}
</style>
