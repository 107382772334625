<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    version="1.1"
    :height="height"
    :width="width"
  >
    <g id="My-logos-&amp;-images" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(1.000000, 1.000000)" stroke="#979797">
        <circle
          id="Oval"
          stroke-width="0.5544"
          fill="#FFFFFF"
          cx="10.858802"
          cy="10.858802"
          r="10.858802"
        />
        <g
          id="Group-5"
          transform="translate(11.000000, 11.000000) rotate(90.000000)
            translate(-11.000000, -11.000000) translate(6.000000, 10.000000)
          "
          fill="#D8D8D8"
        >
          <circle id="Oval" cx="1" cy="1" r="1" />
          <circle id="Oval-Copy" cx="5" cy="1" r="1" />
          <circle id="Oval-Copy-2" cx="9" cy="1" r="1" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Options',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 23
    },
    height: {
      type: [Number, String],
      required: false,
      default: 23
    }
  }
}
</script>
