<template>
  <div
    class="common-image-hover"
    @mousemove="handleTooltip($event)"
    @click="downloadOnClick && downloadImage()"
    :class="{ 'common-image-hover--border': showBorder }"
    :style="{ '--padding-size': padding }"
  >
    <slot />

    <div class="common-image-hover__tooltip">
      <slot name="tooltip">
        Download
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonImageHoverTooltip',
  props: {
    padding: {
      type: String,
      required: false,
      default: '20px',
    },
    showBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    downloadOnClick: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    handleTooltip (event) {
      const tooltip = this.$el.querySelector('.common-image-hover__tooltip')

      if (tooltip) {
        const parent = this.$el
        const parentLeft = parent.getBoundingClientRect().left
        const { pageX, clientY } = event
        tooltip.style.left = `${pageX - parentLeft}px`
        tooltip.style.top = `${clientY + 20}px`
      }
    },
    downloadImage () {
      const image = this.$el.querySelector('img')

      if (image) {
        const link = document.createElement('a')
        link.href = image.src
        link.download = 'CorporateGift_QR_Code.png'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
}
</script>

<style lang="scss">
.common-image-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 auto;
  width: fit-content;
  padding: var(--padding-size);
  cursor: pointer;

  &--border {
    border: 1px solid #D9D9D9;
  }

  &:hover {
    & .common-image-hover__tooltip {
      display: block;
    }
  }

  &__tooltip {
    display: none;
    padding: 6px 10px;
    position: absolute;
    z-index: 1;
    background-color: #4A4A4A;
    border-radius: 3px;
    color: #fff;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }
}
</style>
