<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="add">
      <mask
        id="mask0_6240_169856"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect id="Bounding box" y="0.5" width="24" height="24" :fill="color"/>
      </mask>
      <g mask="url(#mask0_6240_169856)">
        <path
          id="add_2"
          d="M11 13.5H5V11.5H11V5.5H13V11.5H19V13.5H13V19.5H11V13.5Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconsAdd',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24
    },
    color: {
      type: String,
      required: false,
      default: '#D9D9D9'
    }
  }
}
</script>
