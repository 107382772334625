<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <g clip-path="url(#clip0_401_2757)">
      <path
        d="M17.75 5H15.1875C14.875 3.5625 13.625 2.5 12.125 2.5C10.625 2.5 9.375 3.5625 9.0625
          5H0.25V6.25H9.0625C9.375 7.6875 10.625 8.75 12.125 8.75C13.625 8.75 14.875 7.6875 15.1875
          6.25H17.75V5ZM12.125 7.5C11.0625 7.5 10.25 6.6875 10.25 5.625C10.25 4.5625 11.0625 3.75
          12.125 3.75C13.1875 3.75 14 4.5625 14 5.625C14 6.6875 13.1875 7.5 12.125 7.5Z"
        :fill="color"
      />
      <path
        d="M0.25 15H2.8125C3.125 16.4375 4.375 17.5 5.875 17.5C7.375 17.5 8.625 16.4375 8.9375
          15H17.75V13.75H8.9375C8.625 12.3125 7.375 11.25 5.875 11.25C4.375 11.25 3.125 12.3125
          2.8125 13.75H0.25V15ZM5.875 12.5C6.9375 12.5 7.75 13.3125 7.75 14.375C7.75 15.4375 6.9375
          16.25 5.875 16.25C4.8125 16.25 4 15.4375 4 14.375C4 13.3125 4.8125 12.5 5.875 12.5Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_401_2757">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SettingsLine',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    },
    color: {
      type: String,
      required: false,
      default: '#919191',
    },
  }
}
</script>
