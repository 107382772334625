<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="600px"
    class="add-credit-card-panel"
  >
    <panel-header :show.sync="show">
      Add new Credit card
    </panel-header>

    <div v-if="showForm" class="add-credit-card-panel__content-wrapper">
      <add-bluesnap-credit-card-form
        v-if="userIsUsingBlueSnap"
        v-bind="{ ...$props }"
        @onAddCard="onSuccess"
      />

      <add-credit-card-form
        v-else
        v-bind="{ ...$props }"
        @onAddCard="onSuccess"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'
import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'

import PanelHeader from '@/components/PanelHeader.vue'
import AddCreditCardForm from './AddCreditCardForm.vue'
import AddBluesnapCreditCardForm from './AddBluesnapCreditCardForm.vue'

export default {
  name: 'AddCreditCardPanel',
  mixins: [
    panelVModel,
    userIsUsingBlueSnap,
  ],
  components: {
    AddBluesnapCreditCardForm,
    AddCreditCardForm,
    PanelHeader,
  },
  props: {
    forceSaveAsDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showForm: false,
  }),
  watch: {
    show: function (val) {
      if (val) {
        this.showForm = true
      } else {
        setTimeout(() => { this.showForm = false }, 200)
      }
    }
  },
  methods: {
    onSuccess () {
      this.show = false;
      this.$emit('onAddCard')
    },
  },
}
</script>

<style lang="scss" scoped>
.add-credit-card-panel {
  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 50px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
</style>
