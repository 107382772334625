<template>
  <div class="order-item-product-customization">
    <div
      class="order-item-product-customization__item"
      v-for="(customization, index) in customizations"
      :key="index"
    >
      <div>{{ parseOptionLabel(customization) }}:</div>
      <div>
        <v-img
          v-for="(image, j) in customization.images"
          :key="j"
          :src="getCustomizationImageLink(image)"
          :alt="`customization-image-${j}`"
          :height="50"
          contain
          :max-height="50"
          :width="50"
          :max-width="50"
        />

        <template v-if="isCustomizationValueALink(customization)">
          <embed
            :src="getCustomizationValueLink(customization)"
            :title="customization.label"
          >
        </template>

        <template v-else-if="valueIsABrokenJSON(customization)">
          <embed
            :src="getLinkFromBrokenJSON(customization)"
            :title="customization.label"
          >
        </template>

        <span v-else v-html="customization.value" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMyOrdersTableProductCustomization',
  props: {
    customizations: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getCustomizationImageLink (customization) {
      const value = customization?.url || customization

      return this.getCustomizationValueLink({ value })
    },
    isCustomizationValueALink ({ value }) {
      return value?.startsWith('https') || value?.startsWith('media/personalize') || false
    },
    getCustomizationValueLink ({ value }) {
      return value?.startsWith('https') ? value : `https://cf.corporategift.com/${value}`
    },
    parseOptionLabel ({ label }) {
      if (label === 'personalize_text_font') { return 'Font' }
      return label
    },
    valueIsABrokenJSON ({ value }) {
      return /.*\{.*\}.*/g.test(value)
    },
    getLinkFromBrokenJSON ({ value }) {
      return value.match(/"https.*."/g)?.at(0).replaceAll('"', '') || null
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-product-customization {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 14px;
  background-color: #fff;
  max-height: 350px;
  max-width: 500px;
  overflow-y: auto;
  margin-left: 6px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 16px;
    min-height: 60px;
    flex: 1 1 auto;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    color: #16181F;
    border-bottom: 1px solid #D3D2D2;

    & > div {
      display: flex;
      flex: 0 1 auto;

      &:first-child {
        color: #9B9B9B;
        min-width: 100px;
        text-transform: capitalize;
      }

      &:last-child {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
    }

    & img,
    & embed {
      object-fit: contain;
      max-width: 50px;
      max-height: 50px;
    }
  }
}
</style>
