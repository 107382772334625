<template>
  <v-dialog v-model="show" persistent content-class="elevation-0" width="840">
    <v-container>
      <v-row dense>
        <v-col cols="11" v-if="show">
          <iframe
            height="500"
            width="746"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            frameborder="0"
            :src="videoUrl"
            style="box-shadow: rgb(0 0 0 / 75%) 0px 13px 18px -12px; background: #fff"
          />
        </v-col>
        <v-col cols="1">
          <div class="d-flex pointer justify-end" @click="show = false">
            <icons-close
              :width="30"
              :height="30"
              color="#000"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'SettingsPanelVideoDialog',
  mixins: [panelVModel],
  props: {
    videoUrl: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
