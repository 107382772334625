<template>
  <div
    class="active-egift-orders dashboard-card dashboard-card--small"
    :class="{
      'order-2': !hasCgCredits && $vuetify.breakpoint.lgAndUp,
      'order-3': adminAccount && !$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.xsOnly,
      'order-4': (adminAccount && $vuetify.breakpoint.lgAndUp) ||
        !adminAccount && hasCgCredits ||
        marketplaceUser || $vuetify.breakpoint.xsOnly,
    }"
  >
    <div class="active-egift-orders__title">
      Active e-Gift Orders

      <dashboard-card-tooltip offset="0, 5px">
        {{ getTooltipTextBySlug('dashboard_account_active_egift_orders') }}
      </dashboard-card-tooltip>
    </div>
    
    <v-divider />

    <template v-if="!loading">
      <div class="active-egift-orders__info" v-if="activeOrders > 0 || unredeemedGifts > 0">
        <div class="info__gits-amount">
          <div class="amount-info">
            <span>{{ activeOrders }}</span>
            <span>Active orders</span>
          </div>

          <v-divider vertical />

          <div class="amount-info">
            <span>{{ unredeemedGifts }}</span>
            <span>Unredeemed eGifts</span>
          </div>
        </div>
        <div class="info__unredeemed">
          <span>
            {{ unredeemedValue | priceFilter }}
          </span>
          <span>
            Total unredeemed value
          </span>
        </div>
        <div class="info__links">
          <router-link :to="{ name: 'EgiftOrders' }">View all eGift orders</router-link>
        </div>
      </div>

      <div class="active-egift-orders__empty" v-else>
        <icons-empty-active-egift-orders />

        <span>
          You have no active eGift orders
          <router-link :to="{ name: 'EgiftOrders' }">View all eGift orders</router-link>
        </span>
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'
import adminAccount from '@/mixins/adminAccount'
import marketplaceUser from '@/mixins/marketplaceUser'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import DashboardCardTooltip from './common/DashboardCardTooltip.vue'

export default {
  name: 'TheActiveEgiftOrders',
  components: {
    DashboardCardTooltip
  },
  mixins: [
    adminAccount,
    marketplaceUser,
    getTooltipTextBySlug,
  ],
  props: {
    hasCgCredits: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    unredeemedValue: 0,
    activeOrders: 0,
    unredeemedGifts: 0,
  }),
  created () {
    this.loading = true
    Api.get('/customer/dashboard/active-egift-orders')
      .then(({ data }) => {
        this.unredeemedValue = data.unredeemed_egifts_value
        this.activeOrders = data.active_egift_orders_count
        this.unredeemedGifts = data.unredeemed_egifts_count
      })
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.active-egift-orders {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > hr {
    border-color: #D3D2D2;
  }

  & a {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #42B77A !important;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;
    color: #95979D;
    text-transform: uppercase;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    height: 100%;

    & > .info {
      &__unredeemed {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        padding: 10px;
        background-color: #38A39C;
        border-radius: 10px;

        & > span {
          color: #fff;
          font-family: 'Lato-Bold', sans-serif;

          &:nth-child(1) {
            font-size: 40px;
            line-height: 60px;
          }

          &:nth-child(2) {
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }

      &__links {
        margin-top: auto;
        display: flex;
        justify-content: flex-end;
      }

      &__gits-amount {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 24px;
        align-items: center;
        justify-content: space-evenly;

        & > .amount-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 140px;
          max-width: 140px;
          gap: 8px;

          & > span {
            color: #62646A;
            font-family: 'Lato-Bold', sans-serif;

            &:nth-child(1) {
              font-size: 40px;
              line-height: 40px;
            }

            &:nth-child(2) {
              font-size: 12px;
              line-height: 14px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 28px;
    align-items: center;
    justify-content: center;

    & > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      font-family: 'Lato-Italic', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #95979D;
    }
  }
}
</style>