<template>

  <v-select
    class="advanced-filter rounded-0"
    v-model="filter"
    :items="filters"
    :item-text="itemText"
    :label="label"
    :item-value="itemValue"
    dense
    outlined
    solo
    flat
    hide-details
    tile
    height="30"
    @change="$emit('change', filter)"
    :loading="!filters.length && loaded"
    :disabled="(!filters.length && loaded) || disabled"
  >
    <template #prepend-item v-if="isInputVisible && items.length > 3">
      <v-text-field
        class="order-filter__input rounded-0 filter-text-field"
        v-model="search"
        color="black"
        outlined
        solo
        flat
        dense
        hide-details
        tile
        height="30"
        full-width
      />
    </template>

    <template v-slot:item="{ item, attrs, on }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
        class="advanced-filter__item"
      >
        <v-list-item-content>
          <div class="advanced-filter__item-wrapper">
            <img
              v-if="item.image"
              class="advanced-filter__item-img" :src="getImageSrc(item.image)">
            <v-list-item-title
              class="advanced-filter__item-title"
              v-text="item[itemText]"
            ></v-list-item-title>
            <v-list-item-subtitle
              class="advanced-filter__item-subtitle"
              v-text="item[itemValue]"></v-list-item-subtitle>
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>

  </v-select>
</template>

<script>
export default {
  name: 'InputFilterAdvanced',
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    isInputVisible: {
      type: Boolean,
      default: true
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    selected: {
      default: 0,
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    filter: undefined,
    isSelected: false
  }),
  methods: {
    getImageSrc (itemImage) {
      return process.env.VUE_APP_MAGENTO_URL + '/media/catalog/product/' + itemImage
    }
  },
  created () {
    if (this.selected) {
      this.items.filter((item) => {
        if (item[this.itemValue].toString() === this.selected.toString()) {
          this.filter = item[this.itemValue]
        }
      })
    }
  },
  watch: {
    selected (val) {
      if (!val) {
        this.filter = undefined
      } else {
        this.filter = val
      }
    }
  },
  computed: {
    filters () {
      return this.items.filter(item => item[this.itemText] && item[this.itemText].toLowerCase().includes(this.search.toLowerCase()))
    }
  }
}
</script>

<style lang="scss">
  @import "./src/css/common/input-filter-advanced";
</style>
