var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campaigns-tags-combobox__wrapper"},[_c('label',{attrs:{"for":"campaign-tags"}},[_vm._v("Tags associated with this campaign:")]),_c('v-combobox',{ref:"tags-select",staticClass:"campaigns-tags-combobox rounded-0",attrs:{"multiple":"","small-chips":"","clearable":"","id":"campaign-tags","items":_vm.campaignsTags,"append-icon":"mdi-chevron-down","color":"#62646A","outlined":"","dense":"","item-color":"#62646A","item-value":"id","item-text":"name","hide-details":"","menu-props":{
      bottom: true,
      offsetY: true,
      left: true,
      tile: true,
      contentClass: 'campaigns-tags-combobox__select'
    }},on:{"input":_vm.handleInputChange},scopedSlots:_vm._u([{key:"selection",fn:function({ attrs, item, parent, selected }){return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"d-flex align-center pointer",on:{"click":function($event){return parent.selectItem(item)}}},[_c('icons-close',{attrs:{"width":10,"height":11}})],1)]):_vm._e()]}},(_vm.adminAccount)?{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"px-4 py-2 campaigns-tags-combobox__new-tag",class:{ 'campaigns-tags-combobox__new-tag--active': _vm.addNewTag }},[_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){_vm.addNewTag = !_vm.addNewTag}}},[_c('icons-plus-circle',{attrs:{"width":28,"height":28}}),(!_vm.addNewTag)?[_vm._v(" Add new tag ")]:_vm._e()],2),(_vm.addNewTag)?_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[_c('common-input',{attrs:{"height":30,"error":_vm.showNewTagError},model:{value:(_vm.newTagName),callback:function ($$v) {_vm.newTagName=$$v},expression:"newTagName"}}),_c('common-button',{attrs:{"height":30,"loading":_vm.loadingNewTag},on:{"click":function($event){return _vm.sendNewTag()}}},[_vm._v(" Add tag ")])],1):_vm._e()])]},proxy:true}:null,{key:"item",fn:function({ item, attrs }){return [_c('div',{staticClass:"campaigns-tags-combobox__select--item"},[_c('div',{staticClass:"campaigns-tags-combobox__select--item--icon",class:{
            'campaigns-tags-combobox__select--item--icon--selected': attrs['aria-selected'] === 'true'
          }}),_c('div',{staticClass:"campaigns-tags-combobox__select--item--name"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"gray3 f14 px-4 py-2"},[_vm._v(" No data ")])]},proxy:true}],null,true),model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }