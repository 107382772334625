<template>
  <div
    class="inventory-legend"
    :class="{ 'inventory-legend--outlined': outlined }"
  >
    <div
      class="inventory-legend__dot"
      :style="`--dot-color: ${setBackgroundColor()}`"
    />
    <slot />
  </div>
</template>

<script>
export default {
  // TODO change name
  name: 'LegendDot',
  props: {
    color: {
      type: String,
      required: true,
      validator: val => {
        return ['red', 'green', 'yellow', 'grey'].includes(val)
      }
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    setBackgroundColor () {
      const { color } = this

      switch (color) {
        case 'red':
          return '#F15B5B'
        case 'green':
          return '#198B85'
        case 'yellow':
          return '#ECD885'
        case 'grey':
          return '#C8C8C8'
        default:
          return '#C8C8C8'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inventory-legend {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-family: 'Lato-Italic', Lato, sans-serif;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 14px;
  color: #62646A;

  &__dot {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: var(--dot-color, #C8C8C8);
  }

  &--outlined > &__dot {
    border: 1px solid var(--dot-color, #C8C8C8);
    background-color: #fff;
  }

  @media screen and (max-width: 430px) {
    justify-content: flex-start;
    font-family: 'Lato', sans-serif;
    line-height: 24px;
    font-weight: 400;
    color: #222325;
    font-style: normal;
    gap: 12px;

    &__dot {
      height: 18px;
      width: 18px;
    }
  }
}
</style>
