<template>
  <div
    class="address-book-checkbox"
    :class="disabled && 'address-book-checkbox--disabled'"
    @click="onCheckboxClick"
  >
    <div
      class="d-flex address-book-checkbox__checkbox"
      :class="checkboxIsSelected && 'address-book-checkbox__checkbox--active'"
    >
    </div>
    <div class="d-flex address-book-checkbox__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheRecipientsAddressBookCheckbox',
  props: {
    value: {
      type: Array,
      required: true
    },
    inputValue: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    checkboxValue: {
      get () {
        return this.inputValue
      },
      set (val) {
        let array = this.value

        if (array?.filter((item) => item.entity_id === val.entity_id).length || false) {
          array = array?.filter((item) => item.entity_id !== val.entity_id) || []
        } else {
          array.push(val)
        }
        this.$emit('input', array)
      }
    },
    checkboxIsSelected () {
      const { value, inputValue } = this
      return value?.filter((item) => item.entity_id === inputValue.entity_id).length || false
    }
  },
  methods: {
    onCheckboxClick () {
      const { checkboxValue, disabled } = this
      if (!disabled) this.checkboxValue = checkboxValue
    }
  }
}
</script>

<style lang="scss" scoped>
.address-book-checkbox {
  cursor: pointer;
  font-family: 'Lato-Regular', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  &--disabled {
    cursor: not-allowed !important;
  }

  &__checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #DEDEDE;
    background-color: #fff;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    &--active {
      border: none;
      background-color: #42B77A;

      &::after {
        content: ' ';
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  &__content {
    color: #000 !important;
    font-size: 15px;
  }
}
</style>
