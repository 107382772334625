<template>
  <div class="send-egift-panel-review-recipients">
    <table class="send-egift-panel-review-recipients__table">
      <thead>
        <tr>
          <th>#</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Email <template v-if="isSelectedGiftLink">(Optional)</template></th>
          <th>Send date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(recipient, index) in egiftRecipients" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <template v-if="editRowId === index">
              <common-input
                v-model="editForm.firstName"
                :rules="firstNameValidation"
                :height="30"
              />
            </template>
            <template v-else>{{ recipient.firstName }}</template>
          </td>
          <td>
            <template v-if="editRowId === index">
              <common-input
                v-model="editForm.lastName"
                :rules="lastNameValidation"
                :height="30"
              />
            </template>
            <template v-else>{{ recipient.lastName }}</template>
          </td>
          <td :class="{
            'invalid-value-in-row': isRecipientEmailInvalid(recipient.email) && editRowId !== index
          }">
            <template v-if="editRowId === index">
              <common-input
                v-model="editForm.email"
                :rules="emailValidation"
                type="email"
                :height="30"
              />
            </template>
            <template v-else>
              <template v-if="recipient.email">
                {{ recipient.email }}
              </template>

              <template v-else>
                Missing email address
              </template>
            </template>
          </td>
          <td>
            <template v-if="editRowId === index">
              <single-date-picker
                v-model="editForm.sendDate"
                :id="`recipient-send-date-${index}`"
                class="send-egift-panel-review-recipients__date-picker"
                style="height: 30px; max-width: 126px"
                picker-style="--min-width: 120px;--picker-margin-top: -7px;"
                show-checkbox
                :height="30"
                :checkboxValue.sync="setSendDateForAllRecipients"
              />
            </template>
            <template v-else>{{ recipient.sendDate | dateFilter }}</template>
          </td>
          <td>
            <div class="send-egift-panel-review-recipients__table-actions">
              <template v-if="editRowId === index">
                <icons-close-circle
                  @click.native="cancelEditing"
                  :height="24"
                  :width="24"
                  color="#95979D"
                />

                <icons-check-circle
                  @click.native="saveChanges"
                  :height="24"
                  :width="24"
                  :check-stroke-width="1.4"
                  outlineCheckColor="#42B77A"
                  outlineColor="#42B77A"
                  fillColor="#fff"
                />
              </template>
              <template v-else>
                <icons-edit
                  @click.native="editRecipientInfo(index, recipient)"
                  :width="13"
                  :height="13"
                />

                <icons-delete
                  @click.native="deleteRecipient(index)"
                  :width="14"
                  :height="17"
                />
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>    

    <common-button
      :height="44"
      :width="220"
      :disabled="disableContinueButton"
      @click="handleContinue"
    >
      Save recipients info
    </common-button>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps, RecipientMethod, LinkType } from '../../types'
import type { IRecipient } from '../../types'

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import recipientMethodProp from '../mixins/recipientMethodProp'
import recipientValidations from '../mixins/recipientValidations'

import SingleDatePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

@Component({
  components: {
    SingleDatePicker,
  },
})
export default class SendEgiftPanelReviewRecipients extends Mixins(
  panelTypeProp,
  recipientsProp,
  recipientMethodProp,
  recipientValidations,
) {
  editRowId: number | null = null

  setSendDateForAllRecipients = false

  editForm: IRecipient = {
    firstName: null,
    lastName: null,
    email: null,
    sendDate: null,
  }

  get egiftRecipients (): Array<IRecipient> {
    return this.recipients
  }

  set egiftRecipients (val: Array<IRecipient>) {
    this.$emit('update:recipients', val)
  }

  get disableContinueButton (): boolean {
    const { egiftRecipients, isRecipientDataInvalid } = this

    if (!egiftRecipients.length) { return true }

    const validationResults = egiftRecipients.reduce((acc: boolean[], recipient: IRecipient): boolean[] => {
      acc.push(isRecipientDataInvalid(recipient))
      return acc
    }, [])

    return validationResults.includes(true)
  }

  created() {
    if (
      [RecipientMethod.UnrestrictedLink, RecipientMethod.Manually].includes(this.recipientMethod)
    ) {
      this.$emit('update:step', RootSteps.Recipient)
    }
  }

  isRecipientDataInvalid (recipient: IRecipient): boolean {
    const { firstNameValidation, lastNameValidation, emailValidation } = this

    const firstNameResult = firstNameValidation
      .map((validation) => {
        return validation(recipient?.firstName || null)
      })
      .includes('')

    const lastNameResult = lastNameValidation
      .map((validation) => {
        return validation(recipient?.lastName || null)
      })
      .includes('')

    const emailResult = emailValidation
      .map((validation) => {
        return validation(recipient?.email || null)
      })
      .includes('')

    return firstNameResult || lastNameResult || emailResult || false
  }

  isRecipientEmailInvalid (email: string | null): boolean {
    const { emailValidation } = this

    if (!email) { return true }

    if (this.isEmailDuplicated(email)) { return true }

    return emailValidation
      .map((validation) => validation(email || null))
      .includes('')
  }

  isEmailDuplicated (emailToCheck: string): boolean {
    const { egiftRecipients } = this

    if (!emailToCheck) return false

    const emails = egiftRecipients?.map((recipient) => recipient.email) ?? []
    return emails.filter((email) => email === emailToCheck).length !== 1
  }

  editRecipientInfo (index: number, recipient: IRecipient): void {
    this.editRowId = index

    this.editForm = Object.assign({}, recipient)
  }

  deleteRecipient (index: number): void {
    this.egiftRecipients.splice(index, 1)

    if (index === this.editRowId) { this.cancelEditing() }
  }

  saveChanges (): void {
    const { editForm, editRowId } = this
    // TODO validate inputs??
    if (this.isRecipientDataInvalid(editForm)) { return }

    const recipientIndex = this.egiftRecipients.findIndex((recipient, index) => index === editRowId)

    if (recipientIndex !== -1) {
      this.$set(this.egiftRecipients, recipientIndex, { ...editForm, })
    }

    if (this.setSendDateForAllRecipients) {
      this.egiftRecipients?.forEach((recipient, index) => {
        this.$set(this.egiftRecipients, index, {
          ...recipient,
          sendDate: editForm.sendDate,
        })
      })
    }

    this.cancelEditing()
  }

  cancelEditing (): void {
    this.editRowId = null

    this.editForm = {
      firstName: null,
      lastName: null,
      email: null,
      sendDate: null,
    }
  }

  handleContinue (): void {
    this.$emit('update:singleGiftLinkRecipients', 0)
    this.$emit('update:linkType', LinkType.StandardLink)
    this.$emit('update:step', RootSteps.Review)
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-review-recipients {
  display: flex;
  flex-direction: column;
  gap: 45px;

  &__table {
    margin: 0;
    border: none;
    border-spacing: 0;

    & > thead > tr > th,
    & > tbody > tr > td {
      &:first-child {
        min-width: 20px;
        width: 20px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        min-width: 130px;
        width: 130px;
        max-width: 130px;
      }
      
      &:nth-child(5) {
        min-width: 140px;
        width: 140px;
        max-width: 140px;
      }

      &:last-child {
        min-width: 60px;
        width: 60px;
        max-width: 60px;
      }
    }

    & > thead > tr > th {
      text-align: start;
      font-family: 'Lato-Bold', sans-serif;
      background: #fff;
      font-weight: 700;
      padding: 12px 2px;
      border-top: 1px solid #D3D2D2;
      border-bottom: 1px solid #D3D2D2;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #000000;
    }

    & > tbody > tr {
      background: #fff;
      cursor: default;
      position: relative;

      &:hover {
        background: #fff !important;

        & > td {
          background: #fff !important;
        }
      }

      & > td {
        padding-bottom: 30px;
        padding: 18px 2px;
        border-bottom: 1px solid #D3D2D2;
        font-family: 'Lato-Regular', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;

        &.invalid-value-in-row {
          color: #FF5A60;
        }

        &:nth-child(4) {
          word-break: break-all;
        }
      }
    }
  }

  &__table-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    & > svg {
      cursor: pointer;
    }
  }

  &__date-picker::v-deep {
    & .v-input__slot {
      margin-left: 0 !important;
    }

    & .v-input {
      min-height: 30px !important;
    }

    & fieldset {
      padding: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
</style>
