<template>
  <v-navigation-drawer
    :value='isShowSettingsPanel'
    app
    temporary
    right
    width='950px'
    class='side_panel'
    v-click-outside="clickOutside"
  >
    <close-panel-button
      @close='setIsShowSettingsPanel(false)'
      :text="'Back to My Integrations'"
    />
    <main class="px-10">
      <div class="integration-setting">
        <p class="integration-setting__title">Hubspot Integration Settings</p>
        <div class="integration-setting__field">
          <label class="integration-setting__label">Name:</label>
          <strong>{{credentials.name}}</strong>
        </div>
        <div class="integration-setting__field">
          <label class="integration-setting__label">Email:</label>
          <strong>{{credentials.email}}</strong>
        </div>
        <template v-if="credentials.appId">
          <div class="integration-setting__field">
            <label class="integration-setting__label">App ID:</label>
            <strong>{{credentials.appId}}</strong>
          </div>
        </template>
      </div>
      <div class="integration-setting-action">
        <common-button
          width="220"
          height="40"
          :outlined="isInstalled"
          :disabled="isInstalled"
          :href="credentials.authUrl"
        >
          Connect to hubspot
        </common-button>
      </div>
    </main>
  </v-navigation-drawer>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('hubspot')

export default {
  name: 'SettingsPanel',
  data: () => {
    return {
      clientIdError: '',
      secretError: ''
    }
  },
  computed: {
    ...mapState([
      'isShowSettingsPanel',
      'credentials',
      'isInstalled'
    ])
  },
  methods: {
    ...mapMutations([
      'setIsShowSettingsPanel'
    ]),
    ...mapActions([
      'createAccount',
      'fetchCredentials'
    ]),
    clickOutside (e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.setIsShowSettingsPanel(false)
      }
    }
  },
  mounted () {
    // this.fetchCredentials()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/css/integrations/settings-panel";
.red1 {
  display: block;
}
.integration-setting {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(215, 215, 215, 1);

  &__field {
    margin-bottom: 10px;
  }
  &__label {
    width: 55px;
    display: inline-block;
    margin-right: 10px;
  }
}
.integration-setting-action {
  margin-top: 30px;

  a {
    color: #fff;
  }
}
</style>
