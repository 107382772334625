<template>
  <div class="send-egift-panel-success">
    <div v-if="showApprovalMessage" class="send-egift-panel-success__approval">
      <icons-check-circle
        filled
        :checkStrokeWidth="1.5"
        :width="30"
        :height="30"
      />

      <div>
        <span>The order was sent for approval, you will be notified once it is approved.</span>
        <span>Your order number is {{ successResponse.order_number }}</span>
      </div>
    </div>

    <template v-else>
      <div class="send-egift-panel-success__order-info">
        <template v-if="panelTypeIsLink && !isStandardLink">
          <div class="send-egift-panel-success__title">
            <div class="send-egift-panel-success__check" />

            Single link for {{ recipientsCount }} redeems created successfully
          </div>

          <ul>
            <li v-for="(feature, index) in linkTypeFeatures" :key="index">
              {{ feature }}
            </li>
          </ul>

          <div class="send-egift-panel-success__info">
            You will receive an order confirmation email with your order details and a link to track its progress. 
          </div>
        </template>

        <template v-else>
          <div class="send-egift-panel-success__thank-you">
            <icons-thumb-up
              :width="32"
              :height="32"
            />
            Thank you!
          </div>

          <div class="send-egift-panel-success__order-number">
            <div>Your order number is {{ successResponse.order_number }}</div>
            <div>
              You will receive a confirmation email with your order details and a link to track its progress.
            </div>
          </div>

          <div v-if="panelTypeIsEgift" class="send-egift-panel-success__track-order">
            To view eGift redemption status, track deliveries, manage eGift orders, and view estimated
            and actual costs, visit
            <router-link class="green2 pointer" :to="{ name: 'EgiftOrders' }">
              My E-Gifts Orders
            </router-link>
            dashboard in your account.
          </div>

          <div
            v-else-if="panelTypeIsLink"
            class="send-egift-panel-success__track-order send-egift-panel-success__track-order--link"
          >
            <template v-if="recipientsCount === 1">
              <icons-link />

              <div>
                <a
                  role="button"
                  tabindex="0"
                  @keypress="copySingleLink()"
                  @click="copySingleLink()"
                >
                  Copy link
                </a>
                and send it to your recipient.
              </div>
            </template>

            <template v-else>
              <icons-download-invoice
                :width="16"
                :height="20"
              />

              <div>
                <a
                  role="button"
                  tabindex="0"
                  @keypress="downloadFileWithLinks()"
                  @click="downloadFileWithLinks()"
                >
                  Download file
                </a>
                with links for your recipients.
              </div>
            </template>
          </div>

          <div class="send-egift-panel-success__link" @click="showPreview()">
            Preview your eGift
          </div>
        </template>
      </div>

      <v-divider v-if="panelTypeIsLink && !isStandardLink" />

      <div class="send-egift-panel-success__actions">
        <template v-if="panelTypeIsLink && !isStandardLink">
          <div class="send-egift-panel-success__input-wrapper">
            <label for="egift-link">Here’s your gift-link</label>

            <common-input
              v-model="egiftLink"
              id="egift-link"
              disabled
              style="max-width: 500px"
              @click.native="copyLink()"
            />
          </div>

          <div class="send-egift-panel-success__buttons">
            <common-button
              outlined
              :height="44"
              style="min-width: 240px"
              @click="showQrCode = true"
            >
              Generate QR code
            </common-button>

            <common-button :height="44" @click="copyLink()">
              Copy link

              <icons-copy color="#fff" :width="20" :height="20" />
            </common-button>
          </div>

          <div v-if="showQrCode">
            <common-image-hover-tooltip>
              <qr-code :text="egiftLink" :size="200" error-level="L" />
            </common-image-hover-tooltip>
          </div>
        </template>
      </div>
    </template>

    <pre-production-dialog v-model="showPreProductionDialog" />
  </div>
</template>

<script lang="ts">
import { Mixins, Component, Prop } from 'vue-property-decorator'
import type { ISendCampaignResponse } from '../../types'
import { PanelType, RecipientMethod, LinkType } from '../../types'

import recipientMethodProp from '../mixins/recipientMethodProp'
import approvalDataProps from '../mixins/approvalDataProps'
import PreviewCampaign from '@/mixins/previewCampaign'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'
// @ts-ignore
import QrCode from 'vue-qrcode-component'

import PreProductionDialog from '@/components/myCampaign/panels/sendEgiftPanel/PreProductionDialog.vue'

@Component({
  components: {
    QrCode,
    PreProductionDialog,
  }
})
export default class SendEgiftPanelSuccess extends Mixins(
  recipientMethodProp,
  approvalDataProps,
  PreviewCampaign,
  panelTypeProp,
  campaignProp,
  formProp,
) {
  @Prop({ required: true }) successResponse!: ISendCampaignResponse | null;

  showQrCode = false;

  showPreProductionDialog = false;

  get panelTypeIsLink (): boolean {
    return this.panelType === PanelType.Link
  }

  get panelTypeIsEgift (): boolean {
    return this.panelType === PanelType.EGift
  }

  get isStandardLink (): boolean {
    const { form, recipientMethod } = this

    if (recipientMethod === RecipientMethod.UnrestrictedLink) { return false }

    return form.linkType === LinkType.StandardLink
  }

  get egiftLink (): string {
    return this.successResponse?.url || ''
  }

  get recipientsCount (): number {
    return this.successResponse?.number_of_recipients || 0
  }

  get isAutoApproved (): boolean {
    return this.successResponse?.is_approved ?? false
  }

  get showApprovalMessage (): boolean {
    const { isAutoApproved, checkoutNeedsApproval } = this;
    return !isAutoApproved && checkoutNeedsApproval
  }

  get linkTypeFeatures (): Array<string> {
    const { linkType } = this.form

    const result = [
      'Recipients must validate their emails to redeem',
      'Each email address is eligible for 1 gift redemption',
    ]

    if (linkType === LinkType.UnrestrictedLink) result.splice(0, 1)
    return result
  }

  get isCampaignPreProduction (): boolean {
    return this.campaign.is_pre_production ?? false
  }

  mounted () {
    if (this.isCampaignPreProduction) {
      this.showPreProductionDialog = true;
    }
  }

  copyLink (): void {
    navigator.clipboard.writeText(this.egiftLink)

    this.$store.dispatch('snackbar/showSnackbar', {
      message: 'Link copied to clipboard',
      type: 'success'
    })
  }

  copySingleLink (): void {
    const { recipients } = this.successResponse as ISendCampaignResponse

    navigator.clipboard.writeText(recipients[0]?.url)

    this.$store.dispatch('snackbar/showSnackbar', {
      message: 'Link copied to clipboard',
      type: 'success'
    })
  }

  downloadFileWithLinks (): void {
    const { recipients } = this.successResponse as ISendCampaignResponse

    const headers = ['First name', 'Last name', 'Email', 'URL']
    let csvContent = 'data:text/csvcharset=utf-8,' + headers.join(',') + '\n'

    csvContent += recipients
      .map((r) => [r.first_name, r.last_name, r.email, r.url].join(','))
      .join('\n')

    const fileUrl = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', fileUrl)
    link.setAttribute('download', 'recipients_links.csv')
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  showPreview(): void {
    const { form, campaign, panelType } = this

    this.previewCampaign({
      campaignId: campaign.entity_id,
      logoUrl: form.logoUrl || undefined,
      recipients: [],
      senderName: form.giftGiverName || undefined,
      subjectLine: form.emailSubjectLine || undefined,
      greetingMessage: form.greeting || undefined,
      calendlyLink: form.calendlyLink || undefined,
      videoUrl: form.videoGreeting || undefined,
      canUpgrade: form.settings.recipientCanUpgrade,
      canRegift: form.settings.recipientCanReEgift,
      hideEmailStep: panelType === PanelType.Link,
    })
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-success {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 40px;

  &__order-info, &__actions {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__order-number {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    & > div {
      &:nth-child(1) {
        font-family: 'Lato-Regular', sans-serif;
        font-size: 25px;
        line-height: 30px;
        color: #000;
      }

      &:nth-child(2) {
        font-family: 'Lato-Italic', sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #9f9f9f;
      }
    }
  }

  &__approval {
    background: #D8F1E4;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 16px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > span {
        font-size: 15px;
        line-height: 18px;
        color: #000;

        &:nth-child(1) {
          font-family: 'Lato-Bold', sans-serif;

        }

        &:nth-child(2) {
          font-family: 'Lato-Regular', sans-serif;
        }
      }
    }
  }

  &__thank-you {
    font-family: 'Lato-Light', sans-serif !important;
    font-size: 40px;
    line-height: 48px;
    color: #000;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  &__info {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }

  &__check {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #42B77A;

    &::before {
      content: '';
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      width: 12px;
      height: 8px;
      transform: rotate(-45deg) translateX(1px) translateY(-1px);
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > label {
      font-family: 'Lato-Bold', sans-serif;
      flex-direction: row;
      width: fit-content;
      display: flex;
      gap: 10px;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }

    ::v-deep input[disabled] {
      color: #4A4A4A;
      cursor: pointer;
    }
  }

  & ul  {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    margin: 0;

    & > li {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 15px;
      line-height: 22px;
      color: #000000;

      &::before {
        content:'·';
        display: inline-flex;
        font-size: 40px;
        margin-bottom: 2px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 14px;

    &::v-deep button {
      text-transform: unset;

      & > span {
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-family: 'Lato-Bold', sans-serif;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    font-family: 'Lato-Regular', sans-serif;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #42B77A;

    &:hover {
      color: #219358;
    }
  }

  &__track-order {
    background-color: #F6F6F6;
    padding: 20px 30px;
    max-width: 640px;
    width: 100%;
    margin: 24px auto;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #000;

    & a {
      color: #42B77A !important;
      cursor: pointer;

      &:hover {
        color: #219358 !important;
      }
    }

    &--link {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 16px;

      & > div {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        line-height: inherit;
      }
    }
  }
}
</style>
