<template>
  <div class="d-flex w-100">
    <common-button
      block
      elevation="0"
      :disabled="disabled"
      @click="active = !active"
      color="black darken-4 white--text"
    >
      <slot>
        {{ giftType === 'single' ? 'Send gift' : 'Send E-gift' }}
      </slot>

      <div
        class="btn__arrow"
        :class="active && 'btn__arrow--up'"
        v-if="!disabled"
      />
    </common-button>

    <v-expand-transition>
      <div class="btn-menu elevation-3" v-show="active">
        <v-row no-gutters>
          <v-col
            cols="12"
            class="py-2 px-4 btn-menu__link"
            @click="showSendEGiftPanel('egift')"
          >
            <v-img
              max-width="20"
              height="14"
              src="/images/e-gift-icon.svg"
              alt="gift icon"
            />

            <span>
              Send E-Gift
            </span>
          </v-col>

          <v-col
            cols="12"
            class="py-2 px-4 btn-menu__link"
            @click="showSendEGiftPanel('link')"
          >
            <v-img
              max-width="14"
              height="14"
              style="margin-right: 6px"
              src="/images/link-icon.svg"
              alt="link icon"
            />

            <span>
              Create gift link
            </span>

            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-4 text-left" style="min-width: 200px; max-width: 250px;">
                {{ getTooltipTextBySlug('campaign_card_link') }}
              </div>
              <v-img
                slot="reference"
                max-width="17"
                height="18"
                src="/images/question-icon.svg"
              />
            </popper>
          </v-col>

          <v-col
            cols="12"
            class="py-2 px-4 btn-menu__link"
            @click="showSendDirectPanel()"
            v-if="showSendToAddressButton"
          >
            <v-img
              max-width="22"
              height="14"
              src="/images/shipping icon@1x.svg"
              alt="shipping icon"
            />
            <span style="margin-left: -1px">
              Send to address
            </span>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'

import isPageInIframe from '@/components/myCampaign/panels/mixins/isPageInIframe'
import { mapGetters } from 'vuex'

export default {
  name: 'CampaignCardButton',
  components: {
    Popper
  },
  mixins: [
    isPageInIframe
  ],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    giftType: {
      type: String,
      required: false,
      default: 'single'
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    active: false
  }),
  computed: {
    ...mapGetters('tooltips', [
      'getTooltipTextBySlug'
    ]),
    showSendToAddressButton () {
      const { items, isPageInIframe } = this

      if (isPageInIframe) return false
      if (items?.length === 1) {
        return !['virtual', 'grouped'].includes(items[0]?.product?.type?.type_id)
      }
      return false
    }
  },
  mounted () {
    document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    clickHandler (event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.active = false
      }
    },
    showSendEGiftPanel (type) {
      this.$emit('showSendEGiftPanel', type)
      this.active = false
    },
    showSendDirectPanel () {
      this.$emit('showSendDirectPanel')
      this.active = false
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.theme--light.v-btn.v-btn--disabled, .v-btn--disabled {
  background: #C0C0C0 !important;
  color: #fff !important;
}

.btn__arrow {
  position: absolute;
  right: 5px;
  content: '';
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 12px;
  height: 12px;
  transform: rotate(45deg) translate(-5px);
  transition: all ease-in-out 0.3s;

  &--up {
    transform: rotate(-135deg) translateY(-5px);
  }
}
.btn-menu {
  position: absolute;
  width: calc(100% - 48px);
  top: calc(100% - 28px);
  border: 1px solid #e4e4e4;
  background-color: #fafafa;
  border-top: none;
  z-index: 4;

  &__link {
    cursor: pointer;

    &:hover {
      background-color: #f4fffe;
    }
  }

  .row > .col {
    display: flex;
    gap: 14px;
    align-items: center;
    font-size: 15px;
    font-family: 'Lato-Regular', sans-serif;
    line-height: 18px;
    color: #16181f;
  }

  .row > .col:not(:last-child) {
    border-bottom: 1px solid #e4e4e4;
  }
}
</style>
