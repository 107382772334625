<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 16"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M7.72566 14.8571H15.2611C15.5812 14.8571 15.8408 14.6013 15.8408 14.2857V1.71429C15.8408
        1.39869 15.5812 1.14286 15.2611 1.14286H7.72566C7.40553 1.14286 7.14601 0.88702 7.14601
        0.571429C7.14601 0.255837 7.40553 0 7.72566 0H15.2611C16.2215 0 17.0001 0.767512 17.0001
        1.71429V14.2857C17.0001 15.2325 16.2215 16 15.2611 16H7.72566C7.40553 16 7.14601 15.7442
        7.14601 15.4286C7.14601 15.113 7.40553 14.8571 7.72566 14.8571Z"
      :fill="color"
    />
    <path
      d="M12.7711 7.59443L8.71351 3.59444C8.48353 3.40028 8.14071 3.41333 7.9266 3.6244C7.7125
        3.83547 7.69925 4.17343 7.89621 4.40015L10.9626 7.42872H1.34616C1.02603 7.42872 0.76651
        7.68456 0.76651 8.00015C0.76651 8.31574 1.02603 8.57158 1.34616 8.57158H10.9684L7.902
        11.5944C7.73658 11.7341 7.66453 11.9534 7.71547 12.1621C7.7664 12.3708 7.93173 12.5338
        8.14347 12.584C8.35522 12.6342 8.57765 12.5632 8.71931 12.4001L12.7769 8.40015C13 8.1757
        12.9974 7.81574 12.7711 7.59443Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'UsersSignIn',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 16
    },
    height: {
      type: [Number, String],
      required: false,
      default: 16
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    },
  },
}
</script>
