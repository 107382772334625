import { Vue, Component } from 'vue-property-decorator'

@Component
export default class UserInfo extends Vue {
  get userFirstName (): string | null {
    return this.$store.state.header?.headerData?.customerName ?? null;
  }

  get userLastName (): string | null {
    return this.$store.state.header?.headerData?.customerLastName ?? null
  }

  get userEmail (): string | null {
    return this.$store.state.header?.headerData?.customerEmail ?? null
  }

  get userId (): number | null {
    return this.$store.state?.header?.headerData?.customerId ?? null
  }

  get userFullName (): string | null {
    const { userFirstName, userLastName } = this

    return [userFirstName, userLastName]?.join(' ') || null;
  }
}
