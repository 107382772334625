<template>
  <v-tooltip
    bottom
    :content-class="
      darkTooltip ?
        'dark-tooltip v-tooltip__content--none' :
        'v-tooltip__content--none'
    "
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        :style="wrapperStyles"
        @click="download()"
        class="pointer d-flex align-center"
      >
        <slot name="icon" v-if="!loading">
          <icons-download-file
            :color="downloadIconColor"
            :width="size"
            :height="size"
          />
        </slot>
        <slot name="loading-icon" v-else >
          <v-progress-circular
            indeterminate
            color="#7F7F7F"
            :width="2"
            :size="22"
          />
        </slot>
      </div>
    </template>
    <span
      class="f12 text-center text-no-wrap"
      :class="{ 'black--text': !darkTooltip, 'white--text': darkTooltip }"
    >
      <slot name="loading-text" v-if="!loading">
        Download CSV
      </slot>
      <slot name="text" v-else>
        Downloading CSV
      </slot>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TheTableDownloadInvoice',
  props: {
    /**
     * Method that will be run after user click icon
     * Method must return promise
    */
    downloadMethod: {
      type: Function,
      required: true
    },
    darkTooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: Number,
      required: false,
      default: 24
    },
    wrapperStyles: {
      type: String,
      required: false,
      default: null,
    },
    downloadIconColor: {
      type: String,
      required: false,
      default: '#42B77A',
    },
  },
  data: () => ({
    loading: false
  }),
  methods: {
    download () {
      if (this.loading) return

      this.loading = true
      this.downloadMethod()
        .then(() => {
          this.loading = false
        })
        .catch(e => {
          console.error(e)
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.dark-tooltip {
  background: #4A4A4A !important;
  border: none !important;
  padding: 6px 10px;
  border-radius: 3px;
  opacity: 1 !important;

  span > {
    font-family: 'Lato-Regular', sans-serif;
    white-space: nowrap;
    font-size: 13px;
    line-height: 16px;
  }
}
</style>
