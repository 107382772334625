<template>
  <div class="d-flex tooltip-flow tooltip-wrapper">
    <div v-if="item.status === Created" class="d-flex">
      <div class="status-icon">
        <img src="/images/tooltip/tooltip-created.png"/>
        <span class="status-label">Created</span>
      </div>
      <div class="status-bridge passed"></div>
    </div>
    <div v-if="item.is_generate_links === 1" class="d-flex">
      <div class="status-icon">
        <img src="/images/tooltip/tooltip-created.png"/>
        <span class="status-label">Created with<br>dedicated link</span>
      </div>
      <div class="status-bridge passed" style="visibility: hidden;"></div>
    </div>
    <v-sheet v-if="item.is_generate_links !== 1" class="d-flex tooltip-flow px-0">
      <div
        class="status-icon">
        <img :src="'/images/tooltip/tooltip-deliver'+((item.status !== Not_Paid && item.status !== Created) ? 'ed' : '')+'.png'"/>
        <span class="status-label">Delivered<br>{{item.delivered_at}}</span>
      </div>
      <div
        class="status-bridge" :class="(item.status !== Not_Paid && (item.status !== Created)) ? 'passed' : ''"></div>
      <div
        class="status-icon">
        <img v-if="item.status !== Partially_Opened" :src="'/images/tooltip/tooltip-open'+((item.status !== Not_Paid && item.status !== Created && item.status !== Delivered) ? 'ed' : '')+'.png'"/>
        <img v-else  :src="'/images/tooltip/tooltip-opened-partially.png'" width="38" height="47"/>
        <span class="status-label">{{item.status !== Partially_Opened ? Opened : (Partially_Opened + ' ' + (item.recipients_count > 1 ? item.recipients_redeem_count + '/' + item.recipients_count : ''))}}</span>
      </div>
      <div class="status-bridge" :class="(item.status !== Not_Paid && item.status !== Created && item.status !== Delivered) ? 'passed' : ''"></div>
    </v-sheet>
    <div class="status-icon">
      <img :src="'/images/tooltip/tooltip-check'+((item.status !== Not_Paid && item.status !== Created && item.status !== Delivered && item.status !== Opened && item.status !== Partially_Opened) ? 'ed' : '')+'.png'"/>
      <span class="status-label">Started checkout</span>
    </div>
    <div class="status-bridge" :class="(item.status !== Not_Paid && item.status !== Created && item.status !== Delivered && item.status !== Opened && item.status !== Partially_Opened) ? 'passed' : ''"></div>
    <div class="status-icon">
      <img v-if="item.status === Started_Checkout" :src="'/images/tooltip/tooltip-redeemed-partially.png'"/>
      <img v-if="item.status !== Started_Checkout && item.status !== Cancelled" :src="'/images/tooltip/tooltip-redeem'+(item.status === Redemption_Complete || item.status.includes('Redeemed') ? 'ed' : '')+'.png'"/>
      <img v-if="item.status === Cancelled" :src="'/images/tooltip/tooltip-cancelled.png'"/>
      <span class="status-label">{{item.status === Cancelled ? Cancelled : ('Redeemed' + (item.status === Started_Checkout ? (' ' + (item.recipients_count > 1 ? item.recipients_redeem_count + '/' + item.recipients_count : '')) : ''))}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tooltip',
  data: () => ({
    Not_Paid: 'Not Paid',
    Created: 'Created',
    Opened: 'Opened',
    Started_Checkout: 'Started checkout',
    Redemption_Complete: 'Redemption complete',
    Partially_Opened: 'Partially opened',
    Cancelled: 'Canceled',
    Delivered: 'Email Delivered'
  }),
  props: {
    item: Object
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.tooltip-wrapper {
 margin: 0 -20px;
}

.tooltip-flow {
  height: 92px;
  min-height: 92px;
  padding: 0 10px;
  background: none!important;
}

.status-icon {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-bottom: 10px;
    z-index: 1;
  }
}

.status-label {
  text-align: center;
  margin: 0 -20px;
  line-height: 15px;
}

.status-bridge {
  margin-top: 27px;
  margin-left: -60px;
  margin-right: -60px;
  width: 120px;
  height: 1px;
  border-top: 2px dashed #d8d8d8;

  &.passed {
    border-color: #43b77b;
  }
}
</style>
