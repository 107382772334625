<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 29"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g id="My-Campaigns" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="My-Campaigns_upload_02"
        transform="translate(-669.000000, -189.000000)"
        :fill="color"
        fill-rule="nonzero"
        stroke="#FFFFFF"
        :stroke-width="stroke"
      >
        <g id="your-own-design" transform="translate(569.000000, 190.000000)">
          <g id="upload-icon" transform="translate(101.000000, 0.000000)">
            <path
              d="M23.2244665,22.1105006 C22.5538676,22.1105006 22.0102398,21.5528242 22.0102398,
                20.8648954 C22.0102398,20.1769667 22.5538676,19.6192902 23.2244665,19.6192902
                L23.2244665,19.6192902 L28.669,19.619 L28.6877582,19.618792 C29.8483905,19.6192768
                30.8896545,18.9069727 31.3407949,17.8229307 L31.4070549,17.6487267 C31.8151792,
                16.4674345 31.4533966,15.1505147 30.5040392,14.3616719 C29.5546817,13.5728291
                28.2237928,13.4832694 27.1821171,14.1381292 C26.6100613,14.4960461 25.8636277,
                14.3129069 25.5113411,13.7282005 L25.5113411,13.7282005 C25.3295734,13.443799
                25.2657852,13.106121 25.3311499,12.7817897 C26.228325,8.26142763 23.4748717,
                3.82405751 19.1028521,2.74448068 C14.7308326,1.66490384 10.3106165,4.3308895
                9.10434542,8.77492689 C9.0854681,8.8200957 9.06381121,8.86398797 9.03950571,
                8.90633824 C8.98544445,9.0581501 8.89783446,9.19505025 8.78354672,9.30630206
                C8.73011816,9.36334442 8.67139561,9.41490725 8.60821238,9.46025887 C8.54453448,
                9.50329089 8.47700104,9.53999482 8.40652932,9.56987212 C8.26330652,9.63836439
                8.10662454,9.67207912 7.94864442,9.66839949 C7.90142224,9.67615776 7.85377079,
                9.68085884 7.80597278,9.68247483 C7.63382217,9.66388463 7.46084261,9.65452985
                7.28774082,9.65444871 C4.60534522,9.65444871 2.43083394,11.8851545 2.43083394
                ,14.6368695 C2.43083394,17.3885845 4.60534522,19.6192902 7.28774082,19.6192902
                L7.28774082,19.6192902 L10.930421,19.6192902 C11.6010199,19.6192902 12.1446477
                ,20.1769667 12.1446477,20.8648954 C12.1446477,21.5528242 11.6010199,22.1105035
                10.930421,22.1105035 L10.930421,22.1105035 L7.28774082,22.1105035 C3.3077043,
                22.1140725 0.0623171438,18.838681 0.000884760461,14.7562758 C-0.0605476229,
                10.6738706 3.08473989,7.29727706 7.06298745,7.17482246 C8.89207059,2.17034773
                13.9493014,-0.77506551 19.0757551,0.178391757 C24.2022088,1.13184902 27.929569,
                5.71108803 27.9295951,11.0557546 L27.9295951,11.0557546 L27.9190313,11.2712443
                C28.173771,11.2322658 28.4309302,11.2122837 28.6884868,11.2114552 L28.6884868,
                11.2114552 C31.6216284,11.210957 34,13.6507914 34,16.6604797 C34,19.3174561
                32.1464168,21.5303171 29.6934162,22.0124861 C29.547828,22.0754812 29.3877561,
                22.1105006 29.2197109,22.1105006 L29.2197109,22.1105006 Z
              "
              id="Combined-Shape"
            />
            <path
              d="M21.1299254,21.0208452 L18.2157813,23.2629345 L18.2157813,10.8068826 C18.2157813,
                10.1189539 17.6721535,9.56127745 17.0015546,9.56127745 C16.3309557,9.56127745
                15.7873279,10.1189539 15.7873279,10.8068826 L15.7873279,23.2629345 L12.8731837,
                21.0208452 C12.5268516,20.7422009 12.0606448,20.6776374 11.6546851,20.8520992
                C11.2487254,21.0265609 10.9665844,21.412728 10.9172689,21.8614024 C10.8679534,
                22.3100768 11.0591861,22.750998 11.417083,23.0138135 L16.2739899,26.7506291
                C16.7052468,27.0831236 17.2988337,27.0831236 17.7300906,26.7506291 L22.5869975,
                23.0138135 C22.9448944,22.750998 23.1361271,22.3100768 23.0868116,21.8614024
                C23.0374961,21.412728 22.7553552,21.0265609 22.3493954,20.8520992 C21.9434357,
                20.6776374 21.477229,20.7422009 21.1308968,21.0208452 L21.1299254,21.0208452 Z
              "
              id="Path"
              transform="translate(17.002040, 18.280639) scale(1, -1) translate(-17.002040, -18.280639)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Upload',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 36
    },
    height: {
      type: [Number, String],
      required: false,
      default: 29
    },
    color: {
      type: String,
      required: false,
      default: '#000'
    },
    stroke: {
      type: Number,
      required: false,
      default: 1.176
    },
  }
}
</script>
