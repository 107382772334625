const VALID_HOSTS = [
  'corporategift.com',
  'dev.corporategift.com',
  'staging.corporategift.com',
  'account.corporategift.com',
  'account.dev.corporategift.com',
  'account.staging.corporategift.com',
]

export default {
  computed: {
    routerQuery () { return this.$route.query },
    redirect () {
      const { redirect } = this.routerQuery

      if (!redirect) { return null }

      try {
        const parsedURL = new URL(redirect);
        if (parsedURL.protocol !== 'https:') { return null }
        if (!VALID_HOSTS.includes(parsedURL.host)) { return null }

        return parsedURL.href;
      } catch (err) {
        // probably this is a page to account like /dashboard
        return redirect;
      }
    },
  },
  methods: {
    onGoogleSSO () {
      const { redirect } = this

      if (redirect) { sessionStorage.setItem('accountRedirect', redirect) }
    },
    redirectLoggedInUser () {
      const { redirect } = this

      this.$store.dispatch('header/loadHeader')
      sessionStorage.removeItem('authenticationEmail')

      if (!redirect) {
        this.$router.push({ name: 'Dashboard' })
      } else {
        if (redirect.startsWith('https://')) {
          window.location.href = redirect
        } else {
          this.$router.push(redirect).catch()
        }
      }
    },
    onLoginWithGoogleRedirect () {
      const redirect = sessionStorage.getItem('accountRedirect')
      const userIsLoggedIn = this.$store.state.headerAuth?.userIsLoggedIn ?? false

      if (redirect && userIsLoggedIn) {
        sessionStorage.removeItem('accountRedirect')

        if (redirect.startsWith('https://')) {
          window.location.href = redirect
        } else {
          this.$router.push(redirect).catch()
        }
      }
    },
  },
}
