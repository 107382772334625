export default {
  showLoadMoreButton: state => !!state.links.next,
  loadMoreNumber: state => {
    const { currentPage, lastPage, perPage, total, to } = state.meta

    return currentPage !== lastPage - 1 ? perPage : (total - to)
  },
  isDateFilterChanged: state => {
    const { filters: { dateRange }, defaultDateHelper } = state

    return defaultDateHelper.start !== dateRange.start || defaultDateHelper.end !== dateRange.end 
  },
  isPageFiltered: (state, getters) => {
    const { isDateFilterChanged } = getters
    const { dateRange, types, ...rest } = state.filters

    return !![...Object.values(rest), isDateFilterChanged, types.length]
      ?.filter((value) => value)?.length ?? false
  },
  params: state => {
    const { filters, currentPage } = state

    return ({
      date_from: filters.dateRange.start,
      date_to: filters.dateRange.end,
      types: filters?.types?.join(',') || undefined,
      user_id: filters?.userId,
      q: filters?.orderId,
      search: filters?.search ?? undefined,
      campaign_id: filters.campaignId ?? undefined,
      product_id: filters.productId ?? undefined,
      page: currentPage ?? 1,
    })
  }
}
