<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1862 11.0383C12.0285 11.0383 11.9009 11.1686 11.9009 11.3289C11.9009 11.4895 12.0285
        11.619 12.1862 11.619C13.1615 11.619 13.8973 12.4465 13.8973 13.4398C13.8973 13.473 13.8911
        13.5049 13.8779 13.5344C13.8143 13.6811 13.8787 13.8527 14.0229 13.9179C14.0604 13.9351
        14.0997 13.9427 14.1385 13.9427C14.2479 13.9427 14.3519 13.8788 14.3994 13.7703C14.4448
        13.666 14.468 13.555 14.468 13.4398C14.468 12.1265 13.4762 11.0383 12.1862 11.0383M9.68526
        6.86692C9.88965 6.74371 10.1073 6.68147 10.3323 6.68147C11.1969 6.68147 11.9009 7.60994
        11.9009 8.75076C11.9009 8.95554 11.8786 9.15654 11.8344 9.34829C11.7985 9.50472 11.8939
        9.66072 12.0471 9.69731C12.069 9.70277 12.0909 9.7053 12.1123 9.7053C12.242 9.7053 12.3588
        9.61489 12.3898 9.48117C12.4439 9.24569 12.4716 9.00012 12.4716 8.75076C12.4716 7.28952
        11.512 6.10034 10.3323 6.10034C10.0082 6.10034 9.68402 6.19285 9.39499 6.36694C9.25955
        6.44852 9.21455 6.62681 9.29465 6.76473C9.37517 6.90308 9.55024 6.94849 9.68526
        6.86692M11.2733 12.2006C11.1156 12.2006 10.988 12.3305 10.988 12.4907C10.988 12.6513
        11.1156 12.7813 11.2733 12.7813C12.2482 12.7813 13.0418 13.589 13.0418 14.5823C13.0418
        14.7101 12.9394 14.8144 12.8134 14.8144H6.13921C6.01369 14.8144 5.91129 14.7101 5.91129
        14.5823C5.91129 13.589 6.7049 12.7813 7.67936 12.7813C7.83709 12.7813 7.96468 12.6513
        7.96468 12.4907C7.96468 12.3305 7.83709 12.2006 7.67936 12.2006C6.38985 12.2006 5.34106
        13.2686 5.34106 14.5823C5.34106 15.0305 5.69947 15.3955 6.13921 15.3955H12.8134C13.2536
        15.3955 13.6124 15.0305 13.6124 14.5823C13.6124 13.2686 12.5632 12.2006 11.2733
        12.2006M9.47674 7.77057C10.3414 7.77057 11.0454 8.69904 11.0454 9.84028C11.0454 10.9811
        10.3414 11.91 9.47674 11.91C8.6117 11.91 7.90811 10.9811 7.90728 9.84028C7.90728 8.69904
        8.61129 7.77057 9.47674 7.77057M9.47674 12.4907C10.6564 12.4907 11.6156 11.3019 11.6156
        9.84028C11.6156 8.37862 10.6564 7.18986 9.47674 7.18986C8.89331 7.18986 8.34827 7.4737
        7.94279 7.98965C7.55218 8.48711 7.33747 9.14393 7.33747 9.84028C7.33747 11.3019 8.29707
        12.4907 9.47674 12.4907"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.414 2.41735C16.6254 2.41735 16.7968 2.59186 16.7968 2.80716V18.3855C16.7968 18.6003
        16.6254 18.7748 16.414 18.7748H2.83762C2.62662 18.7748 2.45527 18.6003 2.45527
        18.3855V15.854H3.18983C3.50694 15.854 3.76336 15.5921 3.76336 15.27C3.76336 14.9474 3.50694
        14.6855 3.18983 14.6855H2.45527V11.1802H3.18983C3.50694 11.1802 3.76336 10.9186 3.76336
        10.5961C3.76336 10.274 3.50694 10.012 3.18983 10.012H2.45527V6.50672H3.18983C3.50694
        6.50672 3.76336 6.24475 3.76336 5.92265C3.76336 5.60012 3.50694 5.33815 3.18983
        5.33815H2.45527V2.80716C2.45527 2.59186 2.62662 2.41735 2.83762 2.41735H16.414ZM1.30821
        2.80716V5.33815H0.57365C0.256537 5.33815 0.00012207 5.60012 0.00012207 5.92265C0.00012207
        6.24475 0.256537 6.50672 0.57365 6.50672H1.30821V10.012H0.57365C0.256537 10.012 0.00012207
        10.274 0.00012207 10.5961C0.00012207 10.9186 0.256537 11.1802 0.57365
        11.1802H1.30821V14.6855H0.57365C0.256537 14.6855 0.00012207 14.9474 0.00012207
        15.27C0.00012207 15.5921 0.256537 15.854 0.57365 15.854H1.30821V18.3855C1.30821 19.2458
        1.99281 19.9434 2.83762 19.9434H16.414C17.2588 19.9434 17.9438 19.2458 17.9438
        18.3855V2.80716C17.9438 1.94639 17.2588 1.24878 16.414 1.24878H2.83762C1.99281 1.24878
        1.30821 1.94639 1.30821 2.80716V2.80716Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AddressBook extends Vue {
  @Prop({ default: 19 }) readonly width!: number | string;

  @Prop({ default: 21 }) readonly height!: number | string;
}
</script>
