var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0"},[_c('common-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticStyle:{"padding":"200px 0"}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-col',{staticClass:"f15 lato-bold black1 pb-6",attrs:{"cols":"12"}},[_vm._v(" Enter your credit card information: ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('label',{attrs:{"for":"card-number"}},[_vm._v("Credit card number")]),_c('div',{staticClass:"iframe-handler",attrs:{"id":"card-number","data-bluesnap":"ccn"}})]),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"exp-date"}},[_vm._v("Expiry date")]),_c('div',{staticClass:"iframe-handler",attrs:{"id":"exp-date","data-bluesnap":"exp"}})]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('label',{attrs:{"for":"cvv"}},[_vm._v("Card verification code")]),_c('popper',{attrs:{"options":{
            placement: 'top',
            modifiers: {
              offset: {
                offset: '0,8px'
              }
            }
          }}},[_c('div',{staticClass:"popper pa-4 pl-5 text-left",staticStyle:{"max-width":"160px"}},[_vm._v(" 3 digits code on the back of your card ")]),_c('v-img',{staticClass:"pointer",attrs:{"slot":"reference","max-width":"18","height":"19","src":"/images/question-icon.svg"},slot:"reference"})],1)],1),_c('div',{staticClass:"iframe-handler",attrs:{"id":"cvv","data-bluesnap":"cvv"}})]),(_vm.errors.length)?_c('v-col',{staticClass:"f15 error--text",attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('common-button',{attrs:{"height":"42","width":"200"},on:{"click":function($event){return _vm.$emit('addCC')}}},[_vm._v(" Buy Credits ")])],1),_c('v-col',{staticClass:"f15 lato-italic gray2",attrs:{"cols":"10","xl":"11"}},[_vm._v(" By clicking “Buy Credits” you agree to the CorporateGift.com "),_c('a',{staticClass:"f15 lato-italic green3",attrs:{"href":"https://corporategift.com/terms-and-conditions/","target":"_blank"}},[_vm._v(" Terms of Service and Payments Policy. ")]),_vm._v(" Prepaid credits are non-refundable and non-transferrable. ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }