import { render, staticRenderFns } from "./TheSelectMultipleContacts.vue?vue&type=template&id=a0c4ade6&scoped=true"
import script from "./TheSelectMultipleContacts.vue?vue&type=script&lang=js"
export * from "./TheSelectMultipleContacts.vue?vue&type=script&lang=js"
import style0 from "./TheSelectMultipleContacts.vue?vue&type=style&index=0&id=a0c4ade6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0c4ade6",
  null
  
)

export default component.exports