<template>
  <v-container class="e-gift-orders mt-3" style="position: relative">
    <v-row dense justify="space-between" class="py-3">
      <v-col cols="6" class="page-title text-capitalize">
        My E-Gift orders
      </v-col>
      <v-col cols="6" class="d-flex justify-end align-center">
        <v-btn
          class="rounded-0 uppercase f15 lato-bold font-weight-bold ml-3"
          color="#000000"
          @click="viewMergedReport"
          v-if="checked"
          dark
        >
          view merged report
        </v-btn>
      </v-col>
      <v-col cols="12" class="text--secondary f13">
        <span v-if="selectedAll">
          {{ meta.total }} Orders selected
        </span>
        <span v-else-if="checked && !selectedAll">
          {{ this.$store.state.selectedOrders.length }} Orders selected
        </span>
      </v-col>
    </v-row>
    <div class="py-3 d-flex flex-start pb-0 align-center">
      <div class="filter-group d-flex">
        <v-sheet class="d-flex align-center">
          <common-search-form
            :searchIdx="search"
            :iconMaxWidth="13"
            :iconHeight="13"
            placeholder="Recipient / Description / Order #"
            :maxWidth="272"
            style="width: 272px"
            :height="30"
            @setSearchValue="setRecipientSearchValue"
          />
        </v-sheet>
        <span
          class="d-flex ml-0 align-center filter-by mr-3"
        >Filter By</span>
        <common-my-date-range-picker
          :dateRange="range"
          @setDateRange="setDateSearchRange"
          class="mr-3"
        />
        <v-select
          :items="statusIds"
          v-model="filteredStatus"
          item-text="value"
          label="Status"
          dense
          outlined
          solo
          flat
          hide-details
          tile
          height="30"
          class="status-filter-form rounded-0 mr-3"
          style="width: 170px;"
          :class="filteredStatus !== undefined ? 'selected-bg' : ''"
        />
        <v-select
          :items="givers && givers.map(item => item.name)"
          v-model="filteredGiver"
          item-text="value"
          label="Giver"
          dense
          outlined
          solo
          flat
          hide-details
          tile
          height="30"
          class="status-filter-form rounded-0"
          style="width: 170px;"
          :class="filteredGiver !== undefined ? 'selected-bg' : ''"
        />
      </div>
      <common-clear-filter-button @click="clearFilter" :visible="isShowClearFilter" />
      <span class="e-gift-orders__orders-info">
        Showing {{ totalOrdersNumber }} orders
      </span>
    </div>
    <div class="py-3">
      <data-table
        @childToParent="onChildClick"
        @setSortParams="sortTable"
        @loadEgiftOrderPage="loadEgiftOrderPage"
        @selectAll="selectAll"
        ref="data-table"
      />
      <load-more
        class="mt-5"
        :is-visible="links.next !== null && !loading"
        @more="loadMore"
        :loading="isLoadmore"
        :size="showableSize"
      />
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('e-gift-orders')

export default {
  name: 'e-gift-orders',
  data: () => ({
    checked: false,
    opens: 'center',
    minDate: '01/01/1900',
    maxDate: '01/01/2900',
    singleDatePicker: false,
    timePicker: false,
    timePicker24Hour: false,
    showWeekNumbers: false,
    showDropdowns: false,
    autoApply: true,
    linkedCalendars: true,
    recipientSearchValue: '',
    items: [5, 10, 20, 30],
    page_num: 1,
    filteredGiver: undefined,
    selectedAll: false,
    range: undefined,
  }),
  methods: {
    ...mapActions([
      'loadMore'
    ]),
    onChildClick (value) {
      if (value.length > 0) {
        this.checked = true
        this.$store.state.selectedOrders = value
      } else {
        this.checked = false
        this.$store.state.selectedOrders = []
      }
    },
    setRecipientSearchValue: _.debounce(function (searchValue) {
      this.$store.commit('e-gift-orders/setSearch', searchValue)
      this.$store.dispatch('e-gift-orders/search', this.search)
    }, 700),
    setDateSearchRange (range) {
      this.$store.dispatch('e-gift-orders/filterByDateRange', range)
      this.range = this.dateRange
    },
    sortTable (params) {
      this.$store.dispatch('e-gift-orders/sortTable', params)
    },
    // when click the row of orders table
    loadEgiftOrderPage (id) {
      this.$store.commit('e-gift-order/setEgiftId', id)
      this.$router.push({ name: 'EgiftOrder', params: { id: id } })
    },
    viewMergedReport () {
      this.$store.commit('e-gift-order/setEgiftId', this.selectedAll === true ? 'all' : this.selectedOrdersIds)
      this.$store.commit('e-gift-order/setEgiftFilters', this.selectedAll === true ? this.filters : {})
      this.$router.push({ name: 'EgiftOrder', params: { id: 'merged-report' } })
    },
    clearFilter () {
      this.filteredStatus = undefined
      this.filteredGiver = undefined
      this.$store.dispatch('e-gift-orders/resetFilter')
      this.$store.dispatch('e-gift-orders/load')
      this.range = null
    },
    selectAll () {
      this.selectedAll = !this.selectedAll
    },
    closedHandler () {
      this.$store.dispatch('e-gift-orders/load')
      this.$store.state.selectedOrders = []
      this.checked = false
      this.$refs['data-table'].selected = []
    }
  },
  created () {
    this.filteredStatus = this.orderStatus !== undefined ? this.statusIds.filter(item => item === this.orderStatus)[0] : undefined
    if (this.givers !== undefined) {
      this.filteredGiver = this.giftGiver !== undefined ? this.givers.filter(item => item.id === this.giftGiver)[0].name : undefined
    }
    this.range = this.dateRange
  },
  computed: {
    ...mapState([
      'links', 'meta', 'loading', 'isLoadmore', 'givers', 'dateRange', 'showableSize', 'showedOrdersNum', 'statusIds'
    ]),
    ...mapGetters([
      'search', 'orderStatus', 'giftGiver', 'filters'
    ]),
    isShowClearFilter () {
      return this.search.length > 0 || (this.dateRange !== null && this.dateRange.start !== null && this.dateRange.start !== undefined && this.dateRange.start.length > 0) || (this.filteredStatus !== undefined && this.filteredStatus !== 'undefined' && this.filteredStatus !== 'All Statuses') || (this.filteredGiver !== undefined && this.filteredGiver !== 'undefined' && this.filteredGiver !== 'All Givers')
    },
    selectedOrdersIds () {
      return this.$store.state.selectedOrders.map((selected) => selected.id)
    },
    isFiltered () {
      const { filters } = this
      return !Object.values(filters).every((item) => item === undefined)
    },
    filteredStatus: {
      get () {
        return this.orderStatus
      },
      set (val) {
        const result = val === 'All Statuses'
          ? ''
          : val

        if (val) {
          this.$store.dispatch('e-gift-orders/filterByStatus', result)
        }
      }
    },
    totalOrdersNumber () {
      return this.meta?.total || 0
    }
  },
  watch: {
    filteredGiver (value) {
      value && this.$store.dispatch('e-gift-orders/filterByGiver', this.givers.filter(item => item.name === value))
    },
  }
}
</script>

<style lang="scss">
.e-gift-orders {

  .filter-group {

    @media only screen and (max-width: 1092px) {
      flex-direction: column;
      height: 100px;
      justify-content: space-between;

      .filter-by {
        display: none !important;
      }
    }
  }

  .v-input {
    flex: inherit;
  }

  .filter-by {
    color: #bbbbbb;
    font-size: 13px;
  }

  .view-merged-report-btn {
    text-transform: uppercase;
    background: black;
    margin-top: -5px;

    &:hover {
      span {

        a {
          text-decoration: none;
          color: #d8d8d8;
        }
      }
    }

    span {

      a {
        text-decoration: none;
        color: white;
        font-family: 'Lato-Bold';
      }
    }
  }

  &__orders-info {
    color: #9B9B9B;
    font-family: 'Lato-Bold', sans-serif;
    margin-left: auto;
    white-space: nowrap;
    font-size: 13px;
  }
}

.page_size {

  .v-input {
    margin: 0;
    padding: 0;
  }

  .v-input__slot {
    margin: 0;
    border-radius: 0;
    padding: 0 !important;
    background: none !important;
  }

  .v-text-field__details {
    display: none;
  }

  .v-select__slot {
    padding: 0 5px;
  }

  .v-select__slot .v-input__icon i {
    margin-left: 10px;

    &::before {
      color: #919191;
      font-size: 20px;
    }
  }
}

.status-filter-form {
  .v-label {
    font-size: 13px;
  }

  .v-select__slot {
    font-size: 13px;

    label {
      font-size: 13px;
      letter-spacing: 0;
      color: black;
    }

    .v-input__append-inner {
      padding-left: 130px !important;
      margin-left: -130px !important;
    }
  }
}

.selected-bg.v-text-field--solo > .v-input__control > .v-input__slot {
  background-color: #DFF3F2;
}
</style>
