export default {
  canLoadMorePages: state => state.currentPage !== state.totalPages,
  findProductById: state => (id) => {
    const { inventoryList } = state
    const products = inventoryList.filter(({ product: { product_id: productId } }) => productId === id)

    return products?.at(0)?.product ?? {}
  },
  params: state => ({
    campaign_id: state.filters.campaignId ?? undefined,
    product_id: state.filters.productId ?? undefined,
    search: state.filters.search ?? undefined,
    filter_by: state.filters.filter_by ?? undefined,
    page: state.currentPage ?? 1,
  })
}
