import { createNamespacedHelpers } from 'vuex'

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('inventory')

export default {
  computed: {
    ...mapState([
      'loadingPage',
      'loading',
    ]),
    ...mapGetters(['canLoadMorePages']),
    isOnInventoryTab () {
      const queryString = this.$route.query ?? {}

      return queryString?.tab === 'inventory' || !queryString?.tab
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions(['loadNextPage']),
    handleScroll () {
      const { scrollHeight, clientHeight } = document.documentElement ?? {}

      const scrollMaxY = (scrollHeight - clientHeight) || 0
      const scrollY = window.scrollY

      const windowScrollPercent = Math.floor((scrollY / scrollMaxY) * 100)

      if (!this.isOnInventoryTab) return

      if (this.loadingPage || this.loading) return

      if (windowScrollPercent >= 90 && this.canLoadMorePages) { this.loadNextPage() }
    },
  },
}
