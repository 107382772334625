<template>
  <v-tabs-items v-model="value">
    <v-tab-item v-show="!hideBudgetEntitiesTab" :key="0">
      <the-budget-table-wrapper
        v-bind="$attrs"
        v-on="$listeners"
      />
    </v-tab-item>
    <v-tab-item :key="1">
      <the-on-hold-table-wrapper
        v-bind="$attrs"
        v-on="$listeners"
        :marketplaceUser="marketplaceUser"
      />
    </v-tab-item>
    <v-tab-item :key="2">
      <the-transactions-table-wrapper
        v-bind="$attrs"
        v-on="$listeners"
        :marketplaceUser="marketplaceUser"
      />
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import TheBudgetTableWrapper from './TheBudgetTableWrapper'
import TheTransactionsTableWrapper from './TheTransactionsTableWrapper'
import TheOnHoldTableWrapper from './TheOnHoldTableWrapper'

import { tabsVModel, marketplaceUser, hideBudgetEntitiesTab } from './mixins'

export default {
  name: 'TheCreditsTabsItems',
  components: {
    TheOnHoldTableWrapper,
    TheBudgetTableWrapper,
    TheTransactionsTableWrapper
  },
  mixins: [
    tabsVModel,
    marketplaceUser,
    hideBudgetEntitiesTab,
  ]
}
</script>

<style lang="scss" scoped>
.v-window {
  min-height: 400px;
  overflow: unset;

  .v-window-item:not(.v-window-item--active) {
    display: none;
  }

  &::v-deep {
    & > .v-window__container--is-active {
      overflow: unset;
    }

    .v-data-table__wrapper {
      overflow: unset;
    }
  }
}
</style>
