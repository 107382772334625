<template>
  <v-col :cols="breakdownCols" class="price-breakdown">
    <common-loader v-if="loading" />
    <v-row no-gutters v-else>
      <template v-if="+price && !hasVariants">
        <v-col cols="9">Unit Cost</v-col>
        <v-col cols="3" class="text-right">
          {{ price | priceFilter({
            style: 'currency',
            currency: 'USD'
          }) }}
        </v-col>
      </template>

      <template v-for="(variant, index) in variantsPrices" v-else>
        <v-col cols="9" :key="`label-${index}`">Unit Cost - {{ getVariantName(variant) }}</v-col>
        <v-col cols="3" :key="`price-${index}`" class="text-right">
          {{ variant.price | priceFilter({
            style: 'currency',
            currency: 'USD'
          }) }}
        </v-col>
      </template>

      <template v-if="+pickAndPackPrice">
        <v-col cols="9">Inventory fee</v-col>
        <v-col cols="3">
          {{ pickAndPackPrice | priceFilter({
            style: 'currency',
            currency: 'USD'
          }) }}
        </v-col>
      </template>

      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="9" class="price-breakdown__text f14">Total per unit</v-col>
      <v-col cols="3" class="price-breakdown__text">
        {{ hasVariants ? totalPerUnit : totalPricePerUnit | priceFilter({
          style: 'currency',
          currency: 'USD'
        }) }}
      </v-col>

      <template v-if="+shippingPrice">
        <v-col cols="9">Shipping to Swag Closet warehouse</v-col>
        <v-col cols="3">
          {{ +shippingPrice * totalQty | priceFilter({
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 3
          }) }}
        </v-col>
      </template>

      <template v-if="+storagePrice">
        <v-col cols="9">Storage</v-col>
        <v-col cols="3">
          {{ storagePrice | priceFilter({
            style: 'currency',
            currency: 'USD'
          }) }}
        </v-col>
      </template>

      <template v-if="+shippingToRecipPrice">
        <v-col cols="9">Shipping to recipient (flat rate)</v-col>
        <v-col cols="3">
          {{ shippingToRecipPrice | priceFilter({
            style: 'currency',
            currency: 'USD'
          }) }}
        </v-col>
      </template>

      <template v-if="+tax">
        <v-col cols="9">Tax</v-col>
        <v-col cols="3">
          {{ tax | priceFilter({
            style: 'currency',
            currency: 'USD'
          }) }}
        </v-col>
      </template>

      <template v-if="+customizationFee">
        <v-col cols="9">Customization fee</v-col>
        <v-col cols="3">
          {{ customizationFee | priceFilter({
            style: 'currency',
            currency: 'USD'
          }) }}
        </v-col>
      </template>

      <v-col cols="12">
        <v-divider style="border-top-width: 7px" />
      </v-col>
      <v-col cols="9" class="price-breakdown__text f14">Total - {{ totalQty }} units</v-col>
      <v-col cols="3" class="price-breakdown__text">
        {{ totalPrice | priceFilter({
          style: 'currency',
          currency: 'USD'
        }) }}
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'PriceBreakdown',
  props: {
    price: {
      type: [Number, String],
      required: true
    },
    tax: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    shippingPrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    shippingPriceFull: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    storagePrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    pickAndPackPrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    shippingToRecipPrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    customizationFee: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    breakdownCols: {
      type: [String, Number],
      required: false,
      default: 12
    },
    hasVariants: {
      type: Boolean,
      required: false,
      default: false
    },
    variantsPrices: {
      type: Array,
      required: false,
      default: () => ([])
    },
    totalPerUnit: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    totalQty: {
      type: Number,
      required: false,
      default: 0
    },
    totalPrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    totalPricePerUnit () {
      const { price, pickAndPackPrice, customizationFee } = this

      return Number(price) +
        Number(pickAndPackPrice) +
        Number(customizationFee)
    }
  },
  methods: {
    getVariantName (variant) {
      const variants = variant?.variants
      return variants?.map((variant) => variant?.value)?.join(', ') || null
    }
  }
}
</script>

<style lang="scss" scoped>
.price-breakdown {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  line-height: 33px;
  color: #000;

  & .col-3, .col-2 {
    text-align: right;
  }

  &__text {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 16px;
    line-height: 40px;
    color: #000;
  }
}
</style>
