<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="700px"
  >
    <close-panel-button
      @close="tryClosePanel()"
      :text="step === 1 ? 'Close' : 'Back to import addressed'"
    />
    <v-container class="px-10 py-0" v-if="show">
      <v-row dense>
        <v-col cols="10" class="f30 lato-light font-weight-light py-6">
          {{ step === 1 ? 'Import addresses' : 'Match fields' }}
        </v-col>

        <common-loader v-if="loading" style="padding: 200px 0" />

        <template v-if="!loading && step === 1">
          <v-col cols="10">
            <div class="label-wrapper">
              <label for="import-input">
                Import file with contacts
              </label>

              <popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0, 5px' } },
                }"
              >
                <div class="popper pa-4 text-left" style="min-width: 100px; max-width: 500px;">
                  {{ getTooltipTextBySlug('import_contact_file') }}
                </div>
                <div slot="reference" class="pointer">
                  <icons-question width="16" height="16" />
                </div>
              </popper>
            </div>

            <v-file-input
              v-model="selectedFile"
              prepend-icon=""
              clearable
              accept=".csv, .tsv"
              placeholder="Upload CSV File"
              id="import-input"
              outlined
              class="rounded-0 pb-2 thin-border import-contacts-input"
              color="black"
              height="40"
              dense
              truncate-length="50"
              hide-details
            >
              <template #append v-if="!selectedFile">
                <icons-upload-file class="d-flex align-self-center" />
              </template>
            </v-file-input>

            <span class="info-text">
              Max 50MB. Acceptable file types:
              <a href="https://corporategift.com/addressupload/download/sampleCsv/" target="_blank">
                CSV
              </a>
              or Tab-delimited text files.
              <br />
              <a href="https://corporategift.com/addressupload/download/sampleCsv/" target="_blank">
                Download Sample
              </a>
            </span>
          </v-col>

          <v-col cols="10" class="pt-5 pb-6" v-if="selectedFile">
            <v-divider />
          </v-col>

          <v-col cols="10" class="pb-8" v-if="selectedFile">
            <label>
              Add imported users to a group:
            </label>
          </v-col>

          <v-col cols="10" v-if="selectedFile">
            <select-group-list
              v-bind="_props"
              :selectedGroups.sync="selectedGroups"
              :newGroupName.sync="newGroupName"
            />
          </v-col>
        </template>

        <template v-if="!loading && step === 2">
          <v-col cols="10" class="matched-fields-info">
            We were able to match {{ numberOfMatchedColumns }} of {{ userHeaders.length }} data fields. <br />
            Please double check all matched and unmatched fields below, and update as necessary.
          </v-col>
          <!-- columns -->
          <v-col cols="10">
            <v-row no-gutters>
              <v-col cols="6" class="f18 black4">
                <v-row no-gutters>
                  <v-col cols="12" class="table-header pb-3">
                    Your Document columns
                  </v-col>
                  <v-col
                    cols="12"
                    class="table-cel"
                    v-for="(header, index) in userHeaders"
                    :key="`user-cel-${index}`"
                  >
                    <span>
                      {{ header }}
                    </span>
                    <span v-for="(item, index) in getUserListByRowId(index)" :key="index">
                      {{ item }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12" class="table-header pb-3">
                    Update Column Name
                  </v-col>
                  <v-col
                    cols="12"
                    class="table-cel table-cel--right"
                    v-for="(header, index) in userHeaders"
                    :key="`input-${index}`"
                  >
                    <common-select
                      v-model="matchedColumns[index]"
                      :items="selectItems"
                      placeholder="Please select"
                      :rules="[v => !!v || '']"
                      :class="!matchedColumns[index] && 'error--text v-input--has-state'"
                      height="30"
                    />
                    <div class="table-cel--missing-info" v-if="!matchedColumns[index]">
                      Missing information
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="10" class="py-6">
            <v-alert color="#FAE3E3" tile>
              <template #prepend>
                <icons-warning width="20" height="20" />
              </template>

              <b>Unmatched fields will NOT be imported.</b>
              Some required information may be missing, too.
              Please carefully review imported address data after continuing,
              and correct any errors indicated.
            </v-alert>
          </v-col>
        </template>
        <!-- buttons -->
        <template v-if="!loading && selectedFile">
          <v-col cols="10" class="pb-8">
            <common-button
              outlined
              class="mr-4"
              height="40"
              width="130"
              @click="show = false"
            >
              Cancel
            </common-button>

            <common-button
              height="40"
              width="130"
              @click="uploadFile()"
              :disabled="disableUploadButton"
              v-if="step === 1"
            >
              Upload
            </common-button>

            <common-button
              height="40"
              width="130"
              @click="sendDataToApi()"
              v-if="step === 2"
            >
              Continue
            </common-button>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import SelectGroupList from './SelectGroupList'
import Popper from 'vue-popperjs'

import panelVModel from '@/mixins/panelVModel'
import { importHeaders, importValues } from './types/importHeaders'
import { groups, addToGroup } from './mixins'
import { mapGetters } from 'vuex'

export default {
  name: 'TheImportContactsPanel',
  components: {
    SelectGroupList,
    Popper
  },
  mixins: [
    groups,
    addToGroup,
    panelVModel
  ],
  data: () => ({
    selectedFile: null,
    selectedGroups: [],
    newGroupName: null,
    step: 1,
    uploadedAddresses: [],
    loading: false,
    csvHeaders: Object.values(importValues),
    selectItems: importHeaders,
    matchedColumns: [],
    numberOfMatchedColumns: 0,
    userHeaders: [],
    errors: []
  }),
  computed: {
    ...mapGetters('tooltips', [
      'getTooltipTextBySlug'
    ]),
    disableUploadButton () {
      if (!this.newGroupName) return false

      return this.newGroupName?.length < 2
    }
  },
  watch: {
    show: function (val) {
      if (!val) Object.assign(this.$data, this.$options.data())

      if (val && this.addToGroup) {
        const id = this.addToGroup.entity_id
        this.selectedGroups.push(id)
      }
    }
  },
  methods: {
    uploadFile () {
      this.loading = true
      this.readFile(this.selectedFile)
    },
    matchUserColumns () {
      const { userHeaders, csvHeaders } = this
      // eslint-disable-next-line
      userHeaders?.forEach((item, index) => {
        csvHeaders.forEach(header => {
          if (this.matchedColumns[index]) return

          const nameToCheck = item?.replaceAll(' ', '')?.toLowerCase()

          if (nameToCheck === 'streetaddress2') {
            this.$set(this.matchedColumns, index, importValues[8]) // apt
            return
          }

          if (nameToCheck?.includes(header.replaceAll('_', ''))) {
            this.$set(this.matchedColumns, index, header)
          } else if (nameToCheck?.includes('zip') || nameToCheck?.includes('postal')) {
            this.$set(this.matchedColumns, index, importValues[12]) // postcode

          } else if (nameToCheck?.includes('state')) {
            this.$set(this.matchedColumns, index, importValues[10]) // region

          } else if (nameToCheck?.includes('phone')) {
            this.$set(this.matchedColumns, index, importValues[5]) // telephone

          } else if (nameToCheck?.includes('birth')) {
            this.$set(this.matchedColumns, index, importValues[16]) // dob

          } else {
            this.$set(this.matchedColumns, index, null)
          }
        })
      })
      this.numberOfMatchedColumns = this.matchedColumns.filter(item => item).length
    },
    readFile (file) {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        this.uploadedAddresses = this.csvToArray(result)
        this.$cgToast.success('Your file has been uploaded!')
        this.step = 2
        this.loading = false
      }
      reader.readAsText(file)
    },
    csvToArray (csvFile, delimiter = /,|\t/) {
      // remove first row with headers
      this.userHeaders = csvFile.slice(0, csvFile.indexOf('\n')).split(delimiter)
      this.matchUserColumns()
      const { csvHeaders: headers } = this
      let rows = csvFile.slice(csvFile.indexOf('\n') + 1).split('\n')
      // Remove empty rows
      rows = rows.filter((item) => !!item.replaceAll(',', ''))
      const arrayOfAddresses = rows.map((row) => {
        // csv row data can contain string with comma
        const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)|\t/)
        const address = headers.reduce((object, header, index) => {
          object[header] = values[index]?.replaceAll('"', '')
          return object
        }, {})
        return address
      })
      return arrayOfAddresses
    },
    getCsvHeaderById (id) {
      return this.csvHeaders[id] || null
    },
    getUserListByRowId (rowId) {
      const objectHeader = this.getCsvHeaderById(rowId)
      const { uploadedAddresses } = this
      const { length } = uploadedAddresses
      if (length > 3) {
        const text1 = uploadedAddresses[0][objectHeader]
        const text2 = uploadedAddresses[1][objectHeader]
        const text3 = '...'
        return [text1, text2, text3]
      }
      return this.uploadedAddresses.map(item => item[objectHeader])
    },
    tryClosePanel () {
      if (this.step === 1) this.show = false

      this.step--
    },
    sendDataToApi () {
      this.loading = true
      this.errors = []

      const { matchedColumns, uploadedAddresses, csvHeaders } = this
      const addressesWithNewStructure = uploadedAddresses.reduce((acc, address) => {
        const valuesFromAddress = Object.values(address)
        const newObject = {}
        // insert csvHeaders keys to object
        csvHeaders.forEach((key) => {
          if (key !== importValues[0]) newObject[key] = null
        })

        matchedColumns.forEach((column, index) => {
          if (column && column !== importValues[0]) {
            newObject[column] = valuesFromAddress[index]
          }
        })

        if (!newObject?.country) newObject.country = 'US'

        acc.push(newObject)
        return acc
      }, [])

      this.addNewAddresses(addressesWithNewStructure, this.selectedGroups)
        .then(({ success, message }) => {
          if (success) {
            this.sendFileToApi()
              .catch((e) => {
                console.error(e)
              })
              .finally(() => {
                this.loading = false
                this.show = false
                this.$emit('fetchData')
                
                this.$cgToast.successBold(message || 'Contacts added successfully')
              })
          } else {
            this.errors = [
              'An unexpected error occurred, please try again later or contact our support'
            ]
            this.loading = false
          }
        })
        .catch((err) => {
          const { errors } = err?.response?.data
          if (errors) {
            this.errors = Object.values(errors)?.reduce((acc, error) => {
              if (Array.isArray(error)) {
                acc.push(...error)
              } else {
                acc.push(error)
              }
              return acc
            }, []) || []
          } else {
            this.errors = [
              'An unexpected error occurred, please try again later or contact our support'
            ]
          }

          this.loading = false
        })
    },
    addNewAddresses (addresses, groups) {
      const groupNames = []
      if (this.newGroupName) groupNames.push(this.newGroupName)

      return Api.post('/customer/address-book/addresses/bulk-store', {
        addresses,
        group_ids: groups,
        group_names: groupNames
      })
    },
    sendFileToApi () {
      const data = new FormData()
      data.append('file', this.selectedFile, this.selectedFile?.name)

      return Api.post('/customer/address-book/addresses/import-file', data)
    }
  }
}
</script>

<style lang="scss">
.import-contacts-input {
  .v-input__append-outer, .v-input__append-inner {
    margin: 0px !important;
  }

  .v-input__append-inner {
    height: 100% !important;

    & .v-input__icon {
      padding: 0;
      margin: 0 !important;
      display: flex;
      justify-self: center;
      align-self: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.info-text {
  &, a {
    font-size: 15px;
    font-family: 'Lato-Italic', sans-serif !important;
    color: #95979D;
    line-height: 18px;

    a {
      color: #42B77A !important;
    }
  }
}

.matched-fields-info {
  font-family: 'Lato-Italic', sans-serif !important;
  font-size: 15px;
  line-height: 18px;
  color: #95979D;
  padding-bottom: 40px;
}

.table-cel {
  padding: 8px 12px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  height: 100px;
  max-height: 100px;
  border-top: 1px solid #D3D2D2;
  &:last-child {
    border-bottom: 1px solid #D3D2D2;
  }

  font-size: 15px;
  font-family: 'Lato-Regular', sans-serif;
  color: #898989;

  span:first-of-type {
    font-weight: bold;
    color: #000;
  }

  span:not(:first-of-type) {
    font-size: 14px;
  }

  .v-input {
    max-height: 30px;
  }

  &--right {
    border-left: none;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  &--missing-info {
    color: #FA5E5E;
    font-family: 'Lato-Italic', sans-serif !important;
    font-size: 14px;
    width: 100%;
    padding-top: 8px;
  }
}

.table-header {
  color: #000;
  font-family: 'Lato-Bold', sans-serif !important;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 14px;
  letter-spacing: 0.5px;
}

label {
  font-size: 15px;
  display: inline-block;
  font-family: 'Lato-Bold', sans-serif;
  color: #000;
  line-height: 18px;
  position: relative;
}

.label-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  label {
    padding-bottom: 5px;
  }
}

.v-alert {
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Regular', sans-serif !important;
  color: #222325;

  b {
    font-family: 'Lato-Bold', sans-serif !important;
  }

  svg {
    min-width: 20px;
    margin-right: 16px;
  }
}
</style>
