<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 110"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="54.5" cy="73" r="37" fill="#E7E5FD"/>
    <circle cx="7" cy="29.5" r="6.5" fill="#E7E5FD"/>
    <circle cx="32" cy="9.5" r="9.5" fill="#E7E5FD"/>
    <path
      d="M79.5 56.5C79.4981 56.4087 79.4859 56.3181 79.4639 56.23C79.4661 56.2076 79.4661 56.1849
        79.4639 56.1625C79.4425 56.0949 79.4167 56.0291 79.3866 55.9656C79.3782 55.9457 79.3678
        55.9268 79.3556 55.9094C79.313 55.8326 79.261 55.7626 79.2009 55.7013L79.17 55.6731C79.1324
        55.6328 79.0909 55.5969 79.0462 55.5662L72.7969 51.1675V26.125C72.7969 25.5037 72.3352 25
        71.7656 25H21.2344C20.6648 25 20.2031 25.5037 20.2031 26.125V51.1731H20.1619L13.9744
        55.5269C13.93 55.558 13.8885 55.5938 13.8506 55.6338L13.8197 55.6619C13.7595 55.7245
        13.7059 55.7944 13.6598 55.87V55.9263C13.6297 55.9897 13.6039 56.0555 13.5825
        56.1231C13.5803 56.1456 13.5803 56.1682 13.5825 56.1906C13.5415 56.2889 13.5138 56.3931
        13.5 56.5V95.875C13.5 96.4963 13.9617 97 14.5312 97H78.4688C79.0383 97 79.5 96.4963 79.5
        95.875V56.5ZM76.6228 56.5506L72.7969 59.4644V53.8394L76.6228 56.5506ZM70.7344
        27.25V61.0338L50.7848 76.3C48.2103 78.2582 44.7897 78.2582 42.2152 76.3L22.2656
        61.0394V27.25H70.7344ZM20.2031 53.845V59.47L16.3772 56.545L20.2031 53.845ZM77.4375
        94.75H15.5625V58.66L41.0344 78.145C44.3187 80.6418 48.6813 80.6418 51.9656 78.145L77.4375
        58.66V94.75Z"
      fill="#95979D"
    />
    <path
      d="M45.7578 64.0319C45.9524 64.2514 46.2204 64.3753 46.5003 64.375C46.7803 64.3753 47.0483
        64.2514 47.2428 64.0319L57.0397 53.0069C59.8476 49.9422 59.8464 44.9747 57.0371
        41.9116C54.2279 38.8485 49.6743 38.8497 46.8664 41.9144L46.4849 42.3306L46.1136
        41.9256C43.3068 38.912 38.7983 38.9284 36.0101 41.9624C33.2218 44.9965 33.1953 49.9148
        35.9506 52.9844L45.7578 64.0319ZM37.4202 43.5175C39.4171 41.3523 42.642 41.3523 44.6389
        43.5175L45.7372 44.7156C45.931 44.9283 46.1945 45.0477 46.4694 45.0475C46.7425 45.0462
        47.0039 44.9269 47.1964 44.7156L48.3102 43.5062C49.6089 42.0925 51.5003 41.5419 53.272
        42.062C55.0437 42.5821 56.4265 44.0938 56.8994 46.0277C57.3724 47.9615 56.8637 50.0237
        55.565 51.4375L46.5003 61.6356L37.4202 51.4375C36.4541 50.3892 35.911 48.964 35.911
        47.4775C35.911 45.991 36.4541 44.5658 37.4202 43.5175Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThankYouNotes',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 92
    },
    height: {
      type: [Number, String],
      required: false,
      default: 110
    }
  }
}
</script>
