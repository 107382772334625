<template>
  <div
    class="cg-credits-balance dashboard-card"
    :class="{
      'dashboard-card--small': !showLargeCard,
      'dashboard-card--large': showLargeCard,
      'order-6': adminAccount && hasApprovalOrders && !$vuetify.breakpoint.lgAndUp && !$vuetify.breakpoint.xsOnly,
      'order-5': (adminAccount && !(superAdmin || masterAccount) && hasApprovalOrders && $vuetify.breakpoint.lgAndUp) ||
        ((superAdmin || masterAccount) && !hasInventory && hasApprovalOrders && $vuetify.breakpoint.lgAndUp) || 
        ((superAdmin || masterAccount) && hasInventory && !hasApprovalOrders && $vuetify.breakpoint.lgAndUp) ||
        (adminAccount && !hasApprovalOrders && hasInventory && $vuetify.breakpoint.lgAndUp),
      'order-2': (!adminAccount && $vuetify.breakpoint.lgAndUp) ||
        (adminAccount && !hasApprovalOrders && !hasInventory && $vuetify.breakpoint.lgAndUp) ||
        ((superAdmin || masterAccount) && hasApprovalOrders && hasInventory),
      'order-3': (!adminAccount && !$vuetify.breakpoint.lgAndUp) ||
        (adminAccount && !hasApprovalOrders && !$vuetify.breakpoint.lgAndUp) || $vuetify.breakpoint.xsOnly,
    }"
  >
    <div class="cg-credits-balance__title">
      {{ userHasEntitiesWithSpendLimit ? 'CG Credits Balance (personal)' : 'CG Credits Balance' }}
    </div>

    <v-divider />
    
    <template v-if="!loading && entities.length && !allEntitiesHasBalanceSetToZero">
      <div
        class="cg-credits-balance__content"
        :class="{ 'justify-center': marketplaceUser || entities.length < 3 }"
      >
        <the-cg-credits-balance-bar
          v-for="(entity, index) in entities"
          :entity="entity"
          :max-balance="maxValue"
          :key="index"
        />
      </div>

      <div class="cg-credits-balance__actions" v-if="hasAccessToCgCredits">
        <router-link :to="{ name: 'MyCGCredits' }">Full CG Credits info</router-link>
      </div>
    </template>

    <template v-else-if="!loading && (!entities.length || allEntitiesHasBalanceSetToZero)">
      <div class="cg-credits-balance__empty">
        <icons-empty-cg-credits />

        <span v-if="marketplaceUser || allEntitiesHasBalanceSetToZero">
          You have no CG Credits in your account. 
          <a href="https://corporategift.com/platform/" target="_blank">Learn more</a> about CG Credits
        </span>

        <span v-else>
          You are not associated with any budget entity yet
        </span>
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'
import marketplaceUser from '@/mixins/marketplaceUser'
import masterAccount from '@/mixins/masterAccount'
import adminAccount from '@/mixins/adminAccount'
import subAccount from '@/mixins/subAccount'
import superAdmin from '@/mixins/superAdmin'

import TheCgCreditsBalanceBar from '@/components/dashboard/TheCgCreditsBalanceBar.vue'

export default {
  name: 'TheCgCreditsBalance',
  components: {
    TheCgCreditsBalanceBar,
  },
  mixins: [
    superAdmin,
    subAccount,
    adminAccount,
    masterAccount,
    marketplaceUser,
  ],
  props: {
    hasApprovalOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasInventory: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    loading: false,
    budgetEntities: [],
    userOnHold: 0,
    userBalance: 0
  }),
  computed: {
    hasAccessToCgCredits () {
      return this.$store.state.header.headerData.menus?.myCgCredits ?? false
    },
    showLargeCard () {
      const { hasInventory, masterAccount, adminAccount, superAdmin, hasApprovalOrders } = this

      return ((hasApprovalOrders && !hasInventory && adminAccount && !(superAdmin || masterAccount)) ||
        ((superAdmin || masterAccount) && !hasInventory && hasApprovalOrders) ||
        ((superAdmin || masterAccount || adminAccount) && hasInventory && !hasApprovalOrders)) &&
        this.$vuetify.breakpoint.mdAndUp
    },
    marketplaceUserEntities () {
      const { userOnHold, userBalance } = this
      const result = []

      result.push({
        id: 1,
        name: 'Available',
        color: '#38A39C',
        balance: userBalance,
        show_balance: true,
        account_limit: null,
        account_spent: null
      })

      result.push({
        id: 1,
        name: 'On hold',
        color: '#EB8989',
        balance: userOnHold,
        show_balance: true,
        account_limit: null,
        account_spent: null
      })

      return result
    },
    entities () {
      const { budgetEntities, showLargeCard, marketplaceUserEntities, marketplaceUser } = this
      const entities = budgetEntities?.filter(item => item?.show_balance || item?.account_limit) ?? []
      const spendLimitEntities = entities?.filter((item) => item?.account_limit) ?? []
      const maxEntities = showLargeCard ? 6 : 3

      if (marketplaceUser) return marketplaceUserEntities
      return spendLimitEntities?.length
        ? spendLimitEntities.slice(0, maxEntities)
        : entities.slice(0, maxEntities)
    },
    maxValue () {
      const { entities } = this
      const values = entities?.reduce((acc, entity) => {
        acc.push(entity.account_limit ? entity.account_limit : entity.balance)
        return acc
      }, [])
      return Math.max(...values, 0)
    },
    userHasEntitiesWithSpendLimit () {
      return !!this.entities?.filter((entity) => entity?.account_limit)?.length ?? false
    },
    allEntitiesHasBalanceSetToZero () {
      const { entities } = this
      const entitiesWithZeroBalance = entities
        .filter((entity) => entity.show_balance ? !entity.balance : !entity.account_limit)
      return entitiesWithZeroBalance.length === entities.length
    }
  },
  watch: {
    entities: function (val) {
      if (this.loading) return
      if (!val.length && this.subAccount && !this.adminAccount) this.$emit('hideCgCredits', true)
    },
    userBalance: function (val) {
      this.$emit('userBalance', val)
    }
  },
  created() {
    this.loading = true
    Api.get('/customer/dashboard/budget-entities')
      .then(({ data, summary }) => {
        this.budgetEntities = data
        this.userOnHold = summary.on_hold
        this.userBalance = summary.balance
      })
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.cg-credits-balance {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > hr {
    border-color: #D3D2D2;
  }

  & a {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #42B77A !important;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #95979D;
    text-transform: uppercase;
  }

  &__content {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    // gap: 20px;
    gap: 34px;
    height: 230px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 28px;
    align-items: center;
    justify-content: center;

    & > span {
      max-width: 280px;
      text-align: center;
      font-family: 'Lato-Italic', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #95979D;

      & > a {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
      }
    }
  }
}
</style>
