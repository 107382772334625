<template>
  <v-navigation-drawer
    class="my-quotes-details-panel"
    v-lock-scroll="show"
    v-model="show"
    width="1000px"
    temporary
    right
    app
  >
    <panel-header :show.sync="show">
      <div class="my-quotes-details-panel__title">
        Quote # <b>{{ selectedQuoteId }}</b>
      </div>
    </panel-header>

    <div class="my-quotes-details-panel__content">
      <common-loader v-if="loading" style="padding: 200px 0" />

      <template v-else>
        <div v-if="quoteStatus !== 'Active'" class="my-quotes-details-panel__status-info">
          <template v-if="quoteStatus === 'Approved'">
            <icons-check-circle filled />
            Quote is approved!
          </template>
          <template v-else-if="quoteStatus === 'Declined'">
            <icons-close color="#000" :height="16" :width="16" />
            Quote is declined!
          </template>
          <template v-else-if="quoteStatus === 'Expired'">
            <icons-calendar-expired color="#000" :height="24" :width="24" />
            Quote is expired!
          </template>
        </div>

        <cg-textarea
          v-if="declineQuoteReason"
          v-model="declineQuoteReason"
          disabled
          auto-grow
        />

        <div v-if="!showDeclineForm" class="my-quotes-details-panel__actions">
          <template v-if="quoteStatus === 'Active'">
            <cg-button @click="approveQuote">
              <icons-check-circle
                :check-stroke-width="2"
                filled-color="currentColor"
                filled-check-color="#000"
                filled
                :width="20"
                :height="20"
                class="mr-2"
              />
              Approve
            </cg-button>

            <cg-button outlined @click="showDeclineForm = true">
              <icons-close color="currentColor" :width="14" :height="14" class="mr-2" />
              Decline
            </cg-button>
          </template>

          <template v-else-if="quoteStatus === 'Approved'">
            <cg-button v-if="itemIsReady" @click="convertQuoteToCart">
              Checkout
            </cg-button>
          </template>

          <cg-button v-if="quotePdfLink" outlined @click="downloadPdf" :loading="downloadingPdf">
            <icons-download-file color="currentColor" :width="20" :height="20" class="mr-2" />
            Download PDF
          </cg-button>
        </div>

        <template v-if="showDeclineForm">
          <cg-textarea
            v-model="declineReason"
            auto-grow
            ref="decline-reason"
            placeholder="Reasons of declining quote"
            :validation="[required({ errorMessage: '' })]"
          />

          <div class="my-quotes-details-panel__actions">
            <cg-button @click="showDeclineForm = false">
              <icons-arrow-back color="currentColor" :width="22" :height="22" class="mr-2" />
              Back
            </cg-button>

            <cg-button outlined @click="declineQuote">
              <icons-close color="currentColor" :width="14" :height="14" class="mr-2" />
              Decline quote
            </cg-button>
          </div>
        </template>

        <div v-if="quotePdfLink" class="my-quotes-details-panel__input-wrapper">
          <common-loader v-if="loadingPdf" style="padding: 150px 0" />

          <vue-pdf-embed
            v-else
            ref="pdf-file"
            :source="quotePdfLink"
          />
        </div>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import type IQuoteItemDetails from '../types/IQuoteItemDetails'
import type IQuoteResponse from '../types/IQuoteResponse'

import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import { required } from '@corporategift/design-system/validations'

import PanelHeader from '@/components/PanelHeader.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { CgTextarea, CgButton } from '@corporategift/design-system'

interface IDeclineBody {
  reason?: string;
}

@Component({
  mixins: [panelVModel],
  components: {
    PanelHeader,
    VuePdfEmbed,
    CgTextarea,
    CgButton,
  },
  methods: {required},
})
export default class MyQuotesDetailsPanel extends Vue {
  @Prop({ default: undefined }) readonly selectedQuoteId!: number | undefined;

  @Prop({ required: false, default: null }) readonly selectedQuoteItem!: IQuoteItemDetails | null;

  loading = false;

  loadingPdf = false;

  downloadingPdf = false;

  showDeclineForm = false;

  declineReason?: string = '';

  get quotePdfLink() {
    return this.selectedQuoteItem?.document_url;
  }

  get quoteStatus() {
    return this.selectedQuoteItem?.status;
  }

  get declineQuoteReason() {
    return this.selectedQuoteItem?.declined_reason;
  }

  get redirectTo() {
    return this.selectedQuoteItem?.redirect_to;
  }

  get itemIsReady() {
    return this.selectedQuoteItem?.is_ready ?? false;
  }

  @Ref('decline-reason') readonly declineReasonTextarea!: typeof CgTextarea;

  @Watch('show')
  onShowChange (val: boolean) {
    if (!val) {
      const getDefaultData = this.$options.data as { apply: (args: unknown) => void }
      Object.assign(this.$data, getDefaultData.apply(this))
    }
  }

  @Watch('selectedQuoteId')
  onQuoteIdChange (val: number) {
    if (val !== null && this.quotePdfLink) { this.setLoadingPdf() }
  }

  setLoadingPdf () {
    this.loadingPdf = true
    setTimeout(() => { this.loadingPdf = false }, 500)
  }

  approveQuote () {
    const { selectedQuoteId } = this;
    this.loading = true;

    Api.patch<undefined, IQuoteResponse>(`/customer/quotes/${selectedQuoteId}/approve`)
      .then(({ data }) => {
        this.$emit('updateQuoteItem', data);
        this.$cgToast.success('The quote has been successfully approved, and you may now checkout and finalize the transaction')
      })
      .catch(() => {
        this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
      })
      .finally(() => (this.loading = false))
  }

  declineQuote () {
    const { declineReason, declineReasonTextarea, selectedQuoteId } = this

    // @ts-ignore
    if (declineReasonTextarea.validate()) {
      this.loading = true;

      Api.patch<IDeclineBody, IQuoteResponse>(`/customer/quotes/${selectedQuoteId}/decline`, {
        reason: declineReason
      })
        .then(({ data }) => {
          this.$emit('updateQuoteItem', data);
          this.$cgToast.success('You have effectively rejected the quote')
          this.showDeclineForm = false;
          this.declineReason = '';
        })
        .catch(() => {
          this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
        })
        .finally(() => (this.loading = false))
    }
  }

  convertQuoteToCart () {
    const { selectedQuoteId, redirectTo } = this;
    this.loading = true;

    Api.post(`/convert-quote/${selectedQuoteId}`)
      .then(() => {
        const redirectUrl = redirectTo === 'cart' ? 'cart' : 'checkout/onepage/shipping-address'

        window.location.href = `${process.env.VUE_APP_MAGENTO_URL}/${redirectUrl}`
      })
      .catch(() => {
        this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
      })
      .finally(() => (this.loading = false))
  }

  downloadPdf () {
    const { selectedQuoteId } = this;
    this.downloadingPdf = true;

    Api.get<undefined, string>(`/customer/quotes/${selectedQuoteId}/download`, {
      responseType: 'arraybuffer'
    })
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const url = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `quote-${selectedQuoteId}`);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        this.$cgToast.error('An unexpected error occurred, please try again later or contact our support')
      })
      .finally(() => (this.downloadingPdf = false))
  }
}
</script>

<style lang="scss" scoped>
.my-quotes-details-panel {
  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 44px;
    padding: 50px;
  }

  &__title {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;

    & b,
    & strong {
      font-weight: 700;
      font-family: 'Lato-Bold', sans-serif;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 30px;

    &::v-deep .cg-textarea {
      flex: 0 1 auto;

      & textarea {
        box-sizing: content-box;
      }
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > label {
      font-family: 'Lato', 'Lato-Bold', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
      text-align: left;
    }
  }

  &__status-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;

    font-family: 'Lato', 'Lato-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #222325;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
  }
}
</style>
