<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M17.2969 5.41407L16.0469 2.91407C16.0076 2.83606 15.9472 2.77058 15.8727 2.72502C15.7982
        2.67947 15.7124 2.65565 15.625 2.65626H4.375C4.28764 2.65565 4.20185 2.67947 4.12731
        2.72502C4.05277 2.77058 3.99244 2.83606 3.95313 2.91407L2.70313 5.41407C2.67207 5.48005
        2.65606 5.55209 2.65625 5.62501V16.25C2.65625 16.5401 2.77149 16.8183 2.9766 17.0234C3.18172
        17.2285 3.45992 17.3438 3.75 17.3438H16.25C16.5401 17.3438 16.8183 17.2285 17.0234
        17.0234C17.2285 16.8183 17.3438 16.5401 17.3438 16.25V5.62501C17.3439 5.55209 17.3279 5.48005
        17.2969 5.41407ZM4.66406 3.59376H15.3359L16.1172 5.15626H3.88281L4.66406 3.59376ZM16.25
        16.4063H3.75C3.70856 16.4063 3.66882 16.3898 3.63952 16.3605C3.61021 16.3312 3.59375 16.2915
        3.59375 16.25V6.09376H16.4063V16.25C16.4063 16.2915 16.3898 16.3312 16.3605 16.3605C16.3312
        16.3898 16.2914 16.4063 16.25 16.4063Z"
      :fill="color"
    />
    <path
      d="M13.1191 10.7773C13.1191 10.9013 13.0708 11.0204 12.9844 11.1094C12.8954 11.1958 12.7763
        11.2441 12.6523 11.2441C12.5284 11.2441 12.4093 11.1958 12.3203 11.1094L10.4688
        9.25781V14.375C10.4688 14.4993 10.4194 14.6185 10.3315 14.7065C10.2435 14.7944 10.1243
        14.8438 10 14.8438C9.87568 14.8438 9.75645 14.7944 9.66854 14.7065C9.58064 14.6185 9.53125
        14.4993 9.53125 14.375V9.25781L7.67969 11.1094C7.59163 11.1974 7.47219 11.2469 7.34766
        11.2469C7.22312 11.2469 7.10369 11.1974 7.01563 11.1094C6.92756 11.0213 6.87809 10.9019
        6.87809 10.7773C6.87809 10.6528 6.92756 10.5334 7.01563 10.4453L9.67188 7.79688C9.7142
        7.75244 9.7651 7.71706 9.82151 7.69289C9.87791 7.66872 9.93864 7.65625 10 7.65625C10.0614
        7.65625 10.1221 7.66872 10.1785 7.69289C10.2349 7.71706 10.2858 7.75244 10.3281
        7.79688L12.9844 10.4453C13.0708 10.5343 13.1191 10.6534 13.1191 10.7773Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'UsersUnArchive',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    },
  },
}
</script>
