<template>
  <div class="recipients-bulk-options-upload-card">
    <div class="recipients-bulk-options-upload-card__icon">
      <icons-recipients-upload-file />
    </div>

    <div class="recipients-bulk-options-upload-card__text">
      Upload a CSV or tab-delimited TXT file

      <br /><br />

      <a href="https://corporategift.com/resources/sample_list.csv" download>
        Download our template
        <!-- TODO tooltip -->
      </a>
    </div>

    <div class="recipients-bulk-options-upload-card__import-area">
      <input
        type="file"
        name="upload-file"
        id="upload-file"
        @change="onChange"
        ref="file"
        accept=".csv, .tsv"
      >

      <label for="upload-file">
        Upload CSV file
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RecipientMethod, RootSteps } from '../../types'
import type { IRecipient } from '../../types'

import takeCurrentDate from '../mixins/takeCurrentDate'

@Component
export default class RecipientsBulkOptionsUploadCard extends Mixins(takeCurrentDate) {
  onChange (): void {
    const input = this.$refs.file as HTMLInputElement

    const file: File | null = input?.files ? input.files[0] : null

    if (file) {
      const reader = new FileReader()
      reader.onload = ({ target }) => {
        if (target?.result) { this.convertCsvToArray(target.result as string) }
      }
      reader.readAsText(file)
    }
  }

  isDateValid (sendDate?: string | null) {
    if (!sendDate) { return false }
    return new Date(sendDate) >= new Date(this.takeCurrentDate())
  }

  convertCsvToArray (fileData: string, delimiter: RegExp = /,|\t/): void {
    // remove first row with headers
    fileData.slice(0, fileData.indexOf('\n')).split(delimiter)
    // TODO handle country
    const headers = ['firstName', 'lastName', 'email', 'sendDate']
    let rows = fileData.slice(fileData.indexOf('\n') + 1).split('\n')
    // Remove empty
    rows = rows?.filter((item) => !!item) || []

    const arrayOfRecipients: Array<IRecipient> = rows.map((row) => {
      const values = row.split(delimiter)

      const recipient = headers.reduce<IRecipient>((object, header, index) => {
        // sendDate
        if (header === headers[3]) {
          const value = values[index]
          const date = this.isDateValid(value)
            ? new Date(value).toISOString()
            : this.takeCurrentDate()
          object[header] = date
        } else {
          object[header] = values[index]
        }
        return object
      }, {})

      return recipient
    })

    this.$emit('update:recipientMethod', RecipientMethod.ImportFile)
    this.$emit('update:step', RootSteps.ReviewRecipients)
    this.$emit('update:recipients', arrayOfRecipients)
  }
}
</script>

<style lang="scss" scoped>
.recipients-bulk-options-upload-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 30px 24px;
  min-width: 240px;
  max-width: 240px;
  flex: 1 1;
  border: 1px solid #F4F4F4;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);

  &__icon {
    svg > {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
    }
  }

  &__text {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #222325;

    & > a {
      font-family: 'Lato-Italic', sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #42B77A !important;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 6px;
      align-items: center;
      text-decoration: none;
      justify-content: center;

      &:hover {
        color: #219358 !important;
      }
    }
  }

  &__import-area {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000;
    cursor: pointer;
    padding: 6px 16px;
    margin-top: auto;
    background-color: inherit;
    color: #000000;

    &:hover {
      color: #919191 !important;
      border-color: #919191 !important;
    }

    & > input {
      width: 1px;
      height: 1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
    }

    & > label {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: normal;
      color: inherit;
      margin: 0;
      cursor: inherit;
    }
  }
}
</style>
