export default {
  props: {
    panelType: {
      type: String,
      required: false,
      validator: val => {
        return ['egift', 'link'].includes(val)
      },
      default: 'egift'
    }
  }
}
