import api from '@/axios/api'
import { campaignForm as campaignFormInitialState } from '@/store/initialState/surveyMonkey'

export default {
  namespaced: true,

  state: () => ({
    isCampaignFormEdit: false,
    campaignForm: JSON.parse(JSON.stringify(campaignFormInitialState)),
    campaigns: [],
    isShowSettingsPanel: false,
    isShowCampaignPanel: false,
    surveys: [],
    availableSurveys: [],
    credentials: {
      accessToken: '',
      clientId: '',
      secret: ''
    },
    gifts: [],
    selectedSurveyQuestions: [],
    budgetEntities: []
  }),

  getters: {
    getCampaigns (state) {
      return state.campaigns
    },

    getCampaignForm (state) {
      return state.campaignForm
    },

    getIsCampaignFormEdit (state) {
      return state.isCampaignFormEdit
    },

    getSurveys (state) {
      return state.surveys
    },

    getAvailableSurveys (state) {
      return state.availableSurveys
    },

    getCredentials (state) {
      return state.credentials
    },

    getGifts (state) {
      return state.gifts
    }
  },

  mutations: {
    setIsCampaignFormEdit (state, status) {
      state.isCampaignFormEdit = status
    },

    setCampaignFormAttribute (state, data) {
      state.campaignForm[data.key] = data.value
    },

    setCampaigns (state, campaigns) {
      state.campaigns = campaigns
    },

    setIsShowSettingsPanel (state, status) {
      state.isShowSettingsPanel = status
    },

    setIsShowCampaignPanel (state, status) {
      state.isShowCampaignPanel = status
    },

    addCampaign (state, campaign) {
      const updatedCampaigns = []
      if (state.campaigns.length) {
        for (const camp of state.campaigns) {
          if (camp.survey_id === campaign.survey_id) {
            updatedCampaigns.push(campaign)
          } else {
            updatedCampaigns.push(camp)
          }
        }
      } else {
        updatedCampaigns.push(campaign)
      }
      state.campaigns = updatedCampaigns
    },

    setSurveys (state, surveys) {
      state.surveys = surveys
    },

    setAvailableSurveys (state, surveys) {
      state.availableSurveys = surveys
    },

    setCredentials (state, credentials) {
      state.credentials = credentials
    },

    setGifts (state, gifts) {
      state.gifts = gifts
    },

    setSelectedSurveyQuestions (state, selectedSurveyQuestions) {
      state.selectedSurveyQuestions = selectedSurveyQuestions
    },

    setBudgetEntities (state, budgetEntities) {
      state.budgetEntities = budgetEntities
    }
  },

  actions: {
    async updateCampaignForm ({ commit, dispatch }, payload) {
      for (const property in payload) {
        if (Object.prototype.hasOwnProperty.call(payload, property)) {
          commit('setCampaignFormAttribute', { key: property, value: payload[property] })
        }
      }
      dispatch('filterAvailableSurveys')
      dispatch('filterSelectedSurveyQuestions')
    },

    clearCampaignForm ({ dispatch }) {
      dispatch('updateCampaignForm', JSON.parse(JSON.stringify(campaignFormInitialState)))
    },

    async fetchCampaigns ({ commit }) {
      await api.get('/customer/survey-monkey/campaigns')
        .then(response => {
          commit('setCampaigns', response.data)
        })
        .catch(err => console.error(err))
    },

    async createOrUpdateCampaign ({ commit }, payload) {
      const response = await api.post('/customer/survey-monkey/campaigns', payload)
      if (!response.success) {
        throw response.errMsg
      }
      commit('addCampaign', response.data)
    },

    hideCampaignFormDialog ({ commit }) {
      commit('setCampaignFormDialogVisibility', false)
    },

    async fetchCredentials ({ commit, getters }) {
      const credentials = getters.getCredentials
      if (credentials.clientId && credentials.accessToken && credentials.secret) {
        return
      }

      await api.get('/customer/survey-monkey/credentials')
        .then(credentials => {
          commit('setCredentials', credentials)
        })
    },

    async fetchGifts ({ commit, getters }) {
      const gifts = getters.getGifts
      if (gifts.length) {
        return
      }

      await api.get('/gift/products')
        .then(response => {
          commit('setGifts', response.data)
        })
    },

    async fetchSurveys ({ commit, getters, dispatch }) {
      const surveys = getters.getSurveys
      if (surveys.length) {
        return
      }

      await api.get('/customer/survey-monkey/surveys')
        .then(surveys => {
          commit('setSurveys', surveys)
          dispatch('filterAvailableSurveys')
          dispatch('filterSelectedSurveyQuestions')
        })
    },

    filterAvailableSurveys ({ commit, getters }) {
      const surveys = getters.getSurveys
      const isCampaignFormEdit = getters.getIsCampaignFormEdit
      const campaignForm = getters.getCampaignForm
      const campaigns = getters.getCampaigns
      const campaignsSurveyId = campaigns.map((item) => item.survey_id)

      let availableSurveys = []
      if (isCampaignFormEdit) {
        availableSurveys = surveys.filter((survey) => campaignForm.surveyId === survey.id)
      } else {
        availableSurveys = surveys.filter((survey) => !campaignsSurveyId.includes(survey.id))
      }
      commit('setAvailableSurveys', availableSurveys)
    },

    filterSelectedSurveyQuestions ({ commit, getters }) {
      const questions = []

      getters.getSurveys.map((survey) => {
        if (survey.id.toString() === getters.getCampaignForm.surveyId.toString()) {
          survey.questions.map((question) => {
            questions.push(question)
          })
        }
      })
      commit('setSelectedSurveyQuestions', questions)
    },

    async fetchBudgetEntities ({ commit }) {
      await api.get('/customer/payment?amount=1')
        .then(response => {
          commit('setBudgetEntities', response.budgetEntities)
        })
        .catch(err => console.error(err))
    }
  }
}
