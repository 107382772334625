export default {
  computed: {
    formEmail () {
      return this?.form?.email ?? null
    },
  },
  watch: {
    formEmail: {
      handler: function (val) {
        sessionStorage.setItem('authenticationEmail', val)
      }
    }
  },
  mounted () {
    const authenticationEmail = sessionStorage.getItem('authenticationEmail')

    if (authenticationEmail) {
      this.form.email = authenticationEmail;
    }
  }
}
