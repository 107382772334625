<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    width="680"
  >
    <v-container v-if="contacts">
      <v-row dense>
        <v-col cols="11">
          <v-card class="pa-8 rounded-0">
            <v-card-title class="pa-0">
              <span class="lato-light f30 break-word" v-if="contactsLength > 1">
                Add {{ contacts.length}} contacts to existing or new group.
              </span>
              <span class="lato-light f30 break-word" v-else>
                Add 1 contact to existing or new group.
              </span>
            </v-card-title>

            <v-card-text class="px-0 py-6 pb-2">
              <span class="lato-bold black1 f15">
                Please note that if you have any groups selected, ALL contacts within that group will be added as well.
              </span>

              <select-group-list
                class="pt-6"
                v-bind="_props"
                :selectedGroups.sync="selectedGroups"
                :newGroupName.sync="newGroupName"
              />
            </v-card-text>

            <v-card-actions class="pa-0">
              <common-button
                height="40"
                width="145"
                :disabled="!selectedGroups.length && !newGroupName || disableButton"
                :loading="loading"
                @click="saveChanges()"
              >
                Save & Close
              </common-button>

              <common-button
                outlined
                class="ml-4"
                height="40"
                width="130"
                @click="show = false"
              >
                Cancel
              </common-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="1" class="text-center">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Api from '@/axios/api'

import SelectGroupList from './SelectGroupList'

import panelVModel from '@/mixins/panelVModel'
import { groups, contacts } from './mixins'

export default {
  name: 'TheAddToGroupModal',
  components: {
    SelectGroupList
  },
  mixins: [
    panelVModel,
    contacts,
    groups
  ],
  data: () => ({
    selectedGroups: [],
    newGroupName: null,
    loading: false
  }),
  computed: {
    contactsLength () {
      return this.contacts.length
    },
    contactsIds () {
      return this.contacts?.map((item) => item.entity_id) || []
    },
    disableButton () {
      if (!this.newGroupName) return false

      return this.newGroupName?.length < 2
    }
  },
  watch: {
    show: function (val) {
      if (val && this.contactsLength === 1) {
        this.selectedGroups = this.contacts[0].groups.map((group) => group.entity_id)
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  methods: {
    saveChanges () {
      this.loading = true
      const promises = []
      if (this.newGroupName) {
        const promise = this.createNewGroup()
        promises.push(promise)
      }

      this.selectedGroups.forEach((groupId) => {
        const promise = this.addContactsToGroup(groupId)
        promises.push(promise)
      })

      Promise.all(promises).then(() => {
        this.$emit('fetchData')
        this.$cgToast.successBold('Successfully added contacts to group')
        this.loading = false
        this.show = false
      })
    },
    createNewGroup () {
      return Api.post('/customer/address-book/groups', {
        name: this.newGroupName,
        address_ids: this.contactsIds
      })
    },
    addContactsToGroup (groupId) {
      return Api.patch(`/customer/address-book/groups/${groupId}/attach-addresses`, {
        ids: this.contactsIds
      })
    },
  }
}
</script>
