<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="11" cy="11" r="11" :fill="color"/>
    <path d="M6.11108 15.6436L15.6433 6.11108" stroke="white" stroke-width="2"/>
    <path d="M15.6433 15.6433L6.11108 6.11108" stroke="white" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'CloseCircleFilled',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 22
    },
    height: {
      type: [Number, String],
      required: false,
      default: 22
    },
    color: {
      type: String,
      required: false,
      default: '#FF5A60'
    },
  }
}
</script>
