<template>
  <v-form ref="form" class="add-multiple-users-settings">
    <div class="add-multiple-users-settings__divider" />

    <div class="add-multiple-users-settings__inputs-title">
      Permissions
    </div>

    <div class="add-multiple-users-settings__input-wrapper">
      <label for="permission-level">
        Permission level

        <common-tooltip
          top
          :max-width="300"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          <span v-html="getTooltipTextBySlug('users_permission_level')" />
        </common-tooltip>
      </label>
      <common-select
        v-model="usersPermissionLevel"
        :items="permissionsLevels"
        item-text="value"
        item-value="key"
        :height="40"
        id="permission-level"
      />
    </div>

    <div class="add-multiple-users-settings__input-wrapper">
      <label for="categories-access">Access to categories</label>
      <select-multiple-entities
        id="categories-access"
        v-model="usersCategories"
        :items="categoriesList"
        placeholder="Select categories"
        item-text="title"
        item-value="value"
        :height="40"
        clearable
        chips
      />
    </div>

    <div class="add-multiple-users-settings__input-wrapper">
      <label for="campaigns-access">Access to campaigns</label>
      <select-multiple-entities
        id="campaigns-access"
        v-model="usersCampaigns"
        :items="campaignsList"
        placeholder="Select campaigns"
        item-text="name"
        item-value="id"
        :height="40"
        clearable
        chips
      />
    </div>

    <div class="add-multiple-users-settings__input-wrapper">
      <common-checkbox
        v-model="usersOrdersRequireApproval"
        class="add-multiple-users-settings__checkbox"
        bold-text
      >
        User orders require approval

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('users_order_approver') }}
        </common-tooltip>
      </common-checkbox>

      <v-expand-transition>
        <common-select
          v-if="usersOrdersRequireApproval"
          v-model="usersOrderApprover"
          :items="approversList"
          :rules="[validation.approverRequired]"
          placeholder="Select approver"
          :height="40"
          item-text="name"
          item-value="approver_id"
          id="orders-approval"
        />
      </v-expand-transition>
    </div>

    <div class="add-multiple-users-settings__divider" />

    <my-users-set-spend-limit
      :show.sync="usersSetSpendLimit"
      :limit.sync="usersSpendLimitValue"
      :time.sync="usersSpendLimitTime"
      :timeList="spendLimitTimeFrames"
    />

    <div class="add-multiple-users-settings__divider" />

    <div class="add-multiple-users-settings__input-wrapper add-multiple-users-settings__input-wrapper--budget">
      <label for="budget-entities">Budget entites</label>

      <select-multiple-entities
        id="budget-entities"
        v-model="usersBudgetEntities"
        :items="budgetEntitiesList"
        placeholder="Select budget entities"
        item-text="name"
        item-value="id"
        :height="40"
        clearable
        chips
      />
    </div>

    <div class="add-multiple-users-settings__divider" />

    <div class="add-multiple-users-settings__input-wrapper add-multiple-users-settings__input-wrapper--budget">
      <label for="budget-entities">Set access to inventory items</label>

      <select-multiple-entities
        id="budget-entities"
        v-model="usersInventory"
        :items="inventoryItemsList"
        placeholder="Select budget entities"
        item-text="name"
        item-value="id"
        :height="40"
        clearable
        chips
      />
    </div>
    
    <div class="add-multiple-users-settings__divider" />

    <common-checkbox v-model="welcomeEmail">
      Send Welcome email to new users
    </common-checkbox>

    <div class="add-multiple-users-settings__actions">
      <cg-button @click.prevent="onSubmit">
        Add {{ usersData.length }} users
      </cg-button>
    </div>
  </v-form>
</template>

<script>
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import { CgButton } from '@corporategift/design-system'
import MyUsersSetSpendLimit from '@/components/mySubAccounts/MyUsersSetSpendLimit.vue'
import SelectMultipleEntities from '@/components/mySubAccounts/SelectMultipleEntities.vue'

export default {
  name: 'MultipleUsersSettings',
  mixins: [getTooltipTextBySlug],
  components: {
    CgButton,
    MyUsersSetSpendLimit,
    SelectMultipleEntities,
  },
  props: {
    usersData: {
      type: Array,
      required: false,
      default: () => ([])
    },
    options: {
      type: Object,
      required: true,
    },
    permissionLevel: {
      type: Number,
      required: false,
      default: 200,
    },
    ordersRequireApproval: {
      type: Boolean,
      required: false,
      default: false,
    },
    orderApprover: {
      type: Number,
      required: false,
      default: null,
    },
    setSpendLimit: {
      type: Boolean,
      required: false,
      default: false,
    },
    spendLimitValue: {
      type: [String, Number],
      required: false,
      default: null,
    },
    spendLimitTime: {
      type: String,
      required: false,
      default: null,
    },
    selectedBudgetEntities: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    selectedCategories: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    selectedInventoryItems: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    selectedCampaigns: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    sendWelcomeEmail: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    validation: {
      required: v => !!v || '',
      approverRequired: v => v !== null || '',
    },
  }),
  computed: {
    budgetEntitiesList () { return this.options?.budgetEntitiesList ?? [] },
    categoriesList () { return this.options?.categoriesList ?? [] },
    approversList () { return this.options?.approversList ?? [] },
    permissionsLevels () { return this.options?.permissionsLevels ?? [] },
    inventoryItemsList () { return this.options?.inventoryItemsList ?? [] },
    campaignsList () { return this.options?.campaignsList ?? [] },
    spendLimitTimeFrames () { return this.options?.spendLimitTimeFrames ?? [] },
    usersPermissionLevel: {
      get () { return this.permissionLevel },
      set (val) { this.$emit('update:permissionLevel', val) },
    },
    usersOrdersRequireApproval: {
      get () { return this.ordersRequireApproval },
      set (val) { this.$emit('update:ordersRequireApproval', val) },
    },
    usersOrderApprover: {
      get () { return this.orderApprover },
      set (val) { this.$emit('update:orderApprover', val) }
    },
    usersSetSpendLimit: {
      get () { return this.setSpendLimit },
      set (val) { this.$emit('update:setSpendLimit', val) },
    },
    usersSpendLimitValue: {
      get () { return this.spendLimitValue },
      set (val) { this.$emit('update:spendLimitValue', val) },
    },
    usersSpendLimitTime: {
      get () { return this.spendLimitTime },
      set (val) { this.$emit('update:spendLimitTime', val) },
    },
    usersBudgetEntities: {
      get () { return this.selectedBudgetEntities },
      set (val) { this.$emit('update:selectedBudgetEntities', val) },
    },
    usersCategories: {
      get () { return this.selectedCategories },
      set (val) { this.$emit('update:selectedCategories', val) },
    },
    usersInventory: {
      get () { return this.selectedInventoryItems },
      set (val) { this.$emit('update:selectedInventoryItems', val) },
    },
    usersCampaigns: {
      get () { return this.selectedCampaigns },
      set (val) { this.$emit('update:selectedCampaigns', val) },
    },
    welcomeEmail: {
      get () { return this.sendWelcomeEmail },
      set (val) { this.$emit('update:sendWelcomeEmail', val) },
    },
  },
  methods: {
    onSubmit () {
      if (!this.$refs.form.validate()) { return }
      this.$emit('addUsers')
    }
  },
}
</script>

<style lang="scss" scoped>
.add-multiple-users-settings {
  display: flex;
  flex-direction: column;
  max-width: 550px;
  gap: 40px;
  padding-bottom: 140px;

  &__divider {
    height: 1px;
    width: 100%;
    background: #D3D2D2;
  }

  &__inputs-title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;

    & > label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }

    &--budget {
      gap: 20px;

      & > label {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &__checkbox {
    &::v-deep .common-checkbox__content {
      gap: 8px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
</style>
