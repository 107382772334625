<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="items.length"
    item-key="id"
    class="mb-8 transactions-table"
    no-data-text="You have no transaction history"
    fixed-header
    calculate-widths
    hide-default-footer
    :style="`--header-height: ${headerHeight}px`"
  >
    <template #item.type="{ item: { type, comment } }">
      <div v-if="comment" class="d-flex align-start" style="gap: 4px">
        {{ type }}

        <common-tooltip top :max-width="300" :activator-size="19" activator-color="#62646A">
          {{ comment }}
        </common-tooltip>
      </div>
      <div v-else>
        <div class="d-flex align-start" style="gap: 4px">
          {{ type }}

          <common-tooltip
            v-if="['REFUND', 'EGIFT REFUND'].includes(type.toUpperCase())"
            top
            :max-width="250"
          >
            <template v-if="type.toUpperCase() === 'REFUND'">
              Gift cost refunded due to cancelled gift/overcharge/other
            </template>

            <template v-else>
              Unused deposit refunded from credit card/ACH/PO to your CG Credits balance
            </template>
          </common-tooltip>
        </div>
      </div>
    </template>
    <template #item.amount="{ item }">
      {{ item.amount | priceFilter }}
    </template>
    <template #item.credits="{ item }">
      <v-row no-gutters class="f14">
        <v-col cols="5" class="lato-bold">
          Balance:
        </v-col>
        <v-col
          cols="7"
          :class="{
            green1: item.balance > 0,
            red1: item.balance < 0
          }"
        >
          {{ item.balance | priceFilter }}
        </v-col>
        <v-col cols="5" class="lato-bold">
          On hold:
        </v-col>
        <v-col cols="7">
          {{ item.on_hold | priceFilter }}
        </v-col>
      </v-row>
    </template>
    <template #item.invoice="{ item }">
      <v-tooltip
        bottom
        v-if="item.invoice"
        content-class="v-tooltip__content--none"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-flex align-center justify-center"
            v-bind="attrs"
            v-on="on"
            @click="$store.dispatch('downloadInvoice', item.order_id)"
          >
            <icons-download-invoice
              fillable
              :width="14"
              :height="18"
            />
          </div>
        </template>
        <span class="f12 black--text text-center">
          Download invoice
        </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { transactionHistoryHeaders } from '@/assets/headers'

import getHeaderHeight from '@/mixins/getHeaderHeight'
import { items } from './mixins'

export default {
  name: 'TheTransactionsTable',
  mixins: [
    items,
    getHeaderHeight,
  ],
  data: () => ({
    headers: transactionHistoryHeaders
  })
}
</script>

<style lang="scss">
.transactions-table {
  table tr td {
    min-height: 75px !important;
    height: 75px !important;
    padding: 0 5px !important;
  }

  tr th {
    border-right: none !important;
    padding: 0 5px !important;
    font-size: 11px !important;
    line-height: 15px !important;
    height: 34px !important;
    top: calc(var(--header-height, 160px) - 2px) !important;

    &:last-child {
      text-align: left !important;
    }
  }
}
</style>
