<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="20" cy="20" r="20" fill="#FFF8D9"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.423 18.6301C26.5728 18.6072 26.7241 18.5955 26.8756 18.595L26.8752 18.5947C28.601
        18.5947 30 20.0299 30 21.8003C30 23.3635 28.9092 24.6654 27.4659 24.9487C27.3805 24.9857
        27.2866 25.0062 27.1881 25.0062H23.6615C23.267 25.0062 22.9472 24.6781 22.9472
        24.2735C22.9472 23.8688 23.267 23.5408 23.6615 23.5408H26.8547C26.8615 23.5406 26.8683
        23.5405 26.8752 23.5405C27.5938 23.5408 28.2347 23.0765 28.4747 22.3816C28.7148 21.6867
        28.502 20.9121 27.9436 20.448C27.3851 19.984 26.6022 19.9313 25.9895 20.3165C25.653 20.5271
        25.2139 20.4194 25.0067 20.0754L25.0049 20.0704C24.8997 19.9081 24.8622 19.7095 24.9007
        19.5187C25.4284 16.8597 23.8087 14.2494 21.237 13.6144C18.6652 12.9794 16.0651 14.5476
        15.3555 17.1617C15.3444 17.1883 15.3317 17.2141 15.3174 17.239C15.2856 17.3283 15.234
        17.4089 15.1668 17.4743C15.1354 17.5078 15.1008 17.5382 15.0637 17.5649C15.0262 17.5902
        14.9865 17.6118 14.945 17.6293C14.8608 17.6696 14.7686 17.6895 14.6757 17.6873C14.6479
        17.6919 14.6199 17.6946 14.5917 17.6956C14.4905 17.6846 14.3887 17.6791 14.2869
        17.6791C12.709 17.6791 11.4299 18.9913 11.4299 20.6099C11.4299 22.2286 12.709 23.5408
        14.2869 23.5408H16.4297C16.8241 23.5408 17.1439 23.8688 17.1439 24.2735C17.1439 24.6781
        16.8241 25.0062 16.4297 25.0062H14.2869C11.9457 25.0083 10.0367 23.0816 10.0005
        20.6802C9.96438 18.2787 11.8146 16.2925 14.1547 16.2205C15.2306 13.2767 18.2055 11.5441
        21.221 12.1049C24.2366 12.6658 26.4292 15.3595 26.4292 18.5034C26.4292 18.5263 26.4276
        18.549 26.426 18.5717C26.4247 18.5912 26.4233 18.6106 26.423 18.6301Z"
      fill="black"
    />
    <path
      d="M22.4294 21.1414L20.7152 19.8225V27.1496C20.7152 27.5543 20.3955 27.8823 20.001
        27.8823C19.6065 27.8823 19.2867 27.5543 19.2867 27.1496V19.8225L17.5725 21.1414C17.3688
        21.3053 17.0946 21.3433 16.8558 21.2407C16.617 21.138 16.451 20.9109 16.422 20.647C16.393
        20.383 16.5055 20.1237 16.716 19.9691L19.573 17.7709C19.8267 17.5754 20.1759 17.5754
        20.4295 17.7709L23.2865 19.9691C23.4971 20.1237 23.6096 20.383 23.5805 20.647C23.5515
        20.9109 23.3856 21.138 23.1468 21.2407C22.908 21.3433 22.6337 21.3053 22.43
        21.1414H22.4294Z"
      fill="black"
    />
  </svg>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UploadFile extends Vue {
  @Prop({ required: false, default: 40 }) width!: number | string;

  @Prop({ required: false, default: 40 }) height!: number | string;
}
</script>
