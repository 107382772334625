export default {
  namespaced: true,
  state: () => ({
    approvableOrders: [],
    isApproveDialogShow: false,
  }),
  getters: {
    isApproveDialogShow: state => state.isApproveDialogShow,
  },
  mutations: {
    setIsApproveDialogShow (state, isApproveDialogShow) {
      state.isApproveDialogShow = isApproveDialogShow
    },
    setOrders (state, orders) {
      state.approvableOrders = orders
    },
  },
}
