<template>
  <v-row
    no-gutters
    class="flex-column f15 gray2"
    align="start"
    justify="space-between"
  >
    <v-col cols="12" class="black1 lato-bold">
      {{ contactFullName }}
    </v-col>
    <v-col cols="12">
      {{ contactAddress }}
    </v-col>
    <v-col cols="12">
      {{ contactInfo.telephone | phoneFilter }}
    </v-col>
  </v-row>
</template>

<script>
import phoneFilter from '@corporategift/design-system/filters/phoneFilter';

export default {
  name: 'ContactInfoCard',
  filters: { phoneFilter },
  props: {
    contactInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    contactFullName () {
      const { firstname, lastname, company } = this.contactInfo
      let result = [firstname, lastname]?.join(' ')
      if (company) result += ` (${company})`
      return result
    },
    contactAddress () {
      const { street, city, region, postcode } = this.contactInfo

      let result = ''
      if (street) result += `${street}; `
      if (city) result += `${city}, `
      if (region) result += `${region} `
      if (postcode) result += `${postcode}`

      return result
    }
  }
}
</script>
