<template>
  <add-ach-form @onAddAch="onSuccess" />
</template>

<script>
import AddAchForm from '@/components/payment/AddAchForm.vue'

export default {
  name: 'AddNewAch',
  components: { AddAchForm },
  methods: {
    onSuccess () {
      this.$emit('onFetchData');
      this.$emit('update:step', 4);
    },
  },
}
</script>
