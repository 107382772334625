export default () => ({
  orders: [],
  currentPage: 1,
  loadingOrders: false,
  meta: {
    currentPage: null,
    from: null,
    lastPage: null,
    perPage: null,
    to: null,
    total: null,
  },
  links: {
    first: null,
    last: null,
    next: null,
    prev: null,
  },
  searchSku: null,
  filters: {
    search: null,
    dateRange: {
      start: null,
      end: null,
    },
    orderType: null,
    orderStatus: null,
    orderUser: null,
    orderSource: null,
    productId: null, // sku
  },
  filtersItems: {
    types: [],
    statuses: [],
    users: [],
    sources: [],
  },
})