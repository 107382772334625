<template>
  <div class="campaign-automation-no-hr-connection">
    <div class="campaign-automation-no-hr-connection__info-container">
      <div class="campaign-automation-no-hr-connection__info-alert">
        You don't have an HR integration set up for this account.
        <a v-if="userSalesManagerEmail" :href="`mailto:${userSalesManagerEmail}`">Contact your CSM</a>
        <span v-else @click="openHubspotChat">Contact us</span>
        to set up your integration.
      </div>

      <span>
        Some of the supported integrations are:
      </span>
    </div>

    <div class="campaign-automation-no-hr-connection__items">
      <div
        class="campaign-automation-no-hr-connection__item"
        v-for="item in items"
        :key="item.id"
      >
        <img
          alt=""
          :src="item.url"
          :title="item.name"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { hrAwareness } from '@/assets/data/campaigns'

export default {
  name: 'AutomationCampaignHrAwareness',
  data: () => ({
    items: hrAwareness,
  }),
  computed: {
    customerSuccessManagerInfo () {
      return this.$store.state.header?.headerData?.customerSuccessManager ?? null
    },
    userSalesManagerEmail () {
      return this.customerSuccessManagerInfo?.email ?? null
    },
  },
  methods: {
    openHubspotChat () {
      window?.HubSpotConversations?.widget?.open();  
    }
  },
}
</script>

<style lang="scss" scoped>
.campaign-automation-no-hr-connection {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > span {
      font-size: 16px;
      line-height: normal;
      color: #222325;
      font-weight: 400;
    }
  }

  &__info-alert {
    line-height: normal;
    color: #222325;
    gap: 16px;
    font-weight: 700;
    font-size: 16px;

    & > a,
    & > span {
      color: #6E50FF;
      cursor: pointer;
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__item {
    display: flex;
    flex: 0 1 calc(50% - 8px);
    padding: 24px 32px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: #62646A;

    & > img {
      object-fit: contain;
      height: 48px;
      max-width: 100%;
    }
  }
}
</style>
