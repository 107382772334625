import { render, staticRenderFns } from "./RoundFilledChevron.vue?vue&type=template&id=4de39779&scoped=true"
import script from "./RoundFilledChevron.vue?vue&type=script&lang=js"
export * from "./RoundFilledChevron.vue?vue&type=script&lang=js"
import style0 from "./RoundFilledChevron.vue?vue&type=style&index=0&id=4de39779&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de39779",
  null
  
)

export default component.exports