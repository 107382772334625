<template>
<!-- TODO refactor -->
  <div>
    <div class="font-weight-bold d-flex">
      <span>Users</span>

      <common-badge class="ml-3">
        {{ users.length }}
      </common-badge>
    </div>
    <div class="d-flex align-center" :class="{ 'mb-5': !users.length }">
      <div class="pointer ml-auto d-flex align-center add-user__wrapper">
        <v-menu
          content-class="customers-combobox pb-1"
          transition="slide-x-transition"
          bottom
          right
          max-height="500"
        >
          <template>
            <div class="px-4 full-width" @click.stop>
              <v-text-field
                class="customers-search rounded-0 mt-4"
                v-model="searchCustomers"
                full-width
                solo
                flat
                dense
                hide-details
                tile
                prepend-inner-icon="mdi-close"
                height="30"
                @input="searchCustomer"
              >
                <template #prepend-inner>
                  <v-img
                    src="/images/search-icon.png"
                    width="13"
                    height="13"
                  />
                </template>
              </v-text-field>
              <div class="body-2 green3 pointer pt-3" @click="selectAllClickHandler">
                {{ allUsersAreSelected() ? 'Unselect': 'Select' }} all
              </div>
            </div>
          </template>
          <template #activator="{ on, attrs }">
            <div
              class="d-flex pointer"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                src="images/add-contact-icon.svg"
                height="20"
                max-width="20"
              />
              <span class="f14 green2 ml-2">Add users manually</span>
            </div>
          </template>

          <v-list dense>
            <v-list-item
              v-for="(customer, i) in customerItems"
              :key="i"
              @click.stop="customerClickHandler(customer)"
              class="px-2"
            >
              <v-list-item-icon class="mr-2 my-1" style="height: 28px">
                <v-img
                  v-if="customer.check"
                  class="mr-3"
                  src="images/selected-icon.svg"
                  width="28"
                  min-width="28"
                  height="28"
                />
                <v-img
                  v-else
                  class="mr-3"
                  src="images/empty-customer-icon.svg"
                  width="28"
                  min-width="28"
                  height="28"
                />
              </v-list-item-icon>
              <v-list-item-title class="option__title f14">
                {{ customer.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-menu
        content-class="customers-combobox pb-0"
        transition="slide-x-transition"
        bottom
        right
        :close-on-content-click="isCloseDuplicateMenuByClick"
      >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="ml-7 pointer d-flex align-center"
            :style="users.length ? 'display: none !important;' : ''"
          >
            <v-img
              src="images/new-group-icon.png"
              height="17"
              max-width="17"
            />
            <span class="f14 green2 ml-2 text-no-wrap">Duplicate budget entity users list</span>
          </div>
        </template>

        <template>
          <v-list class="approvement-list py-4">
            <v-list-item-group v-model="currentImportBtn">
              <v-list-item
                v-for="(entity, i) in budgetEntities.filter(be => be.accounts.length)"
                :key="i"
                class="px-2 py-2 duplicate-item"
                :class="{
                  'pb-0': i === budgetEntities.length - 1,
                  'pt-0': i === 0,
                  'active': i === currentImportBtn,
                  'approvement-list__item': currentImportBtn !== undefined
                }"
                style="min-height: 1px;"
              >
                <v-list-item-title class="option__title f14 black1">
                  <div class="d-flex justify-space-between align-center" v-if="currentImportBtn === i">
                    <span class="lato-bold">Import {{ entity.accounts.length }} sub accounts?</span>
                    <v-btn @click.stop="currentImportBtn = undefined" class="ml-auto mr-2" width="18" height="18" icon>
                      <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                        <defs/>
                        <g fill="none" fill-rule="evenodd" stroke="#919191" stroke-width="1.636">
                          <path d="M.001 14L14 0M14 14L0 0"/>
                        </g>
                      </svg>
                    </v-btn>
                    <v-btn @click="duplicateUsers(entity)" icon width="18" height="18">
                      <v-img width="18" height="18" src="/images/check-circle-icon.svg"/>
                    </v-btn>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-space-between align-center f14"
                  >
                    <span class="black1 mr-4">{{ entity.name }} ({{ entity.accounts.length }})</span>
                    <span class="green2 pointer">Import</span>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>
      </v-menu>

      <the-table-download-invoice
        v-if="isEdit"
        :downloadMethod="downloadCSV"
      />
    </div>
    <div class="mt-2 pt-2" :class="{ 'my-7': !isEdit }">
      <v-alert
        v-if="isSettingsShow"
        class="d-flex align-center pa-3 customers-alert"
        dense
        color="#DFF3F2"
      >
        <div class="d-flex align-center f14">
          <common-select
            v-model="limit"
            :items="spendLimitOptions"
            placeholder="Select action"
            height="35"
            style="width: 100%; max-width: 220px"
          />
          <template v-if="limit === 1">
            <div class="d-flex align-center">
              <common-input
                v-model="commonSpendLimit"
                type="number"
                prefix="$"
                style="max-width: 75px;"
                class="ml-2"
                :height="35"
              />

              <span class="ml-4 mr-2">Set period:</span>
              <common-select
                v-model="commonPeriod"
                :items="period"
                placeholder="Period"
                style="width: 120px;"
                class="mr-2"
                :height="35"
              />

              <common-tooltip
                top
                :max-width="250"
                :z-index="8"
                :activator-size="20"
              >
                <div>
                  <p><span>Spend limit is based on calendar</span></p>
                  <dl class="d-flex align-center">
                    <dt class="f14 lato-bold font-weight-bold miw90 mr-1">Monthly:</dt>
                    <dd class="f14">01 - 31</dd>
                  </dl>
                  <dl class="d-flex align-center">
                    <dt class="f14 lato-bold font-weight-bold miw90 mr-1">Yearly:</dt>
                    <dd class="f14">Jan 01 - Dec 31</dd>
                  </dl>
                </div>
              </common-tooltip>
            </div>
          </template>

          <div v-if="[1, 0].includes(limit)" class="d-flex flex-row g10 ml-auto">
            <common-button
              outlined
              style="background-color: #fff"
              @click="cancelBulkEdit"
            >
              Cancel
            </common-button>
            <common-button
              @click="setAllLimit()"
              :disabled="limit === null"
              style="width: 60px;"
            >
              Set
            </common-button>
          </div>

          <template v-if="[2, 3].includes(limit)">
            <common-input
              v-model.number="bulkLimitEdit"
              type="number"
              prefix="$"
              class="ml-2"
              min="1"
              :height="35"
              style="max-width: 75px;"
              oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
            />
            
            <div class="d-flex flex-row g10 ml-auto">
              <common-button
                outlined
                style="background-color: #fff"
                @click="cancelBulkEdit"
              >
                Cancel
              </common-button>
              <common-button @click="bulkUpdate">Set</common-button>
            </div>
          </template>
        </div>
        <span class="red1" v-if="editableCustomerErrors.find(e => e.panel)">{{
            editableCustomerErrors.find(e => e.panel).message
          }}</span>
      </v-alert>
      <v-data-table
        v-show="users.length"
        v-model="tableSelectedItems"
        class="customers-table mb-15"
        :class="{ 'customers-table__first-child': !!users.length }"
        :headers="headers"
        :items="users"
        :show-select="!!users.length"
        :items-per-page="users.length"
        hide-default-footer
      >
        <template #item.name="{ item }">
          <div class="f14 lato-bold d-flex">
            {{ item.name }}
          </div>
        </template>
        <template #item.limit="{ item }">
          <div class="pos-r">
            <v-text-field
              v-if="editableCustomer.id === item.id"
              v-model.number="editableCustomer.limit"
              class="rounded-0 input-icon"
              type="number"
              min="0"
              hide-details
              color="black"
              solo
              flat
              dense
              tile
              prefix="$"
              height="30"
              full-width
            />
            <span class="f14 black1" v-else-if="item.limit">
              {{
                item.limit | priceFilter({
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0
                })
              }}
            </span>
            <span
              v-if="(editableCustomerErrors.find(e => e.id === item.id) && editableCustomerErrors.find(e => e.id === item.id).type === 'limit')
                    || (editableCustomerErrors.find(e => e.id === item.id) && !editableCustomerErrors.find(e => e.id === item.id).type)
              "
              class="red1 customers-errors"
            >
              {{ editableCustomerErrors.find(e => e.id === item.id).message }}
            </span>
          </div>
        </template>
        <template #item.period="{ item }">
          <div class="pos-r">
            <v-select
              v-if="editableCustomer.id === item.id"
              v-model="editableCustomer.period"
              class="rounded-0 f14 customers-select__period"
              :items="period"
              hide-details
              full-width
              label="Period"
              dense
              outlined
              solo
              flat
              tile
              height="30"
            />
            <span
              v-else-if="item.period"
              class="f14 black1 d-flex flex-row g10 align-center"
            >
              {{ getCustomerPeriod(item) }}

              <common-tooltip
                v-if="item.period_changed_at"
                :z-index="8"
                :activator-size="20"
                activator-color="#62646A"
              >
                <div class="d-flex flex-column align-center">
                  Spend limit updated on:
                  <b>{{ getPeriodTooltip(item) }}</b>
                </div>
              </common-tooltip>
            </span>
            <span
              v-if="
                editableCustomerErrors.find(e => e.id === item.id) &&
                editableCustomerErrors.find(e => e.id === item.id).type === 'period'
              "
              class="red1 customers-errors"
            >
              {{ editableCustomerErrors.find(e => e.id === item.id).message }}
            </span>
          </div>
        </template>
        <template #item.balance="{ item }">
          <span class="f14 black1" v-if="editableCustomer.id !== item.id">
            <span v-if="item.limit && item.period">
              {{
                (item.balance || 0) | priceFilter({
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0
                })
              }}
            </span>
          </span>
        </template>
        <template #item.details="{ item }">
          <div class="d-flex flex-column">
            <div class="f14 font-weight-bold d-flex">
              <span class="">{{ item.fullName.firstName }}</span><span
              class="ml-1">{{ item.fullName.lastName }}</span>
            </div>
            <span class="">{{ item.emailAddress }}</span>
          </div>
        </template>
        <template #item.control="{ item }">
          <div class="d-flex align-center justify-end" v-if="editableCustomer.id !== item.id">
            <v-img
              class="mr-5"
              @click="editUser(item)"
              src="images/action_pen.png"
              height="12"
              max-width="10"
            />
            <v-img
              @click="deleteUser(item)"
              src="images/delete-icon.svg"
              height="17"
              max-width="14"
            />
          </div>
          <div v-else class="d-flex align-center justify-end">
            <span class="f14 green2 pointer customers-cancel" @click="saveEditingCustomer(item)">set</span>
            <v-divider class="mx-2" vertical/>
            <span class="f14 green2 pointer customers-cancel" @click="cancelEditingCustomer(item)">
              {{ !item.limit ? 'cancel' : 'remove limit' }}
            </span>
          </div>
        </template>
      </v-data-table>
    </div>

    <v-dialog
      v-model="showSpendLimitModal"
      persistent
      content-class="elevation-0"
      width="500"
    >
      <div class="spend-limit-update-modal__wrapper">
        <div class="spend-limit-update-modal__content">
          <div class="spend-limit-update-modal__title">Spend limit updated</div>

          <div class="spend-limit-update-modal__text">
            Pay attention that when spend limit is updated - the user’s <b>balance</b> is reset according to the new spend <b>limit</b>.
            <br><br>
            Start date of the new spend limit is shown next to the selected period in tooltip.
          </div>

          <cg-checkbox v-model="hideSpendLimitModal">Don't show this modal again</cg-checkbox>

          <div class="spend-limit-update-modal__actions">
            <cg-button @click="showSpendLimitModal = false">Close</cg-button>
          </div>
        </div>

        <v-icon color="white" class="pointer" @click="showSpendLimitModal = false">
          mdi-close
        </v-icon>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/axios/api'
import { cloneDeep, toNumber, isEmpty } from 'lodash'
import { createNamespacedHelpers } from 'vuex'

import { customersHeaders } from '@/assets/headers'
import { isEdit, selectedEntity, budgetEntities } from '../mixins'

import { CgButton, CgCheckbox } from '@corporategift/design-system'
import TheTableDownloadInvoice from '../TheTableDownloadInvoice.vue'

const { mapGetters, mapMutations } = createNamespacedHelpers('budgetEntities')

export default {
  name: 'Customers',
  mixins: [
    isEdit,
    selectedEntity,
    budgetEntities
  ],
  components: {
    CgButton,
    CgCheckbox,
    TheTableDownloadInvoice,
  },
  props: {
    // TODO remove showPanel
    showPanel: Boolean,
    accounts: {
      type: Array,
      required: true
    },
    budgetEntityId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: () => ({
    hideSpendLimitModal: false,
    showSpendLimitModal: false,
    currentImportBtn: undefined,
    isCloseDuplicateMenuByClick: false,
    selectedCustomers: [], // change this value and Watch will change store selectedEntity
    headers: customersHeaders,
    limit: null,
    commonSpendLimit: null,
    commonPeriod: '',
    searchCustomers: '',
    customerItems: [],
    bulkLimitEdit: null,
    period: [
      {
        text: 'Monthly',
        value: 'monthly'
      },
      {
        text: 'Annually',
        value: 'annually'
      },
      {
        text: 'No time limit',
        value: 'no_time_limit',
      }
    ],
    tableSelectedItems: [],
    editableCustomer: {},
    editableCustomerErrors: []
  }),
  watch: {
    selectedCustomers (val) {
      const customers = val?.map((item) => {
        return item
      })
      this.$emit('setAccounts', customers)
    },
    accounts: {
      handler: function (val) {
        if (!this.customerItems.length) {
          this.customerItems = cloneDeep(this.accounts)
        }
      },
      immediate: true,
      deep: true
    },
    isEdit () {
      this.editableCustomer = {} // clear editable item
      this.tableSelectedItems = [] // clear table selected items
    },
    limit (val) {
      if (!val) {
        this.commonPeriod = ''
        this.commonSpendLimit = null
        this.editableCustomerErrors = this.editableCustomerErrors.filter(e => !e.panel)
      }
    },
    errors (val) {
      for (const key in val) {
        const customerData = this.getCustomerByIndex(key)
        const customer = customerData.customer
        const type = customerData.type

        if (!isEmpty(customer)) {
          const errors = [...this.editableCustomerErrors]
          const customerError = errors.find(e => e.id === customer.id)

          if (customerError) {
            customerError.message = val[key][0]
            this.editableCustomerErrors = errors
          } else {
            this.editableCustomerErrors.push({
              id: customer.id,
              message: val[key][0],
              type
            })
          }
        }
      }
    },
    hideSpendLimitModal: function (val) {
      if (val) {
        sessionStorage.setItem('hideSpendLimitModal', true)
      } else {
        sessionStorage.removeItem('hideSpendLimitModal')
      }
    }
  },
  computed: {
    ...mapGetters(['errors']),
    users () {
      return this.selectedEntity.accounts
    },
    isSettingsShow () {
      return this.tableSelectedItems.length
    },
    allSelectedUsersHasSpendLimit () {
      const { users, tableSelectedItems } = this

      if (!tableSelectedItems.length) { return false }
      const ids = tableSelectedItems.map((item) => item.id)

      return users
        .filter((user) => ids.includes(user.id))
        .every((user) => user.limit)
    },
    spendLimitOptions () {
      const { allSelectedUsersHasSpendLimit } = this

      return [
        {
          text: 'Remove spend limit',
          value: 0,
        },
        {
          text: 'Set spend limit',
          value: 1,
        },
        {
          text: 'Increase limit by',
          value: 2,
          disabled: !allSelectedUsersHasSpendLimit,
        },
        {
          text: 'Decrease limit by',
          value: 3,
          disabled: !allSelectedUsersHasSpendLimit,
        },
      ]
    },
  },
  created () {
    this.limit = null
    this.tableSelectedItems = []
    if (this.showPanel) {
      this.selectedCustomers = cloneDeep(this.selectedEntity.accounts)

      if (!this.customerItems.length) {
        this.customerItems = cloneDeep(this.accounts)
      }

      this.updateCustomerItems()
    } else {
      this.selectedCustomers = []
      this.customerItems = []
    }
  },
  methods: {
    ...mapMutations(['setErrors']),
    getCustomerByIndex (key) {
      const keySplit = key.split('.')
      if (keySplit[1]) { // if second element exist
        const index = toNumber(keySplit[1])
        const type = toNumber(keySplit[keySplit.length - 1])

        return {
          customer: this.users[index],
          type
        }
      }

      return {}
    },
    getCustomerPeriod ({ period }) {
      const { period: periodsData } = this
      const userPeriod = periodsData.find(({ value }) => value === period);

      return userPeriod?.text ?? null
    },
    getPeriodTooltip ({ period_changed_at }) {
      const dateFormatter = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long' })
      const currentDate = new Date(period_changed_at);

      return dateFormatter.format(currentDate);
    },
    informUserAboutSpendLimitUpdate () {
      const hideSpendLimitModal = sessionStorage.getItem('hideSpendLimitModal')
      if (!hideSpendLimitModal) { this.showSpendLimitModal = true }
    },
    addUser (user) {
      this.accounts.find(item => item === user).selected = !this.accounts.find(item => item === user).selected
    },
    deleteUser (item) {
      if (!this.tableSelectedItems.length) {
        const deletedItem = this.customerItems.find(e => e.id === item.id)
        delete deletedItem.check

        this.selectedCustomers = this.selectedCustomers.filter(customer => customer.id !== item.id)
        this.editableCustomerErrors = this.editableCustomerErrors.filter(e => e.id !== item.id)
      }
    },
    editUser (item) {
      if (!this.tableSelectedItems.length) {
        if (this.editableCustomer.id) {
          if (this.editableCustomer.limit === null || this.editableCustomer.period === null) {
            this.editableCustomer.limit = null
            this.editableCustomer.period = null
          }
          this.editableCustomerErrors = []
        }
        this.editableCustomer = structuredClone(item)
      }
    },
    setAllLimit () {
      if (this.limit) {
        let error = ''

        if (!toNumber(this.commonSpendLimit)) {
          error = 'Limit is required'
        }

        if (!this.commonPeriod) {
          error = 'Period is required'
        }

        if (!toNumber(this.commonSpendLimit) && !this.commonPeriod) {
          error = 'Limit and period are required'
        }

        if (!error) {
          this.setCommonSpendLimit()
        } else {
          const errors = [...this.editableCustomerErrors]
          const errIndex = errors.findIndex(e => e.panel)

          if (~errIndex) {
            errors[errIndex] = {
              panel: true,
              message: error
            }
            this.editableCustomerErrors = errors
          } else {
            this.editableCustomerErrors.push({
              panel: true,
              message: error
            })
          }
        }
      } else {
        this.setCommonSpendLimit()
      }
    },
    setCommonSpendLimit () {
      this.selectedEntity.accounts.forEach(account => {
        this.tableSelectedItems.forEach(item => {
          if (item.id === account.id) {
            account.limit = this.commonSpendLimit
            account.period = this.commonPeriod
            account.balance = this.commonSpendLimit
            account.period_changed_at = new Date()
          }
        })
      })

      this.editableCustomerErrors = this.editableCustomerErrors.filter(e => !e.panel)
      this.clearDataForSetMultipleLimits()
    },
    clearDataForSetMultipleLimits () {
      this.limit = null
      this.commonSpendLimit = null
      this.commonPeriod = null
      this.tableSelectedItems = []
    },
    searchCustomer () {
      this.searchCustomers.length
        ? this.customerItems = this.accounts.filter(selected => selected.name.toLowerCase().includes(this.searchCustomers.toLowerCase()))
        : this.customerItems = this.accounts
    },
    customerClickHandler (customer) {
      if (customer.check) {
        customer.check = false
        this.selectedCustomers = this.selectedCustomers.filter(c => customer.id !== c.id)
      } else {
        customer.check = true
        this.selectedCustomers.push(customer)
      }
    },
    selectAllClickHandler () {
      const { customerItems, selectedCustomers, allUsersAreSelected } = this

      if (allUsersAreSelected()) {
        customerItems.forEach(customer => (customer.check = false))
        selectedCustomers.splice(0, selectedCustomers.length)
      } else {
        customerItems.forEach(customer => {
          customer.check = true
          if (!selectedCustomers.some(selected => selected.id === customer.id)) selectedCustomers.push(customer)
        })
      }
    },
    allUsersAreSelected () {
      const { selectedCustomers: { length: selectedLength }, customerItems: { length: customersLength } } = this

      return selectedLength === customersLength
    },
    duplicateUsers (be) {
      this.isCloseDuplicateMenuByClick = true
      this.selectedCustomers = be.accounts
      setTimeout(() => {
        this.isCloseDuplicateMenuByClick = false
      })
      this.updateCustomerItems()
    },
    saveEditingCustomer (item) {
      const validateSpendLimit = this.validateSpendLimit(this.editableCustomer)

      if (!Object.keys(validateSpendLimit).length) { // if limit and period are valid
        this.setSpendLimit(item)
      } else {
        const errors = [...this.editableCustomerErrors]
        const errIndex = errors.findIndex(e => item.id === e.id)

        if (~errIndex) {
          errors[errIndex] = validateSpendLimit
          this.editableCustomerErrors = errors
        } else {
          this.editableCustomerErrors.push(validateSpendLimit)
        }
      }
    },
    setSpendLimit (item) {
      const accIndex = this.selectedCustomers.findIndex(acc => acc.id === item.id)
      const accounts = structuredClone(this.selectedCustomers)

      const newPeriod = this.editableCustomer.period
      const currentPeriod = this.selectedCustomers[accIndex]?.period

      const showSpendLimitInfo = currentPeriod && newPeriod && newPeriod !== currentPeriod

      accounts[accIndex] = {
        ...this.editableCustomer,
        balance: this.editableCustomer.limit,
        period_changed_at: this.editableCustomer?.period_changed_at || new Date(),
      }
      this.selectedCustomers = accounts
      this.editableCustomer = {}

      if (showSpendLimitInfo) { this.informUserAboutSpendLimitUpdate() }
      this.editableCustomerErrors = this.editableCustomerErrors.filter(e => e.id !== item.id)
    },
    cancelEditingCustomer (customer) {
      this.editableCustomer.limit = null
      this.editableCustomer.period = null
      this.editableCustomer.balance = null
      this.setSpendLimit(customer)

      this.editableCustomerErrors = this.editableCustomerErrors.filter(e => e.id !== customer.id)
    },
    updateCustomerItems () {
      this.selectedCustomers.length && this.selectedCustomers.forEach(selectedAcc => {
        this.customerItems.forEach(acc => {
          if (selectedAcc.id === acc.id) {
            acc.check = true
          }
        })
      })
    },
    validateSpendLimit (customer) {
      if (!toNumber(customer.limit) && !customer.period) {
        return {
          id: customer.id,
          message: 'Limit and period are required fields'
        }
      }

      if (!toNumber(customer.limit)) {
        return {
          id: customer.id,
          message: 'Limit is required',
          type: 'limit'
        }
      }

      if (!customer.period) {
        return {
          id: customer.id,
          message: 'Period is required',
          type: 'period'
        }
      }

      return {}
    },
    bulkUpdate () {
      // 2 increase
      // 3 decrease
      const { tableSelectedItems, bulkLimitEdit, limit } = this

      if (!bulkLimitEdit) { return }

      this.selectedEntity.accounts.forEach(account => {
        tableSelectedItems.forEach(item => {
          if (item.id === account.id) {
            let newLimit = Number(account.limit)
            let newBalance = Number(account.balance)

            if (limit === 2) {
              newLimit += Number(bulkLimitEdit)
              newBalance += Number(bulkLimitEdit)
            } else {
              newLimit -= Number(bulkLimitEdit)
              newBalance -= Number(bulkLimitEdit)
            }

            account.limit = Math.max(newLimit, 0);
            account.balance = Math.max(newBalance, 0);

            if (!account.limit) {
              account.limit = null
              account.period = null
              account.balance = null
            }
          }
        })
      })

      this.cancelBulkEdit();
    },
    cancelBulkEdit () {
      this.bulkLimitEdit = null
      this.clearDataForSetMultipleLimits()
    },
    downloadCSV () {
      return Api.get(`/credit/budget-entities/${this.budgetEntityId}/accounts/csv`).then((response) => {
        this.$store.commit('downloadCSV', response)
      })
    }
  }
}
</script>

<style lang="scss">
@import "src/css/customers";
</style>

<style lang="scss" scoped>
.spend-limit-update-modal {
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  &__content {
    gap: 20px;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000;
  }

  &__text {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000;

    & > b {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
    }
  }

  &__actions {
    gap: 12px;
    display: flex;
    flex-direction: row;

    & > button,
    & > a {
      border-radius: 100px;
    }
  }
}
</style>
