<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    viewBox="0 0 12 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0166 19.7874C10.4475 20.3565 9.5249 20.3565 8.95586 19.7874L0.698751 11.5303C0.129686
        10.9613 0.129686 10.0386 0.698751 9.46959L8.95586 1.21243C9.5249 0.643383 10.4475 0.643383
        11.0166 1.21243C11.5856 1.78147 11.5856 2.7041 11.0166 3.27315L3.78977 10.5L11.0166
        17.7268C11.5856 18.2959 11.5856 19.2184 11.0166 19.7874Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsBack',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 12
    },
    height: {
      type: [Number, String],
      required: false,
      default: 21
    },
    color: {
      type: String,
      required: false,
      default: '#62646A'
    }
  }
}
</script>
