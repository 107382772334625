<template>
  <div class="my-quotes-table">
    <my-quotes-table-header />

    <template v-if="!loading">
      <template v-if="items.length">
        <my-quotes-table-row
          v-for="item in items"
          :key="item.id"
          :item="item"
          @showQuoteDetails="handleShowQuoteDetails"
        />
      </template>

      <template v-else>
        <div class="my-quotes-table__empty">
          <!-- TODO link:to -->
          You didn't get any quote yet. Contact your CSM if you need a quote
        </div>
      </template>
    </template>

    <common-loader
      v-else
      flex
      minHeightAuto
      relative
      style="padding: 100px 0"
    />

    <my-quotes-details-panel
      v-model="showQuoteDetailsPanel"
      :selected-quote-id="itemIdHelper"
      :selected-quote-item="selectedQuoteHelper"
      @updateQuoteItem="updateQuoteItem"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import type IQuoteItem from './types/IQuoteItem';
import type IQuoteItemDetails from './types/IQuoteItemDetails';
import type IQuotesResponse from './types/IQuotesResponse'

import Api from '@/axios/api'

import MyQuotesDetailsPanel from './panels/MyQuotesDetailsPanel.vue'
import MyQuotesTableHeader from './MyQuotesTableHeader.vue'
import MyQuotesTableRow from './MyQuotesTableRow.vue'

@Component({
  components: {
    MyQuotesDetailsPanel,
    MyQuotesTableHeader,
    MyQuotesTableRow,
  },
})
export default class MyQuotesTable extends Vue {
  loading = false;

  showQuoteDetailsPanel = false;

  itemIdHelper?: number | null = null;

  items: Array<IQuoteItem> = [];

  get selectedQuoteHelper (): IQuoteItemDetails | null {
    const { itemIdHelper, items } = this

    return items?.find((item) => item.id === itemIdHelper) ?? null
  }

  created () {
    this.fetchQuotes()
  }

  fetchQuotes (): void {
    this.loading = true;

    Api.get<undefined, IQuotesResponse>('/customer/quotes')
      .then(({ data }) => {
        this.items = data
          .sort((a, b) => new Date(b.generated_at).getTime() - new Date(a.generated_at).getTime());
      })
      .catch(() => {
        // alert
      })
      .finally(() => (this.loading = false))
  }

  handleShowQuoteDetails (itemId: number): void {
    this.itemIdHelper = itemId;
    this.showQuoteDetailsPanel = true;
  }

  updateQuoteItem (item: IQuoteItemDetails): void {
    const { id: idToUpdate } = item;
    const itemIndex = this.items.findIndex(({ id }) => id === idToUpdate);

    if (itemIndex !== -1) { this.$set(this.items, itemIndex, item) }
  }
}
</script>

<style lang="scss" scoped>
.my-quotes-table {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;

  &__load-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 120px;

    & > button {
      margin: 0 !important;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 30px;
    justify-content: center;
    height: 100px;
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }
}
</style>
