<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="11.8588" cy="12.3588" r="10.8588" :stroke="color" stroke-width="0.7" />
    <path d="M7.00023 12.2408L17.2594 12.2406" :stroke="color" stroke-width="1.09091" />
    <path d="M12.1296 17.3699V7.1109" :stroke="color" stroke-width="1.09091" />
  </svg>
</template>

<script>
export default {
  name: 'PlusCircle',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 25
    },
    color: {
      type: String,
      required: false,
      default: '#42B77A'
    }
  }
}
</script>
