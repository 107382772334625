<template>
  <div class="aggregated-card">
    <div class="aggregated-card__title">
      Campaign/s:

      <dashboard-card-pagination
        v-model="currentPage"
        :max-length="totalPages"
        v-if="totalPages > 1"
      />
    </div>

    <div class="aggregated-card__divider" />

    <div class="aggregated-card__content" style="gap: 20px">
      <div v-for="item in itemsToDisplay" :key="item.id" class="aggregated-campaigns__item">
        <icons-campaigns :width="18" :height="22" style="min-width: 18px" />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import aggregatedViewWithPages from './mixins/aggregatedViewWithPages'

export default {
  name: 'TheAggregatedViewCampaigns',
  mixins: [aggregatedViewWithPages],
  data: () =>({
    itemsPerPage: 4,
  }),
}
</script>

<style lang="scss" scoped>
@import "../styles/aggregated-card.scss";

.aggregated-campaigns {
  &__item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    line-height: 35px;
    align-items: center;
  }
}
</style>
