<template>
  <div class="the-account-info dashboard-card dashboard-card--small order-1">
    <template v-if="!loading">
      <div class="the-account-info__title">
        {{ message ? message.title : 'Account overview' }}

        <dashboard-card-tooltip v-if="tooltipTextSlug" offset="0, 5px">
          {{ getTooltipTextBySlug(tooltipTextSlug) }}
        </dashboard-card-tooltip>
      </div>

      <template v-if="message">
        <v-divider />

        <span
          class="the-account-info-custom-message__content"
          v-html="message.content"
        />
      </template>

      <template v-else>
        <v-divider v-if="paymentInfo.cardType" />

        <div class="the-account-info__card" v-if="paymentInfo.cardType">
          <div class="info-card__image">
            <img
              :alt="paymentInfo.cardType"
              :src="`/images/credit-cards/${paymentInfo.cardType.toLowerCase()}.svg`"
            />
          </div>
          <div class="info-card__content">
            <span>{{ paymentInfo.cardType }}</span>
            <span>XXXX-XXXX-XXXX-{{ paymentInfo.cardLastFourDigits }}</span>
            <span v-if="paymentInfo.expirationMonth && paymentInfo.expirationYear">
              Exp: {{ paymentInfo.expirationMonth }}/{{ paymentInfo.expirationYear.slice(2, 4) }}
            </span>
          </div>
          <div class="info-card__actions">
            <router-link :to="{ name: 'Payment' }">Edit</router-link>
          </div>
        </div>

        <v-divider />

        <div class="the-account-info__card">
          <div class="info-card__image">
            <icons-contacts :width="27" :height="28" />
          </div>
          <div class="info-card__content">
            <b>Saved contacts</b>

            {{ savedContactsNumber }}  contacts
          </div>
          <div class="info-card__actions">
            <router-link :to="{ name: 'Contacts' }">Edit</router-link>
          </div>
        </div>

        <v-divider />

        <div class="the-account-info__card" v-if="personalApprover">
          <div class="info-card__image">
            <icons-approver />
          </div>
          <div class="info-card__content">
            <b>Personal approver</b>

            {{ personalApprover }}
          </div>
        </div>

        <div class="the-account-info__card" v-if="!personalApprover && displayName">
          <div class="info-card__image">
            <icons-approver />
          </div>
          <div class="info-card__content">
            <b>Display name</b>

            {{ displayName }}
          </div>
        </div>
      </template>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import DashboardCardTooltip from './common/DashboardCardTooltip.vue'

export default {
  name: 'TheAccountInfo',
  components: { DashboardCardTooltip },
  mixins: [getTooltipTextBySlug],
  data: () => ({
    loading: false,
    accountType: null,
    paymentInfo: {
      binCategory: 'CONSUMER',
      cardLastFourDigits: null,
      cardType: null,
      expirationMonth: null,
      expirationYear: null,
    },
    message: null,
    savedContactsNumber: 0,
    personalApprover: null,
    displayName: null
  }),
  computed: {
    tooltipTextSlug () {
      const { accountType } = this

      switch (accountType) {
        case 'Account Owner':
          return null;
        case 'Account Admin':
          return null;
        case 'Account User':
          return null;
        case 'Marketplace User':
          return 'dashboard_account_overview--marketplace-user';
        default:
          return null;
      }
    }
  },
  created () {
    this.loading = true
    Api.get('/customer/dashboard/account-overview')
      .then(({ data }) => {
        this.accountType = data.type
        this.savedContactsNumber = data.contacts_count
        this.message = data.message ?? null
        // Payment
        const creditCardInfo = data.default_cc?.split('-') ?? []
        this.paymentInfo.cardType = creditCardInfo[0]
        this.paymentInfo.cardLastFourDigits = creditCardInfo[1]
        this.personalApprover = data?.approver_name ?? null
        this.displayName = data?.display_name ?? null
      })
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.the-account-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > hr {
    border-color: #D3D2D2;
  }

  & a {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #42B77A !important;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: center;
    color: #95979D;
    text-transform: uppercase;
  }

  &__card {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
    height: 62px;

    & > .info-card {
      &__image {
        display: flex;
        align-items: center;
        max-width: 45px;
        width: 45px;
        height: 100%;

        & > img {
          object-fit: contain;
          width: 45px;
          max-width: 45px;
        }
      }

      &__content {
        font-family: 'Lato-Regular', sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        gap: 4px;
        color: #62646A;
        font-size: 15px;
        line-height: 22px;

        & > b {
          font-family: 'Lato-Bold', sans-serif;
          color: #000000;
        }

        & span:nth-child(1) {
          text-transform: lowercase;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      &__actions {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
        justify-content: flex-end
      }
    }
  }

  &-custom-message {
    &__content {
      max-height: 100%;
      overflow-y: auto;
      padding-right: 10px;
      font-size: 15px;
      font-family: 'Lato', 'Lato-Regular', sans-serif;
      font-weight: 400;
      line-height: 25px;
      color: #62646A;

      &::v-deep a {
        color: #42B77A !important;
      }
    }
  }
}
</style>
