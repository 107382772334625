<template>
  <div class="gift-options-card">
    <product-image
      :image="item.product.image"
      :name="item.product.name"
      :is-out-of-stock="item.product.qty.is_out_of_stock"
      :out-of-stock-uppercase="false"
      :is-from-inventory="productIsFromMyInventory"
      medium
      :height="230"
      :max-height="230"
      hide-border
    />

    <div class="gift-options-card__content">
      <div class="gift-options-card__content__title">
        {{ item.product.name }}
      </div>
      <a
        :href="item.link + '?7453392592e3d0ef82bb13df1bc6ee42=6fa5cd3fa3ba88633e7c796e5719f1d6'"
        v-if="item.link"
        target="_blank"
      >
        More info
      </a>
    </div>

    <div class="gift-options-card__price">
      {{ item.product.price | priceFilter }}
    </div>
  </div>
</template>

<script>
import ProductImage from './ProductImage.vue'

export default {
  name: 'TheGiftOptionsPanelProduct',
  components: {
    ProductImage
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isPrepaid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    productIsFromMyInventory () {
      const { isPrepaid, item: { product } } = this

      return isPrepaid || !!product.inventory.qty
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-options-card {
  min-height: 333px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-bottom: 1px solid #E4E4E4;

  &__content {
    margin: 20px 0 0px;

    &__title {
      font-family: 'Lato-Bold', sans-serif;
      font-size: 16px;
      line-height: 18px;
      color: #000;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    a {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 18px;
      color: #219358 !important;

      &:hover {
        color: inherit;
      }
    }
  }

  &__price {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 16px;
    line-height: 18px;
    padding: 12px 0;
    margin-top: auto;
    color: #000;
  }
}
</style>
