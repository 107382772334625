<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 98 102"
    fill="none"
    :width="width"
    :height="height"
  >
    <g style="mix-blend-mode: multiply">
      <circle cx="60.125" cy="64.5" r="37" fill="#DFDDEE" />
      <circle cx="7.375" cy="43" r="6.5" fill="#DFDDEE" />
      <circle cx="22.625" cy="10" r="9.5" fill="#DFDDEE" />
    </g>
    <path
      d="M43.6669 59.5913H79.2226C79.7132 59.5908 80.1452 59.2567 80.2861 58.7693L86.9528
        35.7084C87.054 35.3594 86.9905 34.9815 86.7807 34.6886C86.5714 34.3962 86.2405 34.2238
        85.8893 34.2243H40.378L38.068 25.8365C37.9316 25.3418 37.4961 25.0005 37 25H28.1111C27.4975
        25 27 25.5163 27 26.153C27 26.7898 27.4975 27.3061 28.1111 27.3061H36.1622L44.4167
        57.2853H43.6667C40.8879 57.2853 38.3204 58.8239 36.9311 61.3209C35.5417 63.818 35.5417
        66.8952 36.9311 69.3922C38.3204 71.8893 40.8879 73.4279 43.6667 73.4279H45.3998C44.2351
        74.7575 43.6145 76.5051 43.6691 78.301C43.7242 80.097 44.4504 81.8003 45.6939
        83.0506C46.9375 84.301 48.6012 85 50.3328 85C52.064 85 53.7276 84.301 54.9712
        83.0506C56.2152 81.8003 56.9409 80.097 56.9959 78.301C57.0505 76.505 56.43 74.7574 55.2653
        73.4279H68.7333C67.3736 75.0113 66.7918 77.1563 67.1564 79.2431C67.5209 81.3304 68.7913
        83.1269 70.6003 84.1142C72.4093 85.1015 74.5556 85.1694 76.4193 84.298C78.2824 83.427
        79.6558 81.714 80.1419 79.6539C80.6285 77.5938 80.1737 75.416 78.9088 73.7496C77.6444
        72.0833 75.7108 71.1141 73.6667 71.1219H43.6666C41.682 71.1219 39.8477 70.0229 38.8555
        68.2393C37.863 66.4557 37.863 64.2576 38.8555 62.474C39.8476 60.6904 41.682 59.5914 43.6666
        59.5914L43.6669 59.5913ZM41.0122 36.5304H84.3965L78.3965 57.2852H46.7265L41.0122
        36.5304ZM73.6675 73.4279C74.8461 73.4279 75.9766 73.9138 76.8104 74.7786C77.6438 75.6439
        78.112 76.817 78.112 78.04C78.112 79.2631 77.6438 80.4363 76.8104 81.3015C75.9766 82.1663
        74.8461 82.6522 73.6675 82.6522C72.489 82.6522 71.3585 82.1663 70.5247 81.3015C69.6913
        80.4362 69.2231 79.2631 69.2231 78.04C69.2246 76.817 69.6933 75.6449 70.5262
        74.7802C71.3595 73.9159 72.489 73.4294 73.6675 73.4279ZM50.3341 73.4279C51.5127 73.4279
        52.6432 73.9138 53.477 74.7786C54.3103 75.6439 54.7786 76.817 54.7786 78.04C54.7786 79.2631
        54.3103 80.4363 53.477 81.3015C52.6431 82.1663 51.5126 82.6522 50.3341 82.6522C49.1556
        82.6522 48.025 82.1663 47.1912 81.3015C46.3579 80.4362 45.8896 79.2631 45.8896
        78.04C45.8911 76.817 46.3599 75.6449 47.1928 74.7802C48.0261 73.9159 49.1556 73.4294
        50.3341 73.4279Z
      "
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmptyCart',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 98
    },
    height: {
      type: [Number, String],
      required: false,
      default: 102
    }
  }
}
</script>
