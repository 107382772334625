<template>
  <v-autocomplete
    v-model="modelValue"
    :items="items"
    :search-input.sync="search"
    :item-value="itemValue"
    :item-text="itemText"
    v-bind="$attrs"
    v-on="$listeners"
    type="text"
    hide-details
    auto-select-first
    dense
    outlined
    solo
    flat
    :attach="!disableAttach && attachTo"
    color="black"
    item-color="black"
    no-data-text="No data"
    class="ma-0 thin-border rounded-0 common-select"
    :class="{
      'common-select--filter' : (filterStyle && modelValue)
    }"
    append-icon="mdi-chevron-down"
    @click.native="onTimeFrameSelectClick"
    ref="common-select"
    :height="height"
    :menu-props="{
      bottom: true,
      offsetY: true,
      left: true,
      tile: true,
      zIndex: menuZIndex,
      contentClass: contenctClass
    }"
  >
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot" />
      </template>
    </template>
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'CommonAutocomplete',
  props: {
    value: {
      type: [String, Number, Object, Array],
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    height: {
      type: [String, Number],
      required: false,
      default: 36
    },
    itemValue: {
      type: String,
      required: false,
      default: 'value'
    },
    itemText: {
      type: String,
      required: false,
      default: 'text'
    },
    menuZIndex: {
      type: Number,
      required: false,
      default: null
    },
    menuContentClass: {
      type: String,
      required: false,
      default: ''
    },
    closeOnInputClick: {
      type: Boolean,
      required: false,
      default: true
    },
    disableAttach: {
      type: Boolean,
      required: false,
      default: false
    },
    filterStyle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    attachTo: null,
    numberOfClicks: 0,
    search: null
  }),
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    contenctClass () {
      const { menuContentClass } = this
      return menuContentClass + ' common-select-items'
    }
  },
  // watch: {
  //   search: function (val) {
  //     if (val) {
  //       const { items, itemValue, itemText } = this
  //       const availableItems = items?.filter((item) => item[itemText]?.includes(val)) || []
  //       const firstItem = availableItems[0]
  //       if (firstItem) this.modelValue = firstItem[itemValue]
  //     }
  //   }
  // },
  mounted () {
    // attach v-menu to v-select element when element is mounted
    this.attachTo = this.$el
  },
  methods: {
    // when user click on select element and it is in an open state another click will close it
    onTimeFrameSelectClick () {
      const { closeOnInputClick } = this
      this.numberOfClicks += 1
      if (this.numberOfClicks === 2) {
        if (closeOnInputClick) this.$refs['common-select'].blur()
        this.numberOfClicks = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input {
  background-color: #fff;
}
</style>

<style lang="scss">
.common-select {
  .v-list-item__mask {
    color: #16181F !important;
    background: inherit !important;
  }
}
</style>
