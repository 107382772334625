<template>
  <div class="cg-balance-bar" :chart-name="entity.name">
    <v-tooltip
      top
      z-index="4"
      :disabled="hideTooltip || !entityHasAccountLimit"
      content-class="cg-balance-bar-tooltip v-tooltip__content--bottom"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="cg-balance-bar__wrapper"
          :style="`--chart-height: ${chartWrapperHeight}%;`"
          v-bind="attrs"
          v-on="on"
        >
          <div
            class="cg-balance-bar__bar"
            v-if="!entityHasAccountLimit"
            :available-credits="priceEntityBalance"
            :style="`
              --chart-color: ${entity.color};
              --chart-height: ${chartBalanceHeight}%;
              --text-color: ${entity.color};
            `"
          />

          <div
            class="cg-balance-bar__bar"
            v-if="entityHasAccountLimit"
            :style="`
              --chart-color: ${entity.color};
              --chart-height: ${chartAccountSpentHeight}%;
            `"
          />
          <div
            class="cg-balance-bar__bar"
            v-if="entityHasAccountLimit"
            :available-credits="priceAccountSpent"
            :account-limit="priceAccountLimit"
            :style="`
              --chart-color: ${entity.color}66;
              --chart-height: ${chartAccountLimitHeight}%;
              --text-color: ${entity.color};
            `"
          />
        </div>
      </template>

      <span>
        <v-row no-gutters>
          <v-col cols="12" class="content__title">{{ entity.name }}</v-col>
          <v-col cols="12" class="content__subtitle">
            {{ removeSpecialChars(entity.account_period) }} spend limit
          </v-col>
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="6" class="content__dot" :style="`--dot-color: ${entity.color}66`">
                Available:
              </v-col>
              <v-col cols="6" class="content__balance">{{ priceAccountAvailable }}</v-col>
              <v-col cols="6" class="content__dot" :style="`--dot-color: ${entity.color}`">
                Spent:
              </v-col>
              <v-col cols="6" class="content__balance">{{ priceAccountSpent }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TheCgCreditsBalanceBar',
  props: {
    entity: {
      type: Object,
      required: true
    },
    maxBalance: {
      type: Number,
      required: true
    },
    hideTooltip: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    entityHasAccountLimit () {
      return !!this.entity?.account_limit ?? false
    },
    entityBalance () {
      return this.entity?.balance ?? 0;
    },
    entityAccountLimit () {
      return this.entity?.account_limit ?? 0;
    },
    entityAccountSpent () {
      return this.entity?.account_spent ?? 0;
    },
    chartBalanceHeight () {
      const { maxBalance, entityBalance } = this
      return ((entityBalance || 0) * 100) / maxBalance
    },
    chartAccountLimitHeight () {
      const { maxBalance, entityAccountLimit } = this
      return ((entityAccountLimit || 0) * 100) / maxBalance
    },
    chartAccountSpentHeight () {
      const { maxBalance, entityAccountSpent } = this
      return Math.min(((entityAccountSpent || 0) * 100) / maxBalance, 100)
    },
    chartWrapperHeight () {
      const { entityHasAccountLimit, chartBalanceHeight, chartAccountLimitHeight } = this;
      return entityHasAccountLimit ? chartAccountLimitHeight : chartBalanceHeight;
    },
    priceEntityBalance () {
      return this.$options.filters.priceFilter(
        this.entityBalance,
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )
    },
    priceAccountLimit () {
      return this.$options.filters.priceFilter(
        this.entityAccountLimit,
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )
    },
    priceAccountAvailable () {
      const { entityAccountSpent, entityAccountLimit } = this
      const result = entityAccountLimit - entityAccountSpent ?? entityAccountLimit

      return this.$options.filters.priceFilter(
        Math.max(0, result),
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )
    },
    priceAccountSpent () {
      return this.$options.filters.priceFilter(
        this.entityAccountSpent,
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )
    }
  },
  methods: {
    removeSpecialChars (period) {
      return period?.replaceAll(/[^A-Za-z]/g, ' ')
    },
  },
}
</script>

<style lang="scss">
.cg-balance-bar-tooltip {
  background: #fff !important;
  border: none !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15) !important;
  padding: 16px;
  min-width: 170px;
  max-width: 180px;
  opacity: 1 !important;

  .content {
    &__title {
      font-family: 'Lato-Bold', 'Lato-Regular', sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #16181f;
      word-break: break-word;
      margin-bottom: 4px;
    }

    &__subtitle {
      font-family: 'Lato-Italic', sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #95979D;
      margin-bottom: 14px;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    &__dot {
      position: relative;
      font-family: 'Lato-Regular', sans-serif;
      padding-left: 16px !important;
      font-size: 13px;
      line-height: 16px;
      color: #979797;

      &::before {
        width: 10px;
        height: 10px;
        background: var(--dot-color);
        border-radius: 50%;
        content: ' ';
        position: absolute;
        transform: translate(0, 60%);
        left: 0;
        bottom: 60%;
      }
    }

    &__balance {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: #62646A;
    }
  }

  &::before, &::after {
    background-color: #fff !important;
  }
}
</style>

<style lang="scss" scoped>
.cg-balance-bar {
  position: relative;
  min-width: 87px;
  width: 87px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  &__wrapper {
    display: flex;
    position: relative;
    align-items: flex-end;
    max-height: 160px;
    height: var(--chart-height);
    width: 100%;
  }

  &__bar {
    flex: 1 1 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--chart-height);
    background: var(--chart-color);
    border-radius: 6px;

    &::before, &::after {
      position: absolute;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::after {
      top: -25px;
      font-family: 'Lato-Bold', sans-serif;
      color: var(--text-color);
      content: attr(available-credits);
    }

    &::before {
      top: -45px;
      font-family: 'Lato-Regular', sans-serif;
      color: #95979D;
      content: attr(account-limit);
    }
  }

  &::after {
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Lato-Regular', sans-serif;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::after {
    bottom: -20px;
    content: attr(chart-name);
    color: #919191;
  }
}
</style>
