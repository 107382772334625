<template>
  <div class="my-quotes-table-row">
    <div>{{ item.id }}</div>
    <div>{{ item.total | priceFilter }}</div>
    <div>{{ item.generated_at | dateFilter }}</div>
    <div>{{ item.expires_at | dateFilter }}</div>
    <div>
      <update-status-sheet
        :color="statusBadgeColor"
        :text-color="statusTextColor"
        class="d-flex align-center g10"
        style="line-height: 14px"
      >
        <icons-check-circle
          v-if="item.status === 'Approved'"
          :width="14"
          :height="14"
          filled
          :checkStrokeWidth="2"
        />

        <icons-close-circle-filled
          v-else-if="item.status === 'Declined'"
          :height="14"
          :width="14"
          color="currentColor"
        />

        <icons-time-circle
          v-else-if="item.status === 'Expired'"
          :height="12"
          :width="12"
          color="currentColor"
        />

        {{ item.status === 'Active' ? 'Pending approval' : item.status }}
      </update-status-sheet>
    </div>
    <div>
      <icons-eye
        v-tooltip="{ content: 'View Quote', ...tooltipProps }"
        @click.native.stop="$emit('showQuoteDetails', item.id)"
        fill-color="#9B9B9B"
        :width="23"
        :height="20"
        black
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import type IQuoteItem from './types/IQuoteItem'

import UpdateStatusSheet from '@/components/UpdateStatusSheet.vue'

@Component({
  components: {
    UpdateStatusSheet,
  }
})
export default class MyQuotesTableRow extends Vue {
  @Prop() readonly item!: IQuoteItem;

  tooltipProps = {
    placement: 'bottom',
    trigger: 'hover',
    classes: 'my-users-table-row__options-tooltip',
    offset: 12,
  }

  get statusTextColor (): string {
    const { status } = this.item;

    switch (status) {
      case 'Active':
        return '#795E04';
      case 'Declined':
        return '#9E040A';
      case 'Approved':
        return '#07703A';
      case 'Expired':
        return '#795E04';
      default:
        return '#07703A';
    }
  }

  get statusBadgeColor (): string {
    const { status } = this.item;

    switch (status) {
      case 'Active':
        return '#FCEBA4';
      case 'Declined':
        return '#FAE3E3';
      case 'Approved':
        return '#D8F1E4';
      case 'Expired':
        return '#FCEBA4';
      default:
        return '#D8F1E4';
    }
  }
}
</script>

<style lang="scss" scoped>
.my-quotes-table-row {
  padding: 15px;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;
  background-color: #fff;
  gap: 30px;

  & > div {
    display: flex;
    flex: 1 1 0px;

    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #333;

    & > svg {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  &:hover {
    background-color: #fafafa;
  }
}
</style>
