<template>
  <div
    class="my-users-table-row"
    :class="{ 'my-users-table-row--selected': isSelected }"
  >
    <div class="my-users-table-row__checkbox">
      <my-users-table-checkbox
        v-if="isUserActive"
        :selected="isSelected"
        v-on="$listeners"
      />
    </div>

    <div class="my-users-table-row__user-info">
      <div v-if="userFullName" class="lato-bold">{{ userFullName }}</div>
      <div>{{ userEmail }}</div>
    </div>

    <ul class="my-users-table-row__permissions">
      <li>
        <icons-users-type :width="21" :height="17" :is-admin="userIsAnAdmin" />
        {{ userType }}
      </li>
      <li>
        <icons-users-check v-if="userCheckoutNeedsApprover" :width="17" :height="17" />
        <icons-users-un-check v-else :width="17" :height="17" />
        Checkout needs approval
      </li>
      <li>
        <icons-users-check v-if="userCanSeeAllOrders" :width="17" :height="17" />
        <icons-users-un-check v-else :width="17" :height="17" />
        View all orders
      </li>
      <li>
        <icons-users-limit :width="17" :height="17" />
        {{ spendLimitText | capitalizeFirstLetter }}
      </li>
    </ul>

    <div class="my-users-table-row__options">
      <template v-if="isUserActive">
        <icons-users-cart
          v-tooltip="{ content: 'View cart', ...tooltipProps }"
          @click.native.stop="$emit('viewUserCart', userId)"
          :width="17"
          :height="17"
        />
        <icons-users-sign-in
          v-tooltip="{ content: 'Login to this account', ...tooltipProps }"
          @click.native.stop="loginAsUser()"
          :width="16"
          :height="16"
        />
      </template>

      <icons-edit
        v-tooltip="{ content: 'Edit', ...tooltipProps }"
        @click.native.stop="$emit('editUserData', userId)"
        :width="13"
        :height="13"
      />
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'
import createCookieForSameDomain from '@/utils/createCookieForSameDomain'
import getCookie from '@/utils/getCookie'
import userPermissionLevels from './mixins/userPermissionLevels'

import MyUsersTableCheckbox from './MyUsersTableCheckbox.vue'

export default {
  name: 'MyUsersTableRow',
  filters: {
    capitalizeFirstLetter: (text) => text ? `${text?.charAt(0)?.toUpperCase()}${text?.slice(1)}` : null,
  },
  mixins: [userPermissionLevels],
  components: {
    MyUsersTableCheckbox,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false, 
    },
  },
  data: () => ({
    tooltipProps: {
      placement: 'bottom',
      trigger: 'hover',
      classes: 'my-users-table-row__options-tooltip',
      offset: 12,
    }
  }),
  computed: {
    userId () {
      return this.item?.id ?? null
    },
    customerId () {
      return this?.item?.customer_id ?? null
    },
    userFullName () {
      const { first_name, last_name } = this.item ?? {}
      return [first_name, last_name]?.filter((item) => item)?.join(' ') ?? null
    },
    userEmail () {
      return this.item?.email ?? null
    },
    isUserActive () {
      return this.item?.is_active ?? true
    },
    userPermissionLevel () {
      return this?.item?.permission_level ?? 200
    },
    userType () {
      const { permissionsLevels, userPermissionLevel } = this

      return permissionsLevels?.find((level) => level.value === userPermissionLevel)?.text
    },
    userIsAnAdmin () {
      const { userPermissionLevel, permissionsLevels } = this
      const adminsPermissionLevels = permissionsLevels
        ?.filter((item) => ['Super Admin', 'Admin'].includes(item.text))
        ?.map((permission) => permission.value)

      return adminsPermissionLevels.includes(userPermissionLevel) ?? false
    },
    userCheckoutNeedsApprover () {
      return this.item?.is_checkout_needs_approval ?? false
    },
    userCanSeeAllOrders () {
      return this.item?.can_view_all_orders ?? false
    },
    userHasSpendLimit () {
      return this.item.spend_limit_enabled ?? false
    },
    spendLimitText () {
      const { time_frame, amount } = this.item?.spend_limit ?? {}

      if (!this.userHasSpendLimit) return 'No spend limit'

      const filteredPrice = this.$options.filters.priceFilter(
        amount,
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )

      return `${time_frame} spend limit: ${filteredPrice}`
    },
  },
  methods: {
    loginAsUser () {
      Api.get(`/customer/subaccounts/login/${this.userId}`).then(({ token, success }) => {
        if (success) {
          const currentToken = getCookie('cgToken')
          createCookieForSameDomain('cgToken', token, 14)
          createCookieForSameDomain('adminCgToken', currentToken, 14)

          const route = this.$router.resolve({ name: 'Dashboard' })
          window.location.href = route.href
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-table-row {
  padding: 15px;
  display: flex;
  flex-direction: row;
  min-height: 150px;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 1px solid #D8D8D8;
  background-color: #fff;
  gap: 30px;

  &:hover {
    background-color: #fafafa;
  }

  &__checkbox {
    width: 35px;
    height: 20px;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    width: 326px;
  }

  &__permissions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 200px;

    & > li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }

  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: auto;
    align-items: center;
    gap: 20px;

    & > svg {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &--selected {
    background-color: #F4F4F4;
  }
}
</style>

<style lang="scss">
// TODO move to root index file
.my-users-table-row__options-tooltip {
  padding: 6px 12px 4px;
  background-color: #4A4A4A;
  border-radius: 2px;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF;
}
</style>
