<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 18"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.96405 16.875L3.00234 5.625H5.33777V6.90581C4.99777 7.101 4.76634 7.45988 4.76634
        7.875C4.76634 8.496 5.27834 9 5.9092 9C6.54062 9 7.05205 8.496 7.05205 7.875C7.05205 7.45988
        6.8212 7.101 6.48062 6.90581V5.625H11.0521V6.90581C10.7121 7.101 10.4806 7.45988 10.4806
        7.875C10.4806 8.496 10.9926 9 11.6235 9C12.2549 9 12.7663 8.496 12.7663 7.875C12.7663
        7.45988 12.5355 7.101 12.1949 6.90581V5.625H14.5303L15.5692 16.875H1.96405ZM6.48062
        3.375C6.48062 2.13412 7.50634 1.125 8.76634 1.125C10.0263 1.125 11.0521 2.13412 11.0521
        3.375V4.5H6.48062V3.375ZM15.6212 5.01188C15.5949 4.72219 15.3481 4.5 15.0521
        4.5H12.1949V3.375C12.1949 1.51369 10.6572 0 8.76634 0C6.87605 0 5.33777 1.51369 5.33777
        3.375V4.5H2.48062C2.1852 4.5 1.93834 4.72219 1.91148 5.01188L0.768624 17.3869C0.754339
        17.5438 0.808053 17.7002 0.916053 17.8166C1.02405 17.9336 1.1772 18 1.33777
        18H16.1949C16.3561 18 16.5086 17.9336 16.6172 17.8166C16.7252 17.7002 16.7789 17.5438
        16.7641 17.3869L15.6212 5.01188Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'UsersCart',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 17
    },
    height: {
      type: [Number, String],
      required: false,
      default: 17
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    },
  },
}
</script>
