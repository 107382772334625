<template>
  <div
    class="account-status dashboard-card dashboard-card--small order-1"
    style="background-color: #007E76"
  >
    <template v-if="!loading">
      <div class="account-status__title">
        Account status

        <div>
          <v-progress-linear
            color="#FCEBA4"
            rounded
            :value="progressValue"
          />

          {{ progressValue }}%
        </div>
      </div>

      <div class="account-status__content">
        <router-link
          v-for="(item, index) in list"
          :key="index"
          :to="item.url"
        >
          <icons-check-circle
            fillColor="#fff"
            outlineCheckColor="#007E76"
            :checkStrokeWidth="2"
            :width="25"
            :height="25"
            v-if="item.completed"
          />

          <div class="test" v-else />

          {{ item.name }}

          <icons-arrow-left
            class="ml-auto"
            style="transform: rotate(180deg)"
            :width="7"
            :height="12"
            v-if="!item.completed"
          />
        </router-link>
      </div>

      <div class="account-status__cg-elite-info">
        <div class="cg-logo">
          <icons-gift color="#000" :width="17" :height="21" />
        </div>
        <div class="cg-elite-info">
          Gifting year round? <a href="https://corporategift.com/platform/">Learn</a>
          how a CG Elite membership can help
        </div>
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'

export default {
  name: 'TheAccountStatus',
  data: () => ({
    loading: false,
    list: [
      {
        name: 'Personal info',
        url: '/settings',
        completed: false,
      },
      {
        name: 'Payment methods',
        url: '/payment',
        completed: false,
      },
      {
        name: 'Brand assets',
        url: '/my-logos-and-images',
        completed: false,
      },
      {
        name: 'Your contacts',
        url: '/contacts',
        completed: false,
      },
    ]
  }),
  computed: {
    progressValue () {
      return 25 * (this.list?.filter((item) => item.completed)?.length ?? 0)
    }
  },
  watch: {
    progressValue: {
      immediate: true,
      handler: function (val) {
        if (val === 100) this.$emit('showAccountInfo', true)
      }
    }
  },
  created () {
    this.loading =  true

    Api.get('/customer/dashboard/account-overview')
      .then(({ data }) => {
        this.loading =  false
        this.list[0].completed = data.state?.has_personal_info ?? false
        this.list[1].completed = data.state?.has_payment_method ?? false
        this.list[2].completed = data.state?.has_brand_assets ?? false
        this.list[3].completed = data.state?.has_contacts ?? false
      })
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.account-status {
  width: 100%;
  color: #EDFCFB;
  display: flex;
  flex-direction: column;
  gap: 34px;

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    color: #EDFCFB;
    text-transform: uppercase;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 20px;
      gap: 8px;
    }

    &::v-deep {
      & .v-progress-linear__background {
        background-color: #38A39C !important;
      }

      & .v-progress-linear__determinate {
        border-radius: inherit;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > a {
      color: #EDFCFB !important;
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: 15px;
      line-height: 23px;
      font-family: 'Lato-Bold', sans-serif;
    }
  }

  &__cg-elite-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: auto;
    gap: 16px;

    & .cg-elite-info {
      color: #D2F5F3;
      font-size: 16px;
      line-height: 23px;
      font-family: 'Lato-Italic', sans-serif;
      font-weight: 400;

      & > a {
        font-family: inherit;
        font-weight: inherit;
        color: inherit !important;
        border-bottom: 1px solid #D2F5F3;
        padding-bottom: 1px;
        font-size: inherit;
        line-height: inherit;
      }
    }

    & .cg-logo {
      width: 37px;
      min-width: 37px;
      height: 37px;
      min-height: 37px;
      background-color: #FCEBA4;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.test {
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  border-radius: 50%;
}
</style>
