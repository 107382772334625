<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M10 1C14.9831 1 19 5.01691 19 10C19 14.9831 14.9831 19 10 19C5.0169 19 1 14.9831 1 10C1
        5.01691 5.0169 1 10 1Z"
      :stroke="color"
    />
    <path
      d="M9.89496 3.25623C9.40018 3.31486 9.02892 3.77139 9.03962 4.30802V4.87436C7.63888 5.12829
        6.63867 6.46832 6.63867 7.93263C6.63867 9.69766 8.1724 11.0394 10 11.0394C11.0706 11.0394
        11.4406 11.5452 11.4406 12.075C11.4406 12.7434 11.1986 13.1106 10.4802
        13.1106H7.59905C7.25271 13.1054 6.93055 13.3016 6.75595 13.6242C6.58135 13.9468 6.58135
        14.3457 6.75595 14.6683C6.93055 14.9909 7.25271 15.1871 7.59905 15.1818H9.03962V15.6997C9.03472
        16.0731 9.21669 16.4205 9.51585 16.6088C9.815 16.7971 10.185 16.7971 10.4842 16.6088C10.7833
        16.4205 10.9653 16.0731 10.9604 15.6997V15.1333C12.3603 14.8787 13.3613 13.5388 13.3613
        12.075C13.3613 10.31 11.8276 8.96818 10 8.96823C8.92939 8.96823 8.55943 8.46249 8.55943
        7.93263C8.55943 7.2643 8.80137 6.89702 9.51981 6.89702H12.4009C12.7473 6.90231 13.0694
        6.70608 13.244 6.3835C13.4187 6.06091 13.4187 5.66193 13.244 5.33935C13.0694 5.01676 12.7473
        4.82054 12.4009 4.82582H10.9604V4.30802C10.9663 4.00977 10.8527 3.72323 10.6487
        3.52179C10.4446 3.32035 10.1699 3.22357 9.89496 3.25623Z"
      :fill="color"
      stroke="white"
      stroke-width="0.6"
    />
  </svg>
</template>

<script>
export default {
  name: 'UsersLimit',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 18
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    },
    color: {
      type: String,
      required: false,
      default: '#42B77A'
    },
  },
}
</script>
