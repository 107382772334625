import Api from '@/axios/api'

export default {
  getTransactions ({ getters, commit, dispatch }) {
    commit('setLoading',  true)
    commit('setCurrentPage', 1)

    Api.get('/customer/purchased-inventory/transactions', { params: getters.params })
      .then(({ data, meta, links }) => {
        commit('setTransactions', data)
        commit('setMeta', meta)
        commit('setLinks', links)
      })
      .catch((e) => (console.error(e)))
      .finally(() => (commit('setLoading',  false)))

    dispatch('getFiltersItems')
  },
  getAggregatedView ({ getters, commit }) {
    commit('setAggregatedViewLoading', true)

    Api.get('/customer/purchased-inventory/transactions/aggregated', { params: getters.params })
      .then((data) => {
        commit('setAggregatedView', data)
      })
      .catch(() => (commit('setAggregatedView', {})))
      .finally(() => (commit('setAggregatedViewLoading', false)))
  },
  async loadMoreTransactions ({ state, getters, commit, dispatch }) {
    commit('setCurrentPage', state.currentPage + 1)

    return Api.get('/customer/purchased-inventory/transactions', {
      params: {
        page: state.currentPage,
        ...getters.params
      }
    })
      .then(({ data, meta, links }) => {
        commit('addTransactions', data)
        commit('setMeta', meta)
        commit('setLinks', links)
      })
      .catch((e) => (console.error(e)))
  },
  getFiltersItems ({ commit }) {
    Api.get('/customer/purchased-inventory/transactions/filters')
      .then(({ data }) => {
        if (data?.users?.length) commit('setFilterItemsUsers', data.users)
      })
  },
  getFilter ({ getters }, type) {
    return Api.get('' + type, {
      params: getters.params
    })
  },
  resetFilters({ commit }) {
    commit('setSearch')
    commit('setFiltersDateRange')
    commit('setFiltersTypes')
    commit('setFiltersUserId')
    commit('setFiltersOrderId')
    commit('setFiltersCampaignId')
    commit('setFiltersProductId')
  }
}
