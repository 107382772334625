<template>
  <add-bluesnap-credit-card-form @onAddCard="onSuccess" />
</template>

<script>
import AddBluesnapCreditCardForm from '@/components/payment/AddBluesnapCreditCardForm.vue'

export default {
  name: 'AddNewBluesnapCreditCard',
  components: { AddBluesnapCreditCardForm },
  methods: {
    onSuccess () {
      this.$emit('onFetchData');
      this.$emit('update:step', 7);
    },
  },
}
</script>
