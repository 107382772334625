<template>
  <div class="my-users-table-header">
    <div>
      <my-users-table-checkbox
        :selected="allItemsSelected"
        :partially-selected="onlyFewItemsSelected"
        @select="$emit('selectAll')"
        @unselect="$emit('unselectAll')"
      />
    </div>

    <template v-if="!isAnyItemSelected">
      <div>Name & email</div>

      <div>Permissions</div>
    </template>

    <div v-else role="button" @click="$emit('editSelectedItems')">
      Edit selected
    </div>
  </div>
</template>

<script>
import MyUsersTableCheckbox from './MyUsersTableCheckbox.vue'

export default {
  name: 'MyUsersTableHeader',
  components: {
    MyUsersTableCheckbox
  },
  props: {
    availableItems: {
      type: Array,
      required: false,
      default: () => ([])
    },
    selectedItems: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  computed: {
    isAnyItemSelected () {
      return !!this.selectedItems?.length ?? false
    },
    allItemsSelected () {
      const { availableItems, selectedItems } = this

      if (!availableItems?.length) { return false }
      return (selectedItems?.length === availableItems?.length) ?? false
    },
    onlyFewItemsSelected () {
      const { allItemsSelected, selectedItems, availableItems } = this

      if (!availableItems?.length) { return false }
      return !!(!allItemsSelected && selectedItems?.length)
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-table-header {
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  gap: 30px;

  & > div {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: #000000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:nth-child(1) {
      width: 35px;
      height: 20px;
    }

    &:nth-child(2) {
      width: 326px;
    }

    &:nth-child(3) {
      width: 200px;
    }

    &[role="button"] {
      font-family: 'Lato-Regular', sans-serif;
      cursor: pointer;
      font-size: 14px;
      line-height: 19px;
      width: fit-content;
      text-transform: none;
      color: #42B77A;
    }
  }
}
</style>
