import { render, staticRenderFns } from "./AssignUsersPanel.vue?vue&type=template&id=5c812ebf&scoped=true"
import script from "./AssignUsersPanel.vue?vue&type=script&lang=js"
export * from "./AssignUsersPanel.vue?vue&type=script&lang=js"
import style0 from "./AssignUsersPanel.vue?vue&type=style&index=0&id=5c812ebf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c812ebf",
  null
  
)

export default component.exports