import { sortByEnum } from '../types/sortByEnum'

export default {
  methods: {
    sortContactByValue (contacts, sortBy) {
      const contactIsAGroup = (contact) => !!contact?.addresses || false
      // TODO refactor
      return contacts.sort((a, b) => {
        if (sortBy === sortByEnum.FirstName) {
          if (contactIsAGroup(a) && contactIsAGroup(b)) return 0
          if (contactIsAGroup(a) && !contactIsAGroup(b)) return 1
          if (!contactIsAGroup(a) && contactIsAGroup(b)) return -1
          if (!contactIsAGroup(a) && !contactIsAGroup(b)) return a.firstname > b.firstname ? 1 : -1
        }
        if (sortBy === sortByEnum.City) {
          if (contactIsAGroup(a) && contactIsAGroup(b)) return 0
          if (contactIsAGroup(a) && !contactIsAGroup(b)) return 1
          if (!contactIsAGroup(a) && contactIsAGroup(b)) return -1
          if (!contactIsAGroup(a) && !contactIsAGroup(b)) return a.city > b.city ? 1 : -1
        }
        if (sortBy === sortByEnum.CompanyName) {
          if (contactIsAGroup(a) && contactIsAGroup(b)) return 0
          if (contactIsAGroup(a) && !contactIsAGroup(b)) return 1
          if (!contactIsAGroup(a) && contactIsAGroup(b)) return -1
          if (!contactIsAGroup(a) && !contactIsAGroup(b)) return a.company > b.company ? 1 : -1
        }
        if (sortBy === sortByEnum.State) {
          if (contactIsAGroup(a) && contactIsAGroup(b)) return 0
          if (contactIsAGroup(a) && !contactIsAGroup(b)) return 1
          if (!contactIsAGroup(a) && contactIsAGroup(b)) return -1
          if (!contactIsAGroup(a) && !contactIsAGroup(b)) return a.region > b.region ? 1 : -1
        }
        if (sortBy === sortByEnum.LastNameGroupName) {
          if (contactIsAGroup(a) && contactIsAGroup(b)) return a.name > b.name ? 1 : -1
          if (contactIsAGroup(a) && !contactIsAGroup(b)) return a.name > b.lastname ? 1 : -1
          if (!contactIsAGroup(a) && contactIsAGroup(b)) return a.lastname > b.name ? 1 : -1
          if (!contactIsAGroup(a) && !contactIsAGroup(b)) return a.lastname > b.lastname ? 1 : -1
        }

        return 0
      })
    }
  }
}
