<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    :touchless="showPreviewModal"
    right
    width="750px"
    class="right-side-panel"
  >
    <panel-header :show.sync="show">
      <div class="campaign-automation-panel__title">
        Add automation to <b>{{ campaignName }}</b>
      </div>
    </panel-header>

    <cg-loader v-if="loading" />

    <div v-else class="right-side-panel__content-wrapper">
      <span class="campaign-automation-panel__subtext">
        Automations allow you to set a campaign and we’ll send it automatically to your recipients on their birthday or anniversary.
        <a :href="tutorialURL" target="_blank" rel="noopener noreferrer">Learn more</a>
      </span>

      <automation-campaign-how-it-works />

      <template v-if="!campaignGreetingMessage">
        <cg-alert type="error" class="campaign-automation-panel__alert">
          This campaign is missing a greeting, and cannot be automated (i.e. sent with no greeting).
        </cg-alert>

        <span class="campaign-automation-panel__subtext">
          To change campaign settings,
          <router-link :to="{ name: 'CreateCampaignReview', params: { campaignId } }">
            edit campaign
          </router-link>
        </span>
      </template>

      <div v-else class="campaign-automation-panel__form">
        <div class="campaign-automation-panel__step-wrapper">
          <label class="campaign-automation-panel__step" for="target-audience">
            <span>1</span>

            Select target audience:
          </label>

          <common-select
            v-model="selectedTarget"
            :height="40"
            id="target-audience"
            item-text="name"
            item-value="code"
            style="max-width: 400px"
            :items="availableTargets"
            :rules="[rules.required]"
          />
        </div>

        <hr-awareness v-if="selectedTarget === 'no-hr'" />

        <template v-else>
          <div
            v-if="selectedTarget === 'contacts'"
            class="campaign-automation-panel__step-wrapper"
          >
            <template v-if="userContactGroups.length">
              <label>Select contacts group/s:</label>

              <table class="campaign-automation-panel__checkbox-group">
                <tr v-for="group in userContactGroups" :key="group.id">
                  <td>
                    <cg-checkbox v-model="selectedGroupIds" :input-value="group.id">
                      <span class="campaign-automation-panel__checkbox-text" :title="group.name">
                        {{ group.name }}
                      </span>
                    </cg-checkbox>
                  </td>
                  <td><group-info :group="group" :trigger="selectedTrigger" /></td>
                </tr>
              </table>
            </template>

            <cg-alert v-else type="error" class="campaign-automation-panel__alert">
              <div class="campaign-automation-panel__no-contacts">
                You don’t have groups in your contact.

                <router-link :to="{ name: 'Contacts' }">
                  Add groups to contacts
                </router-link>
              </div>
            </cg-alert>
          </div>

          <automation-filters
            v-show="selectedTargetId"
            :automation-id="selectedTargetId"
            :campaign-id="campaignId"
            :settingsFilters.sync="automationFilters"
            @require-authorize="(val) => requireAuthorize = val"
          />

          <template v-if="!requireAuthorize">
            <template v-if="showAutomationTrigger">
              <div class="campaign-automation-panel__step-wrapper">
                <label class="campaign-automation-panel__step" for="automation-trigger">
                  <span>2</span>

                  Select automation trigger:
                </label>

                <common-select
                  v-model="selectedTrigger"
                  :height="40"
                  id="automation-trigger"
                  item-text="name"
                  item-value="code"
                  style="max-width: 400px"
                  :items="automationTriggers"
                  :rules="[rules.required]"
                />
              </div>
            </template>

            <div v-if="isAnniversarySelected" class="campaign-automation-panel__step-wrapper">
              <label class="d-flex flex-row align-center" style="gap: 10px">
                Select anniversary range:

                <common-tooltip
                  :activator-size="20"
                  activator-color="#62646A"
                  :z-index="8"
                  :max-width="300"
                >
                  TODO
                </common-tooltip>
              </label>

              <div class="campaign-automation-panel__anniversary-range">
                <common-input
                  v-model.number="anniversaryRange.from"
                  :height="40"
                  suffix="years"
                  type="number"
                  min="1"
                  max="99"
                  step="1"
                  style="max-width: 84px"
                  :rules="[rules.required, rules.greaterThanZero]"
                />

                <span>to</span>

                <common-input
                  v-model.number="anniversaryRange.to"
                  :height="40"
                  suffix="years"
                  type="number"
                  min="1"
                  max="99"
                  step="1"
                  style="max-width: 84px"
                  :rules="[rules.required, rules.greaterThanZero, anniversaryRangeFromMin]"
                />
              </div>
            </div>

            <template v-if="selectedTrigger">
              <div class="campaign-automation-panel__step-wrapper">
                <label class="campaign-automation-panel__step" for="automation-trigger">
                  <span>3</span>

                  Send campaign:
                </label>

                <div class="d-flex flex-row" style="gap: 8px">
                  <common-select
                    v-model="selectedNotificationOption"
                    :height="40"
                    top
                    id="notification-trigger"
                    style="max-width: 160px"
                    :items="notificationOptions"
                    :rules="[rules.required]"
                  />

                  <template v-if="selectedNotificationOption === 'other'">
                    <common-input
                      v-model.number="customNotification.days"
                      :height="40"
                      suffix="Days"
                      type="number"
                      min="1"
                      step="1"
                      style="max-width: 112px"
                      :rules="[rules.required, rules.greaterThanZero]"
                    />

                    <common-select
                      v-model="customNotification.type"
                      :items="notificationTypes"
                      id="notification-type"
                      style="max-width: 112px"
                      :height="40"
                      top
                      :rules="[rules.required]"
                    />
                  </template>
                </div>
              </div>
            </template>

            <template v-if="isNotificationFilled">
              <div class="campaign-automation-panel__step-wrapper">
                <label class="campaign-automation-panel__step" for="payment-method">
                  <span>4</span>

                  Set payment method:
                </label>

                <payment-module
                  :paymentMethod.sync="payment.method"
                  :paymentData.sync="payment.paymentData"
                  :defaultPaymentMethod="payment.method"
                  payable-type="campaign"
                  :payable-id="campaignId"
                  ref="paymentModule"
                />
              </div>

              <cg-alert
                v-if="payment.method === 'budgetentities'"
                type="information"
                background-color="#F6F5FF"
                class="campaign-automation-panel__alert"
              >
                In case the selected budget entity balance is insufficient when the send date arrives,
                we’ll send you a notification.
                <router-link
                  target="_blank"
                  :to="{
                    name: 'MyCGCredits',
                    query: { auto_reload: selectedBudgetId },
                  }"
                >
                  Set auto-reload
                </router-link>
                to avoid insufficient balance.
              </cg-alert>

              <cg-checkbox v-model="approveBeforeSending">
                Receive a reminder email before each eGift is sent. Option to cancel available
              </cg-checkbox>

              <div class="campaign-automation-panel__buttons">
                <cg-button
                  v-if="campaignIsAutomated"
                  outlined
                  @click="removeAutomation"
                >
                  Remove automation
                </cg-button>
                <cg-button v-else outlined @click="show = false">Cancel</cg-button>

                <cg-button outlined class="ml-auto" @click="onPreviewAutomation">Preview</cg-button>

                <cg-button @click="submit">
                  {{ campaignIsAutomated ? 'Save changes' : 'Apply' }}
                </cg-button>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>

    <automation-preview-modal
      v-model="showPreviewModal"
      :preview-data="previewData"
    />
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import getPaymentBody from '@/components/payment/paymentModule/utils/getPaymentBody'

import { CgLoader, CgCheckbox, CgAlert, CgButton } from '@corporategift/design-system'
import PanelHeader from '@/components/PanelHeader.vue'
import PaymentModule from '@/components/payment/PaymentModule.vue'
import AutomationPreviewModal from './TheAutomationCampaignPreviewModal.vue'
import HrAwareness from './campaignAutomation/AutomationCampaignHrAwareness.vue'
import GroupInfo from './campaignAutomation/AutomationCampaignPanelGroupInfo.vue'
import AutomationFilters from './campaignAutomation/CampaignAutomationFilters.vue'
import AutomationCampaignHowItWorks from './campaignAutomation/AutomationCampaignHowItWorks.vue'

import { required } from '@corporategift/design-system/validations'

import { NotificationType, parseOffset, setOffset } from '@/utils/automationNotification'

export default {
  name: 'TheAutomationCampaignPanel',
  mixins: [panelVModel],
  components: {
    CgAlert,
    CgLoader,
    CgButton,
    GroupInfo,
    CgCheckbox,
    HrAwareness,
    PanelHeader,
    PaymentModule,
    AutomationFilters,
    AutomationPreviewModal,
    AutomationCampaignHowItWorks,
  },
  props: {
    campaign: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    selectedTarget: null,
    selectedTrigger: null,
    approveBeforeSending: false,
    selectedGroupIds: [],
    selectedNotificationOption: null,
    requireAuthorize: false,
    automationFilters: {},
    automationSources: [],
    payment: {
      method: PaymentType.BE,
      paymentData: null,
    },
    customNotification: {
      days: null,
      type: null,
    },
    anniversaryRange: {
      from: 1,
      to: 99,
    },
    userContactGroups: [],
    notificationOptions: [
      {
        text: 'On exact date',
        value: '0after',
        data: {
          days: 0,
          type: NotificationType.After,
        },
      },
      {
        text: '1 day before',
        value: '1before',
        data: {
          days: 1,
          type: NotificationType.Before,
        },
      },
      {
        text: '2 days before',
        value: '2before',
        data: {
          days: 2,
          type: NotificationType.Before,
        },
      },
      {
        text: '3 days before',
        value: '3before',
        data: {
          days: 3,
          type: NotificationType.Before,
        },
      },
      {
        text: '4 days before',
        value: '4before',
        data: {
          days: 4,
          type: NotificationType.Before,
        },
      },
      {
        text: '5 days before',
        value: '5before',
        data: {
          days: 5,
          type: NotificationType.Before,
        },
      },
      {
        text: '1 day after',
        value: '1after',
        data: {
          days: 1,
          type: NotificationType.After,
        },
      },
      {
        text: '2 days after',
        value: '2after',
        data: {
          days: 2,
          type: NotificationType.After,
        },
      },
      {
        text: '3 days after',
        value: '3after',
        data: {
          days: 3,
          type: NotificationType.After,
        },
      },
      {
        text: '4 days after',
        value: '4after',
        data: {
          days: 4,
          type: NotificationType.After,
        },
      },
      {
        text: '5 days after',
        value: '5after',
        data: {
          days: 5,
          type: NotificationType.After,
        },
      },
      {
        text: 'Other',
        value: 'other',
        data: null,
      },
    ],
    notificationTypes: Object.values(NotificationType).map((value) => ({
      text: value.charAt(0).toUpperCase() + value.slice(1),
      value,
    })),
    rules: {
      required: required(),
      greaterThanZero: (v) => v > 0 || ''
    },
    // preview modal
    showPreviewModal: false,
    previewData: null,
  }),
  computed: {
    campaignName () {
      return this.campaign?.name ?? null
    },
    campaignId () {
      return this.campaign?.entity_id ?? null
    },
    routeParams () {
      return this.$route.query ?? null
    },
    tutorialURL () {
      return `${process.env.VUE_APP_MAGENTO_URL}/video-tutorials/?tag=Automation`
    },
    campaignIsAutomated () {
      return this.campaign?.has_automation ?? false
    },
    availableTargets () {
      const { automationSources } = this

      if (automationSources.length > 1) { return automationSources }
      return [
        ...automationSources,
        {
          code: 'no-hr',
          id: null,
          name: 'My HR system',
        },
      ]
    },
    showAutomationTrigger () {
      const { selectedTrigger, selectedTarget, selectedGroupIds } = this

      if (selectedTrigger) { return true }
      if (!selectedTarget) { return false }
      if (selectedTarget === 'contacts') { return !!selectedGroupIds.length }
      return true
    },
    campaignGreetingMessage () {
      return this.campaign?.greeting_message ?? null
    },
    notificationData () {
      const { selectedNotificationOption, customNotification, notificationOptions } = this

      if (!selectedNotificationOption) { return { days: null, type: null } }
      if (selectedNotificationOption === 'other') { return customNotification }

      const selectedNotification = notificationOptions.find(({ value }) => value === selectedNotificationOption)
      if (selectedNotification) { return selectedNotification.data; }

      return { days: null, type: null }
    },
    isNotificationFilled () {
      const { notificationData } = this
      return Object.values(notificationData).every((value) => value !== null);
    },
    selectedTargetId () {
      const { selectedTarget, automationSources } = this
      const selectedSource = automationSources.find(({ code }) => code === selectedTarget)

      return selectedSource?.id ?? null
    },
    automationTriggers () {
      const { selectedTarget, automationSources } = this
      const selectedSource = automationSources
        .find(({ code }) => code === selectedTarget) ?? automationSources.at(0);

      return selectedSource?.types ?? [];
    },
    isAnniversarySelected () {
      return this.selectedTrigger === 'anniversary'
    },
    selectedBudgetId () {
      const { paymentData } = this.payment
      return paymentData?.id ?? null
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        Object.assign(this.$data, this.$options.data())

        if (this.campaignGreetingMessage) { this.loadData() }
      }
    },
  },
  methods: {
    loadData () {
      this.loading = true;

      const promises = [
        Api.get('/campaigns/automation/contact-groups')
          .then(({ data }) => data),
        Api.get('/campaigns/automation/sources')
          .then(({ data }) => data),
        Api.get(`/campaigns/${this.campaignId}/automation`)
          .then(({ data }) => data)
          .catch(() => null)
      ]

      Promise.all(promises)
        .then(([contacts, sources, form]) => {
          this.userContactGroups = contacts
          this.automationSources = sources

          if (form) {
            this.selectedTarget = form.source
            this.selectedTrigger = form.type
            this.approveBeforeSending = form?.is_for_approval ?? false
            this.selectedGroupIds = form?.settings?.group_ids ?? []
            this.anniversaryRange = form?.anniversary_range ?? { from: 1, to: 99 }

            const notificationData = parseOffset(form.offset)
            const notificationKey = Object.values(notificationData).join('')
            const notificationOptionIndex = this.notificationOptions
              .findIndex(({ value }) => value === notificationKey)
            const paymentInfo = form.payment
            const settingsData = {
              ...form.settings,
              group_ids: undefined,
            }

            this.automationFilters = structuredClone(settingsData)

            this.payment.method = paymentInfo.method
            delete paymentInfo.method
            delete paymentInfo.deviceData

            if (Object.keys(paymentInfo).length) {
              this.payment.paymentData = {
                ...paymentInfo,
              }
            }

            if (notificationOptionIndex === -1) {
              this.selectedNotificationOption = 'other'
              this.customNotification = notificationData
            } else {
              this.selectedNotificationOption = notificationKey
            }
          }

          const { routeParams } = this

          if (routeParams && routeParams?.source_id) {
            this.$nextTick(() => {
              const targetToSelect = this.availableTargets?.find(({ id }) => id === Number(routeParams.source_id))

              if (targetToSelect) { this.selectedTarget = targetToSelect.code }
            })
          }
        })
        .finally(() => (this.loading = false))
    },
    anniversaryRangeFromMin (val) {
      if (!val) { return true }
      return this.anniversaryRange.from <= val
    },
    getRequestBody () {
      const {
        payment,
        selectedTarget,
        selectedTrigger,
        selectedTargetId,
        selectedGroupIds,
        anniversaryRange,
        automationFilters,
        approveBeforeSending,
        notificationData,
      } = this

      let settings = {
        group_ids: selectedGroupIds,
      };

      if (selectedTargetId) {
        settings = {
          ...automationFilters,
          group_ids: [],
        };
      }

      return {
        source: selectedTarget,
        source_id: selectedTargetId,
        type: selectedTrigger,
        payment: getPaymentBody(payment),
        is_for_approval: approveBeforeSending,
        offset: setOffset(notificationData),
        anniversary_range: anniversaryRange,
        settings,
      }
    },
    onPreviewAutomation () {
      this.showPreviewModal = true
      this.previewData = this.getRequestBody()
    },
    submit () {
      this.loading = true
      const requestBody = this.getRequestBody()

      Api.post(`/campaigns/${this.campaignId}/automation`, requestBody)
        .then(({ success }) => {
          if (success) {
            this.$cgToast.success('Successfully added automation to campaign')
            this.$emit('automationCreated')
            this.show = false
          }
        })
        .catch(({ response: { data } }) => {
          const errorMessage = data?.message ?? undefined
          this.$cgToast.error(errorMessage || 'An error occurred, please contact our support')
        })
        .finally(() => (this.loading = false))
    },
    removeAutomation () {
      this.loading = true

      Api.delete(`/campaigns/${this.campaignId}/automation`)
        .then(() => {
          this.$cgToast.success('Successfully removed automation')
          this.$emit('automationRemoved')
          this.show = false
        })
        .catch(({ response: { data } }) => {
          const errorMessage = data?.message ?? undefined
          this.$cgToast.error(errorMessage || 'An error occurred, please contact our support')
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/common/right-side-panel.scss';

.right-side-panel {
  &::v-deep > .v-navigation-drawer__content {
    gap: 36px;
  }

  &__content-wrapper {
    gap: 32px;
  }
}

.campaign-automation-panel {
  &__title {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit;

    & > b {
      font-family: 'Lato-Bold', sans-serif;
    }
  }

  &__subtext {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000;

    & > a {
      color: #6E50FF !important;
    }
  }

  &__form {
    gap: 42px;
    display: flex;
    flex-direction: column;
  }

  &__step {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    color: #000;
    font-size: 18px;
    line-height: 21px;
    font-family: 'Lato-Bold', sans-serif;

    & > span {
      font-size: 15px;
      line-height: 18px;
      color: #fff;
      border-radius: 48px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 29px;
      min-width: 29px;
      background-color: #222325;
    }
  }

  &__step-wrapper {
    gap: 24px;
    display: flex;
    flex-direction: column;

    & > label {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000;
    }
  }

  &__checkbox-group {
    table-layout: fixed;
    border: none;
    width: 100%;
    border-collapse: collapse;

    & > tr {
      & > td {
        padding: 15px 0;
        border-top: 1px solid #D3D2D2;

        &:first-child {
          padding-right: 16px;
          width: 200px;
        }
      }

      &:last-of-type > td {
        border-bottom: 1px solid #D3D2D2;
      }
    }
  }

  &__checkbox-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
  }

  &__anniversary-range {
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;

    & > span {
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #000;
    }
  }

  &__buttons {
    gap: 12px;
    display: flex;
    flex-direction: row;

    &::v-deep > button {
      border-radius: 100px;
    }
  }

  &__alert::v-deep > .cg-alert__content {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    width: 100%;

    & > a {
      color: #6E50FF !important;
    }
  }

  &__no-contacts {
    display: flex;
    gap: 16px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    & > a {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
      text-decoration: underline !important;
    }
  }
}
</style>
