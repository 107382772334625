const PaymentType = {
  CC: 'gene_braintree_creditcard',
  CC_Bluesnap: 'bluesnap_cc',
  BE: 'budgetentities',
  ACH: 'braintree_ach',
  ACH_Bluesnap: 'bluesnap_ach',
  PO: 'purchaseorder',
  Test: 'test',
};

export default PaymentType;
