import Vue, { PropType } from 'vue'
import { PanelType } from '../../types'

export default Vue.extend({
  props: {
    panelType: {
      type: String as PropType<PanelType>,
      required: false,
      default: () => PanelType.EGift,
    },
  },
  computed: {
    isSelectedGiftLink (): boolean {
      return this.panelType === PanelType.Link
    }
  },
});
