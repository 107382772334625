<template>
  <div class="send-egift-panel-review__summary">
    <!-- images / name / preview -->
    <div>
      <span
        class="send-egift-panel-review__link send-egift-panel-review__preview-link"
        @click="showPreview()"
      >
        How recipient will see this?
      </span>

      <h3>
        {{ campaignName }}
      </h3>

      <the-campaign-card-images
        :products="availableItemsInGift"
        :defaultImageHeight="120"
        :alternativeImageHeight="60"
        show-products-from-inventory
        show-small-inventory-icon
        class="send-egift-panel-review__images"
      />
    </div>
    <!-- cost / CTA button -->
    <div>
      <h3>Cost estimate</h3>

      <span class="send-egift-panel-review__link" @click="showDepositInfo">
        How is the deposit calculated?
      </span>

      <div class="send-egift-panel-review__divider" />

      <template v-if="!loadingCosts">
        <ul>
          <li>
            <div>
              Max gift cost
              <span>x {{ numberOfRecipients }} recipients</span>
            </div>
            <div>{{ giftMaxCost | priceFilter }}</div>
          </li>
          <li v-if="giftMaxShipping > giftCardFeeTotal">
            <div>
              Max shipping
              <span>x {{ numberOfRecipients }} recipients</span>
            </div>
            <div>{{ giftMaxShipping | priceFilter }}</div>
          </li>
          <li v-if="giftSalesTax">
            <div>Max sales tax</div>
            <div>{{ giftSalesTax | priceFilter }}</div>
          </li>
          <template v-if="dropshipFeeTotal">
            <li>
              <div>Max Dropship fee</div>
              <div>{{ dropshipFeeTotal| priceFilter }}</div>
            </li>
          </template>
          <li v-if="egiftFeeTotal">
            <div>{{ egiftFeeLabel }}</div>
            <div>{{ egiftFeeTotal | priceFilter }}</div>
          </li>
          <li v-if="giftCardFeeTotal && giftCardFeeTotal > giftMaxShipping">
            <div>Gift card fee</div>
            <div>{{ giftCardFeeTotal | priceFilter }}</div>
          </li>
        </ul>

        <div class="send-egift-panel-review__divider send-egift-panel-review__divider--large" />

        <div class="send-egift-panel-review__total-cost">
          <div>Today’s deposit</div>
          <div>{{ giftTotalCost | priceFilter }}</div>
        </div>
      </template>

      <common-loader v-else style="padding: 40px 0" />

      <div class="send-egift-panel-review__deposit-info">
        You will be refunded for the difference between this deposit and actual costs. 
        <a role="button" @click.stop="showRefundPolicy">Learn more</a>
      </div>

      <common-button
        block
        height="44"
        :loading="loading"
        :disabled="loadingCosts || isSpendLimitInsufficient"
        @click="handleSendCampaign()"
      >
        {{ ctaButtonName }}
      </common-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Component, Watch } from 'vue-property-decorator'
import { PanelType, RootSteps, LinkType } from '../../types'

import personalSpendLimitProp from '../mixins/personalSpendLimitProp'
import recipientMethodProp from '../mixins/recipientMethodProp'
import approvalDataProps from '../mixins/approvalDataProps'
import ShowErrorMessage from '../mixins/showErrorMessage'
import PreviewCampaign from '@/mixins/previewCampaign'
import recipientsProp from '../mixins/recipientsProp'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import SendCampaign from '../mixins/sendCampaign'
import formProp from '../mixins/formProp'
import Api from '@/axios/api'

import TheCampaignCardImages from '@/components/myCampaign/TheCampaignCardImages.vue'

interface ICampaignCosts {
  fee: number | null;
  fee_per_unit: number | null;
  fee_rate: number | null;
  fee_type: 'quantity' | 'percent' | 'fixed';
  gift_card_fee: number | null;
  gift_card_fee_per_unit: number | null;
  gift_card_fee_rate: number | null;
  shipping: number | null;
  shipping_per_unit: number | null;
  subtotal: number | null;
  subtotal_per_unit: number | null;
  dropship_fee: number | null;
  dropship_fee_per_unit: number | null;
  tax: number | null;
  tax_rate: number | null;
  total: number | null;
}

@Component({
  components: {
    TheCampaignCardImages,
  },
})
export default class SendEgiftPanelReviewSummary extends Mixins(
  personalSpendLimitProp,
  recipientMethodProp,
  approvalDataProps,
  ShowErrorMessage,
  PreviewCampaign,
  recipientsProp,
  SendCampaign,
  panelTypeProp,
  campaignProp,
  formProp,
) {
  loading = false;

  loadingCosts = false;

  campaignCosts: ICampaignCosts = {
    fee: 0,
    fee_per_unit: 0,
    fee_rate: 0,
    fee_type: 'quantity',
    gift_card_fee: 0,
    gift_card_fee_per_unit: 0,
    gift_card_fee_rate: 0,
    shipping: 0,
    shipping_per_unit: 0,
    subtotal: 0,
    subtotal_per_unit: 0,
    dropship_fee: 0,
    dropship_fee_per_unit: 0,
    tax: 0,
    tax_rate: 0,
    total: 0,
  }

  get campaignName(): string {
    return this.campaign?.name ?? ''
  }

  get availableItemsInGift () {
    const { egift_settings: settings, collection: { items } } = this.campaign
  
    const availableItems = items?.filter((item) => {
      return !item.product.qty.is_out_of_stock
    })

    if (settings?.egift_type === 'coin') {
      return availableItems.filter(item => item.product.price <= Number(settings?.coin_value || 0))
    }

    return availableItems
  }

  get egiftFeeLabel(): string {
    const { fee_rate, fee_type } = this.campaignCosts
    return fee_type === 'percent' ? `${fee_rate}% eGift fee` : 'eGift fee'
  }

  // total costs
  get giftMaxCost(): number {
    return this.campaignCosts?.subtotal ?? 0
  }

  get giftMaxShipping(): number {
    return this.campaignCosts?.shipping ?? 0
  }

  get giftSalesTax(): number {
    return this.campaignCosts?.tax ?? 0
  }

  get dropshipFeeTotal(): number {
    return this.campaignCosts?.dropship_fee ?? 0;
  }

  get egiftFeeTotal(): number {
    return this.campaignCosts?.fee ?? 0
  }

  get giftCardFeeTotal(): number {
    return this.campaignCosts?.gift_card_fee ?? 0
  }

  get giftTotalCost(): number {
    return this.campaignCosts?.total ?? 0
  }

  get ctaButtonName(): string {
    const { checkoutNeedsApproval, numberOfRecipients, panelType } = this

    return checkoutNeedsApproval
      ? 'Continue' : panelType === PanelType.Link
        ? numberOfRecipients > 1
          ? 'Create links'
        : 'Create link'
      : 'Send'
  }

  @Watch('giftTotalCost')
  onGiftTotalCostChange (val: number) {
    this.$emit('update:campaignTotalCost', val)
  }

  @Watch('giftMaxShipping')
  onGiftShippingChange (val: number) {
    this.$emit('update:campaignShippingCost', val)
  }

  mounted () {
    this.calculatePrices()
  }

  showPreview(): void {
    const { form, campaign, panelType } = this

    const calendlyLink = form.actionLinkType === 'calendly' ? form.calendlyLink : undefined

    this.previewCampaign({
      campaignId: campaign.entity_id,
      logoUrl: form.logoUrl || undefined,
      recipients: [],
      senderName: form.giftGiverName || undefined,
      subjectLine: form.emailSubjectLine || undefined,
      greetingMessage: form.greeting || undefined,
      calendlyLink: calendlyLink,
      videoUrl: form.videoGreeting || undefined,
      freeFormButton: calendlyLink ? undefined : form.freeForm.buttonText,
      freeFormUrl: calendlyLink ? undefined : form.freeForm.link,
      canUpgrade: form.settings.recipientCanUpgrade,
      canRegift: form.settings.recipientCanReEgift,
      hideEmailStep: panelType === PanelType.Link,
      linkType: form.linkType,
      welcomeMessage: form.linkType !== LinkType.StandardLink ? form.welcomeMessage : undefined
    })
  }

  showRefundPolicy(): void {
    this.$emit('update:step', RootSteps.RefundPolicy)
  }

  showDepositInfo(): void {
    this.$emit('update:step', RootSteps.DepositInfo)
  }

  calculatePrices(): void {
    const { campaign: { entity_id: id }, numberOfRecipients  } = this

    this.loadingCosts = true

    Api.get<{ number_of_recipients: number }, ICampaignCosts>(`campaigns/send-egift/${id}/calculate`, {
      params: { number_of_recipients: numberOfRecipients }
    })
      .then((costs) => (this.campaignCosts = costs))
      .finally(() => (this.loadingCosts = false))
  }

  validationChecker (): boolean {
    let result = true

    const {
      panelType,
      form: {
        greeting,
        welcomeMessage,
        linkType,
        emailSubjectLine,
        giftGiverName,
        giftGiverEmail,
      },
    } = this

    const message = greeting?.replaceAll('\n', '')

    if (panelType === PanelType.EGift) {
      if (!emailSubjectLine) {
        this.showErrorMessage('Email subject line is required')
        this.findReviewFieldWithLabel('Email subject line')
        result = false
      }

      if (!message) {
        this.showErrorMessage('Greeting Message is required')
        this.findReviewFieldWithLabel('Greeting')
        result = false
      }
    }

    if (panelType === PanelType.Link) {
      if (linkType === LinkType.SingleLink && !welcomeMessage) {
        this.showErrorMessage('Welcome message is required')
        this.findReviewFieldWithLabel('Welcome message')
        result = false
      }
    }

    if (!giftGiverName) {
      this.showErrorMessage('Gift giver name is required')
      this.findReviewFieldWithLabel('Gift giver name')
      result = false
    }

    if (!giftGiverEmail) {
      this.showErrorMessage('Gift giver email is required')
      this.findReviewFieldWithLabel('Gift giver email')
      result = false
    }

    return result
  }

  handleSendCampaign(): void {
    if (!this.validationChecker()) { return }

    if (this.checkoutNeedsApproval) {
      this.$emit('update:step', RootSteps.Approval)
      return
    }

    this.sendCampaign()
  }

  findReviewFieldWithLabel(labelName: string): void {
    const elements = this?.$parent?.$el.querySelectorAll<HTMLDivElement>('.review-field__label')

    if (elements?.length) {
      let elementToFind: HTMLDivElement | undefined = undefined;

      for (const element of elements) {
        if (element.textContent?.includes(labelName)) { elementToFind = element }
      }

      if (elementToFind) { elementToFind.classList.add('review-field__label--error') }
    }
  }
}

</script>

<style lang="scss" scoped>
.send-egift-panel-review {
  &__summary {
    display: flex;
    flex-direction: column;
    flex: 0 1 300px;
    background: #FAFAFA;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    height: fit-content;

    & > div {
      &:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 30px;
        background: #fff;
      }

      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 18px;
        padding: 30px;
        background: inherit;
      }
    }
  }

  h3 {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  &__images {
    max-width: 240px;
    width: 240px;
    border: 1px solid #E4E4E4;
    border-bottom: none;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: #F4F4F4;

    &--large {
      height: 4px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 12px;
      align-items: center;
      justify-content: space-between;

      & > div {
        font-family: 'Lato-Bold', sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #000000;

        & > span {
          font-family: 'Lato-Regular', sans-serif;
          font-weight: 400;
          color: #95979D;
        }
      }
    }
  }

  &__total-cost {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    justify-content: space-between;
    align-items: center;

    & > div {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
  }

  &__deposit-info {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;

    & > a {
      color: #42B77A !important;
      text-decoration: none;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      font-family: inherit;

      &:hover {
        color: #219358 !important;
      }
    }
  }

  &__link {
    font-family: 'Lato-Regular', sans-serif;
    width: fit-content;
    color: #42B77A;
    cursor: pointer;

    &:hover {
      color: #219358;
    }
  }

  &__preview-link {
    font-size: 15px;
    line-height: 18px;
  }

  button {
    &::v-deep > span {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
    }
  }
}
</style>
