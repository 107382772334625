<template>
  <add-bluesnap-ach-form @onAddAch="onSuccess" />
</template>

<script>
import AddBluesnapAchForm from '@/components/payment/AddBluesnapAchForm.vue'

export default {
  name: 'AddNewBluesnapAch',
  components: { AddBluesnapAchForm },
  methods: {
    onSuccess () {
      this.$emit('onFetchData');
      this.$emit('update:step', 9);
    },
  },
}
</script>
