<template>
  <div class="send-egift-panel-my-logos">
    <span>
      <b>Upload preferences:</b> 5MB max | JPG, JPEG, PNG, PDF, GIF, SVG, AI, ESP accepted
    </span>

    <div class="send-egift-panel-my-logos__files">
      <common-loader v-if="loading" style="padding: 100px 0" />

      <template v-else>
        <upload-logo @addNewFile="addNewLogo" />

        <div
          v-for="logo in availableLogos"
          :key="logo.id"
          class="send-egift-panel-my-logos__logo"
          :class="{ 'send-egift-panel-my-logos__logo--selected': logo.url === selectedImage }"
          @click="selectedImage = logo.url"
        >
          <img :src="logo.url" :alt="logo.caption" draggable="false">

          <icons-check-circle v-if="logo.url === selectedImage" filled />

          <span v-if="logo.caption">{{ logo.caption }}</span>
        </div>
      </template>
    </div>

    <div class="send-egift-panel-my-logos__actions">
      <common-button
        v-if="!loading"
        :height="44"
        width="200"
        @click="saveChanges()"
      >
        Save changes
      </common-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import type { ISavedLogo } from '../../types'

import Api from '@/axios/api'
import formProp from '../mixins/formProp'

import UploadLogo from '../components/UploadLogo.vue'

@Component({
  components: {
    UploadLogo,
  },
})
export default class SendEgiftPanelLogos extends Mixins(formProp) {
  loading = false;

  selectedImage: string | null = null;

  availableLogos: Array<ISavedLogo> = [];

  mounted () {
    this.loading = true
    this.selectedImage = this.form.logoUrl ?? null

    Api.get('/customer/logos')
      .then(({ data }: { data: Array<ISavedLogo> }) => (this.availableLogos = data))
      .finally(() => (this.loading = false))
  }

  addNewLogo (logoData: ISavedLogo): void {
    this.availableLogos = [logoData, ...this.availableLogos]
    this.selectedImage = logoData.url;
  }

  saveChanges (): void {
    this.$emit('update:logoUrl', this.selectedImage ?? null)
    this.$emit('update:step', RootSteps.Review)
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-my-logos {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 40px;

  & > span {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;

    & > b {
      font-family: inherit;
      font-weight: 600;
    }
  }

  &__files {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px 20px;
  }

  &__actions {
    background: #fff;
    position: sticky;
    width: 100%;
    left: 0;
    bottom: -50px;
    margin-bottom: -50px;
    padding: 10px 0 50px;
  }

  &__logo {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
    gap: 6px;

    & > img {
      object-fit: contain;
      width: 196px;
      height: 196px;
      border: 2px solid transparent;
      box-sizing: content-box;
      aspect-ratio: 1 / 1;
    }

    & > span {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 25px;
      color: #62646A;
    }

    &--selected {
      & > img {
        border-color: #42B77A;
      }

      & > svg {
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }
  }
}
</style>
