<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 20"
    :height="height"
    :width="width"
    fill="none"
  >
    <g clip-path="url(#clip0_3551_66879)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.74746 4.44213C9.74746 3.8737 10.2106 3.41055 10.7788 3.41055C11.3475 3.41055 11.8106
          3.8737 11.8106 4.44213C11.8106 5.01055 11.3475 5.4737 10.7788 5.4737C10.1896 5.4737
          9.74746 5.01055 9.74746 4.44213ZM12.8211 4.44213C12.8211 3.30528 11.8948 2.37897 10.758
          2.37897C9.62093 2.37897 8.69482 3.30528 8.69482 4.44213C8.69482 5.57897 9.62093 6.50528
          10.758 6.50528C11.8948 6.50528 12.8211 5.57897 12.8211 4.44213Z
        "
        :fill="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7578 1.03158C12.6315 1.03158 14.1683 2.56842 14.1683 4.44211C14.1683 5.47368 13.0525
          8 11.0946 11.3895C10.9894 11.5789 10.8209 11.5789 10.7578 11.5789C10.6946 11.5789 10.5262
          11.5579 10.4209 11.3895C8.46304 8.02105 7.34725 5.47368 7.34725 4.44211C7.34725 2.56842
          8.88409 1.03158 10.7578 1.03158ZM10.7578 12.6105C11.2841 12.6105 11.7473 12.3368 11.9999
          11.8947C13.4525 9.36842 15.1999 6.02105 15.1999 4.44211C15.1999 2 13.2209 0 10.7578
          0C8.29462 0 6.31567 2 6.31567 4.44211C6.31567 6.02105 8.06304 9.36842 9.51567
          11.8947C9.78936 12.3368 10.2525 12.6105 10.7578 12.6105Z
        "
        :fill="color"
      />
      <mask
        id="mask0_3551_66879"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="9"
        width="15"
        height="11"
      >
        <path d="M0 9.36841H14.6736V19.9789H0V9.36841Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_3551_66879)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.7684 14.1684H2.90526C1.87368 14.1684 1.03158 13.3263 1.03158 12.2947C1.03158
            11.2631 1.87368 10.421 2.90526 10.421H5.68421C5.97895 10.421 6.21053 10.1895 6.21053
            9.89472C6.21053 9.59999 5.97895 9.36841 5.68421 9.36841H2.90526C1.30526 9.36841 0
            10.6737 0 12.2737C0 13.8737 1.30526 15.1789 2.90526 15.1789H11.7684C12.8 15.1789
            13.6421 16.021 13.6421 17.0526C13.6421 18.0842 12.8 18.9263 11.7684
            18.9263H4.50526C4.21053 18.9263 3.97895 19.1579 3.97895 19.4526C3.97895 19.7474 4.21053
            19.9789 4.50526 19.9789H11.7684C13.3686 19.9789 14.6737 18.6737 14.6737 17.0737C14.6737
            15.4737 13.3686 14.1684 11.7684 14.1684Z
          "
          :fill="color"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3551_66879">
        <rect width="16" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Tracking',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 16
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    }
  }
}
</script>
