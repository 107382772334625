<template>
  <div class="account-auth-page__container">
    <cg-loader v-if="loading" style="height: unset" />

    <div v-else class="account-auth-page__form">
      <template v-if="step === 1">
        <h1>Forgot  your password?</h1>

        <span>Enter the email address associated with your account, and we’ll email you a link to reset your password.</span>

        <cg-form ref="form" @submit="onSubmit">
          <cg-input
            v-model="form.email"
            type="email"
            :validation="[required(), email()]"
            aria-autocomplete="none"
            placeholder="Email"
          >
            <template #label>Email address</template>
          </cg-input>

          <cg-button :disabled="!form.email" type="submit">Reset password</cg-button>
        </cg-form>

        <span class="forgot-password__sub-text">
          Remember your password? Go back to <router-link :to="{ name: 'LoginPage' }">Login</router-link>.
        </span>
      </template>

      <template v-else>
        <h1>Okay!</h1>

        <icons-check-circle
          filled
          :height="36"
          :width="36"
          filled-color="#42B77A"
          :checkStrokeWidth="2"
          class="mx-auto"
        />

        <span class="text-center" style="margin-bottom: 75%">
          Check <b>{{ form.email }}</b> for the confirmation email. <br />
          It will have a link to reset your password.
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import syncEmail from '@/mixins/auth/syncEmail'
import { forgotPasswordAction } from '@/services/authService'
import { required, email } from '@corporategift/design-system/validations'

import { CgButton, CgForm, CgInput, CgLoader } from '@corporategift/design-system'

export default {
  name: 'ForgotPasswordPage',
  mixins: [syncEmail],
  components: {
    CgLoader,
    CgButton,
    CgForm,
    CgInput,
  },
  data: () =>({
    form: {
      email: '',
    },
    loading: false,
    step: 1,
  }),
  methods: {
    required,
    email,
    onSubmit () {
      if (this.$refs.form.validate()) {
        const { form } = this
        this.loading = true

        forgotPasswordAction({ ...form })
          .then(({ success }) => {
            if (success) {
              this.step += 1
            } else {
              this.$cgToast.error('An error occurred, please contact our support')
            }

            this.loading = false
          })
          .catch((e) => {
            this.$cgToast.error(e, { html: true })
            this.loading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/css/auth-page.scss";

.account-auth-page__form {
  max-width: 430px;

  &::v-deep > span {
    text-align: left;
  }
}
// TODO fix
.forgot-password__sub-text {
  text-align: center !important;
  font-family: 'Lato', sans-serif;
  font-weight: 400 !important;
  font-style: italic !important;
  line-height: normal !important;
  font-size: 0.9375rem !important;
  color: #95979D !important;

  & > a {
    color: #6E50FF;
  }
}
</style>
