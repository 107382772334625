<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 90 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.4604 21.0207L67.4938 23.9872C74.0555 27.798 80.0466 32.5157 85.2899 38.0007C79.0545
        44.5556 71.7287 49.9784 63.6386 54.0279C70.5601 46.0128 71.5527 34.4595 66.0998
        25.3812L63.1686 28.3124C67.4431 36.3087 65.9819 46.1601 59.5706 52.5714C53.1593 58.9827
        43.3079 60.4439 35.3116 56.1694L31.1772 60.3039C35.6331 61.8028 40.2989 62.5835 44.9999
        62.6169C69.3915 62.6169 88.7119 40.2498 89.5224 39.2976C90.1589 38.5507 90.1594 37.4522
        89.5234 36.7048C84.0015 30.54 77.5736 25.2514 70.4604 21.0207Z
      "
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.1987 56.9734L8.08605 72.086C7.30509 72.8669 7.30509 74.1331 8.08605 74.9141C8.86702
        75.695 10.1332 75.695 10.9142 74.9141L81.9142 3.91409C82.6951 3.13312 82.6951 1.86693
        81.9142 1.08597C81.1332 0.305003 79.867 0.305003 79.0861 1.08597L62.9337 17.2383C57.2727
        14.7704 51.1752 13.4597 45.0001 13.3836C20.6085 13.3836 1.28722 35.7517 0.476674
        36.7049C-0.159269 37.4523 -0.158846 38.5507 0.477674 39.2976C6.93151 46.5029 14.6274
        52.4899 23.1987 56.9734ZM45.0001 17.4011C49.7722 17.3968 54.3964 19.0572 58.0758
        22.0962L53.2501 26.922C50.1111 24.57 46.1179 23.6687 42.2729 24.4444C44.6493 26.9082
        44.186 31.2742 41.2096 34.2508C38.2449 37.2154 33.9026 37.6852 31.4337 35.341C30.6764
        39.1658 31.581 43.1321 33.9217 46.2503L29.0955 51.0765C24.0299 44.9249 22.9692 36.4025
        26.3723 29.1969C29.7755 21.9913 37.0313 17.3966 45.0001 17.4011ZM26.2656 53.9064C18.4089
        44.6978 18.45 31.1331 26.3624 21.9723C18.2719 26.0221 10.9458 31.4454 4.71011
        38.0008C10.9097 44.5144 18.1848 49.9115 26.2165 53.9555L26.2656 53.9064Z
      "
      fill="black"
    />
    <path
      d="M44.9998 51.8281C49.4828 51.8377 53.691 49.6689 56.2845 46.0123C58.8779 42.3556 59.5332
        37.6669 58.0417 33.4393L40.439 51.0421C41.9041 51.5594 43.4461 51.8252 44.9998 51.8281Z
      "
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'EyeCrossed',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 23
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    }
  }
}
</script>
