import { render, staticRenderFns } from "./SendEgiftPanel.vue?vue&type=template&id=4c717104&scoped=true"
import script from "./SendEgiftPanel.vue?vue&type=script&lang=ts"
export * from "./SendEgiftPanel.vue?vue&type=script&lang=ts"
import style0 from "./SendEgiftPanel.vue?vue&type=style&index=0&id=4c717104&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c717104",
  null
  
)

export default component.exports