<template>
  <div class="my-approval-orders-table">
    <my-orders-table-item
      v-for="(order, index) in orders"
      order-require-approval
      :order="order"
      :key="index"
    />

    <orders-table-empty v-if="!orders.length">
      No orders waiting your approval
    </orders-table-empty>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import MyOrdersTableItem from './TheMyOrdersTableItem'
import OrdersTableEmpty from './OrdersTableEmpty'

const { mapState } = createNamespacedHelpers('new-approvable-orders')

export default {
  name: 'TheApprovalOrdersTable',
  components: {
    MyOrdersTableItem,
    OrdersTableEmpty,
  },
  computed: {
    ...mapState(['orders']),
  },
}
</script>

<style lang="scss" scoped>
.my-approval-orders-table {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
}
</style>
