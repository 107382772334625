<template>
  <div>
    <input
      @change.prevent="update"
      id="status-checkbox"
      type="checkbox"
      class="checkbox1 hidden"
      v-model="checked"
    />
    <label class="switchbox" for="status-checkbox"></label>
  </div>
</template>

<script>
module.exports = {
  name: 'switcher',
  props: {
    initialValue: Boolean
  },
  data: () => {
    return {
      checked: undefined
    }
  },
  methods: {
    setInitialValue () {
      if (this.checked === undefined) {
        this.checked = this.initialValue
      }
    },
    update () {
      this.$emit('update-checked', this.checked)
    }
  },
  mounted () {
    this.setInitialValue()
  }
}
</script>
