<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.7513 15.6068C3.96207 16.3294 4.25459 17.0264 4.62433 17.6803L3.5707 19.0093C3.22663
        19.4437 3.26536 20.0632 3.65245 20.4546L5.46742 22.27C5.85878 22.6614 6.47803 22.6958
        6.91241 22.3518L8.23288 21.3065C8.91249 21.6981 9.63486 22.0034 10.3877 22.2182L10.5854
        23.9177C10.65 24.468 11.1145 24.881 11.6649 24.881H14.2326C14.783 24.881 15.2475 24.468
        15.3122 23.9177L15.5013 22.2786C16.31 22.0678 17.0841 21.7537 17.8108 21.3449L19.0882
        22.356C19.5229 22.7004 20.1421 22.6614 20.5335 22.2743L22.3482 20.4591C22.7398 20.0675
        22.774 19.448 22.4302 19.0136L21.4364 17.7533C21.8497 17.039 22.1721 16.2776 22.3869
        15.4821L23.9182 15.3055C24.4688 15.2411 24.8815 14.7766 24.8815 14.2257V11.6577C24.8815
        11.1072 24.4688 10.6426 23.9182 10.578L22.4086 10.4017C22.2021 9.61441 21.8924 8.86162
        21.4968 8.15636L22.4257 6.98202C22.77 6.5474 22.7313 5.92792 22.3442 5.53655L20.5335
        3.72562C20.1421 3.33402 19.5229 3.2998 19.0882 3.64388L17.9486 4.54709C17.2089 4.11271
        16.4174 3.78146 15.5873 3.55762L15.4153 2.08222C15.3509 1.53164 14.8863 1.11865 14.3358
        1.11865H11.7682C11.2177 1.11865 10.7531 1.53164 10.6887 2.08222L10.5167 3.55762C9.66504
        3.78574 8.85213 4.12982 8.0953 4.58154L6.91265 3.64388C6.47827 3.2998 5.85878 3.3383
        5.46742 3.72562L3.65245 5.54083C3.26108 5.9322 3.22663 6.55168 3.5707 6.9863L4.55993
        8.23786C4.16429 8.95216 3.86322 9.7135 3.66528 10.505L2.0827 10.6856C1.53213 10.7503
        1.11914 11.2148 1.11914 11.7654V14.3336C1.11914 14.884 1.53213 15.3485 2.0827
        15.4131L3.7513 15.6068Z"
      stroke="currentColor"
      stroke-width="1.18812"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0007 16.1266C14.7275 16.1266 16.1273 14.7265 16.1273 12.9999C16.1273 11.2731 14.7275
        9.87329 13.0007 9.87329C11.2739 9.87329 9.87402 11.2731 9.87402 12.9999C9.87402 14.7265
        11.2739 16.1266 13.0007 16.1266Z"
      stroke="currentColor"
      stroke-width="1.18812"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Payment extends Vue {
  @Prop({ default: 26 }) readonly width!: number | string;

  @Prop({ default: 26 }) readonly height!: number | string;
}
</script>
