export default {
  showLoadMoreButton: state => !!state.links.next,
  loadMoreNumber: state => {
    const { currentPage, lastPage, perPage, total, to } = state.meta

    return currentPage !== lastPage - 1 ? perPage : (total - to)
  },
  isPageFiltered: state => {
    const { dateRange, ...rest } = state.filters

    return !![...Object.values(rest), ...Object.values(dateRange)]
      ?.filter((value) => value)?.length ?? false
  },
  numberOfActiveFilters: state => {
    const { dateRange, ...rest } = state.filters

    return [...Object.values(rest), ...Object.values(dateRange)]?.filter((value) => value)?.length
  },
  totalNumberOfOrders: state => state?.meta?.total ?? 0,
  params: state => {
    const { filters } = state

    return ({
      q: filters.search,
      date_from: filters.dateRange.start,
      date_to: filters.dateRange.end,
      type: filters.orderType,
      status: filters.orderStatus,
      user: filters.orderUser,
      source: filters.orderSource,
      order_desc: null,
      order_by: null,
      product_id: filters.productId, // sku
    })
  }
}
