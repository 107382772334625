<template>
  <div
    class="deposit-info-item"
    :class="{
      'deposit-info-item--expandable': expandable,
      'deposit-info-item--expanded': expand && expandable
    }"
  >
    <div v-if="title" class="deposit-info-item__title" @click="expand = !expand">
      {{ title }}

      <icons-close
        :width="18"
        :height="18"
        color="#42B77A"
        v-if="expandable"
      />
    </div>

    <v-expand-transition>
      <div class="deposit-info-item__content" v-show="showContent">
        <iframe
          id="deposit-video"
          v-if="videoUrl"
          :src="videoUrl"
          :title="title"
          frameborder="0"
          autoplay="1"
          allow="autoplay; fullscreen;"
          allowfullscreen
          scrolling="no"
        />

        <span v-html="content" />
      </div>
    </v-expand-transition>

    <v-divider />
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DepositInfoItem extends Vue {
  @Prop({ required: false }) title!: string;

  @Prop({ required: true }) content!: string;

  @Prop({ required: false }) videoUrl!: string;

  @Prop({ required: false, default: false }) expandable!: boolean;

  expand = false;

  get showContent (): boolean {
      const { expand, expandable } = this

    return expandable ? expand : true
  }
}
</script>

<style lang="scss" scoped>
.deposit-info-item {
  display: flex;
  flex-direction: column;
  gap: 18px;

  & > div {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }

  &__title {
    & > svg {
      transform: translate(50%) rotate(45deg);
      transform-origin: left top;
      transition: all ease-in-out 0.2s;
    }

    &:first-of-type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 18px;

    & > span {
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #000000;

      &::v-deep > a {
        color: #42B77A;
        text-decoration: none !important;

        &:hover {
          color: #219358 !important;
        }
      }
    }
  }

  &:last-of-type {
    & > hr {
      display: none;
    }
  }

  &--expandable {
    & > div:first-of-type {
      cursor: pointer;
    }
  }

  &--expanded {
    & > div:first-of-type > svg {
      transform:  translate(0) rotate(0deg);
    }
  }
}

#deposit-video {
  aspect-ratio: 16 / 9;
}
</style>
