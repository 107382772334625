<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="8.5" :stroke="color"/>
    <path d="M14.6139 9.4047L4.19685 9.40486" :stroke="color" stroke-width="1.17067"/>
  </svg>
</template>

<script>
export default {
  name: 'MinusCircle',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 19
    },
    height: {
      type: [Number, String],
      required: false,
      default: 19
    },
    color: {
      type: String,
      required: false,
      default: '#FF5A60',
    },
  },
}
</script>
