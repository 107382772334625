export default {
  setInventory (state, data = []) {
    state.inventoryList = data
  },
  updateInventory (state, data) {
    state.inventoryList = state.inventoryList.concat(data)
  },
  setLoading (state, loading = false) {
    state.loading = loading
  },
  setLoadingPage (state, val) {
    state.loadingPage = val
  },
  setCurrentPage (state, val) {
    state.currentPage = val
  },
  setTotalPages (state, val) {
    state.totalPages = val
  },
  setDisableKitWhenAnyItemIsOOSById (state, { itemId, value }) {
    const index = state.inventoryList?.findIndex(({ product }) => product.item_id === itemId)
    state.inventoryList[index].product.kit.flags.disable_when_child_product_out_of_stock = value ?? false
  },
  // filters
  setFiltersCampaignId (state, val = null) {
    state.filters.campaignId = val
  },
  setFiltersProductId (state, val = null) {
    state.filters.productId = val
  },
  setSearch (state, val = null) {
    state.filters.search = val
  },
  setFilterBy (state, val = null) {
    state.filters.filter_by = val
  },
}
