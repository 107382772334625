<template>
  <div class="my-quotes-table-header">
    <div>Quote #</div>
    <div>Quote total</div>
    <div>Date generated</div>
    <div>Expiration date</div>
    <div>Status</div>
    <div></div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MyQuotesTableHeader extends Vue {}
</script>

<style lang="scss" scoped>
.my-quotes-table-header {
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  justify-content: space-between;
  gap: 30px;

  & > div {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    flex: 1 1 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
