<template>
  <v-menu
    max-width="200"
    open-on-click
    :attach="$el"
    offset-y
    bottom
    left
    tile
    z-index="4"
    content-class="tray-products__menu"
  >
    <template #activator="{ on, attrs }">
      <div class="tray-products__menu__activator" v-bind="attrs" v-on="on">
        <icons-settings-kebab />
      </div>
    </template>
    <v-list dense>
      <v-list-item dense @click="$emit('onShowKitPanel')">
        <v-list-item-title>
          <icons-settings-line :width="20" :height="20" />

          Kit items
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="superAdmin || masterAccount" dense @click="$emit('onAssignUsers')">
        <v-list-item-title>
          <icons-sidebar-sub-account :width="18" :height="19" color="#95979D" />

          Access to users
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

export default {
  name: 'TrayKitOptions',
  mixins: [
    superAdmin,
    masterAccount,
  ],
}
</script>
