import { Vue, Component } from 'vue-property-decorator'

@Component
export default class TakeCurrentDate extends Vue {
  takeCurrentDate (): string {
    const date = new Date()
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date.toISOString()
  }
}