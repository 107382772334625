export default {
  data: () => ({
    iconTooltipProps: {
      placement: 'bottom',
      trigger: 'hover',
      classes: 'inventory-status__icon-tooltip',
      offset: 12,
      boundariesElement: 'div',
    }
  }),
}
