<template>
  <v-navigation-drawer
    :value='isShowSettingsPanel'
    app
    temporary
    right
    width='950px'
    class='side_panel'
    v-click-outside="clickOutside"
  >
    <close-panel-button
      @close='setIsShowSettingsPanel(false)'
      :text="'Cancel'"
    />
    <main class="px-10">
      <div class="integration-setting">

        <p class="integration-setting__title">Survey Monkey Integration Settings</p>

        <div class="integration-setting__field">
          <label class="integration-setting__label">Enter your SurveyMonkey account ID:</label>
          <input class="integration-setting__input" type="text" v-model="credentials.clientId">
          <span class="red1" v-show="clientIdError">{{clientIdError}}</span>
        </div>

        <div class="integration-setting__field">
          <label class="integration-setting__label">Enter your SurveyMonkey account secret:</label>
          <input class="integration-setting__input" type="text" v-model="credentials.secret">
          <span class="red1" v-show="secretError">{{secretError}}</span>
        </div>

        <div class="integration-setting__field">
          <label class="integration-setting__label">Enter your SurveyMonkey token:</label>
          <input class="integration-setting__input" type="text" v-model="credentials.accessToken">
          <span class="red1" v-show="accessTokenError">{{accessTokenError}}</span>
        </div>
      </div>

      <common-button
        @click="submit()"
        style="width: 133px; font-size: 13px;"
        :height="40"
      >
        Save Changes
      </common-button>
    </main>
  </v-navigation-drawer>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import Api from '@/axios/api'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('surveyMonkey')

export default {
  name: 'SettingsPanel',
  props: {},
  data: () => {
    return {
      clientIdError: '',
      secretError: '',
      accessTokenError: ''
    }
  },
  computed: {
    ...mapState([
      'isShowSettingsPanel',
      'credentials'
    ])
  },
  methods: {
    ...mapMutations([
      'setIsShowSettingsPanel'
    ]),
    ...mapActions([
      'fetchCredentials'
    ]),
    async submit () {
      const isFormValid = this.validateForm()

      if (!isFormValid) {
        return
      }

      await Api.post('/customer/survey-monkey/credentials', this.credentials)
        .then(response => {
          if (response.success) {
            this.setIsShowSettingsPanel(false)
            this.$router.push({ name: 'SurveyMonkey' })
          } else {
            alert(response.errMsg ? response.errMsg : 'Something went wrong')
          }
        })
        .catch(err => {
          console.log(err)
          alert('Something went wrong')
        })
    },
    validateForm () {
      let isValid = true

      if (!this.credentials.clientId) {
        this.clientIdError = 'Client Id is required'
        isValid = false
      }

      if (!this.credentials.secret) {
        this.secretError = 'Secret is required'
        isValid = false
      }

      if (!this.credentials.secret) {
        this.accessTokenError = 'Access token is required'
        isValid = false
      }

      return isValid
    },
    clickOutside (e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.setIsShowSettingsPanel(false)
      }
    }
  },
  mounted () {
    this.fetchCredentials()
  }
}
</script>

<style lang="scss" scoped>
  @import "./src/css/integrations/settings-panel";
  .red1 {
    display: block;
  }
</style>
