<template>
  <popper
    :visible-arrow="true"
    :disabled="!show || disabled"
    :force-show="true"
    trigger="click"
    :options="{
      placement: 'left',
      modifiers: { offset: { offset: '0,10px' } }
    }"
  >
    <div class="popper verified-link-type-tooltip">
      You must enter recipients email addresses so we can verify them before redeem

      <div class="verified-link-type-tooltip__actions">
        <div @click="show = false">Dismiss</div>
      </div>
    </div>
    <div style="width: fit-content" slot="reference">
      <slot>
      </slot>
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs'

export default {
  name: 'TheVerifiedLinkTypeTooltip',
  components: {
    Popper,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    show: true,
  }),
}
</script>

<style lang="scss">
.verified-link-type-tooltip {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  text-transform: none;
  padding: 12px;
  width: 350px;
  min-width: 350px;
  line-height: 22px;
  background: #D2F5F3;
  color: #222325;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;

    & > div, & > a {
      cursor: pointer;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 16px;
      text-decoration-line: underline;
      color: #222325;
    }
  }

  & > .popper__arrow {
    height: 20px;
    width: 20px;
    background: inherit;
    transform: rotate(45deg);
    position: absolute;
    border-color: transparent !important;
    left: -10px;
  }
}
</style>