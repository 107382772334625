export default {
  data: () => ({
    permissionsLevels: [
      { value: 100, text: 'Read Only' },
      { value: 200, text: 'Sub account' },
      { value: 300, text: 'Admin' },
      { value: 400, text: 'Super Admin' }
    ],
  }),
}
