// NOT REAL PLUGIN FOR NOW SOMETHING LIKE UTILS - TO PLUGIN NEED INSTALL AND ADD TO GLOBAL VUE
// import Vuex object to have access to header data 
import store from '../store'

const tagManager = window?.dataLayer ?? null

const isTagManagerSet = () => {
  if (!tagManager) throw new Error('Tag manager not detected');
  return !!tagManager
}

const userType = () => {
  return store.state.header?.headerData?.userType ?? undefined
}

const beginCheckout = ({ value, coupon, checkoutType, items }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'begin_checkout',
    'ecommerce': {
      'value': value,
      'currency': 'USD',
      'coupon': coupon,
      'type': checkoutType,
      'user_type': userType(),
      'items': items,
    }
  })
}

const addShippingInfo = ({ value, coupon, shippingTier, items }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'add_shipping_info',
    'ecommerce': {
      'value': value,
      'currency': 'USD',
      'coupon': coupon,
      'shipping_tier': shippingTier,
      'items': items,
    }
  })
}

const addPaymentInfo = ({ value, coupon, items }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'add_payment_info',
    'ecommerce': {
      'value': value,
      'currency': 'USD',
      'coupon': coupon,
      'items': items,
    }
  })
}

const paymentDeclined = ({ paymentType, action, type }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'payment_declined',
    'payment_type': paymentType,
    'action': action,
    'type': type,
  })
}

const purchase = ({ paymentType, email, userId, parentAccount, transactionId, checkoutType, value, coupon, tax, shipping, items }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'purchase',
    'payment_type': paymentType,
    'user_id': userId,
    'email': email,
    'parent_account': parentAccount,
    'ecommerce': {
      'type': checkoutType,
      'user_type': userType(),
      'transaction_id': transactionId,
      'value': value,
      'currency': 'USD',
      'coupon': coupon,
      'tax': tax,
      'shipping': shipping,
      'items': items,
    }
  })
}

const destinationDeclined = ({ action }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'destination_declined',
    'action': action,
  })
}

const custom = (tagManagerObject) => {
  if (!isTagManagerSet()) return

  tagManager.push(tagManagerObject)
}

const creditsOpenPanel = () => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'buy_cg_credits',
    'action': 'open modal',
  })
}

const creditsSelectPaymentMethod = ({ paymentType }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'buy_cg_credits',
    'action': 'select payment method',
    'method': paymentType,
  })
}

const creditsPurchase = ({  paymentType, value }) => {
  if (!isTagManagerSet()) return

  tagManager.push({
    'event': 'buy_cg_credits',
    'action': 'payment',
    'method': paymentType,
    'value': value,
  })
}

export {
  custom,
  purchase,
  beginCheckout,
  addShippingInfo,
  addPaymentInfo,
  paymentDeclined,
  creditsPurchase,
  creditsOpenPanel,
  destinationDeclined,
  creditsSelectPaymentMethod,
}
