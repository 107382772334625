<template>
  <v-card outlined tile class="inventory-card" min-height="430" width="350">
    <inventory-card-tray
      :qty="item.product.stock.qty"
      :inProduction="item.product.stock.qty_in_production"
      :onHold="item.product.stock.qty_on_hold"
      :minQty="item.product.notify_for_qty_below.value"
      :balance="item.product.balance"
      :balancePeriod="item.product.period"
      :variants="productVariants"
      @productSettings="showProductSettings()"
      @ordersHistory="showOrdersHistory()"
      @onHoldUnits="showProductOnHold()"
      @assignUsers="showAssignUsers()"
    />

    <popper
      :options="{
        placement: 'top',
        modifiers: {
          flip: { enabled: false },
          offset: {
            offset: '0,-53px',
          },
        },
      }"
    >
      <div
        class="popper pa-4 text-left"
        :style="!item.product.flags.is_disabled && 'display: none'"
        style="z-index: 4"
      >
        Purchasing this item is currently disabled. Click "Product settings" to enable purchasing this item again.
      </div>

      <product-image
        :altText="item.product.name"
        :image="item.product.image"
        height="240"
        slot="reference"
      >
        <div class="inventory-card__locked" v-if="item.product.flags.is_disabled">
          <v-img
            max-width="32"
            width="32"
            height="40"
            src="/images/lock_white.svg"
            alt="Lock icon"
          />
        </div>
      </product-image>
    </popper>

    <v-card-text class="px-6 pt-4 pb-0" style="height: 121px">
      <a
        v-if="item.product.product_url"
        class="product-name"
        :href="productUrl"
        target="_blank"
      >
        {{ item.product.name }}
      </a>

      <h1 v-else class="product-name">
        {{ item.product.name }}
      </h1>

      <div class="product-variants" v-if="productVariants.length > 1">
        <update-status-sheet
          color="#D2F5F3"
          textColor="#004642"
          v-for="(variantOption, index) in Object.keys(productVariantsOptions)"
          :key="index"
        >
          {{ productVariantsOptions[variantOption].length }} {{ variantOption }} options
        </update-status-sheet>

        <popper
          :options="{
            placement: 'top',
            modifiers: {
              offset: {
                offset: '0,3px'
              },
            }
          }"
        >
          <div class="popper pa-3 f14 text-left" style="max-width: 200px">
            Hover the numbers in the card to see specifications and breakdown
          </div>
          <icons-question
            width="20"
            height="20"
            color="#62646A"
            class="pointer"
            slot="reference"
          />
        </popper>
      </div>

      <div class="f14 product-description ma-0">
        <span v-html="item.product.description" />
      </div>
    </v-card-text>

    <v-card-actions class="pa-6 pt-3">
      <common-button
        v-if="superAdmin || masterAccount"
        block
        :height="44"
        outlined
        @click="refillProduct()"
      >
        Order refill
      </common-button>

      <inventory-card-send-button :item="item" />
    </v-card-actions>
  </v-card>
</template>

<script>
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import genInventoryProductUrl from './utils/genInventoryProductUrl'

import InventoryCardTray from './InventoryCardTray'
import ProductImage from './ProductImage'
import UpdateStatusSheet from '@/components/UpdateStatusSheet'
import InventoryCardSendButton from './InventoryCardSendButton.vue'
import Popper from 'vue-popperjs'

import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

export default {
  name: 'InventoryCard',
  components: {
    Popper,
    ProductImage,
    InventoryCardTray,
    UpdateStatusSheet,
    InventoryCardSendButton,
  },
  mixins: [
    superAdmin,
    masterAccount,
  ],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    productVariants () {
      return this.item.product?.variations?.short || []
    },
    productVariantsOptions () {
      const variants = [].concat(...this.productVariants.map((item) => item.variants))
      const uniqueVariants = uniqBy(variants, item => item.value)
      return groupBy(uniqueVariants, item => item.variant)
    },
    productUrl () {
      const { product_url } = this.item.product

      return genInventoryProductUrl(product_url)
    },
  },
  methods: {
    getProductId () {
      const { product: { product_id: productId } } = this.item
      return productId
    },
    refillProduct () {
      const productId = this.getProductId()

      this.$emit('showRefillPanel', productId)
    },
    // TODO remove
    showRefillHistory () {
      const productId = this.getProductId()
      const { name: productName } = this.item?.product ?? {}
      // TODO add one action
      this.$store.commit('transactions-history/setSearch', productName);
      this.$store.commit('transactions-history/setFiltersProductId', productId);
      this.$store.commit('transactions-history/setFiltersTypes', ['in']);
      this.$store.commit('transactions-history/setFiltersCampaignId');
      this.$store.commit('transactions-history/setFiltersUserId');
      this.$store.commit('transactions-history/setFiltersDateRange', { start: null, end: null });
      this.$store.commit('transactions-history/setFiltersOrderId');

      // move to transactions page
      const query = Object.assign({}, this.$route.query)
      query.tab = 'transactions'
      this.$router.replace({ query }).catch(() => {})
    },
    showOrdersHistory () {
      const productId = this.getProductId()
      const { name: productName } = this.item?.product ?? {}
      // TODO add one action
      this.$store.commit('transactions-history/setSearch', productName);
      this.$store.commit('transactions-history/setFiltersProductId', productId);
      this.$store.commit('transactions-history/setFiltersTypes');
      this.$store.commit('transactions-history/setFiltersCampaignId');
      this.$store.commit('transactions-history/setFiltersUserId');
      this.$store.commit('transactions-history/setFiltersDateRange', { start: null, end: null });
      this.$store.commit('transactions-history/setFiltersOrderId');

      // move to transactions page
      const query = Object.assign({}, this.$route.query)
      query.tab = 'transactions'
      this.$router.replace({ query }).catch(() => {})
    },
    showProductSettings () {
      const productId = this.getProductId()

      this.$emit('showProductSettingsPanel', productId)
    },
    showProductOnHold () {
      const productId = this.getProductId()
      this.$emit('showUnitsOnHoldPanel', productId)
    },
    showAssignUsers () {
      const productId = this.getProductId()
      this.$emit('showAssignUsersPanel', productId)
    }
  }
}
</script>

<style lang="scss">
.inventory-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .left-100 {
    left: 100%;
  }

  &__menu {
    padding: 0px !important;

    .v-list {
      padding: 17px 0px;
    }

    .v-list-item {
      padding: 0px 17px !important;
      color: #16181f !important;
      cursor: pointer;

      &--dense {
        min-height: 30px;
      }
      &__icon {
        margin: 3px 16px 3px 0px !important;
      }

      &__title {
        font-family: Lato, sans-serif !important;
        font-size: 14px !important;
        font-weight: bold !important;
        letter-spacing: 0 !important;
        line-height: 30px !important;

        &--regular {
          font-weight: normal !important;
        }
      }
    }
  }

  &__locked {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: rgba(22, 24, 31, 0.6);
    border-radius: 50%;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    color: #FFFFFF;
    display: grid;
    place-items: center;
  }

  .v-card__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-name {
      line-height: 18px;
      font-family: 'Lato-Bold';
      font-size: 15px;
      letter-spacing: 0;
      display: inline-block;
      color: #222325;
      font-weight: bold;

      &:is(a) {
        cursor: pointer;

        &:hover {
          color: #219358 !important;
        }
      }
    }

    .product-variants {
      display: flex;
      align-items: center;
      gap: 8px;

      span:first-of-type {
        display: flex;
      }
    }

    .product-description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 17px;
      max-height: 35px;
      min-height: 35px;
      font-family: Lato, sans-serif;
      font-weight: normal;
      letter-spacing: 0;
      color: #919191;

      & li {
        list-style: unset !important;
      }
      & p {
        margin-bottom: 0px !important;
      }
    }
  }

  .v-card__actions {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      font-size: 18px;
      letter-spacing: 0;
      text-transform: unset;
      font-weight: 600 !important;
    }

    .v-btn--outlined {
      border-width: 2px;
    }
  }
}
</style>
