<template>
  <div class="payment-module-achs-container">
    <div class="change-payment-method-link" @click="$emit('update:step', 10)">
      + Add new  ACH
    </div>

    <ach-item
      v-for="ach in availableAchs"
      :key="ach.publicAccountNumber"
      :data="ach"
      show-default-icon
      :isSelected="ach.publicAccountNumber === selectedAchHelper"
      :class="{ 'credit-card-item--is-selected': ach.publicAccountNumber === selectedAchHelper }"
      @deletePaymentMethod="onDeleteAch(ach.publicAccountNumber)"
      @click.native="selectedAchHelper = ach.publicAccountNumber"
    />

    <cg-button :disabled="!selectedAchData" @click="onContinue">Continue</cg-button>
  </div>
</template>

<script>
import PaymentType from '../utils/PaymentType'

import AchItem from '@/components/payment/AchItem.vue'
import { CgButton } from '@corporategift/design-system'

export default {
  name: 'PayWithBluesnapAch',
  components: {
    AchItem,
    CgButton,
  },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    availableAchs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedAchHelper: null,
  }),
  computed: {
    defaultAchAccount () {
      const { availableAchs } = this

      if (!availableAchs.length) { return null }
      return availableAchs?.at(0);
    },
    selectedAchData () {
      const { selectedAchHelper, availableAchs } = this

      if (!selectedAchHelper) { return null }
      return availableAchs.find((cardData) => cardData.publicAccountNumber === selectedAchHelper)
    },
  },
  created () {
    const { paymentData, defaultAchAccount } = this
    if (paymentData && paymentData?.publicAccountNumber) {
      this.selectedAchHelper = paymentData?.publicAccountNumber
    } else if (defaultAchAccount) {
      this.selectedAchHelper = defaultAchAccount?.publicAccountNumber ?? null
    }
  },
  methods: {
    onContinue () {
      const { selectedAchData } = this

      if (selectedAchData) {
        this.$emit('onAchSelect', PaymentType.ACH_Bluesnap, selectedAchData);
      }
    },
    onDeleteAch (achPublicNumber) {
      this.$emit('deleteBluesnapAch', achPublicNumber)
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-module-achs-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > .credit-card-item {
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color ease-in-out 0.26s;

    &:not(&--is-selected):hover {
      background-color: #F4F4F4;
    }

    &--is-selected {
      border-color: #D3D2D2;
      cursor: default;
      background-color: #fff;
    }
  }

  & .change-payment-method-link {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    color: #42B77A;
  }

  & .cg-button {
    margin-top: 20px;
    border-radius: 100px;
  }
}
</style>
