<template>
  <div class="campaign-automation-group-info">
    <icons-sidebar-sub-account :width="19" :height="20" color="#62646A" />

    {{ contactsCount }} Contacts

    <div class="campaign-automation-group-info__dot" />

    <v-popover
      placement="top"
      trigger="hover"
      :container="false"
    >
      <span class="campaign-automation-group-info__link">
        {{ emailsCount }} emails set
      </span>

      <group-info-tooltip slot="popover" :contacts="groupContacts" trigger="email" />
    </v-popover>

    <template v-if="trigger">
      <div class="campaign-automation-group-info__dot" />

      <v-popover
        placement="top"
        trigger="hover"
        :container="false"
      >
        <span class="campaign-automation-group-info__link">
          {{ tooltipText }}
        </span>

        <group-info-tooltip slot="popover" :contacts="groupContacts" :trigger="selectedTrigger" />
      </v-popover>
    </template>
  </div>
</template>

<script>
import GroupInfoTooltip from './AutomationCampaignPanelGroupInfoTooltip.vue'

export default {
  name: 'AutomationCampaignPanelGroupInfo',
  components: { GroupInfoTooltip },
  props: {
    group: {
      type: Object,
      required: true,
    },
    trigger: {
      type: String,
      required: false,
    },
  },
  computed: {
    groupContacts () { return this.group?.contacts ?? [] },
    contactsCount () { return this.group?.contacts_count ?? 0 },
    emailsCount () { return this.group?.emails_count ?? 0 },
    birthdayDateCount () { return this.group?.birthdays_count ?? 0 },
    anniversaryCount () { return this.group?.anniversaries_count ?? 0 },
    selectedTrigger () { return this.trigger || 'birthday' },
    tooltipText () {
      const { selectedTrigger, birthdayDateCount, anniversaryCount } = this

      if (selectedTrigger === 'birthday') { return `${birthdayDateCount} birthday set` }
      return `${anniversaryCount} anniversary set`
    },
  },
}
</script>

<style lang="scss" scoped>
.campaign-automation-group-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 8px;

  & > b {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #95979D;
  }

  &__link {
    color: #6E50FF;
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    cursor: pointer;
  }
}
</style>
