<template>
  <div
    class="review-field"
    :class="{
      'review-field__column': (editMode && editModeAsColumn) || (!editMode && reviewModeAsColumn),
      'review-field--disable-hover-tooltip': disableHoverTooltip,
    }"
    :aria-disabled="disabled"
    @mousemove="handleTooltip"
    @click="!hideHoverTooltip && onLabelClick()"
  >
    <div
      class="review-field__label"
      :class="{
        'review-field__label--active': editMode,
        'review-field__label--allow-label-click': allowToClickLabelInEditMode && !disabled,
      }"
      :aria-disabled="disabled"
      @click="!disableLabelClick && onLabelClick()"
    >
      <slot name="label" />
    </div>

    <div v-if="editMode">
      <slot name="edit-mode" />
    </div>

    <div v-else class="review-field__review">
      <slot name="review-mode" />
    </div>

    <div v-show="!hideHoverTooltip" class="review-field__tooltip">Click to edit</div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReviewField extends Vue {
  @Prop({ default: true, required: false }) editable!: boolean;

  @Prop({ default: false, required: false }) disabled!: boolean;

  @Prop({ default: null, required: false }) fieldKey!: string | null;

  @Prop({ default: false, required: false }) editModeAsColumn!: boolean;

  @Prop({ default: false, required: false }) reviewModeAsColumn!: boolean;

  @Prop({ default: false, required: false }) allowToClickLabelInEditMode!: boolean;

  @Prop({ default: false, required: false }) disableHoverTooltip!: boolean;

  editMode = false;

  get disableLabelClick (): boolean {
    const { editMode, allowToClickLabelInEditMode, disabled } = this

    if (disabled) { return true }

    if (allowToClickLabelInEditMode) { return false }

    return editMode
  }

  get hideHoverTooltip (): boolean {
    const { disableHoverTooltip, editMode, disabled } = this

    return editMode || disableHoverTooltip || disabled
  }

  onLabelClick(): void {
    const newValue = !this.editMode

    const label: HTMLDivElement | null = this.$el.querySelector('review-field__label')

    if (label) { label.classList.remove('review-field__label--error') }

    if (this.editable) { this.editMode = newValue }

    this.$emit('onLabelClick', { value: newValue, key: this.fieldKey })
  }

  handleTooltip (event: MouseEvent) {
    const { hideHoverTooltip } = this

    const tooltip: HTMLDivElement | null = this.$el.querySelector('.review-field__tooltip')

    if (tooltip && !hideHoverTooltip) {
      const rootEl = this.$el as HTMLDivElement
      const rootLeft = rootEl?.getBoundingClientRect()?.left ?? 0

      const { pageX, clientY } = event
      

      tooltip.style.left = `${pageX - (rootLeft - 10)}px`
      tooltip.style.top = `${clientY + 28}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.review-field {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;

  &[aria-disabled]:hover {
    box-shadow: none;
    cursor: default;

    & .review-field__tooltip {
      display: none;
    }
  }

  &:hover {
    & .review-field__tooltip {
      display: block;
    }
  }

  &:not(&--disable-hover-tooltip):not([aria-disabled]) {
    cursor: pointer;

    &:hover {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    }
  }

  &:has(.review-field__label--active) {
    cursor: default;
  }

  &__column {
    flex-direction: column;

    & > div {
      &:nth-child(1) {
        flex: 0 1 auto;
      }

      &:nth-child(2) {
        flex: 0 1 auto !important;
        height: fit-content;
      }
    }
  }

  & > div {
    &:nth-child(1) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      flex: 0 1 auto;
    }

    &:nth-child(2) {
      display: flex;
      flex: 0 1 258px;
      min-width: 258px;
    }
  }

  &__label {
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: #42B77A;
    cursor: pointer;
    height: fit-content;

    &:not(&--active):not(&[aria-disabled]):hover {
      color: #219358;
    }

    &[aria-disabled],
    &--active {
      color: #000000;
      cursor: default;
    }

    &--allow-label-click {
      cursor: pointer;

      &:not(.review-field__label--active):hover {
        color: #219358;
      }
    }

    &--error {
      color: #FF5A60;
    }
  }

  &__review {
    font-family: 'Lato-Regular', sans-serif;
    word-break: break-word;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &__tooltip {
    display: none;
    padding: 6px 10px;
    position: absolute;
    z-index: 1;
    background-color: #4A4A4A;
    border-radius: 3px;
    color: #fff;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }
}
</style>
