<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="CG_credits-3.0-"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="My-CG-Credits-0.3_budget-entities"
        transform="translate(-1134.000000, -665.000000)"
        fill="#919191"
        fill-rule="nonzero"
      >
        <g id="entitity-icon" transform="translate(1134.000000, 665.000000)">
          <path
            d="M15.0957893,-1.04517089e-16 C14.9818842,-0.00247584754 14.8700076,0.0303847707 14.7755343,0.0940659485 C13.0603729,1.24875257 11.2568013,2.16437753 9.40515854,2.98066981 C9.12636706,3.10340945 8.99986205,3.42891474 9.12260169,3.70770622 C9.24534133,3.98649771 9.57084662,4.11300272 9.8496381,3.99026308 C11.5502255,3.24056315 13.2243048,2.37184106 14.8480583,1.33272058 L15.0584501,1.77001951 C15.1416813,1.95121233 15.3162314,2.07337153 15.5149714,2.08951683 C15.7137115,2.10566212 15.9056906,2.01327911 16.017077,1.84789669 C16.1284633,1.68251426 16.1419102,1.46988835 16.0522461,1.29179113 L15.5804802,0.31235639 C15.4905443,0.125345706 15.3032498,0.00464483598 15.0957893,-2.67770832e-16 L15.0957893,-1.04517089e-16 Z"
            id="Path"
          />
          <path
            d="M1.65441176,2.94117646 C0.747237353,2.94117646 0,3.68841382 0,4.59558824 L0,17.4632353 C0,18.7765017 1.07643982,19.8529412 2.38970588,19.8529412 L17.4632353,19.8529412 C18.7765016,19.8529412 19.8529412,18.7765016 19.8529412,17.4632353 L19.8529412,6.80147059 C19.8529412,5.89429577 19.1056992,5.1470646 18.1985294,5.14705882 L9.5236386,5.14705882 C9.35986531,5.14705778 9.20509409,5.0742892 9.09998279,4.94815602 L7.92379945,3.53644874 C7.60980675,3.15965808 7.14372386,2.94117646 6.65283206,2.94117646 L1.65441176,2.94117646 Z M1.65441176,4.04411765 L6.65283199,4.04411765 C6.81660115,4.04411765 6.97137562,4.11688606 7.07648779,4.24302045 L8.25267114,5.65472773 C8.56666464,6.03151887 9.03275085,6.24999688 9.52363853,6.25000001 L18.1985293,6.25000001 C18.5096336,6.25000199 18.7499999,6.49036999 18.7499999,6.8014706 L18.7499999,17.4632353 C18.7499999,18.1804285 18.1804285,18.75 17.4632352,18.75 L2.38970581,18.75 C1.67251298,18.75 1.1029411,18.1804284 1.1029411,17.4632353 L1.1029411,4.59558824 C1.1029411,4.28448721 1.34331066,4.04411765 1.65441169,4.04411765 L1.65441176,4.04411765 Z"
            id="Shape"
          />
          <path
            d="M17.4294864,2.19798368 C15.5446138,2.81873441 13.8187579,3.22361695 12.1675379,3.51921529 C11.436173,3.64365812 11.6330742,4.74223588 12.3621323,4.60492301 C13.8701557,4.33495947 15.4666747,3.94837871 17.16524,3.42084099 L17.2693588,4.01252298 C17.3971736,4.73680651 18.4835993,4.5450843 18.3557846,3.82080078 L18.1446748,2.62594784 C18.0868456,2.29864252 17.7581044,2.09343927 17.4294863,2.19798368 L17.4294864,2.19798368 Z"
            id="Path"
          />
          <path
            d="M9.91785368,8.45581492 C9.6135666,8.46063607 9.37063474,8.71095872 9.37499982,9.01525162 L9.37499982,9.2364143 C8.64271835,9.4060358 8.08823511,10.0651367 8.08823511,10.8455882 L8.08823511,11.3970588 C8.08823511,12.304064 8.83564165,13.0514706 9.74264688,13.0514706 L10.1102939,13.0514706 C10.4253476,13.0514706 10.6617645,13.2878876 10.6617645,13.6029412 L10.6617645,14.1544118 C10.6617645,14.4694654 10.4253476,14.7058824 10.1102939,14.7058824 L9.74264688,14.7058824 C9.42759325,14.7058824 9.19117629,14.4694654 9.19117629,14.1544118 C9.19398895,13.9555317 9.08949873,13.7705434 8.91771699,13.6702825 C8.74593526,13.5700216 8.53347614,13.5700216 8.3616944,13.6702825 C8.18991266,13.7705434 8.08542245,13.9555317 8.08823511,14.1544118 C8.08823511,14.9348633 8.64271835,15.5939642 9.37499982,15.7635857 L9.37499982,15.9847484 C9.37218716,16.1836285 9.47667737,16.3686168 9.64845911,16.4688776 C9.82024085,16.5691385 10.0327,16.5691385 10.2044817,16.4688776 C10.3762634,16.3686168 10.4807537,16.1836285 10.477941,15.9847484 L10.477941,15.7635857 C11.2102225,15.5939642 11.7647057,14.9348633 11.7647057,14.1544118 L11.7647057,13.6029412 C11.7647057,12.695936 11.0172992,11.9485294 10.1102939,11.9485294 L9.74264688,11.9485294 C9.42759325,11.9485294 9.19117629,11.7121124 9.19117629,11.3970588 L9.19117629,10.8455882 C9.19117629,10.5305346 9.42759325,10.2941176 9.74264688,10.2941176 L10.1102939,10.2941176 C10.4253476,10.2941176 10.6617645,10.5305346 10.6617645,10.8455882 C10.6589519,11.0444683 10.7634421,11.2294566 10.9352238,11.3297175 C11.1070056,11.4299784 11.3194647,11.4299784 11.4912464,11.3297175 C11.6630281,11.2294566 11.7675184,11.0444683 11.7647057,10.8455882 C11.7647057,10.0651367 11.2102225,9.40603577 10.477941,9.2364143 L10.477941,9.01525162 C10.4800802,8.86612712 10.4217348,8.72249322 10.3162094,8.61710309 C10.2106839,8.51171296 10.0669753,8.45355185 9.91785368,8.45581492 L9.91785368,8.45581492 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AddBudget',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    }
  }
}
</script>
