<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 13"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M5.12288 4.6736C5.27137 4.89513 5.21481 5.19688 4.99656 5.3476L2.46744 7.09409C2.36264
        7.16647 2.23379 7.19362 2.10926 7.16958C1.98473 7.14553 1.87471 7.07226 1.8034
        6.96588L0.0827169 4.39882C-0.0657682 4.17729 -0.00920963 3.87553 0.209044
        3.72482C0.427298 3.5741 0.724598 3.63151 0.873084 3.85304L1.83369 5.28615C2.38775 2.32753
        4.87158 0.1427 7.83564 0.00669549C10.7997 -0.129309 13.4676 1.81914 14.2824 4.71494C15.0972
        7.61075 13.8455 10.6954 11.2578 12.1688C8.67002 13.6422 5.42402 13.1183 3.41412
        10.9029C3.23704 10.7057 3.25018 10.4003 3.44353 10.2194C3.63688 10.0386 3.93784 10.0502
        4.11709 10.2454C5.84471 12.1511 8.64353 12.5855 10.8533 11.291C13.0631 9.99641 14.0925
        7.31926 13.3309 4.84761C12.5694 2.37596 10.2206 0.771173 7.67787 0.985202C5.13517 1.19923
        3.07891 3.17481 2.72888 5.74003L4.45884 4.54538C4.56365 4.47301 4.69249 4.44586 4.81703
        4.4699C4.94156 4.49395 5.05158 4.56722 5.12288 4.6736Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'AutoRefillAlt',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 15
    },
    height: {
      type: [Number, String],
      required: false,
      default: 13
    },
    color: {
      type: String,
      required: false,
      default: '#62646A'
    },
  }
}
</script>
