<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 16"
    fill="none"
    :width="width"
    :height="height"
  >
    <g>
      <path d="M8.05127 14.7104L1.49999 7.87254" :stroke="color" stroke-width="1.5"/>
      <path d="M8.25 1.55688L1.69872 8.39479" :stroke="color" stroke-width="1.5"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeft',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 9
    },
    height: {
      type: [Number, String],
      required: false,
      default: 16
    },
    color: {
      type: String,
      required: false,
      default: '#fff'
    }
  }
}
</script>
