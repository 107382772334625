<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 15"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4974 5.3369C15.9196 5.2047 16.3571 5.13771 16.8035 5.13771C19.1951 5.13771 21.1408
        7.05583 21.1408 9.41345C21.1408 11.7711 19.1951 13.6892 16.8035 13.6892H5.33726C2.94572
        13.6892 1 11.7711 1 9.41345C1 7.8229 1.89731 6.37131 3.31078 5.6348C3.19873 5.35423 3.14032
        5.05398 3.14032 4.75139C3.14032 3.42201 4.23759 2.3403 5.5861 2.3403C6.05844 2.3403 6.51887
        2.47632 6.91284 2.72809C7.8027 1.49596 9.21855 0.77002 10.7628 0.77002C13.3398 0.77002
        15.4425 2.80947 15.4974 5.3369ZM5.33726 13.0875H16.8035C18.8586 13.0875 20.5305 11.4394
        20.5305 9.41345C20.5305 7.38751 18.8586 5.73938 16.8035 5.73938C16.2838 5.73938 15.7778
        5.84515 15.2992 6.05373C15.2002 6.09604 15.0867 6.08458 14.9994 6.02406C14.9118 5.96266
        14.8632 5.86042 14.8715 5.75495L14.8715 5.75488C14.8799 5.65003 14.8882 5.54517 14.8882
        5.43855C14.8882 3.19609 13.0376 1.37169 10.7628 1.37169C9.32107 1.37169 8.00714 2.0991
        7.2487 3.31742C7.20161 3.39263 7.12354 3.44405 7.03473 3.45727C6.94652 3.47107 6.85622
        3.44522 6.78827 3.38705C6.45361 3.10002 6.02685 2.94197 5.5861 2.94197C4.57405 2.94197
        3.75064 3.75369 3.75064 4.75139C3.75064 5.0581 3.83051 5.36158 3.9819 5.62951C4.02362
        5.70354 4.03167 5.79138 4.00455 5.87159C3.97713 5.95179 3.91664 6.01701 3.83826
        6.05109C2.48469 6.63836 1.61033 7.95804 1.61033 9.41345C1.61033 11.4394 3.28217 13.0875
        5.33726 13.0875Z"
      fill="currentColor"
    />
    <path
      d="M15.4974 5.3369L15.2474 5.34232L15.2546 5.67485L15.572 5.57548L15.4974 5.3369ZM3.31078
        5.6348L3.4263 5.8565L3.6268 5.75203L3.54295 5.54207L3.31078 5.6348ZM6.91284 2.72809L6.77822
        2.93875L6.97723 3.06593L7.11551 2.87446L6.91284 2.72809ZM15.2992 6.05373L15.3975
        6.28361L15.3991 6.28291L15.2992 6.05373ZM14.9994 6.02406L14.8559 6.22879L14.857
        6.22953L14.9994 6.02406ZM14.8715 5.75495L14.6224 5.73461L14.6223 5.73524L14.8715
        5.75495ZM14.8715 5.75488L15.1207 5.77522L15.1207 5.77471L14.8715 5.75488ZM7.2487
        3.31742L7.4606 3.45009L7.46094 3.44955L7.2487 3.31742ZM7.03473 3.45727L6.99792
        3.20998L6.99607 3.21027L7.03473 3.45727ZM6.78827 3.38705L6.62552 3.57682L6.62569
        3.57696L6.78827 3.38705ZM3.9819 5.62951L4.1997 5.50677L4.19956 5.50652L3.9819
        5.62951ZM4.00455 5.87159L4.24111 5.95245L4.24138 5.95167L4.00455 5.87159ZM3.83826
        6.05109L3.93777 6.28043L3.93795 6.28035L3.83826 6.05109ZM16.8035 4.88771C16.3319 4.88771
        15.8692 4.95852 15.4227 5.09832L15.572 5.57548C15.9701 5.45087 16.3824 5.38771 16.8035
        5.38771V4.88771ZM21.3908 9.41345C21.3908 6.91441 19.3298 4.88771 16.8035
        4.88771V5.38771C19.0604 5.38771 20.8908 7.19726 20.8908 9.41345H21.3908ZM16.8035
        13.9392C19.3298 13.9392 21.3908 11.9125 21.3908 9.41345H20.8908C20.8908 11.6296 19.0604
        13.4392 16.8035 13.4392V13.9392ZM5.33726 13.9392H16.8035V13.4392H5.33726V13.9392ZM0.75
        9.41345C0.75 11.9125 2.81102 13.9392 5.33726 13.9392V13.4392C3.08041 13.4392 1.25 11.6296
        1.25 9.41345H0.75ZM3.19525 5.41309C1.70087 6.19177 0.75 7.72778 0.75 9.41345H1.25C1.25
        7.91803 2.09375 6.55086 3.4263 5.8565L3.19525 5.41309ZM2.89032 4.75139C2.89032 5.08609
        2.95491 5.41778 3.07861 5.72752L3.54295 5.54207C3.44255 5.29068 3.39032 5.02188 3.39032
        4.75139H2.89032ZM5.5861 2.0903C4.1029 2.0903 2.89032 3.28059 2.89032
        4.75139H3.39032C3.39032 3.56344 4.37229 2.5903 5.5861 2.5903V2.0903ZM7.04747
        2.51744C6.61352 2.24012 6.10643 2.0903 5.5861 2.0903V2.5903C6.01046 2.5903 6.42422 2.71252
        6.77822 2.93875L7.04747 2.51744ZM10.7628 0.52002C9.13876 0.52002 7.64731 1.28412 6.71017
        2.58172L7.11551 2.87446C7.95809 1.7078 9.29835 1.02002 10.7628 1.02002V0.52002ZM15.7473
        5.33148C15.6894 2.66518 13.4727 0.52002 10.7628 0.52002V1.02002C13.2068 1.02002 15.1956
        2.95376 15.2474 5.34232L15.7473 5.33148ZM16.8035
        12.8375H5.33726V13.3375H16.8035V12.8375ZM20.2805 9.41345C20.2805 11.298 18.7239 12.8375
        16.8035 12.8375V13.3375C18.9933 13.3375 20.7805 11.5808 20.7805 9.41345H20.2805ZM16.8035
        5.98938C18.7239 5.98938 20.2805 7.52894 20.2805 9.41345H20.7805C20.7805 7.24609 18.9933
        5.48938 16.8035 5.48938V5.98938ZM15.3991 6.28291C15.8463 6.08799 16.3184 5.98938 16.8035
        5.98938V5.48938C16.2493 5.48938 15.7093 5.6023 15.1993 5.82455L15.3991 6.28291ZM14.857
        6.22953C15.0156 6.33945 15.2201 6.35945 15.3975 6.2836L15.2009 5.82386C15.1804 5.83263
        15.1578 5.82971 15.1418 5.81859L14.857 6.22953ZM14.6223 5.73524C14.6069 5.9302 14.6968
        6.11729 14.8559 6.22879L15.1429 5.81933C15.1267 5.80803 15.1195 5.79064 15.1208
        5.77467L14.6223 5.73524ZM14.6224 5.73454L14.6224 5.73461L15.1207 5.77529L15.1207
        5.77522L14.6224 5.73454ZM14.6382 5.43855C14.6382 5.53362 14.6308 5.62864 14.6223
        5.73506L15.1207 5.77471C15.129 5.67141 15.1382 5.55672 15.1382 5.43855H14.6382ZM10.7628
        1.62169C12.9029 1.62169 14.6382 3.33752 14.6382 5.43855H15.1382C15.1382 3.05467 13.1723
        1.12169 10.7628 1.12169V1.62169ZM7.46094 3.44955C8.17347 2.30497 9.40692 1.62169 10.7628
        1.62169V1.12169C9.23523 1.12169 7.84081 1.89324 7.03647 3.1853L7.46094 3.44955ZM7.07154
        3.70454C7.23273 3.68055 7.37475 3.58721 7.4606 3.45009L7.0368 3.18476C7.02848 3.19806
        7.01435 3.20755 6.99792 3.20999L7.07154 3.70454ZM6.62569 3.57696C6.74873 3.6823 6.91243
        3.72945 7.07339 3.70426L6.99607 3.21027C6.98061 3.21269 6.96371 3.20815 6.95086
        3.19714L6.62569 3.57696ZM5.5861 3.19197C5.9671 3.19197 6.33613 3.32862 6.62552
        3.57682L6.95103 3.19728C6.57108 2.87143 6.08661 2.69197 5.5861 2.69197V3.19197ZM4.00064
        4.75139C4.00064 3.89512 4.70874 3.19197 5.5861 3.19197V2.69197C4.43936 2.69197 3.50064
        3.61227 3.50064 4.75139H4.00064ZM4.19956 5.50652C4.06932 5.27602 4.00064 5.01489 4.00064
        4.75139H3.50064C3.50064 5.1013 3.5917 5.44713 3.76424 5.75249L4.19956 5.50652ZM4.24138
        5.95167C4.29114 5.80449 4.27641 5.64289 4.1997 5.50677L3.76411 5.75225C3.77084 5.76419
        3.7722 5.77827 3.76772 5.79151L4.24138 5.95167ZM3.93795 6.28035C4.07999 6.21859 4.19068
        6.09997 4.24111 5.95245L3.76799 5.79072C3.76359 5.8036 3.75328 5.81543 3.73857
        5.82182L3.93795 6.28035ZM1.86033 9.41345C1.86033 8.05942 2.67393 6.82877 3.93777
        6.28043L3.73875 5.82175C2.29545 6.44795 1.36033 7.85667 1.36033 9.41345H1.86033ZM5.33726
        12.8375C3.41686 12.8375 1.86033 11.298 1.86033 9.41345H1.36033C1.36033 11.5808 3.14748
        13.3375 5.33726 13.3375V12.8375Z"
      fill="currentColor"
    />
    <path
      d="M12.0909 6.32514L9.12779 9.18531L8.45459 8.5354C8.28406 8.37076 8.00779 8.37076 7.83725
        8.5354C7.66672 8.70004 7.66672 8.96675 7.83725 9.13139L8.81912 10.0793C8.90438 10.1616
        9.01609 10.2028 9.12779 10.2028C9.23949 10.2028 9.35119 10.1616 9.43646 10.0793L12.7082
        6.92113C12.8787 6.75649 12.8787 6.48978 12.7082 6.32514C12.5377 6.1605 12.2614 6.1605
        12.0909 6.32514Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EGiftOrders extends Vue {
  @Prop({ default: 21 }) readonly width!: number | string;

  @Prop({ default: 14 }) readonly height!: number | string;
}
</script>
