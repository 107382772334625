import Vue, { PropType } from 'vue'
import { ICampaign } from '../../types'

export default Vue.extend({
  props: {
    campaign: {
      type: Object as PropType<ICampaign>,
      required: false,
    },
  },
});
