<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    version="1.1"
    :width="width"
    :height="height"
  >
    <defs>
      <polygon id="path-1" points="0 0.0002 32 0.0002 32 32 0 32"></polygon>
    </defs>
    <g id="My-Campaigns" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Campaigns_sent_link_success" transform="translate(-836.000000, -119.000000)">
        <g id="success-icon" transform="translate(836.000000, 119.000000)">
          <g id="Group-3">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path
              d="M29.978,14.5352 C29.957,15.0292 29.75,16.0002 28,16.0002 L26,16.0002
                C25.724,16.0002 25.5,16.2232 25.5,16.5002 C25.5,16.7762 25.724,17.0002
                26,17.0002 L27.938,17.0002 C29.438,17.0002 29.635,18.2442 29.538,18.8442
                C29.416,19.5902 29.065,21.0002 27.376,21.0002 L25,21.0002 C24.724,21.0002
                24.5,21.2232 24.5,21.5002 C24.5,21.7762 24.724,22.0002 25,22.0002
                L26.969,22.0002 C28.657,22.0002 28.509,23.2872 28.266,24.0562 C27.948,25.0642
                27.752,26.0002 25.626,26.0002 L23.995,26.0002 C23.718,26.0002 23.495,26.2232
                23.495,26.5002 C23.495,26.7752 23.718,27.0002 23.995,27.0002 L25.563,27.0002
                C26.657,27.0002 26.708,28.0352 26.594,28.4062 C26.469,28.8122 26.322,29.1132
                26.315,29.1272 C26.012,29.6722 25.526,30.0002 24.495,30.0002 L19.029,30.0002
                C16.282,30.0002 13.558,29.3772 13.488,29.3612 C9.333,28.4042 9.115,28.3302
                8.854,28.2562 C8.854,28.2562 8.008,28.1132 8.008,27.3752 L8,13.5632 C8,13.0962
                8.299,12.6712 8.794,12.5212 C8.856,12.4972 8.94,12.4722 9,12.4462 C13.569,10.5562
                14.959,6.4062 15,3.0002 C15.006,2.5212 15.375,2.0002 16,2.0002 C17.058,2.0002
                18.926,4.1222 18.926,6.7482 C18.926,9.1192 18.832,9.5292 18,12.0002 C28,12.0002
                27.93,12.1432 28.813,12.3752 C29.907,12.6872 30,13.5942 30,13.9062 C30,14.2492
                29.991,14.1992 29.978,14.5352 L29.978,14.5352 Z M7,29.0002 C7,29.5532 6.553,30.0002
                6,30.0002 L3,30.0002 C2.448,30.0002 2,29.5532 2,29.0002 L2,13.0002 C2,12.4472
                2.448,12.0002 3,12.0002 L6,12.0002 C6.553,12.0002 7,12.4472 7,13.0002 L7,29.0002 Z
                M29.165,10.4732 C27.915,10.1432 24.975,10.1472 20.676,10.0342 C20.88,9.0962
                20.926,8.2502 20.926,6.7482 C20.926,3.1602 18.313,0.0002 16,0.0002 C14.368,0.0002
                13.021,1.3352 13,2.9762 C12.978,4.9902 12.356,8.4692 9,10.2322 C8.754,10.3622
                8.05,10.7092 7.948,10.7552 L8,10.8002 C7.475,10.3482 6.748,10.0002 6,10.0002
                L3,10.0002 C1.346,10.0002 0,11.3462 0,13.0002 L0,29.0002 C0,30.6542 1.346,32.0002
                3,32.0002 L6,32.0002 C7.191,32.0002 8.186,31.2812 8.668,30.2732 C8.68,30.2772
                8.702,30.2832 8.715,30.2852 C8.782,30.3032 8.859,30.3222 8.955,30.3482 C8.972,30.3512
                8.982,30.3532 9,30.3592 C9.577,30.5022 10.685,30.7672 13.055,31.3122 C13.564,31.4282
                16.247,32.0002 19.029,32.0002 L24.495,32.0002 C26.162,32.0002 27.362,31.3592
                28.077,30.0722 C28.087,30.0532 28.318,29.6032 28.505,28.9962 C28.646,28.5392
                28.699,27.8922 28.529,27.2362 C29.602,26.4982 29.948,25.3832 30.173,24.6572
                C30.549,23.4652 30.436,22.5712 30.174,21.9312 C30.778,21.3602 31.293,20.4912
                31.511,19.1642 C31.646,18.3422 31.501,17.4962 31.122,16.7922 C31.688,16.1562
                31.947,15.3562 31.975,14.6162 L31.987,14.4072 C31.995,14.2752 32,14.1942 32,13.9062
                C32,12.6422 31.126,11.0322 29.165,10.4732 L29.165,10.4732 Z"
              id="Fill-1"
              fill="#323232"
              mask="url(#mask-2)"
            />
          </g>
          <path
            d="M4.5005,28 C4.2255,28 4.0005,27.775 4.0005,27.5 C4.0005,27.225 4.2255,27 4.5005,27
              C4.7755,27 5.0005,27.225 5.0005,27.5 C5.0005,27.775 4.7755,28 4.5005,28 M4.5005,26
              C3.6725,26 3.0005,26.672 3.0005,27.5 C3.0005,28.328 3.6725,29 4.5005,29 C5.3285,29
              6.0005,28.328 6.0005,27.5 C6.0005,26.672 5.3285,26 4.5005,26"
            id="Fill-4"
            fill="#323232"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ThumbUp',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 32
    },
    height: {
      type: [Number, String],
      required: false,
      default: 32
    }
  }
}
</script>
