<template>
  <review-field
    ref="review-field"
    :editable="!isSelectedUnrestrictedLink"
    :disabled="isSelectedUnrestrictedLink"
    :allow-to-click-label-in-edit-mode="true"
    :edit-mode-as-column="true"
    @onLabelClick="({ value }) => inEditMode = value"
  >
    <template #label>
      Gift link type

      <review-save-cancel-icons
        v-if="inEditMode"
        class="review-fields-link-type__options"
        @onSave="handleOnSave"
        @onCancel="handleOnCancel"
      />
    </template>

    <template #review-mode>
      {{ linkTypeReviewText }}
    </template>

    <template #edit-mode>
      <div class="review-fields-link-type__edit-mode">
        <common-radio v-model="linkTypeHelper" inputValue="standard_link">
          <div class="review-fields-link-type__radio-item">
            <div>
              Standard
            </div>
            <div>
              Unique link for each recipient
            </div>
          </div>
        </common-radio>

        <common-radio
          v-model="linkTypeHelper"
          inputValue="single_link_restricted"
          :disabled="disableSingleLink"
        >
          <div class="review-fields-link-type__radio-item">
            <div>
              Single link - Verified
            </div>
            <div>
              Same link is sent to all recipients
            </div>
          </div>
        </common-radio>
      </div>
    </template>
  </review-field>
</template>

<script lang="ts">
import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import type { IReviewField } from '../../types'
import { LinkType, RecipientMethod } from '../../types'

import recipientMethodProp from '../mixins/recipientMethodProp'
import recipientsProp from '../mixins/recipientsProp'

import ReviewField from './ReviewField.vue'
import ReviewSaveCancelIcons from './ReviewSaveCancelIcons.vue'

@Component({
  components: {
    ReviewField,
    ReviewSaveCancelIcons,
  },
})
export default class ReviewFieldsLinkType extends Mixins(recipientMethodProp, recipientsProp) {
  @Prop({ required: true }) linkType!: LinkType;

  inEditMode = false;

  linkTypeHelper: LinkType = LinkType.StandardLink;

  get linkTypeReviewText (): string {
    const { linkType, isSelectedUnrestrictedLink } = this

    if (isSelectedUnrestrictedLink) { return 'Unrestricted gift link' }

    return linkType === LinkType.StandardLink ? 'Standard' : 'Single link verified'
  }

  get isSelectedUnrestrictedLink (): boolean {
    return this.recipientMethod === RecipientMethod.UnrestrictedLink
  }

  get disableSingleLink (): boolean {
    return !this.recipients.every((recipient) => recipient.firstName && recipient.email) ?? true
  }

  @Watch('inEditMode', { immediate: false })
  onEditModeChange (newVal: boolean) {
    if (newVal) { this.linkTypeHelper = this.linkType }
  }

  handleOnSave (): void {
    this.$emit('update:linkType', this.linkTypeHelper)

    this.handleOnCancel()
  }

  handleOnCancel (): void {
    const refElement = this.$refs?.['review-field']

    if (refElement) { (refElement as IReviewField).onLabelClick() }
  }
}
</script>

<style lang="scss" scoped>
.review-fields-link-type {
  &__options {
    margin-top: 0;
    margin-left: auto;
    pointer-events: all;
  }

  &__edit-mode {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__radio-item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > div {
      &:first-child {
        font-family: 'Lato-Regular', sans-serif;
        color: #000;
        font-size: 15px;
      }

      &:last-child {
        font-family: 'Lato-Italic', sans-serif;
        color: #95979D;
        font-size: 15px;
      }
    }
  }
}
</style>
