import { Vue, Component } from 'vue-property-decorator'

import { destinationDeclined } from '@/plugins/googleTagManager'

@Component
export default class ShowErrorMessage extends Vue {
  showErrorMessage(message: string | null): void {
    const errorMessage = message || 'An error occurred, please contact our support'
    destinationDeclined({ action: errorMessage })

    this.$store.dispatch('snackbar/showSnackbar', {
      message: errorMessage,
      type: 'error'
    })
  }
}
