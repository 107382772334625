<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.567312 0 0 0 0 0.567312 0 0 0 0 0.567312 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g id="My-Orders" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="My-Orders--interactions1" transform="translate(-1163.000000, -697.000000)">
        <g id="show-gray" transform="translate(1163.000000, 697.642788)" :filter="!black && 'url(#filter-1)'">
          <g id="noun_Eye_2462852" :fill="fillColor" fill-rule="nonzero">
            <path
              d="M12.5,0.107406189 C5.72455556,0.107406189 0.357666667,6.37528728
                0.132513889,6.64209345 C-0.0442170099,6.85146082 -0.0442170099,7.15926043
                0.132513889,7.3686278 C0.357666667,7.63543397 5.72455556,13.9033151 12.5,13.9033151
                C19.2754444,13.9033151 24.6423333,7.63543397 24.8675,7.3686278
                C25.0442309,7.15926043 25.0442309,6.85146082 24.8675,6.64209345
                C24.6423333,6.37528728 19.2754444,0.107406189 12.5,0.107406189 Z M12.5,12.7778058
                C9.33968859,12.7778058 6.77775,10.1933941 6.77775,7.00536062 C6.77775,3.81732718
                9.33968859,1.23291545 12.5,1.23291545 C15.6603114,1.23291545 18.22225,3.81732718
                18.22225,7.00536062 C18.2186695,10.1918967 15.6588271,12.7741939 12.5,12.7778058
                L12.5,12.7778058 Z M1.30845833,7.00536062 C3.04040235,5.16849039
                5.07531505,3.64887442 7.32255833,2.51419871 C5.11466571,5.09410252
                5.11466571,8.91661873 7.32255833,11.4965225 C5.07531505,10.3618468
                3.04040235,8.84223086 1.30845833,7.00536062 L1.30845833,7.00536062 Z
                M17.6774417,11.4965225 C19.8853343,8.91661873 19.8853343,5.09410252
                17.6774417,2.51419871 C19.924685,3.64887442 21.9595976,5.16849039
                23.6915417,7.00536062 C21.9595976,8.84223086 19.924685,10.3618468
                17.6774417,11.4965225 Z
              "
              id="Shape"
            />
            <path
              d="M12.5,3.13060595 C12.2456277,3.13073856 11.9918922,3.1562549 11.7425,3.20678229
                C12.4025944,3.89717739 12.2739139,5.12059917 11.4471222,5.95467702
                C10.6235889,6.78541752 9.4174,6.91705945 8.73160278,6.2601864
                C8.41998241,7.86517192 9.14102349,9.49468822 10.533486,10.3323511
                C11.9259485,11.1700141 13.6898736,11.0353775 14.9415386,9.99589414
                C16.1932036,8.95641076 16.6629797,7.23600199 16.1155101,5.69658893
                C15.5680406,4.15717587 14.121259,3.13037285 12.5,3.13060595 Z
              "
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Eye',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 23
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    },
    black: {
      type: Boolean,
      required: false,
      default: false
    },
    fillColor: {
      type: String,
      required: false,
      default: '#000000',
    },
  }
}
</script>
