<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.27151 4.67163C6.95105 2.62981 8.742 1.05029 11 1.05029C13.2579 1.05029 15.0489 2.62981
        15.7284 4.67163C16.0019 4.59742 16.3034 4.53175 16.6312 4.53175C19.4651 4.53175 21.7729
        6.87569 21.7729 9.75393C21.7729 12.6322 19.4651 14.9761 16.6312 14.9761H16.1339C16.0074
        17.7409 13.7528 19.9496 11 19.9496C8.24709 19.9496 5.99255 17.7409 5.86599
        14.9761H5.36866C2.53483 14.9761 0.227051 12.6322 0.227051 9.75393C0.227051 6.87569 2.53483
        4.53175 5.36866 4.53175C5.69651 4.53175 5.99801 4.59742 6.27151 4.67163ZM14.9327
        5.38657C14.4905 3.50786 12.9534 2.04499 11 2.04499C9.04651 2.04499 7.50943 3.50786 7.06723
        5.38657C7.03809 5.51555 6.95955 5.62742 6.84898 5.69742C6.73841 5.76743 6.60494 5.7898
        6.47809 5.75958C6.405 5.74142 6.32889 5.72184 6.25181 5.70201L6.25168 5.70197L6.25167
        5.70197C5.91941 5.61648 5.56949 5.52645 5.36866 5.52645C3.06411 5.52645 1.20641 7.41326
        1.20641 9.75393C1.20641 12.0946 3.06411 13.9814 5.36866 13.9814H5.9119C6.26948 11.4555
        8.4156 9.50525 11 9.50525C13.5843 9.50525 15.7304 11.4555 16.088 13.9814H16.6312C18.9358
        13.9814 20.7935 12.0946 20.7935 9.75393C20.7935 7.41326 18.9358 5.52645 16.6312
        5.52645C16.4304 5.52645 16.0805 5.61648 15.7482 5.70197L15.7481 5.70199L15.748
        5.70202C15.671 5.72185 15.5949 5.74143 15.5218 5.75958C15.395 5.7898 15.2615 5.76743
        15.1509 5.69742C15.0404 5.62742 14.9618 5.51555 14.9327 5.38657ZM11 10.5C13.3045 10.5
        15.1622 12.3868 15.1622 14.7274C15.1622 17.0681 13.3045 18.9549 11 18.9549C8.6954 18.9549
        6.8377 17.0681 6.8377 14.7274C6.8377 12.3868 8.6954 10.5 11 10.5ZM10.5103 11.992C10.5048
        11.7343 10.6941 11.515 10.9464 11.4869C11.0866 11.4712 11.2267 11.5177 11.3307
        11.6144C11.4348 11.7112 11.4927 11.8488 11.4896 11.992V12.2407H12.2241C12.4007 12.2381
        12.565 12.3324 12.654 12.4873C12.7431 12.6422 12.7431 12.8338 12.654 12.9888C12.565 13.1437
        12.4007 13.2379 12.2241 13.2354H10.7551C10.3888 13.2354 10.2654 13.4118 10.2654
        13.7327C10.2654 13.9872 10.4541 14.2301 11 14.2301C11.9318 14.2301 12.7138 14.8745 12.7138
        15.7221C12.7138 16.4251 12.2034 17.0686 11.4896 17.1909V17.4629C11.4921 17.6422 11.3993
        17.8091 11.2468 17.8995C11.0943 17.9899 10.9056 17.9899 10.7531 17.8995C10.6006 17.8091
        10.5078 17.6422 10.5103 17.4629V17.2142H9.77576C9.59917 17.2167 9.43491 17.1225 9.34588
        16.9676C9.25685 16.8126 9.25685 16.621 9.34588 16.4661C9.43491 16.3112 9.59917 16.2169
        9.77576 16.2195H11.2448C11.6111 16.2195 11.7345 16.0431 11.7345 15.7221C11.7345 15.4677
        11.5458 15.2248 11 15.2248C10.0681 15.2248 9.28608 14.5804 9.28608 13.7327C9.28608 13.0295
        9.79607 12.3859 10.5103 12.264V11.992Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Credits extends Vue {
  @Prop({ default: 22 }) readonly width!: number | string;

  @Prop({ default: 21 }) readonly height!: number | string;
}
</script>
