<template>
  <common-loader v-if="loading" style="padding: 70px 0" />

  <div v-else>
    <v-row no-gutters justify="space-between" align="center">
      <v-col class="row no-gutters g10 flex-row align-center">
        <common-input
          v-model="search"
          height="30"
          clearable
          filter-style
          prepend-inner-icon="mdi-magnify"
          style="min-width: 216px; max-width: 216px"
          placeholder="Filter by name..."
        />

        <common-checkbox v-model="showDisabledEntites">Show disabled budget entities</common-checkbox>
      </v-col>
      <v-col class="flex-grow-0">
        <the-table-total-info v-bind="$attrs" />
      </v-col>
    </v-row>

    <the-budget-table
      class="pt-4"
      :search="search"
      :items="filteredItems"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import TheBudgetTable from './TheBudgetTable'
import TheTableTotalInfo from './TheTableTotalInfo'

import { budgetEntities, loading } from './mixins'

export default {
  name: 'TheBudgetTableWrapper',
  components: {
    TheBudgetTable,
    TheTableTotalInfo
  },
  mixins: [
    budgetEntities,
    loading
  ],
  data: () => ({
    search: null,
    showDisabledEntites: false,
  }),
  computed: {
    filteredItems () {
      const { budgetEntities, showDisabledEntites } = this

      return budgetEntities
        .filter((budget) => showDisabledEntites ? true : budget.active)
    },
  },
}
</script>
