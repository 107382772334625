<template>
  <v-form>
    <div class="d-flex align-center justify-space-between mb-1">
      <label for="old-password" class="mb-0">
        Enter old password
      </label>

      <div
        class="d-flex pointer g10 eye-icon-wrapper"
        @click="showOldPassword = !showOldPassword"
      >
        <icons-eye black v-if="showOldPassword" />
        <icons-eye-crossed v-else />

        {{ showOldPassword ? 'Hide' : 'Show' }}
      </div>
    </div>

    <common-input
      v-model="formOldPassword"
      id="old-password"
      :type="showOldPassword ? 'text': 'password'"
      placeholder=""
    />

    <div class="d-flex align-center justify-space-between pt-7 mb-1">
      <label for="new-password" class="mb-0">
        Enter new password
      </label>

      <div
        class="d-flex pointer g10 eye-icon-wrapper"
        @click="showNewPassword = !showNewPassword"
      >
        <icons-eye black v-if="showNewPassword" />
        <icons-eye-crossed v-else />

        {{ showNewPassword ? 'Hide' : 'Show' }}
      </div>
    </div>

    <div style="position: relative">
      <common-input
        v-model="formNewPassword"
        id="new-password"
        :type="showNewPassword ? 'text': 'password'"
        placeholder=""
      />

      <account-settings-password-validation
        :password="formNewPassword"
        :minLength="10"
      />
    </div>
  </v-form>
</template>

<script>
import AccountSettingsPasswordValidation from './AccountSettingsPasswordValidation.vue'

export default {
  name: 'AccountSettingsPasswordForm',
  components: {
    AccountSettingsPasswordValidation
  },
  props: {
    oldPassword: {
      type: String,
      required: false,
      default: null
    },
    newPassword: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    showOldPassword: false,
    showNewPassword: false
  }),
  computed: {
    formOldPassword: {
      get () {
        return this.oldPassword
      },
      set (val) {
        this.$emit('update:oldPassword', val)
      }
    },
    formNewPassword: {
      get () {
        return this.newPassword
      },
      set (val) {
        this.$emit('update:newPassword', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-family: 'Lato-Bold', sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #000;
  display: block;
}

.eye-icon-wrapper {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  color: #4A4A4A;
}
</style>
