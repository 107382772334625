<template>
  <div class="my-orders-table">
    <my-orders-table-item
      @shoMultipleTracking="(data) => shoMultipleTracking(data)"
      v-for="order in orders"
      :order="order"
      :key="order.id"
    />

    <orders-table-empty v-if="!orders.length">
      No orders found
    </orders-table-empty>

    <multiple-tracking-panel
      v-model="showMultipleTrackingPanel"
      :trackingData="multipleTrackingData"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import OrdersTableEmpty from './OrdersTableEmpty'
import MyOrdersTableItem from './TheMyOrdersTableItem'
import MultipleTrackingPanel from '@/components/myEgift/MultipleTrackingPanel'

const { mapState } = createNamespacedHelpers('new-orders')

export default {
  name: 'TheMyOrdersTable',
  components: {
    OrdersTableEmpty,
    MyOrdersTableItem,
    MultipleTrackingPanel
  },
  data: () => ({
    // multiple tracking panel
    showMultipleTrackingPanel: false,
    multipleTrackingData: []
  }),
  computed: {
    ...mapState(['orders']),
  },
  methods: {
    shoMultipleTracking (data) {
      this.showMultipleTrackingPanel = true
      this.multipleTrackingData = data
    }
  },
}
</script>

<style lang="scss" scoped>
.my-orders-table {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
}
</style>
