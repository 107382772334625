<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 6"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.7803 0.344668C10.0732 0.637558 10.0732 1.11244 9.7803 1.40533L5.53033 5.6553C5.23744
        5.9482 4.76256 5.9482 4.46967 5.6553L0.219668 1.40533C-0.0732225 1.11244 -0.0732225 0.637558
        0.219668 0.344668C0.512558 0.0517775 0.987438 0.0517775 1.28033 0.344668L5 4.06434L8.7197
        0.344668C9.0126 0.0517775 9.4874 0.0517775 9.7803 0.344668Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDown',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 10
    },
    height: {
      type: [Number, String],
      required: false,
      default: 6
    },
    color: {
      type: String,
      required: false,
      default: '#fff'
    }
  }
}
</script>
