<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M10.9998 16.7383C11.4487 16.7383 11.8126 16.3745 11.8126 15.9256C11.8126 15.4767 11.4487
        15.1128 10.9998 15.1128C10.5509 15.1128 10.187 15.4767 10.187 15.9256C10.187 16.3745
        10.5509 16.7383 10.9998 16.7383Z
      "
      :fill="color"
    />
    <path
      d="M10.9998 13.8173C10.8377 13.8173 10.6823 13.7529 10.5677 13.6383C10.4531 13.5237 10.3887
        13.3682 10.3887 13.2062V5.87283C10.3887 5.71075 10.4531 5.55531 10.5677 5.44071C10.6823
        5.3261 10.8377 5.26172 10.9998 5.26172C11.1619 5.26172 11.3173 5.3261 11.4319
        5.44071C11.5465 5.55531 11.6109 5.71075 11.6109 5.87283V13.2062C11.6109 13.3682 11.5465
        13.5237 11.4319 13.6383C11.3173 13.7529 11.1619 13.8173 10.9998 13.8173Z
      "
      :fill="color"
    />
    <path
      d="M9.20475 1.02735C9.3746 0.718228 9.62439 0.460419 9.928 0.280891C10.2316 0.101363 10.5779
        0.00671114 10.9306 0.00683606C11.2833 0.00696098 11.6295 0.101858 11.933 0.281601C12.2365
        0.461344 12.4861 0.71933 12.6557 1.02858L21.144 16.6486C21.3068 16.9483 21.3889 17.2851
        21.3823 17.6261C21.3757 17.9671 21.2805 18.3005 21.1062 18.5937C20.9319 18.8868 20.6843
        19.1296 20.3879 19.2982C20.0914 19.4669 19.7563 19.5556 19.4152 19.5556H2.45809C2.11319
        19.5633 1.77233 19.4803 1.46968 19.3147C1.16702 19.1491 0.913215 18.9069 0.733699
        18.6123C0.554182 18.3177 0.455268 17.9811 0.446872 17.6362C0.438476 17.2914 0.520895
        16.9503 0.685865 16.6474L9.20475 1.02735ZM1.7602 17.2322C1.69723 17.3479 1.66607 17.4783
        1.66989 17.61C1.6737 17.7417 1.71236 17.8701 1.78192 17.982C1.85148 18.0939 1.94945
        18.1854 2.06587 18.2471C2.18228 18.3089 2.31298 18.3386 2.44464 18.3334H19.4152C19.5444
        18.3333 19.6714 18.2997 19.7837 18.2357C19.896 18.1718 19.9898 18.0797 20.0558
        17.9686C20.1218 17.8575 20.1578 17.7311 20.1602 17.6019C20.1626 17.4727 20.1315
        17.3451 20.0697 17.2316L11.5832 1.61463C11.5189 1.49777 11.4244 1.40031 11.3096
        1.33242C11.1947 1.26452 11.0638 1.22867 10.9304 1.2286C10.797 1.22854 10.666 1.26427
        10.5512 1.33206C10.4363 1.39985 10.3417 1.49722 10.2773 1.61402L1.75959 17.2322H1.7602Z
      "
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Warning',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 22
    },
    height: {
      type: [Number, String],
      required: false,
      default: 22
    },
    color: {
      type: String,
      required: false,
      default: '#444444'
    }
  }
}
</script>
