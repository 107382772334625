<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 118 92"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M93.0995 42.5831C92.7749 42.0496 92.3211 41.6063 91.7799 41.294L65.9744 26.7628C65.4431
        26.4756 64.8485 26.3252 64.2443 26.3252C63.6402 26.3252 63.0455 26.4756 62.5142
        26.7628L36.7087 41.2647C36.1676 41.577 35.7138 42.0203 35.3891 42.5538C35.0872 43.091
        34.9258 43.6956 34.9199 44.3116V73.1983C34.9233 73.8225 35.0902 74.4349 35.404
        74.9745C35.7178 75.5142 36.1677 75.9624 36.7087 76.2745L62.5142 90.7764C63.015 91.0606
        63.5804 91.2119 64.1563 91.2159H64.3323C64.9048 91.1881 65.4647 91.0383 65.9744
        90.7764L91.7799 76.2745C92.327 75.9694 92.7819 75.5228 93.0966 74.9815C93.4114 74.4402
        93.5744 73.8243 93.5687 73.1983V44.3409C93.5628 43.7249 93.4014 43.1203 93.0995
        42.5831ZM63.6871 28.8135C63.8582 28.7203 64.0494 28.6701 64.2443 28.6671C64.4484 28.6709
        64.6489 28.721 64.8308 28.8135L90.0204 42.9639L78.6719 49.4093L52.5732 35.0538L63.6871
        28.8135ZM64.5082 57.4366L38.4975 42.9639L50.1686 36.4014L76.2673 50.7862L64.5082
        57.4366ZM37.8817 74.253C37.6943 74.1556 37.5375 74.0082 37.4288 73.8272C37.3201 73.6462
        37.2637 73.4387 37.2659 73.2276V44.9561L63.3353 59.4581L63.1007 88.4327L37.8817
        74.253ZM90.6363 74.253L65.4466 88.4034L65.6812 59.4581L77.4403 52.8077V65.962C77.4403
        66.2728 77.5639 66.5709 77.7838 66.7906C78.0038 67.0104 78.3022 67.1339 78.6133
        67.1339C78.9243 67.1339 79.2227 67.0104 79.4427 66.7906C79.6626 66.5709 79.7862 66.2728
        79.7862 65.962V51.46L91.2227 44.9854V73.2276C91.2246 73.4351 91.1713 73.6394 91.0682
        73.8195C90.9652 73.9997 90.8161 74.1493 90.6363 74.253Z"
      fill="#95979D"
      stroke="white"
      stroke-width="0.5"
    />
    <g style="mix-blend-mode:multiply">
      <circle r="37" transform="matrix(-1 0 0 1 80.25 37.7842)" :fill="color"/>
      <circle r="6.5" transform="matrix(-1 0 0 1 28.25 13.7842)" :fill="color"/>
      <circle r="9.5" transform="matrix(-1 0 0 1 10.25 48.7842)" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'RecentOrders',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 118
    },
    height: {
      type: [Number, String],
      required: false,
      default: 92
    },
    color: {
      type: String,
      required: false,
      default: '#F1EBD6'
    }
  }
}
</script>
