<template>
  <v-tooltip
    v-bind="$attrs"
    v-on="$listeners"
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    :max-width="maxWidth"
    :min-width="minWidth"
    :z-index="zIndex"
    :content-class="tooltipContentClass"
  >
    <template #activator="{ on, attrs }">
      <div
        slot="reference"
        class="d-flex pointer"
        v-on="on"
        v-bind="attrs"
      >
        <slot name="activator">
          <icons-question
            :width="activatorSize"
            :height="activatorSize"
            :color="activatorColor"
          />
        </slot>
      </div>
    </template>
    <span class="common-tooltip__content">
      <slot />
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'CommonTooltip',
  props: {
    maxWidth: {
      type: [String, Number],
      required: false,
      default: 400
    },
    minWidth: {
      type: [String, Number],
      required: false,
      default: 100
    },
    contentClass: {
      type: String,
      required: false,
      default: null
    },
    zIndex: {
      type: [Number, String],
      required: false,
      default: 4
    },
    top: {
      type: Boolean,
      required: false,
      default: true
    },
    bottom: {
      type: Boolean,
      required: false,
      default: false
    },
    left: {
      type: Boolean,
      required: false,
      default: false
    },
    right: {
      type: Boolean,
      required: false,
      default: false
    },
    activatorSize: {
      type: Number,
      required: false,
      default: 16
    },
    activatorColor: {
      type: String,
      required: false,
      default: '#42B77A'
    },
  },
  computed: {
    arrowPosition () {
      const { top, bottom, left, right } = this
      if (top) return 'v-tooltip__content--bottom'
      if (bottom) return 'v-tooltip__content--top'
      if (left) return 'v-tooltip__content--right'
      if (right) return ''

      return 'v-tooltip__content--top'
    },
    tooltipContentClass () {
      let result = `common-tooltip ${this.arrowPosition} `
      if (this.contentClass) result += this.contentClass

      return result
    }
  }
}
</script>

<style lang="scss">
.common-tooltip {
  background: #fff !important;
  border: none !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15) !important;
  padding: 0;
  opacity: 1 !important;

  &__content {
    font-family: 'Lato-Regular', sans-serif;
    word-break: break-word;
    border-radius: 4px;
    line-height: 16px;
    color: #212121;
    text-align: left;
    font-size: 13px;
    padding: 16px;
    display: flex;
  }

  &::before, &::after {
    background-color: #fff !important;
  }
}
</style>
