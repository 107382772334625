<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="My-Campaigns"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.4"
    >
      <g
        id="My-Campaigns_send_eGift_less-items"
        transform="translate(-492.000000, -1040.000000)"
      >
        <g id="greeting" transform="translate(479.000000, 631.000000)">
          <g id="change-logo-but" transform="translate(14.000000, 409.000000)">
            <g id="Group" transform="translate(0.000000, 1.000000)">
              <g id="Group-6" transform="translate(0.258424, 0.175255)">
                <polygon
                  id="Stroke-1"
                  stroke="#1D1D1B"
                  stroke-width="1.63636364"
                  points="4.41952941 15.2104675 0 7.60523377 4.41952941 4.28228881e-16 13.2585882
                    4.28228881e-16 17.6781176 7.60523377 13.2585882 15.2104675
                  "
                />
                <polygon
                  id="Fill-3"
                  fill="#000000"
                  points="7.16142353 3.53580779 8.09318824 3.53580779 8.09318824 9.84714545
                    11.1422471 9.84714545 11.1422471 10.625587 7.16142353 10.625587
                  "
                />
                <path
                  d="M10.3258588,4.09547922 L12.4036235,4.09547922 L10.3258588,4.09547922 Z"
                  id="Fill-4"
                  fill="#FFFFFF"
                />
                <line
                  x1="10.3259647"
                  y1="4.09544416"
                  x2="12.4033765"
                  y2="4.09544416"
                  id="Stroke-5"
                  stroke="#1D1D1B"
                  stroke-width="1.63636364"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 18
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    }
  }
}
</script>
