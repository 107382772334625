<template>
  <div class="success-area-link">
    <icons-download-invoice
      :width="16"
      :height="20"
      class="mr-5"
    />

    <div>
      <span class="green2 pointer" @click="downloadFile()">Download file</span> with links for your recipients.
    </div>

    <popper
      :options="{
        placement: 'top',
        modifiers: { offset: { offset: '0, 5px' } },
      }"
    >
      <div class="popper pa-4 text-left" style="min-width: 300px; max-width: 500px;">
        {{ getTooltipTextBySlug('campaigns_copy_file') }}
      </div>
      <div class="d-flex pointer" slot="reference">
        <icons-question
          :width="20"
          :height="20"
        />
      </div>
    </popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'

import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

export default {
  name: 'TheSuccessAreaFile',
  components: { Popper },
  mixins: [getTooltipTextBySlug],
  props: {
    recipientsResponse: {
      type: Array,
      required: true
    }
  },
  methods: {
    downloadFile () {
      const headers = ['First name', 'Last name', 'Email', 'URL']
      let csvContent = 'data:text/csvcharset=utf-8,' + headers.join(',') + '\n'

      csvContent += this.recipientsResponse
        .map((r) => [r.first_name, r.last_name, r.email, r.url].join(','))
        .join('\n')

      const fileUrl = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', fileUrl)
      link.setAttribute('download', 'recipients_links.csv')
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.success-area-link {
  background-color: #F6F6F6;
  padding: 15px;
  font-family: 'Lato-Regular', sans-serif;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  text-align: left;

  // popper with question icon
  span:last-child {
    margin-left: auto;
  }
}
</style>
