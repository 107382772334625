<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="none"
    :height="height"
    :width="width"
  >
    <path
      d="M8.38923 17.4375H6.75V8.92364L0.84375 1.89239V0.5625H16.875V1.8848L11.25
        8.91605V14.5767L8.38923 17.4375ZM7.875 16.3125H7.92327L10.125 14.1108V8.52145L15.5921
        1.6875H2.14102L7.875 8.51386V16.3125Z
      "
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'FilterIcon',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 18
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    },
    color: {
      type: String,
      required: false,
      default: '#888888'
    }
  }
}
</script>
