<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 18"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.54785 16.326H12.3073V5.57407H1.54785V16.326ZM13.0531 17.774H0.845929C0.450505 17.774
        0.142822 17.4665 0.142822 17.0719V4.87142C0.142822 4.47684 0.450505 4.16936 0.845929
        4.16936H13.0092C13.4052 4.16936 13.7123 4.47684 13.7123 4.87142V17.028C13.7562 17.4226
        13.4491 17.774 13.0531 17.774V17.774Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.9941 17.5986C6.59809 17.5986 6.29041 17.2906 6.29041 16.896V5.17896C6.29041 4.78379
        6.59809 4.4769 6.9941 4.4769C7.38893 4.4769 7.69603 4.78379 7.69603 5.17896V16.896C7.69603
        17.2473 7.38893 17.5986 6.9941 17.5986"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0093 9.04042H1.10864C0.713811 9.04042 0.406128 8.73352 0.406128 8.33836C0.406128
        7.94319 0.713811 7.63689 1.10864 7.63689H13.0093C13.4053 7.63689 13.7124 7.94319 13.7124
        8.33836C13.7124 8.73352 13.4053 9.04042 13.0093 9.04042"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.16891 5.52963C6.99402 5.52963 6.86182 5.48579 6.73021 5.35427L3.34866 2.58927C3.04098
        2.32622 2.99711 1.88721 3.26092 1.58032C3.52414 1.27224 3.96343 1.22899 4.27052
        1.49263L7.65207 4.25704C7.95976 4.52068 8.00363 4.9591 7.73981 5.26718C7.60761 5.44195
        7.38885 5.52963 7.16891 5.52963"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.77404 5.52963C6.55409 5.52963 6.37861 5.44195 6.247 5.26718C5.98319 4.9591 6.02706
        4.52068 6.33474 4.25704L9.7163 1.49263C10.0228 1.22899 10.4633 1.27224 10.7259
        1.58032C10.9897 1.88721 10.9458 2.32622 10.6382 2.58927L7.21274 5.35427C7.08113 5.48579
        6.95011 5.52963 6.77404 5.52963"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.95005 5.44172C6.55404 5.44172 6.24695 5.13483 6.24695 4.73966V0.702062C6.24695 0.307485
        6.55404 0 6.95005 0C7.34489 0 7.65198 0.307485 7.65198 0.702062V4.73966C7.65198 5.09098
        7.34489 5.44172 6.95005 5.44172"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Gift',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 14
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    }
  }
}
</script>
