<template>
  <div
    id="mention-menu"
    class="mention-menu elevation-4"
    role="listbox"
  />
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const properties = [
  'direction',
  'boxSizing',
  'width',
  'height',
  'overflowX',
  'overflowY',
  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderStyle',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontStretch',
  'fontSize',
  'fontSizeAdjust',
  'lineHeight',
  'fontFamily',
  'textAlign',
  'textTransform',
  'textIndent',
  'textDecoration',
  'letterSpacing',
  'wordSpacing',
  'tabSize',
  'MozTabSize'
]
const isFirefox = typeof window !== 'undefined' && window['mozInnerScreenX'] !== null

function getCaretCoordinates (element: HTMLInputElement | HTMLTextAreaElement, position: number) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  const style = div.style
  const computed = getComputedStyle(element)

  style.whiteSpace = 'pre-wrap'
  style.wordWrap = 'break-word'
  style.position = 'absolute'
  style.visibility = 'hidden'

  properties.forEach((prop: string) => {
    style[prop] = computed[prop]
  })

  if (isFirefox) {
    if (element.scrollHeight > parseInt(computed.height)) {
      style.overflowY = 'scroll'
    }
  } else {
    style.overflow = 'hidden'
  }

  div.textContent = element.value.substring(0, position)
  const span = document.createElement('span')
  span.textContent = element.value.substring(position) || '.'
  div.appendChild(span)

  const coordinates = {
    top: span.offsetTop + parseInt(computed['borderTopWidth']),
    left: span.offsetLeft + parseInt(computed['borderLeftWidth']),
    height: span.offsetHeight
  }
  div.remove()

  return coordinates
}

interface IMentionOption {
  value: string;
}

class Mentionify {
  ref: HTMLInputElement | HTMLTextAreaElement;
  menuRef: HTMLDivElement;
  el: HTMLDivElement;
  resolveFn: (prefix: string) => Array<IMentionOption>;
  replaceFn: (mention: IMentionOption | string, trigger: string) => string;
  menuItemFn: (mention: IMentionOption, setItem: any, selected: boolean) => HTMLDivElement;
  emitChangeFn: (newValue: string, id: string) => void;
  options: Array<IMentionOption> = []

  left?: number = undefined
  top?: number  = undefined
  triggerIdx?: number = undefined
  active: number = 0
  isOnCreateCampaignPage = false

  constructor (
    ref: HTMLInputElement | HTMLTextAreaElement,
    menuRef: HTMLDivElement,
    el: HTMLDivElement,
    resolveFn: (prefix: string) => Array<IMentionOption>,
    replaceFn: (mention: IMentionOption | string, trigger: string) => string,
    menuItemFn: (mention: IMentionOption, setItem: any, selected: boolean) => HTMLDivElement,
    emitChangeFn: (newValue: string, id: string) => void,
    createCampaignPage: boolean,
  ) {
    this.ref = ref
    this.menuRef = menuRef
    this.el = el
    this.resolveFn = resolveFn
    this.replaceFn = replaceFn
    this.menuItemFn = menuItemFn
    this.emitChangeFn = emitChangeFn
    this.options = []
    this.isOnCreateCampaignPage = createCampaignPage

    this.makeOptions = this.makeOptions.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.selectItem = this.selectItem.bind(this)
    this.onInput = this.onInput.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.renderMenu = this.renderMenu.bind(this)
    this.onClick = this.onClick.bind(this)

    this.ref?.addEventListener('input', this.onInput)
    this.ref?.addEventListener('keydown', this.onKeyDown)
    this.ref?.addEventListener('blur', this.onBlur)
    this.ref?.addEventListener('focus', this.onFocus)
    this.menuRef?.addEventListener('click', this.onClick)
    document?.addEventListener('show-mention-menu', this.onInput)
  }

  onClick (ev: MouseEvent) {
    ev.preventDefault()
    const selectedOption = (ev.target as HTMLDivElement).innerText
    const mentionOptions = this.resolveFn('')
    const index = mentionOptions.findIndex(option => option.value === selectedOption)
    this.selectItem(index, false)()
  }

  async makeOptions (query: string) {
    const options = await this.resolveFn(query)
    if (options.length !== 0) {
      this.options = options
      this.renderMenu()
    } else {
      this.closeMenu()
    }
  }

  closeMenu () {
    setTimeout(() => {
      this.options = []
      this.left = undefined
      this.top = undefined
      this.triggerIdx = undefined
      this.renderMenu()
    }, 0)
  }

  selectItem (active: number, update = true) {
    return () => {
      if (update) {
        const preMention = this.ref.value.substr(0, this.triggerIdx)
        const option = this.options[active]
        const mention = this.replaceFn(option, this.ref.value[this.triggerIdx || 0])
        const postMention = this.ref.value.substr(this.ref.selectionStart || 0)
        const newValue = `${preMention}${mention}${postMention}`
        this.ref.value = newValue
        const caretPosition = this.ref.value.length - postMention.length
        this.ref.setSelectionRange(caretPosition, caretPosition)
        this.emitChangeFn(newValue, this.ref?.id)
        this.closeMenu()
        this.ref.focus()
      }
    }
  }

  onInput () {
    const positionIndex = this.ref.selectionStart || 0
    const textBeforeCaret = this.ref.value.slice(0, positionIndex)
    const tokens = textBeforeCaret.split(/\s/)
    const lastToken = tokens[tokens.length - 1]
    const triggerIdx = textBeforeCaret.endsWith(lastToken)
      ? textBeforeCaret.length - lastToken.length
      : -1
    const maybeTrigger = textBeforeCaret[triggerIdx]
    const keystrokeTriggered = maybeTrigger === '@'
    if (!keystrokeTriggered) {
      this.closeMenu()
      return
    }
    const query = textBeforeCaret.slice(triggerIdx + 1)
    this.makeOptions(query)

    const coords = getCaretCoordinates(this.ref, positionIndex)
    const parentSidebar = this.el?.closest('#campaign-view-mode-panel > .v-navigation-drawer__content')
    const scrollTop = parentSidebar?.scrollTop ?? window.pageYOffset ?? 0
    const offsetTop = this.ref.offsetTop
    const offsetLeft = this.ref.offsetLeft
    const parentOffsetTop = this.el.offsetTop
    const parentOffSetLeft = parentSidebar ? this.el.offsetLeft : 0

    setTimeout(() => {
      this.active = 0

      if (this.isOnCreateCampaignPage) {
        const refId = this.ref?.id ?? ''
        this.left = coords.left + 24

        if (refId.includes('title')) {
          this.top = coords.top + 50
        } else if (refId.includes('from')) {
          this.top = offsetTop - 314 + 44
        } else {
          this.top = coords.top + 100 + 44
        }
      } else {
        this.left =  offsetLeft - parentOffSetLeft + coords.left - 24
        // this.top = offsetTop - parentOffsetTop + coords.top + 27
        this.top = offsetTop - (Math.abs(scrollTop + parentOffsetTop)) + coords.top
      }

      this.triggerIdx = triggerIdx
      this.renderMenu()
    }, 0)
  }

  onFocus () {
    setTimeout(() => { this.onInput() }, 0);
  }

  onBlur () {
    this.closeMenu()
  }

  onKeyDown (event: Event): void {
    let keyCaught = false
    const ev = event as KeyboardEvent

    if (this.triggerIdx !== undefined) {
      switch (ev.key) {
        case 'ArrowDown':
          this.active = Math.min(this.active + 1, this.options.length - 1)
          this.renderMenu()
          keyCaught = true
          break
        case 'ArrowUp':
          this.active = Math.max(this.active - 1, 0)
          this.renderMenu()
          keyCaught = true
          break
        case 'Escape':
          keyCaught = true
          this.closeMenu()
          this.ref.focus()
          break
        case 'Enter':
        case 'Tab':
          this.selectItem(this.active)()
          keyCaught = true
          break
      }
    }

    if (keyCaught) {
      ev.preventDefault()
    }
  }

  renderMenu () {
    if (this.top === undefined) {
      this.menuRef.hidden = true
      return
    }

    this.menuRef.style.marginLeft = `calc(${this.left}px)`
    this.menuRef.style.marginTop = `calc(${this.top}px`
    this.menuRef.innerHTML = ''
    this.options.forEach((option, idx) => {
      this.menuRef.appendChild(this.menuItemFn(
        option,
        this.selectItem(idx),
        this.active === idx))
    })

    this.menuRef.hidden = false
  }

  onDestroy () {
    this.ref?.removeEventListener('input', this.onInput)
    this.ref?.removeEventListener('keydown', this.onKeyDown)
    this.ref?.removeEventListener('blur', this.onBlur)
    this.ref?.removeEventListener('focus', this.onFocus)
    this.menuRef?.removeEventListener('click', this.onClick)
    document?.removeEventListener('show-mention-menu', this.onInput)
  }
}

@Component
export default class GreetingMentionMenu extends Vue {
  @Prop({ required: false, default: 'textarea' }) readonly textAreaId!: string;

  @Prop({ required: false, default: 'mention-menu' }) readonly menuId!: string;

  @Prop({ required: false, default: false }) readonly createCampaignPage!: boolean;

  mentionOptions: Array<IMentionOption> = [
    { value: 'First_name' },
    { value: 'Last_name' },
  ]

  mentionMenuInstance: Mentionify | null = null

  @Watch('textAreaId', { immediate: true })
  onTextAreaIdChange () { this.renderMenu() }

  beforeDestroy () {
    this.mentionMenuInstance?.onDestroy()
  }

  resolveFn (prefix: string): Array<IMentionOption> {
    const { mentionOptions } = this

    return prefix === ''
      ? mentionOptions
      : mentionOptions?.filter(user => {
        const valueLowerCase = user?.value?.toLowerCase()
        const prefixLowerCase = prefix?.toLowerCase()

        return valueLowerCase?.startsWith(prefixLowerCase)
      })
  }

  replaceFn (mention: IMentionOption | string = '@', trigger = ''): string {
    const mentionText = typeof mention === 'string' ? mention : mention?.value

    return `${trigger}${mentionText} `
  }

  menuItemFn (mention: IMentionOption, setItem: any, selected: boolean): HTMLDivElement {
    const div = document.createElement('div')

    div.setAttribute('role', 'option')
    div.className = 'mention-menu__item'

    if (selected) {
      div.classList.add('selected')
      div.setAttribute('aria-selected', '')
    }

    div.textContent = mention?.value
    div.onclick = setItem
    return div
  }

  emitChange (newValue: string, id: string): void {
    this.$emit('changeInput', { id, message: newValue, })
  }

  renderMenu (): void {
    if (this.mentionMenuInstance) {
      this.mentionMenuInstance.onDestroy();
    }

    this.mentionMenuInstance = new Mentionify(
      (document.getElementById(this.textAreaId) as HTMLInputElement | HTMLTextAreaElement),
      document.getElementById(this.menuId) as HTMLDivElement,
      this?.$parent?.$el as HTMLDivElement,
      this.resolveFn,
      this.replaceFn,
      this.menuItemFn,
      this.emitChange,
      this.createCampaignPage,
    )
  }
}
</script>

<style lang="scss">
.mention-menu {
  background-color: #f3f3f3;
  position: absolute;

  &__item {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 37px;
    cursor: pointer;
    padding: 4px 1rem;
    color: #000;
    background-color: #fff;

    &:hover {
      background-color: #f2f2f2;
    }

    &.selected {
      background-color: #f2f2f2;
    }
  }
}
</style>