<template>
  <div class="create-campaign-review-card">

    <div class="create-campaign-review-card__text-wrapper">
      <h2>Greeting message</h2>
    </div>

    <hr>

    <review-card-edit-input v-model="campaignDisplayName" :rules="[checkIfStringContainsNotAllowedTag]">
      <template #label>
        <div class="d-inline-flex flex-row g4 mr-2">
          From

          <common-tooltip
            top
            :max-width="null"
            :z-index="8"
            :activator-size="20"
            activator-color="#62646A"
          >
            <img alt="" :src="getProductImageBySlug('egift_collections_from')" />
          </common-tooltip>
        </div>
      </template>
      <template> {{ campaignDisplayName }} </template>
    </review-card-edit-input>

    <hr>

    <div class="create-campaign-review-card__text-wrapper">
      <div class="create-campaign-review-card__text">
        <b>Message</b>
      </div>

      <router-link :to="{ name: 'CreateCampaignGreeting', params: $route.params }">Edit</router-link>
    </div>

    <div v-if="isGreetingMessage" class="review-campaign-greeting-message">
      <span>{{ greetingMessageTitle }}</span>
      <br>
      <span v-html="greetingMessageContent" />
      <br>
      <br>
      <span>{{ greetingMessageFooter }}</span>
    </div>

    <div v-else class="review-campaign-greeting-empty-message">
      <svg
        width="76"
        height="59"
        viewBox="0 0 76 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.2871 47.6093H60.125V23.7715H36.2871V47.6093Z"
          stroke="#62646A"
          stroke-width="1.44644"
        />
        <path
          d="M35.8418 23.6484C42.5727 21.5462 49.3036 19.444 56.0345 17.3418V24.0534"
          stroke="#62646A"
          stroke-width="1.44644"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M47.2411 28.7422H49.209L49.4451 33.6814L54.1483 32.422L54.778 34.2718L50.1732 36.0035L52.8888 40.0769L51.3146 41.2183L48.225 37.381L45.1355 41.2183L43.5809 40.0769L46.2572 35.9642L41.6328 34.2325L42.2625 32.3827L47.0049 33.6814L47.2411 28.7422Z"
          fill="#62646A"
        />
        <g style="mix-blend-mode:multiply">
          <circle
            cx="23.9072"
            cy="23.9072"
            r="23.9072"
            transform="matrix(-1 0 0 1 75.6387 0)"
            fill="#E5DFFF"
          />
          <circle
            cx="4.19992"
            cy="4.19992"
            r="4.19992"
            transform="matrix(-1 0 0 1 22.332 4.19922)"
            fill="#E5DFFF"
          />
          <circle
            cx="6.13834"
            cy="6.13834"
            r="6.13834"
            transform="matrix(-1 0 0 1 12.6387 24.877)"
            fill="#E5DFFF"
          />
        </g>
      </svg>

      No greeting message saved for this campaign.
      Campaigns without greeting message cannot be automated.
      You can add a greeting message anytime later.
    </div>

    <img
      v-if="greetingMessageLogo"
      :src="greetingMessageLogo"
      class="review-campaign-greeting-logo"
      :class="{ 'mx-auto': !isGreetingMessage }"
      alt=""
    >
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import getProductImageBySlug from '@/mixins/getProductImageBySlug'
import checkIfStringContainsNotAllowedTag from '@/components/createCampaign/mixins/checkIfStringContainsNotAllowedTag'

import ReviewCardEditInput from './CreateCampaignReviewCardEditInput.vue'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignReviewCardGreeting',
  mixins: [
    getProductImageBySlug,
    checkIfStringContainsNotAllowedTag,
  ],
  components: { ReviewCardEditInput },
  computed: {
    ...mapState(['form']),
    campaignDisplayName: {
      get () { return this.form.displayName },
      set (val) { this.setDisplayName(val) },
    },
    greetingMessage () { return this.form.greetingMessage },
    greetingMessageTitle () { return this.form.greetingMessage?.title ?? null },
    greetingMessageContent () {
      return this.form.greetingMessage?.content?.replaceAll('\n', '<br />') ?? null
    },
    greetingMessageFooter () { return this.form.greetingMessage?.footer ?? null },
    greetingMessageLogo () { return this.form.greetingMessage?.logo ?? null },
    isGreetingMessage () {
      const { title, content, footer } = this.form.greetingMessage
      return [title, content, footer].some((item) => item)
    },
  },
  methods: {
    ...mapMutations(['setDisplayName']),
  },
}
</script>

<style lang="scss" scoped>
.review-campaign-greeting-message {
  font-family: 'Lato-Regular', sans-serif;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  // word-break: break-all;
  text-wrap: balance;
  line-height: 24px;
  color: #000;
}

.review-campaign-greeting-empty-message {
  font-family: 'Lato-Italic', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #222325;
  align-items: center;
  gap: 24px;
  display: flex;
  flex-direction: column;
  max-width: 292px;
  margin-inline: auto;
}

.review-campaign-greeting-logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100px;
}
</style>