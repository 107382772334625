<template>
<!-- TODO refactor -->
  <div class="d-flex flex-column">
    <span class="f15 black1 font-weight-bold mb-3">Approval flow</span>
    <div class="f15 gray1 mb-4 lato-italic" style="display: flex; gap: 8px">
      Assign who should approve orders for this budget entity
      <common-tooltip top :max-width="350" :z-index="8" :activator-size="20">
        Approval Process is customized to your company needs, to customize your approval process please contact your customer success manager.
      </common-tooltip>
    </div>
    <v-combobox
      v-model="selectedEntity.approvers"
      class="approvers-box"
      item-text="name"
      multiple
      chips
      :items="approvers"
      hide-details
      outlined
    >
      <template #selection="{ attrs, item, parent, selected }">
        <v-chip
          class="my-2"
          v-bind="attrs"
          :input-value="selected"
          label
          small
          style="height: 50px;"
        >
          <div class="d-flex flex-column pr-2">
            <span class="lato-bold f14 black1">
              {{ item.name }}
            </span>
            <span class="gray2">
              {{ item.email }}
            </span>
          </div>
          <v-icon
            small
            @click="parent.selectItem(item)"
          >
            mdi-close
          </v-icon>
        </v-chip>
      </template>
      <template #item="{ attrs, item }">
        <div class="d-flex align-center">
          <v-img
            class="mr-3"
            :src="attrs['aria-selected'] === 'true' ? 'images/selected-icon.svg' : 'images/empty-customer-icon.svg'"
            width="28"
            height="28"/>
          <div class="option__desc ml-4">
            <span class="option__title f14">{{ item.name }}</span>
          </div>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { isEdit, selectedEntity } from '../mixins'

export default {
  name: 'Approvers',
  mixins: [
    isEdit,
    selectedEntity
  ],
  props: {
    approvers: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.v-menu__content {
  margin-top: 0 !important;
}

.approvers-box .v-input__append-inner {
  display: none;
}
</style>
