export default {
  setOrder (state, order) {
    state.order = order
  },
  setOrderShippingStatus (state, orderShippingStatus) {
    state.orderShippingStatus = orderShippingStatus?.data[0]?.status
  },
  setOrderShippingData (state, { data, id }) {
    if (id !== null) {
      const index = state.orders?.findIndex((order) => order.id === id)
      state.orders[index].shipping_data = data
    } else {
      state.order.shipping_data = data
    }
  },
  setRecipientShippingData (state, { data, index }) {
    if (index === null) {
      state.order.recipient.shipping_data = data
    } else {
      state.order.recipients[index].shipping_data = data
    }
  },
  setLoading (state, loading) {
    state.loading = loading
  },
}
