<template>
  <add-credit-card-form @onAddCard="onSuccess" />
</template>

<script>
import AddCreditCardForm from '@/components/payment/AddCreditCardForm.vue'

export default {
  name: 'AddNewCreditCard',
  components: { AddCreditCardForm },
  methods: {
    onSuccess () {
      this.$emit('onFetchData');
      this.$emit('update:step', 2);
    },
  },
}
</script>
