<template>
  <v-container class="pa-0 ma-0">
    <v-row no-gutters>
      <v-col class="credits-info__summary">
        <the-credits-info-summary v-bind="$attrs" />
      </v-col>
      <v-col class="credits-info__charts">
        <the-credits-info-charts v-bind="$attrs" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheCreditsInfoSummary from './TheCreditsInfoSummary'
import TheCreditsInfoCharts from './TheCreditsInfoCharts'

export default {
  name: 'TheCreditsInfo',
  components: {
    TheCreditsInfoSummary,
    TheCreditsInfoCharts
  }
}
</script>

<style lang="scss" scoped>
.container {
  &, & > .row {
    min-height: 290px;
  }
}
.credits-info__summary {
  background-color: #f6f6f6;
  min-width: 290px;
  width: 290px;
  max-width: 290px;
  padding: 20px 40px !important;
}
.credits-info__charts {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  width: calc(100% - 290px);
  max-width: calc(100% - 290px);
  padding: 20px 30px !important;
  position: relative;
}
</style>
