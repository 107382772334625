<template>
  <div class="create-campaign-source-review">
    <h1>Review your recipients’ gift options</h1>

    <div class="create-campaign-source-review__actions">
      <cg-button outlined @click="showAddMoreGiftsModal = true">
        <template #prepend-icon>
          <icons-add color="currentColor" />
        </template>

        Add more gifts
      </cg-button>

      <cg-button :to="{ name: 'CreateCampaignGreeting', params: $route.params }" :disabled="!selectedProducts.length">
        Continue to greeting
      </cg-button>
    </div>

    <div class="create-campaign__products-wrapper">
      <div class="create-campaign__products-filters create-campaign-source-review__products-filter">
        <common-input
          v-model="search"
          placeholder="Search gifts"
          prepend-inner-icon="mdi-magnify"
          height="36"
          clearable
          filter-style
          style="max-width: 250px"
        />

        <common-select
          v-model="sortBy"
          :items="sortByOptions"
          placeholder="Sort by"
          height="36"
          filter-style
          style="max-width: 250px"
        />

        <span
          role="button"
          :tabindex="!filteredProducts.length ? '-1' : '0'"
          @click="onRemoveAllProducts"
          @keyup.enter.space="onRemoveAllProducts"
          :aria-disabled="!filteredProducts.length ? 'true' : 'false'"
        >
          Remove all
        </span>
      </div>

      <div class="create-campaign__products-grid">
        <product-card
          v-for="product in filteredProducts"
          :key="product.id"
          :id="product.id"
          is-selected
          :show-selected-icon="false"
          :inventory-product="product.isInventory"
          :image="product.image"
          :name="product.name"
          add-button-text="Add"
          remove-button-text="Remove"
          :price="product.price"
          :url="product.url"
          @removeProduct="removeSelectedProduct"
        />
      </div>
    </div>

    <div class="create-campaign-source-review__actions">
      <cg-button :to="{ name: 'CreateCampaignGreeting', params: $route.params }" :disabled="!selectedProducts.length">
        Continue to greeting
      </cg-button>
    </div>

    <div class="create-campaign__sticky-button">
      <cg-button
        :to="{ name: 'CreateCampaignGreeting', params: $route.params }"
        block
        :disabled="!selectedProducts.length"
      >
        Continue to greeting
      </cg-button>

      <cg-button outlined block @click="showAddMoreGiftsModal = true">
        <template #prepend-icon>
          <icons-add color="currentColor" />
        </template>

        Add more gifts
      </cg-button>
    </div>

    <add-more-gifts-modal v-model="showAddMoreGiftsModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { CgButton } from '@corporategift/design-system'
import ProductCard from '@/components/createCampaign/ProductCard.vue'
import AddMoreGiftsModal from '@/components/createCampaign/CreateCampaignSourceReviewAddMoreGiftsModal.vue'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignSourceReview',
  components: {
    CgButton,
    ProductCard,
    AddMoreGiftsModal,
  },
  data: () => ({
    search: '',
    sortBy: 'price-high',
    sortByOptions: [
      {
        value: 'price-high',
        text: 'Price: Low to High',
        sortMethod: (a, b) => (a.price - b.price),
      },
      {
        value: 'price-low',
        text: 'Price: High to Low',
        sortMethod: (a, b) => (b.price - a.price),
      },
    ],
    showAddMoreGiftsModal: false,
  }),
  computed: {
    ...mapState(['form']),
    selectedProducts () { return this.form.selectedProducts ?? [] },
    sortMethod () {
      const { sortBy, sortByOptions } = this
      const selectedOption = sortByOptions.find(({ value }) => value === sortBy);

      return selectedOption?.sortMethod ?? sortByOptions.at(0).sortMethod
    },
    filteredProducts () {
      const { selectedProducts, search, sortMethod } = this
      const textToSearch = (search ?? '').toLowerCase()
      // to avoid array modification by sort method
      const clonedProducts = structuredClone(selectedProducts)

      return clonedProducts
        .filter(({ name }) => name.toLowerCase().indexOf(textToSearch) !== -1)
        .sort(sortMethod)
    },
    filteredProductsIds () {
      return this.filteredProducts.map((product) => product.id)
    },
  },
  mounted () {
    const stepInfo = document.querySelector("a[href='/my-campaigns/create/source/review'] > .create-campaign__header-step-info");

    if (stepInfo) {
      const [_title, subtext] = stepInfo.childNodes;
      if (subtext.innerText) {
        subtext.classList.add('blink-animation');

        setTimeout(() => {
          subtext.classList.remove('blink-animation');
        }, 1800);
      }
    }
  },
  methods: {
    ...mapMutations([
      'removeSelectedProduct',
      'removeAllSelectedProducts',
      'removeSelectedProductsById',
    ]),
    onRemoveAllProducts () {
      const { filteredProductsIds, search } = this

      if (search) {
        this.removeSelectedProductsById(filteredProductsIds)
      } else {
        this.removeAllSelectedProducts()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-source-review {
  display: flex;
  gap: 42px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  &__products-filter > span {
    &::before {
      content: none;
    }
  }

  &__actions {
    gap: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;

    & > button,
    & > a {
      border-radius: 100px;
    }

    @media screen and (max-width: 430px) {
      display: none;
    }
  }

  @media screen and (max-width: 430px) {
    padding-bottom: 0;

    & > h1 {
      padding: 0 16px;
    }

    &__products-filter > div {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
</style>
