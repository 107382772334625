import Api from '../axios/api'

export default {
  namespaced: true,

  state: () => ({
    items: [],
    search: '',
    dateRange: {
      start: undefined,
      end: undefined
    },
    orderBy: undefined,
    orderDesc: undefined,
    links: {
      first: undefined,
      last: undefined,
      next: undefined,
      prev: undefined
    },
    meta: {
      current_page: undefined,
      from: undefined,
      last_page: undefined,
      path: undefined,
      per_page: undefined,
      to: undefined,
      total: undefined
    },
    page: undefined,
    loading: undefined,
    statusIds: undefined,
    filteredStatus: undefined,
    givers: undefined,
    giver: undefined,
    isLoadmore: undefined,
    showableSize: undefined,
    showedOrdersNum: undefined
  }),

  getters: {
    getItems (state) {
      return state.items
    },
    search: (state) => state.search,
    dateRange: (state) => state.dateRange,
    total: (state) => state.meta.total,
    orderStatus: (state) => state.filteredStatus,
    giftGiver: (state) => state.giver,
    page: (state) => state.page,
    filterOrderStatus: (state) => state.filteredStatus === 'Partially redeemed'
      ? 'Started checkout'
      : state.filteredStatus,
    filters: (state, getters) => ({
      search: state.search || undefined,
      date_from: state.dateRange.start || undefined,
      date_to: state.dateRange.end || undefined,
      status: getters.filterOrderStatus,
      giver: state.giver
    })
  },

  mutations: {
    setItems (state, items) {
      state.items = items
    },
    loadMoreItems (state, items) {
      state.items = [...state.items, ...items]
    },
    setLinks (state, links) {
      state.links = links
      if (links.next !== null) {
        state.showableSize = (links.next !== links.last)
          ? 50 : (Number(state.meta.total) - (Number(links.next.split('=')[1]) - 1) * 50)
        state.showedOrdersNum = (Number(links.next.split('=')[1]) - 1) * 50
      } else {
        state.showedOrdersNum = Number(state.meta.total)
      }
    },
    setMetaData (state, meta) {
      state.meta = meta
    },
    setSearch (state, search) {
      state.search = search
    },
    setDateRange (state, dateRange) {
      const range = {
        start: undefined,
        end: undefined
      }
      state.dateRange = dateRange !== null ? dateRange : range
    },
    setSortParam (state, param) {
      state.orderDesc = (state.orderBy === param.column && state.orderDesc === 0) ? 1 : 0
      state.orderBy = param.column
    },
    setPage (state, page) {
      state.page = page
    },
    setFilteredStatus (state, id) {
      state.filteredStatus = id !== 101 ? id : undefined
    },
    setFilteredGiver (state, id) {
      state.giver = id !== -1 ? id : undefined
    },
    setGivers (state, data) {
      state.givers = [{ id: -1, name: 'All Givers' }, ...data]
    },
    setStatusIds (state, data) {
      state.statusIds = ['All Statuses', ...data]
      const idx = state.statusIds.findIndex(item => item === 'Started checkout')
      state.statusIds[idx] = 'Partially redeemed'
    },
    setItemsClosed (state, itemsIds) {
      state.items = state.items.map((item) => {
        if (itemsIds.includes(item.id)) item.close = false
        return item
      })
    }
  },

  actions: {
    async load ({ state, commit, dispatch, getters }) {
      state.loading = true
      await Api.get('/customer/egifts', {
        params: {
          order_by: state.orderBy,
          order_desc: state.orderDesc,
          ...getters.filters
        }
      })
        .then(response => {
          commit('setItems', response.data)
          commit('setMetaData', response.meta)
          commit('setLinks', response.links)
          commit('setPage', 1)
          state.loading = false
        })
        .catch(e => console.error(e))

      dispatch('getFilters')
    },
    getFilters ({ commit, dispatch }) {
      dispatch('getFilter', 'giver')
        .then(response => commit('setGivers', response.data))
      dispatch('getFilter', 'status')
        .then(response => commit('setStatusIds', response.data))
    },
    async getFilter ({ commit, dispatch, getters }, filter) {
      return await Api.get('/customer/egifts/filter/' + filter, {
        params: getters.filters
      })
    },
    async loadMore ({ state, commit, getters }) {
      state.isLoadmore = true
      await Api.get('/customer/egifts', {
        params: {
          page: ++state.page,
          order_by: state.orderBy,
          order_desc: state.orderDesc,
          ...getters.filters
        }
      })
        .then(response => {
          commit('loadMoreItems', response.data)
          commit('setMetaData', response.meta)
          commit('setLinks', response.links)
          state.isLoadmore = false
        })
        .catch(e => console.error(e))
    },
    async search ({ commit, dispatch }, search) {
      commit('setSearch', search)
      await dispatch('load')
    },
    async filterByDateRange ({ commit, dispatch }, range) {
      commit('setDateRange', range)
      await dispatch('load')
    },
    async sortTable ({ commit, dispatch }, param) {
      // commit('setSortParam', param)
      // await dispatch('load')
    },
    async loadSelectedPage ({ commit, dispatch }, page) {
      commit('setPage', page)
      await dispatch('load')
    },
    async filterByStatus ({ commit, dispatch }, status) {
      commit('setFilteredStatus', status)
      await dispatch('load')
    },
    async filterByGiver ({ commit, dispatch }, items) {
      commit('setFilteredGiver', items[0] !== undefined ? items[0].id : undefined)
      await dispatch('load')
    },
    resetFilter ({ commit }) {
      commit('setSearch', '')
      commit('setDateRange', null)
      commit('setFilteredStatus', 101)
      commit('setFilteredGiver', -1)
    }
  }
}
