<template>
  <div class="add-multiple-users-panel-review-recipients">
    <table class="add-multiple-users-panel-review-recipients__table">
      <thead>
        <tr>
          <th>#</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td>#{{ index + 1 }}</td>
          <td>
            <template v-if="editRowId === index">
              <common-input
                v-model="editForm.firstname"
                :rules="firstNameValidation"
                :height="30"
              />
            </template>
            <template v-else>{{ user.firstname }}</template>
          </td>
          <td>
            <template v-if="editRowId === index">
              <common-input
                v-model="editForm.lastname"
                :rules="lastNameValidation"
                :height="30"
              />
            </template>
            <template v-else>{{ user.lastname }}</template>
          </td>
          <td :class="{
            'invalid-value-in-row': isRecipientEmailInvalid(user.email) && editRowId !== index
          }">
            <template v-if="editRowId === index">
              <common-input
                v-model="editForm.email"
                :rules="emailValidation"
                type="email"
                :height="30"
              />
            </template>
            <template v-else>
              <template v-if="user.email">
                {{ user.email }}
              </template>

              <template v-else>
                Missing email address
              </template>
            </template>
          </td>
          <td>
            <div
              class="add-multiple-users-panel-review-recipients__table-actions"
              :class="{
                'add-multiple-users-panel-review-recipients__table-actions--edit': editRowId === index
              }"
            >
              <template v-if="editRowId === index">
                <icons-close-circle
                  @click.native="cancelEditing"
                  :height="24"
                  :width="24"
                  color="#95979D"
                />

                <icons-check-circle
                  @click.native="saveChanges"
                  :height="24"
                  :width="24"
                  :check-stroke-width="1.4"
                  outlineCheckColor="#42B77A"
                  outlineColor="#42B77A"
                  fillColor="#fff"
                />
              </template>
              <template v-else>
                <div class="duplicated-email" v-if="isEmailDuplicated(user.email)">
                  Duplicate
                </div>

                <icons-edit
                  @click.native="editRecipientInfo(index, user)"
                  :width="13"
                  :height="13"
                />

                <icons-delete
                  @click.native="deleteRecipient(index)"
                  :width="14"
                  :height="17"
                />
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>    

    <div class="add-multiple-users-panel-review-recipients__actions">
      <cg-button :disabled="disableContinueButton" @click="$emit('update:step', 5)">
        Continue
      </cg-button>
    </div>
  </div>
</template>

<script>
import { CgButton } from '@corporategift/design-system'

import userValidations from '../mixins/userValidations'

export default {
  name: 'MultipleUsersReviewUpload',
  components: { CgButton },
  mixins: [userValidations],
  props: {
    usersData: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data: () => ({
    editRowId: null,
    editForm: {
      firstname: null,
      lastname: null,
      email: null,
    },
  }),
  computed: {
    users: {
      get () { return this.usersData },
      set (val) { this.$emit('update:usersData', val) },
    },
    disableContinueButton () {
      const { users, isRecipientDataInvalid } = this

      if (!users.length) { return true }

      const validationResults = users.reduce((acc, user) => {
        acc.push(isRecipientDataInvalid(user))
        return acc
      }, [])

      return validationResults.includes(true)
    },
  },
  methods: {
    isEmailDuplicated (emailToCheck) {
      const { users } = this

      if (!emailToCheck) return false

      const emails = users?.map((recipient) => recipient.email) ?? []
      return emails.filter((email) => email === emailToCheck).length !== 1
    },
    isRecipientEmailInvalid (email) {
      const { emailValidation } = this

      if (!email) { return true }

      if (this.isEmailDuplicated(email)) { return true }

      return emailValidation
        .map((validation) => validation(email || null))
        .includes('')
    },
    isRecipientDataInvalid (user) {
      const { firstNameValidation, lastNameValidation, emailValidation } = this

      const firstNameResult = firstNameValidation
        .map((validation) => {
          return validation(user?.firstname || null)
        })
        .includes('')

      const lastNameResult = lastNameValidation
        .map((validation) => {
          return validation(user?.lastname || null)
        })
        .includes('')

      const emailResult = emailValidation
        .map((validation) => {
          return validation(user?.email || null)
        })
        .includes('')

      return firstNameResult || lastNameResult || emailResult || false
    },
    cancelEditing () {
      this.editRowId = null

      this.editForm = {
        firstname: null,
        lastname: null,
        email: null,
      }
    },
    editRecipientInfo (index, recipient) {
      this.editRowId = index
      this.editForm = Object.assign({}, recipient)
    },
    deleteRecipient (index) {
      this.users.splice(index, 1)
      if (index === this.editRowId) { this.cancelEditing() }
    },
    saveChanges () {
      const { editForm, editRowId } = this

      if (this.isRecipientDataInvalid(editForm)) { return }

      const userIndex = this.users.findIndex((user, index) => index === editRowId)

      if (userIndex !== -1) { this.$set(this.users, userIndex, { ...editForm, }) }

      this.cancelEditing()
    },
  },
}
</script>

<style lang="scss" scoped>
.add-multiple-users-panel-review-recipients {
  display: flex;
  flex-direction: column;
  gap: 45px;

  &__table {
    margin: 0;
    border: none;
    border-spacing: 0;

    & > thead > tr > th,
    & > tbody > tr > td {
      &:first-child {
        min-width: 44px;
        width: 44px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        min-width: 118px;
        width: 118px;
        max-width: 118px;
      }

      &:nth-child(4) {
        min-width: 180px;
        width: 100%;
      }

      &:last-child {
        min-width: 168px;
        width: 168px;
        max-width: 168px;
      }
    }

    & > thead > tr > th {
      text-align: start;
      font-family: 'Lato-Bold', sans-serif;
      background: #fff;
      font-weight: 700;
      padding: 20px 4px;
      border-top: 1px solid #D3D2D2;
      border-bottom: 1px solid #D3D2D2;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #000000;

      &:first-of-type {
        padding-left: 20px;
      }

      &:last-of-type {
        padding-right: 20px;
      }
    }

    & > tbody > tr {
      background: #fff;
      cursor: default;
      position: relative;

      &:hover {
        background: #fff !important;

        & > td {
          background: #fff !important;
        }
      }

      & > td {
        padding-bottom: 30px;
        padding: 20px 4px;
        border-bottom: 1px solid #D3D2D2;
        font-family: 'Lato-Regular', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;

        &.invalid-value-in-row {
          color: #FF5A60;
        }

        &:first-of-type {
          padding-left: 20px;
        }

        &:last-of-type {
          padding-right: 20px;
        }
      }
    }
  }

  &__table-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;

    &--edit {
      gap: 8px;
    }

    & > svg {
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

.duplicated-email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid #FF5A60;
  background: #FFF;
  color: #FA5E5E;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
</style>
