<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="items.length"
    item-key="id"
    class="on-hold-table mb-8"
    @update:sort-by="setSortByData"
    @update:sort-desc="setSortByValues"
    calculate-widths
    hide-default-footer
    fixed-header
    :sort-by="sortByData"
    :sort-desc="sortByValue"
    :style="`--header-height: ${headerHeight}px`"
  >
    <template #item.send_date="{ item }">
      {{ item.send_date | date }}
    </template>
    <template #item.expiration="{ item }">
      {{ item.expiration | date }}
    </template>
    <template #item.status="{ item }">
      {{ item.status }}
    </template>
    <template #item.on_hold="{ item }">
      <div class="text-right" style="max-width: 130px">
        {{ item.on_hold | priceFilter({
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        }) }}
      </div>
    </template>
    <template #item.actions="{ item }">
      <v-tooltip
        bottom
        content-class="v-tooltip__content--none"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- TODO debug why svg can't handle v-on -->
          <div v-bind="attrs" v-on="on" @click="showEGiftOrderInNewTab(item.egift_id)">
            <icons-eye />
          </div>
        </template>
        <span class="f12 black--text text-center">
          View order
        </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { onHoldHeaders } from '@/assets/headers'

import getHeaderHeight from '@/mixins/getHeaderHeight'
import { items } from './mixins'

export default {
  name: 'TheOnHoldTable',
  mixins: [
    items,
    getHeaderHeight,
  ],
  props: {
    sortBy: {
      type: String,
      required: false,
      default: null
    },
    sortDesc: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    }
  },
  data: () => ({
    headers: onHoldHeaders,
    sortByData: [],
    sortByValue: []
  }),
  created () {
    const { sortBy, sortDesc } = this
    if (sortBy) this.sortByData.push(sortBy)
    if (sortDesc) this.sortByValue.push(sortDesc)
  },
  methods: {
    showEGiftOrderInNewTab (orderId) {
      const routeData = this.$router.resolve({ name: 'EgiftOrder', params: { id: orderId } })
      window.open(routeData.href, '_blank')
    },
    setSortByData (val) {
      this.sortByData = val
    },
    setSortByValues (val) {
      this.sortByValue = val
      this.emitUpdateSortBy()
    },
    emitUpdateSortBy () {
      this.$emit('updateSortBy', this.sortByData[0] || null, this.sortByValue[0] || false)
    }
  }
}
</script>

<style lang="scss">
.on-hold-table {
  table tr td {
    min-height: 70px !important;
    height: 70px !important;

    &:last-child {
      // padding: 0 !important;
    }
  }

  tr > th {
    border-right: none !important;
    top: calc(var(--header-height, 160px) - 2px) !important;

    &:last-child {
      padding: 0 !important;
    }

    i {
      opacity: 1 !important;
      width: 24px !important;
    }
  }
}
</style>
