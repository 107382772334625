<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 19"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="My-inventory"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="My-Inventory-6"
        transform="translate(-1207.000000, -382.000000)"
        fill="#DDF5F4"
        stroke="#979797"
      >
        <g id="group-icon" transform="translate(1207.000000, 382.000000)">
          <rect
            id="Rectangle-8"
            x="0.5"
            y="0.5"
            width="12.1246126"
            height="12.1246126"
          />
          <rect
            id="Rectangle-8-Copy"
            x="2.96086486"
            y="2.96086486"
            width="12.1246126"
            height="12.1246126"
          />
          <rect
            id="Rectangle-8-Copy-2"
            x="5.42172973"
            y="5.42172973"
            width="12.1246126"
            height="12.1246126"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ContactsGroup',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 19
    },
    height: {
      type: [Number, String],
      required: false,
      default: 19
    }
  }
}
</script>
