<template>
  <v-navigation-drawer
    :value='isShowCampaignPanel'
    app
    temporary
    right
    width='950px'
    class='side_panel'
    v-click-outside="clickOutside"
  >
    <close-panel-button
      @close='setIsShowCampaignPanel(false)'
      :text="'Cancel'"
    />
    <main class="campaign-panel px-10">
        <div class="campaign-panel__header">
          <h2 class="campaign-panel__headline headline">
            Create New Campaign
          </h2>

          <div class="campaign-panel__status">
            <div class="campaign-panel__status-text">
              Status:
              <span v-bind:class="{'campaign-panel__status-val--active': campaignForm.isActive}">
                {{campaignForm.isActive ? 'Active' : 'Disabled'}}
              </span>
            </div>
            <switcher
              :initialValue="campaignForm.isActive"
              @update-checked="updateIsActive"
            ></switcher>
          </div>
        </div>

        <div class="campaign-panel__field">
          <span class="campaign-panel__field-label">Select survey:</span>
          <common-input-filter-advanced
            :items="availableSurveys"
            @change="setSelectedSurvey"
            label="Select Survey"
            :itemText="'title'"
            :itemValue="'id'"
            :selected="campaignForm.surveyId ? campaignForm.surveyId : 0"
            :disabled="isCampaignFormEdit"
          />
          <span class="red1" v-show="surveyError">{{surveyError}}</span>
        </div>

        <div class="campaign-panel__field">
          <span class="campaign-panel__field-label">Select survey question that asks for respondent email address:</span>
          <common-input-filter-advanced
            :items="selectedSurveyQuestions"
            @change="setSelectedQuestion"
            label="Select Question"
            :itemText="'heading'"
            :itemValue="'id'"
            :selected="campaignForm.surveyEmailQuestionId ? campaignForm.surveyEmailQuestionId : 0"
          />
          <span class="red1" v-show="surveyEmailQuestionError">{{surveyEmailQuestionError}}</span>
        </div>

        <span class="campaign-panel__spacer"></span>

        <div class="campaign-panel__field">
          <span class="campaign-panel__field-label">Campaign date range:</span>
          <common-my-date-range-picker
            :dateRange="{
              start: campaignForm.activeFrom,
              end: campaignForm.activeTo
            }"
            @setDateRange="setActiveDates"
            style="margin-left: 5px;"/>
        </div>

        <span class="campaign-panel__spacer"></span>

        <div class="campaign-panel__field">
          <span class="campaign-panel__field-label">Select occasion (eGift):</span>
          <common-input-filter-advanced
            v-if="gifts.length"
            :items="gifts"
            @change="setSelectedProduct"
            label="Select Product"
            :itemText="'name'"
            :itemValue="'id'"
            :selected="campaignForm.productId"
          />
          <span class="red1" v-show="productError">{{productError}}</span>
        </div>

        <span class="campaign-panel__spacer"></span>

        <div class="campaign-panel__field">
          <span class="campaign-panel__field-label">eGift email subject line:</span>
          <v-text-field
            class="campaign-panel__field-text"
            v-model="campaignForm.emailSubject"></v-text-field>
          <span class="red1 red1--up" v-show="emailSubjectError">{{emailSubjectError}}</span>
        </div>

        <div class="campaign-panel__field">
          <span class="campaign-panel__field-label">eGift email message:</span>
          <v-textarea
            class="campaign-panel__field-text"
            v-model="campaignForm.giftMessage"></v-textarea>
          <span class="red1 red1--up" v-show="emailMessageError">{{emailMessageError}}</span>
        </div>

        <span class="campaign-panel__spacer"></span>

        <div class="campaign-panel__field">
          <span class="campaign-panel__field-label">Payment method:</span>
          <div class="payment-method">
            <div class="payment-method__box">
              <input type="radio" name="info-source" :checked="true">
              <p class="payment-method__title">CG Credits</p>
              <v-select
                id="state"
                v-model="campaignForm.budgetEntityId"
                :items="budgetEntities"
                :item-text="'name'"
                :item-value="'id'"
                label=""
                dense
                outlined
                solo
                flat
                hide-details
                tile
                class="region-attr-select-form">
              </v-select>
            </div>
          </div>
        </div>

        <div class="campaign-panel__actions">
          <common-button
            @click="submit()"
            style="width: 133px; font-size: 13px;"
            :height="40"
          >
            {{ isCampaignFormEdit ? 'Update' : 'Create' }}
          </common-button>
        </div>
    </main>
  </v-navigation-drawer>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('surveyMonkey')

export default {
  name: 'CampaignPanel',
  props: {},
  data: () => {
    return {
      surveyError: '',
      surveyEmailQuestionError: '',
      productError: '',
      emailSubjectError: '',
      emailMessageError: ''
    }
  },
  computed: {
    ...mapState([
      'campaignForm',
      'isCampaignFormEdit',
      'isShowCampaignPanel',
      'surveys',
      'availableSurveys',
      'gifts',
      'selectedSurveyQuestions',
      'budgetEntities'
    ])
  },
  methods: {
    ...mapGetters([
      'getCampaigns',
      'getSurveys',
      'getAvailableSurveys'
    ]),

    ...mapMutations([
      'setIsShowCampaignPanel',
      'setSurveys',
      'setAvailableSurveys',
      'setSelectedSurveyQuestions'
    ]),

    ...mapActions([
      'createOrUpdateCampaign',
      'updateCampaignForm'
    ]),

    updateIsActive (isActive) {
      this.campaignForm.isActive = isActive
    },

    clickOutside (e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.setIsShowCampaignPanel(false)
      }
    },

    async submit () {
      const isFormValid = this.validateForm()

      if (isFormValid) {
        try {
          await this.createOrUpdateCampaign(this.campaignForm)
          this.setIsShowCampaignPanel(false)
        } catch (errMsg) {
          console.log(errMsg)
          alert('Something went wrong')
        }
      }
    },

    validateForm () {
      let isValid = true

      this.surveyError = this.campaignForm.surveyId ? '' : 'Please select survey'
      this.surveyEmailQuestionError = this.campaignForm.surveyEmailQuestionId ? '' : 'Please select question'
      this.productError = this.campaignForm.productId ? '' : 'Please select product'
      this.emailSubjectError = this.campaignForm.emailSubject ? '' : 'Subject can not be empty'
      this.emailMessageError = this.campaignForm.giftMessage ? '' : 'Message can not be empty'

      if (!this.campaignForm.surveyId) {
        isValid = false
      }

      if (!this.campaignForm.surveyEmailQuestionId) {
        isValid = false
      }

      if (!this.campaignForm.productId) {
        isValid = false
      }

      if (!this.campaignForm.emailSubject) {
        isValid = false
      }

      if (!this.campaignForm.giftMessage) {
        isValid = false
      }

      return isValid
    },

    setSelectedSurvey (value) {
      for (const survey of this.availableSurveys) {
        if (survey.id === value) {
          this.updateCampaignForm({
            surveyId: value,
            surveyName: survey.title
          })
          break
        }
      }
    },

    setSelectedQuestion (value) {
      this.updateCampaignForm({
        surveyEmailQuestionId: value
      })
    },

    setSelectedProduct (value) {
      this.updateCampaignForm({
        productId: value
      })
    },

    setActiveDates (range) {
      this.updateCampaignForm({
        activeFrom: range.start,
        activeTo: range.end
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./src/css/integrations/campaign-panel";
  .payment-method {
    width: 100%;
    max-width: 400px;
    border: 1px solid $colorBorder;
    padding: 15px;

    &__title {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .red1--up {
    top: -20px;
    position: relative;
  }
</style>
