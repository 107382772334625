<template>
  <div
    class="upload-image"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      type="file"
      name="upload-image"
      id="upload-image"
      @change="onChange"
      multiple
      ref="file"
      accept=".jpg, .jpeg, .png, .pdf, .gif, .ai, .eps"
    >

    <label for="upload-image">
      <icons-upload
        width="71"
        height="55"
      />
      Upload New
    </label>
  </div>
</template>

<script>
import Api from '@/axios/api'

export default {
  name: 'TheUploadImage',
  methods: {
    onChange () {
      const files = this.$refs.file.files
      // eslint-disable-next-line
      files?.forEach((file) => {
        this.uploadFile(file)
      })
    },
    dragover (event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('upload-image--file-hover')) {
        event.currentTarget.classList.add('upload-image--file-hover')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('upload-image--file-hover')
    },
    drop (event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('upload-image--file-hover')
    },
    uploadFile (file) {
      const formData = new FormData()
      formData.append('file', file, file?.name)

      Api.post('/customer/logos', formData)
        .then(({ success, data }) => {
          if (success) this.$emit('newFile', data)
        })
        .catch((e) => {
          if (e?.response?.data) {
            const { file } = e?.response?.data?.errors
            const errorMessage = file?.join(' ')

            this.$emit('showError', { message: errorMessage })
          } else {
            this.$emit('showError', {})
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-image {
  display: grid;
  place-items: center;
  background-color: #fff;
  border: 1px dashed #979797;
  padding: 3px;
  width: 100%;
  height: 150px;
  cursor: pointer;

  &:hover, &--file-hover {
    border: 4px solid #A7DAD8;
    padding: 0;
  }

  input {
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }

  label {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    padding: 26px;
    text-transform: uppercase;
    font-family: 'Lato-Bold', sans-serif;
    cursor: inherit;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000;
  }
}
</style>
