<template>
  <ul>
    <li v-for="(item, index) in items" :key="index">
      <a :href="getItemLink(item)">{{ item.title }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TheFooterItemList',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    magentoUrl: process.env.VUE_APP_MAGENTO_URL
  }),
  methods: {
    getItemLink ({ url }) {
      const { magentoUrl } = this

      if (url.includes('/contacts/')) return window.location.origin + url

      return magentoUrl + url
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > li > a {
    cursor: pointer;
    color: #000;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;

    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 639px) {
    padding: 0 14px;

    & > li > a {
      font-size: 15px;
    }
  }
}
</style>
