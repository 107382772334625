import { createNamespacedHelpers } from 'vuex'
import { required, minLength, maxLength } from '@corporategift/design-system/validations'
import checkIfStringContainsNotAllowedTag from './checkIfStringContainsNotAllowedTag'

const { mapState } = createNamespacedHelpers('newCampaign')

export default {
  mixins: [checkIfStringContainsNotAllowedTag],
  computed: {
    ...mapState(['alreadyUsedNames']),
    campaignNameValidation () {
      const {
        required,
        minLength,
        maxLength,
        isCampaignNameAlreadyUsed,
        isCampaignNameTooShort,
        checkIfStringContainsNotAllowedTag,
      } = this

      return [
        required(),
        minLength({
          min: 2,
          errorMessage: 'Campaign name should have at least 2 characters',
        }),
        maxLength({
          max: 150,
          errorMessage: `You can't create a campaign name with more than 150 characters`,
        }),
        isCampaignNameAlreadyUsed,
        isCampaignNameTooShort,
        checkIfStringContainsNotAllowedTag,
      ]
    }
  },
  methods: {
    required,
    minLength,
    maxLength,
    isCampaignNameAlreadyUsed (val) {
      const { alreadyUsedNames } = this
      if (!val) { return true }

      return !alreadyUsedNames.some((name) => name.toLowerCase() === val.toLowerCase())
        || 'You already have a campaign with that name'
    },
    isCampaignNameTooShort (val) {
      const parsedVal = val?.trim() || ''
      if (parsedVal.length < 2) { return 'Campaign name should have at least 2 characters' }
      
      return true
    }
  },
}
