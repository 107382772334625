var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cg-credits-balance dashboard-card",class:{
    'dashboard-card--small': !_vm.showLargeCard,
    'dashboard-card--large': _vm.showLargeCard,
    'order-6': _vm.adminAccount && _vm.hasApprovalOrders && !_vm.$vuetify.breakpoint.lgAndUp && !_vm.$vuetify.breakpoint.xsOnly,
    'order-5': (_vm.adminAccount && !(_vm.superAdmin || _vm.masterAccount) && _vm.hasApprovalOrders && _vm.$vuetify.breakpoint.lgAndUp) ||
      ((_vm.superAdmin || _vm.masterAccount) && !_vm.hasInventory && _vm.hasApprovalOrders && _vm.$vuetify.breakpoint.lgAndUp) || 
      ((_vm.superAdmin || _vm.masterAccount) && _vm.hasInventory && !_vm.hasApprovalOrders && _vm.$vuetify.breakpoint.lgAndUp) ||
      (_vm.adminAccount && !_vm.hasApprovalOrders && _vm.hasInventory && _vm.$vuetify.breakpoint.lgAndUp),
    'order-2': (!_vm.adminAccount && _vm.$vuetify.breakpoint.lgAndUp) ||
      (_vm.adminAccount && !_vm.hasApprovalOrders && !_vm.hasInventory && _vm.$vuetify.breakpoint.lgAndUp) ||
      ((_vm.superAdmin || _vm.masterAccount) && _vm.hasApprovalOrders && _vm.hasInventory),
    'order-3': (!_vm.adminAccount && !_vm.$vuetify.breakpoint.lgAndUp) ||
      (_vm.adminAccount && !_vm.hasApprovalOrders && !_vm.$vuetify.breakpoint.lgAndUp) || _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('div',{staticClass:"cg-credits-balance__title"},[_vm._v(" "+_vm._s(_vm.userHasEntitiesWithSpendLimit ? 'CG Credits Balance (personal)' : 'CG Credits Balance')+" ")]),_c('v-divider'),(!_vm.loading && _vm.entities.length && !_vm.allEntitiesHasBalanceSetToZero)?[_c('div',{staticClass:"cg-credits-balance__content",class:{ 'justify-center': _vm.marketplaceUser || _vm.entities.length < 3 }},_vm._l((_vm.entities),function(entity,index){return _c('the-cg-credits-balance-bar',{key:index,attrs:{"entity":entity,"max-balance":_vm.maxValue}})}),1),(_vm.hasAccessToCgCredits)?_c('div',{staticClass:"cg-credits-balance__actions"},[_c('router-link',{attrs:{"to":{ name: 'MyCGCredits' }}},[_vm._v("Full CG Credits info")])],1):_vm._e()]:(!_vm.loading && (!_vm.entities.length || _vm.allEntitiesHasBalanceSetToZero))?[_c('div',{staticClass:"cg-credits-balance__empty"},[_c('icons-empty-cg-credits'),(_vm.marketplaceUser || _vm.allEntitiesHasBalanceSetToZero)?_c('span',[_vm._v(" You have no CG Credits in your account. "),_c('a',{attrs:{"href":"https://corporategift.com/platform/","target":"_blank"}},[_vm._v("Learn more")]),_vm._v(" about CG Credits ")]):_c('span',[_vm._v(" You are not associated with any budget entity yet ")])],1)]:_c('common-loader')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }