var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"inventory-kit",attrs:{"outlined":"","tile":"","min-height":"430","width":"350"}},[_c('div',{staticClass:"inventory-kit__images"},[_c('v-row',{staticClass:"h100",attrs:{"no-gutters":""}},[(_vm.item.product.kit.products.length === 2)?[_vm._l((_vm.item.product.kit.products),function(product,index){return [(index === 1)?_c('v-col',{key:`empty-${index}`,attrs:{"cols":"6"}}):_vm._e(),(index < 3 || (_vm.item.product.kit.products.length <= 4 && index <= 4))?_c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"alt":product.name,"src":_vm.getImagePath(product.image),"width":"148","height":"148"}})],1):_vm._e(),(index === 0)?_c('v-col',{key:`empty-${index}`,attrs:{"cols":"6"}}):_vm._e()]})]:[_vm._l((_vm.item.product.kit.products),function(product,index){return [(index < 3 || (_vm.item.product.kit.products.length <= 4 && index <= 4))?_c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"alt":product.name,"src":_vm.getImagePath(product.image),"width":"148","height":"148"}})],1):_vm._e()]}),(_vm.item.product.kit.products.length > 4)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('div',{staticClass:"inventory-kit__multiple-images"},[_vm._v(" +"+_vm._s(_vm.item.product.kit.products.length - 3)+" ")])]):_vm._e()]],2),_c('tray',[_c('div',{staticClass:"d-flex"},[(_vm.showBalanceBadge)?_c('tray-badge',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.tooltipText,
            ..._vm.iconTooltipProps,
            offset: 8,
            placement: 'top',
            classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
          }),expression:"{\n            content: tooltipText,\n            ...iconTooltipProps,\n            offset: 8,\n            placement: 'top',\n            classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',\n          }"}],attrs:{"color":"red","outlined":""}},[_vm._v(" "+_vm._s(_vm._f("maxZeroFilter")(_vm.userKitBalance))+" ")]):_vm._e()],1),(_vm.superAdmin || _vm.masterAccount)?_c('tray-kit-options',{on:{"onOrdersHistory":function($event){return _vm.showOrdersHistory()},"onAssignUsers":function($event){return _vm.showAssignUsers()},"onShowKitPanel":function($event){return _vm.showKitPanel()}}}):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Kit items', ..._vm.iconTooltipProps }),expression:"{ content: 'Kit items', ...iconTooltipProps }"}],staticClass:"inventory-kit__refill-icon",on:{"click":function($event){return _vm.showKitPanel()}}},[_c('icons-settings-line',{attrs:{"width":20,"height":20}})],1)],1)],1),_c('v-card-text',{staticClass:"px-6 pt-4 pb-0 mt-auto"},[(_vm.linkToKitPage)?_c('a',{staticClass:"inventory-kit__name",attrs:{"href":_vm.linkToKitPage,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")]):_c('h1',{staticClass:"inventory-kit__name"},[_vm._v(" "+_vm._s(_vm.item.product.name)+" ")])]),_c('v-card-actions',{staticClass:"pa-6"},[(_vm.isGroupedKit)?_c('common-button',{attrs:{"height":44,"disabled":_vm.disableButton,"block":""},on:{"click":function($event){return _vm.sendItemAsEGift(_vm.item)}}},[_vm._v(" Send with eGift ")]):_c('inventory-card-send-button',{attrs:{"item":_vm.item}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }