<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 92 110"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="54.5" cy="73" r="37" fill="#FAE3E3"/>
    <circle cx="7" cy="29.5" r="6.5" fill="#FAE3E3"/>
    <circle cx="32" cy="9.5" r="9.5" fill="#FAE3E3"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.599 25.0624L62.6193 32.5972C62.9268 32.7072 63.1257 33.011 63.1073
        33.3424V55.6156L78.5583 62.902C78.8658 63.012 79.0647 63.3158 79.0463
          63.6472V86.5C79.0647 86.8313 78.8658 87.1351 78.5583 87.2452L62.538
          94.78H61.8061L46.2737 87.4936L30.7413 94.78H30.0094L13.9891 87.2452C13.6817 87.1351
          13.4827 86.8313 13.5012 86.5V63.6472C13.4827 63.3158 13.6817 63.012 13.9891
          62.902L29.4402 55.6156V33.3424C29.4217 33.011 29.6207 32.7072 29.9281 32.5972L45.9484
          25.0624C46.1576 24.9792 46.3898 24.9792 46.599 25.0624ZM44.2407 63.7299L30.1721
          57.106L16.2661 63.6472L30.3347 70.1884L44.2407 63.7299ZM45.4605 41.374V62.488L31.0666
          55.6984V34.5844L45.4605 41.374ZM61.4808 34.5844L47.0869 41.374V62.488L61.4808
          55.6984V34.5844ZM31.1479 71.6788L45.4605 64.972V86.0032L31.1479 92.71V71.6788ZM61.3995
          71.6788L47.0869 64.972V86.0032L61.3995 92.71V71.6788ZM62.2127 70.1884L48.3068
          63.7299L62.3754 57.106L76.2813 63.6472L62.2127 70.1884ZM60.3423 33.3424L46.2737
          26.7184L32.2051 33.3424L46.2737 39.9664L60.3423 33.3424ZM15.1276 64.8892L29.5215
          71.6788V92.71L15.1276 86.0032V64.8892ZM63.0259 71.6788V92.71L77.4198
          86.0032V64.8892L63.0259 71.6788Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'Inventory',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 92
    },
    height: {
      type: [Number, String],
      required: false,
      default: 110
    }
  }
}
</script>
