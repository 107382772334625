<template>
  <div class="refill-input" :class="(outOfStock || missingInfo) && 'refill-input--out-of-stock'">
    <label :for="'refill-input-' + id" v-if="label">
      {{ label }}
    </label>
    <popper
      :trigger="(!outOfStock && !missingInfo) ? 'clickToOpen' : 'hover'"
      :visible-arrow="false"
      :options="{
        placement: 'bottom',
        modifiers: {
          offset: { offset: '0, 0' },
        },
      }"
    >
      <div class="refill-input__tooltip popper">
        <template v-if="!outOfStock && !missingInfo">
          {{ marketplaceProductQty }} in inventory
        </template>
        <template v-else>
          We're missing some info for this size. <br />
          Please contact your Customer Success Manager to update your info and enable refill.
        </template>
      </div>

      <div class="input-wrapper" slot="reference">
        <input
          v-model="inputValue"
          :id="'refill-input-' + id"
          type="number"
          ref="input"
          min="0"
          :max="marketplaceProductQty"
          :disabled="missingInfo"
          :aria-disabled="missingInfo"
          @focus="setIsFocused(true)"
          @keydown.tab="setIsFocused(false)"
          v-if="!outOfStock && !missingInfo"
        />
        <div class="input__controls" @click="focusInput()" v-if="!outOfStock && !missingInfo">
          <div @click="increaseValue()">
            <icons-arrow-up />
          </div>
          <div @click="decreaseValue()">
            <icons-arrow-up />
          </div>
        </div>

        <div class="input__out-of-stock" v-if="outOfStock || missingInfo">
          {{ missingInfo ? 'Missing info' : 'Out of stock' }}
        </div>
      </div>
    </popper>

  </div>
</template>

<script>
import Popper from 'vue-popperjs'

export default {
  name: 'RefillInput',
  components: {
    Popper,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    id: {
      type: Number,
      required: true
    },
    marketplaceProductQty: {
      type: Number,
      required: false,
      default: 0
    },
    outOfStock: {
      type: Boolean,
      required: false,
      default: false
    },
    missingInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    isFocused: false
  }),
  computed: {
    inputValue: {
      get () {
        return this.value?.qty || 0
      },
      set (val) {
        const { id } = this

        this.$emit('input', {
          entity_id: id,
          qty: Number(val)
        })
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    clickHandler (event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.setIsFocused(false)
      }
    },
    focusInput () {
      this.$refs.input.focus()
    },
    increaseValue () {
      const { inputValue, marketplaceProductQty } = this
      this.focusInput()
      if (inputValue < marketplaceProductQty) this.inputValue += 1
    },
    decreaseValue () {
      this.focusInput()
      if (this.inputValue > 0) this.inputValue -= 1
    },
    setIsFocused (val = true) {
      this.isFocused = val
    }
  }
}
</script>

<style lang="scss">
.refill-input {
  position: relative;

  label {
    font-family: 'Lato-Bold', sans-serif !important;
    font-size: 13px;
    line-height: 22px;
    color: #000;
    display: block;
  }

  .input-wrapper {
    border: 1px solid #DEDEDE;
    min-width: 60px;
    max-width: 60px;
    height: 36px;
    cursor: pointer;
    position: relative;

    input {
      height: 100%;
      max-width: calc(60px - 2px);
      box-sizing: border-box;
      padding: 0 18px 0 6px;
      min-width: inherit;
      border: none;

      &:focus {
        outline: none;
        & ~ .input__controls {
          opacity: 1;
        }
      }
    }

    .input__controls {
      position: absolute;
      display: flex;
      opacity: 0;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 10px;
      right: 6px;
      top: 0;
      transition: all ease-in-out 0.28s;

      & > div {
        height: 50%;
        display: flex;
        align-items: center;

        &:last-child {
          transform: rotate(180deg);
        }
      }
    }

    .input__out-of-stock {
      font-family: 'Lato-Bold', sans-serif !important;
      text-transform: uppercase;
      font-size: 8px;
      line-height: 10px;
      color: #95979D;
      padding: 8px;
      display: flex;
      align-items: center;
      text-align: center;
      height: 100%;
    }
  }

  &__tooltip {
    border-radius: 4px;
    background: #4A4A4A;
    z-index: 4;
    color: #fff;
    margin-top: 0;
    max-width: 240px;
    padding: 6px 10px;
    width: fit-content;
    white-space: nowrap;
    border: none;

    & > .popper__arrow {
      border-top-color: #4A4A4A !important;
    }
  }

  &--out-of-stock {
    label {
      color: #FF5A60;
    }

    .input-wrapper {
      cursor: default;
    }

    &:hover {
      .refill-input__tooltip {
        display: block !important;
        white-space: pre-wrap;
        width: 290px;
      }
    }
  }
}
</style>
