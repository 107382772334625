<template>
  <span>
    This feature is available for CG Elite members only.
    <a :href="link" target="_blank" rel="noopener noreferrer"> Click here </a>
    to learn more about becoming a CG Elite member
    <br/>
    <br/>
  </span>
</template>

<script>
export default {
  name: 'AvailableOnlyForCgEliteText',
  data: () => ({
    link: process.env.VUE_APP_MAGENTO_URL + '/platform/'
  })
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline !important;
}
</style>
