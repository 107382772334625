<template>
  <div class="api-tokens">
    <v-container class="px-0">
      <span class="page-title text-capitalize">API Tokens</span>
    </v-container>
    <v-container class="px-0">
      <div class="d-flex justify-space-between">
        <common-search-form
          :iconMaxWidth="13"
          :iconHeight="13"
          :placeholder="'Filter by name'"
          :maxWidth="227"
          :height="30"
          @setSearchValue="setSearchValue"
        />
        <div class="d-flex align-center pointer" @click="createModal = true">
          <v-img
            src="/images/new-group-icon.png"
            width="17"
            height="17"
          />
          <span class="f14 green2 ml-2">New Token</span>
        </div>
      </div>
    </v-container>
    <v-container class="px-0">
      <api-tokens-data-table
        :search="search"
        :items="items"
        :loading="loading"
        @destroy="destroy"
      />
    </v-container>

    <api-tokens-create
      v-model="createModal"
    />

    <api-tokens-destroy
      v-model="destroyModal"
      :token="destroyToken"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('apiTokens')

export default {
  name: 'api-tokens',
  data: () => ({
    search: '',
    createModal: false,
    destroyModal: false,
    destroyToken: undefined
  }),
  created () {
    this.load()
  },
  methods: {
    ...mapActions([
      'load'
    ]),
    destroy (token) {
      this.destroyToken = token
      this.destroyModal = true
    },
    setSearchValue (value) {
      this.search = value
    }
  },
  computed: {
    ...mapState([
      'loading',
      'items'
    ])
  }
}
</script>
