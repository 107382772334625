<template>
  <v-dialog v-model="show" persistent eager content-class="elevation-0" width="740">
    <div class="preview-video-modal">
      <div class="preview-video-modal__content">
        <video
          :src="video.url"
          v-if="video.video"
          frameborder="0"
          title="Video preview"
          allowfullscreen="false"
          webkitallowfullscreen="false"
          mozallowfullscreen="false"
          controls
          width="650"
          height="360"
        />

        <iframe
          :src="video.url"
          v-if="video.iframe"
          frameborder="0"
          title="Video preview"
          controls
          width="650"
          height="360"
        />

        <div v-if="video.invalid" class="preview-video-modal__error">
          Unable to load video
        </div>
      </div>

      <icons-close
        @click.native="show = false"
        :width="18"
        :height="18"
        color="#fff"
      />
    </div>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('app')

export default {
  name: 'ThePreviewVideoModal',
  data: () => ({
    iframe: false,
    video: {
      iframe: false,
      video: false,
      url: null,
      invalid: false,
    },
  }),
  computed: {
    ...mapState([
      'showVideoModal',
      'videoModalUrl',
    ]),
    show: {
      get () {
        return this.showVideoModal
      },
      set (val) {
        this.handleVideoModal({
          show: val,
          url: null,
        })
      }
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        const { videoModalUrl } = this
        try {
          const url = new URL(videoModalUrl)
          const takeArgumentFromPathnameById = (pathname, index, splitBy = '/') => pathname.split(splitBy)[index]
          // for youtu.be and youtube links
          if (url.hostname.includes('youtu')) {
            const takeYoutubeId = (url) => url.match(/(^|=|\/)([0-9A-Za-z_-]{11})(\/|&|$|\?|#)/)[2]
            this.video.iframe = true
            this.video.video = false
            // take video id and create youtube embed link
            this.video.url = `https://youtube.com/embed/${takeYoutubeId(videoModalUrl)}`
          } else if (url.hostname.includes('loom')) {
            this.video.iframe = true
            this.video.video = false
            // take video id and create loom embed link
            this.video.url = `https://www.loom.com/embed/${takeArgumentFromPathnameById(url.pathname, [2])}`
          } else if (url.hostname.includes('kommute')) {
            this.video.iframe = true
            this.video.video = false
            // take video id and create vimail embed link
            this.video.url = `https://www.kommute.com/to/mymail/${takeArgumentFromPathnameById(url.pathname, [3])}/embed?width=730&height=460`
          } else {
            this.video.iframe = false
            this.video.video = true
            this.video.url = this.videoUrl
          }
        } catch (e) {
          this.video.invalid = true
          console.error('Invalid Url')
        }
      } else {
        setTimeout(() => {
          Object.assign(this.$data, this.$options.data())
        }, 400)
      }
    }
  },
  methods: {
    ...mapActions(['handleVideoModal']),
  },
}
</script>

<style lang="scss" scoped>
.preview-video-modal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;

  &__content {
    flex: 1 1 auto;
    padding: 30px;
    background: #fff;
  }

  &__error {
    color: #FF5A60;
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  & > svg {
    cursor: pointer;
  }
}
</style>
