<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="13" cy="13" r="12" :stroke="color" stroke-width="0.7"/>
    <path d="M9.2908 8.86133L17.3076 16.8779" :stroke="color" stroke-width="1.09091"/>
    <path d="M9.29105 16.8775L17.3076 8.86096" :stroke="color" stroke-width="1.09091"/>
  </svg>
</template>

<script>
export default {
  name: 'CloseCircle',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 26
    },
    height: {
      type: [Number, String],
      required: false,
      default: 26
    },
    color: {
      type: String,
      required: false,
      default: '#95979D',
    },
  }
}
</script>
