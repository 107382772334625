import { render, staticRenderFns } from "./AchMethod.vue?vue&type=template&id=37f4c6f8&scoped=true"
import script from "./AchMethod.vue?vue&type=script&lang=js"
export * from "./AchMethod.vue?vue&type=script&lang=js"
import style0 from "./AchMethod.vue?vue&type=style&index=0&id=37f4c6f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f4c6f8",
  null
  
)

export default component.exports