const els = []

let currentRoute = null

function addEl (el) {
  if (!els.includes(el)) {
    els.push(el)
  }
}

function removeEl (el) {
  const index = els.indexOf(el)
  if (index > -1) els.splice(index, 1)
}

function disableBodyScroll (el, router) {
  addEl(el)
  currentRoute = router.history.current.name
  document.querySelector('html').style.overflow = 'hidden'
}

function enableBodyScroll (el) {
  removeEl(el)
  if (els.length === 0) {
    document.querySelector('html').style.overflow = 'auto'
    currentRoute = null
  }
}

export default {
  install (Vue, options) {
    Vue.directive('lock-scroll', {
      inserted (el, binding, vnode) {
        if (binding.value) {
          disableBodyScroll(el, vnode.context.$router)
        }
      },
      componentUpdated (el, binding, vnode) {
        const elementRouteName = vnode.context.$router?.history?.current?.name ?? null

        if (currentRoute) {
          if (elementRouteName !== currentRoute) enableBodyScroll(el)
        }

        if (binding.value) {
          disableBodyScroll(el, vnode.context.$router)
        } else {
          enableBodyScroll(el)
        }
      },
      unbind (el) {
        enableBodyScroll(el)
      }
    })
  }
}
