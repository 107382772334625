<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fill="none"
    :width="width"
    :height="height"
  >
    <rect id="square-helper" x="0.5" y="0.25" width="29" height="29" rx="2" fill="#EDFCFB"/>
    <path
      d="M6.5 8.125C6.5 7.62772 6.69754 7.15081 7.04917 6.79917C7.40081 6.44754 7.87772 6.25 8.375
        6.25H12.125C12.6223 6.25 13.0992 6.44754 13.4508 6.79917C13.8025 7.15081 14 7.62772 14
        8.125V11.875C14 12.3723 13.8025 12.8492 13.4508 13.2008C13.0992 13.5525 12.6223 13.75 12.125
        13.75H8.375C7.87772 13.75 7.40081 13.5525 7.04917 13.2008C6.69754 12.8492 6.5 12.3723 6.5
        11.875V8.125ZM8.375 7.5C8.20924 7.5 8.05027 7.56585 7.93306 7.68306C7.81585 7.80027 7.75
        7.95924 7.75 8.125V11.875C7.75 12.0408 7.81585 12.1997 7.93306 12.3169C8.05027 12.4342
        8.20924 12.5 8.375 12.5H12.125C12.2908 12.5 12.4497 12.4342 12.5669 12.3169C12.6842 12.1997
        12.75 12.0408 12.75 11.875V8.125C12.75 7.95924 12.6842 7.80027 12.5669 7.68306C12.4497
        7.56585 12.2908 7.5 12.125 7.5H8.375ZM16.5 8.125C16.5 7.62772 16.6975 7.15081 17.0492
        6.79917C17.4008 6.44754 17.8777 6.25 18.375 6.25H22.125C22.6223 6.25 23.0992 6.44754 23.4508
        6.79917C23.8025 7.15081 24 7.62772 24 8.125V11.875C24 12.3723 23.8025 12.8492 23.4508
        13.2008C23.0992 13.5525 22.6223 13.75 22.125 13.75H18.375C17.8777 13.75 17.4008 13.5525
        17.0492 13.2008C16.6975 12.8492 16.5 12.3723 16.5 11.875V8.125ZM18.375 7.5C18.2092 7.5
        18.0503 7.56585 17.9331 7.68306C17.8158 7.80027 17.75 7.95924 17.75 8.125V11.875C17.75
        12.0408 17.8158 12.1997 17.9331 12.3169C18.0503 12.4342 18.2092 12.5 18.375
        12.5H22.125C22.2908 12.5 22.4497 12.4342 22.5669 12.3169C22.6842 12.1997 22.75 12.0408 22.75
        11.875V8.125C22.75 7.95924 22.6842 7.80027 22.5669 7.68306C22.4497 7.56585 22.2908 7.5 22.125
        7.5H18.375ZM6.5 18.125C6.5 17.6277 6.69754 17.1508 7.04917 16.7992C7.40081 16.4475 7.87772
        16.25 8.375 16.25H12.125C12.6223 16.25 13.0992 16.4475 13.4508 16.7992C13.8025 17.1508 14
        17.6277 14 18.125V21.875C14 22.3723 13.8025 22.8492 13.4508 23.2008C13.0992 23.5525 12.6223
        23.75 12.125 23.75H8.375C7.87772 23.75 7.40081 23.5525 7.04917 23.2008C6.69754 22.8492 6.5
        22.3723 6.5 21.875V18.125ZM8.375 17.5C8.20924 17.5 8.05027 17.5658 7.93306 17.6831C7.81585
        17.8003 7.75 17.9592 7.75 18.125V21.875C7.75 22.0408 7.81585 22.1997 7.93306 22.3169C8.05027
        22.4342 8.20924 22.5 8.375 22.5H12.125C12.2908 22.5 12.4497 22.4342 12.5669 22.3169C12.6842
        22.1997 12.75 22.0408 12.75 21.875V18.125C12.75 17.9592 12.6842 17.8003 12.5669 17.6831C12.4497
        17.5658 12.2908 17.5 12.125 17.5H8.375ZM16.5 18.125C16.5 17.6277 16.6975 17.1508 17.0492
        16.7992C17.4008 16.4475 17.8777 16.25 18.375 16.25H22.125C22.6223 16.25 23.0992 16.4475 23.4508
        16.7992C23.8025 17.1508 24 17.6277 24 18.125V21.875C24 22.3723 23.8025 22.8492 23.4508
        23.2008C23.0992 23.5525 22.6223 23.75 22.125 23.75H18.375C17.8777 23.75 17.4008 23.5525 17.0492
        23.2008C16.6975 22.8492 16.5 22.3723 16.5 21.875V18.125ZM18.375 17.5C18.2092 17.5 18.0503
        17.5658 17.9331 17.6831C17.8158 17.8003 17.75 17.9592 17.75 18.125V21.875C17.75 22.0408 17.8158
        22.1997 17.9331 22.3169C18.0503 22.4342 18.2092 22.5 18.375 22.5H22.125C22.2908 22.5 22.4497
        22.4342 22.5669 22.3169C22.6842 22.1997 22.75 22.0408 22.75 21.875V18.125C22.75 17.9592 22.6842
        17.8003 22.5669 17.6831C22.4497 17.5658 22.2908 17.5 22.125 17.5H18.375Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'InventoryGridView',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 25
    },
    height: {
      type: [Number, String],
      required: false,
      default: 25
    },
  },
}
</script>

<style>

</style>