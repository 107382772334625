<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.591 13.7702C15.2757 13.7702 15.0207 14.0236 15.0207 14.336C15.0207 14.6492 15.2757
        14.9025 15.591 14.9025C17.5396 14.9025 19.1258 16.4776 19.1258 18.4143C19.1258 18.6635
        18.9212 18.8669 18.6694 18.8669H5.32898C5.07809 18.8669 4.87341 18.6635 4.87341
        18.4143C4.87341 16.4776 6.45967 14.9025 8.40742 14.9025C8.72269 14.9025 8.97772 14.6492
        8.97772 14.336C8.97772 14.0236 8.72269 13.7702 8.40742 13.7702C5.82995 13.7702 3.73364
        15.8528 3.73364 18.4143C3.73364 19.2883 4.45002 20 5.32898 20H18.6694C19.5492 20 20.2664
        19.2883 20.2664 18.4143C20.2664 15.8528 18.1693 13.7702 15.591 13.7702ZM12 5.13232C13.7282
        5.13232 15.1354 6.94271 15.1354 9.16798C15.1354 11.3924 13.7282 13.2036 12 13.2036C10.271
        13.2036 8.86465 11.3924 8.863 9.16798C8.863 6.94271 10.2702 5.13232 12 5.13232ZM12
        14.336C14.358 14.336 16.2752 12.018 16.2752 9.16798C16.2752 6.31793 14.358 4 12 4C10.8339 4
        9.74444 4.55345 8.93397 5.5595C8.15322 6.52947 7.72406 7.81019 7.72406 9.16798C7.72406 12.018
        9.6421 14.336 12 14.336Z"
      fill="#62646A"
    />
  </svg>
</template>

<script>
export default {
  name: 'User',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    }
  }
}
</script>
