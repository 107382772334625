<template>
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <green-text-with-arrow
        :arrowDown="showPriceBreakdown"
        @onClick="showPriceBreakdown = !showPriceBreakdown"
      >
        Price breakdown
      </green-text-with-arrow>
    </v-col>

    <v-expand-transition>
      <v-col cols="12" class="py-0" v-show="showPriceBreakdown">
        <v-row no-gutters class="justify-end my-3">
          <price-breakdown
            :loading="loading"
            :price="price"
            :tax="tax"
            :shippingPrice="shippingPrice"
            :shippingPriceFull="shippingPriceFull"
            :storagePrice="storagePrice"
            :pickAndPackPrice="pickAndPackPrice"
            :shippingToRecipPrice="shippingToRecipPrice"
            :customizationFee="customizationFee"
            :hasVariants="hasVariants"
            :variantsPrices="variantsPrices"
            :totalPerUnit="totalPerUnit"
            :breakdownCols="5"
            :totalQty="totalQty"
            :totalPrice="overwriteSubtotal ? customSubtotal : subtotal"
          />
        </v-row>
      </v-col>
    </v-expand-transition>

    <v-col cols="12">
      <v-row no-gutters class="justify-space-between fill-height align-center subtotal">
        <v-col cols="6" class="px-4">
          <span class="subtotal__title pr-2">Total Qty:</span>
          <span class="subtotal__qty pr-2">
            {{ totalQty || 0 }}
          </span>
          <span class="subtotal__min-qty" v-if="showMinQtyInfo && minQty > 1">
            Minimum quantity for this item is {{ minQty }}
          </span>
        </v-col>
        <v-col cols="4" class="px-4 d-flex align-center justify-end">
          <span class="subtotal__title pr-2">Subtotal:</span>
          <span class="subtotal__total-price">
            {{ overwriteSubtotal ? customSubtotal : subtotal | priceFilter({
              style: 'currency',
              currency: 'USD'
            }) }}
          </span>

          <v-progress-circular
            v-if="loading"
            indeterminate
            size="14"
            width="2"
            class="ml-2"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import GreenTextWithArrow from '../Panels/common/GreenTextWithArrow'
import PriceBreakdown from '../Panels/common/PriceBreakdown'

export default {
  name: 'RefillSubtotal',
  components: {
    GreenTextWithArrow,
    PriceBreakdown
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    price: {
      type: [Number, String],
      required: true
    },
    tax: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    shippingPrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    shippingPriceFull: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    storagePrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    pickAndPackPrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    shippingToRecipPrice: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    customizationFee: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    totalPerUnit: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    overwriteSubtotal: {
      type: Boolean,
      required: false,
      default: false
    },
    customSubtotal: {
      type: [Number, String],
      required: false,
      default: '0.00'
    },
    hasVariants: {
      type: Boolean,
      required: false,
      default: false
    },
    variantsPrices: {
      type: Array,
      required: false,
      default: () => ([])
    },
    totalQty: {
      type: [Number, String],
      required: true
    },
    minQty: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    showPriceBreakdown: false
  }),
  computed: {
    unitCost () {
      // const { totalQty, price } = this
      // const total = totalQty > 9
      //   ? totalQty > 25
      //     ? totalQty > 50
      //       ? totalQty > 100
      //         ? totalQty > 200
      //           ? (price * 0.91)
      //           : (price * 0.92)
      //         : (price * 0.94)
      //       : (price * 0.96)
      //     : (price * 0.98)
      //   : (price * 1)

      return this.price
    },
    totalPricePerUnit () {
      const { unitCost, tax, shippingPrice, storagePrice, pickAndPackPrice, shippingToRecipPrice, customizationFee } = this

      return Number(unitCost) +
        Number(tax) +
        Number(shippingPrice) +
        Number(storagePrice) +
        Number(pickAndPackPrice) +
        Number(shippingToRecipPrice) +
        Number(customizationFee)
    },
    subtotal () {
      const { totalQty, totalPricePerUnit } = this

      return Number(totalQty) * totalPricePerUnit
    },
    showMinQtyInfo () {
      const { minQty, totalQty } = this
      return totalQty < minQty
    }
  },
  watch: {
    subtotal: function (val) {
      this.$emit('subtotalChange', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.subtotal {
  height: 55px;
  background-color: #DFF3F2;

  &__title, &__total-price {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
    line-height: 40px;
    color: #000;
  }

  &__qty {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #4a4a4a;
  }

  &__min-qty {
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #F15B5B;
  }

  &__total-price {
    font-size: 16px;
  }
}
</style>
