<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    fill="none"
    :height="height"
    :width="width"
  >
    <ellipse cx="10.5313" cy="10.4324" rx="9.56306" ry="10" fill="#FF5A60" />
    <path d="M6.28076 14.6537L14.5678 5.98779" stroke="white" stroke-width="1.01774" />
    <path d="M14.5678 14.6535L6.28076 5.98779" stroke="white" stroke-width="1.01774" />
  </svg>
</template>

<script>
export default {
  name: 'RemoveFile',
  props: {
    width: {
      type: Number,
      required: false,
      default: 21
    },
    height: {
      type: Number,
      required: false,
      default: 21
    },
  }
}
</script>
