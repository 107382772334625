<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    :height="height"
    :width="width"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.55204 4.42709C8.95477 4.02435 9.60773 4.02435 10.0105 4.42709L15.8542 10.2708C16.2569
        10.6735 16.2569 11.3265 15.8542 11.7292L10.0105 17.573C9.60773 17.9757 8.95477 17.9757
        8.55204 17.573C8.14932 17.1702 8.14932 16.5173 8.55204 16.1145L13.6666 11L8.55204
        5.88542C8.14932 5.48268 8.14932 4.82983 8.55204 4.42709Z
      "
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRight',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    }
  }
}
</script>
