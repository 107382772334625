<template>
  <div class="send-egift-panel-recipients-copy-paste">
    <div class="send-egift-panel-recipients-copy-paste__subtext">
      Paste recipient info by table column from a spreadsheet (in each column, separately). 
      You can edit individual cells in the next step, or clear a column and re-paste here
    </div>

    <div class="send-egift-panel-recipients-copy-paste__table">
      <div class="send-egift-panel-recipients-copy-paste__table-column">
        <div class="send-egift-panel-recipients-copy-paste__table-header">#</div>

        <div
          class="send-egift-panel-recipients-copy-paste__table-content"
          :class="{
            'send-egift-panel-recipients-copy-paste__table-content--filled': tableRecipients.length
          }"
        >
          <div
            class="send-egift-panel-recipients-copy-paste__table-content-item
              send-egift-panel-recipients-copy-paste__table-content-item--center"
            v-for="(recipient, index) in tableRecipients"
            :key="index"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>

      <div class="send-egift-panel-recipients-copy-paste__table-column">
        <div class="send-egift-panel-recipients-copy-paste__table-header">
          First name

          <div
            class="send-egift-panel-recipients-copy-paste__table-header-link"
            @click="clearRecipientColumnData('firstName', 'firstNames')"
            v-if="tableFirstNames"
          >
            Clear
          </div>
        </div>
        <div
          class="send-egift-panel-recipients-copy-paste__table-content"
          :class="{ 'send-egift-panel-recipients-copy-paste__table-content--filled': tableFirstNames }"
        >
          <copy-paste-table-input v-model="tableFirstNames" ref="firstNames" v-if="!tableFirstNames">
            Paste here list of first names
          </copy-paste-table-input>

          <div
            class="send-egift-panel-recipients-copy-paste__table-content-item"
            v-for="(recipient, index) in tableRecipients"
            :key="index"
            v-else
          >
            {{ recipient.firstName }}
          </div>
        </div>
      </div>

      <div class="send-egift-panel-recipients-copy-paste__table-column">
        <div class="send-egift-panel-recipients-copy-paste__table-header">
          Last name

          <div
            class="send-egift-panel-recipients-copy-paste__table-header-link"
            @click="clearRecipientColumnData('lastName', 'lastNames')"
            v-if="tableLastNames"
          >
            Clear
          </div>
        </div>
        <div
          class="send-egift-panel-recipients-copy-paste__table-content"
          :class="{ 'send-egift-panel-recipients-copy-paste__table-content--filled': tableLastNames }"
        >
          <copy-paste-table-input v-model="tableLastNames" ref="lastNames" v-if="!tableLastNames">
            Paste here list of last names
          </copy-paste-table-input>

          <div
            class="send-egift-panel-recipients-copy-paste__table-content-item"
            v-for="(recipient, index) in tableRecipients"
            :key="index"
            v-else
          >
            {{ recipient.lastName }}
          </div>
        </div>
      </div>

      <div class="send-egift-panel-recipients-copy-paste__table-column">
        <div class="send-egift-panel-recipients-copy-paste__table-header">
          Email
          <template v-if="isSelectedGiftLink">(Optional)</template>

          <div
            class="send-egift-panel-recipients-copy-paste__table-header-link"
            @click="clearRecipientColumnData('email', 'emails')"
            v-if="tableEmails"
          >
            Clear
          </div>
        </div>
        <div
          class="send-egift-panel-recipients-copy-paste__table-content"
          :class="{ 'send-egift-panel-recipients-copy-paste__table-content--filled': tableEmails }"
        >
          <copy-paste-table-input v-model="tableEmails" ref="emails" v-if="!tableEmails">
            Paste here list of Emails
          </copy-paste-table-input>

          <div
            class="send-egift-panel-recipients-copy-paste__table-content-item"
            v-for="(recipient, index) in tableRecipients"
            :key="index"
            v-else
          >
            {{ recipient.email }}
          </div>
        </div>
      </div>

      <div v-if="!isSelectedGiftLink" class="send-egift-panel-recipients-copy-paste__table-column">
        <div class="send-egift-panel-recipients-copy-paste__table-header">
          Send date (Optional)

          <div
            class="send-egift-panel-recipients-copy-paste__table-header-link"
            @click="clearRecipientColumnData('sendDate', 'sendDates')"
            v-if="tableSendDates"
          >
            Clear
          </div>
        </div>
        <div
          class="send-egift-panel-recipients-copy-paste__table-content"
          :class="{ 'send-egift-panel-recipients-copy-paste__table-content--filled': tableSendDates }"
        >
          <copy-paste-table-input v-model="tableSendDates" ref="sendDates" v-if="!tableSendDates">
            Paste here list of send dates (optional - if nothing entered send date will default to today)
          </copy-paste-table-input>

          <div
            class="send-egift-panel-recipients-copy-paste__table-content-item"
            v-for="(recipient, index) in tableRecipients"
            :key="index"
            v-else
          >
            <template v-if="recipient.sendDate">
              {{ recipient.sendDate | dateFilter }}
            </template>
          </div>
        </div>
      </div>

    </div>

    <div class="send-egift-panel-recipients-copy-paste__actions">
      <common-button
        :height="44"
        :width="200"
        :disabled="disableContinueButton"
        @click="handleContinueButton()"
      >
        Continue
      </common-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import type { IRecipient } from '../../types';

import panelTypeProp from '../mixins/panelTypeProp'
import recipientsProp from '../mixins/recipientsProp'
import takeCurrentDate from '../mixins/takeCurrentDate'
// TODO add types
// @ts-ignore
import cloneDeep from 'lodash/cloneDeep'

import CopyPasteTableInput from '../components/CopyPasteTableInput.vue'

interface ITableForm {
  firstNames: string | null;
  lastNames: string | null;
  emails: string | null;
  sendDates: string | null;
}

@Component({
  components: {
    CopyPasteTableInput,
  },
})
export default class SendEgiftPanelRecipientsCopyPaste extends Mixins(
  panelTypeProp,
  recipientsProp,
  takeCurrentDate
) {
  tableRecipients: Array<IRecipient> = []

  tableForm: ITableForm = {
    firstNames: null,
    lastNames: null,
    emails: null,
    sendDates: null,
  }

  get tableFirstNames(): string | null {
    return this.tableForm.firstNames
  }

  set tableFirstNames(val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'firstName')
    } else {
      this.fillRecipientColumnWith('firstName')
    }

    this.tableForm.firstNames = val
  }

  get tableLastNames(): string | null {
    return this.tableForm.lastNames
  }

  set tableLastNames(val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'lastName')
    } else {
      this.fillRecipientColumnWith('lastName')
    }

    this.tableForm.lastNames = val
  }

  get tableEmails(): string | null {
    return this.tableForm.emails
  }

  set tableEmails(val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'email')
    } else {
      this.fillRecipientColumnWith('email')
    }

    this.tableForm.emails = val
  }

  get tableSendDates () {
    return this.tableForm.sendDates
  }

  set tableSendDates (val: string | null) {
    if (!this.isValueASpecialChar(val as string)) {
      this.setRecipientsColumnData(val as string, 'sendDate')
    } else {
      this.fillRecipientColumnWith('sendDate')
    }

    this.tableForm.sendDates = val
  }

  get isFormFilled(): boolean {
    const { tableForm, isSelectedGiftLink } = this
    const helper: Partial<ITableForm> = { ...tableForm }
    if (isSelectedGiftLink) { delete helper.sendDates }

    return !Object.values(helper).every((value) => value === null)
  }

  get disableContinueButton(): boolean {
    const { tableForm, isSelectedGiftLink } = this
    const helper: Partial<ITableForm> = { ...tableForm }

    delete helper.sendDates
    if (isSelectedGiftLink) { delete helper.emails }

    return Object.values(helper).some((value) => value === null)
  }

  mounted() {
    const { recipients } = this
    if (recipients.length) {
      const recipientsWithData = recipients
        .filter((recipient) => recipient.firstName || recipient.lastName || recipient.email)

      this.tableRecipients = cloneDeep(recipientsWithData)

      this.setFormData()
    }
    this.setFocusedField()
  }

  setFormData(): void {
    this.setFormDataColumn('firstName', 'firstNames')
    this.setFormDataColumn('lastName', 'lastNames')
    this.setFormDataColumn('email', 'emails')
    // Since on the panel init it's adding one recipient with only the send date and because of this the send date column is filled but with empty data
    // and to fix this we should check if there are actually tableRecipients set (check mounted step)
    if (this.tableRecipients.length) { this.setFormDataColumn('sendDate', 'sendDates') }
  }

  setFormDataColumn(recipientKey: keyof IRecipient, formKey: keyof ITableForm): void {
    const value = this.recipients.reduce((acc, recipient) => {
      if (recipient[recipientKey]) { acc += `${recipient[recipientKey]}\n` }
      return acc
    }, '')
    
    if (value) { this.tableForm[formKey] = value }
  }

  setFocusedField(): void {
    const {
      firstNames,
      lastNames,
      emails,
      sendDates,
    } = this.tableForm

    switch (null) {
      case firstNames:
        this.focusTextField('firstNames')
        break;
      case lastNames:
        this.focusTextField('lastNames')
        break;
      case emails:
        this.focusTextField('emails')
        break;
      case sendDates:
        this.focusTextField('sendDates')
        break;
      default:
        break;
    }
  }

  focusTextField(fieldRefName: string): void {
    setTimeout(() => {
      // TODO
      // @ts-ignore
      this.$refs[fieldRefName]?.focusTextArea()
    }, 0)
  }

  isValueASpecialChar (value: string) {
    return value?.match(/^[^a-zA-Z0-9]+$/)
  }

  isDateValid ({ sendDate }: IRecipient) {
    if (!sendDate) { return false }
    return new Date(sendDate) >= new Date(this.takeCurrentDate())
  }

  setRecipientsColumnData (columnData: string, columnName: keyof IRecipient) {
    // split csv columns
    const columnArray = columnData.split('\n')
    // remove the last element when it is empty
    if (!columnArray[columnArray.length - 1]) { columnArray.pop() }

    columnArray.forEach((value, index) => {
      if (this.tableRecipients[index]) {
        const data = this.tableRecipients[index]
        data[columnName] = value
        this.$set(this.tableRecipients, index, data)
      } else {
        const recipientInfo = {}
        recipientInfo[columnName] = value
        this.$set(this.tableRecipients, index, recipientInfo)
      }
    })
    setTimeout(() => {
      this.setFocusedField()
    }, 100)
  }

  fillRecipientColumnWith (columnName: keyof IRecipient, fillWith: string | null = null) {
    this.tableRecipients.forEach((item, index) => {
      const data = this.tableRecipients[index]
      data[columnName] = fillWith
      this.$set(this.tableRecipients, index, data)
    })
  }

  clearRecipientColumnData (columnName: keyof IRecipient, formName: string) {
    this.fillRecipientColumnWith(columnName)
    this.tableForm[formName] = null
    if (!this.isFormFilled) { this.tableRecipients = [] }
    this.focusTextField(formName)
  }

  handleContinueButton(): void {
    const { tableRecipients, takeCurrentDate, isDateValid } = this

    this.$emit('update:recipients', tableRecipients.map((recipient) => ({
      ...recipient,
      country: 'US',
      sendDate: isDateValid(recipient)
        ? new Date(recipient.sendDate as string).toISOString()
        : takeCurrentDate()
    })))
    this.$emit('update:step', RootSteps.ReviewRecipients)
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-recipients-copy-paste {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__subtext {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }

  &__table {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    flex: 1 1;
    border-top: 1px solid #D3D2D2;
  }
    
  &__table-column {
    display: flex;
    flex: 0 1 200px;
    flex-direction: column;

    &:nth-child(1) {
      flex: 0 1 36px;
    }

    &:nth-child(4) {
      flex: 1 1 300px;
    }

    &:nth-child(n + 2) {
      & > .send-egift-panel-recipients-copy-paste__table-content {
        border-left: none;
      }
    }
  }

  &__table-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 11px;
    line-height: 13px;
    color: #000;
    text-transform: uppercase;
    padding: 12px 14px;
  }

  &__table-header-link {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: inherit;
    color: #42B77A;
    text-transform: capitalize;
    cursor: pointer;
  }

  &__table-content {
    background-color: #FAFAFA;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    border: 1px dashed #D3D2D2;

    &--filled {
      border: none;
      border-top: 1px solid #D3D2D2;
    }
  }

  &__table-content-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 0 1 40px;
    padding: 0 14px;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    min-height: 40px;
    height: 40px;
    line-height: 17px;
    color: #000;
    border-bottom: 1px solid #D3D2D2;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      line-height: inherit;
      font-size: inherit;
    }

    &:nth-child(odd) {
      background-color: #FFFFFF;
    }

    &:nth-child(even) {
      background-color: #FAFAFA;
    }

    &--center {
      justify-content: center;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}
</style>
