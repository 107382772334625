<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 17"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="E-Gift-Orders"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.5"
    >
      <g
        id="My-CG-Order"
        transform="translate(-1317.000000, -510.000000)"
        fill="#000000"
        fill-rule="nonzero"
      >
        <g id="Group" transform="translate(1316.887869, 496.000000)">
          <g
            id="noun_download-files_1820519"
            transform="translate(1.000000, 3.000000)"
          >
            <path
              d="M16.387,27.362 L5.361,27.362 C2.405,27.362 0,24.957 0,22 C0,21.448 0.447,21 1,21
                C1.553,21 2,21.448 2,22 C2,23.854 3.508,25.362 5.361,25.362 L16.386,25.362
                C18.24,25.362 19.747,23.854 19.747,22 C19.747,21.448 20.194,21 20.747,21 C21.3,21
                21.7470003,21.448 21.7470003,22 C21.748,24.957 19.343,27.362 16.387,27.362 Z
              "
              id="Path"
            />
            <rect id="Rectangle" x="9.813" y="11" width="2" height="10" />
            <path
              d="M10.813,22.665 C10.564,22.665 10.316,22.573 10.123,22.389 L6.313,18.754
                C5.914,18.373 5.899,17.74 6.28,17.34 C6.661,16.941 7.295,16.925 7.694,17.307
                L10.819,20.289 L14.007,17.301 C14.409,16.925 15.042,16.944 15.42,17.346
                C15.798,17.748 15.777,18.382 15.374,18.76 L11.497,22.395 C11.305,22.575
                11.06,22.665 10.813,22.665 Z
              "
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DownloadCsv',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    }
  }
}
</script>
