import { render, staticRenderFns } from "./UnitsOnHoldPanel.vue?vue&type=template&id=bb77b322&scoped=true"
import script from "./UnitsOnHoldPanel.vue?vue&type=script&lang=js"
export * from "./UnitsOnHoldPanel.vue?vue&type=script&lang=js"
import style0 from "./UnitsOnHoldPanel.vue?vue&type=style&index=0&id=bb77b322&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb77b322",
  null
  
)

export default component.exports