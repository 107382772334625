<template>
  <div class="d-flex">
    <input
      v-model="modelValue"
      :disabled="disabled"
      :id="id"
      type="checkbox"
      class="switchbox_wrapper"
    />
    <label class="switchbox" :for="id" />
  </div>
</template>

<script>
export default {
  name: 'CommonSwitch',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.switchbox_wrapper {
  display: none;
  & + .switchbox {
    background-color: #f5f5f5;
    border: 2px solid #e4e4e4;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    overflow: hidden;
    position: relative;
    width: 76px;
    &:after {
      // border: 2px solid #42B77A;
      background-color: #d6d6d6;
      border-radius: 16px;
      content: '';
      display: block;
      height: 32px;
      position: absolute;
      top: 2px;
      right: 50%;
      transition: all .1s linear;
      width: 32px;
    }
  }
  &:checked {
    & + .switchbox {
      border-color: #42B77A;

      &:after {
        right: 2px;
        background-image: url('@/assets/temp-images/ok_fill__popup.png');
        background-size: cover;
      }
    }
  }
}
</style>
