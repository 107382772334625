<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :value="selectedContacts"
    @input="(val) => selectedContacts = val"
    item-key="entity_id"
    class="contact-table"
    :items-per-page="9999"
    show-select
    hide-default-footer
    hide-default-header
    no-data-text="You have no addresses matching your criteria"
  >
    <template #item.contact_info="{ item }">
      <div
        class="d-flex align-center"
        v-if="isAddressAnGroup(item)"
      >
        <v-img
          width="16"
          max-width="16"
          height="16"
          max-height="16"
          src="/images/group_icon.svg"
        />
        <div class="d-flex flex-column ml-3 f15 gray2">
          <span class="black1 lato-bold">
            {{ item.name }}
          </span>
          <span>
            {{ item.addresses.length }} Contacts
          </span>
        </div>
      </div>

      <div class="ml-7" v-else>
        <contact-info-card :contact-info="item" />
      </div>
    </template>

    <template #item.controls="{ item }">
      <div class="d-flex justify-end" style="gap: 24px">
        <span
          class="f14 green1 pointer"
          @click="addToGroup(item)"
          v-if="!isAddressAnGroup(item) && !hideAddToGroupButton"
        >
          + {{
            item.groups.length > 1
              ? `${item.groups.length} groups`
              : item.groups.length === 1
              ? '1 group'
              : 'Add to group'
          }}
        </span>
        |
        <div class="d-flex align-center pointer">
          <v-img
            src="/images/pen-icon.svg"
            alt="Edit icon"
            @click="onEditIcon(item)"
            max-width="13px"
            width="13px"
            height="13px"
            max-height="13px"
          />
        </div>
        <div class="d-flex align-center pointer">
          <v-img
            src="/images/delete-icon.svg"
            alt="Delete icon"
            @click="onDeleteIcon(item)"
            width="17px"
            max-width="13px"
            height="17px"
            max-height="17px"
          />
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import ContactInfoCard from './ContactInfoCard.vue'

import { isAddressAnGroup } from './mixins'

export default {
  name: 'ContactsTable',
  components: {
    ContactInfoCard
  },
  mixins: [
    isAddressAnGroup
  ],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => ([])
    },
    selected: {
      type: Array,
      required: false,
      default: () => ([])
    },
    hideAddToGroupButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Contact info',
        align: 'start',
        value: 'contact_info',
        sortable: false
      },
      {
        text: 'Controls',
        align: 'end',
        value: 'controls',
        sortable: false
      }
    ]
  }),
  computed: {
    selectedContacts: {
      get () {
        return this.selected
      },
      set (val) {
        this.$emit('update:selected', val)
      }
    }
  },
  methods: {
    addToGroup (contact) {
      this.$emit('showAddToGroupPanel', contact)
    },
    onEditIcon (item) {
      if (!this.isAddressAnGroup(item)) {
        this.$emit('showEditPanel', item)
      } else {
        this.$emit('showEditGroupPanel', item)
      }
    },
    onDeleteIcon (item) {
      this.$emit('showDeletePanel', item)
    }
  }
}
</script>

<style lang="scss">
.contact-table {
  tbody tr {
    cursor: default;

    td {
      min-height: 70px !important;
      height: 70px !important;
      padding: 16px !important;

      & > div {
        height: 70px !important;
        min-height: 70px !important;
        display: flex;
        align-items: center;
      }

      &:first-child {
        text-align: center !important;
        align-items: center !important;
        min-width: 8%;
        width: 8%;

        .v-input--selection-controls__input {
          margin-left: 10px;
        }
      }

      &:nth-child(2) {
        min-width: 62%;
        width: 62%;
      }

      &:nth-child(3) {
        min-width: 30%;
        width: 30%;
      }
    }
  }
}
</style>
