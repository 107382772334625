<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M5.41203 10.2998H15.8666L11.2992 5.73233C10.9341 5.36731 10.9341 4.7683 11.2992
        4.40328C11.6642 4.03826 12.2538 4.03826 12.6189 4.40328L18.7868 10.5712C19.1518 10.9362
        19.1518 11.5259 18.7868 11.8909L12.6189 18.0588C12.2538 18.4238 11.6642 18.4238 11.2992
        18.0588C10.9341 17.6938 10.9341 17.1041 11.2992 16.7391L15.8666 12.1717H5.41203C4.89726
        12.1717 4.47608 11.7505 4.47608 11.2357C4.47608 10.721 4.89726 10.2998 5.41203 10.2998Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'CollectionArrowRight',
  props: {
    width: {
      type: Number,
      required: false,
      default: 23
    },
    height: {
      type: Number,
      required: false,
      default: 23
    },
    color: {
      type: String,
      required: false,
      default: '#000',
    },
  },
}
</script>
