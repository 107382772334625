<template>
  <div class="upload-logo__wrapper">
    <input
      type="file"
      name="upload-image"
      id="upload-image"
      @change="onChange"
      multiple
      ref="file"
      accept=".jpg, .jpeg, .png, .webp"
    >

    <label
      for="upload-image"
      class="upload-logo__content"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <common-loader v-if="loadingLogo" />

      <logo-placeholder-icon v-else />
    </label>

    <div class="upload-logo__name">
      Upload logo
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import type { ISavedLogo } from '../../types'

import Api from '@/axios/api'

import LogoPlaceholderIcon from '@/components/myCampaign/panels/sendEgiftPanel/icons/TheLogoPlaceholderIcon.vue'

@Component({
  components: {
    LogoPlaceholderIcon
  },
})
export default class UploadLogo extends Vue {
    loadingLogo = false;

    onChange (): void {
      const files = (this.$refs.file as HTMLInputElement).files

      Array.from(files || []).forEach((file) => {
        this.uploadFile(file)
      })
    }

    dragover (event: DragEvent) {
      event.preventDefault()

      if (event.currentTarget) {
        const currentTarget = event.currentTarget as HTMLElement

        if (!currentTarget.classList.contains('upload-logo__content--file-hover')) {
          currentTarget.classList.add('upload-logo__content--file-hover')
        }
      }
    }

    dragleave (event: DragEvent) {
      if (event.currentTarget) {
        const currentTarget = event.currentTarget as HTMLElement
        currentTarget.classList.remove('upload-logo__content--file-hover')
      }
    }

    drop (event: DragEvent): void {
      event.preventDefault();

      if (event?.dataTransfer?.files) {
        const currentTarget = event.currentTarget as HTMLElement

        (this.$refs.file as HTMLInputElement).files = event?.dataTransfer?.files
        this.onChange()
        currentTarget.classList.remove('upload-logo__content--file-hover')
      }
    }

    uploadFile (file: File) {
      const formData = new FormData()
      formData.append('file', file, file?.name)
      this.loadingLogo = true

      Api.post('/customer/logos', formData)
        .then(({ success, data }: { success?: boolean; data: ISavedLogo }) => {
          if (success) {
            this.$emit('addNewFile', data)
            this.$store.dispatch('snackbar/showSnackbar',
              { message: 'Logo uploaded successfully', type: 'success' }
            )
          }
        })
        .catch((e) => {
          if (e?.response?.data) {
            const { file } = e?.response?.data?.errors
            const errorMessage = file?.join(' ') || 'The logo failed to upload.'

            this.$store.dispatch('snackbar/showSnackbar',
              { message: errorMessage, type: 'error' }
            )
          } else {
            this.$store.dispatch('snackbar/showSnackbar',
              { message: 'The logo failed to upload.', type: 'error' }
            )
          }
        })
        .finally(() => (this.loadingLogo = false))
    }
}
</script>

<style lang="scss" scoped>
.upload-logo {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 200px;
    max-width: 200px;
    min-height: 240px;
    max-height: 240px;

    & > input {
      width: 1px;
      height: 1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
    }
  }


  &__content {
    min-width: 200px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1.4px dashed #95979D;
    background-color: #FAFAFA;
    transition: box-shadow ease-in-out 0.3s;

    &:has(.common-loader) {
      pointer-events: none;
      cursor: default;
      border-color: #fff;
    }

    &:hover,
    &--file-hover {
      border-style: solid;
      border-width: 3px;
      border-color: #A7DAD8;
      box-shadow: 0px 0px 26.0811px rgba(0, 0, 0, 0.15);
    }
  }

  &__name {
    color: #62646A;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 25px;
    text-align: center;
  }
}
</style>
