import Api from '@/axios/api'
import updateCartAmount from '@/mixins/updateCartAmount'
import genInventoryProductUrl from '../../utils/genInventoryProductUrl'

export default {
  mixins: [updateCartAmount],
  data: () => ({
    addingToCart: false,
  }),
  methods: {
    isItemAKit (item) {
      return item?.is_kit ?? false
    },
    itemHasVariants (item) {
      const variants = item?.product?.variations?.short || []

      return variants.length > 1
    },
    async addItemToCart (item) {
      const { product_id, cart_product_id, product_url, name } = item?.product ?? {}

      const hasVariants = this.itemHasVariants(item)

      if (hasVariants) {
        window.location.href = genInventoryProductUrl(product_url)
        return Promise.resolve('Item with variants')
      }

      this.addingToCart = true

      return Api.post(`/cart/inventory/item/${cart_product_id || product_id}`, {
        product_id: cart_product_id || product_id,
        qty: 1,
        redirect_url: product_url ?? '-'
      })
        .then(() => {
          this.updateCartAmount()
          this.$cgToast.successBold(`Added ${name} to cart`)

          window.open(`${process.env.VUE_APP_MAGENTO_URL}/cart/`, '_blank')
        })
        .catch(({ response: { data: message } }) => {
          if (message?.includes('update quantity to add to cart')) {
            this.$cgToast.error(message)
            window.open(`${process.env.VUE_APP_MAGENTO_URL}/cart/`, '_blank')
          } else {
            this.$cgToast.error(`Can't add ${name} to cart`)
            window.open(genInventoryProductUrl(product_url), '_blank')
          }
        })
        .finally(() => (this.addingToCart = false))
    },
    sendItemAsEGift (item) {
      const productId = item?.product?.product_id ?? undefined
      const link = `${process.env.VUE_APP_MAGENTO_URL}/inventory-product/${productId}/send/?budget_option=inventory`

      window.open(link, '_blank')
    },
  },
}
