var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('common-loader'):_c('v-row',{staticClass:"h100",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column justify-start",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center g10"},[_c('span',{staticClass:"credits-info__summary--title"},[_vm._v(" Available Credit ")]),_c('popper',{attrs:{"options":{
          placement: 'top',
          modifiers: { offset: { offset: '0,10px' } }
        }}},[_c('div',{staticClass:"popper px-4 py-2 text-left"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"lato-bold",attrs:{"cols":"12"}},[_vm._v(" Available Credits ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" This is your total amount of available CG Credits across "),_c('b',[_vm._v("all")]),_vm._v(" budget entities ")])],1)],1),_c('icons-question',{attrs:{"slot":"reference","width":24,"height":24,"color":"#62646A"},slot:"reference"})],1)],1),_c('span',{staticClass:"credits-info__summary--value"},[_vm._v(" "+_vm._s(_vm._f("priceFilter")(_vm.summary.balance,{ style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 4 }))+" ")]),(_vm.userHasSubscriptionPayment)?_c('div',{staticClass:"credits-info__subscription-payment"},[_vm._v(" Next subscription payment is scheduled for 3/5/2020 for $2500 ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column justify-end",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center g10"},[_c('span',{staticClass:"credits-info__summary--title"},[_vm._v(" On hold ")]),_c('popper',{ref:"popper",attrs:{"options":{
          placement: 'top',
          modifiers: {
            offset: { offset: '0,10px' },
            preventOverflow: {
              padding: {
                left: _vm.getSidebarWidth(),
                altAxis: true,
              },
            },
            rootBoundary: 'main',
          }
        }},on:{"show":_vm.onPopperShow}},[_c('div',{staticClass:"popper px-4 py-2 text-left",staticStyle:{"min-width":"440px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"lato-bold",attrs:{"cols":"12"}},[_vm._v(" On Hold ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" This is the balance of CG Credits used to purchase E-Gifts which are currently unredeemed."),_c('br'),_vm._v(" Once an E-Gift is redeemed, its cost is deducted from your On Hold balance."),_c('br'),_vm._v(" If you close an E-Gift order with unredeemed E-Gifts, their total cost will be converted back to Available Credit in the General budget entity. ")])],1)],1),_c('icons-question',{attrs:{"slot":"reference","width":24,"height":24,"color":"#62646A"},slot:"reference"})],1)],1),_c('span',{staticClass:"credits-info__summary--value"},[_vm._v(" "+_vm._s(_vm._f("priceFilter")(_vm.summary.on_hold,{ style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 4 }))+" ")]),(_vm.showSalesManagerInfo)?_c('div',{staticClass:"credits-info__csm-contact"},[_c('a',{attrs:{"href":`mailto:${_vm.userSalesManagerEmail}`}},[_vm._v("Contact CSM")]),_vm._v(" to reset balance ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }