<template>
  <div v-if="!authorizeUrl" class="campaign-automation-filters__wrapper">
    <cg-loader v-if="loading" />

    <template v-else>
      <div v-if="automationFilters.length" class="campaign-automation-filters__title">
        Select filter/s:
      </div>

      <div
        v-for="automationFilter in automationFilters"
        :key="automationFilter.code"
        class="campaign-automation-filters__item"
      >
        <label :for="automationFilter.code">{{ automationFilter.name }}:</label>

        <select-multiple-entities
          :id="automationFilter.code"
          v-model="filtersValues[automationFilter.code]"
          :items="automationFilter.values"
          placeholder="All"
          disableAttach
          :badgeSuffix="filtersValues[automationFilter.code].length > 1 ? 'options' : 'option'"
          item-text="name"
          item-value="id"
          :height="40"
          style="max-width: 220px; min-width: 220px"
          badge
        />
      </div>
    </template>
  </div>

  <div v-else class="campaign-automation-rippling-hr">
    <span>
      You must complete integration with Rippling HR system in order to automate campaigns:
    </span>

    <cg-button :href="authorizeUrl" target="_blank">Finalize integration</cg-button>

    <cg-alert type="information">
      Note that it may take <b>up to 2 hours</b> after completing your integration before you can automate your campaigns via Rippling
    </cg-alert>
  </div>
</template>

<script>
import Api from '@/axios/api'

import { CgLoader, CgAlert, CgButton } from '@corporategift/design-system'
import SelectMultipleEntities from '@/components/mySubAccounts/SelectMultipleEntities.vue'

export default {
  name: 'CampaignAutomationFilters',
  components: {
    CgAlert,
    CgLoader,
    CgButton,
    SelectMultipleEntities,
  },
  props: {
    automationId: {
      type: Number,
      required: false,
      default: null,
    },
    settingsFilters: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    campaignId: {
      type: Number,
      required: false,
      default: null,
    }
  },
  data: () => ({
    lazyId: null,
    loading: false,
    automationFilters: [],
    automationMeta: null,
  }),
  computed: {
    filtersValues: {
      get () { return this.settingsFilters },
      set (val) { this.$emit('update:settingsFilters', val) },
    },
    authorizeUrl () {
      return this.automationMeta?.authorize_url || undefined
    },
  },
  watch: {
    automationId: {
      handler: function (newId) {
        if (newId && newId !== this.lazyId) {
          this.fetchAutomationFilters(newId)
        }
      },
      immediate: true,
    },
    authorizeUrl: {
      handler: function (val) {
        this.$emit('requireAuthorize', !!val)
      },
      immediate: true,
    },
  },
  methods: {
    fetchAutomationFilters (id) {
      this.loading = true
      this.lazyId = id

      Api.get(`/campaigns/automation/sources/${id}/filters?automationable_id=${this.campaignId}`)
        .then(({ data, meta }) => {
          this.automationFilters = data
          this.automationMeta = meta

          if (meta?.authorize_url) { this.$emit('requireAuthorize', true) }

          data?.forEach(({ code }) => {
            if (!Object.prototype.hasOwnProperty.call(this.filtersValues, code)) {
              this.$set(this.filtersValues, code, [])
            }
          });
        })
        .catch((errorMessage) => {
          this.$cgToast.error(errorMessage)
        })
        .finally(() => (this.loading = false))
    }
  },
}
</script>

<style lang="scss" scoped>
.campaign-automation-filters {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &::v-deep > .cg-loader {
      max-width: 400px
    }
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000;
  }

  &__item {
    gap: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 400px;
    align-items: center;
    justify-content: space-between;

    & > label {
      // font-family: 'Lato-Bold', sans-serif;
      // font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000;
    }
  }
}

.campaign-automation-rippling-hr {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > span {
    font-size: 14px;
    font-family: 'Lato-Light', sans-serif;
    color: #333;
  }

  &::v-deep > button,
  &::v-deep > a {
    border-radius: 100px;
    width: fit-content;
  }

  &::v-deep > .cg-alert > .cg-alert__content {
    font-weight: 400;

    & > b {
      font-family: 'Lato-Bold', sans-serif;
    }
  }
}
</style>
