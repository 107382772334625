<template>
  <v-row no-gutters class="pr-10 kit-card">
    <v-col cols="6" class="relative">
      <tray>
        <tray-badge color="green" outlined v-if="product.stock.qty_in_kit > 1">
          x{{ product.stock.qty_in_kit }}
        </tray-badge>
      </tray>
      <product-image
        :altText="product.name"
        :image="product.image"
        :centerHorizontal="true"
        width="215"
        height="215"
      />
    </v-col>
    <v-col cols="6">
      <v-row no-gutters>
        <v-col cols="12" class="pb-3">
          <v-row no-gutters>
            <v-col v-if="superAdmin || masterAccount" class="d-flex align-center">
              <div
                class="kit-card__order-refill-button"
                @click="$emit('onAutoRefill', product.product_id)"
                @keypress="$emit('onAutoRefill', product.product_id)"
                tabindex="0"
              >
                <icons-auto-refill-alt :width="15" :height="13" />

                Order refill
              </div>
            </v-col>
            <v-col>
              <div class="d-flex justify-end" style="gap: 4px">
                <tray-badge-inventory-qty
                  :qty="product.stock.qty"
                  :minQty="product.notify_for_qty_below.value"
                  :variants="variations"
                />

                <tray-variants
                  v-if="superAdmin || masterAccount"
                  :variants="variations"
                  showInProduction
                  :showVariantsTooltip="variations.length > 1"
                >
                  <tray-badge
                    color="yellow"
                    v-if="product.stock.qty_in_production > 0"
                    :class="variations.length > 1 && 'pointer'"
                  >
                    {{ product.stock.qty_in_production | maxZeroFilter }}
                  </tray-badge>
                </tray-variants>

                <tray-variants
                  v-if="superAdmin || masterAccount"
                  :variants="variations"
                  showOnHold
                  :showVariantsTooltip="variations.length > 1"
                >
                  <tray-badge
                    color="grey"
                    v-if="product.stock.qty_on_hold > 0"
                    :class="variations.length > 1 && 'pointer'"
                  >
                    {{ product.stock.qty_on_hold | maxZeroFilter }}
                  </tray-badge>
                </tray-variants>

                <tray-badge
                  v-if="!superAdmin && !masterAccount && product.balance !== null"
                  color="red"
                  outlined
                  style="cursor: default"
                >
                  {{ product.balance | maxZeroFilter }}
                </tray-badge>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <a class="kit-card__product-name" :href="product.product_url" target="_blank">
            {{ product.name }}
          </a>

          <div class="kit-card__product-variants" v-if="variations.length > 1">
            <update-status-sheet
              color="#D2F5F3"
              textColor="#004642"
              v-for="(variantOption, index) in Object.keys(productVariantsOptions)"
              :key="index"
            >
              {{ productVariantsOptions[variantOption].length }} {{ variantOption }} options
            </update-status-sheet>

            <popper
              :options="{
                placement: 'top',
                modifiers: {
                  offset: {
                    offset: '0,3px'
                  },
                }
              }"
            >
              <div class="popper pa-3 f14 text-left" style="max-width: 200px">
                Hover the numbers in the card to see specifications and breakdown
              </div>
              <icons-question
                width="20"
                height="20"
                color="#62646A"
                class="pointer"
                slot="reference"
              />
            </popper>
          </div>

          <span
            v-html="product.description"
            class="kit-card__product-description ma-0 pa-0"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="my-4">
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'

import ProductImage from '../../ProductImage'
import Tray from '../../InventoryCardTray/Tray'
import TrayBadge from '../../InventoryCardTray/TrayBadge'
import TrayVariants from '../../InventoryCardTray/TrayVariants'
import TrayBadgeInventoryQty from '../../InventoryCardTray/TrayBadgeInventoryQty'
import UpdateStatusSheet from '@/components/UpdateStatusSheet'
import Popper from 'vue-popperjs'

import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'
import maxZeroFilter from '@/mixins/maxZeroFilter'

export default {
  name: 'PanelCard',
  components: {
    TrayBadgeInventoryQty,
    UpdateStatusSheet,
    TrayVariants,
    ProductImage,
    TrayBadge,
    Popper,
    Tray
  },
  mixins: [
    superAdmin,
    masterAccount,
    maxZeroFilter,
  ],
  props: {
    product: {
      type: Object,
      required: true
    },
    variations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    productVariantsOptions () {
      const variants = [].concat(...this.variations.map((item) => item.variants))
      const uniqueVariants = uniqBy(variants, item => item.value)
      return groupBy(uniqueVariants, item => item.variant)
    }
  }
}
</script>

<style lang="scss" scoped>
.kit-card {
  &__product {
    &-name, &-description {
      font-size: 14px;
    }

    &-name {
      line-height: 18px;
      font-family: 'Lato-Bold';
      font-size: 15px;
      letter-spacing: 0;
      display: inline-block;
      color: #222325;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        color: #219358 !important;
      }
    }

    &-variants {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 0;

      span:first-of-type {
        display: flex;
      }
    }

    &-description {
      font-family: 'Lato-Regular', sans-serif;
      line-height: 20px;
      color: #919191;

      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__order-refill-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 28px;
    border: 1px solid #D3D2D2;
    background-color: #FFFFFF;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    color: #62646A;

    &:hover {
      background-color: #F4F4F4;
    }

    &:active {
      background-color: #EDFCFB;
    }
  }
}
.relative {
  position: relative;
}
</style>
