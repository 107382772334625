<template>
  <v-row dense class="flex-column password-validation-wrapper">
    <v-col cols="12">
      <v-row dense class="g-10">
        <v-col class="flex-shrink-1 flex-grow-0">
          <icons-check-circle
            height="20"
            width="20"
            :filled="passwordHasMinLength"
          />
        </v-col>
        <v-col class="flex-shrink-0 flex-grow-1">
          {{ minLength }} characters minimum
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense class="g-10">
        <v-col class="flex-shrink-1 flex-grow-0">
          <icons-check-circle
            height="20"
            width="20"
            :filled="passwordHasOneUppercaseChar"
          />
        </v-col>
        <v-col class="flex-shrink-0 flex-grow-1">
          One uppercase character
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense class="g-10">
        <v-col class="flex-shrink-1 flex-grow-0">
          <icons-check-circle
            height="20"
            width="20"
            :filled="passwordHasOneLowercaseChar"
          />
        </v-col>
        <v-col class="flex-shrink-0 flex-grow-1">
          One lowercase character
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense class="g-10">
        <v-col class="flex-shrink-1 flex-grow-0">
          <icons-check-circle
            height="20"
            width="20"
            :filled="passwordHasSpecialCharacter"
          />
        </v-col>
        <v-col class="flex-shrink-0 flex-grow-1">
          One special character
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense class="g-10">
        <v-col class="flex-shrink-1 flex-grow-0">
          <icons-check-circle
            height="20"
            width="20"
            :filled="passwordHasOneNumber"
          />
        </v-col>
        <v-col class="flex-shrink-0 flex-grow-1">
          One number
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AccountSettingsPasswordValidation',
  props: {
    password: {
      type: String,
      required: false,
      default: null
    },
    /**
      * ID of the element
      */
    attachTo: {
      type: String,
      required: false,
      default: null
    },
    minLength: {
      type: Number,
      required: false,
      default: 8
    }
  },
  computed: {
    passwordHasMinLength () {
      const { password, minLength } = this
      const length = password?.length || 0
      return length >= minLength
    },
    passwordHasOneUppercaseChar () {
      const { password } = this
      return /[A-Z]/.test(password || '')
    },
    passwordHasOneLowercaseChar () {
      const { password } = this
      return /[a-z]/.test(password || '')
    },
    passwordHasOneNumber () {
      const { password } = this
      return /[0-9]/.test(password || '')
    },
    passwordHasSpecialCharacter () {
      const { password } = this
      return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password || '')
    },
  },
  mounted () {
    const passwordValidation = document.querySelector('.password-validation-wrapper')
    const { width } = passwordValidation.getBoundingClientRect()
    passwordValidation.style.right = `-${width}px`
  }
}
</script>

<style lang="scss" scoped>
.password-validation-wrapper {
  position: absolute;
  color: #A1A1A1;
  font-family: 'Lato-Italic', sans-serif;
  font-size: 15px;
  padding-left: 18px;
  top: 0;
}
</style>
