export default {
  numberOfProductsInFavorites: state => {
    const { favorites } = state.source

    return favorites.reduce((acc, item) => acc += item.products_count, 0)
  },
  greetingMessage: state => {
    const { title, content, footer } = state.form.greetingMessage

    return [title, content, footer].join('\n\n')
  },
  numberOfSelectedProducts: state => {
    const { selectedProducts } = state.form

    return selectedProducts.length ?? 0
  },
  priceRangeOfSelectedProducts: state => {
    const { selectedProducts } = state.form
    const prices = selectedProducts
      .filter(({ isInventory }) => !isInventory)
      .map((product) => Math.round(product.price))

    if (selectedProducts.length && !prices.length) { return 'N/A' }
    if (!prices.length) { return null }

    const min = Math.min(...prices)
    const max = Math.max(...prices)

    return `$${min} - $${max}`
  },
}
