export default {
  methods: {
    filterContactBySearch (contacts, searchValue) {
      const convertContactToString = (contact) => {
        const contactArrayWithValues = Object.values(contact).reduce((contactAcc, contactValue) => {
          if (typeof contactValue === 'object' && contactValue !== null) {
            contactAcc.push(Object.values(contactValue).join(' '))
          } else {
            contactAcc.push(contactValue)
          }
          return contactAcc
        }, [])
        return contactArrayWithValues.join(' ').toLowerCase()
      }

      const convertGroupToString = (group) => group?.name?.toLowerCase() || ''

      return contacts?.filter((item) => {
        const result = (!!item?.addresses || false) ? convertGroupToString(item) : convertContactToString(item)
        const search = searchValue ? searchValue.toLowerCase() : ''
        return result.includes(search)
      }) || []
    }
  }
}
