<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 13"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g id="My-logos-&amp;-images" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="My-account_logos-&amp;-images_intersactions"
        transform="translate(-837.000000, -578.000000)"
        :fill="color"
      >
        <g id="Edit-name-Copy" transform="translate(700.000000, 574.000000)">
          <g id="Edit-icon" transform="translate(137.000000, 4.000000)">
            <path
              d="M1.14700326,9.28791191 L3.4712398,11.6124959 L0,12.78 L1.14700326,9.28791191
                L1.14700326,9.28791191 L1.14700326,9.28791191 L1.14700326,9.28791191 Z
                M2.08482871,8.44355628 L8.48525285,2.04313214 L10.716367,4.27389886 L4.31559543,
                10.674323 L2.08482871,8.44355628 L2.08482871,8.44355628 L2.08482871,8.44355628
                L2.08482871,8.44355628 Z M9.3817292,1.14665579 L10.3615987,0.135513866 C10.4661876,
                0.0451712887 10.5808532,0 10.7055954,0 C10.8306852,0 10.9453507,0.0451712887
                11.0499396,0.135513866 L12.6444861,1.73040783 C12.7348287,1.82769984 12.7803475,
                1.94410277 12.7803475,2.07961664 C12.7803475,2.21513051 12.7348287,2.3284062
                12.6444861,2.41840131 L11.6124959,3.37742251 L9.3817292,1.14665579 L9.3817292,
                1.14665579 Z
              "
              id="Fill-7"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Edit',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 13
    },
    height: {
      type: [Number, String],
      required: false,
      default: 13
    },
    color: {
      type: String,
      required: false,
      default: '#9B9B9B',
    },
  }
}
</script>
