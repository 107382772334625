<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 20"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g id="My-Campaigns" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="My-Campaigns_view-gift-options"
        transform="translate(-481.000000, -154.000000)"
        fill="#919191"
        fill-rule="nonzero"
        stroke="#919191"
        stroke-width="0.5"
      >
        <g id="Group-2" transform="translate(481.407407, 154.000000)">
          <g id="multiple-icon" transform="translate(0.000000, 1.200000)">
            <path
              d="M12.6519477,1.93727388 L8.52550128,0.0158998815 C8.47162386,-0.00529996051
                8.41180726,-0.00529996051 8.35792985,0.0158998815 L4.23148342,1.93727388
                C4.15228964,1.96534441 4.10105131,2.04281676 4.10580485,2.12729988
                L4.10580485,7.80696588 L0.125983421,9.66499788 C0.0467896413,9.69306841
                -0.00444868546,9.77054076 0.000304849605,9.85502388 L0.000304849605,15.6824879
                C-0.00444868546,15.766971 0.0467896413,15.8444434 0.125983421,15.8725139
                L4.25242985,17.7938879 L4.44094771,17.7938879 L8.44171556,15.9358559
                L12.4424834,17.7938879 L12.6310013,17.7938879 L16.7574477,15.8725139
                C16.8366415,15.8444434 16.8878798,15.766971 16.8831263,15.6824879
                L16.8831263,9.85502388 C16.8878798,9.77054076 16.8366415,9.69306841
                16.7574477,9.66499788 L12.7776263,7.80696588 L12.7776263,2.12729988
                C12.7823798,2.04281676 12.7311415,1.96534441 12.6519477,1.93727388 Z
                M4.29432271,8.18701788 L7.91805485,9.87613788 L4.33621556,11.5230299
                L0.712483421,9.85502388 L4.29432271,8.18701788 Z M8.23225128,4.17535788
                L8.23225128,9.55942788 L4.52473342,7.82807988 L4.52473342,2.44400988
                L8.23225128,4.17535788 Z M8.65117985,4.17535788 L12.3586977,2.44400988
                L12.3586977,7.82807988 L8.65117985,9.55942788 L8.65117985,4.17535788 Z
                M4.54567985,11.9030819 L8.23225128,10.1928479 L8.23225128,15.5558039
                L4.54567985,17.2660379 L4.54567985,11.9030819 Z M8.65117985,10.1928479
                L12.3377513,11.9030819 L12.3377513,17.2660379 L8.65117985,15.5558039
                L8.65117985,10.1928479 Z M12.5472156,11.5230299 L8.96537628,9.87613788
                L12.5891084,8.18701788 L16.1709477,9.85502388 L12.5472156,11.5230299 Z
                M8.44171556,0.438179882 L12.0654477,2.12729988 L8.44171556,3.81641988
                L4.81798342,2.12729988 L8.44171556,0.438179882 Z M0.419233421,10.1717339
                L4.12675128,11.9030819 L4.12675128,17.2660379 L0.419233421,15.5558039
                L0.419233421,10.1717339 Z M12.7566798,17.2660379 L12.7566798,11.9030819
                L16.4641977,10.1717339 L16.4641977,15.5558039 L12.7566798,17.2660379 Z
              "
              id="Inventory-icon"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Edit',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 25
    },
    height: {
      type: [Number, String],
      required: false,
      default: 27
    }
  }
}
</script>
