import axios from 'axios'
import getCookie from '@/utils/getCookie'

const ApiM = axios.create({
  baseURL: process.env.VUE_APP_MAGENTO_URL,
  headers: {
    Accept: 'application/json'
  }
})

/**
 * Use request interceptor to fetch token.
 * Token is fetched asynchronously and attached to request as Authorization header.
 */
ApiM.interceptors.request.use(
  async config => {
    config.headers.Authorization = 'Bearer ' + getCookie('cgToken')
    return config
  },
  error => Promise.reject(error)
)

/**
 * Use response interceptor to catch 401 (Unauthorized) api response.
 * If caught, refresh the token and retry the request.
 */
ApiM.interceptors.response.use(
  response => response?.data || response,

  async error => {
    const { config: request, response: { status } } = error

    if (status === 401 && !request.retryRequest) {
      request.retryRequest = true

      window.location.href = `${process.env.VUE_APP_MAGENTO_URL}/login`
    }

    throw error
  }
)

export default ApiM
