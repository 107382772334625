<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 15"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M10.8536 5.39262C10.6822 5.64822 10.7475 5.9964 10.9993 6.1703L13.9175 8.18549C14.0385
        8.269 14.1871 8.30033 14.3308 8.27259C14.4745 8.24484 14.6015 8.1603 14.6837
        8.03755L16.6691 5.07556C16.8405 4.81995 16.7752 4.47177 16.5234 4.29787C16.2715 4.12397
        15.9285 4.19021 15.7572 4.44581L14.6488 6.0994C14.0095 2.68562 11.1435 0.164654 7.72345
        0.00772556C4.30339 -0.149203 1.22502 2.099 0.284847 5.44032C-0.655322 8.78163 0.788964
        12.3409 3.77483 14.0409C6.7607 15.7409 10.5061 15.1365 12.8252 12.5803C13.0295 12.3527
        13.0144 12.0003 12.7913 11.7916C12.5682 11.583 12.2209 11.5964 12.0141 11.8217C10.0207
        14.0205 6.79127 14.5218 4.24154 13.0281C1.69181 11.5343 0.504003 8.4453 1.38273
        5.5934C2.26146 2.7415 4.9716 0.889815 7.90549 1.13677C10.8394 1.38373 13.212 3.66324
        13.6159 6.62311L11.6198 5.24467C11.4988 5.16116 11.3502 5.12983 11.2065 5.15758C11.0628
        5.18532 10.9358 5.26987 10.8536 5.39262Z
      "
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'AutoRefill',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 17
    },
    height: {
      type: [Number, String],
      required: false,
      default: 15
    }
  }
}
</script>
