<template>
  <v-dialog
    v-model="show"
    persistent
    :content-class="mobileVersion ? 'elevation-0 rounded-0 dialog-bottom' : 'elevation-0 rounded-0'"
    :width="mobileVersion ? null : 550"
    :transition="mobileVersion ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-container :class="{ 'pa-0': mobileVersion }">
      <v-row :dense="!mobileVersion" :no-gutters="mobileVersion">
        <v-col :cols="!mobileVersion ? 11 : 12">
          <v-card class="px-4 py-5 pa-md-8" rounded="0" v-if="!loading">
            <v-card-title class="pa-0 pb-7 d-flex justify-end" v-if="mobileVersion">
              <v-icon
                color="black"
                x-large
                @click="show = false"
                class="pointer"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-title class="pa-0 pb-7">
              Are you sure you want to remove item from this collection?
            </v-card-title>

            <v-card-actions
              class="pa-0"
              :class="{
                'd-flex': mobileVersion,
                'g20': mobileVersion,
                'flex-column': mobileVersion,
              }"
            >
              <common-button
                outlined
                height="44"
                width="130"
                :class="{ 'ma-0': mobileVersion, 'order-2': mobileVersion }"
                class="favorites-button"
                :block="mobileVersion"
                @click="show = false"
              >
                Cancel
              </common-button>

              <common-button
                height="44"
                width="200"
                :class="{ 'ma-0': mobileVersion, 'order-1': mobileVersion }"
                class="favorites-button"
                :block="mobileVersion"
                @click="deleteProduct()"
              >
                Remove item
              </common-button>
            </v-card-actions>
          </v-card>

          <v-card class="pa-12" rounded="0" v-else>
            <common-loader />
          </v-card>
        </v-col>
        <v-col cols="1" class="text-center" v-if="!mobileVersion">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Api from '@/axios/api'

import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'TheMyFavoritesRemoveProductDialog',
  mixins: [panelVModel],
  props: {
    productId: {
      type: [Number, String],
      required: false,
      default: null
    },
    listId: {
      type: Number,
      required: false,
      default: null
    },
    mobileVersion: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    deleteProduct () {
      const { listId, productId } = this
      this.loading = true
      Api.patch(`/customer/wishlists/${listId}/products/${productId}/detach`)
        .then(() => {
          this.loading = false
          this.$emit('removeFromList', productId)
          this.show = false
          this.$cgToast.successBold('Successfully removed item from collection')
        })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/favorites-button.scss";

.v-card {
  &__title {
    font-family: 'Lato-Bold', sans-serif;
    color: #000 !important;
    font-size: 24px;
    word-break: break-word !important;
  }

  &__text {
    color: #000 !important;
    font-size: 15px;
    line-height: 22px;
    font-family: 'Lato-Regular', sans-serif;
  }
}
</style>

<style lang="scss">
.dialog-bottom {
  margin: 0;
  position: absolute;
  bottom: 0;
}
</style>
