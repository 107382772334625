<template>
  <popper
    ref="popper"
    @show="onPopperShow"
    :options="{
      placement: 'top',
      modifiers: {
        offset: {
          offset: '0,6px',
        },
        preventOverflow: {
          padding: {
            left: getSidebarWitdth(),
            altAxis: true,
          },
        },
        rootBoundary: 'main',
      }
    }"
  >
    <div
      class="popper pa-4 text-left"
      style="min-width: 261px; z-index: 5"
      :style="!productHasEmptyInventory && 'display: none'"
      v-if="variants.length <= 1"
    >
      <template v-if="productHasEmptyInventory">
        Out of stock. <br />
        We recommend refilling stock 2-4 weeks before estimated expiry to allow for production, customization and shipping.
      </template>
    </div>

    <tray-variants-tooltip v-else :variants="variants" />

    <tray-badge
      slot="reference"
      :class="{ 'pointer': variants.length > 1 }"
      :color="productHasEmptyInventory ? 'red' : 'green'"
    >
      {{ qty | maxZeroFilter }}
    </tray-badge>
  </popper>
</template>

<script>
import 'vue-popperjs/dist/vue-popper.css'
import maxZeroFilter from '@/mixins/maxZeroFilter'

import Popper from 'vue-popperjs'
import TrayBadge from './TrayBadge'
import TrayVariantsTooltip from './TrayVariantsTooltip'

export default {
  name: 'TrayBadgeInventoryQty',
  mixins: [maxZeroFilter],
  components: {
    Popper,
    TrayBadge,
    TrayVariantsTooltip
  },
  props: {
    qty: {
      type: Number,
      required: true
    },
    minQty: {
      type: Number,
      required: true
    },
    variants: {
      type: Array,
      required: true
    }
  },
  computed: {
    productHasEmptyInventory () {
      const { qty } = this
      return qty < 1
    }
  },
  methods: {
    onPopperShow () {
      this.$refs.popper.options.modifiers.preventOverflow.padding.left = this.getSidebarWitdth()
    },
    getSidebarWitdth () {
      return parseInt(document.querySelector('.common-sidebar').style.width) + 6 || 70
    }
  }
}
</script>
