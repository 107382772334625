<template>
  <v-img
    :alt="altText"
    :width="width"
    :height="height"
    contain
    class="relative"
    :class="centerHorizontal && 'mx-auto'"
    :src="getImagePath()"
  >
    <slot />
  </v-img>
</template>

<script>
export default {
  name: 'ProductImage',
  props: {
    altText: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    centerHorizontal: {
      type: Boolean,
      required: false,
      default: true
    },
    width: {
      type: [Number, String],
      required: false,
      default: 215
    },
    height: {
      type: [Number, String],
      required: false,
      default: 215
    }
  },
  methods: {
    getImagePath () {
      const { image } = this
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : image
    }
  }
}
</script>
