import Api from '@/axios/api'

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    items: []
  }),

  actions: {
    async load ({ state }) {
      state.loading = true

      return await Api.get('/customer/tokens')
        .then(response => {
          state.items = response.data
          state.loading = false
        })
    },
    async create ({ state, dispatch }, name) {
      return await Api.post('/customer/tokens', {
        name
      })
        .then(response => {
          dispatch('load')
          return response
        })
    },
    async destroy ({ state, dispatch }, token) {
      return await Api.delete('/customer/tokens/' + token.id)
        .then(response => {
          dispatch('load')
        })
    }
  }
}
