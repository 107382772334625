import userIsLoggedIn from '@/mixins/userIsLoggedIn'

export default {
  mixins: [userIsLoggedIn],
  methods: {
    onExitPage () {
      this.$store.commit('newCampaign/clearFormState')

      if (this.userIsLoggedIn || window.history.length === window.routerLength) {
        this.$router.push({ name: 'MyCampaignsPage' })
      } else {
        window.history.go(-window.routerLength)
      }
    },
  },
}