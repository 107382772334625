<template>
  <div class="footer-social-list">
    <a
      :href="url"
      v-for="({ id, title, url, icon }) in items"
      :key="id"
      :aria-label="title"
      target="_blank"
    >
      <img :src="icon.data.attributes.url" :alt="title">
    </a>
  </div>
</template>

<script>
export default {
  name: 'TheFooterSocialList',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    cfPath: 'https://cf.corporategift.com',
  }),
}
</script>

<style lang="scss" scoped>
.footer-social-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 8px;

  & > a {
    width: fit-content;

    & > img {
      width: 21px;
      height: 21px;
      object-fit: contain;
    }
  }

  @media (max-width: 639px) {
    & > a > img {
      width: 41px;
      height: 41px;
    }
  }
}
</style>
