<template>
  <v-popover
    :open.sync="showResults"
    placement="bottom-start"
    trigger="click"
    :disabled="!search"
    :container="false"
    @hide="hidePopper()"
  >
    <common-input
      v-model="search"
      placeholder="Product name/ SKU"
      prepend-inner-icon="mdi-magnify"
      :style="!disableStyles && 'min-width: 180px; max-width: 220px'"
      :height="height"
      filter-style
      @focus="inFocusMode = true"
      @blur="inFocusMode = false"
    />

    <div
      :style="`--min-width: ${minWidth}px; --max-width: ${maxWidth}px`"
      class="search-product-sku-tooltip"
      slot="popover"
    >
      <template v-if="!loading">
        <template v-if="products.length">
          <div
            class="search-product-sku-tooltip__product"
            :class="{
              'search-product-sku-tooltip__product--selected': isProductSelected(product)
            }"
            v-for="(product, index) in products"
            @click="handleProductClick(product)"
            :key="index"
          >
            <div>
              <v-img
                :width="60"
                :height="60"
                :max-width="60"
                :max-height="60"
                :alt="product.name"
                :src="getProductImage(product)"
              />
            </div>
            <div>
              <div>{{ product.name }}</div>
              <div>{{ product.sku }}</div>
            </div>
          </div>
        </template>
        <div class="search-product-sku-tooltip__empty" v-else>
          No products found
        </div>
      </template>

      <common-loader v-else />
    </div>
  </v-popover>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'

export default {
  name: 'SearchProductSkuInput',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    initialSearch: {
      type: String,
      required: false,
      default: null,
    },
    searchApprovable: {
      type: Boolean,
      required: false,
      default: false,
    },
    params: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disableStyles: {
      type: Boolean,
      required: false,
      default: false,
    },
    minWidth: {
      type: Number,
      required: false,
      default: 290,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 290,
    },
    height: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data: () => ({
    search: null,
    inFocusMode: false,
    loading: false,
    showResults: false,
    products: [],
  }),
  watch: {
    search: function (val) {
      if (val && val !== this.initialSearch) {
        this.loading = true
        this.loadProducts()
      }
    },
    initialSearch: {
      immediate: true,
      handler: function (val) {
        this.search = val
      }
    }
  },
  methods: {
    isProductSelected ({ id }) {
      return this.value === id
    },
    getProductImage ({ image_url: image }) {
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : image
    },
    hidePopper() {
      if (this.inFocusMode) setTimeout(() => { this.showResults = true; }, 0);
    },
    loadProducts: debounce(function () {
      const { search, params } = this

      Api.get(`/customer/orders/filter/product?search=${search}`, { params })
        .then(({ data }) => (this.products = data ?? []))
        .catch(() => (this.products = []))
        .finally(() => (this.loading = false))
    }, 600),
    handleProductClick ({ id, name, sku }) {
      const { search } = this
      this.showResults = false
      this.search = search.includes('-') ? sku : name
      this.$emit('update:initialSearch', this.search)
      this.$emit('input', id)
    }
  },
}
</script>

<style lang="scss" scoped>
.search-product-sku-tooltip {
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: #fff;
  max-height: 300px;
  min-width: var(--min-width);
  max-width: var(--max-width);
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  color: #16181F;
  overflow-y: auto;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:has(.search-product-sku-tooltip__product) {
    padding: 0;
  }

  &__product {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 4px 14px;
    cursor: pointer;

    &:hover {
      background-color: #F4F4F4;
    }

    &--selected {
      background-color: #EDFCFB;

      &:hover {
        background-color: #EDFCFB;
      }
    }

    & > div {
      &:first-child {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
      }

      &:last-child {
        font-family: 'Lato-Regular', sans-serif;
        color: #222325;
        display: flex;
        flex-direction: column;
        gap: 6px;

        & > div:first-child {
          font-size: 14px;
        }

        & > div:last-child {
          font-size: 13px;
          color: #95979D;
        }
      }
    }
  }
}

::v-deep .vue-popover-theme {
  z-index: 5 !important;
}
</style>