import DashboardCardPagination from '@/components/dashboard/common/DashboardCardPagination.vue'

export default {
  components: {
    DashboardCardPagination
  },
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    currentPage: 0,
    itemsPerPage: 4,
  }),
  computed: {
    itemsTotal () {
      return this.items?.length ?? 0
    },
    totalPages () {
      const { itemsTotal, itemsPerPage } = this

      return Math.ceil(itemsTotal / itemsPerPage) || 1
    },
    itemsToDisplay () {
      const { currentPage, items, itemsPerPage } = this

      const start = itemsPerPage * (currentPage)
      const end = itemsPerPage * (currentPage + 1)

      return items?.slice(start, end) ?? []
    },
  },
}
