<template>
  <div class="inventory-status-list-view-item__images">
    <product-image
      v-for="(imageInfo, index) in imagesToDisplay"
      :key="index"
      :width="imageSize"
      :height="imageSize"
      :altText="imageInfo.name"
      :image="imageInfo.image"
    />

    <div
      class="inventory-status-list-view-item__multiple-images"
      v-if="numberOfHiddenImages"
    >
      +{{ numberOfHiddenImages }}
    </div>
  </div>
</template>

<script>
import ProductImage from '@/components/myInventory/ProductImage.vue'

export default {
  name: 'TheListViewItemImages',
  components: {
    ProductImage,
  },
  props: {
    // Array of Objects with image and name
    images: {
      type: Array,
      required: true,
    },
  },
  computed: {
    numberOfImages () {
      return this.images.length ?? 0
    },
    numberOfHiddenImages () {
      const { numberOfImages } = this

      return numberOfImages > 4 ? numberOfImages - 3 : 0
    },
    imagesToDisplay () {
      const { images, numberOfImages, numberOfHiddenImages } = this

      return images.slice(0, numberOfImages - numberOfHiddenImages)
    },
    imageSize () {
      return this.numberOfImages > 1 ? 60 : 120
    },
  },
}
</script>

<style lang="scss" scoped>
.inventory-status-list-view-item {
  &__images {
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    height: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__multiple-images {
    height: 60px;
    max-height: 60px;
    width: 60px;
    max-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: #EDFCFB;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #62646A;
  }
}
</style>