<template>
  <div class="survey-monkey mt-3">
    <v-container>
      <span class="page-title">Survey Monkey</span>
      <span class="survey-monkey__settings-link-container">
        <v-img
          src="/images/integrations/settings-icon.svg"
          class="survey-monkey__settings-icon"
        />

        <a class="survey-monkey__settings-link" @click="setIsShowSettingsPanel(true)">
          Integration Settings
        </a>
      </span>
      <div class="survey-monkey__header">
        <v-img
          src="/images/integrations/survey-monkey-logo.svg"
          class="survey-monkey__logo"/>
        <div class="survey-monkey__desc">
          Automate reward fulfillment for survey respondents. Automatically send eGift cards, physical swag, or gifts as rewards and incentives.
          <a class="survey-monkey__details-link">More details</a>
        </div>
      </div>
      <div class="survey-monkey__campaigns">
        <div class="survey-monkey__campaigns-header">My Campaigns</div>
        <v-simple-table class="survey-monkey__campaigns-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Survey Id</th>
                <th class="text-left">Survey Name</th>
                <th class="text-left">Product Id</th>
                <th class="text-left">Product Image</th>
                <th class="text-left">Product Name</th>
                <th class="text-left">Product Price</th>
                <th class="text-left">Active From</th>
                <th class="text-left">Active To</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in campaigns" :key="item.id">
                <td>{{ index+1 }}.</td>
                <td>{{ item.survey_id }}</td>
                <td>{{ item.survey_name }}</td>
                <td>{{ item.product.id }}</td>
                <td>
                  <img class="survey-monkey__campaign-row-img" :src="getImageSrc(item.product.image)">
                </td>
                <td>{{ item.product.name }}</td>
                <td>{{ item.product.price }}</td>
                <td>{{ item.active_from }}</td>
                <td>{{ item.active_to }}</td>
                <td>
                  <common-button
                    @click="showCampaignFormPanel(item)"
                    style="width: 150px; font-size: 13px;"
                    :height="40"
                  >
                    Edit
                  </common-button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="survey-monkey__create-campaign">
          <common-button
            @click="showCampaignPanel()"
            style="width: 150px; font-size: 13px;"
            :height="40"
          >
            Create Campaign
          </common-button>
        </div>
      </div>
    </v-container>

    <survey-monkey-settings-panel
      v-if="isShowSettingsPanel"
      @close="setIsShowSettingsPanel(false)"
    />

    <survey-monkey-campaign-panel
      v-if="isShowCampaignPanel"
      @close="setIsShowCampaignPanel(false)"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import SurveyMonkeySettingsPanel from '@/components/integrations/surveyMonkey/SettingsPanel'
import SurveyMonkeyCampaignPanel from '@/components/integrations/surveyMonkey/CampaignPanel'

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('surveyMonkey')

export default {
  name: 'survey-monkey',
  components: {
    SurveyMonkeySettingsPanel,
    SurveyMonkeyCampaignPanel
  },
  computed: {
    ...mapState([
      'campaigns',
      'isShowSettingsPanel',
      'isShowCampaignPanel',
      'gifts'
    ])
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapMutations([
      'setIsShowSettingsPanel',
      'setIsShowCampaignPanel',
      'setIsCampaignFormEdit'
    ]),
    ...mapActions([
      'fetchCampaigns',
      'fetchGifts',
      'fetchSurveys',
      'filterAvailableSurveys',
      'updateCampaignForm',
      'clearCampaignForm',
      'fetchBudgetEntities'
    ]),
    async loadData () {
      await this.fetchGifts()
      await this.fetchSurveys()
      await this.fetchCampaigns()
      this.filterAvailableSurveys()
      await this.fetchBudgetEntities()
    },
    showCampaignPanel () {
      this.setIsCampaignFormEdit(false)
      this.clearCampaignForm()
      this.filterAvailableSurveys()
      this.setIsShowCampaignPanel(true)
    },
    showCampaignFormPanel (item) {
      this.setIsCampaignFormEdit(true)
      this.updateCampaignForm({
        surveyId: item.survey_id,
        surveyName: item.survey_name,
        surveyEmailQuestionId: item.survey_email_question_id,
        productId: item.product.id,
        activeFrom: item.active_from.substr(0, 10),
        activeTo: item.active_to.substr(0, 10),
        emailSubject: item.email_subject,
        giftMessage: item.gift_message,
        isActive: item.is_active,
        budgetEntityId: item.budget_entity_id
      })
      this.setIsShowCampaignPanel(true)
    },
    getImageSrc (itemImage) {
      return process.env.VUE_APP_MAGENTO_URL + '/media/catalog/product/' + itemImage
    }
  },
}
</script>

<style lang="scss" scoped>
@import './src/css/integrations/survey-monkey';
</style>
