<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M5.53197 1.99572L14.9591 5.97417L14.6068 6.73228L5.17966 2.75384L5.53197 1.99572Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 17.0351L10.0933 20.9477L20.1866 17.4097V4.38139L10.0933 0.0524902L0
        3.96515V17.0351ZM10.0933 20.032L0.877677 16.4523V4.50626L10.0933 0.926594L19.3089
        4.9225V16.8686L10.0933 20.032Z"
      fill="currentColor"
    />
    <path
      d="M0.593792 3.89452L10.1767 7.63025L9.84729 8.39765L0.264368 4.66193L0.593792 3.89452Z"
      fill="currentColor"
    />
    <path
      d="M10.1186 20.1441H9.2522V7.459L19.0856 4.0708L19.3455 4.81455L10.1186 8.03747V20.1441Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Order extends Vue {
  @Prop({ default: 21 }) readonly width!: number | string;

  @Prop({ default: 21 }) readonly height!: number | string;
}
</script>
