import Api from '@/axios/api'

export default {
  namespaced: true,

  state: () => ({
    customers: []
  }),
  mutations: {
    setCustomers (state, customers) {
      state.customers = customers
    }
  },
  actions: {
    async getCustomers ({ commit }) {
      await Api.get('/customer/accounts')
        .then(response => {
          commit('setCustomers', response.data)
        })
    }
  },
  getters: {
    customers: state => state.customers
  }
}
