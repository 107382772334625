<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="CG_credits-3.0-"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="My-CG-Credits-0.3_budget-entities"
        transform="translate(-1173.000000, -275.000000)"
        fill-rule="nonzero"
      >
        <g
          id="top-charts-+-title"
          transform="translate(165.000000, 200.000000)"
        >
          <g id="Path" transform="translate(1009.000000, 76.000000)">
            <path
              d="M9,0 C13.9831041,0 18,4.01691176 18,9 C18,13.9830882 13.9831041,18 9,18 C4.01689588,18 0,13.9830882 0,9 C0,4.01691176 4.01689588,0 9,0 Z"
              stroke="#979797"
            />
            <path
              d="M8.89495859,2.25620275 C8.40017524,2.31486346 8.02891584,2.7713908 8.03962135,3.30801714 L8.03962135,3.87436257 C6.63887933,4.1282852 5.63867474,5.46832401 5.63867474,6.93262793 C5.63867474,8.69765805 7.17240088,10.0394372 9,10.0394372 C10.0705768,10.0393854 10.440568,10.545174 10.440568,11.0750403 C10.440568,11.7433667 10.1986265,12.1106434 9.48018932,12.1106434 L6.59905338,12.1106434 C6.25270716,12.1053628 5.9305538,12.3015846 5.75595163,12.6241719 C5.58134946,12.9467592 5.58134946,13.3457337 5.75595163,13.668321 C5.9305538,13.9909083 6.25270716,14.1871301 6.59905338,14.1818495 L8.03962135,14.1818495 L8.03962135,14.6996511 C8.03472438,15.0731259 8.21669296,15.4205128 8.51584805,15.6087912 C8.81500314,15.7970696 9.18499686,15.7970696 9.48415195,15.6087912 C9.78330704,15.4205128 9.96527562,15.0731259 9.96037865,14.6996511 L9.96037865,14.1333056 C11.3603087,13.8786715 12.3613253,12.5388041 12.3613253,11.0750403 C12.3613253,9.31001015 10.8276347,7.96817924 9,7.96823102 C7.92938765,7.96823102 7.55943203,7.46249425 7.55943203,6.93262793 C7.55943203,6.26430148 7.8013735,5.89702485 8.51981068,5.89702485 L11.4009466,5.89702485 C11.7472928,5.90230539 12.0694462,5.70608359 12.2440484,5.38349631 C12.4186505,5.06090904 12.4186505,4.66193449 12.2440484,4.33934721 C12.0694462,4.01675993 11.7472928,3.82053814 11.4009466,3.82581868 L9.96037865,3.82581868 L9.96037865,3.30801714 C9.96633665,3.0097674 9.85274379,2.72323108 9.64869118,2.52179001 C9.44463857,2.32034895 9.1699447,2.22356801 8.89495859,2.25623275 L8.89495859,2.25623275 Z"
              fill="#919191"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AddFunds',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    }
  }
}
</script>
