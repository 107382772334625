<template>
  <v-row no-gutters class="flex-wrap">
    <template v-if="numberOfImages <= 1">
      <v-col cols="12" class="col--no-border">
        <v-img
          :src="getImagePath(imagesToShow[0])"
          aspect-ratio="1"
          contain
        />
      </v-col>
    </template>

    <template v-else>
      <v-col
        v-for="(image, index) in imagesToShow"
        :key="index"
        cols="6"
      >
        <v-img
          :src="getImagePath(imagesToShow[index])"
          aspect-ratio="1"
          contain
          class="v-image--with-padding"
        />
      </v-col>

      <v-col cols="6" v-if="numberOfImages === 2" />
      <v-col
        cols="6"
        v-if="numberOfImages === 2 || numberOfImages === 3"
      />

      <v-col cols="6" v-if="numberOfImages > 4">
        <span>
          +{{ numberOfImages - 3 }}
        </span>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'TheProductCardImages',
  props: {
    images: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  computed: {
    numberOfImages () {
      return this.images.length
    },
    imagesToShow () {
      const { images, numberOfImages } = this
      let slice = 4

      if (numberOfImages > 4) slice = 3
      return images?.slice(0, slice) || []
    }
  },
  methods: {
    getImagePath (image) {
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : image
    }
  }
}
</script>

<style lang="scss">
.v-image--with-padding {
  .v-image__image {
    background-origin: content-box;
    padding: 4px;
  }
}
</style>

<style lang="scss" scoped>
.row {
  border: 0.5px solid #E4E4E4;
  height: 164px;
  width: 164px;
  max-height: 164px;
  max-width: 164px;

  .col {
    border-bottom: 0.5px solid #E4E4E4;

    &:nth-child(odd) {
      border-right: 0.5px solid #E4E4E4;
    }

    &--no-border {
      border: none !important;
    }
  }

  &:has(.col--no-border) {
    border: none;
  }

  & > .col {
    background: #EDFCFB;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 81px;

    &:has(.v-image) {
      background: #fff;
    }

    & > span {
      color: #62646A;
      font-size: 20px;
      font-family: 'Lato-Regular', sans-serif;
    }

    & > .v-image {
      background: #fff;
    }
  }
}
</style>
