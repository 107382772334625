<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 18"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M12.818 6.59574L8.62266 10.8457L6.19409 8.36556C5.93254 8.09982 5.5051 8.09642 5.23936
        8.35797C4.97362 8.61951 4.97022 9.04696 5.23177 9.3127L8.15516 12.2816C8.20064 12.3214
        8.2518 12.3541 8.30695 12.3788C8.32723 12.3967 8.34966 12.412 8.37373 12.4243C8.45464
        12.4577 8.54121 12.4752 8.62873 12.4759C8.71629 12.4755 8.80292 12.458 8.88373
        12.4243C8.91285 12.4089 8.94031 12.3906 8.9657 12.3697C9.01512 12.3468 9.0611 12.3172
        9.10231 12.2816L13.7803 7.54592C13.964 7.37692 14.0398 7.12054 13.9776 6.87882C13.9153
        6.6371 13.725 6.44926 13.4826 6.39006C13.2401 6.33086 12.9847 6.40991 12.818 6.59574Z"
      :fill="color"
    />
    <rect
      x="1"
      y="0.5"
      width="17"
      height="17"
      rx="8.5"
      :stroke="color"
      stroke-miterlimit="0"
      stroke-linejoin="bevel"
    />
  </svg>
</template>

<script>
export default {
  name: 'UsersCheck',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 17
    },
    height: {
      type: [Number, String],
      required: false,
      default: 17
    },
    color: {
      type: String,
      required: false,
      default: '#42B77A'
    },
  },
}
</script>
