export default {
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
