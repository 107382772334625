<template>
  <v-navigation-drawer
    class="my-users-add-edit-panel"
    v-lock-scroll="show"
    v-model="show"
    width="650px"
    temporary
    right
    app
  >
    <div class="my-users-add-edit-panel__title">
      <div>
        {{ inEditMode ? 'Edit' : 'Add' }} user
      </div>

      <div class="my-users-add-edit-panel__title-icon" @click="show = false">
        <icons-close color="#62646A" :width="17" :height="17" />
      </div>
    </div>

    <v-form ref="form" class="my-users-add-edit-panel__content">
      <template v-if="!loading">
        <div v-if="!inEditMode" class="my-users-add-edit-panel__add-multiple-users">
          <icons-multiple-users :width="20" :height="20" />

          <div>
            Want to add multiple users? <span @click="onAddMultipleUsers">click here</span>
          </div>
        </div>

        <div class="my-users-add-edit-panel__divider" :class="{ 'mt-3': inEditMode }" />

        <div class="my-users-add-edit-panel__multiple-inputs">
          <div class="my-users-add-edit-panel__input-wrapper">
            <label for="firstname">First name</label>
            <common-input
              v-model="form.firstName"
              id="firstname"
              placeholder=""
              :height="40"
              :rules="[validation.required]"
            />
          </div>
          <div class="my-users-add-edit-panel__input-wrapper">
            <label for="lastname">Last name</label>
            <common-input
              v-model="form.lastName"
              id="lastname"
              placeholder=""
              :height="40"
              :rules="[validation.required]"
            />
          </div>
        </div>

        <div class="my-users-add-edit-panel__input-wrapper">
          <label for="email">Email</label>
          <common-input
            v-model="form.email"
            id="email"
            type="email"
            :rules="[validation.required, validation.email]"
            placeholder=""
            :height="40"
          />
        </div>

        <div class="my-users-add-edit-panel__divider" />

        <div class="my-users-add-edit-panel__subtitle">
          Permissions
        </div>

        <div class="my-users-add-edit-panel__input-wrapper">
          <label for="permission-level">
            Permission level

            <common-tooltip
              top
              :max-width="300"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              <span v-html="getTooltipTextBySlug('users_permission_level')" />
            </common-tooltip>
          </label>
          <common-select
            v-model="form.permissions.level"
            :items="options.permissionsLevels"
            item-text="value"
            item-value="key"
            :height="40"
            id="permission-level"
          />
        </div>

        <div class="my-users-add-edit-panel__input-wrapper">
          <label for="categories-access">Access to categories</label>
          <select-multiple-entities
            id="categories-access"
            v-model="form.permissions.categoriesAccess"
            :items="options.categoriesList"
            placeholder="Select categories"
            item-text="title"
            item-value="value"
            :height="40"
            clearable
            chips
          />
        </div>

        <div class="my-users-add-edit-panel__input-wrapper">
          <label for="campaigns-access">Access to campaigns</label>
          <select-multiple-entities
            id="campaigns-access"
            v-model="form.selectedCampaigns"
            :items="options.campaignsItems"
            placeholder="Select campaigns"
            item-text="name"
            item-value="id"
            :height="40"
            clearable
            chips
          />
        </div>

        <div class="my-users-add-edit-panel__input-wrapper">
          <label for="budget-entities">Access to budget entities</label>
          <select-multiple-entities
            id="budget-entities"
            v-model="form.selectedBudgetEntities"
            :items="options.budgetEntitiesList"
            placeholder="Select budget entities"
            badgeSuffix="budget entities"
            item-text="name"
            item-value="id"
            :height="40"
            badge
          />
        </div>

        <my-user-budget-limits-list
          v-if="form.budgetEntitiesLimits.length"
          :budgetLimits="form.budgetEntitiesLimits"
          :timeList="options.budgetEntitiesTimeFrames"
          @removeItem="onRemoveBudgetLimitItem"
          @updateItem="onUpdateBudgetLimitItem"
        />

        <template v-if="form.permissions.level !== 400">
          <div class="my-users-add-edit-panel__input-wrapper">
            <label for="inventory-items">Access to inventory items</label>
            <select-multiple-entities
              id="inventory-items"
              v-model="form.selectedInventoryItems"
              :items="options.inventoryItems"
              placeholder="Select inventory items"
              badgeSuffix="inventory items"
              item-text="name"
              item-value="id"
              :height="40"
              badge
            />
          </div>

          <my-user-inventory-limits-list
            v-if="form.inventoryItemsLimits.length"
            :inventoryLimits="form.inventoryItemsLimits"
            :periodOptions="options.inventoryPeriodOptions"
            @removeItem="onRemoveInventoryLimitItem"
            @updateItem="onUpdateInventoryLimitItem"
          />
        </template>

        <div class="my-users-add-edit-panel__input-wrapper">
          <common-checkbox
            v-model="form.userOrdersRequireApproval"
            class="my-users-add-edit-panel__checkbox"
            bold-text
          >
            User orders require approval

            <common-tooltip
              top
              :max-width="250"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              {{ getTooltipTextBySlug('users_order_approver') }}
            </common-tooltip>
          </common-checkbox>

          <v-expand-transition>
            <common-select
              v-if="form.userOrdersRequireApproval"
              v-model="form.permissions.orderApprover"
              :items="availableApprovers"
              :rules="[validation.approverRequired]"
              placeholder="Select approver"
              :height="40"
              item-text="name"
              item-value="approver_id"
              id="orders-approval"
            />
          </v-expand-transition>
        </div>

        <div class="my-users-add-edit-panel__divider" />

        <my-users-set-spend-limit
          class="my-users-add-edit-panel__set-spend-limit"
          :show.sync="form.setSpendLimit"
          :limit.sync="form.spendLimit.limit"
          :time.sync="form.spendLimit.time"
          :timeList="options.spendLimitTimeFrames"
        />

        <div class="my-users-add-edit-panel__divider" />
        
        <common-checkbox v-model="form.userCanViewAllOrders">
          User can view all orders
        </common-checkbox>

        <template v-if="!inEditMode">
          <div class="my-users-add-edit-panel__divider" />

          <common-checkbox v-model="form.sendWelcomeEmail">
            Send Welcome email to new user
          </common-checkbox>
        </template>

        <template v-if="inEditMode">
          <div
            v-if="!showAdvancedOptions"
            class="my-users-add-edit-panel__link"
            @click="showAdvancedOptions = true"
          >
            + Advanced option
          </div>

          <common-checkbox v-else v-model="form.isDeactivated">
            <div class="my-users-add-edit-panel__archive-user-info">
              <div>Archive user</div>
              <div>The user will no longer be able to sign in to Corporategift.com</div>
            </div>
          </common-checkbox>
        </template>

        <common-button :height="44" style="max-width: 174px" @click="submit()">
          {{ inEditMode ? 'Save changes' : 'Add new user' }}
        </common-button>
      </template>

      <common-loader
        v-else
        flex
        relative
        minHeightAuto
        style="padding: 100px 0"
      />
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import { createNamespacedHelpers } from 'vuex'

import MyUserInventoryLimitsList from '@/components/mySubAccounts/MyUserInventoryLimitsList.vue'
import SelectMultipleEntities from '@/components/mySubAccounts/SelectMultipleEntities.vue'
import MyUserBudgetLimitsList from '@/components/mySubAccounts/MyUserBudgetLimitsList.vue'
import MyUsersSetSpendLimit from '@/components/mySubAccounts/MyUsersSetSpendLimit.vue'

const { mapActions } = createNamespacedHelpers('users')

export default {
  name: 'MyUsersAddEditUserPanel',
  mixins: [
    panelVModel,
    getTooltipTextBySlug,
  ],
  components: {
    MyUsersSetSpendLimit,
    MyUserBudgetLimitsList,
    SelectMultipleEntities,
    MyUserInventoryLimitsList,
  },
  props: {
    selectedUserId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    form: {
      firstName: null,
      lastName: null,
      email: null,
      permissions: {
        level: 200,
        categoriesAccess: [],
        orderApprover: null,
      },
      setSpendLimit: false,
      spendLimit: {
        limit: null,
        time: null,
      },
      isDeactivated: false,
      selectedBudgetEntities: [],
      budgetEntitiesLimits: [],
      selectedInventoryItems: [],
      inventoryItemsLimits: [],
      selectedCampaigns: [],
      userCanViewAllOrders: false,
      userOrdersRequireApproval: false,
      sendWelcomeEmail: true,
    },
    options: {
      budgetEntitiesList: [],
      categoriesList: [],
      approversList: [],
      permissionsLevels: [],
      budgetEntitiesTimeFrames: [],
      spendLimitTimeFrames: [],
      inventoryItems: [],
      campaignsItems: [],
      inventoryPeriodOptions: [
        {
          text: 'Monthly',
          value: 'monthly',
        },
        {
          text: 'Annually',
          value: 'annually',
        }
      ], // TODO move to separate file
    },
    showAdvancedOptions: false,
    validation: {
      required: v => !!v || '',
      approverRequired: v => v !== null || '',
      email: v => /.+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || ''
    }
  }),
  computed: {
    inEditMode () {
      return !!this.selectedUserId ?? false
    },
    availableApprovers () {
      const { approversList } = this.options

      const result = [
        { approver_id: 0, name: 'All admins', },
      ]
      result.push(...approversList)

      return result ?? []
    },
    selectedBudgetEntitiesHelper () {
      return this.form.selectedBudgetEntities
    },
    selectedInventoryItemsHelper () {
      return this.form.selectedInventoryItems
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        Object.assign(this.$data, this.$options.data())
        this.getSelectOptions()
      }
    },
    selectedBudgetEntitiesHelper: {
      deep: true,
      handler: function (val) {
        const {
          form: { budgetEntitiesLimits: limits },
          options: { budgetEntitiesList: list },
        } = this

        let newLimitsData = [...limits]
        newLimitsData = newLimitsData.filter((item) => val.includes(item.id))

        const usedIds = newLimitsData.map((item) => item.id)

        val.forEach((id) => {
          if (!usedIds.includes(id)) {
            const budgetData = list.find((budget) => budget.id === id)

            newLimitsData.push({
              id,
              color: budgetData?.color,
              name: budgetData?.name,
              limit: null,
              time: null,
            })
          }
        })

        this.form.budgetEntitiesLimits = newLimitsData
      }
    },
    selectedInventoryItemsHelper: {
      deep: true,
      handler: function (val) {
        const {
          form: { inventoryItemsLimits: limits },
          options: { inventoryItems: list },
        } = this

        let newLimitsData = [...limits]
        newLimitsData = newLimitsData.filter((item) => val.includes(item.id))

        const usedIds = newLimitsData.map((item) => item.id)

        val.forEach((id) => {
          if (!usedIds.includes(id)) {
            const budgetData = list.find((budget) => budget.id === id)

            newLimitsData.push({
              id,
              name: budgetData?.name,
              limit: null,
              period: null,
            })
          }
        })

        this.form.inventoryItemsLimits = newLimitsData
      }
    }
  },
  methods: {
    ...mapActions(['getUsers']),
    prePopulateUserData (userData) {
      this.form = {
        firstName: userData?.first_name ?? null,
        lastName: userData?.last_name ?? null,
        email: userData?.email ?? null,
        permissions: {
          level: userData?.permission_level ?? 200,
          categoriesAccess: userData?.access_to ?? [],
          orderApprover: userData?.order_approver ?? null,
        },
        setSpendLimit: userData?.spend_limit_enabled ?? false,
        spendLimit: {
          limit: userData?.spend_limit?.amount ?? null,
          time: userData?.spend_limit?.time_frame ?? null,
        },
        selectedCampaigns: userData.campaigns?.map((campaign) => campaign.id) ?? [],
        isDeactivated: !userData?.is_active ?? false,
        selectedBudgetEntities: userData?.budget_entities?.map((item) => item.id) ?? [],
        budgetEntitiesLimits: userData?.budget_entities?.map((item) => ({
          id: item.id,
          color: null,
          name: null,
          limit: item.limit,
          time: item.time,
        })) ?? [],
        selectedInventoryItems: userData?.inventory_products?.map((item) => item.id) ?? [],
        inventoryItemsLimits: userData?.inventory_products?.map((item) => ({
          limit: null,
          period: null,
          ...item,
        })) ?? [],
        userCanViewAllOrders: userData?.can_view_all_orders ?? false,
        userOrdersRequireApproval: userData?.order_approver !== null ?? false,
      }

      this.showAdvancedOptions = !userData?.is_active
    },
    getSelectOptions () {
      this.loading = true
      const promises = []

      const promise1 = Api.get('/customer/subaccounts/additional-data')
        .then(({
          data: {
            access_to_categories,
            order_approvers,
            campaigns,
            permissions,
            budget_entities,
            inventory_products,
            spend_limit_time_frames,
            budget_entities_periods,
          },
        }) => {
          this.options.categoriesList = access_to_categories ?? []
          this.options.approversList = order_approvers ?? []
          this.options.permissionsLevels = permissions ?? []
          this.options.inventoryItems = inventory_products ?? []
          this.options.budgetEntitiesList = budget_entities ?? []
          this.options.spendLimitTimeFrames = spend_limit_time_frames ?? []
          this.options.budgetEntitiesTimeFrames = budget_entities_periods ?? []
          this.options.campaignsItems = campaigns ?? []
        })
        .catch((e) => (console.error(e)))

      promises.push(promise1)

      if (this.inEditMode) {
        const promise2 = Api.get(`/customer/subaccounts/${this.selectedUserId}`)
          .then(({ data }) => (this.prePopulateUserData(data)))
          .catch((e) => (console.error(e)))

        promises.push(promise2)
      }

      Promise.all(promises)
        .then(() => {
          if (this.options.budgetEntitiesList?.length) { this.setBudgetEntitiesLimitsColors() }
        })
        .finally(() => (this.loading = false))
    },
    setBudgetEntitiesLimitsColors () {
      const { options: { budgetEntitiesList }, form: { budgetEntitiesLimits } } = this

      const newLimitsData = budgetEntitiesLimits?.map((item) => {
        const budgetData = budgetEntitiesList?.find((budget) => budget.id === item.id) ?? null
        return ({
          ...item,
          color: budgetData?.color,
          name: budgetData?.name,
        })
      }) ?? []

      this.$set(this.form, 'budgetEntitiesLimits', newLimitsData)
    },
    onAddMultipleUsers () {
      this.show = false
      this.$emit('addMultipleUsers')
    },
    onRemoveBudgetLimitItem ({ id }) {
      const findIndex = this.selectedBudgetEntitiesHelper.findIndex((itemId) => itemId === id)

      this.form.selectedBudgetEntities.splice(findIndex, 1)
    },
    onUpdateBudgetLimitItem ({ id, limit, time }) {
      const { budgetEntitiesLimits: limits } = this.form

      const findIndex = limits.findIndex((item) => item.id === id)

      if (findIndex !== -1) {
        let newObject = Object.assign({}, limits[findIndex])
        newObject = {
          ...newObject,
          time,
          limit,
        }

        this.$set(this.form.budgetEntitiesLimits, findIndex, newObject)
      }
    },
    onRemoveInventoryLimitItem ({ id }) {
      const findIndex = this.selectedInventoryItemsHelper.findIndex((itemId) => itemId === id)

      this.form.selectedInventoryItems.splice(findIndex, 1)
    },
    onUpdateInventoryLimitItem ({ id, spend_limit, period }) {
      const { inventoryItemsLimits: limits } = this.form

      const findIndex = limits.findIndex((item) => item.id === id)

      if (findIndex !== -1) {
        let newObject = Object.assign({}, limits[findIndex])
        newObject = {
          ...newObject,
          period,
          spend_limit,
        }

        this.$set(this.form.inventoryItemsLimits, findIndex, newObject)
      }
    },
    getRequestBody () {
      const { form } = this

      const spendLimit = form.spendLimit.limit && form.spendLimit.time
        ? ({ amount: form.spendLimit.limit, time_frame: form.spendLimit.time, })
        : null

      return ({
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        permission_level: form.permissions.level,
        access_to: form.permissions.categoriesAccess,
        order_approver: form.userOrdersRequireApproval ? form.permissions.orderApprover : null,
        inventory_products: form.inventoryItemsLimits ?? [],
        campaigns: form.selectedCampaigns?.map((val) => ({ id: val })) ?? [],
        can_view_all_orders: form.userCanViewAllOrders,
        send_welcome_email: form.sendWelcomeEmail,
        spend_limit_enabled: form.setSpendLimit,
        spend_limit: spendLimit,
        is_deactivated: form.isDeactivated,
        budget_entities: form.budgetEntitiesLimits?.map((budget) => ({
          id: budget.id,
          time: budget.time,
          limit: budget.limit,
        }))
      })
    },
    submit () {
      if (!this.$refs.form.validate()) {
        this.goToMissingRequiredField()
        return
      }

      const requestBody = this.getRequestBody()

      if (this.inEditMode) {
        this.updateUserData(requestBody)
      } else {
        this.addNewUser(requestBody)
      }
    },
    addNewUser (requestBody) {
      this.loading = true

      Api.post('/customer/subaccounts', requestBody)
        .then(() => {
          this.show = false
          this.$cgToast.successBold('Successfully created a new user')
          this.getUsers()
        })
        .catch(({ response: { data } }) => {
          const errors = Object.values(data?.errors)?.flat() ?? ['An error occurred, please contact our support']
          this.$cgToast.error(errors?.at(0))
        })
        .finally(() => (this.loading = false))
    },
    updateUserData (requestBody) {
      this.loading = true

      Api.put(`/customer/subaccounts/${this.selectedUserId}`, requestBody)
        .then(() => {
          this.show = false
          this.$cgToast.successBold('User has been successfully updated')
          this.getUsers()
        })
        .catch(({ response: { data } }) => {
          const errors = Object.values(data?.errors)?.flat() ?? ['An error occurred, please contact our support']
          this.$cgToast.error(errors?.at(0))
        })
        .finally(() => (this.loading = false))
    },
    goToMissingRequiredField () {
      const { errorBag, inputs } = this.$refs.form
      // find index of first error
      const firstErrorInputIndex = Object.values(errorBag).findIndex((error) => error)
      if (firstErrorInputIndex !== -1) {
        // take field vue info
        const requiredField = inputs[firstErrorInputIndex]
        // scroll to required field
        this.$el.querySelector('.v-navigation-drawer__content').scrollTo(
          {
            top: requiredField.$el?.offsetTop || requiredField.$el?.scrollTop || 0,
            behavior: 'smooth'
          }
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-add-edit-panel {
  &::v-deep > .v-navigation-drawer__content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;

    & > div {
      color: #000;
      font-family: 'Lato-Light', sans-serif;
      font-size: 40px;
      line-height: 44px;

      & > b {
        font-family: 'Lato-Bold', sans-serif;
      }
    }

    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }

  &__add-multiple-users {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;

    & > div {
      font-family: 'Lato-Regular', 'Lato', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      & > span {
        color: #42B77A;
        cursor: pointer;
      }
    }
  }

  &__subtitle {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 140px;

    & > form {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: #D3D2D2;
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;

    & > label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }
  }

  &__multiple-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
  }

  &__checkbox {
    &::v-deep .common-checkbox__content {
      gap: 8px;
    }
  }

  &__set-spend-limit::v-deep .common-checkbox__content--bold {
    font-size: 15px;
    line-height: unset;
    color: #000 !important;
  }

  &__info {
    font-family: 'Lato-Italic', sans-serif;
    margin-top: -20px;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }

  &__archive-user-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > div {
      &:nth-child(2) {
        font-family: 'Lato-Italic', sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #95979D;
      }
    }
  }

  &__link {
    font-family: 'Lato-Regular', sans-serif;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #42B77A;
  }
}
</style>
