<template>
  <div class="auth-layout__container">
    <div class="auth-layout__banner">
      <a href="https://corporategift.com" aria-label="Redirect to homepage">
        <svg width="262" height="25" viewBox="0 0 262 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M41.2855 15.7963C41.8523 14.9971 40.5224 14.1979 40.021 14.9323C39.5631 15.5155 38.9091 16.0339 38.2333 16.3795C37.4048 16.7899 36.5109 17.0059 35.7043 17.0059C32.6957 17.0059 30.1449 14.5003 30.1449 11.2171C30.1449 8.08512 32.0416 6.20592 34.7232 5.79552C36.6417 5.57952 38.3641 5.81712 39.8466 7.37232C40.457 8.02032 41.6343 7.13472 40.9802 6.40032C39.2579 4.60752 37.0342 3.98112 34.4834 4.32672C31.126 4.84512 28.5752 7.65312 28.5752 11.1955C28.5752 15.4723 31.9326 18.4963 35.6607 18.4963C36.7072 18.4963 37.8844 18.1939 38.9309 17.7187C39.8684 17.2651 40.6968 16.6171 41.2855 15.7963Z" fill="#222325"/>
          <path d="M50.1367 18.4963C53.8429 18.4963 57.1132 15.6451 57.1132 11.1523C57.1132 7.60992 54.5188 4.84512 51.1614 4.32672C50.3983 4.24032 49.6571 4.21872 48.8504 4.32672C45.384 4.86672 43.073 7.76112 43.073 11.1523C43.073 15.7315 46.2124 18.4963 50.1367 18.4963ZM49.0684 5.77392C49.6789 5.68752 50.3111 5.68752 50.9652 5.77392C53.6467 6.20592 55.4999 8.43072 55.4999 11.2171C55.4999 14.8459 53.0799 17.0059 50.1367 17.0059C47.0627 17.0059 44.7081 14.7595 44.7081 11.2171C44.7081 8.27952 46.4304 6.18432 49.0684 5.77392Z" fill="#222325"/>
          <path d="M61.7594 5.94672H66.1633C68.6923 5.94672 70.1966 7.37232 70.2184 9.14352C70.262 10.7635 69.0193 12.0811 66.1633 12.0811H61.7594V5.94672ZM65.9889 13.4419L70.3928 18.1723C71.1123 18.9067 72.3114 17.9563 71.4829 17.1355L67.9511 13.3555C70.6326 12.9019 71.8317 11.3251 71.8317 9.14352C71.8317 6.61632 69.9132 4.49952 66.1415 4.49952H60.9746C60.495 4.49952 60.1679 4.80192 60.1679 5.25552V17.6539C60.1679 18.6259 61.7594 18.6259 61.7594 17.6323V13.4419H65.9889Z" fill="#222325"/>
          <path d="M76.306 5.90352H80.2957C82.8246 5.90352 84.2417 7.69632 84.2635 9.46752C84.3071 11.1091 83.108 12.7939 80.2084 12.7939H76.306V5.90352ZM80.2084 14.1979C84.0237 14.1979 85.8114 12.0595 85.8114 9.46752C85.8114 6.98352 84.0455 4.49952 80.2738 4.49952H75.5429C75.1069 4.49952 74.7363 4.80192 74.7363 5.25552V17.6971C74.7363 18.6475 76.306 18.6691 76.306 17.6755V14.1979H80.2084Z" fill="#222325"/>
          <path d="M94.895 18.4963C98.6012 18.4963 101.871 15.6451 101.871 11.1523C101.871 7.60992 99.2771 4.84512 95.9196 4.32672C95.1566 4.24032 94.4154 4.21872 93.6087 4.32672C90.1423 4.86672 87.8313 7.76112 87.8313 11.1523C87.8313 15.7315 90.9707 18.4963 94.895 18.4963ZM93.8267 5.77392C94.4371 5.68752 95.0694 5.68752 95.7234 5.77392C98.405 6.20592 100.258 8.43072 100.258 11.2171C100.258 14.8459 97.8382 17.0059 94.895 17.0059C91.821 17.0059 89.4664 14.7595 89.4664 11.2171C89.4664 8.27952 91.1887 6.18432 93.8267 5.77392Z" fill="#222325"/>
          <path d="M106.518 5.94672H110.922C113.451 5.94672 114.955 7.37232 114.977 9.14352C115.02 10.7635 113.778 12.0811 110.922 12.0811H106.518V5.94672ZM110.747 13.4419L115.151 18.1723C115.871 18.9067 117.07 17.9563 116.241 17.1355L112.709 13.3555C115.391 12.9019 116.59 11.3251 116.59 9.14352C116.59 6.61632 114.671 4.49952 110.9 4.49952H105.733C105.253 4.49952 104.926 4.80192 104.926 5.25552V17.6539C104.926 18.6259 106.518 18.6259 106.518 17.6323V13.4419H110.747Z" fill="#222325"/>
          <path d="M128.368 13.2691H121.5L124.945 6.46512L128.368 13.2691ZM129.044 14.6299L130.744 17.9779C131.158 18.8203 132.772 18.2803 132.205 17.3515L125.643 4.56432C125.49 4.28352 125.228 4.15392 124.945 4.15392C124.662 4.15392 124.356 4.28352 124.225 4.56432L117.641 17.4163C117.249 18.2803 118.688 18.8419 119.08 18.0427L120.846 14.6299H129.044Z" fill="#222325"/>
          <path d="M131.489 4.49952C130.464 4.49952 130.464 5.98992 131.489 5.98992H136.329V17.7187C136.329 18.2587 136.743 18.5179 137.114 18.5179C137.484 18.5179 137.942 18.2587 137.942 17.7187C137.942 13.8307 137.92 9.87792 137.92 5.98992H142.782C143.85 5.98992 143.85 4.49952 142.782 4.49952H131.489Z" fill="#222325"/>
          <path d="M147.031 12.1459H155.228C156.144 12.1459 156.1 10.7635 155.228 10.7635H147.031V5.90352H155.642C156.558 5.90352 156.601 4.52112 155.664 4.52112H146.464C145.853 4.52112 145.461 4.95312 145.461 5.44992V17.3083C145.461 17.8267 145.853 18.2587 146.464 18.2587H155.642C156.689 18.2587 156.558 16.8331 155.642 16.8331H147.031V12.1459Z" fill="#222325"/>
          <path d="M177.141 15.1483V11.5411C177.141 11.0659 176.945 10.7419 176.312 10.7419H171.69C170.775 10.7419 170.666 12.1675 171.69 12.1675H175.615V15.0403C174.655 16.3147 172.977 17.0059 171.363 16.9843C168.551 16.9195 166.066 14.3707 166.022 11.3251C166.022 8.49552 168.115 6.40032 170.513 5.83872C172.148 5.42832 174.176 5.92512 175.397 7.32912C176.073 8.10672 177.184 7.06992 176.53 6.31392C175.331 4.93152 173.304 4.28352 171.407 4.28352C171.015 4.28352 170.6 4.30512 170.273 4.34832C166.938 4.97472 164.452 7.97712 164.452 11.2603C164.365 15.3427 167.592 18.4315 171.342 18.4531C173.456 18.4747 175.615 17.5459 176.901 15.8611C177.097 15.6667 177.141 15.3859 177.141 15.1483Z" fill="#222325"/>
          <path d="M180.565 17.7187C180.565 18.7555 182.2 18.7123 182.2 17.7187V4.80192C182.2 3.74352 180.565 3.72192 180.565 4.80192V17.7187Z" fill="#222325"/>
          <path d="M185.53 17.7187C185.53 18.7771 187.1 18.7771 187.1 17.6971V12.6427H195.057C195.951 12.6427 195.908 11.3035 195.057 11.3035H187.1V5.88192H195.472C196.387 5.88192 196.387 4.52112 195.472 4.52112H186.489C185.966 4.52112 185.53 4.95312 185.53 5.44992V17.7187Z" fill="#222325"/>
          <path d="M198.266 4.49952C197.242 4.49952 197.242 5.98992 198.266 5.98992H203.106V17.7187C203.106 18.2587 203.52 18.5179 203.891 18.5179C204.262 18.5179 204.719 18.2587 204.719 17.7187C204.719 13.8307 204.698 9.87792 204.698 5.98992H209.559C210.628 5.98992 210.628 4.49952 209.559 4.49952H198.266Z" fill="#222325"/>
          <path d="M211.802 16.4659C211.235 16.4659 210.821 16.8331 210.821 17.3731C210.821 17.9131 211.235 18.2803 211.802 18.2803C212.369 18.2803 212.74 17.9131 212.74 17.3731C212.74 16.8331 212.369 16.4659 211.802 16.4659Z" fill="#222325"/>
          <path d="M227.207 15.7965C227.774 14.9973 226.444 14.1981 225.943 14.9325C225.485 15.5157 224.831 16.0341 224.155 16.3797C223.327 16.7901 222.433 17.0061 221.626 17.0061C218.618 17.0061 216.067 14.5005 216.067 11.2173C216.067 8.08534 217.964 6.20614 220.645 5.79574C222.564 5.57974 224.286 5.81734 225.768 7.37254C226.379 8.02054 227.556 7.13494 226.902 6.40054C225.18 4.60774 222.956 3.98134 220.405 4.32694C217.048 4.84534 214.497 7.65334 214.497 11.1957C214.497 15.4725 217.855 18.4965 221.583 18.4965C222.629 18.4965 223.806 18.1941 224.853 17.7189C225.79 17.2653 226.619 16.6173 227.207 15.7965Z" fill="black" fill-opacity="0.5"/>
          <path d="M236.059 18.4965C239.765 18.4965 243.035 15.6453 243.035 11.1525C243.035 7.61014 240.441 4.84534 237.083 4.32694C236.32 4.24054 235.579 4.21894 234.772 4.32694C231.306 4.86694 228.995 7.76134 228.995 11.1525C228.995 15.7317 232.134 18.4965 236.059 18.4965ZM234.99 5.77414C235.601 5.68774 236.233 5.68774 236.887 5.77414C239.569 6.20614 241.422 8.43094 241.422 11.2173C241.422 14.8461 239.002 17.0061 236.059 17.0061C232.985 17.0061 230.63 14.7597 230.63 11.2173C230.63 8.27974 232.352 6.18454 234.99 5.77414Z" fill="black" fill-opacity="0.5"/>
          <path d="M252.935 13.0533L247.616 4.84534C247.18 4.06774 246.133 4.30534 246.133 5.23414V17.6757C246.133 18.7125 247.725 18.7125 247.725 17.6757V7.86934L252.172 14.7381C252.499 15.3645 253.328 15.3645 253.677 14.7381L258.124 7.84774V17.6757C258.124 18.7125 259.716 18.7125 259.716 17.6757C259.716 12.817 259.716 10.0929 259.716 5.23414C259.716 4.32694 258.713 4.06774 258.211 4.84534L252.935 13.0533Z" fill="black" fill-opacity="0.5"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.72232 19.7415H14.9115V6.74011H1.72232V19.7415ZM15.8257 21.4924H0.861885C0.377166 21.4924 0 21.1206 0 20.6435V5.89045C0 5.41332 0.377166 5.0415 0.861885 5.0415H15.7719C16.2574 5.0415 16.6338 5.41332 16.6338 5.89045V20.5904C16.6876 21.0676 16.3111 21.4924 15.8257 21.4924Z" fill="#222325"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.39826 21.2806C7.91281 21.2806 7.53564 20.9081 7.53564 20.4309V6.26252C7.53564 5.78467 7.91281 5.41357 8.39826 5.41357C8.88225 5.41357 9.25869 5.78467 9.25869 6.26252V20.4309C9.25869 20.8558 8.88225 21.2806 8.39826 21.2806Z" fill="#222325"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.772 10.932H1.18391C0.69992 10.932 0.322754 10.5609 0.322754 10.0831C0.322754 9.60525 0.69992 9.23486 1.18391 9.23486H15.772C16.2575 9.23486 16.6339 9.60525 16.6339 10.0831C16.6339 10.5609 16.2575 10.932 15.772 10.932Z" fill="#222325"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.61278 6.68645C8.3984 6.68645 8.23634 6.63344 8.07501 6.47439L3.92982 3.13091C3.55266 2.81283 3.49888 2.28197 3.82227 1.91087C4.14493 1.53834 4.68343 1.48604 5.05987 1.80484L9.20506 5.1476C9.58222 5.46641 9.636 5.99655 9.31261 6.36908C9.15055 6.58042 8.88239 6.68645 8.61278 6.68645Z" fill="#222325"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.12896 6.68645C7.85935 6.68645 7.64424 6.58042 7.48291 6.36908C7.15952 5.99655 7.2133 5.46641 7.59047 5.1476L11.7357 1.80484C12.1114 1.48604 12.6513 1.53834 12.9733 1.91087C13.2966 2.28197 13.2429 2.81283 12.8657 3.13091L8.66673 6.47439C8.5054 6.63344 8.3448 6.68645 8.12896 6.68645Z" fill="#222325"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34431 6.58021C7.85886 6.58021 7.48242 6.20912 7.48242 5.73127V0.848944C7.48242 0.371816 7.85886 0 8.34431 0C8.8283 0 9.20474 0.371816 9.20474 0.848944V5.73127C9.20474 6.1561 8.8283 6.58021 8.34431 6.58021Z" fill="#222325"/>
        </svg>
      </a>

      <div class="auth-layout__banner-content">
        <template v-if="routerName === 'RegisterPage'">
          <b>Create an account to:</b>

          <ul>
            <li v-for="item in listItems" :key="item">
              <icons-check-circle
                filled
                :height="24"
                :width="24"
                filled-color="#B0A0FF"
                :checkStrokeWidth="2"
              />
              {{ item }}
            </li>
          </ul>
        </template>

        <template v-else>
          <span>
            The platform to <b>manage & automate</b> gifting programs
          </span>
        </template>
      </div>

      <img
        src="/images/login-gift.png"
        alt=""
        :class="{ 'center': routerName !== 'RegisterPage' }"
      >

      <div class="auth-layout__social-proof">
        <img src="/images/social-proof.png" alt="">
      </div>
    </div>

    <div class="auth-layout__content">
      <a href="https://corporategift.com" aria-label="Redirect to homepage">
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="45" height="45" rx="22.5" fill="black"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9027 29.4954H29.0919V16.494H15.9027V29.4954ZM30.0061 31.2463H15.0423C14.5576 31.2463 14.1804 30.8745 14.1804 30.3974V15.6444C14.1804 15.1672 14.5576 14.7954 15.0423 14.7954H29.9523C30.4378 14.7954 30.8142 15.1672 30.8142 15.6444V30.3443C30.868 30.8215 30.4916 31.2463 30.0061 31.2463Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5789 31.0345C22.0935 31.0345 21.7163 30.662 21.7163 30.1848V16.0164C21.7163 15.5386 22.0935 15.1675 22.5789 15.1675C23.0629 15.1675 23.4394 15.5386 23.4394 16.0164V30.1848C23.4394 30.6097 23.0629 31.0345 22.5789 31.0345Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M29.9524 20.6859H15.3643C14.8803 20.6859 14.5032 20.3148 14.5032 19.837C14.5032 19.3592 14.8803 18.9888 15.3643 18.9888H29.9524C30.4379 18.9888 30.8143 19.3592 30.8143 19.837C30.8143 20.3148 30.4379 20.6859 29.9524 20.6859Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7932 16.4404C22.5788 16.4404 22.4168 16.3873 22.2554 16.2283L18.1102 12.8848C17.7331 12.5667 17.6793 12.0359 18.0027 11.6648C18.3253 11.2922 18.8638 11.2399 19.2403 11.5587L23.3855 14.9015C23.7626 15.2203 23.8164 15.7505 23.493 16.123C23.331 16.3343 23.0628 16.4404 22.7932 16.4404Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3091 16.4404C22.0395 16.4404 21.8244 16.3343 21.6631 16.123C21.3397 15.7505 21.3935 15.2203 21.7706 14.9015L25.9158 11.5587C26.2915 11.2399 26.8315 11.2922 27.1534 11.6648C27.4768 12.0359 27.423 12.5667 27.0459 12.8848L22.8469 16.2283C22.6856 16.3873 22.525 16.4404 22.3091 16.4404Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.525 16.3341C22.0395 16.3341 21.6631 15.963 21.6631 15.4852V10.6029C21.6631 10.1257 22.0395 9.75391 22.525 9.75391C23.009 9.75391 23.3854 10.1257 23.3854 10.6029V15.4852C23.3854 15.91 23.009 16.3341 22.525 16.3341Z" fill="white"/>
        </svg>
      </a>

      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
  data: () => ({
    listItems: [
      'Browse & save ideas to wish lists',
      'Track deliveries and order status',
      'Expedite checkout',
      'Manage eGifts and more',
      'Plus much more..',
    ],
  }),
  computed: {
    routerName () { return this.$route.name },
    userIsLoggedIn () { return this.$store.state.headerAuth.userIsLoggedIn ?? false },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('app/setHideHeader', true)
      vm.$store.commit('app/setHideUserMenu', true)
    })
  },
  mounted () {
    if (this.userIsLoggedIn) {
      this.$router.push({ name: 'Dashboard' })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$nextTick(() => {
      this.$store.commit('app/setHideHeader', false)
      this.$store.commit('app/setHideUserMenu', false)
    })
    next()
  },
}
</script>

<style lang="scss" scoped>
.auth-layout {
  &__container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__banner {
    padding: 40px 42px 60px;
    height: 100%;
    width: 466px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    background-color: #E5DFFF;
    align-items: flex-start;
    justify-content: space-between;

    & > img {
      max-width: 260px;
      max-height: 260px;
      object-fit: contain;

      &.center {
        margin-inline: auto;
      }
    }
  }

  &__banner-content {
    gap: 24px;
    display: flex;
    flex-direction: column;
    color: #222325;
    font-family: 'Lato', sans-serif;

    & > b {
      font-weight: 700;
      font-size: 1.5rem;
    }

    & > ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 18px;

      & > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
      }
    }

    & > span {
      font-family: 'Inter', 'Lato', sans-serif;
      font-weight: 600;
      font-size: 2.625rem;
      text-align: center;
      line-height: 3rem;
      color: #000;
      text-wrap: balance;

      & > b {
        font-weight: 900;
      }
    }
  }

  &__social-proof {
    margin-inline: auto;

    & > img {
      max-width: 182px;
      max-height: 114px;
      object-fit: contain;
    }
  }

  &__content {
    width: calc(100% - 466px);
    height: 100%;

    & > a:first-of-type {
      display: none;
      margin: 0 auto 1.2rem;
      width: fit-content;
    }

    &::v-deep h1 {
      font-family: 'Lato', sans-serif;
      font-size: 42px;
      line-height: normal;
      text-align: center;
      font-weight: 300;
      color: #000;
    }
  }

  @media (max-width: 840px) {
    &__container {
      height: unset;
      flex-direction: column-reverse;
    }

    &__banner {
      width: 100%;
      align-items: center;
      padding: 30px 16px;

      & > a:first-of-type {
        display: none;
      }

      & > img {
        max-width: 184px;
        max-height: 168px;
      }
    }

    &__banner-content {
      width: 100%;

      & > span {
        font-size: 2rem;
        line-height: 2.25rem;
      }
    }

    &__content {
      width: 100%;
      padding: 32px 16px 48px;

      &::v-deep h1 {
        font-size: 32px;
      }

      & > a:first-of-type {
        display: block;
      }
    }

    &__social-proof > img {
      max-width: 162px;
      max-height: 102px;
    }
  }
}
</style>
