<template>
  <v-col cols="12" v-if="['single'].includes(giftType)">
    <span class="campaign-card__text">
      Single choice:
    </span>
    <span class="campaign-card__subtext">
      {{ minPrice | priceFilter }}
    </span>

    <span
      class="campaign-card__subtext"
      v-if="maxPrice"
    >
      -
      {{ maxPrice | priceFilter }}
    </span>
  </v-col>

  <v-col cols="12" v-else-if="['multiple'].includes(giftType)">
    <span class="campaign-card__text">
      Multiple choice ({{ maxSelection }} {{ maxSelection > 1 ? 'Items' : 'Item' }}):
    </span>

    <span class="campaign-card__subtext">
      {{ minPrice | priceFilter }}
      -
      {{ maxPrice | priceFilter }}
    </span>
  </v-col>

  <v-col cols="12" v-else>
    <span class="campaign-card__text">
      GiftCoin:
    </span>

    <span class="campaign-card__subtext">
      {{ coinValue | priceFilter({
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }) }} value
    </span>
  </v-col>
</template>

<script>
export default {
  name: 'TheCampaignCardType',
  props: {
    giftType: {
      type: String,
      required: false,
      default: 'single'
    },
    priceRange: {
      type: Object,
      required: true
    },
    giftSettings: {
      type: Object,
      required: false,
      default: null
    },
    giftProducts: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  computed: {
    maxSelection () {
      return this.giftSettings?.max_allowed_selection || 1
    },
    coinValue () {
      return this.giftSettings?.coin_value || 0
    },
    productsPrices () {
      return this.giftProducts.map(product => product.product.price)
    },
    minPrice () {
      const { giftType, priceRange, productsPrices, maxSelection } = this
      if (giftType === 'multiple') {
        return productsPrices
          .sort()
          .slice(0, maxSelection)
          .reduce((acc, price) => (acc += price), 0)
      }
      return priceRange.min
    },
    maxPrice () {
      const { giftType, priceRange, productsPrices, maxSelection } = this
      if (giftType === 'multiple') {
        return productsPrices
          .sort((a, b) => (b - a))
          .slice(0, maxSelection)
          .reduce((acc, price) => (acc += price), 0)
      }
      return priceRange.max
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-card {
  background: #f6f6f6;
  &__text, &__subtext {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.19px;
    color: #222325;
  }

  &__text {
    font-family: 'Lato-Bold', sans-serif;
  }
}
</style>
