<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 19"
    :width="width"
    :height="height"
    :class="{
      'fillable': fillable
    }"
  >
    <defs/>
    <g fill="none" fill-rule="evenodd">
      <g stroke="#919191" stroke-linecap="round" stroke-linejoin="round" stroke-width=".75">
        <path d="M14.5 1H1v13.125l4.125 4.125H14.5z"/>
        <path d="M5.125 14.125v4.125L1 14.125z"/>
      </g>
      <path
        fill="#919191"
        fill-rule="nonzero"
        d="M10.894 8.778a.559.559 0 01-.056.723l-2.539 2.422-.018.015-.022.018a.153.153 0
          01-.039.023h-.036l-.052.018h-.03a.3.3 0 01-.083 0 .3.3 0 01-.084
          0H7.906l-.053-.018h-.036a.153.153 0 01-.038-.023l-.023-.018-.018-.015h-.011L5.177
          9.51a.548.548 0 01-.171-.489.5.5 0 01.298-.402.414.414 0 01.455.107l1.796
          1.704V5.513c0-.283.202-.513.452-.513s.453.23.453.513v4.918l1.796-1.704a.417.417
          0 01.638.05z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: '',
  props: {
    fillable: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24
    }
  }
}
</script>

<style lang="scss" scoped>
.fillable:hover {
  g {
    fill: #DFF3F2;
  }
}
</style>
