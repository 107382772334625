<template>
  <div class="my-users-budget-limits">
    <div>
      <div>Entity name</div>
      <div>Spend limit</div>
      <div>Period</div>
      <div></div>
    </div>

    <div
      v-for="budgetLimit in budgetLimits"
      :key="budgetLimit.id"
      class="my-users-budget-limits__item"
    >
      <div
        class="my-users-budget-limits__item-name"
        :style="`--badge-color: ${budgetLimit.color}`"
      >
        {{ budgetLimit.name }}
      </div>
      <template v-if="budgetLimit.id !== editedItemId">
        <div>{{ getBudgetItemLimit(budgetLimit) }}</div>
        <div>{{ getBudgetItemTimeName(budgetLimit) }}</div>
        <div class="my-users-budget-limits__item-options">
          <icons-edit
            :width="13"
            :height="13"
            color="#9B9B9B"
            @click.native.stop="editBudgetItem(budgetLimit)"
          />

          <icons-delete
            :width="13"
            :height="17"
            color="#9B9B9B"
            @click.native.stop="removeItem(budgetLimit)"
          />
        </div>
      </template>

      <template v-else>
        <common-input
          v-model.number="form.limit"
          @input="budgetLimitHandler"
          @blur="budgetLimitHandler"
          type="number"
          placeholder="0.00"
          prefix="$"
          :min="0"
          :step="1"
          :height="40"
        />

        <common-select
          v-model="form.time"
          :items="timeList"
          placeholder="Time frame"
          item-text="value"
          item-value="key"
          class="my-users-budget-limits__select"
          :height="40"
        />

        <div class="my-users-budget-limits__item-options">
          <icons-close-circle
            :width="20"
            :height="20"
            color="#9B9B9B"
            @click.native.stop="cancelEditing()"
          />

          <icons-check-circle
            :checkStrokeWidth="1.5"
            :outlineStrokeWidth="1"
            outline-color="#42B77A"
            outline-check-color="#42B77A"
            :width="20"
            :height="20"
            @click.native.stop="saveChanges()"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyUserBudgetLimitsList',
  props: {
    budgetLimits: {
      type: Array,
      required: true
    },
    timeList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    editedItemId: null,
    form: {
      limit: null,
      time: null,
    },
  }),
  methods: {
    getBudgetItemLimit ({ limit }) {
      if (limit === null) return null

      return this.$options.filters.priceFilter(
        limit,
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
      )
    },
    getBudgetItemTimeName ({ time }) {
      if (time === null) return null
      const timeItem = this.timeList.find((item) => item.key === time)
      return timeItem?.value ?? null
    },
    editBudgetItem ({ id, limit, time }) {
      this.editedItemId = id
      this.form = {
        limit: limit ?? null,
        time: time ?? null,
      }
    },
    budgetLimitHandler () {
      const { limit } = this.form

      if (typeof limit === 'number') {
        if (limit < 0) { this.$nextTick(() => { this.form.limit = 0 }) }
      }
    },
    removeItem ({ id }) {
      this.$emit('removeItem', { id })
    },
    cancelEditing () {
      this.editedItemId = null
      this.form = {
        limit: null,
        time: null,
      }
    },
    saveChanges () {
      const { editedItemId, form } = this

      if ((form.time && !form.limit) || (!form.time && form.limit)) { return }

      this.$emit('updateItem', {
        id: editedItemId,
        ...form,
      })

      this.cancelEditing()
    }
  },
}
</script>

<style lang="scss" scoped>
.my-users-budget-limits {
  display: flex;
  flex-direction: column;
  
  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: 200px 100px 110px 60px;
    padding: 10px 20px;
    gap: 10px;
    border-bottom: 1px solid #D3D2D2;

    &:first-of-type {
      border-top: 1px solid #D3D2D2;
      text-transform: uppercase;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 11px;
      line-height: 13px;
      color: #000000;

      & > div {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
    }
  }

  &__item {
    min-height: 65px;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    color: #000000;
  }

  &__item-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 12px;
    color: #222325;

    &::before {
      content: '';
      background: var(--badge-color, #AD84B7);
      min-width: 13px;
      height: 13px;
      border-radius: 50%;
    }
  }

  &__item-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;

    & > svg {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__select {
    &::v-deep .v-input__append-inner {
      padding: 0 !important;
    }

    &::v-deep .v-input__icon {
      padding: 0 !important;
    }
  }
}
</style>
