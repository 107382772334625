<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    :stateless="isPaymentPanelOpen"
    width="600px"
    class="buy-new-credits-panel"
  >
    <panel-header :show.sync="show">Buy new credits</panel-header>

    <div v-if="show" class="buy-new-credits-panel__content-wrapper">
      <div
        class="buy-new-credits-panel__total-balance"
        :class="{ 'buy-new-credits-panel__total-balance--is-negative': isBalanceNegative }"
      >
        Your balance: <span>{{
          creditsBalance | priceFilter({
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
          })
        }}</span>
      </div>

      <v-divider />

      <cg-loader v-if="loading" />

      <div v-show="!loading" class="buy-new-credits-panel__content-wrapper">
        <template v-if="!responseData.success">
          <div class="buy-new-credits-panel__input-wrapper">
            <label for="credits-amount">Credits amount</label>

            <common-input
              v-model.number="creditsAmount"
              type="number"
              min="1"
              prefix="$"
              id="credits-amount"
              ref="creditsAmount"
              placeholder="0.00"
              :rules="[(v) => v && v >= 1 || '']"
            />
          </div>

          <v-divider />

          <label>Choose a payment method:</label>

          <payment-module
            :paymentMethod.sync="payment.method"
            :paymentData.sync="payment.paymentData"
            :amount="creditsAmount"
            :availableMethods="availablePaymentMethods"
            ref="paymentModule"
            @update:isPaymentPanelOpen="(val) => isPaymentPanelOpen = val"
          />

          <cg-button style="max-width: 165px" @click="onBuyCredits">Buy Credits</cg-button>

          <span v-if="!isInFundMode" class="buy-new-credits-panel__info-text">
            By clicking “Buy Credits” you agree to the CorporateGift.com
            <a href="https://corporategift.com/terms-and-conditions/" target="_blank">
              Terms of Service and Payments Policy.
            </a><br/>
            Prepaid credits are non-refundable and non-transferrable.
          </span>

          <span v-else class="buy-new-credits-panel__info-text">
            By clicking “Anable auto-reload", you agree to farm-to-table bicycle rights unicorn
            cloud bread occupy pinterest offal keffiyeh. Wolf craft beer authentic DIY raclette
            tofu pinterest gluten-free crucifix 8-bit fixie farm-to-table hashtag sartorial
            kinfolk. Lumbersexual fam <span class="link-type">plaid humblebrag distiller</span>.
          </span>
        </template>

        <template v-else>
          <add-credits-confirmation>
            <template #icon>
              <v-img
                contain
                max-width="26"
                max-height="26"
                width="26"
                height="26"
                :src="responseData.approved === false ? '/images/info-icon.svg' : '/images/check-icon-fill.svg'"
              />
            </template>

            <template #text>
              <div v-if="responseData.approved === false">
                <p class="mb-7">
                  Your payment has been received and is pending. We'll notify you when payment has processed.
                </p>
                <p class="mb-0">Once processed, you may reallocate credits to your budget entities.</p>
              </div>
              <div v-else>
                <p class="mb-7">
                  Your payment has been received and your order confirmed!<br/>
                  Your order number is {{ responseData.id }}
                </p>
                <p class="mb-0">You have added an additional {{ creditsAmount | priceFilter }} to your balance.</p>
              </div>
            </template>

            <template #buttons>
              <cg-button @click="show = false">Close</cg-button>

              <cg-button
                v-if="responseData.approved && !marketplaceUser"
                @click="allocateCredits()"
              >
                Allocate credits
              </cg-button>
            </template>
          </add-credits-confirmation>
        </template>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import ApiM from '@/axios/apiM'
import panelVModel from '@/mixins/panelVModel'
import { budgetEntities, entitesSummary, marketplaceUser } from '../mixins'
import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import getPaymentBody from '@/components/payment/paymentModule/utils/getPaymentBody'

import {
  creditsOpenPanel,
  creditsSelectPaymentMethod,
  creditsPurchase,
  destinationDeclined
} from '@/plugins/googleTagManager'

import { CgLoader, CgButton } from '@corporategift/design-system'
import PanelHeader from '@/components/PanelHeader.vue'
import PaymentModule from '@/components/payment/PaymentModule.vue'
import AddCreditsConfirmation from '@/components/myCGCredits/credits/AddCreditsConfirmation'

export default {
  name: 'TheBuyNewCreditsPanel',
  mixins: [
    panelVModel,
    budgetEntities,
    entitesSummary,
    marketplaceUser,
  ],
  components: {
    AddCreditsConfirmation,
    PaymentModule,
    PanelHeader,
    CgButton,
    CgLoader,
  },
  data: () => ({
    loading: false,
    creditsAmount: null,
    payment: {
      method: PaymentType.CC,
      paymentData: null,
    },
    isPaymentPanelOpen: false,
    availablePaymentMethods: [
      PaymentType.CC,
      PaymentType.ACH,
      PaymentType.CC_Bluesnap,
      PaymentType.ACH_Bluesnap,
      PaymentType.PO,
    ],
    responseData: {
      success: false,
      approved: false,
      message: null,
    },
  }),
  computed: {
    creditsBalance () {
      return this.summary.balance ?? 0
    },
    isBalanceNegative () {
      return this.creditsBalance < 0
    },
    isInFundMode () {
      return this.$store.state?.cgCreditAddFundMode ?? false
    },
    googleAnalyticsPaymentMethod () {
      const { method } = this.payment
      let GAPaymentMethod = method

      if (method === PaymentType.CC) { GAPaymentMethod = 'credit card' }
      if (method === PaymentType.ACH) { GAPaymentMethod = 'ACH' }
      if (method === PaymentType.PO) { GAPaymentMethod = 'purchase order' }

      return GAPaymentMethod
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        creditsOpenPanel()
        Object.assign(this.$data, this.$options.data())
      }
    },
    googleAnalyticsPaymentMethod: function (val) {
      if (val) { creditsSelectPaymentMethod({ paymentType: val }) }
    },
  },
  methods: {
    onBuyCredits2 () {
      const { creditsAmount, payment } = this
      const isCreditsAmountValid = this.$refs.creditsAmount.validate(true)
      const isPaymentMethodValid = this.$refs.paymentModule.validate()

      if (isCreditsAmountValid && isPaymentMethodValid) {
        this.loading = true;
        const paymentBody = getPaymentBody(payment)

        const requestBody = {
          amount: creditsAmount,
          payment_method: paymentBody.method,
          ...paymentBody,
        }

        const requestObject = new FormData()
        Object.entries(requestBody).forEach(([key, value]) => {
          requestObject.append(key, value)
        })

        ApiM.post('/customer/credits_api/buy', requestObject)
          .then((response) => {
            this.responseData = response

            if (response?.success) {
              creditsPurchase({
                paymentType: this.googleAnalyticsPaymentMethod,
                value: creditsAmount
              })
            } else {
              if (response?.message) {
                destinationDeclined({ action: response.message })
                this.$cgToast.error(response.message, { html: true })
              }
            }

            if (response?.success && response?.approved) {
              this.$emit('updateAmount', creditsAmount)
            }
          })
          .catch(({ response: { data } }) => {
            const errorMessage = data?.message ?? undefined
            this.$cgToast.error(
              errorMessage || 'An error occurred, please contact our support',
              { html: true },
            )
          })
          .finally(() => (this.loading = false))
      }
    },
    onBuyCredits () {
      const { creditsAmount, payment } = this
      const isCreditsAmountValid = this.$refs.creditsAmount.validate(true)
      const isPaymentMethodValid = this.$refs.paymentModule.validate()

      if (isCreditsAmountValid && isPaymentMethodValid) {
        this.loading = true;
        const paymentBody = getPaymentBody(payment)

        const requestBody = {
          amount: creditsAmount,
          payment: [paymentBody],
        }

        Api.post('/credit', requestBody)
          .then((response) => {
            this.responseData = response

            if (response?.success) {
              creditsPurchase({
                paymentType: this.googleAnalyticsPaymentMethod,
                value: creditsAmount
              })
            } else {
              if (response?.message) {
                destinationDeclined({ action: response.message })
                this.$cgToast.error(response.message, { html: true })
              }
            }

            if (response?.success && response?.approved) {
              this.$emit('updateAmount', creditsAmount)
            }
          })
          .catch(({ response: { data } }) => {
            const errorMessage = data?.message ?? undefined
            this.$cgToast.error(
              errorMessage || 'An error occurred, please contact our support',
              { html: true },
            )
          })
          .finally(() => (this.loading = false))
      }
    },
    allocateCredits () {
      this.show = false
      this.$emit('bought', true)
      this.$emit('allocate')
    },
  },
}
</script>

<style lang="scss" scoped>
.buy-new-credits-panel {
  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 50px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;

    & label {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }
  }

  &__total-balance {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000;

    & > span {
      color: #198B85;
    }

    &--is-negative > span {
      color: #FF5A60;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 220px;
    gap: 10px;
  }

  &__info-text {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;

    & > a,
    & > span.link-type {
      color: #42B77A !important;
    }
  }
}
</style>
