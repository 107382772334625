<template>
  <v-navigation-drawer
    class="my-users-add-multiple-users-panel"
    v-lock-scroll="show"
    v-model="show"
    width="750px"
    temporary
    right
    app
  >
    <panel-header
      :show.sync="show"
      :step="step"
      @update:step="handleStepChange"
    >
      <span class="my-users-add-multiple-users-panel__title" v-html="panelTitle" />
    </panel-header>

    <v-slide-x-reverse-transition
      v-if="show"
      v-show="!loading"
      group
      tag="div"
      hide-on-leave
      class="my-users-add-multiple-users-panel__content"
    >
      <multiple-users-upload-options
        v-if="step === 1"
        :key="1"
        @update:step="(val) => step = val"
        @update:selectedMethod="(val) => selectedMethod = val"
      />

      <multiple-users-upload-file
        v-else-if="step === 2"
        :key="2"
        :file.sync="uploadedFile"
        @update:step="(val) => step = val"
        @update:usersData="(val) => usersData = val"
      />

      <multiple-users-upload-copy-paste
        v-else-if="step === 3"
        :key="3"
        :usersData.sync="usersData"
        @update:step="(val) => step = val"
      />

      <multiple-users-review-upload
        v-else-if="step === 4"
        :key="4"
        :usersData.sync="usersData"
        @update:step="(val) => step = val"
      />

      <multiple-users-settings
        v-else-if="step === 5"
        :key="5"
        :options="options"
        :usersData.sync="usersData"
        :orderApprover.sync="settings.orderApprover"
        :setSpendLimit.sync="settings.setSpendLimit"
        :spendLimitTime.sync="settings.spendLimit.time"
        :spendLimitValue.sync="settings.spendLimit.limit"
        :permissionLevel.sync="settings.permissionLevel"
        :selectedCategories.sync="settings.selectedCategories"
        :selectedBudgetEntities.sync="settings.selectedBudgetEntities"
        :ordersRequireApproval.sync="settings.userOrdersRequireApproval"
        :selectedInventoryItems.sync="settings.selectedInventoryItems"
        :selectedCampaigns.sync="settings.selectedCampaigns"
        :sendWelcomeEmail.sync="settings.sendWelcomeEmail"
        @addUsers="addNewUsers"
      />
    </v-slide-x-reverse-transition>

    <common-loader v-if="loading" />
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'

import PanelHeader from '@/components/PanelHeader.vue'
import MultipleUsersSettings from './multipleUsers/steps/MultipleUsersSettings.vue'
import MultipleUsersUploadFile from './multipleUsers/steps/MultipleUsersUploadFile.vue'
import MultipleUsersReviewUpload from './multipleUsers/steps/MultipleUsersReviewUpload.vue'
import MultipleUsersUploadOptions from './multipleUsers/steps/MultipleUsersUploadOptions.vue'
import MultipleUsersUploadCopyPaste from './multipleUsers/steps/MultipleUsersUploadCopyPaste.vue'

export default {
  name: 'MyUsersAddMultipleUsersPanel',
  mixins: [panelVModel],
  components: {
    PanelHeader,
    MultipleUsersSettings,
    MultipleUsersUploadFile,
    MultipleUsersReviewUpload,
    MultipleUsersUploadOptions,
    MultipleUsersUploadCopyPaste,
  },
  data: () => ({
    step: 1,
    usersData: [],
    selectedMethod: null, // null | 'upload-file' | 'copy-paste'
    uploadedFile: null,
    loading: false,
    options: {
      budgetEntitiesList: [],
      categoriesList: [],
      approversList: [],
      permissionsLevels: [],
      inventoryItemsList: [],
      campaignsList: [],
      spendLimitTimeFrames: [],
    },
    settings: {
      permissionLevel: 200,
      userOrdersRequireApproval: false,
      sendWelcomeEmail: true,
      orderApprover: null,
      setSpendLimit: false,
      spendLimit: {
        limit: null,
        time: null,
      },
      selectedBudgetEntities: [],
      selectedCategories: [],
      selectedInventoryItems: [],
      selectedCampaigns: [],
    },
  }),
  computed: {
    panelTitle () {
      const { step, usersData } = this;

      if (step === 2) { return 'Upload file' }
      if (step === 3) { return 'Copy & paste user list' }
      if (step === 4) { return 'Review user list' }
      if (step === 5) { return `Set options for <b>${usersData.length}</b> users` }

      return 'Add multiple users'
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        Object.assign(this.$data, this.$options.data())
        this.getSelectOptions()
      }
    }
  },
  methods: {
    handleStepChange (val) {
      const { step: currentStep, selectedMethod } = this;

      if (currentStep === 3) {
        this.step = 1
      } else if (currentStep === 4) {
        if (selectedMethod === 'copy-paste') { this.step = 3 }
        else { this.step = 2 }
      } else {
        this.step = val
      }
    },
    getSelectOptions () {
      Api.get('/customer/subaccounts/additional-data')
        .then(({
          data: {
            access_to_categories,
            order_approvers,
            permissions,
            campaigns,
            budget_entities,
            inventory_products,
            spend_limit_time_frames,
          },
        }) => {
          this.options.categoriesList = access_to_categories ?? []
          this.options.approversList = order_approvers ?? []
          this.options.permissionsLevels = permissions ?? []
          this.options.budgetEntitiesList = budget_entities ?? []
          this.options.inventoryItemsList = inventory_products ?? []
          this.options.spendLimitTimeFrames = spend_limit_time_frames ?? []
          this.options.campaignsList = campaigns ?? []
        })
        .catch((e) => (console.error(e)))
    },
    addNewUsers () {
      const { usersData, settings } = this

      const spendLimit = settings.setSpendLimit
        ? { amount: settings.spendLimit.limit, time_frame: settings.spendLimit.time }
        : null
      const orderApprover = settings.userOrdersRequireApproval ? settings.orderApprover : null

      this.loading = true
      Api.post('/customer/subaccounts/multiple', {
        users: usersData.map((user) => ({
          ...user,
          first_name: user.firstname,
          last_name: user.lastname,
        })),
        settings: {
          permission_level: settings.permissionLevel,
          access_to: settings.selectedCategories,
          order_approver: orderApprover,
          can_view_all_orders: false,
          send_welcome_email: settings.sendWelcomeEmail,
          spend_limit_enabled: settings.setSpendLimit,
          spend_limit: spendLimit,
          budget_entities: settings.selectedBudgetEntities.map((id) => ({
            id,
            time: null,
            limit: null,
          })),
          inventory_products: settings.selectedInventoryItems.map((id) => ({ id })) ?? [],
          campaigns: settings.selectedCampaigns?.map((val) => ({ id: val })) ?? [],
        }
      })
        .then(({ meta }) => {
          if (meta?.count_success) {
            this.$cgToast.successBold(`${meta.count_success} Users added successfully!`)
            this.$store.dispatch('users/getUsers')
            this.show = false
          }

          if (meta?.count_failed) {
            this.$cgToast.error(`${meta.count_failed} E-mail addresses already registered`)
          }
        })
        .catch((e) => {
          const error = e?.response?.data?.message || 'An unexpected error occurred, please try again later or contact our support'
          this.$cgToast.error(error)
        })
        .finally(() => (this.loading = false))
    },
  }
}
</script>

<style lang="scss" scoped>
.my-users-add-multiple-users-panel {
  &::v-deep > .v-navigation-drawer__content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 45px;
  }

  &__title {
    font-family: inherit;

    &::v-deep > b {
      font-family: 'Lato-Bold', sans-serif;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    & > form {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: #D3D2D2;
  }
}
</style>
