export default {
  showLoadMoreButton: state => !!state.links.next,
  loadMoreNumber: state => {
    const { currentPage, lastPage, perPage, total, to } = state.meta

    return currentPage !== lastPage - 1 ? perPage : (total - to)
  },
  numberOfInactiveAccounts: (state) => {
    return state.meta?.counts?.inactive ?? 0
  },
  numberOfSubAccounts: (state) => {
    return state.meta?.counts?.subaccount ?? 0
  },
  numberOfAdminAccounts: (state) => {
    return state.meta?.counts?.admin ?? 0
  },
  params: (state) => {
    const { filters, currentPage, show } = state

    return ({
      q: filters.search,
      sort_by: filters.sort,
      show_admins: show.admins,
      show_sub_accounts: show.subAccounts,
      show_inactive: show.inactiveUsers,
      page: currentPage,
    })
  }
}
