import formatDate from '@/utils/formatDate'

const initState = () => {
  const startDate = new Date();
  startDate.setYear(startDate.getFullYear() - 1)
  
  const defaultStartDate = formatDate(startDate)
  const defaultEndDate = formatDate(new Date())

  return ({
    transactions: [],
    currentPage: 1,
    loading: false,
    loadingAggregatedView: false,
    meta: {
      currentPage: null,
      from: null,
      lastPage: null,
      perPage: null,
      to: null,
      total: null,
    },
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
    aggregatedView: {
      products: [],
      campaigns: [],
      users: [],
      total: {},
    },
    defaultDateHelper: {
      start: defaultStartDate,
      end: defaultEndDate,
    },
    filters: {
      dateRange: {
        start: defaultStartDate,
        end: defaultEndDate,
      },
      types: [],
      userId: null,
      orderId: null,
      search: null,
      campaignId: null,
      productId: null,
    },
    filtersItems: {
      transactionTypes: [
        { text: 'In (order/refill)', value: 'in' },
        { text: 'Out (send gift)', value: 'out' },
        { text: 'Hold (send eGift)', value: 'hold' },
        { text: 'Hold release', value: 'hold_release' },
        { text: 'eGift redeem', value: 'egift_redeem' },
        { text: 'Audit', value: 'audit' },
      ],
      users: [],
    },
  })
}

export default () => ({ ...initState() })
