<template>
  <common-autocomplete
    v-model="modelValue"
    :items="selectedCountryStates"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-if="loading" #no-data>
      <common-loader style="padding: 40px 0" />
    </template>
  </common-autocomplete>
</template>

<script>
import Api from '@/axios/api'

import CommonAutocomplete from './Autocomplete.vue'

export default {
  name: 'CommonStateSelect',
  components: {
    CommonAutocomplete
  },
  data: () => {
    const statesList = localStorage.getItem('statesList')

    return ({
      countriesStates: statesList ? JSON.parse(statesList) : {},
      loading: false,
    })
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    country: {
      type: String,
      required: false,
      default: 'US',
    },
    prefetchCountries: {
      type: Array,
      required: false,
      default: () => (['US', 'CA']),
    },
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    selectedCountryStates () {
      const { country, getStatesListBasedOnCountyCode } = this
      const availableStates = getStatesListBasedOnCountyCode(country || 'US') || []

      this.$emit('availableStates', availableStates)
      return availableStates
    },
  },
  watch: {
    country: function (val) {
      if (val) {
        const statesList = this.getStatesListBasedOnCountyCode(val)

        if (!statesList || !statesList?.length) {
          this.loadStatesList([val])

          this.modelValue = null
        }
      }
    }
  },
  mounted () {
    const { prefetchCountries, getStatesListBasedOnCountyCode } = this

    const result = prefetchCountries.reduce((acc, countryCode) => {
      acc.push(getStatesListBasedOnCountyCode(countryCode))
      return acc
    }, [])

    if (result.some((states) => states === null)) {
      this.loadStatesList(this.prefetchCountries)
    }
  },
  methods: {
    getSavedStatesList () {
      const statesList = localStorage.getItem('statesList')

      return statesList ? JSON.parse(statesList) : {}
    },
    getStatesListBasedOnCountyCode (countyCode) {
      return this.countriesStates?.[countyCode] ?? null
    },
    loadStatesList (countriesCodeArray = []) {
      this.loading = true
      const currentStatesList = this.getSavedStatesList()

      Api.get('/shared/data/states', {
        params: { countries: countriesCodeArray?.join(','), },
      })
        .then(({ data }) => {
          const newCountriesList = { ...currentStatesList, ...data }

          this.countriesStates = newCountriesList || currentStatesList || {}
          localStorage.setItem('statesList', JSON.stringify(newCountriesList || currentStatesList))
        })
        .finally(() => (this.loading = false))
    }
  },
}
</script>
