// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  data: () => ({
    rules: {
      required: (v) => !!v || '',
      validEmail: (v) => !v || emailRegex.test(v) || '',
    },
  }),
  computed: {
    firstNameValidation  () {
      const { rules } = this
      return [rules.required]
    },
    lastNameValidation () {
      const { rules } = this
      return [rules.required]
    },
    emailValidation () {
      const { rules } = this
      return [rules.required, rules.validEmail]
    },
  },
}
