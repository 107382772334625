<template>
  <router-link
    class="create-campaign-source-collections-item"
    :to="{
      name: 'CreateCampaignSourceCollection',
      params: { ...$route.params, id: collectionId }
    }"
  >
    <div class="create-campaign-source-collections-item__head">
      <h2>
        {{ collectionTitle }}

        <span class="create-campaign-source-collections-item__view-full-collection">
          View full collection

          <icons-collection-arrow-right />
        </span>
      </h2>

      <h3>Budget per recipient from ${{ Math.round(collectionsMinPrice) }}</h3>
    </div>

    <div class="create-campaign-source-collections-item__images-wrapper">
      <div class="create-campaign-source-collections-item__images">
        <img
          v-for="(image, index) in collectionImages"
          :srcset="getImageSrcset(index)"
          sizes="(max-width: 1440px) 160px,
            (max-width: 1280px) 140px,
            (max-width: 860px) 120px,
            (max-width: 430px) 140px,
            (max-width: 360px) 120px,
            200px"
          :key="index"
          :src="image"
          alt=""
        >
      </div>
    </div>

    <div class="create-campaign-source-collections-item__view-fill-collections-alt">
      View full collection

      <icons-collection-arrow-right :width="15" :height="15" color="currentColor" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CreateCampaignSourceCollectionsItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    imageUrl: 'https://cf.corporategift.com/media/catalog/product',
  }),
  computed: {
    collectionId () { return this.item.id },
    collectionTitle () { return this.item.name },
    collectionImages () {
      return this.item.images.slice(0, 7).map((image) => this.imageUrl + image)
    },
    collectionThumbnails () { return this.item?.thumbnails ?? [] },
    collectionsMinPrice () {
      return Math.min(...this.item.collections.map((collection) => collection.min_price))
    },
  },
  methods: {
    getImageSrcset (index) {
      const { collectionThumbnails } = this
      const thumbnails = collectionThumbnails.at(index)

      if (!thumbnails?.length) { return undefined }

      return thumbnails.map((imgUrl) => {
        const [match] = imgUrl.match(/([0-9]{3}x[0-9]{3})/g)
        const [firstValue] = match?.split('x')

        return !firstValue ? undefined : `${imgUrl} ${firstValue}w`
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-source-collections-item {
  padding: 30px 50px 50px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: #000;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  background-color: #FAFAFA;
  border: 1px solid transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  transition: all ease-in-out 0.2s;

  &__head {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > h2,
    & > h3 {
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      font-style: normal;
      font-weight: 400;
    }

    & > h2 {
      font-family: 'Inter', 'Lato-Bold', sans-serif;
      font-weight: 600;
      font-size: 32px;
      line-height: normal;
      font-weight: 600;
      color: #000000;
    }

    & > h3 {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 16px;
      line-height: normal;
      color: #62646A;
    }
  }

  &__view-full-collection {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 13px 25px;
    font-family: 'Lato-Bold', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    border-radius: 50px;
    flex: 1 1 auto;
    white-space: nowrap;
    max-width: 207px;
    border: 2px solid #000000;
    transition: max-width ease-in-out 0.2s;

    & > svg {
      width: 0;
      margin: 0;
    }

    &:hover {
      max-width: 238px;

      & > svg {
        width: 22px;
        margin-left: 10px;
      }
    }
  }

  &__view-fill-collections-alt {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #000;
    align-items: center;
    flex-wrap: nowrap;
    display: none;
  }

  &__images-wrapper {
    overflow: hidden;
    position: relative;
  }

  &__images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    justify-content: space-between;
    overflow: hidden;
    width: calc(100% + (200px / 2));

    & > img {
      object-fit: contain;
      background-color: #fff;
      width: 200px;
      aspect-ratio: 1 / 1;
    }
  }

  &:hover {
    background: #fff;
    border: 1px solid #A7DAD8;
    box-shadow: 0px 0px 20px rgba(0, 126, 118, 0.4);
  }

  @media (max-width: 1440px) {
    &__images {
      gap: 30px;
      width: calc(100% + (160px / 2));

      & > img {
        width: 160px;
      }
    }
  }

  @media (max-width: 1280px) {
    &__images {
      gap: 24px;
      width: calc(100% + (140px / 2));

      & > img {
        width: 140px;
      }
    }
  }

  @media (max-width: 860px) {
    padding: 30px;

    &__head {
      gap: 8px;

      & > h2 {
        font-size: 28px;
        line-height: 34px;
        justify-content: flex-start;

        & > svg {
          display: unset;
        }
      }

      & > h4 {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &:has(.collection-item__badges) {
      padding: 20px 30px 30px;
    }

    &__images-arrow {
      height: 30px;
      width: 30px;
      right: 7px;

      & > svg {
        width: 16px;
        height: 16px;
      }
    }

    &__images {
      gap: 22px;
      width: calc(100% + (120px / 2));

      & > img {
        width: 120px;

        &:nth-last-child(-n + 2) {
          display: none;
        }
      }
    }

    &__view-full-collection {
      display: none;
    }

    &__badge {
      font-size: 12px;
      line-height: 17px;
    }
  }

  @media screen and (max-width: 430px) {
    padding: 15px;
    gap: 14px;

    &__head {
      gap: 8px;

      & > h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;

        & > svg {
          display: none;
        }
      }

      & > h4 {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &:has(.collection-item__badges) {
      padding: 15px;
    }

    &__images-arrow {
      display: none;
    }

    &__images {
      gap: 8px;
      width: 100%;
      flex-wrap: wrap;

      & > img {
        width: 140px;

        &:nth-last-child(-n + 3) {
          display: none;
        }
      }
    }

    &__view-fill-collections-alt {
      display: inline-flex;
    }

    &__badge {
      font-size: 10px;
      line-height: 14px;
    }
  }

  @media screen and (max-width: 360px) {
    &__images {
      max-height: 250px;

      & > img {
        max-width: 120px;
      }
    }
  }
}
</style>