<script>
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('hubspot')

export default {
  name: 'Hubspot',
  mounted () {
    this.openTab()
  },
  methods: {
    ...mapActions(['openTab'])
  },
}
</script>
