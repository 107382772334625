<template>
  <popper
    :options="{
      placement: 'right',
      modifiers: {
        offset: {
          offset: '0, 4px'
        },
        preventOverflow: {
          boundariesElement: 'offsetParent'
        }
      }
    }">
    <div class="popper pa-4 pl-7 text-left white customized-popper" style="max-width: 350px">
      <v-simple-table class="customized-table">
        <template v-slot:default>
          <tbody>
          <tr v-for="(item, i) in customized" :key="i">
            <td><span class="f14 text-capitalize">{{ parseOptionLabel(item) }}:</span></td>
            <td v-if="item.images">
              <div class="cl-width cl-height customized-list__image-wrapper">
                <div class="customized-list__image" v-for="(image, j) in item.images" :key="j">
                  <img :src="image" :alt="item.label">
                </div>
              </div>
            </td>
            <td v-if="!item.images" colspan="2"><span class="d-inline-block black1" v-html="item.value"></span></td>
            <td v-else><span class="d-inline-block black1" v-html="item.value"></span></td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="orders-table__product-status green2 mt-auto" slot="reference">
      Customized
      <img src="/images/check-circle-icon.svg" alt="Rounded yes">
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs'

export default {
  name: 'CustomizedPopup',
  components: {
    popper: Popper
  },
  props: {
    customized: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  methods: {
    parseOptionLabel ({ label }) {
      if (label === 'personalize_text_font') { return 'Font' }

      return label
    }
  },
}
</script>

<style lang="scss">
.customized-table {
  table {
    border: 0;
  }

  tbody {
    tr {
      background-color: #fff !important;

      &:hover {
        background-color: #fff !important;

        td:first-child {
          background-color: #fff !important;
        }
      }
    }

    td {
      background-color: #fff !important;
    }
  }
}

.customized-list {
  &__item {
    min-height: 60px;
  }

  &__title {
    min-width: 95px;
    margin-right: 10px;

    span {
      color: #9b9b9b;
    }
  }

  &__image-wrapper {
    display: flex;
  }

  &__image {
    position: relative;
    width: 50px;
    height: 50px;

    &:not(:last-child) {
      margin-right: 4px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .cl-width {
    width: 50px;
  }

  .cl-height {
    height: 50px;
  }
}

.orders-table__product-status {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  height: 22px;
}

.customized-popper {
  &.popper[x-placement^="right"] .popper__arrow {
    left: -7px !important;
    transform: rotate(135deg);
  }
}
</style>
