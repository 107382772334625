<template>
  <div class="campaigns-tags-combobox__wrapper">
    <label for="campaign-tags">Tags associated with this campaign:</label>

    <v-combobox
      v-model="selectedTags"
      multiple
      small-chips
      clearable
      id="campaign-tags"
      :items="campaignsTags"
      append-icon="mdi-chevron-down"
      color="#62646A"
      ref="tags-select"
      outlined
      dense
      item-color="#62646A"
      item-value="id"
      item-text="name"
      hide-details
      class="campaigns-tags-combobox rounded-0"
      @input="handleInputChange"
      :menu-props="{
        bottom: true,
        offsetY: true,
        left: true,
        tile: true,
        contentClass: 'campaigns-tags-combobox__select'
      }"
    >
      <template #selection="{ attrs, item, parent, selected }">
        <v-chip
          class="my-1"
          v-if="item === Object(item)"
          v-bind="attrs"
          :input-value="selected"
          small
        >
          <span class="pr-2">
            {{ item.name }}
          </span>

          <div class="d-flex align-center pointer" @click="parent.selectItem(item)">
            <icons-close :width="10" :height="11" />
          </div>
        </v-chip>
      </template>

      <template #prepend-item v-if="adminAccount">
        <div
          class="px-4 py-2 campaigns-tags-combobox__new-tag"
          :class="{ 'campaigns-tags-combobox__new-tag--active': addNewTag }"
        >
          <div class="d-flex align-center" @click="addNewTag = !addNewTag">
            <icons-plus-circle :width="28" :height="28" />

            <template v-if="!addNewTag">
              Add new tag
            </template>
          </div>

          <div class="d-flex flex-grow-1 align-center" v-if="addNewTag">
            <common-input v-model="newTagName" :height="30" :error="showNewTagError" />
            <common-button :height="30" :loading="loadingNewTag" @click="sendNewTag()">
              Add tag
            </common-button>
          </div>
        </div>
      </template>

      <template #item="{ item, attrs }">
        <div class="campaigns-tags-combobox__select--item">
          <div
            class="campaigns-tags-combobox__select--item--icon"
            :class="{
              'campaigns-tags-combobox__select--item--icon--selected': attrs['aria-selected'] === 'true'
            }"
          />
          <div class="campaigns-tags-combobox__select--item--name">
            {{ item.name }}
          </div>
        </div>
      </template>

      <template #no-data>
        <div class="gray3 f14 px-4 py-2">
          No data
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import Api from '@/axios/api'
import { createNamespacedHelpers } from 'vuex'

import adminAccount from '@/mixins/adminAccount'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'TheAssignCampaignTagsCombobox.vue',
  mixins: [adminAccount],
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loadingNewTag: false,
    addNewTag: false,
    newTagName: null,
    showNewTagError: false,
  }),
  computed: {
    ...mapState(['campaignsTags']),
    selectedTags: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    },
  },
  methods: {
    ...mapMutations(['addCampaignTag']),
    // it checks if the user types a new value and removed everything that is not an object
    handleInputChange (newValue) {
      this.selectedTags = newValue.filter((item) => typeof item === 'object')
    },
    sendNewTag () {
      this.loadingNewTag = true
      this.showNewTagError = false

      Api.post('/campaigns/tags', { name: this.newTagName })
        .then(({ data, success }) => {
          if (success) {
            // check if tag from data object exist in tags
            if (!this.campaignsTags.some((item) => item.id === data.id)) { this.addCampaignTag(data) }
            if (!this.selectedTags.some((item) => item.id === data.id)) { this.selectedTags.push(data) }

            this.addNewTag = false
            this.newTagName = null
            this.$refs['tags-select'].isMenuActive = false
          } else {
            this.showNewTagError = true
          }
        })
        .catch(() => (this.showNewTagError = true))
        .finally(() => (this.loadingNewTag = false))
    },
  },
}
</script>

<style lang="scss">
.campaigns-tags-combobox {
  background-color: #fff;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 24px;

    & > label {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
    }
  }

  .v-input__slot {
    padding-left: 0 !important;
    min-height: 40px !important;
  }

  .v-select__selections {
    padding: 0 !important;

    & > .v-chip {
      font-family: 'Lato', sans-serif !important;
      font-weight: 400;
      background: #EDFCFB;
      line-height: 14px;
      font-size: 12px;
      color: #62646A;
    }
  }

  fieldset {
    border-width: 0 !important;
    padding-left: 0 !important;
  }

  &__new-tag {
    color: #42B77A;
    display: flex;
    height: 54px;
    align-items: center;
    cursor: pointer;

    & > div > svg {
      transition: all ease-in-out 0.3s;
      margin-right: 6px;
    }

    &--active {
      cursor: default;

      & > div > svg {
        cursor: pointer;
        transform: rotate(45deg);
      }
    }
  }

  &__select {
    .v-list {
      padding: 0 !important;
    }

    .v-list-item {
      padding: 0 !important;
    }

    &--item {
      cursor: pointer;
      display: flex;
      flex: 1 1 auto;
      padding: 0px 16px;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: #F5F5F5;
      }

      &--name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 155px;

        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 37px;
        color: #000;
      }

      &--icon {
        display: flex;
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
        border: 1px solid #DADADA;
        background: #fff;
        position: relative;

        &--selected {
          border: none;
          background-color: #42B77A;

          &::after {
            position: absolute;
            left: 50%;
            top: 48%;
            transform: translate(-50%,-50%) rotate(45deg);
            content: ' ';
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }
  }
}
</style>
