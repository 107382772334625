<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M3.33317 15.9999V6.83325H16.6665V9.57492C17.2665 9.75825 17.8332 10.0249 18.3332
        10.3833V6.83325C18.3332 5.90825 17.5915 5.16659 16.6665 5.16659H13.3332V3.49992C13.3332
        2.57492 12.5915 1.83325 11.6665 1.83325H8.33317C7.40817 1.83325 6.6665 2.57492 6.6665
        3.49992V5.16659H3.33317C2.40817 5.16659 1.67484 5.90825 1.67484 6.83325L1.6665 15.9999C1.6665
        16.9249 2.40817 17.6666 3.33317 17.6666H9.73317C9.48317 17.1499 9.3165 16.5916 9.23317
        15.9999H3.33317ZM8.33317 3.49992H11.6665V5.16659H8.33317V3.49992Z"
      fill="#95979D"
    />
    <path
      d="M15.0002 11C12.7002 11 10.8335 12.8667 10.8335 15.1667C10.8335 17.4667 12.7002 19.3333
        15.0002 19.3333C17.3002 19.3333 19.1668 17.4667 19.1668 15.1667C19.1668 12.8667 17.3002 11
        15.0002 11ZM16.3752 17.125L14.5835 15.3333V12.6667H15.4168V14.9917L16.9585 16.5333L16.3752
        17.125Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'History',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 21
    }
  }
}
</script>
