<template>
  <div class="green-text" @click="$emit('onClick')">
    <div class="d-flex">
      <div
        class="green-text__plus"
        :class="isOpen && 'green-text__plus--open'"
      />
    </div>
    <div class="d-flex align-center">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GreenTextWithPlus',
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.green-text {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-family: 'Lato-Regular', sans-serif !important;
  font-size: 15px;
  line-height: 18px;
  color: #219358;
  cursor: pointer;

  &__plus {
    position: relative;
    width: 20px;
    height: 20px;
    transition: all ease-in-out 0.3s;

    &::after {
      content: ' ';
      position: absolute;
      display: block;
      background-color: #219358;
      height: 10px;
      width: 1px;
      margin-top: -5px;
      top: 50%;
      left: 50%;
      right: 5px;
      transition: all ease-in-out 0.3s;
    }

    &::before {
      content: ' ';
      position: absolute;
      display: block;
      background-color: #219358;
      width: 10px;
      height: 1px;
      margin-left: -5px;
      left: 50%;
      top: 50%;
      bottom: 5px;
    }

    &--open {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
