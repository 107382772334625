<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    width="900"
  >
    <v-container v-if="show">
      <v-row dense>
        <v-col cols="11">
          <v-card class="pa-0 rounded-0 elevation-0 transparent">
            <iframe
              width="100%"
              height="540"
              src="https://www.youtube.com/embed/dybrrobH0gs?autoplay=1"
              title="Corporategift address book guide"
              autoplay="1"
              allow="autoplay"
              frameborder="0"
              allowfullscreen
            />
          </v-card>
        </v-col>
        <v-col cols="1" class="text-center">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'TheAddressBookGuideModal',
  mixins: [panelVModel]
}
</script>

<style lang="scss" scoped>
</style>
