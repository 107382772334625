<template>
  <cg-alert type="warning">Test method</cg-alert>
</template>

<script>
import { CgAlert } from '@corporategift/design-system'

export default {
  name: 'TestMethod',
  components: { CgAlert },
  methods: {
    validate () { return true },
  },
}
</script>
