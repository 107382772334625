<template>
  <div class="common-filter-select">
    <div
      class="common-filter-select__activator"
      :class="{ 'common-filter-select__activator--is-active': selectedItemsCount > 0 }"
      @click="showPanel = true"
    >
      <icons-settings-line color="#222325" />

      <span>
        <slot>Filters</slot>
      </span>

      <cg-badge v-if="selectedItemsCount > 0" color="#6E50FF">{{ selectedItemsCount }}</cg-badge>
    </div>

    <v-navigation-drawer
      v-model="showPanel"
      v-lock-scroll="showPanel"
      app
      temporary
      right
      width="100%"
      class="common-filter-select__panel"
    >
      <div class="common-filter-select__panel-header">
        <div @click="showPanel = false">
          <icons-back color="#62646A" />
        </div>

        <span>
          <slot name="panel-header">Filters</slot>

          <cg-badge color="#6E50FF">{{ selectedItemsCount }}</cg-badge>
        </span>

        <div />
      </div>

      <div class="common-filter-select__items">
        <div
          class="common-filter-select__item"
          :class="{ 'common-filter-select__item--is-selected': isItemSelected(item) }"
          v-for="item in items"
          :key="item"
          @click="selectItem(item)"
        >
          <div
            class="common-filter-select__item-icon"
            :class="{ 'common-filter-select__item-icon---active': isItemSelected(item) }"
          />

          {{ item }}
        </div>
      </div>

      <div class="common-filter-select__panel-actions">
        <cg-button @click="showPanel = false">Apply</cg-button>

        <span role="button" @click="modelValue = []">
          Clear
        </span>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { CgBadge, CgButton } from '@corporategift/design-system'

export default {
  name: 'CommonFilterSelect',
  components: {
    CgBadge,
    CgButton,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    lazyValue: [],
    showPanel: false,
  }),
  computed: {
    modelValue: {
      get () { return this.lazyValue },
      set (val) {
        this.lazyValue = val
        this.$emit('input', this.lazyValue)
      }
    },
    selectedItemsCount () {
      return this.lazyValue?.length ?? 0
    }
  },
  watch: {
    value (val) {
      this.lazyValue = val
    },
  },
  mounted () {
    this.lazyValue = this.value
  },
  methods: {
    isItemSelected (item) {
      return this.modelValue?.includes(item) ?? false
    },
    selectItem (item) {
      const selectedItems = structuredClone(this.lazyValue ?? [])

      if (selectedItems.includes(item)) {
        this.modelValue = this.modelValue.filter((selected) => selected !== item) ?? []
      } else {
        selectedItems.push(item)
        this.modelValue = selectedItems
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.common-filter-select {
  & .cg-badge {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }

  &__panel {
    &::v-deep > .v-navigation-drawer__content {
      display: flex;
      flex-direction: column;
    }
  }

  &__panel-header {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    & > span {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 10px 0;
      gap: 8px;
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      line-height: normal;
      font-weight: 700;
    }

    & > div {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__panel-actions {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid #D3D2D2;
    padding: 0 20px 20px;
    min-height: 90px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    & > button {
      border-radius: 100px;
    }

    & > span {
      cursor: pointer;
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #7B61FF;
    }
  }

  &__activator {
    padding: 4px 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    border-radius: 28px;
    background-color: #fff;
    border: 1px solid #D3D2D2;

    // REMOVE?
    transition: border ease-in-out .2s;

    & > span {
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      line-height: 34px;
      font-size: 14px;
      color: #222325;
    }

    &--is-active {
      border: 2px solid #222325;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    border-radius: 4px;
    padding: 4px 10px 4px 14px;
    background-color: #FFFFFF;
    display: flex;
    gap: 8px;
    align-items: center;
    height: 60px;
    width: 100%;
    cursor: pointer;
    text-transform: capitalize;
    line-height: normal;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 15px;

    &--is-selected {
      background-color: #F6F5FF;
    }
  }

  &__item-icon {
    display: flex;
    width: 22px;
    aspect-ratio: 1 / 1;
    min-width: 22px;
    border-radius: 50%;
    border: 1px solid #DADADA;
    background: #fff;
    position: relative;

    &---active {
      background-color: #6E50FF;
      border-color: #6E50FF;

      &::after {
        position: absolute;
        left: 50%;
        top: 44%;
        transform: translate(-50%,-50%) rotate(45deg);
        border-radius: 1px;
        content: '';
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
      }
    }
  }
}
</style>
