<template>
  <div class="my-users-set-spend-limit__wrapper">
    <common-checkbox
      v-model="showForm"
      bold-text
      center-content
    >
      Set spend limit
    </common-checkbox>

    <v-expand-transition>
      <div v-if="showForm" class="my-users-set-spend-limit__form">
        <common-input
          v-model.number="formLimit"
          @input="budgetLimitHandler"
          @blur="budgetLimitHandler"
          :rules="[validation.required]"
          type="number"
          placeholder="0.00"
          prefix="$"
          :min="0"
          :step="1"
          :height="40"
          style="max-width: 90px"
        />

        <common-select
          v-model="formTime"
          :items="timeList"
          :rules="[validation.required]"
          placeholder="Time frame"
          item-text="value"
          item-value="key"
          :height="40"
        />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'MyUsersSetSpendLimit',
  data: () => ({
    validation: { required: v => !!v || '' },
  }),
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    limit: {
      type: [String, Number],
      required: false,
      default: null,
    },
    time: {
      type: String,
      required: false,
      default: null
    },
    timeList: {
      type: Array,
      required: true
    }
  },
  computed: {
    showForm: {
      get () { return this.show },
      set (val) { this.$emit('update:show', val) },
    },
    formLimit: {
      get () { return this.limit },
      set (val) { this.$emit('update:limit', val) },
    },
    formTime: {
      get () { return this.time },
      set (val) { this.$emit('update:time', val) },
    },
  },
  methods: {
    budgetLimitHandler () {
      const { formLimit } = this

      if (typeof formLimit === 'number') {
        if (formLimit < 0) { this.$nextTick(() => { this.formLimit = 0 }) }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-set-spend-limit {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &::v-deep .common-checkbox__content--bold {
      font-size: 20px;
      line-height: 24px;
      color: #222325 !important;
    }
  }

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }
}
</style>
