<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 22"
    fill="none"
    :width="width"
    :height="height"
  >
    <mask
      id="mask0_3947_73720"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="22"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.770752 0.443115H17.1937V21.6143H0.770752V0.443115Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3947_73720)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.54151 11.6694C1.54151 11.1595 1.96235 10.7447 2.47953 10.7447H15.4826C15.9967 10.7447
          16.4176 11.1573 16.4203 11.6644L16.423 12.7195C16.423 13.5693 15.7185 14.2619 14.8521
          14.2619C13.9862 14.2619 13.2817 13.5693 13.2817 12.7183C13.2817 12.5082 13.1091 12.3384
          12.8963 12.3384C12.6836 12.3384 12.511 12.5082 12.511 12.7183C12.511 13.5693 11.8065
          14.2619 10.9402 14.2619C10.0742 14.2619 9.36935 13.5693 9.36935 12.7183C9.36935 12.5082
          9.19708 12.3384 8.98397 12.3384C8.77124 12.3384 8.59859 12.5082 8.59859 12.7183C8.59859
          13.5693 7.89411 14.2619 7.02817 14.2619C6.16183 14.2619 5.45736 13.5693 5.45736
          12.7183C5.45736 12.5082 5.28471 12.3384 5.07198 12.3384C4.85925 12.3384 4.6866 12.5082
          4.6866 12.7183C4.6866 13.5693 3.98212 14.2619 3.11579 14.2619C2.24984 14.2619 1.54537
          13.5693 1.54537 12.7183C1.54537 12.7001 1.54382 12.6822 1.54151 12.6648V11.6694ZM3.46263
          14.994C4.13396 14.8956 4.71357 14.518 5.07198 13.9831C5.49089 14.6084 6.21155 15.0217
          7.02817 15.0217C7.84479 15.0217 8.56506 14.6084 8.98397 13.9831C9.40326 14.6084 10.1235
          15.0217 10.9402 15.0217C11.7568 15.0217 12.4771 14.6084 12.8963 13.9831C13.254 14.5168
          13.832 14.8937 14.5015 14.9932L13.7677 20.8546H4.18946L3.46263 14.994ZM14.8155
          9.98484H3.14893C3.29615 8.74176 4.33166 7.7745 5.58607 7.7745H12.3788C13.6328 7.7745
          14.6683 8.74176 14.8155 9.98484ZM8.98243 5.1569C8.14268 5.1569 7.45979 4.48369 7.45979
          3.65586C7.45979 3.09624 8.30763 2.04692 8.98243 1.35928C9.65723 2.04692 10.5051 3.09624
          10.5051 3.65586C10.5051 4.48369 9.82178 5.1569 8.98243 5.1569ZM0.770752
          11.6694V12.7183C0.770752 12.7381 0.772293 12.7575 0.775377 12.7765C0.803124 13.8755
          1.61782 14.7847 2.68455 14.9811L3.46571 21.2804C3.48922 21.4712 3.65339 21.6144 3.84801
          21.6144H14.1084C14.303 21.6144 14.4668 21.4715 14.4907 21.2808L15.2795 14.9815C16.3671
          14.7832 17.1937 13.8448 17.1937 12.7183L17.191 11.6614C17.186 10.7735 16.4773 10.0525
          15.5917 9.99586C15.4449 8.32728 14.061 7.01468 12.3788 7.01468H9.36781V5.88253C10.4488
          5.70093 11.2758 4.7728 11.2758 3.65586C11.2758 2.488 9.45567 0.743437 9.24795
          0.548541C9.09958 0.407973 8.86489 0.407973 8.71652 0.548541C8.5088 0.743437 6.68903 2.488
          6.68903 3.65586C6.68903 4.7728 7.51567 5.70093 8.59705 5.88253V7.01468H5.58607C3.90389
          7.01468 2.51999 8.32728 2.37316 9.99548C1.48139 10.0509 0.770752 10.7766 0.770752 11.6694Z
        "
        fill="#62646A"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Campaigns',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 18
    },
    height: {
      type: [Number, String],
      required: false,
      default: 22
    },
  },
}
</script>
