<template>
  <div class="order-item-details-recipients" v-if="availableRecipients.length">
    <div v-for="(recipient, index) in availableRecipients" :key="index">
      {{ recipient.first_name }} {{ recipient.last_name }}

      <template v-if="recipient.region">
        ({{ recipient.region }})
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMyOrdersTableDetailsRecipients',
  props: {
    recipients: {
      type: Array,
      required: true,
    }
  },
  computed: {
    availableRecipients () {
      return this.recipients.filter((recipient) => recipient.first_name || recipient.last_name)
    }
  }
}
</script>

<style lang="scss">
.order-item-details-recipients {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 14px;
  background-color: #fff;
  max-height: 300px;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  color: #16181F;
  overflow-y: auto;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
</style>
