export default {
  data: () => ({
    standardTooltipProps: {
      placement: 'top',
      trigger: 'hover',
      classes: 'v-tooltip-content',
      targetClasses: 'pointer',
      offset: 6,
      boundariesElement: 'div',
    },
  }),
}
