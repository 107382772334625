<template>
  <div class="orders-table-empty">
    <icons-empty-recent-orders color="#D8F1E4" />

    <slot />
  </div>
</template>

<script>
export default {
  name: 'OrdersTableEmpty'
}
</script>

<style lang="scss" scoped>
.orders-table-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  gap: 30px;

  font-family: 'Lato-Italic', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: #95979D;
}
</style>