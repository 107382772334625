<template>
  <v-col cols="12">
    <v-row no-gutters class="success-area-single-link">
      <v-col cols="12" class="success-area-single-link__title">
        <div class="success-area-single-link__check" />

        Single link for {{ recipientsCount }} redeems created successfully
      </v-col>
      <v-col cols="12">
        <ul>
          <li v-for="(feature, index) in linkTypeFeatures" :key="index">
            {{ feature }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12" class="success-area-single-link__info">
        You will receive an order confirmation email with your order details and a link to track its progress. 
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>

      <v-col cols="12">
        <label for="egift-link">Here’s your gift-link</label>
        <common-input
          v-model="egiftLink"
          id="egift-link"
          disabled
          style="max-width: 500px"
          @click.native="copyLink()"
        />
      </v-col>

      <v-col cols="12" style="display: flex; flex-direction: row; gap: 14px;">
        <common-button outlined height="44" style="min-width: 240px" @click="showQrCode = true">
          Generate QR code
        </common-button>

        <common-button height="44" @click="copyLink()">
          Copy link

          <icons-copy color="#fff" :width="20" :height="20" />
        </common-button>
      </v-col>

      <v-col cols="12" v-if="showQrCode">
        <common-image-hover-tooltip>
          <qr-code :text="egiftLink" :size="200" error-level="L" />
        </common-image-hover-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import QrCode from 'vue-qrcode-component'

export default {
  name: 'TheSuccessAreaSingleLink',
  components: {
    QrCode,
  },
  props: {
    response: {
      type: Object,
      required: true
    },
    linkType: {
      type: String,
      required: true
    },
  },
  data: () => ({
    showQrCode: false
  }),
  computed: {
    egiftLink () {
      return this.response?.url || ''
    },
    linkTypeFeatures () {
      const { linkType } = this

      const result = [
        'Recipients must validate their emails to redeem',
        'Each email address is eligible for 1 gift redemption',
      ]

      if (linkType === 'single_link_unrestricted') result.splice(0, 1)

      return result
    },
    recipientsCount () {
      return this.response?.number_of_recipients || 0
    }
  },
  methods: {
    copyLink () {
      navigator.clipboard.writeText(this.egiftLink);
      this.$cgToast.success('Link copied to clipboard')
    },
  },
}
</script>

<style lang="scss">
.success-area-single-link {
  #egift-link .v-input__control > .v-input__slot {
    cursor: pointer;
  }

  & input[disabled] {
    color: #4A4A4A;
    cursor: pointer;
  }

  button > span {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-family: 'Lato-Bold', sans-serif;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }
}
</style>

<style lang="scss" scoped>
.success-area-single-link {
  gap: 24px;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  &__check {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #42B77A;

    &::before {
      content: '';
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      width: 12px;
      height: 8px;
      transform: rotate(-45deg) translateX(1px) translateY(-1px);
    }
  }
  
  &__info {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }

  & ul  {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    margin: 0;

    & > li {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      font-family: 'Lato-Regular', sans-serif;
      font-size: 15px;
      line-height: 22px;
      color: #000000;

      &::before {
        content:'·';
        display: inline-flex;
        font-size: 40px;
        margin-bottom: 2px;
      }
    }
  }

  label {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
    color: #222325;
  }
}
</style>
