import { render, staticRenderFns } from "./RecipientsBulkOptionsCard.vue?vue&type=template&id=232c19ff&scoped=true"
import script from "./RecipientsBulkOptionsCard.vue?vue&type=script&lang=ts"
export * from "./RecipientsBulkOptionsCard.vue?vue&type=script&lang=ts"
import style0 from "./RecipientsBulkOptionsCard.vue?vue&type=style&index=0&id=232c19ff&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232c19ff",
  null
  
)

export default component.exports