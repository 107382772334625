<template>
  <div class="inventory-card-send-button__wrapper">
    <common-button
      block
      :height="44"
      elevation="0"
      :disabled="disableButton"
      class="inventory-card-send-button__button"
      :class="{ 'inventory-card-send-button__button--active': isActive }"
      @click="handleButtonClick()"
    >
      Send gift

      <icons-arrow-down :width="10" :height="6" />
    </common-button>

    <v-expand-transition>
      <div v-show="isActive" class="inventory-card-send-button__menu elevation-3">
        <div
          class="inventory-card-send-button__menu-item"
          :aria-disabled="addingToCart ? 'true' : 'false'"
          @click="handleAddToCart"
        >
          <icons-add-to-cart-alt :width="23" :height="18" />

          Add to cart

          <v-progress-circular
            v-if="addingToCart"
            indeterminate
            color="#919191"
            :width="2"
            :size="16"
          />
        </div>
        <div class="inventory-card-send-button__menu-item" @click="handleSendAsEGift">
          <icons-egift :width="23" :height="16" />

          Send with eGift
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import sendInventoryActions from './inventoryStatus/mixins/sendInventoryActions'

export default {
  name: 'InventoryCardSendButton',
  mixins: [sendInventoryActions],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    disableButton () {
      const { qty, balance, auto_refill: { refill_strategy } } = this.item?.product ?? {}
    
      if (refill_strategy === 'back_order') { return false }
      // super admins and master account should have always access to inventory products
      // for super admins and master account the balance is always a null
      if (balance !== null && balance < 1) { return true }
      return qty < 1
    },
    itemWithVariants () {
      const variants = this.item?.product?.variations?.short || []

      return variants.length > 1
    },
  },
  mounted () {
    document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    clickHandler (event) {
      const { target } = event
      const { $el } = this

      if (!$el.contains(target)) {
        this.isActive = false
      }
    },
    handleButtonClick () {
      this.isActive = !this.isActive
    },
    handleAddToCart () {
      this.addItemToCart(this.item)
        .finally(() => {
          this.isActive = false
        })
    },
    handleSendAsEGift () {
      this.sendItemAsEGift(this.item)

      this.isActive = false
    },
  },
}
</script>

<style lang="scss" scoped>
.inventory-card-send-button {
  &__wrapper {
    display: flex;
    width: 100%;
  }

  &__button {
    font-size: 18px;
    letter-spacing: 0;
    text-transform: unset;
    font-weight: 600 !important;

    &::v-deep > .v-btn__content {
      flex-direction: row;
      gap: 10px;

      & > svg {
        transition: all ease-in-out 0.28s;
      }
    }

    &--active {
      &::v-deep > .v-btn__content > svg {
        transform: rotate(180deg) translate(0, 1px);
      }
    }
  }

  &__menu {
    position: absolute;
    width: calc(100% - 48px);
    top: calc(100% - 24px);
    border: 1px solid #e4e4e4;
    background-color: #fafafa;
    border-top: none;
    z-index: 4;
  }

  &__menu-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    align-items: center;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 36px;
    cursor: pointer;
    color: #62646A;
    padding: 0 16px;
    background-color: #fff;
    transition: background-color ease-in-out 0.2s;

    &[aria-disabled="true"] {
      pointer-events:  none;
    }

    &:hover {
      background-color: #EDFCFB;
    }
  }
}
</style>
