const states = {
  Arizona: 'AZ',
  Alabama: 'AL',
  Alaska: 'AK',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY'
}

const defaultDateOption = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
}

export const priceFilter = (num, options = { style: 'currency', currency: 'USD' }) =>
  new Intl.NumberFormat('en-US', options).format(num)
export const numberWithSeparator = (x, sep = ',') => (x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep))
export const convertRegionToAbbr = (input) => (states[input] || '')
// used usually in the date table
export const date = (date, options = defaultDateOption) => {
  if (date === null || date.includes('70') || date.includes('N/A')) return 'N/A'
  if (date === 'Multiple') return 'Multiple'
  return new Date(date).toLocaleString('en-EN', options)
}

export const dateFilter = (date) => {
  let userDate = date
  try {
    // try conver user date to JS Date object
    userDate = new Date(userDate)
  } catch (e) {
    // if there is problem with date, try to change european date to american date
    const dateArray = userDate.split('/')
    userDate = new Date(`${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`)
  } finally {
    // if there is another problem, return current date as
    if (userDate.getFullYear() === 1970) userDate = new Date()
  }

  return new Intl.DateTimeFormat('en', { dateStyle: 'medium' }).format(userDate)
}
