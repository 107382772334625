export default {
  setLoading (state, value = false) {
    state.loading = value
  },
  setUsers (state, users) {
    state.users = users
  },
  addUsers (state, newUsers) {
    state.users.push(...newUsers)
  },
  setSelectedIds (state, ids) {
    state.selectedIds = ids
  },
  addSelectedId (state, id) {
    state.selectedIds.push(id)
  },
  removeSelectedId (state, id) {
    const index = state.selectedIds.indexOf(id);
    state.selectedIds.splice(index, 1);
  },
  setMeta (state, meta) {
    state.meta = {
      counts: meta.counts,
      currentPage: meta.current_page,
      from: meta.from,
      lastPage: meta.last_page,
      perPage: meta.per_page,
      to: meta.to,
      total: meta.total
    }
  },
  setLinks (state, links) {
    state.links = links
  },
  setCurrentPage (state, value = 1) {
    state.currentPage = value
  },
  // set filters
  setFiltersSearch (state, data = null) {
    state.filters.search = data
  },
  setFiltersSort (state, data = 'firstname') {
    state.filters.sort = data
  },
  // set show
  setShowInactiveUsers (state, data = false) {
    state.show.inactiveUsers = data
  },
  setShowSubAccounts (state, data = false) {
    state.show.subAccounts = data
  },
  setShowAdmins (state, data = false) {
    state.show.admins = data
  },
}
