<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4 16"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06
        14C1.69779e-06 13.45 0.196002 12.979 0.588002 12.587C0.980002 12.195 1.45067 11.9993 2
        12C2.55 12 3.021 12.196 3.413 12.588C3.805 12.98 4.00067 13.4507 4 14C4 14.55 3.804 15.021
        3.412 15.413C3.02 15.805 2.54934 16.0007 2 16ZM2 10C1.45 10 0.979002 9.804 0.587002
        9.412C0.195002 9.02 -0.000664969 8.54934 1.69779e-06 8C1.69779e-06 7.45 0.196002 6.979
        0.588002 6.587C0.980002 6.195 1.45067 5.99934 2 6C2.55 6 3.021 6.196 3.413 6.588C3.805 6.98
        4.00067 7.45067 4 8C4 8.55 3.804 9.021 3.412 9.413C3.02 9.805 2.54934 10.0007 2 10ZM2 4C1.45
        4 0.979002 3.804 0.587002 3.412C0.195002 3.02 -0.000664969 2.54934 1.69779e-06 2C1.69779e-06
        1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2
        1.69779e-06C2.55 1.69779e-06 3.021 0.196001 3.413 0.588001C3.805 0.980001 4.00067 1.45067 4
        2C4 2.55 3.804 3.021 3.412 3.413C3.02 3.805 2.54934 4.00067 2 4Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'SettingsKebab',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 4
    },
    height: {
      type: [Number, String],
      required: false,
      default: 16
    }
  }
}
</script>
