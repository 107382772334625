<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    width="600"
  >
    <v-container>
      <v-row dense>
        <v-col cols="11">
          <v-card class="pa-8 rounded-0">
            <v-card-title class="pa-0 lato-light f30">
              Add new group
            </v-card-title>

            <v-card-text class="px-0 pt-8 pb-3">
              <common-input
                v-model="groupName"
                placeholder="Enter New Group"
                style="max-width: 276px"
                min="2"
                ref="group-name"
                :hideDetails="false"
                hint="Min 2 characters"
              />
            </v-card-text>

            <v-card-actions class="pa-0">
              <common-button
                height="40"
                width="130"
                :disabled="disableButton"
                @click="saveGroup()"
              >
                Save & Close
              </common-button>

              <common-button
                outlined
                class="ml-4"
                height="40"
                width="130"
                @click="show = false"
              >
                Cancel
              </common-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="1" class="text-center">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'TheAddNewGroupModal',
  mixins: [panelVModel],
  data: () => ({
    groupName: null
  }),
  watch: {
    show: function (val) {
      if (val) {
        setTimeout(() => {
          this.$refs['group-name']?.$children[0]?.focus()
        }, 0)
      } else {
        this.groupName = null
      }
    }
  },
  computed: {
    disableButton () {
      if (!this.groupName) return true

      return this.groupName?.length < 2
    }
  },
  methods: {
    saveGroup () {
      Api.post('/customer/address-book/groups', {
        name: this.groupName
      }).then(({ success, data }) => {
        if (success) {
          this.$emit('addGroup', data)
          this.$cgToast.successBold('Successfully created a new group')
          this.show = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.f30 {
  font-size: 30px !important;
}
</style>
