<template>
  <div
    id="mention-menu"
    class="mention-menu elevation-4"
    role="listbox"
  />
</template>

<script>
/* eslint-disable dot-notation */

const properties = [
  'direction',
  'boxSizing',
  'width',
  'height',
  'overflowX',
  'overflowY',
  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderStyle',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'fontStyle',
  'fontVariant',
  'fontWeight',
  'fontStretch',
  'fontSize',
  'fontSizeAdjust',
  'lineHeight',
  'fontFamily',
  'textAlign',
  'textTransform',
  'textIndent',
  'textDecoration',
  'letterSpacing',
  'wordSpacing',
  'tabSize',
  'MozTabSize'
]

export default {
  name: 'TheMentionMenu',
  props: {
    textAreaId: {
      type: String,
      required: true,
      default: 'textarea'
    },
    menuId: {
      type: String,
      required: false,
      default: 'mention-menu'
    }
  },
  data: () => ({
    mentionOptions: [
      { value: 'First_name' },
      { value: 'Last_name' }
    ]
  }),
  mounted () {
    const isFirefox = typeof window !== 'undefined' && window['mozInnerScreenX'] != null
    function getCaretCoordinates (element, position) {
      const div = document.createElement('div')
      document.body.appendChild(div)
      const style = div.style
      const computed = getComputedStyle(element)
      style.whiteSpace = 'pre-wrap'
      style.wordWrap = 'break-word'
      style.position = 'absolute'
      style.visibility = 'hidden'
      properties.forEach(prop => {
        style[prop] = computed[prop]
      })
      if (isFirefox) {
        if (element.scrollHeight > parseInt(computed.height)) {
          style.overflowY = 'scroll'
        }
      } else {
        style.overflow = 'hidden'
      }
      div.textContent = element.value.substring(0, position)
      const span = document.createElement('span')
      span.textContent = element.value.substring(position) || '.'
      div.appendChild(span)
      const coordinates = {
        top: span.offsetTop + parseInt(computed['borderTopWidth']),
        left: span.offsetLeft + parseInt(computed['borderLeftWidth']),
        height: span.offsetHeight
      }
      div.remove()
      return coordinates
    }
    class Mentionify {
      constructor (ref, menuRef, resolveFn, replaceFn, menuItemFn, emitChangeFn) {
        this.ref = ref
        this.menuRef = menuRef
        this.resolveFn = resolveFn
        this.replaceFn = replaceFn
        this.menuItemFn = menuItemFn
        this.emitChangeFn = emitChangeFn
        this.options = []

        this.makeOptions = this.makeOptions.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.onInput = this.onInput.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.renderMenu = this.renderMenu.bind(this)
        this.onClick = this.onClick.bind(this)
        this.ref.addEventListener('input', this.onInput)
        this.ref.addEventListener('keydown', this.onKeyDown)
        this.menuRef.addEventListener('click', this.onClick)
        document.addEventListener('show-mention-menu', this.onInput)
      }

      onClick (ev) {
        ev.preventDefault()
        const selectedOption = ev.target.innerText
        const mentionOptions = this.resolveFn('')
        const index = mentionOptions.findIndex(option => option.value === selectedOption)
        this.selectItem(index, false)()
      }

      async makeOptions (query) {
        const options = await this.resolveFn(query)
        if (options.lenght !== 0) {
          this.options = options
          this.renderMenu()
        } else {
          this.closeMenu()
        }
      }

      closeMenu () {
        setTimeout(() => {
          this.options = []
          this.left = undefined
          this.top = undefined
          this.triggerIdx = undefined
          this.renderMenu()
        }, 0)
      }

      selectItem (active, update = true) {
        return () => {
          if (update) {
            const preMention = this.ref.value.substr(0, this.triggerIdx)
            const option = this.options[active]
            const mention = this.replaceFn(option, this.ref.value[this.triggerIdx])
            const postMention = this.ref.value.substr(this.ref.selectionStart)
            const newValue = `${preMention}${mention}${postMention}`
            this.ref.value = newValue
            const caretPosition = this.ref.value.length - postMention.length
            this.ref.setSelectionRange(caretPosition, caretPosition)
            this.emitChangeFn(newValue)
            this.closeMenu()
            this.ref.focus()
          }
        }
      }

      onInput () {
        const positionIndex = this.ref.selectionStart
        const textBeforeCaret = this.ref.value.slice(0, positionIndex)
        const tokens = textBeforeCaret.split(/\s/)
        const lastToken = tokens[tokens.length - 1]
        const triggerIdx = textBeforeCaret.endsWith(lastToken)
          ? textBeforeCaret.length - lastToken.length
          : -1
        const maybeTrigger = textBeforeCaret[triggerIdx]
        const keystrokeTriggered = maybeTrigger === '@'

        if (!keystrokeTriggered) {
          this.closeMenu()
          return
        }
        const query = textBeforeCaret.slice(triggerIdx + 1)
        this.makeOptions(query)

        const coords = getCaretCoordinates(this.ref, positionIndex)
        const { top, left } = this.ref.getBoundingClientRect()

        setTimeout(() => {
          this.active = 0
          this.left = coords.left + left
          this.top = coords.top + top
          this.screenTopEdge = top
          this.triggerIdx = triggerIdx
          this.renderMenu()
        }, 0)
      }

      onKeyDown (ev) {
        let keyCaught = false
        if (this.triggerIdx !== undefined) {
          switch (ev.key) {
            case 'ArrowDown':
              this.active = Math.min(this.active + 1, this.options.length - 1)
              this.renderMenu()
              keyCaught = true
              break
            case 'ArrowUp':
              this.active = Math.max(this.active - 1, 0)
              this.renderMenu()
              keyCaught = true
              break
            case 'Escape':
              keyCaught = true
              this.closeMenu()
              this.ref.focus()
              break
            case 'Enter':
            case 'Tab':
              this.selectItem(this.active)()
              keyCaught = true
              break
          }
        }

        if (keyCaught) {
          ev.preventDefault()
        }
      }

      renderMenu () {
        if (this.top === undefined) {
          this.menuRef.hidden = true
          return
        }
        // TODO debug
        this.menuRef.style.marginLeft = `calc(${this.left}px - (100vw - 868px))`
        this.menuRef.style.marginTop = `calc(${this.top}px - (${this.screenTopEdge}px - (3 * 27px)))`
        this.menuRef.innerHTML = ''

        this.options.forEach((option, idx) => {
          this.menuRef.appendChild(this.menuItemFn(
            option,
            this.selectItem(idx),
            this.active === idx))
        })

        this.menuRef.hidden = false
      }
    }
    // eslint-disable-next-line
    new Mentionify(
      document.getElementById(this.textAreaId),
      document.getElementById(this.menuId),
      this.resolveFn,
      this.replaceFn,
      this.menuItemFn,
      this.emitChange
    )
  },
  methods: {
    resolveFn (prefix) {
      const { mentionOptions } = this
      return prefix === '' ? mentionOptions : mentionOptions?.filter(user => user?.value.startsWith(prefix))
    },
    replaceFn (mention = '@', trigger = '') {
      return `${trigger}${mention?.value} `
    },
    menuItemFn (mention, setItem, selected) {
      const div = document.createElement('div')
      div.setAttribute('role', 'option')
      div.className = 'mention-menu__item'
      if (selected) {
        div.classList.add('selected')
        div.setAttribute('aria-selected', '')
      }
      div.textContent = mention?.value
      div.onclick = setItem
      return div
    },
    emitChange (newValue) {
      this.$emit('changeInput', newValue)
    }
  }
}
</script>
<style lang="scss">
.mention-menu {
  background-color: #f3f3f3;
  position: absolute;

  &__item {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 37px;
    cursor: pointer;
    padding: 4px 1rem;
    color: #000;
    background-color: #fff;

    &:hover {
      background-color: #f2f2f2;
    }

    &.selected {
      background-color: #f2f2f2;
    }
  }
}
</style>
