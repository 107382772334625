import Api from '@/axios/api'

export default {
  getUsers ({ getters, commit }) {
    commit('setLoading',  true)
    commit('setCurrentPage', 1)

    Api.get('/customer/subaccounts', {
      params: getters.params,
    })
    .then(({ data, meta, links }) => {
      commit('setUsers', data)
      commit('setMeta', meta)
      commit('setLinks', links)
    })
    .catch((e) => (console.error(e)))
    .finally(() => {
      commit('setLoading',  false)
    })
  },
  async loadMoreUsers ({ state, getters, commit }) {
    commit('setCurrentPage', state.currentPage + 1)

    return Api.get('/customer/subaccounts', {
      params: {
        page: state.currentPage,
        ...getters.params
      }
    })
    .then(({ data, meta, links }) => {
      commit('addUsers', data)
      commit('setMeta', meta)
      commit('setLinks', links)
    })
    .catch((e) => (console.error(e)))
  },
}
