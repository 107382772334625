<template>
  <div class="success-area-link" @mouseleave="showPopUp = false">
    <icons-link class="mr-5" />
    <popper
      :options="{
        placement: 'bottom',
        modifiers: {
          offset: { offset: '0px, 24px' },
        },
      }"
    >
      <div
        class="popper pa-4 text-left d-flex align-center"
        style="min-width: 300px"
        :style="!showPopUp && 'opacity: 0 !important'"
      >
        <icons-check-circle
          :width="20"
          :height="20"
          filled
          :checkStrokeWidth="1.5"
          class="mr-2"
        />
        Link copied to clipboard
      </div>
      <div slot="reference">
        <span class="green2 pointer" @click="copyText()">Copy link</span> and send it to your recipient.
      </div>
    </popper>
    <popper
      :options="{
        placement: 'top',
        modifiers: { offset: { offset: '0, 5px' } },
      }"
    >
      <div class="popper pa-4 text-left" style="min-width: 300px; max-width: 500px;">
        {{ getTooltipTextBySlug('campaigns_copy_link') }}
      </div>
      <div class="d-flex pointer" slot="reference">
        <icons-question
          :width="20"
          :height="20"
        />
      </div>
    </popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'

import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

export default {
  name: 'TheSuccessAreaLink',
  components: {
    Popper
  },
  mixins: [getTooltipTextBySlug],
  props: {
    linkUrl: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showPopUp: false
  }),
  mounted () {
    const span = this.$el.querySelector('span')
    span.addEventListener('copy', (event) => {
      event.preventDefault()
      if (event.clipboardData) {
        event.clipboardData.setData('text/plain', this.linkUrl)
      }
    })
  },
  methods: {
    copyText () {
      document.execCommand('copy')
      this.showPopUp = true
    }
  }
}
</script>

<style lang="scss" scoped>
.success-area-link {
  background-color: #F6F6F6;
  padding: 15px;
  font-family: 'Lato-Regular', sans-serif !important;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  text-align: left;

  // popper with question icon
  span:last-child {
    margin-left: auto;
  }
}
</style>
