<template>
  <common-select
    v-model="selectedEntites"
    :items="items"
    :menu-z-index="4"
    menuContentClass="credits-info__select--menu"
    multiple
    class="credits-info__select"
    placeholder="All entites"
    style="max-width: 134px"
    disableAttach
  >
    <template #selection="{ index }">
      <div v-if="index === 0" class="credits-info__select--selected">
        <div>
          {{ selectedEntites.length }}
        </div>
        Entites
      </div>
    </template>

    <template #item="{ item, attrs }">
      <div class="credits-info__select--item">
        <div
          class="credits-info__select--item--icon"
          :class="{
            'credits-info__select--item--icon--selected': attrs['aria-selected'] === 'true'
          }"
        />
        <div class="credits-info__select--item--name">
          {{ item.text }}
        </div>
      </div>
    </template>

    <template #append-item>
      <div
        class="credits-info__select--clear"
        @click="selectedEntites = []"
      >
        Clear
      </div>
    </template>
  </common-select>
</template>

<script>
export default {
  name: 'TheCreditsInfoSelect',
  props: {
    value: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    selectedEntites: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.credits-info__select {
  .v-select__slot {
    padding: 0px;
  }

  &--selected {
    display: flex;
    align-items: baseline;
    gap: 4px;
    font-size: 13px;
    line-height: 16px;
    font-family: 'Lato-Regular', sans-serif;
    color: #62646a;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Lato-Bold', sans-serif;
      color: #fff;
      line-height: 18px;
      font-size: 14px;
      background: #219358;
      border-radius: 24px;
      min-width: 22px;
      min-height: 22px;
    }
  }

  &--clear {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #219358;
    cursor: pointer;
    padding: 14px 20px;
  }

  &--item {
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    gap: 8px;

    &:hover {
      background-color: #F5F5F5;
    }

    &--name {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 155px;

      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 37px;
      color: #000;
    }

    &--icon {
      display: flex;
      width: 26px;
      height: 26px;
      min-width: 26px;
      min-height: 26px;
      border-radius: 50%;
      border: 1px solid #DADADA;
      background: #fff;
      position: relative;

      &--selected {
        border: none;
        background-color: #42B77A;

        &::after {
          position: absolute;
          left: 50%;
          top: 48%;
          transform: translate(-50%,-50%) rotate(45deg);
          content: ' ';
          width: 6px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &--menu {
    .v-list {
      padding: 0;
      padding-top: 4px !important;

      > .v-list-item {
        padding: 2px 20px !important;
        max-height: unset !important;
        color: #16181F;

        &--active::before {
          opacity: 0 !important;
        }
      }
    }
  }
}
</style>
