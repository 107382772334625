<template>
  <div class="d-flex w-100">
    <common-button
      block
      elevation="0"
      :disabled="disabled"
      @click="onButtonClick"
      color="black darken-4 white--text"
    >
      Send gift

      <div
        v-if="showChevronIcon"
        class="btn__arrow"
        :class="active && 'btn__arrow--up'"
      />
    </common-button>

    <v-expand-transition>
      <div v-show="active" class="btn-menu elevation-3">
        <v-row no-gutters>
          <v-col
            cols="12"
            class="py-2 px-4 btn-menu__link"
            @click="showSendEGiftPanel()"
          >
            <v-img
              max-width="20"
              height="14"
              src="/images/e-gift-icon.svg"
              alt="gift icon"
            />

            <span>
              Send eGift
            </span>
          </v-col>

          <v-col
            cols="12"
            class="py-2 px-4 btn-menu__link"
            @click="showSendDirectPanel()"
          >
            <v-img
              max-width="22"
              height="14"
              src="/images/shipping icon@1x.svg"
              alt="shipping icon"
            />
            <span style="margin-left: -1px">
              Send to address
            </span>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import isPageInIframe from '@/components/myCampaign/panels/mixins/isPageInIframe'

export default {
  name: 'CampaignCardSendButton',
  mixins: [isPageInIframe],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    active: false
  }),
  computed: {
    showSendToAddressButton () {
      const { items, isPageInIframe } = this

      if (isPageInIframe) return false
      if (items?.length === 1) {
        return !['virtual', 'grouped'].includes(items[0]?.product?.type?.type_id)
      }
      return false
    },
    showChevronIcon () {
      const { showSendToAddressButton, disabled } = this
      return showSendToAddressButton && !disabled
    },
  },
  mounted () {
    document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    onButtonClick () {
      const { active, showChevronIcon } = this

      if (showChevronIcon) {
        this.active = !active
      } else {
        this.showSendEGiftPanel()
      }
    },
    clickHandler (event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.active = false
      }
    },
    showSendEGiftPanel () {
      this.$emit('showSendEGiftPanel')
      this.active = false
    },
    showSendDirectPanel () {
      this.$emit('showSendDirectPanel')
      this.active = false
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.theme--light.v-btn.v-btn--disabled, .v-btn--disabled {
  background: #C0C0C0 !important;
  color: #fff !important;
}

.btn__arrow {
  position: absolute;
  right: 5px;
  content: '';
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 12px;
  height: 12px;
  transform: rotate(45deg) translate(-5px);
  transition: all ease-in-out 0.3s;

  &--up {
    transform: rotate(-135deg) translateY(-5px);
  }
}
.btn-menu {
  position: absolute;
  width: calc(100% - 48px);
  top: calc(100% - 28px);
  border: 1px solid #e4e4e4;
  background-color: #fafafa;
  border-top: none;
  z-index: 4;

  &__link {
    cursor: pointer;

    &:hover {
      background-color: #f4fffe;
    }
  }

  .row > .col {
    display: flex;
    gap: 14px;
    align-items: center;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    line-height: 18px;
    color: #16181f;
  }

  .row > .col:not(:last-child) {
    border-bottom: 1px solid #e4e4e4;
  }
}
</style>
