<template>
  <div
    v-if="items.length">
    <common-loader v-if="loading" />
    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items="items"
      :search="search"
      item-key="id"
      calculate-widths
      hide-default-footer
      class="api_tokens_table"
    >
      <template v-slot:item.details="{item}">
        <div class="d-flex">
          <div class="mytooltip-wrapper">
            <v-img @click="$emit('destroy', item)"
              max-width="13px"
              height="17px"
              src="/images/delete-icon.png"/>
            <span>Delete</span>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'data-table',
  props: {
    search: String,
    items: Array,
    loading: Boolean
  },
  data: () => ({
    headers: [
      {
        text: 'NAME/DESCRIPTION',
        value: 'name',
        align: 'start',
        filterable: true,
        sortable: false
      },
      {
        text: 'LAST USED AT',
        value: 'last_used_at',
        filterable: false,
        sortable: true,
        width: '200'
      },
      {
        text: 'EXPIRES_AT',
        value: 'expires_at',
        filterable: false,
        sortable: true,
        width: '200'
      },
      {
        text: 'DETAILS',
        value: 'details',
        filterable: false,
        sortable: false,
        width: '80'
      }
    ]
  })
}
</script>

<style lang="scss">
.api_tokens_table {
}
</style>
