const savedFormState = JSON.parse(sessionStorage.getItem('createCampaignData') || null);

const formDefaultState = () => ({
  name: null,
  selectedProducts: [],
  greetingMessage: {
    title: null,
    content: null,
    footer: null,
    logo: null,
  },
  displayName: null,
  isActive: true,
  tags: [],
  advancedOptions: {
    expirationDays: 90,
    allowExchange: false,
    disableThankYouNotes: false,
    disableReminderEmails: false,
    emailSubjectLine: null,
    videoGreeting: null,
    successButton: {
      addButton: false,
      buttonURL: null,
      buttonLabel: null
    }
  },
})

const getFormDefaultState = () => {
  const savedFormState = JSON.parse(sessionStorage.getItem('createCampaignData') || null);

  return savedFormState || formDefaultState()
}

export default getFormDefaultState
