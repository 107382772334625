<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 20"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="My-Campaigns"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.4"
    >
      <g
        id="My-Campaigns_send_eGift_advanced"
        transform="translate(-678.000000, -1731.000000)"
        fill="#161616"
        fill-rule="nonzero"
      >
        <g id="name-copy-3" transform="translate(482.000000, 1584.000000)">
          <g id="calendar" transform="translate(196.218750, 147.000000)">
            <g
              id="noun_Calendar-Tasks-Manager_878575"
              transform="translate(0.883929, 1.714286)"
            >
              <path
                d="M15.0424018,16.2857143 L1.75224107,16.2857143 C0.786549107,16.2857143
                  -1.57017256e-15,15.5236786 -1.57017256e-15,14.5865714 L-1.57017256e-15,3.05696429
                  C-1.57017256e-15,2.11985714 0.786549107,1.35714286 1.75294085,1.35714286
                  L15.041702,1.35714286 C16.0080937,1.35714286 16.7946429,2.11985714
                  16.7946429,3.05696429 L16.7946429,14.5865714 C16.7946429,15.5236786
                  16.0087935,16.2857143 15.0424018,16.2857143 Z M1.75294085,2.71428571
                  C1.5584029,2.71428571 1.39955357,2.86832143 1.39955357,3.05696429
                  L1.39955357,14.5865714 C1.39955357,14.7752143 1.55770312,14.9285714
                  1.75224107,14.9285714 L15.0424018,14.9285714 C15.2369397,14.9285714
                  15.3950893,14.7752143 15.3950893,14.5865714 L15.3950893,3.05696429
                  C15.3950893,2.86832143 15.23624,2.71428571 15.041702,2.71428571
                  L1.75294085,2.71428571 Z M16.0948661,5.42857143 L0.699776786,5.42857143
                  C0.3135,5.42857143 2.06184276e-16,5.12457143 2.06184276e-16,4.75
                  C2.06184276e-16,4.37542857 0.3135,4.07142857 0.699776786,4.07142857
                  L16.0948661,4.07142857 C16.4818426,4.07142857 16.7946429,4.37542857
                  16.7946429,4.75 C16.7946429,5.12457143 16.4818426,5.42857143
                  16.0948661,5.42857143 Z M3.49888393,3.39285714 C3.11260714,3.39285714
                  2.79910714,3.08885714 2.79910714,2.71428571 L2.79910714,0.678571429
                  C2.79910714,0.304 3.11260714,0 3.49888393,0 C3.88516071,0 4.19866071,0.304
                  4.19866071,0.678571429 L4.19866071,2.71428571 C4.19866071,3.08885714
                  3.88516071,3.39285714 3.49888393,3.39285714 Z M13.2957589,3.39285714
                  C12.9087824,3.39285714 12.5959821,3.08885714 12.5959821,2.71428571
                  L12.5959821,0.678571429 C12.5959821,0.304 12.9087824,0 13.2957589,0 C13.6827355,0
                  13.9955357,0.304 13.9955357,0.678571429 L13.9955357,2.71428571
                  C13.9955357,3.08885714 13.6827355,3.39285714 13.2957589,3.39285714 Z
                "
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 17
    },
    height: {
      type: [Number, String],
      required: false,
      default: 17
    }
  }
}
</script>
