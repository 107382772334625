<template>
  <v-btn
    text
    v-if="visible"
    @click="clickHandler()"
    height="30"
    class="clear-filter-btn"
  >
    Clear filters
  </v-btn>
</template>

<script>
export default {
  name: 'clear-filter-button',
  props: {
    visible: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  methods: {
    clickHandler () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.clear-filter-btn {
  text-transform: none;
  color: #219358 !important;
  font-size: 14px !important;
  margin-left: 12px;
  letter-spacing: 0;

  &:hover {
    background: none !important;
  }
}
</style>
