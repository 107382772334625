<template>
  <v-popover
    :open.sync="showResults"
    placement="bottom-start"
    trigger="click"
    :disabled="!search"
    :style="`flex: 0 1 ${maxWidth}px`"
    popoverBaseClass="popover search-input-tooltip-popover"
    @show="updateInputWidth"
    @hide="hidePopper()"
  >
    <common-input
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      placeholder="Campaign name / creator..."
      style="width: 320px;"
      :height="height"
      filter-style
      clearable
      @focus="inFocusMode = true"
      @blur="inFocusMode = false"
      @click:clear="clearFilters"
      @keyup.delete="handleDeleteButton"
    />

    <div
      :style="`--min-width: ${inputWidth}px; --max-width: ${maxWidth}px`"
      class="search-input-tooltip"
      slot="popover"
    >
      <template v-if="!loading">
        <template v-if="campaigns.length || creators.length">
          <ul v-if="campaigns.length">
            <li>Campaigns</li>
            <li
              v-for="campaign in campaigns"
              :key="campaign.id"
              class="search-input-tooltip__item-campaign"
              :class="{ 'search-input-tooltip__item--selected': isCampaignSelected(campaign) }"
              @click="onCampaignClick(campaign)"
            >
              <icons-campaigns :height="22" :width="18" style="min-width: 18px" />
              {{ campaign.name }}
            </li>
          </ul>

          <ul v-if="creators.length">
            <li>Creators</li>

            <li
              v-for="creator in creators"
              :key="creator.id"
              class="search-input-tooltip__item-campaign"
              :class="{ 'search-input-tooltip__item--selected': isCreatorSelected(creator) }"
              @click="onCreatorClick(creator)"
            >
              <icons-user :width="22" :height="22" style="min-width: 22px" />
              {{ creator.name }}
            </li>
          </ul>
        </template>

        <div v-else class="search-input-tooltip__empty">
          No campaigns found
        </div>
      </template>

      <common-loader v-else />
    </div>
  </v-popover>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'

export default {
  name: 'TheCampaignSearchInput',
  props: {
    campaignId: {
      type: Number,
      required: false,
      default: null,
    },
    creatorId: {
      type: Number,
      required: false,
      default: null,
    },
    initialSearch: {
      type: String,
      required: false,
      default: null,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 320,
    },
    height: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data: () => ({
    search: null,
    inFocusMode: false,
    loading: false,
    showResults: false,
    creators: [],
    campaigns: [],
    inputWidth: 320,
  }),
  watch: {
    search: function (val) {
      this.updateInitialSearch()
      if (val?.length < 3) { return }

      if (val && val !== this.initialSearch) {
        this.updateInputWidth()
        this.loading = true
        this.loadData()
      }
    },
    initialSearch: {
      immediate: true,
      handler: function (val) {
        this.search = val
      }
    },
  },
  methods: {
    updateInputWidth () {
      this.inputWidth = this.$el?.clientWidth || 364;
    },
    isCampaignSelected ({ id }) {
      return this.campaignId === id
    },
    isCreatorSelected ({ id }) {
      return this.creatorId === id
    },
    hidePopper () {
      if (this.inFocusMode) {
        this.$nextTick(() => { this.showResults = true; })
      }
    },
    loadData: debounce(function () {
      const { search } = this

      Api.post('/campaigns/search', { q: search })
        .then(({ data: { creators, campaigns } }) => {
          this.creators = creators ?? []
          this.campaigns = campaigns ?? []
        })
        .catch(() => {
          this.creators = []
          this.campaigns = []
        })
        .finally(() => (this.loading = false))
    }, 600),
    updateInitialSearch: debounce(function () {
      const { search, initialSearch } = this

      if (search !== initialSearch) {
        this.$emit('update:initialSearch', search)
      }
    }, 700),
    clearFilters () {
      this.$emit('update:initialSearch', null)
      this.$emit('update:campaignId', null)
      this.$emit('update:creatorId', null)
    },
    handleDeleteButton () {
      if (!this.search) { this.clearFilters() }
    },
    onCampaignClick({ id, name }) {
      this.search = name
      this.showResults = false
      this.$emit('update:initialSearch', name)
      this.$emit('update:campaignId', id)
      this.$emit('update:creatorId', null)
    },
    onCreatorClick({ id, name }) {
      this.search = name
      this.showResults = false
      this.$emit('update:initialSearch', name)
      this.$emit('update:campaignId', null)
      this.$emit('update:creatorId', id)
    },
  },
}
</script>
