import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('newCampaign');

const MAX_GIFTS_NUMBER = 250;

export default {
  computed: {
    ...mapGetters(['numberOfSelectedProducts']),
  },
  methods: {
    /**
     * @param {number} newGiftsCount 
     * @returns {boolean}
     */
    maxGiftsGuard (newGiftsCount) {
      const { numberOfSelectedProducts } = this;
      const newTotal = numberOfSelectedProducts + newGiftsCount;

      if (newTotal > MAX_GIFTS_NUMBER) {
        this.$cgToast.error(
          `By adding the selected gifts, the total number of gift options for this campaign will exceed 250.<br>
            Please remove some of your existing gift options before adding these.`,
          { html: true },
        );
        return false;
      }

      return true;
    }
  },
}
