export default {
  methods: {
    /**
     * @param {String} valueName - value name used in vue data object
     * @param {Object} options - accepts object with min and max props
     */
    negativeNumberHandler (valueName, options = { min: 0, max: null }) {
      const value = this[valueName]
      const min = options?.min ?? null
      const max = options?.max ?? null

      if (min !== null && value < min) this.$nextTick(() => { this[valueName] = min })
      if (max !== null && value > max) this.$nextTick(() => { this[valueName] = max })
    }
  },
}
