import store from '../store/'

import Vue from 'vue'
import VueRouter from 'vue-router'
import getCookie from '@/utils/getCookie'

import EgiftOrders from '../views/EgiftOrders.vue'
import EgiftOrder from '../views/EgiftOrder.vue'
import MyUsers from '../views/MyUsers.vue'
import MyCGCredits from '../views/MyCGCredits.vue'
import Dashboard from '../views/Dashboard.vue'
import MyInventory from '../views/MyInventory.vue'
import MyAddressBook from '../views/MyAddressBook.vue'
import MyCampaignsPage from '@/views/MyCampaignsPage.vue'
import MyOrders from '@/views/MyOrders'
import MyOrder from '@/views/MyOrder'
import ApiTokens from '@/views/ApiTokens'
import SurveyMonkey from '@/views/Integrations/SurveyMonkey'
import Integrations from '@/views/Integrations'
import Hubspot from '@/views/Integrations/Hubspot'
import AccountSettings from '@/views/AccountSettings'
import PaymentInfo from '@/views/PaymentInfo'
import DedicatedPayment from '@/views/DedicatedPayment'
import MyLogosAndImages from '@/views/MyLogosAndImages'
import MyFavorites from '@/views/MyFavorites'
import ShareWishlist from '@/views/ShareWishlist'
import MyQuotes from '@/views/MyQuotes'

// AUTH
import AuthLayout from '@/views/auth/AuthLayout.vue'
import SSOPage from '@/views/auth/SsoPage.vue'
import LoginPage from '@/views/auth/LoginPage.vue'
import RegisterPage from '@/views/auth/RegisterPage.vue'
import ForgotPasswordPage from '@/views/auth/ForgotPasswordPage.vue'

import CampaignAutomationDecline from '@/views/CampaignAutomationDecline.vue'

import CreateCampaign from '@/views/createCampaign/root.vue'
import CreateCampaignName from '@/views/createCampaign/CreateCampaignName.vue'
import CreateCampaignSource from '@/views/createCampaign/CreateCampaignSource.vue'
import CreateCampaignReview from '@/views/createCampaign/CreateCampaignReview.vue'
import CreateCampaignGreeting from '@/views/createCampaign/CreateCampaignGreeting.vue'
import CreateCampaignSourceSelect from '@/views/createCampaign/sources/CreateCampaignSourceSelect.vue'
import CreateCampaignSourceReview from '@/views/createCampaign/sources/CreateCampaignSourceReview.vue'
import CreateCampaignSourceFavorites from '@/views/createCampaign/sources/CreateCampaignSourceFavorites.vue'
import CreateCampaignSourceInventory from '@/views/createCampaign/sources/CreateCampaignSourceInventory.vue'
import CreateCampaignSourceCollection from '@/views/createCampaign/sources/CreateCampaignSourceCollection.vue'
import CreateCampaignSourceCollections from '@/views/createCampaign/sources/CreateCampaignSourceCollections.vue'

const baseBreadcrumbs = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'My Account',
    url: '/dashboard',
  },
]

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      const storeUrl = process.env.VUE_APP_STORE_URL || process.env.VUE_APP_MAGENTO_URL

      window.location.href = `${storeUrl}${process.env.VUE_APP_PAGE_404}`
    }
  },
  {
    path: '/',
    meta: { requireAuth: true },
    beforeEnter: (to, from, next) => {
      next({ name: 'Dashboard' })
    }
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      next({ name: 'LoginPage' })
    }
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'LoginPage',
        component: LoginPage,
      },
      {
        path: 'register',
        name: 'RegisterPage',
        component: RegisterPage,
      },
      {
        path: 'login-with-sso',
        name: 'SSOPage',
        component: SSOPage,
      },
      {
        path: 'forgot-password',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage,
      }
    ],
    meta: { requireAuth: false },
  },
  {
    path: '/subAccounts',
    meta: { requireAuth: true },
    beforeEnter: (to, from, next) => {
      next({ name: 'Users' })
    }
  },
  {
    path: '/e-gift-orders',
    name: 'EgiftOrders',
    component: EgiftOrders,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My E-Gift Orders',
          url: '/e-gift-orders',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/e-gift-order/:id/:recipientId?',
    name: 'EgiftOrder',
    component: EgiftOrder,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
      ],
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.meta.breadcrumbs.length !== 2) {
        to.meta.breadcrumbs = to.meta.breadcrumbs.slice(0, 2)
      }

      if (from.name === 'Orders') {
        store.commit('e-gift-order/setBackButton', { text: 'Back to My Orders', url: '/orders' })

        to.meta.breadcrumbs.push({
          name: 'My Orders',
          url: '/orders',
        })
      }

      if (from.name === 'EgiftOrders') {
        store.commit('e-gift-order/setBackButton', { text: 'Back to My E-gift Orders', url: '/e-gift-orders' })

        to.meta.breadcrumbs.push({
          name: 'My E-Gift Orders',
          url: '/e-gift-orders',
        })
      }

      to.meta.breadcrumbs.push({
        name: to?.params?.id ? `Order ${to.params.id}` : 'Orders',
        url: to.fullPath,
      })

      next()
    }
  },
  {
    path: '/settings',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'Account Settings',
          url: '/settings',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: MyUsers,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'Users',
          url: '/users',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/my-cg-credits',
    name: 'MyCGCredits',
    component: MyCGCredits,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My CG Credit',
          url: '/my-cg-credits',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requireAuth: true }
  },
  {
    path: '/my-inventory',
    name: 'MyInventory',
    component: MyInventory,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My Inventory',
          url: '/my-inventory',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: MyAddressBook,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'Contacts',
          url: '/contacts',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: MyOrders,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My Orders',
          url: '/orders',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/order/:id',
    name: 'MyOrder',
    component: MyOrder,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My Orders',
          url: '/orders',
        },
      ],
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.meta.breadcrumbs.at(-1).url.includes('/order/')) {
        to.meta.breadcrumbs.pop()
      }

      to.meta.breadcrumbs.push({
        name: `Order ${to.params.id}`,
        url: to.fullPath,
      })

      next()
    }
  },
  {
    path: '/api-tokens',
    name: 'ApiTokens',
    component: ApiTokens,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'API Tokens',
          url: '/api-tokens',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: Integrations,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'Integrations',
          url: '/integrations',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/integrations/survey-monkey',
    name: 'SurveyMonkey',
    component: SurveyMonkey,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'Integrations',
          url: '/integrations',
        },
        {
          name: 'Survey Monkey',
          url: '/integrations/survey-monkey',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/integrations/hubspot',
    name: 'Hubspot',
    component: Hubspot
  },
  {
    path: '/my-campaigns',
    name: 'MyCampaignsPage',
    component: MyCampaignsPage,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My Campaigns',
          url: '/my-campaigns',
        },
      ],
      requireAuth: true,
      canBeInIframe: true,
    }
  },
  {
    path: '/my-campaigns/:campaignId?',
    component: CreateCampaign,
    meta: { requireAuth: false },
    children: [
      {
        path: 'name',
        name: 'CreateCampaignName',
        component: CreateCampaignName,
      },
      {
        path: 'source',
        component: CreateCampaignSource,
        children: [
          {
            path: '',
            name: 'CreateCampaignSource',
            component: CreateCampaignSourceSelect,
          },
          {
            path: 'collections',
            name: 'CreateCampaignSourceCollections',
            component: CreateCampaignSourceCollections,
          },
          {
            path: 'collections/:id',
            name: 'CreateCampaignSourceCollection',
            component: CreateCampaignSourceCollection,
          },
          {
            path: 'inventory',
            name: 'CreateCampaignSourceInventory',
            component: CreateCampaignSourceInventory,
          },
          {
            path: 'favorites',
            name: 'CreateCampaignSourceFavorites',
            component: CreateCampaignSourceFavorites,
          },
          {
            path: 'review',
            name: 'CreateCampaignSourceReview',
            component: CreateCampaignSourceReview,
          },
        ],
      },
      {
        path: 'greeting',
        name: 'CreateCampaignGreeting',
        component: CreateCampaignGreeting,
      },
      {
        path: 'review',
        name: 'CreateCampaignReview',
        component: CreateCampaignReview,
      },
    ]
  },
  {
    path: '/campaign-automation/:campaignId/auto-egifts/:automationId/decline',
    name: 'CampaignAutomationDecline',
    component: CampaignAutomationDecline,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentInfo,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'Payment Info',
          url: '/payment',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/payment/:token',
    name: 'PaymentForm',
    props: true,
    component: DedicatedPayment
  },
  {
    path: '/my-logos-and-images',
    name: 'MyLogosAndImages',
    component: MyLogosAndImages,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My Logos & Images',
          url: '/my-logos-and-images',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/my-favorites',
    name: 'MyFavorites',
    component: MyFavorites,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My Favorites',
          url: '/my-favorites',
        },
      ],
      requireAuth: true,
    }
  },
  {
    path: '/wishlist/:code',
    name: 'ShareWishlist',
    props: true,
    component: ShareWishlist,
    meta: {  requireAuth: false }
  },
  {
    path: '/my-quotes',
    name: 'MyQuotes',
    props: false,
    component: MyQuotes,
    meta: {
      breadcrumbs: [
        ...baseBreadcrumbs,
        {
          name: 'My Quotes',
          url: '/my-quotes',
        },
      ],
      requireAuth: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = await getCookie('cgToken')

  window.routerLength += 1;

  if (to.matched.some(record => record.meta.canBeInIframe)) {
    if (to.query?.iframe === 'true') {
      next();
      return
    }
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    if (token) {
      next()
    } else {
      next({ name: 'LoginPage', query: { redirect: to.fullPath } })
    }
  }

  next()
})

export default router
