<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 16"
    fill="none"
    :width="width"
    :height="height"
  >
    <g clip-path="url(#clip0_3602_70051)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8573 9.77022C11.5421 9.77022 11.2871 10.0236 11.2871 10.336C11.2871 10.6492 11.5421
          10.9025 11.8573 10.9025C13.8059 10.9025 15.3922 12.4776 15.3922 14.4143C15.3922 14.6635
          15.1875 14.8669 14.9358 14.8669H1.59534C1.34444 14.8669 1.13976 14.6635 1.13976
          14.4143C1.13976 12.4776 2.72603 10.9025 4.67378 10.9025C4.98905 10.9025 5.24407 10.6492
          5.24407 10.336C5.24407 10.0236 4.98905 9.77022 4.67378 9.77022C2.09631 9.77022 0 11.8528
          0 14.4143C0 15.2883 0.716376 16 1.59534 16H14.9358C15.8156 16 16.5328 15.2883 16.5328
          14.4143C16.5328 11.8528 14.4356 9.77022 11.8573 9.77022ZM8.26639 1.13232C9.99461 1.13232
          11.4018 2.94271 11.4018 5.16798C11.4018 7.39244 9.99461 9.20365 8.26639 9.20365C6.53735
          9.20365 5.131 7.39244 5.12935 5.16798C5.12935 2.94271 6.53652 1.13232 8.26639
          1.13232ZM8.26639 10.336C10.6243 10.336 12.5415 8.01804 12.5415 5.16798C12.5415 2.31793
          10.6243 0 8.26639 0C7.10021 0 6.01079 0.553449 5.20033 1.5595C4.41958 2.52947 3.99041
          3.81019 3.99041 5.16798C3.99041 8.01804 5.90845 10.336 8.26639 10.336Z"
        :fill="color"
      />
    </g>
    <path
      v-if="isAdmin"
      d="M20.0775 5.18428L16.8003 8.66492L14.9032 6.63372C14.6989 6.41608 14.365 6.4133 14.1574
        6.6275C13.9499 6.8417 13.9472 7.19177 14.1515 7.4094L16.4351 9.84089C16.4706 9.87344 16.5106
        9.90025 16.5537 9.92044C16.5695 9.9351 16.587 9.94763 16.6058 9.95774C16.669 9.98507 16.7367
        9.99942 16.805 10C16.8734 9.99967 16.9411 9.98532 17.0042 9.95774C17.027 9.94515 17.0484
        9.93015 17.0683 9.91298C17.1069 9.89429 17.1428 9.87002 17.175 9.84089L20.8292 5.96245C20.9727
        5.82404 21.0319 5.61408 20.9833 5.41611C20.9346 5.21815 20.786 5.06432 20.5966 5.01583C20.4072
        4.96735 20.2077 5.03209 20.0775 5.18428Z"
      :fill="color"
    />
    <defs>
      <clipPath id="clip0_3602_70051">
        <rect width="17" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'UsersType',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 21
    },
    height: {
      type: [Number, String],
      required: false,
      default: 17
    },
    color: {
      type: String,
      required: false,
      default: '#42B77A'
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
