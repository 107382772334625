<template>
  <div class="base-pagination">
    <v-icon
      :disabled="links.prev === null"
      @click="load(meta.current_page-1)"
      :class="links.prev === null ? 'disabled' : 'active'"
    >
      mdi-chevron-left
    </v-icon>

    <BasePaginationTrigger
      v-for="paginationTrigger in paginationTriggers"
      :class="{
        'base-pagination__description--current':
          paginationTrigger === meta.current_page
      }"
      :key="paginationTrigger"
      :pageNumber="paginationTrigger"
      class="base-pagination__description"
      @loadPage="load"
    />

    <v-icon
      :disabled="this.links.next === null"
      @click="load(meta.current_page+1)"
      :class="this.links.next === null ? 'disabled' : 'active'"
    >
      mdi-chevron-right
    </v-icon>
  </div>
</template>

<script>
import BasePaginationTrigger from './BasePaginationTrigger.vue'

export default {
  components: {
    BasePaginationTrigger
  },
  data: () => ({
  }),
  props: {
    links: {
      first: String,
      last: String,
      next: String,
      prev: String
    },
    meta: {
      current_page: Number,
      from: Number,
      last_page: Number,
      path: Number,
      per_page: Number,
      to: Number,
      total: Number
    }
  },

  computed: {
    paginationTriggers () {
      const currentPage = this.meta.current_page
      const pageCount = this.meta.last_page
      if (pageCount === undefined) {
        return
      }
      if (pageCount === 1) {
        return [1]
      }
      const visiblePagesCount = pageCount > 5 ? 5 : pageCount
      const visiblePagesThreshold = (visiblePagesCount - 1) / 2
      const pagintationTriggersArray = Array(visiblePagesCount - 1).fill(
        0
      )
      if (currentPage <= visiblePagesThreshold + 1) {
        pagintationTriggersArray[0] = 1
        const pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return index !== 3 ? pagintationTriggersArray[0] + index : 100000
          }
        )
        pagintationTriggers.push(pageCount)

        return pagintationTriggers
      }

      if (currentPage >= pageCount - visiblePagesThreshold) {
        const pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return index !== 3 ? pageCount - index : 100000
          }
        )

        pagintationTriggers.reverse().unshift(1)

        return pagintationTriggers
      }

      pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1
      const pagintationTriggers = pagintationTriggersArray.map(
        (paginationTrigger, index) => {
          return pagintationTriggersArray[0] + index
        }
      )

      pagintationTriggers.unshift(1)
      pagintationTriggers[pagintationTriggers.length - 1] = pageCount

      return pagintationTriggers
    }
  },

  methods: {
    load (page) {
      this.$emit('change', page)
    }
  }
}
</script>

<style lang="scss" scoped>
$c-primary-accent: black;

.base-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__description {
    display: flex;
    margin: 0 5px;
    font-size: 14px;

    &--current {
      color: $c-primary-accent;
    }
  }

  .arrow-left {
    transform: rotateZ(180deg);
  }

  .disabled {
    color: #b8b8b8;
  }

  .active {
    color: black;
  }
}
</style>
