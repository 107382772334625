<template>
  <div>
    <label class="form_radio__custom" :class="labelClass">
      <input
        v-model="inputModel"
        type="radio"
        :disabled="disabled"
        :value="inputValue"
      >
      <span class="radiomark" />
      <span class="d-flex">
        <slot />
      </span>
    </label>

    <slot name="non-clickable" />
  </div>
</template>

<script>
export default {
  name: 'CommonRadio',
  props: {
    value: {
      type: [String, Number, Object],
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    inputValue: {
      type: [String, Number, Object],
      required: true
    },
    labelClass: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    inputModel: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.form_radio__custom {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 15px;
  display: flex;
  width: fit-content;

  /* Hide the browser's default radio button */
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    /* When the radio button is checked, add a blue background */
    &:checked ~ .radiomark {
      background-color: #fff;
    }
    /* Show the indicator (dot/circle) when checked */
    &:checked ~ .radiomark:after {
      opacity: 1;
      background-color: #42B77A;
    }

    &:disabled ~ .radiomark:after {
      opacity: 1;
      background-color: #eaeaea;
    }
  }

  span {
    color: black;
  }
  /* Create a custom radio button */
  .radiomark {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border: 1px solid #DEDEDE;
    border-radius: 50%;

    /* Create the indicator (the dot/circle - hidden when not checked) */
    &:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 14px;
      height: 14px;
      border-radius: 50%;
      content: "";
      position: absolute;
      opacity: 0;
      transition: background-color .2s, opacity .2s;
    }
  }
}
</style>
