<template>
  <div class="send-egift-panel-recipients-bulk-options">
    <recipients-bulk-options-upload-card
      v-on="$listeners"
    />

    <recipients-bulk-options-card @onButtonClick="moveUserToCopyPasteStep">
      <template #icon>
        <icons-recipients-copy-paste />
      </template>
      <template #text>
        Paste your contacts information from a spreadsheet or other document in each column separately
      </template>
      <template #button>
        Copy & paste
      </template>
    </recipients-bulk-options-card>

    <recipients-bulk-options-card @onButtonClick="moveUserToContactsStep">
      <template #icon>
        <icons-recipients-contacts />
      </template>
      <template #text>
        Choose specific recipients or groups from your Contacts list
      </template>
      <template #button>
        Select from Contacts 
      </template>
    </recipients-bulk-options-card>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { RecipientMethod, RootSteps } from '../../types'

import RecipientsBulkOptionsCard from '../components/RecipientsBulkOptionsCard.vue'
import RecipientsBulkOptionsUploadCard from '../components/RecipientsBulkOptionsUploadCard.vue'

@Component({
  components: {
    RecipientsBulkOptionsCard,
    RecipientsBulkOptionsUploadCard
  },
})
export default class SendEgiftPanelRecipientsBulkOptions extends Mixins() {
  moveUserToCopyPasteStep (): void {
    this.$emit('update:recipientMethod', RecipientMethod.ImportCopyPaste)
    this.$emit('update:step', RootSteps.RecipientBulkCopyPaste)
  }

  moveUserToContactsStep (): void {
    this.$emit('update:recipientMethod', RecipientMethod.Contacts)
    this.$emit('update:step', RootSteps.RecipientBulkContacts)
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-recipients-bulk-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
</style>
