export default {
  setLoading (state, value = false) {
    state.loading = value
  },
  setAggregatedViewLoading (state, value = false) {
    state.loadingAggregatedView = value
  },
  setTransactions (state, transactions) {
    state.transactions = transactions
  },
  addTransactions (state, newTransactions) {
    state.transactions.push(...newTransactions)
  },
  setMeta (state, meta) {
    state.meta = {
      currentPage: meta?.current_page ?? 1,
      from: meta?.from ?? 1,
      lastPage: meta?.last_page ?? 1,
      perPage: meta?.per_page ?? 50,
      to: meta?.to ?? 50,
      total: meta?.total ?? 50,
    }
  },
  setLinks (state, links) {
    state.links = links
  },
  setCurrentPage (state, value = 1) {
    state.currentPage = value
  },
  setAggregatedView (state, value = {}) {
    state.aggregatedView = {
      products: value?.products ?? [],
      campaigns: value?.campaigns ?? [],
      users: value?.users ?? [],
      total: value?.values ?? {},
    }
  },
  // set filters items
  setFilterItemsUsers (state, data = []) {
    state.filtersItems.users = data
  },
  // set filters
  setFiltersDateRange (state, data = null) {
    if (data) {
      state.filters.dateRange = data
    } else {
      const dateHelper = state.defaultDateHelper

      state.filters.dateRange = {
        start: dateHelper?.start ?? null,
        end: dateHelper?.end ?? null,
      }
    }
  },
  setFiltersTypes (state, data = []) {
    state.filters.types = data
  },
  setFiltersUserId (state, data = null) {
    state.filters.userId = data
  },
  setFiltersOrderId (state, data = null) {
    state.filters.orderId = data
  },
  setSearch (state, val = null) {
    state.filters.search = val
  },
  setFiltersCampaignId (state, val = null) {
    state.filters.campaignId = val
  },
  setFiltersProductId (state, val = null) {
    state.filters.productId = val
  },
}
