<template>
  <div class="create-campaign-source">
    <h1>Start adding gifts from:</h1>

    <span class="create-campaign-source__subtext">Mix & match from all three</span>

    <create-campaign-source-list />
  </div>
</template>

<script>
import CreateCampaignSourceList from '@/components/createCampaign/CreateCampaignSourceList.vue'

export default {
  name: 'CreateCampaignSourceSelect',
  components: { CreateCampaignSourceList },
}
</script>

<style lang="scss" scoped>
.create-campaign-source {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  &__subtext {
    color: #62646A;
    font-family: 'Lato-Italic', sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 19px;
    margin-top: -34px;
  }

  @media screen and (max-width: 430px) {
    width: 100%;
    gap: 30px;

    &__subtext {
      margin-top: -20px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>
