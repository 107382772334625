<template>
  <div class="inventory-status-grid-view">
    <template v-if="!loading && inventoryList.length">
      <template v-for="item in inventoryList">
        <inventory-kit-card
          v-if="item.is_kit"
          v-on="$listeners"
          :key="item.product.item_id"
          :item="item"
        />

        <inventory-card
          v-else
          v-on="$listeners"
          :key="item.product.item_id"
          :item="item"
        />
      </template>
    </template>

    <div
      v-else-if="!loading && !inventoryList.length"
      class="inventory-status-grid-view__empty"
    >
      <icons-empty-recent-orders color="#D8F1E4" />

      No inventory products found
    </div>

    <common-loader
      v-if="loading || loadingPage"
      :style="loadingPage ? 'padding: 140px 0' : 'padding: 200px 0'"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import loadMoreOnScroll from './mixins/loadMoreOnScroll'

import InventoryCard from '@/components/myInventory/InventoryCard'
import InventoryKitCard from '@/components/myInventory/InventoryKitCard'

const { mapState } = createNamespacedHelpers('inventory')

export default {
  name: 'TheGridView',
  mixins: [loadMoreOnScroll],
  components: {
    InventoryCard,
    InventoryKitCard,
  },
  computed: {
    ...mapState(['inventoryList']),
  },
}
</script>

<style lang="scss" scoped>
.inventory-status-grid-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    justify-content: center;
    gap: 30px;
    height: 100%;
    width: 100%;
    min-height: 400px;

    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }
}
</style>
