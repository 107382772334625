<template>
  <div class="my-orders-table-product">
    <div class="my-orders-table-product__images">
      <icons-empty-cg-credits v-if="isProductACgCredits" />

      <product-card-images
        v-else
        :images="productImages"
        style="max-width: 160px; max-height: 160px"
      />

      <div
        v-if="isProductASample && productIsCustomized"
        class="my-orders-table-product__sample-ribbon"
      >
        Customized sample
      </div>
    </div>
    <ul class="my-orders-table-product__info">
      <v-popover
        placement="top"
        trigger="hover"
        :disabled="orderProduct.length <= 1"
        :container="false"
      >
        <li
          class="product-title"
          :class="{ 'pointer': orderProduct.length > 1 }"
        >{{ productName }}</li>

        <multiple-products slot="popover" :products="orderProduct" :order-type="orderType" />
      </v-popover>
      <li v-if="productSku" class="product-sku">SKU - {{ productSku }}</li>
      <li>{{ productPrice | priceFilter }}</li>
      <li>QTY: {{ productQty }}</li>

      <v-popover
        v-if="productIsCustomized"
        placement="right"
        trigger="hover"
        :container="false"
        style="width: fit-content"
      >
        <li class="product-customized">Customized</li>

        <product-customization slot="popover" :customizations="productCustomization" />
      </v-popover>
    </ul>
  </div>
</template>

<script>
import ProductCardImages from '@/components/myEgift/TheProductCardImages'
import MultipleProducts from './TheMyOrdersTableProductMultipleProducts'
import ProductCustomization from './TheMyOrdersTableProductCustomization'

export default {
  name: 'TheMyOrdersTableProduct',
  components: {
    MultipleProducts,
    ProductCardImages,
    ProductCustomization,
  },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  computed: {
    orderType () {
      return this.order.order_type
    },
    productName () {
      const { name, products } = this.order

      if ('multiple items' === name?.toLowerCase()) { return `${products.length} items` }
      return name
    },
    orderProduct () {
      return this.order?.products ?? []
    },
    productSku () {
      return this.order?.sku ?? null
    },
    productImages () {
      return this.order?.images ?? []
    },
    productQty () {
      return this.order?.quantity ?? 0
    },
    productPrice () {
      return this.order?.price ?? 0
    },
    isProductASample () {
      const { orderType } = this
      return ['random_sample', 'customized_sample', 'virtual_sample'].includes(orderType) ?? false
    },
    isProductACgCredits () {
      return ['cg credits'].includes(this.productName?.toLowerCase())
    },
    productCustomization () {
      return this.order?.customized ?? []
    },
    productIsCustomized () {
      return !!this.productCustomization.length ?? false
    },
  },
  methods: {
    getImagePath (image) {
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : image
    }
  }
}
</script>

<style lang="scss" scoped>
.my-orders-table-product {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 14px;

  &__images {
    position: relative;
    min-height: 160px;
    height: 160px;
    min-width: 160px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__sample-ribbon {
    background-color: #FCEBA4;
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 8px;
    text-transform: uppercase;
    color: #795E04;
    letter-spacing: 0.5px;
    font-size: 10px;
    font-family: 'Lato-Bold', sans-serif;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 0;
    list-style: none;

    & li {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      white-space: pre-wrap;
      color: #000;

      &.product-title {
        font-family: 'Lato-Bold', sans-serif;
      }

      &.product-title.pointer {
        color: #42B77A;
      }

      &.product-sku {
        font-size: 12px;
        color: #95979D;
      }

      &.product-customized {
        color: #42B77A;
      }
    }
  }
}
</style>
