var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"success-area-link",on:{"mouseleave":function($event){_vm.showPopUp = false}}},[_c('icons-link',{staticClass:"mr-5"}),_c('popper',{attrs:{"options":{
      placement: 'bottom',
      modifiers: {
        offset: { offset: '0px, 24px' },
      },
    }}},[_c('div',{staticClass:"popper pa-4 text-left d-flex align-center",staticStyle:{"min-width":"300px"},style:(!_vm.showPopUp && 'opacity: 0 !important')},[_c('icons-check-circle',{staticClass:"mr-2",attrs:{"width":20,"height":20,"filled":"","checkStrokeWidth":1.5}}),_vm._v(" Link copied to clipboard ")],1),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"green2 pointer",on:{"click":function($event){return _vm.copyText()}}},[_vm._v("Copy link")]),_vm._v(" and send it to your recipient. ")])]),_c('popper',{attrs:{"options":{
      placement: 'top',
      modifiers: { offset: { offset: '0, 5px' } },
    }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"min-width":"300px","max-width":"500px"}},[_vm._v(" "+_vm._s(_vm.getTooltipTextBySlug('campaigns_copy_link'))+" ")]),_c('div',{staticClass:"d-flex pointer",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{attrs:{"width":20,"height":20}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }