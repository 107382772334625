<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    version="1.1"
    :width="width"
    :height="height"
  >
    <g
      id="My-Campaigns"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.5"
    >
      <g
        id="My-Campaigns_send_enter-manualy_2"
        transform="translate(-1068.000000, -28.000000)"
        fill="#000000"
      >
        <g id="Address-book-icon" transform="translate(1068.740741, 28.200000)">
          <g id="Group-6" transform="translate(0.000084, 0.434609)">
            <path
              d="M12.4212963,10.1220435 C12.2605219,10.1220435 12.1304714,10.2568261
                12.1304714,10.4224783 C12.1304714,10.5885652 12.2605219,10.7224783
                12.4212963,10.7224783 C13.415404,10.7224783 14.165404,11.5781304
                14.165404,12.605087 C14.165404,12.6394348 14.1590909,12.6724783
                14.1456229,12.702913 C14.0808081,12.8546522 14.1464646,13.0320435
                14.2933502,13.0994348 C14.3316498,13.1172609 14.371633,13.125087
                14.4111953,13.125087 C14.5227273,13.125087 14.6287879,13.059 14.6771886,12.9468261
                C14.7234848,12.839 14.7470539,12.7242174 14.7470539,12.605087
                C14.7470539,11.2472609 13.7361111,10.1220435 12.4212963,10.1220435
                M9.87205387,5.809 C10.0803872,5.6816087 10.3021886,5.61726087 10.5315657,5.61726087
                C11.4128788,5.61726087 12.1304714,6.57726087 12.1304714,7.75682609
                C12.1304714,7.96856522 12.1077441,8.1763913 12.0627104,8.37465217
                C12.0260943,8.5363913 12.1233165,8.69769565 12.2794613,8.73552174
                C12.3017677,8.74117391 12.3240741,8.74378261 12.3459596,8.74378261
                C12.4781145,8.74378261 12.5972222,8.65030435 12.6287879,8.51204348
                C12.6839226,8.26856522 12.7121212,8.01465217 12.7121212,7.75682609
                C12.7121212,6.24595652 11.7340067,5.0163913 10.5315657,5.0163913
                C10.2011785,5.0163913 9.87079125,5.11204348 9.57617845,5.29204348
                C9.43813131,5.3763913 9.39225589,5.56073913 9.47390572,5.70334783
                C9.55597643,5.8463913 9.73442761,5.89334783 9.87205387,5.809 M11.4907407,11.3237826
                C11.3299663,11.3237826 11.1999158,11.4581304 11.1999158,11.6237826
                C11.1999158,11.7898696 11.3299663,11.9242174 11.4907407,11.9242174
                C12.4844276,11.9242174 13.2933502,12.7594348 13.2933502,13.7863913
                C13.2933502,13.9185652 13.1889731,14.0263913 13.0606061,14.0263913
                L6.25757576,14.0263913 C6.12962963,14.0263913 6.02525253,13.9185652
                6.02525253,13.7863913 C6.02525253,12.7594348 6.83417508,11.9242174
                7.82744108,11.9242174 C7.98821549,11.9242174 8.11826599,11.7898696
                8.11826599,11.6237826 C8.11826599,11.4581304 7.98821549,11.3237826
                7.82744108,11.3237826 C6.51304714,11.3237826 5.44402357,12.4281304
                5.44402357,13.7863913 C5.44402357,14.2498696 5.80934343,14.6272609
                6.25757576,14.6272609 L13.0606061,14.6272609 C13.5092593,14.6272609
                13.875,14.2498696 13.875,13.7863913 C13.875,12.4281304 12.8055556,11.3237826
                11.4907407,11.3237826 M9.65951178,6.74334783 C10.5408249,6.74334783
                11.2584175,7.70334783 11.2584175,8.88334783 C11.2584175,10.062913
                10.5408249,11.0233478 9.65951178,11.0233478 C8.77777778,11.0233478
                8.06060606,10.062913 8.05976431,8.88334783 C8.05976431,7.70334783
                8.7773569,6.74334783 9.65951178,6.74334783 M9.65951178,11.6237826
                C10.8619529,11.6237826 11.8396465,10.3946522 11.8396465,8.88334783
                C11.8396465,7.37204348 10.8619529,6.14291304 9.65951178,6.14291304
                C9.06481481,6.14291304 8.50925926,6.4363913 8.0959596,6.96986957
                C7.69781145,7.48421739 7.47895623,8.16334783 7.47895623,8.88334783
                C7.47895623,10.3946522 8.45707071,11.6237826 9.65951178,11.6237826
              "
              id="Fill-4"
            />
            <path
              d="M16.7306397,1.20826087 C16.9461279,1.20826087 17.1207912,1.38869565
                17.1207912,1.61130435 L17.1207912,17.7186957 C17.1207912,17.9408696
                16.9461279,18.1213043 16.7306397,18.1213043 L2.89225589,18.1213043
                C2.67718855,18.1213043 2.50252525,17.9408696 2.50252525,17.7186957
                L2.50252525,15.1013043 L3.25126263,15.1013043 C3.57449495,15.1013043
                3.83585859,14.8304348 3.83585859,14.4973913 C3.83585859,14.163913
                3.57449495,13.8930435 3.25126263,13.8930435 L2.50252525,13.8930435
                L2.50252525,10.2686957 L3.25126263,10.2686957 C3.57449495,10.2686957
                3.83585859,9.99826087 3.83585859,9.66478261 C3.83585859,9.33173913
                3.57449495,9.06086957 3.25126263,9.06086957 L2.50252525,9.06086957
                L2.50252525,5.43652174 L3.25126263,5.43652174 C3.57449495,5.43652174
                3.83585859,5.16565217 3.83585859,4.8326087 C3.83585859,4.49913043
                3.57449495,4.22826087 3.25126263,4.22826087 L2.50252525,4.22826087
                L2.50252525,1.61130435 C2.50252525,1.38869565 2.67718855,1.20826087
                2.89225589,1.20826087 L16.7306397,1.20826087 Z M1.33333333,1.61130435
                L1.33333333,4.22826087 L0.58459596,4.22826087 C0.261363636,4.22826087 0,4.49913043
                0,4.8326087 C0,5.16565217 0.261363636,5.43652174 0.58459596,5.43652174
                L1.33333333,5.43652174 L1.33333333,9.06086957 L0.58459596,9.06086957
                C0.261363636,9.06086957 0,9.33173913 0,9.66478261 C0,9.99826087
                0.261363636,10.2686957 0.58459596,10.2686957 L1.33333333,10.2686957
                L1.33333333,13.8930435 L0.58459596,13.8930435 C0.261363636,13.8930435 0,14.163913
                0,14.4973913 C0,14.8304348 0.261363636,15.1013043 0.58459596,15.1013043
                L1.33333333,15.1013043 L1.33333333,17.7186957 C1.33333333,18.6082609
                2.03114478,19.3295652 2.89225589,19.3295652 L16.7306397,19.3295652
                C17.5917508,19.3295652 18.2899832,18.6082609 18.2899832,17.7186957
                L18.2899832,1.61130435 C18.2899832,0.721304348 17.5917508,0 16.7306397,0
                L2.89225589,0 C2.03114478,0 1.33333333,0.721304348 1.33333333,1.61130435
                L1.33333333,1.61130435 Z
              "
              id="Fill-1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Contacts',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 21
    }
  }
}
</script>
