<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="none"
    :width="width"
    :height="height"
  >
    <g id="time_icon">
      <path
        id="Page 1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 6.375C5.793 6.375 5.625 6.207 5.625 6C5.625 5.79338 5.793 5.625 6 5.625C6.207 5.625 6.375 5.79338 6.375 6C6.375 6.207 6.207 6.375 6 6.375ZM6.97987 6.54075C7.06987 6.37987 7.125 6.19725 7.125 6C7.125 5.51175 6.81037 5.09963 6.375 4.94438V1.875C6.375 1.668 6.20738 1.5 6 1.5C5.79262 1.5 5.625 1.668 5.625 1.875V4.94438C5.18962 5.09963 4.875 5.51175 4.875 6C4.875 6.62025 5.37975 7.125 6 7.125C6.14887 7.125 6.29025 7.09462 6.42037 7.04175L7.98487 8.60587C8.058 8.679 8.154 8.71575 8.25 8.71575C8.346 8.71575 8.442 8.679 8.51512 8.60587C8.66175 8.46 8.66175 8.22262 8.51512 8.076L6.97987 6.54075ZM6 11.25C3.105 11.25 0.75 8.895 0.75 6C0.75 3.105 3.105 0.75 6 0.75C8.895 0.75 11.25 3.105 11.25 6C11.25 8.895 8.895 11.25 6 11.25ZM6 0C2.69175 0 0 2.69175 0 6C0 9.30825 2.69175 12 6 12C9.30825 12 12 9.30825 12 6C12 2.69175 9.30825 0 6 0Z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TimeCircle',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 12
    },
    height: {
      type: [Number, String],
      required: false,
      default: 12
    },
    color: {
      type: String,
      required: false,
      default: '#795E04'
    },
  }
}
</script>
