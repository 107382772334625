var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transactions-history-table-item"},[_c('div',{staticClass:"transactions-history-table-item__product"},[_c('list-view-item-images',{attrs:{"images":_vm.productImages}}),_c('div',[_c('div',[_vm._v(_vm._s(_vm.productName))]),(_vm.productSku)?_c('div',[_vm._v(_vm._s(_vm.productSku))]):_vm._e()])],1),_c('div',[(_vm.orderNumber && _vm.orderNumber !== 'N/A')?_c('router-link',{attrs:{"to":_vm.orderRouterLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.orderNumber)+" ")]):[_vm._v(" "+_vm._s(_vm.orderNumber)+" ")]],2),_c('div',[_vm._v(_vm._s(_vm.kitName))]),_c('div',[_vm._v(_vm._s(_vm.kitSku))]),_c('div',[_vm._v(_vm._s(_vm.campaignName))]),_c('div',[_vm._v(_vm._s(_vm.userName))]),_c('div',{staticClass:"transactions-history-table-item__transaction"},[_vm._v(" "+_vm._s(_vm.convertTransactionType(_vm.transactionType))+" "),(_vm.auditTransactionReason)?_c('icons-question',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.auditTransactionReason,
        placement: 'top',
        trigger: 'hover',
        classes: 'transactions-history-table-item__tooltip',
        offset: 6,
        boundariesElement: 'div',
      }),expression:"{\n        content: auditTransactionReason,\n        placement: 'top',\n        trigger: 'hover',\n        classes: 'transactions-history-table-item__tooltip',\n        offset: 6,\n        boundariesElement: 'div',\n      }"}],attrs:{"width":18,"height":18}}):_vm._e()],1),_c('div',[(_vm.parentOrderNumber && _vm.parentOrderNumber !== 'N/A')?_c('router-link',{attrs:{"to":_vm.parentOrderRouterLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.parentOrderNumber)+" ")]):[_vm._v(" "+_vm._s(_vm.parentOrderNumber)+" ")]],2),_c('div',[_vm._v(_vm._s(_vm._f("date")(_vm.orderDate)))]),_c('div',[_vm._v(_vm._s(_vm.orderQuantity))]),_c('div',[_vm._v(_vm._s(_vm.orderValue))]),_c('div',[_vm._v(_vm._s(_vm.orderTotalValue))]),_c('div',{staticClass:"transactions-history-table-item__status"},[_c('div',[_c('div',[_vm._v("Available")]),_c('div',[_vm._v(_vm._s(_vm.orderStatus.available))])]),_c('div',[_c('div',[_vm._v("On hold:")]),_c('div',[_vm._v(_vm._s(_vm.orderStatus.onHold))])]),_c('div',[_c('div',[_vm._v("On hand:")]),_c('div',[_vm._v(_vm._s(_vm.orderStatus.onHand))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }