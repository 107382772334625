<template>
  <common-input
    v-model="modelValue"
    v-bind="$attrs"
    v-on="$listeners"
    :placeholder="placeholderText"
    :maxlength="maxLength"
    :error="showError"
    @blur="onBlur"
    :aria-invalid="showError"
    @focus="inFocusMode = true"
  />
</template>

<script>
export default {
  name: 'MonthDayInput',
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    focusPlaceholder: {
      type: String,
      required: false,
      default: null
    },
    allowYear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    inFocusMode: false,
    lazyValue: null,
    showError: false,
  }),
  computed: {
    modelValue: {
      get () {
        if (!this.lazyValue) { return '' }
        return this.parseDate(this.lazyValue)
      },
      set (val) {
        this.lazyValue = val;
      }
    },
    placeholderText () {
      const { inFocusMode, placeholder, focusPlaceholder } = this
      if (!focusPlaceholder) return placeholder
      return inFocusMode ? focusPlaceholder : placeholder
    },
    maxLength () {
      return this.allowYear ? 10 : 5
    },
    isDateValid () {
      const { lazyValue, allowYear } = this;
      if (!lazyValue) { return true }

      const monthDayYearRegex = /^(0[1-9]|1[0-2])[-/](0[1-9]|[12]\d|3[01])[-/](19\d\d|20\d\d)$/g
      const monthDayRegex = /^(0[1-9]|1[0-2])[-/](0[1-9]|[12]\d|3[01])$/g

      return allowYear ? monthDayYearRegex.test(lazyValue) : monthDayRegex.test(lazyValue)
    }
  },
  watch: {
    lazyValue: function (val) {
      this.$emit('input', val)
    }
  },
  mounted() {
    this.lazyValue = this.value || null
  },
  methods: {
    parseDate(date) {
      if (!date) { return '' }
      const dateString = date.replace(/[/ ]/g, '')

      if (dateString.length < 2) { return dateString }

      let result = '';
      const month = dateString.slice(0, 2)
      const day = dateString.slice(2, 4)
      const year = dateString.slice(4, 8)

      result += month
      if (day) { result += `/${day}` }
      if (year) { result += `/${year}` }

      return result
    },
    onBlur () {
      this.inFocusMode = false
      this.showError = !this.isDateValid
    },
    validate () {
      return this.isDateValid
    }
  }
}
</script>
