<template>
  <div>
    <v-row no-gutters justify="space-between" align="center">
      <v-col class="flex-grow-0 d-flex align-center g10">
        <common-input
          v-model="filters.search"
          height="30"
          clearable
          filter-style
          prepend-inner-icon="mdi-magnify"
          style="min-width: 216px; max-width: 216px"
          placeholder="Filter by name/order #"
        />
        <common-select
          v-model="filters.entityId"
          :items="availableBudgetEntities"
          v-if="!marketplaceUser"
          :menu-z-index="4"
          filter-style
          style="min-width: 216px; max-width: 216px"
          placeholder="Filter by budget entity"
          item-text="name"
          item-value="id"
          height="30"
        />
      </v-col>

      <v-col class="flex-grow-0 d-flex align-center g20">
        <the-table-total-info
          v-bind="$attrs"
          show-on-hold
        />

        <the-table-download-invoice
          :downloadMethod="downloadCSV"
        />
      </v-col>
    </v-row>

    <common-loader v-if="loading" style="padding: 70px 0" />

    <template v-else>
      <the-on-hold-table
        class="pt-6"
        @updateSortBy="updateSortBy"
        :items="onHoldItems"
        :sort-by="filters.sort.name"
        :sort-desc="filters.sort.desc"
      />
      <load-more
        class="mt-6"
        :is-visible="showLoadMore"
        :loading="loadingMore"
        :size="nextPageSize"
        @more="loadNextPage()"
      />
    </template>
  </div>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'

import LoadMore from '@/components/LoadMore'
import TheOnHoldTable from './TheOnHoldTable'
import TheTableDownloadInvoice from './TheTableDownloadInvoice'
import TheTableTotalInfo from './TheTableTotalInfo'

import { marketplaceUser } from './mixins'

export default {
  name: 'TheOnHoldTableWrapper',
  components: {
    LoadMore,
    TheOnHoldTable,
    TheTableDownloadInvoice,
    TheTableTotalInfo
  },
  mixins: [
    marketplaceUser
  ],
  data: () => ({
    loading: false,
    onHoldItems: [],
    filterBudgetEntities: [],
    // filters
    filters: {
      search: null,
      sort: {
        name: null,
        desc: false
      },
      entityId: null
    },
    // load more btn
    loadingMore: false,
    onHoldMeta: {},
    onHoldLinks: {}
  }),
  computed: {
    showLoadMore () {
      return !!this.onHoldLinks?.next || false
    },
    nextPageSize () {
      return this.onHoldMeta?.per_page || 0
    },
    currentPage () {
      return this.onHoldMeta?.current_page || 1
    },
    params () {
      const { sort, search, entityId } = this.filters
      const result = {}

      if (sort?.name) {
        result.order_by = sort.name
        result.order_desc = +sort.desc
      }
      if (search) result.search = search
      if (entityId) result.budget_entity_id = entityId

      return result
    },
    availableBudgetEntities () {
      const { filterBudgetEntities, filters: { entityId } } = this

      if (entityId) return [{ name: 'All', id: null }, ...filterBudgetEntities]
      return filterBudgetEntities
    }
  },
  watch: {
    filters: {
      handler: 'handleFilterParams',
      deep: true
    }
  },
  created () {
    this.fetchFirstPage()
  },
  methods: {
    fetchFirstPage () {
      this.loading = true
      const { params } = this
      this.takeFiltersData()
      Api.get('/credit/on-hold', { params: { ...params } })
        .then((response) => {
          this.onHoldItems = response.data
          this.setOnHoldData(response)
          this.loading = false
        })
    },
    takeFiltersData () {
      const { params } = this
      Api.get('/credit/on-hold/filter/budget_entity', { params: { ...params } })
        .then(({ data }) => (this.filterBudgetEntities = data))
    },
    handleFilterParams: debounce(function () {
      this.fetchFirstPage()
    }, 700),
    loadNextPage () {
      this.loadingMore = true
      const { params, currentPage } = this
      Api.get('/credit/on-hold', {
        params: {
          page: (currentPage + 1),
          ...params
        }
      }).then((response) => {
        this.onHoldItems.push(...response.data)
        this.setOnHoldData(response)
        this.loadingMore = false
      })
    },
    setOnHoldData ({ meta, links }) {
      this.onHoldMeta = meta
      this.onHoldLinks = links
    },
    updateSortBy (value, sortDesc) {
      this.filters.sort = {
        name: value,
        desc: sortDesc
      }
    },
    downloadCSV () {
      const { params, currentPage } = this

      return Api.get('/credit/on-hold/csv', {
        params: {
          ...params,
          page: currentPage
        }
      }).then((response) => {
        this.$store.commit('downloadCSV', response)
      })
    }
  }
}
</script>
