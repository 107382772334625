var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tray',[_c('div',{staticClass:"d-flex"},[_c('tray-badge-inventory-qty',{attrs:{"qty":_vm.qty,"minQty":_vm.minQty,"variants":_vm.variants}}),(_vm.superAdmin || _vm.masterAccount)?_c('tray-variants',{attrs:{"variants":_vm.variants,"showInProduction":"","showVariantsTooltip":_vm.variants.length > 1}},[(_vm.inProduction > 0)?_c('tray-badge',{class:_vm.variants.length > 1 && 'pointer',attrs:{"color":"yellow"}},[_vm._v(" "+_vm._s(_vm._f("maxZeroFilter")(_vm.inProduction))+" ")]):_vm._e()],1):_vm._e(),(_vm.superAdmin || _vm.masterAccount)?_c('tray-variants',{attrs:{"variants":_vm.variants,"showOnHold":"","showVariantsTooltip":_vm.variants.length > 1}},[(_vm.onHold > 0)?_c('tray-badge',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'Click to view where the on-hold items are',
          ..._vm.iconTooltipProps,
          offset: 8,
          classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
        }),expression:"{\n          content: 'Click to view where the on-hold items are',\n          ...iconTooltipProps,\n          offset: 8,\n          classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',\n        }"}],staticClass:"pointer",attrs:{"color":"grey","aria-disabled":(!_vm.superAdmin && !_vm.masterAccount)},nativeOn:{"click":function($event){return _vm.$emit('onHoldUnits')}}},[_vm._v(" "+_vm._s(_vm._f("maxZeroFilter")(_vm.onHold))+" ")]):_vm._e()],1):_vm._e(),(!_vm.superAdmin && !_vm.masterAccount && _vm.balance !== null)?_c('tray-badge',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.tooltipText,
        ..._vm.iconTooltipProps,
        offset: 8,
        placement: 'top',
        classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
      }),expression:"{\n        content: tooltipText,\n        ...iconTooltipProps,\n        offset: 8,\n        placement: 'top',\n        classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',\n      }"}],attrs:{"color":"red","outlined":""}},[_vm._v(" "+_vm._s(_vm._f("maxZeroFilter")(_vm.balance))+" ")]):_vm._e()],1),(_vm.superAdmin || _vm.masterAccount)?_c('tray-product-options',{on:{"onProductSettings":function($event){return _vm.$emit('productSettings')},"onOrdersHistory":function($event){return _vm.$emit('ordersHistory')},"onAssignUsers":function($event){return _vm.$emit('assignUsers')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }