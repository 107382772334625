const NotificationType = {
  Before: 'before',
  After: 'after'
}

const setOffset = ({ days, type }) => {
  if (type === NotificationType.Before) { return -days }
  return days
}

const parseOffset = (val) => {
  if (val < 0) {
    return {
      days: val * (-1),
      type: NotificationType.Before
    }
  }

  return {
    days: val,
    type: NotificationType.After,
  }
}

export {
  setOffset,
  parseOffset,
  NotificationType,
}
