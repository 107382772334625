var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-checkbox",class:{
    'common-checkbox--disabled': _vm.disabled
  },attrs:{"aria-disabled":_vm.disabled && 'disabled'},on:{"click":function($event){!_vm.disabled ?_vm.checkboxValue = !_vm.checkboxValue : null}}},[_c('div',{staticClass:"common-checkbox__checkbox",class:{
      'common-checkbox__checkbox--active': _vm.value,
      'common-checkbox__checkbox--disabled': _vm.disabled
    },attrs:{"role":"checkbox","aria-checked":_vm.value}}),_c('div',{staticClass:"d-flex common-checkbox__content",class:{
      'common-checkbox__content--bold': _vm.boldText,
      'align-center': _vm.centerContent
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }