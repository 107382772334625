<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M2.5 13.75H1.25V2.5C1.25033 2.16858 1.38213 1.85083 1.61648 1.61648C1.85083 1.38213 2.16858 1.25033 2.5 1.25H13.75V2.5H2.5V13.75ZM13.125 10.625C13.4958 10.625 13.8584 10.515 14.1667 10.309C14.475 10.103 14.7154 9.81014 14.8573 9.46753C14.9992 9.12492 15.0363 8.74792 14.964 8.38421C14.8916 8.02049 14.713 7.6864 14.4508 7.42417C14.1886 7.16195 13.8545 6.98337 13.4908 6.91103C13.1271 6.83868 12.7501 6.87581 12.4075 7.01773C12.0649 7.15964 11.772 7.39996 11.566 7.70831C11.36 8.01665 11.25 8.37916 11.25 8.75C11.2505 9.24713 11.4482 9.72375 11.7997 10.0753C12.1512 10.4268 12.6279 10.6245 13.125 10.625ZM13.125 8.125C13.2486 8.125 13.3695 8.16166 13.4722 8.23033C13.575 8.29901 13.6551 8.39662 13.7024 8.51082C13.7497 8.62503 13.7621 8.75069 13.738 8.87193C13.7139 8.99317 13.6544 9.10453 13.5669 9.19194C13.4795 9.27935 13.3682 9.33887 13.2469 9.36299C13.1257 9.38711 13 9.37473 12.8858 9.32743C12.7716 9.28012 12.674 9.20001 12.6053 9.09723C12.5367 8.99445 12.5 8.87361 12.5 8.75C12.5 8.58424 12.5658 8.42527 12.6831 8.30806C12.8003 8.19085 12.9592 8.125 13.125 8.125Z"
      :fill="color"
    />
    <path
      d="M17.5 4.375H5.625C5.29358 4.37533 4.97583 4.50713 4.74148 4.74148C4.50713 4.97583 4.37533 5.29358 4.375 5.625V17.5C4.37533 17.8314 4.50713 18.1492 4.74148 18.3835C4.97583 18.6179 5.29358 18.7497 5.625 18.75H17.5C17.8314 18.7497 18.1492 18.6179 18.3835 18.3835C18.6179 18.1492 18.7497 17.8314 18.75 17.5V5.625C18.7497 5.29358 18.6179 4.97583 18.3835 4.74148C18.1492 4.50713 17.8314 4.37533 17.5 4.375ZM17.5 17.5H5.625V13.75L8.125 11.2519L11.6163 14.7431C11.8507 14.9775 12.1685 15.1091 12.5 15.1091C12.8315 15.1091 13.1493 14.9775 13.3837 14.7431L14.375 13.7519L17.5 16.875V17.5ZM17.5 15.1075L15.2587 12.8663C15.0243 12.6319 14.7065 12.5003 14.375 12.5003C14.0435 12.5003 13.7257 12.6319 13.4913 12.8663L12.5 13.8575L9.00875 10.3663C8.77434 10.1319 8.45646 10.0003 8.125 10.0003C7.79354 10.0003 7.47566 10.1319 7.24125 10.3663L5.625 11.9825V5.625H17.5V15.1075Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoPreview',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    },
    color: {
      type: String,
      required: false,
      default: '#62646A'
    }
  }
}
</script>
