var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cg-balance-bar",attrs:{"chart-name":_vm.entity.name}},[_c('v-tooltip',{attrs:{"top":"","z-index":"4","disabled":_vm.hideTooltip || !_vm.entityHasAccountLimit,"content-class":"cg-balance-bar-tooltip v-tooltip__content--bottom"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"cg-balance-bar__wrapper",style:(`--chart-height: ${_vm.chartWrapperHeight}%;`)},'div',attrs,false),on),[(!_vm.entityHasAccountLimit)?_c('div',{staticClass:"cg-balance-bar__bar",style:(`
            --chart-color: ${_vm.entity.color};
            --chart-height: ${_vm.chartBalanceHeight}%;
            --text-color: ${_vm.entity.color};
          `),attrs:{"available-credits":_vm.priceEntityBalance}}):_vm._e(),(_vm.entityHasAccountLimit)?_c('div',{staticClass:"cg-balance-bar__bar",style:(`
            --chart-color: ${_vm.entity.color};
            --chart-height: ${_vm.chartAccountSpentHeight}%;
          `)}):_vm._e(),(_vm.entityHasAccountLimit)?_c('div',{staticClass:"cg-balance-bar__bar",style:(`
            --chart-color: ${_vm.entity.color}66;
            --chart-height: ${_vm.chartAccountLimitHeight}%;
            --text-color: ${_vm.entity.color};
          `),attrs:{"available-credits":_vm.priceAccountSpent,"account-limit":_vm.priceAccountLimit}}):_vm._e()])]}}])},[_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"content__title",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.entity.name))]),_c('v-col',{staticClass:"content__subtitle",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.removeSpecialChars(_vm.entity.account_period))+" spend limit ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"content__dot",style:(`--dot-color: ${_vm.entity.color}66`),attrs:{"cols":"6"}},[_vm._v(" Available: ")]),_c('v-col',{staticClass:"content__balance",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.priceAccountAvailable))]),_c('v-col',{staticClass:"content__dot",style:(`--dot-color: ${_vm.entity.color}`),attrs:{"cols":"6"}},[_vm._v(" Spent: ")]),_c('v-col',{staticClass:"content__balance",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.priceAccountSpent))])],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }