<template>
  <!-- TODO remove -->
  <common-input
    v-model="modelValue"
    v-bind="$attrs"
    v-on="$listeners"
    :height="height"
    type="tel"
    autocomplete="tel"
  />
</template>

<script>
export default {
  name: 'PhoneNumberInput',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    height: {
      type: [String, Number],
      required: false,
      default: 36
    }
  },
  computed: {
    modelValue: {
      get () {
        if (!this.value) return ''
        const phoneNumber = this.value.replace(/[- ()]/g, '')
        const { length } = phoneNumber

        if (length < 4) return phoneNumber

        const chunks = phoneNumber.match(/.{1,3}/g) || []
        const firstChunk = chunks.shift()
        const secondChunk = chunks.shift()
        const rest = chunks.join('')

        if (length < 7) return `(${firstChunk}) ${secondChunk}`

        return `(${firstChunk}) ${secondChunk}-${rest}`
      },
      set (val) {
        const newValue = val.replace(/[- ()]/g, '')
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
