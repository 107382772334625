<template>
  <v-navigation-drawer
    :value="visible"
    @input="inputHandler"
    v-scroll-lock="visible"
    app
    stateless
    temporary
    right
    width="50%"
    ref="drawer"
  >
    <v-alert
      class="py-3 px-10 mb-0 d-flex"
      type="error"
      color="#FFF8D9"
    >
      <template #prepend>
        <v-img class="mr-5"
          width="21"
          max-width="21"
          height="20"
          max-height="20"
          src="/images/alert_icon.svg"
        />
      </template>
      <span class="grow alert-text">
        <template v-if="isPreProduction">
          By closing this E-Gift, unredeemed gifts will be cancelled, and recipients will no longer be able to redeem. <br>
          All items selected by recipients shall be sent to production
        </template>

        <template v-else>
          By closing this E-Gift, unredeemed gifts will be cancelled, and recipients will no longer be able to redeem.
        </template>
      </span>
    </v-alert>

    <close-panel-button
      v-if="!hideCloseButton"
      @close="updateVisible(false)"
      :text="setCloseButtonText()"
    />

    <common-loader v-if="loading || showLoading" />

    <div class="px-10" :class="hideCloseButton && 'pt-6'" v-else>
      <h2 class="f30 lato-light font-weight-light mb-9">Close E-Gift order</h2>

      <template v-if="!isClosed">
        <dl class="d-flex align-center mb-3" v-if="refundData.paymentMethod">
          <dt class="f15 mr-5" style="min-width: 185px">E-Gift paid with:</dt>
          <dd class="f15 lato-bold">{{ refundData.paymentMethod }}</dd>
        </dl>
        <dl class="d-flex align-center mb-8">
          <dt class="f15 mr-5" style="min-width: 185px">Unredeemed E-Gift balance:</dt>
          <dd class="f15 lato-bold" v-if="isNaN(refundData.amount)">
            N/A
          </dd>
          <dd class="f15 lato-bold" v-else>
            {{
              refundData.amount | priceFilter({
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2
              })
            }}
          </dd>
        </dl>

        <v-divider class="mb-10" />

        <div v-if="refundData.refund" class="mb-12">
          <span class="f15 gray1 lato-italic font-weight-light">
            Claim {{ refundPercent }}% of your unredeemed E-Gift balance
          </span>

          <div class="d-flex align-center mt-8 mb-4" v-if="!showCreditCashRefund">
            <span>
              Keep
              <span class="lato-bold font-weight-bold d-inline-block">{{
                  refundData.creditsRefund | priceFilter({
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 2
                  }) }}
              </span>
              as CG Credits for future gifting
            </span>
            <common-select
              v-model="budgetEntity"
              :items="budgetEntities"
              height="30px"
              item-text="name"
              item-value="id"
              class="mx-3"
              style="max-width: 230px"
            >
              <template #selection="{ item }">
                <div class="d-flex align-center justify-space-between f14 full-width">
                  <div class="text-truncate">
                    <span class="be-color mr-2" :style="{ backgroundColor: item.color }"></span>
                    <span>{{ item.name }}</span>
                  </div>
                  <span class="pl-1">
                    {{
                      item.balance | priceFilter({
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0
                      })
                    }}
                  </span>
                </div>
              </template>
            </common-select>
            <common-tooltip top :max-width="250" :z-index="8" :activator-size="20">
              The selected Budget Entity shall be credited with the unredeemed balance of this eGift:
              {{
                refundData.creditsRefund | priceFilter({
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 2
                })
              }}
            </common-tooltip>
          </div>

          <div class="d-flex flex-column align-start mt-8 mb-4 g20" v-else>
            <common-radio v-model="paymentMethod" inputValue="CG Credits" class="close-order-radio">
              <div class="d-flex align-center">
                <span>
                  Keep
                  <span class="lato-bold font-weight-bold d-inline-block">{{
                      refundData.creditsRefund | priceFilter({
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 2
                      }) }}
                  </span>
                  as CG Credits for future gifting
                </span>
                <common-select
                  v-model="budgetEntity"
                  :items="budgetEntities"
                  @click.prevent
                  height="30px"
                  item-text="name"
                  item-value="id"
                  class="mx-3"
                  style="max-width: 230px"
                >
                  <template #selection="{ item }">
                    <div class="d-flex align-center justify-space-between f14 full-width">
                      <div class="text-truncate">
                        <span class="be-color mr-2" :style="{ backgroundColor: item.color }"></span>
                        <span>{{ item.name }}</span>
                      </div>
                      <span class="pl-1">
                        {{
                          item.balance | priceFilter({
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 0
                          })
                        }}
                      </span>
                    </div>
                  </template>
                </common-select>
                <common-tooltip top :max-width="250" :z-index="8" :activator-size="20">
                  The selected Budget Entity shall be credited with the unredeemed balance of this eGift:
                  {{
                    refundData.creditsRefund | priceFilter({
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2
                    })
                  }}
                </common-tooltip>
              </div>
            </common-radio>

            <common-radio v-model="paymentMethod" inputValue="Credit Card">
              <span>
                Initiate a
                <span class="lato-bold font-weight-bold d-inline-block">
                  {{
                    refundData.creditCardRefund | priceFilter({
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2
                    })
                  }}
                </span>
                refund to your original payment method
              </span>
            </common-radio>
          </div>
        </div>

        <common-button
          class="mt-4"
          @click="closeOrder"
          :height="36"
          :loading="loadingBtn"
        >
          Close e-gift
        </common-button>
      </template>

      <template v-else>
        <template v-if="isFailed">
          <div>
            <v-alert
              class="black1 py-2 px-5 mb-0"
              type="error"
              color="#FAE3E3"
              max-height="65"
            >
              <template #prepend>
                <v-img
                  class="mr-5"
                  width="22"
                  max-width="22"
                  height="20"
                  max-height="20"
                  src="/images/error-alert.png"
                />
              </template>
              <span class="grow">
                We were unable to close 1 E-Gift
              </span>
              <template #append>
                <common-tooltip top :max-width="250" :z-index="8" :activator-size="20">
                  <template v-if="paymentMethod === 'CG Credits'">
                    The transactions could not be completed due to a technical error
                  </template>
                  <template v-else>
                    The transactions could not be completed due to connection errors with the credit card company.
                  </template>
                </common-tooltip>
              </template>
            </v-alert>

            <v-divider class="mb-8" />

            <div class="py-5 font-weight-bold">
              What you can do?
            </div>

            <ol class="close-order-list close-order-list__decimal">
              <li>
                Contact our <a class="green2" :href="getCustomerServiceLink()" target="_blank">customer service</a>
              </li>
              <li>
                Refund these E-Gift order to your CG Credits account
              </li>
            </ol>
          </div>
        </template>

        <template v-else>
          <div class="mb-12">
            <v-alert
              class="black1 py-2 px-5 mb-10"
              type="success"
              text
              max-height="65"
            >
              <span class="grow black1">
                E-Gift successfully closed!
              </span>
            </v-alert>

            <div v-if="closeData.refunded">
              <template v-if="closeData.creditCardRefund">
                <ul class="ml-3 close-order-list close-order-list__disc">
                  <li>
                    <span class="mx-1">
                      {{
                        closeData.creditCardRefund | priceFilter({
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 2
                        })
                      }}
                    </span>
                    will be refunded to credit card ****-****-****-{{ closeData.creditCard }}
                  </li>
                  <li>
                    You will get an email confirming the transaction
                  </li>
                </ul>
              </template>

              <template v-else-if="closeData.creditsRefund">
                <span class="">
                  {{
                    closeData.creditsRefund | priceFilter({
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2
                    })
                  }}
                  CG Credits will be refunded to <strong>{{ closeData.budgetEntity.name }}</strong> budget entity
                </span>
              </template>
            </div>
          </div>
        </template>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import ClosePanelButton from '@/components/ClosePanelButton'

import Api from '@/axios/api'

export default {
  name: 'CloseOrder',
  components: {
    ClosePanelButton,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    id: {
      type: Number,
      required: false
    },
    productState: {
      type: String,
      required: false,
      default: null
    },
    productDate: {
      type: String,
      required: false,
      default: null
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    closeAfterSuccess: {
      type: Boolean,
      required: false,
      default: false
    },
    isCollection: {
      type: Boolean,
      required: false,
      default: false
    },
    isPreProduction: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    paymentMethod: 'CG Credits',
    budgetEntity: null,
    refundData: {},
    closeData: {},
    loading: false,
    isExpanded: false,
    loadingBtn: false,
    isClosed: false
  }),
  computed: {
    budgetEntities () {
      return this.refundData.budgetEntities || []
    },
    showCreditCashRefund () {
      const { refundData: { creditCardRefund }, productState, productDate } = this
      const sixMonthBeforeCurrDate = new Date()
      sixMonthBeforeCurrDate.setMonth(sixMonthBeforeCurrDate.getMonth() - 6)
      const orderDate = new Date(productDate)

      return creditCardRefund &&
        !['Expired'].includes(productState) &&
        (orderDate.getTime() >= sixMonthBeforeCurrDate.getTime())
    },
    isFailed () {
      return (this.refundData.close && !this.closeData.closed) ||
        (this.refundData.refund && !this.closeData.refunded)
    },
    refundPercent () {
      const { creditCardRefundFee, paymentMethod } = this.refundData

      return  paymentMethod === 'Credit Card' ? 100 - creditCardRefundFee : 100;
    },
  },
  watch: {
    visible (val) {
      this.closeData = {}
      this.isClosed = false

      if (val) {
        this.loading = true

        Api.get(`customer/egifts/${this.id}/refund`)
          .then(({ data }) => {
            this.refundData = data
            this.budgetEntity = data?.budgetEntities[0].id
            this.loading = false
          }).catch(() => {
            this.$emit('refund-error')
            this.updateVisible(false)
          })
      }
    },
  },
  methods: {
    updateVisible (val) {
      this.$emit('update:visible', val)
    },
    inputHandler (val) {
      this.updateVisible(val)
    },
    getCustomerServiceLink () {
      return window.location.origin.replace('account.', '') + '/faq'
    },
    setCloseButtonText () {
      if (this.isClosed) {
        return 'Back to eGift Order page'
      }
      return 'Cancel'
    },
    async closeOrder () {
      this.loadingBtn = true

      await Api.post(`/customer/egifts/${this.id}/close`, {
        method: this.paymentMethod,
        budget_entity_id: this.budgetEntity
      })
        .then(({ data }) => {
          this.closeData = data
        })
        .catch(() => {
          this.closeData = {}
        })
        .finally(() => {
          this.loadingBtn = false
          this.isClosed = true
          this.$emit('closed')
          if (this.closeAfterSuccess) {
            this.updateVisible(false)
          }
        })
    }
  }
}
</script>

<style lang="scss">
.close-order-list {
  li {
    margin-bottom: 8px;
  }

  &:is(ul) {
    li {
      list-style: disc;
    }
  }

  &:is(ol) {
    li {
      list-style: decimal;
    }
  }
}

.alert-text {
  color: #16181F;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Lato-Regular', sans-serif;
}

.close-order-radio .radiomark {
  top: 3px !important;
}
</style>
