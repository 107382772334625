import api from '@/axios/api'
import axios from 'axios'
import router from '@/router'

export default {
  namespaced: true,

  state: () => ({
    isShowSettingsPanel: false,
    credentials: {
      name: '',
      email: '',
      appId: '',
      authUrl: ''
    },
    isInstalled: false
  }),

  getters: {
    getCredentials (state) {
      return state.credentials
    }
  },

  mutations: {
    setIsShowSettingsPanel (state, status) {
      state.isShowSettingsPanel = status
    },

    setCredentials (state, credentials) {
      state.credentials = credentials
    },

    isInstalled (state, credentials) {
      state.isInstalled = !!credentials.appId
    }
  },

  actions: {
    async createAccount ({ dispatch }) {
      await api.post('/customer/hubspot/create')
        .then(response => {
          dispatch('fetchCredentials')
        })
    },

    async fetchCredentials ({ commit, dispatch, getters }) {
      const credentials = getters.getCredentials
      if (credentials.name && credentials.email && credentials.appId && credentials.authUrl) {
        return
      }

      await axios.get('/api/hubspot/credentials', {
        baseURL: process.env.VUE_APP_HUBSPOT_URL,
        headers: {
          Authorization: 'Bearer ' + await dispatch('headerAuth/getToken', null, { root: true })
        }
      })
        .then(({ data: credentials }) => {
          commit('setCredentials', credentials)
          commit('isInstalled', credentials)
        })
    },

    openTab ({ commit }) {
      router.push({ path: '/integrations' })
      commit('setIsShowSettingsPanel', true)
    }
  }
}
