<template>
  <label :for="id" class="common-toggle">
    <div class="common-toggle__wrapper">
      <input v-model="modelValue" type="checkbox" :id="id">
      <span />
    </div>
    <slot />
  </label>
</template>

<script>
export default {
  name: 'CommonToggle',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.common-toggle {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  font-family: 'Lato-Regular', sans-serif;
  font-size: 13px;
  line-height: 24px;
  color: #62646A;

  &__wrapper {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    border-radius: 14px;

    & > input {
      opacity: 0;
      widows: 0;
      height: 0;

      &:checked + span {
        background-color: #1DBF73;

        &::before {
          -webkit-transform: translateX(12px);
          -ms-transform: translateX(12px);
          transform: translateX(12px);
        }
      }
    }

    & > span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #DADBDD;
      border-radius: inherit;
      -webkit-transition: .4s;
      transition: .4s;

      &::before {
        content: "";
        position: absolute;
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 2px;
        background-color: #FFFFFF;
        border-radius: 50%;
        box-shadow: 0px 0px 0px rgba(76, 93, 110, 0.2),
          0px 1px 2px rgba(76, 93, 110, 0.2);
        -webkit-transition: inherit;
        transition: inherit;
      }
    }
  }
}
</style>
