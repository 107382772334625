<template>
  <v-form ref="form">
    <label for="full-name">
      Full name
    </label>
    <div class="d-flex" id="full-name">
      <common-input
        v-model="formFirstName"
        placeholder="First name"
        :rules="[validation.required]"
        autocomplete="given-name"
      />
      <common-input
        v-model="formLastName"
        placeholder="Last name"
        :rules="[validation.required]"
        autocomplete="family-name"
      />
    </div>

    <div class="d-flex align-center pt-7 mb-1 g10">
      <label for="display-name" class="mb-0">
        Display name
      </label>

      <popper
        :options="{
          placement: 'top',
          modifiers: {
            offset: {
              offset: '0,5px'
            },
          }
        }"
      >
        <div class="popper pa-4 text-left" style="max-width: 400px">
          Display Name can be used as gift giver name in the E Gift orders in case the account name
          is not the desired display name. For example an E Gift is sent from a whole company rather
          than the account holder name
        </div>
        <div slot="reference" class="d-flex">
          <icons-question width="16" height="16" class="pointer" />
        </div>
      </popper>
    </div>

    <common-input
      v-model="formDisplayName"
      id="display-name"
      placeholder="Display name"
    />

    <label for="email">
      Email address
    </label>
    <common-input
      v-model="formEmail"
      id="email"
      placeholder="Email"
      :rules="[validation.required, validation.email]"
      type="email"
    />

    <cg-phone-input
      v-model="formPhoneNumber"
      ref="phoneInput"
      style="padding-top: 30px"
    >
      <template #label>
        Phone number
      </template>
    </cg-phone-input>
  </v-form>
</template>

<script>
import { CgPhoneInput } from '@corporategift/design-system'

import Popper from 'vue-popperjs'

export default {
  name: 'AccountSettingsForm',
  components: {
    Popper,
    CgPhoneInput,
  },
  props: {
    firstName: {
      type: String,
      required: false,
      default: null
    },
    lastName: {
      type: String,
      required: false,
      default: null
    },
    displayName: {
      type: String,
      required: false,
      default: null
    },
    email: {
      type: String,
      required: false,
      default: null
    },
    phoneNumber: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    validation: {
      required: v => !!v || '',
      email: v => /.+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || ''
    }
  }),
  computed: {
    formFirstName: {
      get () {
        return this.firstName
      },
      set (val) {
        this.$emit('update:firstName', val)
      }
    },
    formLastName: {
      get () {
        return this.lastName
      },
      set (val) {
        this.$emit('update:lastName', val)
      }
    },
    formDisplayName: {
      get () {
        return this.displayName
      },
      set (val) {
        this.$emit('update:displayName', val)
      }
    },
    formEmail: {
      get () {
        return this.email
      },
      set (val) {
        this.$emit('update:email', val)
      }
    },
    formPhoneNumber: {
      get () {
        return this.phoneNumber
      },
      set (val) {
        this.$emit('update:phoneNumber', val)
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate() && this.$refs?.phoneInput?.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-family: 'Lato-Bold', sans-serif;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 4px;
  color: #000;
  display: block;

  &:not(:first-child) {
    padding-top: 30px;
  }
}
</style>
