<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 44"
    fill="none"
    :height="height"
    :width="width"
  >
    <path
      d="M34.1487 33.1488H9.80635C8.32842 33.1488 7.19824 32.0186 7.19824 30.5407V13.1533C7.19824
        11.6753 8.32842 10.5452 9.80635 10.5452H34.1487C35.6266 10.5452 36.7568 11.6753 36.7568
        13.1533V30.5407C36.7568 32.0186 35.6266 33.1488 34.1487 33.1488ZM9.80635 12.2839C9.28473
        12.2839 8.93698 12.6317 8.93698 13.1533V30.5407C8.93698 31.0623 9.28473 31.41 9.80635
        31.41H34.1487C34.6703 31.41 35.0181 31.0623 35.0181 30.5407V13.1533C35.0181 12.6317
        34.6703 12.2839 34.1487 12.2839H9.80635Z"
      fill="#D3D2D2"
    />
    <path
      d="M26.3243 20.9774C24.4117 20.9774 22.8468 19.4126 22.8468 17.4999C22.8468 15.5873 24.4117
        14.0225 26.3243 14.0225C28.2369 14.0225 29.8018 15.5873 29.8018 17.4999C29.8018 19.4126
        28.2369 20.9774 26.3243 20.9774ZM26.3243 15.7612C25.368 15.7612 24.5856 16.5436 24.5856
        17.4999C24.5856 18.4562 25.368 19.2387 26.3243 19.2387C27.2806 19.2387 28.063 18.4562 28.063
        17.4999C28.063 16.5436 27.2806 15.7612 26.3243 15.7612ZM30.932 32.8878L16.7612
        19.5864L8.6761 27.063L7.45898 25.8459L16.7612 17.1522L32.1491 31.6707L30.932 32.8878Z"
      fill="#D3D2D2"
    />
    <path
      d="M35.3656 28.5409L30.671 24.7157L26.7588 27.9323L25.6287 26.5414L30.671 22.4553L36.4088
        27.1499L35.3656 28.5409Z"
      fill="#D3D2D2"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheLogoPlaceholderIcon',
  props: {
    width: {
      type: Number,
      required: false,
      default: 44
    },
    height: {
      type: Number,
      required: false,
      default: 44
    },
  }
}
</script>
