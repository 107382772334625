import { Mixins, Component } from 'vue-property-decorator'

import panelTypeProp from './panelTypeProp'
import { PanelType } from '../../types'

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

@Component
export default class RecipientValidations extends Mixins(panelTypeProp) {
  rules = {
    required: (v: string | null | number) => !!v || '',
    validEmail: (v: string | null) => !v || emailRegex.test(v) || '',
  }

  get firstNameValidation () {
    const { rules } = this

    return [rules.required]
  }

  get lastNameValidation () {
    const { rules } = this

    return [rules.required]
  }

  get emailValidation () {
    const { rules, panelType } = this

    return  panelType === PanelType.Link
      ? [rules.validEmail]
      : [rules.required, rules.validEmail]
  }
}