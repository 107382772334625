// key string value object
import { isEqual } from 'lodash'

type StoredForm = {
  [key:string]: object
}

class FormsComparator {
  storedForms: StoredForm = {};

  addItemToStore (key: string, form: object) {
    this.storedForms[key] = form;
  }

  updateItemByKey (key: string, form: object) {
    this.addItemToStore(key, form);
  }

  removeItemByKey (key: string) {
    if (key in this.storedForms) {
      delete this.storedForms[key];
    }
  }

  compare (key: string, form: object): boolean {
    const storedForm = this.storedForms[key];

    if (!storedForm) { return true }
    return isEqual(storedForm, form)
  }

  clearStore (): void {
    this.storedForms = {};
  }
}

export default FormsComparator;
