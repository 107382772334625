<template>
  <div>
    <cg-loader v-if="loading" />

    <div v-show="!loading" class="add-credit-card-form">
      <div class="hosted-field__input-wrapper">
        <label for="card-number">Credit card number</label>

        <div class="hosted-field__credit-card">
          <div class="hosted-field" id="card-number" />

          <img :src="creditCardIcon" alt="">
        </div>
      </div>

      <div class="hosted-field__double-inputs">
        <div class="hosted-field__input-wrapper">
          <label for="expiration-date">Expiry date</label>
          <div class="hosted-field" id="expiration-date" />
        </div>
        <div class="hosted-field__input-wrapper">
          <label for="cvv">Card verification code</label>
          <div class="hosted-field" id="cvv" />
        </div>
      </div>

      <cg-checkbox v-if="showSetAsDefault" v-model="saveAsDefault" :disabled="forceSaveAsDefault">
        Set as default
      </cg-checkbox>

      <span>
        Billing address
      </span>

      <billing-address-form
        ref="billingAddress"
        @onBullingAddressUpdate="(val) => billingForm = val"
      />

      <cg-button v-if="showAddCardButton" :disabled="disableSaveButton" @click="sendCard">
        Add card
      </cg-button>
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'
import PaymentType from './paymentModule/utils/PaymentType'

import { CgButton, CgCheckbox, CgLoader } from '@corporategift/design-system'
import BillingAddressForm from './BillingAddressForm.vue'

export default {
  name: 'AddCreditCardForm',
  components: {
    CgButton,
    CgLoader,
    CgCheckbox,
    BillingAddressForm,
  },
  props: {
    showSetAsDefault: {
      type: Boolean,
      required: false,
      default: true,
    },
    showAddCardButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    forceSaveAsDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    creditCardIcon: '/images/credit-cards/master-card.svg',
    loading: false,
    creditCardData: {
      token: null,
      binNumber: null,
    },
    billingForm: {},
    saveAsDefault: false,
    validFlags: {
      ccn: false,
      exp: false,
      cvv: false,
    },
    braintreeInstance: null,
  }),
  computed: {
    isCCValid () {
      const { validFlags } = this;
      return Object.values(validFlags).every((flag) => flag === true);
    },
    disableSaveButton () {
      return !this.isCCValid
    },
  },
  watch: {
    forceSaveAsDefault: {
      immediate: true,
      handler: function (val) {
        this.saveAsDefault = val
      }
    },
    creditCardData: {
      deep: true,
      handler: 'sendPaymentData',
    },
    billingForm: {
      deep: true,
      handler: 'sendPaymentData',
    },
  },
  created () {
    this.initBraintree();
  },
  methods: {
    sendPaymentData () {
      const creditCardData = this.getCreditCardData()
      this.$emit('update:paymentData', creditCardData)
    },
    // public
    refreshPaymentToken () {
      const { braintreeInstance } = this
      if (!braintreeInstance) { throw new Error('Missing payment gateway instance') }

      braintreeInstance.tokenize((err, payload) => {
        if (err) { return }
        this.creditCardData = {
          token: payload?.nonce ?? null,
          binNumber: payload?.details?.bin ?? null,
        }
      });
    },
    initBraintree () {
      this.loading = true

      Api.get('/braintree/token')
        .then(({ token }) => {
          const createHostedFields = (clientInstance) => {
            window.braintree.hostedFields.create({
              client: clientInstance,
              styles: {
                'input': {
                  'font-size': '15px',
                  'background-color': 'white',
                  'color': '#000',
                  'padding': '0 16px',
                  'font-family': 'Lato-Regular, sans-serif',
                },
                ':focus': {
                  'color': '#000'
                },
                '::placeholder': {
                  'font-style': 'italic',
                  color: '#919191',
                  'font-family': 'Lato-Italic, sans-serif',
                },
                '.invalid': {
                  'color': '#FF5A60',
                }
              },
              fields: {
                number: {
                  selector: '#card-number',
                  placeholder: '____ ____ ____ ____'
                },
                cvv: {
                  selector: '#cvv',
                  placeholder: 'CVC'
                },
                expirationDate: {
                  selector: '#expiration-date',
                  placeholder: 'MM/YY'
                }
              }
            }, (err, hostedFieldsInstance) => {
              this.loading = false

              if (err) {
                console.error(err)
                return
              }

              this.braintreeInstance = hostedFieldsInstance ?? null

              hostedFieldsInstance.on('validityChange', (event) => {
                this.validFlags = {
                  ccn: event.fields.number.isValid,
                  exp: event.fields.expirationDate.isValid,
                  cvv: event.fields.cvv.isValid,
                }

                hostedFieldsInstance.setAttribute({
                  field: 'number',
                  attribute: 'aria-invalid',
                  value: event.fields.number.isValid ? 'false' : 'true',
                })

                hostedFieldsInstance.setAttribute({
                  field: 'expirationDate',
                  attribute: 'aria-invalid',
                  value: event.fields.expirationDate.isValid ? 'false' : 'true',
                })

                hostedFieldsInstance.setAttribute({
                  field: 'cvv',
                  attribute: 'aria-invalid',
                  value: event.fields.cvv.isValid ? 'false' : 'true',
                })

                if (event.fields.cvv.isValid) {
                  hostedFieldsInstance.removeClass('cvv', 'invalid')
                } else {
                  hostedFieldsInstance.addClass('cvv', 'invalid')
                }

                if (this.isCCValid) {
                  this.updateCreditCardIcon(event.cards[0].type)
                  this.refreshPaymentToken()
                }
              });
            });
          }

          window.braintree.client.create(
            { authorization: token },
            (err, clientInstance) => {
              if (err) {
                console.error(err);
                return;
              }

              createHostedFields(clientInstance)

              window.braintree.dataCollector.create(
                { client: clientInstance, kount: true },
                (error, dataCollectorInstance) => {
                  if (!error) {
                    sessionStorage.setItem('braintreeDeviceData', dataCollectorInstance?.deviceData)
                  }
                }
              );
            }
          );
        })
        .catch(() => {
          console.error('Fetch client braintree token issue')
        })
    },
    updateCreditCardIcon (cardType) {
      if (cardType) {
        this.creditCardIcon = `/images/credit-cards/${cardType.toLowerCase()}.svg`
      }
    },
    sendCard () {
      if (!this.validate()) { return }

      this.loading = true
      const creditCardData = this.getCreditCardData()
      const gRecaptcha = window.grecaptcha.enterprise

      gRecaptcha.ready(() => {
        gRecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'SUBMIT' })
          .then((token) => {
            Api.post('braintree/payment-methods', { ...creditCardData, recaptcha_token: token })
              .then(() => (this.$emit('onAddCard')))
              .catch(({ response }) => {
                const errorMessage = response?.data?.message || 'An error occurred, please contact our support'
                this.$cgToast.error(errorMessage, { html: true })
                this.refreshPaymentToken();
              })
              .finally(() => (this.loading = false))
          })
          .catch(() => (this.loading = false))
      })
    },
    // public
    validate () {
      const { isCCValid } = this
      const isBillingAddressValid = this.$refs.billingAddress?.validate()

      return isCCValid && isBillingAddressValid;
    },
    // public
    getCreditCardData () {
      const { creditCardData, billingForm, saveAsDefault } = this;

      return {
        payment: {
          paymentMethodNonce: creditCardData?.token,
          method: PaymentType.CC,
        },
        isDefault: saveAsDefault,
        billingAddress: { ...billingForm },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-credit-card-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > span {
    padding: 20px 0 10px;
    color: #95979D;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    line-height: normal;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
  }

  & button {
    border-radius: 100px;
  }
}

.cg-loader {
  max-height: 200px;
  margin: 200px auto 0;
}
</style>

<style lang="scss">
.hosted-field {
  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & label {
      color: #000;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 15px;
      line-height: normal;
    }
  }

  &__double-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }

  &__credit-card {
    position: relative;

    & > img {
      width: 40px;
      object-fit: contain;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & iframe {
    max-height: 40px;
    border: 1px solid #d3d2d2 !important;
  }
}
</style>
