<template>
  <div class="inventory-tray">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tray'
}
</script>

<style lang="scss">
.inventory-tray {
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .d-flex {
    gap: 4px;
  }
}
</style>
