var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inventory-status-list-view-item",class:{
    'inventory-status-list-view-item--root-variant': _vm.productVariants.length > 1,
    'inventory-status-list-view-item--simple': !_vm.superAdmin && !_vm.masterAccount,
    'inventory-status-list-view-item--bold-border': _vm.isLastVariantChild && !_vm.nextItemIsAVariant,
  }},[_c('div',{staticClass:"inventory-status-list-view-item__product"},[(!_vm.itemIsAVariantChild)?_c('list-view-item-images',{attrs:{"images":_vm.productImages}}):_c('div',{staticClass:"inventory-status-list-view-item__product-variant"},[_c('div',[_vm._v(" "+_vm._s(_vm.productVariantCode)+" ")])]),_c('div',[(_vm.productLink && !_vm.itemIsAVariantChild)?_c('a',{attrs:{"href":_vm.productLink,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.productName)+" ")]):_c('div',[_vm._v(_vm._s(_vm.productName))]),(_vm.productSku)?_c('div',[_vm._v(_vm._s(_vm.productSku))]):_vm._e()])],1),_c('div',{staticClass:"inventory-status-list-view-item__associated-campaigns"},[_c('ul',[_vm._l((_vm.campaignsToShow),function(campaign){return _c('li',{key:campaign.id},[_c('icons-campaigns'),_vm._v(" "+_vm._s(campaign.name)+" ")],1)}),(_vm.showMoreCampaignsLink)?_c('v-popover',{attrs:{"placement":"top","trigger":"hover","container":false}},[_c('li',{staticClass:"inventory-status-list-view-item__more-info-link"},[_c('icons-campaigns'),_vm._v(" More... ")],1),_c('div',{staticClass:"inventory-status-list-view-item__more-info-tooltip",attrs:{"slot":"popover"},slot:"popover"},[_c('ul',_vm._l((_vm.campaignsToShowInMoreInfo),function(campaign){return _c('li',{key:campaign.id},[_c('icons-campaigns'),_vm._v(" "+_vm._s(campaign.name)+" ")],1)}),0)])]):_vm._e()],2)]),_c('div',{staticClass:"inventory-status-list-view-item__units-wrapper",class:{
      'inventory-status-list-view-item__units-wrapper--no-variants': _vm.productVariants.length < 1
    }},[_c('tray-variants',{attrs:{"variants":_vm.productVariants,"showVariantsTooltip":_vm.productVariants.length > 1}},[_c('div',{staticClass:"inventory-status-list-view-item__units-info",style:(`--badge-color: ${_vm.availableUnits ? '#007E76' : '#F15B5B'}`)},[_vm._v(" "+_vm._s(_vm.availableUnits)+" ")])])],1),(_vm.superAdmin || _vm.masterAccount)?[_c('div',{staticClass:"inventory-status-list-view-item__units-wrapper",class:{
        'inventory-status-list-view-item__units-wrapper--no-variants': _vm.productVariants.length < 1
      }},[_c('tray-variants',{attrs:{"variants":_vm.productVariants,"show-on-hold":"","showVariantsTooltip":_vm.productVariants.length > 1}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Click to view where the on-hold items are',
            ..._vm.iconTooltipProps,
            offset: 8,
            classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',
          }),expression:"{\n            content: 'Click to view where the on-hold items are',\n            ...iconTooltipProps,\n            offset: 8,\n            classes: 'inventory-status__icon-tooltip inventory-status__icon-tooltip--dark',\n          }"}],staticClass:"inventory-status-list-view-item__units-info",class:{ 'inventory-status-list-view-item__units-info--link': _vm.onHoldUnits },staticStyle:{"--badge-color":"#D3D2D2"},attrs:{"aria-disabled":!_vm.superAdmin && !_vm.masterAccount},on:{"click":function($event){_vm.onHoldUnits && _vm.showProductUnitsOnHold()}}},[_vm._v(" "+_vm._s(_vm.onHoldUnits)+" ")])])],1),_c('div',{staticClass:"inventory-status-list-view-item__units-wrapper",class:{
        'inventory-status-list-view-item__units-wrapper--no-variants': _vm.productVariants.length < 1
      }},[_c('tray-variants',{attrs:{"variants":_vm.productVariants,"show-in-production":"","showVariantsTooltip":_vm.productVariants.length > 1}},[_c('div',{staticClass:"inventory-status-list-view-item__units-info",staticStyle:{"--badge-color":"#ECD885"}},[_vm._v(" "+_vm._s(_vm.unitsInProduction)+" ")])])],1)]:[_c('div',{staticClass:"inventory-status-list-view-item__units-wrapper"},[_c('div',{staticClass:"inventory-status-list-view-item__units-info inventory-status-list-view-item__units-info--outlined",staticStyle:{"--badge-color":"#F15B5B"}},[_vm._v(" "+_vm._s(_vm.productBalance || 0)+" ")])])],_c('div',{staticClass:"inventory-status-list-view-item__price"},[_vm._v(_vm._s(_vm._f("priceFilter")(_vm.costPerUnit)))]),_c('div',{staticClass:"inventory-status-list-view-item__price"},[_vm._v(_vm._s(_vm._f("priceFilter")(_vm.totalCost)))]),_c('div',{staticClass:"inventory-status-list-view-item__options"},[(!_vm.itemIsAKit && (_vm.superAdmin || _vm.masterAccount))?_c('icons-history',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: 'Transactions history', ..._vm.iconTooltipProps }),expression:"{ content: 'Transactions history', ...iconTooltipProps }"}],attrs:{"width":20,"height":20},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.showOrdersHistory()}}}):_vm._e(),(_vm.showItemSettings)?_c('list-view-item-settings',{attrs:{"isKit":_vm.itemIsAKit,"hasVariants":_vm.productVariants.length > 1},on:{"onKitPanel":function($event){return _vm.showKitPanel()},"onProductSettings":function($event){return _vm.showProductSettings()},"onOrderRefill":function($event){return _vm.showOrderRefill()},"onAddToCart":function($event){return _vm.addItemToCart(_vm.item)},"onAssignUsers":function($event){return _vm.showAssignUsers()},"onSendWithEgift":function($event){return _vm.sendItemAsEGift(_vm.item)}}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }