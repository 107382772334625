<template>
  <v-col
    :cols="cols"
    class="empty-image"
  >
    <slot />
  </v-col>
</template>

<script>
export default {
  name: 'EmptyImageCol',
  props: {
    cols: {
      type: [Number, String],
      required: false,
      default: 3
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-image {
  border-right: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
</style>
