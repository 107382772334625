<template>
  <v-dialog
    v-model="show"
    persistent
    :content-class="mobileVersion ? 'elevation-0 rounded-0 dialog-bottom' : 'elevation-0 rounded-0'"
    :width="mobileVersion ? null : 550"
    :transition="mobileVersion ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-container :class="{ 'pa-0': mobileVersion }">
      <v-row :dense="!mobileVersion" :no-gutters="mobileVersion">
        <v-col :cols="!mobileVersion ? 11 : 12">
          <v-card class="pa-8" rounded="0" v-if="!loading">
            <v-card-title class="pa-0 pb-7 d-flex justify-end" v-if="mobileVersion">
              <v-icon
                color="black"
                x-large
                @click="show = false"
                class="pointer"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-title class="pa-0">
              Create a new collection
            </v-card-title>

            <v-card-text class="px-0 py-7">
              <label for="list-name">List name</label>
              <common-input
                v-model="newListName"
                id="list-name"
                ref="list-name"
                size="30"
                maxlength="30"
                @keypress.enter="createNewList()"
              />

              <div class="create-list__error-text" v-if="isNewWishlistNameExists">
                You already have a collection with this name.
              </div>
            </v-card-text>

            <v-card-actions
              class="pa-0"
              :class="{
                'd-flex': mobileVersion,
                'g20': mobileVersion,
                'flex-column': mobileVersion,
              }"
            >
              <common-button
                outlined
                height="44"
                width="130"
                :class="{ 'ma-0': mobileVersion, 'order-2': mobileVersion }"
                class="favorites-button"
                :block="mobileVersion"
                @click="show = false"
              >
                Cancel
              </common-button>

              <common-button
                height="44"
                width="200"
                :class="{ 'ma-0': mobileVersion, 'order-1': mobileVersion }"
                class="favorites-button"
                :block="mobileVersion"
                :disabled="disableCTAButton"
                @click="createNewList()"
              >
                Create collection
              </common-button>
            </v-card-actions>
          </v-card>

          <v-card class="pa-12" rounded="0" v-else>
            <common-loader />
          </v-card>
        </v-col>
        <v-col cols="1" class="text-center" v-if="!mobileVersion">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Api from '@/axios/api'

import panelVModel from '@/mixins/panelVModel'

export default {
  name: 'TheMyFavoritesCreateListItemDialog',
  mixins: [panelVModel],
  props: {
    mobileVersion: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentWishlists: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data: () => ({
    loading: false,
    newListName: null,
  }),
  computed: {
    disableCTAButton () {
      const { newListName, isNewWishlistNameExists } = this

      return !newListName || newListName.length < 2 || isNewWishlistNameExists
    },
    wishlistsNames () {
      return this.currentWishlists?.map((item) => item.name) ?? []
    },
    isNewWishlistNameExists () {
      const { wishlistsNames, newListName } = this

      return wishlistsNames?.includes(newListName?.trim()) ?? false
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.newListName = null
        setTimeout(() => (this.$refs['list-name'].$children[0]?.focus()), 0)
      }
    }
  },
  methods: {
    createNewList () {
      if (this.disableCTAButton) return

      this.loading = true
      Api.post('/customer/wishlists', {
        name: this.newListName
      }).then(({ data, success }) => {
        if (success) {
          this.$emit('addNewItem', data)
          this.loading = false
          this.show = false
          this.$cgToast.successBold('Successfully created a new collection')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/favorites-button.scss";

label {
  font-family: 'Lato-bold', sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: #000;
}
.v-card {
  &__title {
    font-family: 'Lato-Bold', sans-serif;
    color: #000 !important;
    font-size: 24px;
    line-height: 22px;
    word-break: break-word !important;
  }
}
.create-list__error-text {
  font-family: 'Lato-Regular', sans-serif;
  padding-top: 4px;
  font-size: 14px;
  color: #FF5A60;
}
</style>
