<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 89"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="37" cy="52" r="37" fill="#D2F5F3"/>
    <circle cx="89.5" cy="40.5" r="6.5" fill="#D2F5F3"/>
    <circle cx="73.5" cy="9.5" r="9.5" fill="#D2F5F3"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.8054 53.0019C42.5102 53.0019 42.2714 53.2446 42.2714 53.5427C42.2714 53.8417 42.5102
        54.0827 42.8054 54.0827C44.6306 54.0827 46.0076 55.6229 46.0076 57.4714C46.0076 57.5332
        45.996 57.5927 45.9713 57.6475C45.8523 57.9206 45.9728 58.2399 46.2425 58.3612C46.3128
        58.3933 46.3862 58.4074 46.4588 58.4074C46.6636 58.4074 46.8583 58.2885 46.9472
        58.0865C47.0322 57.8925 47.0755 57.6859 47.0755 57.4714C47.0755 55.0273 45.2194 53.0019
        42.8054 53.0019ZM38.125 45.2385C38.5075 45.0092 38.9147 44.8933 39.3358 44.8933C40.9539
        44.8933 42.2714 46.6213 42.2714 48.7446C42.2714 49.1257 42.2297 49.4998 42.147
        49.8566C42.0798 50.1478 42.2583 50.4381 42.545 50.5062C42.5859 50.5164 42.6269 50.5211
        42.6671 50.5211C42.9097 50.5211 43.1284 50.3528 43.1863 50.1039C43.2876 49.6657 43.3393
        49.2086 43.3393 48.7446C43.3393 46.025 41.5435 43.8118 39.3358 43.8118C38.7293 43.8118
        38.1227 43.9839 37.5818 44.3079C37.3283 44.4598 37.2441 44.7916 37.394 45.0483C37.5447
        45.3058 37.8723 45.3903 38.125 45.2385ZM41.0969 55.1651C40.8017 55.1651 40.5629 55.4069
        40.5629 55.7051C40.5629 56.004 40.8017 56.2459 41.0969 56.2459C42.9213 56.2459 44.4065
        57.7492 44.4065 59.5978C44.4065 59.8357 44.2148 60.0298 43.9792 60.0298H31.4888C31.2539
        60.0298 31.0623 59.8357 31.0623 59.5978C31.0623 57.7492 32.5474 56.2459 34.3711
        56.2459C34.6663 56.2459 34.905 56.004 34.905 55.7051C34.905 55.4069 34.6663 55.1651 34.3711
        55.1651C31.9578 55.1651 29.9951 57.1529 29.9951 59.5978C29.9951 60.432 30.6658 61.1113
        31.4888 61.1113H43.9792C44.8029 61.1113 45.4744 60.432 45.4744 59.5978C45.4744 57.1529
        43.5109 55.1651 41.0969 55.1651ZM37.7348 46.9203C39.3528 46.9203 40.6703 48.6483 40.6703
        50.7723C40.6703 52.8955 39.3528 54.6243 37.7348 54.6243C36.1159 54.6243 34.7992 52.8955
        34.7976 50.7723C34.7976 48.6483 36.1151 46.9203 37.7348 46.9203ZM37.7348 55.7051C39.9424
        55.7051 41.7375 53.4926 41.7375 50.7723C41.7375 48.0519 39.9424 45.8395 37.7348
        45.8395C36.6429 45.8395 35.6229 46.3678 34.8641 47.328C34.1331 48.2539 33.7313 49.4763
        33.7313 50.7723C33.7313 53.4926 35.5271 55.7051 37.7348 55.7051Z"
      fill="#95979D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.7175 36.9572C51.1131 36.9572 51.4338 37.2819 51.4338 37.6826V66.6759C51.4338 67.0758
        51.1131 67.4006 50.7175 67.4006H25.3102C24.9153 67.4006 24.5946 67.0758 24.5946
        66.6759V61.9646H25.9693C26.5628 61.9646 27.0426 61.4771 27.0426 60.8776C27.0426 60.2773
        26.5628 59.7898 25.9693 59.7898H24.5946V53.2659H25.9693C26.5628 53.2659 27.0426 52.7792
        27.0426 52.1789C27.0426 51.5794 26.5628 51.0919 25.9693
        51.0919H24.5946V44.568H25.9693C26.5628 44.568 27.0426 44.0805 27.0426 43.481C27.0426
        42.8807 26.5628 42.3932 25.9693 42.3932H24.5946V37.6826C24.5946 37.2819 24.9153 36.9572
        25.3102 36.9572H50.7175ZM22.448 37.6826V42.3932H21.0733C20.4799 42.3932 20 42.8807 20
        43.481C20 44.0805 20.4799 44.568 21.0733 44.568H22.448V51.0919H21.0733C20.4799 51.0919 20
        51.5794 20 52.1789C20 52.7792 20.4799 53.2659 21.0733 53.2659H22.448V59.7898H21.0733C20.4799
        59.7898 20 60.2773 20 60.8776C20 61.4771 20.4799 61.9646 21.0733 61.9646H22.448V66.6759C22.448
        68.2772 23.7292 69.5755 25.3102 69.5755H50.7175C52.2985 69.5755 53.5804 68.2772 53.5804
        66.6759V37.6826C53.5804 36.0806 52.2985 34.7823 50.7175 34.7823H25.3102C23.7292 34.7823
        22.448 36.0806 22.448 37.6826Z"
      fill="#95979D"
    />
  </svg>
</template>

<script>
export default {
  name: 'Contacts',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 96
    },
    height: {
      type: [Number, String],
      required: false,
      default: 89
    }
  }
}
</script>
