<template>
  <v-form ref="form" class="send-egift-panel-more-options">
    <div v-if="!form.description" class="send-egift-panel-more-options__input-wrapper">
      <label for="description">
        Description

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_gift_description') }}
        </common-tooltip>
      </label>

      <common-input v-model="description" id="description" height="40" />
    </div>

    <div v-if="!form.videoGreeting" class="send-egift-panel-more-options__input-wrapper">
      <label for="video-greeting">
        Video greeting

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_video_greeting_link') }}
        </common-tooltip>
      </label>

      <common-input v-model="videoGreeting" id="video-greeting" height="40" />
    </div>

    <div class="send-egift-panel-more-options__radio-buttons">
      <common-radio v-model="actionLinkType" inputValue="calendly">
        Add a Calendar meeting link:

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_calendly_meeting_link') }}
        </common-tooltip>

        <template #non-clickable>
          <div class="send-egift-panel-more-options__radio-button-inputs">
            <common-input
              v-model="calendlyLink"
              :disabled="actionLinkType !== 'calendly'"
              placeholder="Enter your Calendar URL"
              height="40"
            />
          </div>
        </template>
      </common-radio>

      <common-radio v-model="actionLinkType" inputValue="free-form">
        Add a free form link:

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          You can add any URL to redirect your recipients
        </common-tooltip>

        <template #non-clickable>
          <div class="send-egift-panel-more-options__radio-button-inputs">
            <common-input
              v-model="freeForm.link"
              :disabled="actionLinkType !== 'free-form'"
              placeholder="https://"
              height="40"
            />

            <div class="d-flex flex-column">
              <label for="button-label">Set button label (max 20 characters):</label>

              <common-input
                v-model="freeForm.buttonText"
                :disabled="actionLinkType !== 'free-form'"
                id="button-label"
                placeholder="Go!"
                height="40"
                maxLength="20"
              />
            </div>
          </div>
        </template>
      </common-radio>
    </div>

    <div
      v-if="!form.shippingArrivalDate && !isPreProduction && false"
      class="send-egift-panel-more-options__input-wrapper"
    >
      <label for="shipping-arrival">
        Shipping arrival date

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_shipping_dispatch_date') }}
        </common-tooltip>
      </label>

      <single-date-range-picker
        v-model="shippingDate"
        id="shipping-arrival"
        placeholder="Choose an arrival date"
        style="position: relative;"
        set-min-date-week-later
        show-remove-item
        height="40"
        picker-style="--min-width: 300px;--picker-margin-top: 3px"
      />
    </div>
    
    <common-checkbox
      v-if="!form.settings.disableEmailReminders && !isSelectedGiftLink"
      v-model="disableReminderEmails"
      :disabled="!canOverrideDisableReminders"
    >
      <div style="display: flex; gap: 8px">
        Disable reminder emails to recipients

        <common-tooltip
          top
          :max-width="250"
          :z-index="8"
          :activator-size="20"
          activator-color="#62646A"
        >
          {{ getTooltipTextBySlug('campaigns_disable_reminder_emails') }}
        </common-tooltip>
      </div>
    </common-checkbox>

    <div class="send-egift-panel-more-options__input-wrapper">
      <label for="egift-expiration-time">eGift will expire after</label>

      <div class="send-egift-panel-more-options__expiration-time-input">
        <common-input
          v-model.number="expirationDays"
          :rules="[required(), campaignExpirationDaysValidation]"
          id="egift-expiration-time"
          style="max-width: 80px"
          height="40"
        />

        days
      </div>
    </div>

    <common-button width="300" height="44" @click="saveChanges()">
      Save changes
    </common-button>
  </v-form>
</template>

<script lang="ts">
import { Mixins, Component } from 'vue-property-decorator'
import { RootSteps } from '../../types'
import Vue from 'vue/types/umd';
import type { ICampaignSettings, ActionLinkType } from '../../types';

export type VForm = Vue & { validate: () => boolean }

import formProp from '../mixins/formProp'
import campaignProp from '../mixins/campaignProp'
import panelTypeProp from '../mixins/panelTypeProp'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'

import { required } from '@corporategift/design-system/validations'

import campaignExpirationDaysValidation from '@/utils/campaignExpirationDaysValidation'

@Component({
  mixins: [getTooltipTextBySlug],
  components: {
    SingleDateRangePicker,
  },
  methods: {
    required,
    campaignExpirationDaysValidation,
  },
})
export default class SendEgiftPanelMoreOptions extends Mixins(
  formProp,
  campaignProp,
  panelTypeProp,
) {
  description: string | null = null;

  videoGreeting: string | null = null;

  calendlyLink: string | null = null;

  disableReminderEmails = false;

  shippingDate: string | null = null;

  expirationDays: string | number = 90;

  actionLinkType: ActionLinkType = null;

  freeForm: { link: string | null; buttonText: string | null } = {
    link: null,
    buttonText: null,
  };

  get campaignSettings(): ICampaignSettings {
    return this.campaign?.egift_settings ?? {}
  }

  get canOverrideDisableReminders (): boolean {
    return this.campaignSettings.can_override_disable_recipient_reminders ?? false
  }

  get isPreProduction (): boolean {
    return this.campaign?.is_pre_production ?? false
  }

  created () {
    const {
      description,
      shippingArrivalDate,
      videoGreeting,
      calendlyLink,
      actionLinkType,
      freeForm,
      expirationDays,
      settings: { disableEmailReminders },
    } = this.form;

    this.description = description;
    this.videoGreeting = videoGreeting;
    this.calendlyLink = calendlyLink;
    this.disableReminderEmails = disableEmailReminders;
    this.shippingDate = shippingArrivalDate;
    this.actionLinkType = actionLinkType;
    this.expirationDays = expirationDays || 90;
    this.freeForm = { ...freeForm };
  }

  saveChanges (): void {
    if (!(this.$refs.form as VForm).validate()) { return }

    this.$emit('update:description', this.description);
    this.$emit('update:videoGreeting', this.videoGreeting);
    this.$emit('update:calendlyLink', this.calendlyLink);
    this.$emit('update:shippingArrivalDate', this.shippingDate);
    this.$emit('update:disableEmailReminders', this.disableReminderEmails);
    this.$emit('update:actionLinkType', this.actionLinkType);
    this.$emit('update:freeForm', this.freeForm);
    this.$emit('update:expirationDays', this.expirationDays);
    this.$emit('update:step', RootSteps.Review);
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-more-options {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 30px;
  padding-bottom: 200px;

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > label {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      color: #000000;
    }
  }

  &__expiration-time-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__radio-buttons {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &::v-deep .form_radio__custom > span.d-flex {
      gap: 8px;
    }
  }

  &__radio-button-inputs {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin: 8px 0 0 30px;

    & label {
      font-size: 15px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      color: #000000;
      padding-bottom: 8px;
    }
  }

  button {
    &::v-deep > span {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
    }
  }
}
</style>
