import ApiCMS from '@/axios/apiCMS'

export default {
  async getTooltips ({ commit }, type = 'Account') {
    return ApiCMS.get('/tooltips?populate=*&pagination[limit]=9999999', {
      params: {
        Type_eq: type
      }
    }).then(response => {
      commit('setTooltips', response.data)
    })
  }
}
