<template>
  <div class="approval-file-input__wrapper">
    <div v-if="selectedFile" class="approval-file-input__options">
      <icons-remove-file @click.native="removeFile()" />
    </div>

    <label
      class="approval-file-input__content"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        @change="onChange"
      >

      <icons-logo-placeholder v-if="!selectedFile" />

      <img v-else :alt="selectedFileName" :src="logoPreview" />
    </label>

    <div class="approval-file-input__name">
      {{ selectedFile ? selectedFileName : 'Upload file' }}
    </div>
  </div>
</template>

<script>
import Api from '@/axios/api'

export default {
  name: 'ApprovalFileInput',
  props: {
    value: {
      type: [File, String],
      required: false,
      default: null
    },
    rules: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data: () => ({
    logoPreview: null,
    selectedFileName: null
  }),
  computed: {
    selectedFile: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    selectedFile: {
      immediate: true,
      handler: function (val) {
        if (val && typeof val === 'object') {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            const { result } = reader
            this.logoPreview = result
          })
          reader.readAsDataURL(val)
        }

        if (val && typeof val === 'string') {
          this.logoPreview = val
        }
      }
    },
  },
  methods: {
    getInputElement () {
      return this.$el.querySelector('input')
    },
    removeFile () {
      this.selectedFile = null
      this.logoPreview = null
      this.getInputElement().value = null
    },
    async uploadFile (formData) {
      return Api.post('/campaigns/send-egift/set-tmp-picture', formData)
        .then(({ data }) => data?.url || null)
        .catch(() => null)
    },
    async onChange () {
      const file = this.getInputElement().files[0]

      if (file) {
        const formData = new FormData()
        this.selectedFileName = file.name
        formData.append('logo', file)

        await this.uploadFile(formData).then((path) => { this.selectedFile = path })
      } else {
        this.selectedFileName = null
        this.selectedFile = null
      }
    },
    dragover (event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('approval-file-input__content--file-hover')) {
        event.currentTarget.classList.add('approval-file-input__content--file-hover')
      }
    },
    dragleave (event) {
      event.currentTarget.classList.remove('approval-file-input__content--file-hover')
    },
    drop (event) {
      event.preventDefault()
      this.getInputElement().files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('approval-file-input__content--file-hover')
    },
    validate () {
      const { value, rules } = this
      const errors = [];

      rules?.forEach((fnc) => {
        const valid = fnc(value);
        if (valid === false || typeof valid === 'string') { errors.push(valid || ''); }
      })
      return !errors.length;
    }
  },
}
</script>

<style lang="scss" scoped>
.approval-file-input {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-width: 200px;
    max-width: 200px;
    min-height: 240px;
    max-height: 240px;

    &:hover {
      & > .approval-file-input__options {
        height: 38px;
        padding: 8px;
      }
    }

    & input {
      width: 1px;
      height: 1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
    }
  }

  &__options {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 0 8px;
    background: rgba(255, 255, 255, 0.8);
    transition: height ease-in-out 0.08s;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    & > svg {
      cursor: pointer;
    }
  }

  &__content {
    min-width: 200px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1.4px dashed #95979D;
    background-color: #FAFAFA;
    transition: box-shadow ease-in-out 0.3s;

    & > img {
      min-width: 200px;
      max-width: 200px;
      min-height: 200px;
      max-height: 200px;
      object-fit: contain;
      background-color: #fff;
      border: 1px solid #D3D2D2;
    }

    &:has(img) {
      pointer-events: none;
      cursor: default;
      border-color: #fff;
    }

    &:hover, &--file-hover {
      border-style: solid;
      border-width: 3px;
      border-color: #A7DAD8;
      box-shadow: 0px 0px 26.0811px rgba(0, 0, 0, 0.15);
    }
  }

  &__name {
    color: #62646A;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 25px;
    text-align: center;
  }
}
</style>
