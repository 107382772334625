<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    version="1.1"
    :height="height"
    :width="width"
  >
    <g id="CG-Credits" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Manage-autoreload" transform="translate(-1240.000000, -786.000000)">
        <g id="Pay-with-CC" transform="translate(742.000000, 600.000000)">
          <g id="saved-address---primary-copy" transform="translate(2.000000, 154.000000)">
            <g id="Group-3" transform="translate(497.000000, 33.000000)">
              <g id="OFF---check">
                <g>
                  <circle
                    id="Oval"
                    :stroke="!filled && outlineColor"
                    :stroke-width="!filled && outlineStrokeWidth"
                    :fill="filled ? filledColor : fillColor"
                    cx="10.858802"
                    cy="10.858802"
                    r="10.858802"
                  />
                  <g
                    id="v"
                    transform="translate(6.949633, 8.252690)"
                    :stroke="filled ? filledCheckColor : outlineCheckColor"
                    stroke-linecap="round"
                    stroke-linejoin="bevel"
                    :stroke-width="checkStrokeWidth"
                    v-if="!hideCheck"
                  >
                    <line x1="2.59453921" y1="5.07788555" x2="7.69624371" y2="-6.35239916e-05" id="Stroke-2" />
                    <line x1="2.55074056" y1="5.07788555" x2="4.78673719e-05" y2="2.53914923" id="Stroke-5" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CheckCircle',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 23
    },
    height: {
      type: [Number, String],
      required: false,
      default: 23
    },
    filled: {
      type: Boolean,
      required: false,
      default: false
    },
    filledColor: {
      type: String,
      required: false,
      default: '#42B77A'
    },
    filledCheckColor: {
      type: String,
      required: false,
      default: '#FFFFFF'
    },
    fillColor: {
      type: String,
      required: false,
      default: null
    },
    outlineColor: {
      type: String,
      required: false,
      default: '#979797'
    },
    outlineCheckColor: {
      type: String,
      required: false,
      default: '#9B9B9B'
    },
    outlineStrokeWidth: {
      type: [String, Number],
      required: false,
      default: 0.5544
    },
    checkStrokeWidth: {
      type: [String, Number],
      required: false,
      default: 1.1088
    },
    hideCheck: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
