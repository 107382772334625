import Vue, { PropType } from 'vue'
import { IRecipient } from '../../types';

export default Vue.extend({
  props: {
    recipients: {
      type: Array as PropType<Array<IRecipient>>,
      required: false,
      default: () => ([]),
    },
    singleGiftLinkRecipients: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    numberOfRecipients (): number {
      const { recipients, singleGiftLinkRecipients } = this

      return recipients?.length || singleGiftLinkRecipients || 0
    }
  },
});
