<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    width="680"
  >
    <v-container v-if="contacts">
      <v-row dense>
        <v-col cols="11" v-if="loading">
          <v-card class="pa-8 rounded-0">
            <common-loader style="padding: 80px 0" />
          </v-card>
        </v-col>
        <v-col cols="11" v-else>
          <v-card class="pa-8 rounded-0">
            <v-card-title class="pa-0">
              <template v-if="!isContactAGorup && !deleteMultiple && userIsInGroupPanel">
                <span class="lato-light f30 break-word">
                  Are you sure you want to remove this contact from the group?
                </span>
              </template>

              <template v-if="!isContactAGorup && !deleteMultiple && !userIsInGroupPanel">
                <span class="lato-light f30 break-word">
                  Are you sure you want to remove this contact?
                </span>
              </template>

              <template v-if="isContactAGorup && !deleteMultiple && !userIsInGroupPanel">
                <span class="lato-light f30">
                  Delete group
                </span>
              </template>

              <template v-if="deleteMultiple">
                <span class="lato-light f30 break-word">
                  Are you sure you want to delete {{ contacts.length }} selected
                  {{ userSelectOnlyContacts
                    ? 'contacts'
                      : userSelectOnlyGroups
                      ? 'groups'
                    : 'contacts and groups'
                  }}?
                </span>
              </template>
            </v-card-title>

            <v-card-text class="px-0 py-6">
              <template v-if="!isContactAGorup && !deleteMultiple">
                <div class="pa-4" style="background: #F5F5F5">
                  <contact-info-card :contact-info="contacts[0]" />
                </div>
              </template>

              <template v-if="isContactAGorup && !deleteMultiple">
                <div class="d-flex flex-column lato-bold black1 f15">
                  <span class="lato-bold">
                    There are currently {{ contacts[0].addresses.length }} Contacts assigned to this group.
                    You can choose to delete the group but keep the contacts currently assigned to it
                    in your address book, or delete the group and all contacts in it from your address book.
                  </span>

                  <span class="lato-bold pt-6">
                    What would you like to do?
                  </span>
                </div>
              </template>

              <template v-if="deleteMultiple">
                <div class="d-flex flex-column lato-bold black1 f15">
                  <span class="lato-bold">
                    Please note that individual contacts in selected groups will NOT be deleted from your address book.
                  </span>
                </div>
              </template>
            </v-card-text>

            <v-card-actions class="px-0 py-3" v-if="isContactAGorup">
              <common-button
                outlined
                height="40"
                width="390"
                color="red3"
                @click="deleteContactsAndGroup()"
              >
                Delete this group and all contacts in it
              </common-button>
            </v-card-actions>

            <v-card-actions class="pa-0">
              <common-button
                height="40"
                :width="isContactAGorup ? 390 : 130"
                @click="isContactAGorup ? deleteGroupButKeepContacts() : removeContact()"
              >
                {{ isContactAGorup ? 'Delete this group but keep contacts' : 'Yes, remove' }}
              </common-button>

              <common-button
                outlined
                class="ml-4"
                height="40"
                width="130"
                @click="show = false"
              >
                Cancel
              </common-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="1" class="text-center">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Api from '@/axios/api'

import ContactInfoCard from './ContactInfoCard.vue'

import panelVModel from '@/mixins/panelVModel'
import { group, contacts, isAddressAnGroup } from './mixins'

export default {
  name: 'TheDeleteContactGroupModal',
  components: {
    ContactInfoCard
  },
  mixins: [
    isAddressAnGroup,
    panelVModel,
    contacts,
    group
  ],
  props: {
    userIsInGroupPanel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    isSelectedOneItem () {
      const { length } = this.contacts

      return length === 1
    },
    userSelectOnlyContacts () {
      const { contacts } = this
      if (!Array.isArray(contacts)) return true
      const filteredContacts = contacts?.filter((contact) => contact?.firstname) || []

      return contacts?.length === filteredContacts?.length
    },
    userSelectOnlyGroups () {
      const { contacts } = this
      if (!Array.isArray(contacts)) return false
      const filteredGroups = contacts?.filter((contact) => contact?.addresses) || []

      return contacts?.length === filteredGroups?.length
    },
    isContactAGorup () {
      const { isSelectedOneItem, contacts } = this

      if (isSelectedOneItem) return !!contacts[0]?.addresses
      return false
    },
    deleteMultiple () {
      return !this.isSelectedOneItem
    }
  },
  watch: {
    show: function (val) {
      if (!val) Object.assign(this.$data, this.$options.data())
    }
  },
  methods: {
    deleteContactsAndGroup () {
      const [group] = this.contacts
      this.loading = true
      const promise = this.deleteGroup(group.entity_id)
      promise.then(() => {
        this.$emit('fetchData')
        this.showSuccessMessage('Successfully removed group with contacts')
        this.show = false
      })
    },
    deleteGroupButKeepContacts () {
      const [group] = this.contacts
      this.loading = true
      const promise = this.deleteGroup(group.entity_id, true)
      promise.then(() => {
        this.$emit('fetchData')
        this.showSuccessMessage('Successfully removed group and kept contacts')
        this.show = false
      })
    },
    removeContact () {
      this.loading = true

      if (this.userIsInGroupPanel) {
        const groupId = this.group.entity_id
        const addresses = this.contacts.map((contact) => (contact.entity_id))

        this.deleteContactFromGroup(groupId, addresses).then(() => {
          this.$emit('fetchData')
          this.showSuccessMessage('Successfully removed contacts from group')
          this.show = false
        })
      } else {
        const groupsIds = this.contacts.reduce((acc, contact) => {
          if (this.isAddressAnGroup(contact)) acc.push(contact.entity_id)
          return acc
        }, [])
        const contactsIds = this.contacts.reduce((acc, contact) => {
          if (!this.isAddressAnGroup(contact)) acc.push(contact.entity_id)
          return acc
        }, [])

        if (contactsIds.length) {
          this.bulkDeleteContact(contactsIds).then(() => {
            if (groupsIds.length) return this.bulkDeleteGroup(groupsIds)
          }).finally(() => {
            this.$emit('fetchData')
            this.showSuccessMessage('Successfully removed contacts and groups')
            this.show = false
          })
        } else if (groupsIds.length) {
          this.bulkDeleteGroup(groupsIds).then(() => {
            this.$emit('fetchData')
            this.showSuccessMessage('Successfully removed contacts and groups')
            this.show = false
          })
        }
      }
    },
    deleteContactFromGroup (groupId, addresses) {
      return Api.patch(`/customer/address-book/groups/${groupId}/detach-addresses`, {
        ids: addresses
      })
    },
    bulkDeleteContact (contactIds) {
      return Api.patch('/customer/address-book/addresses/bulk-delete', {
        ids: contactIds
      })
    },
    bulkDeleteGroup (groupIds) {
      return Api.patch('/customer/address-book/groups/bulk-delete', {
        ids: groupIds
      })
    },
    deleteGroup (groupId, keepAddresses = false) {
      return Api.delete(`/customer/address-book/groups/${groupId}`, {
        data: { keep_addresses: keepAddresses }
      })
    },
    showSuccessMessage (message) {
      this.$cgToast.successBold(message)
    }
  }
}
</script>
