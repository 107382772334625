<template>
  <span class='base-pagination-trigger' @click="onClick">
    {{ pageNumber !== 100000 ? pageNumber : '...'}}
  </span>
</template>

<script>
export default {
  props: {
    pageNumber: {
      type: Number,
      required: true
    }
  },

  methods: {
    onClick () {
      this.$emit('loadPage', this.pageNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
$c-primary-accent--dark: black;

.base-pagination-trigger {
  cursor: pointer;
  transition: all 300ms ease;
  color: #b8b8b8;

  &:hover {
    color: $c-primary-accent--dark;
  }
}
</style>
