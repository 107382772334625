<template>
  <table class="price-table">
    <tr class="subtitle">
      <th>Quantity</th>
      <th>Price</th>
    </tr>
    <template v-for="(price, index) in prices">
      <tr :key="index">
        <td v-if="price.min_qty && price.max_qty">{{ price.min_qty }} - {{ price.max_qty }}</td>
        <td v-else>{{ price.min_qty }}+</td>
      <td>
        {{ price.price | priceFilter({
          style: 'currency',
          currency: 'USD'
        }) }}
      </td>
      </tr>
    </template>
  </table>
</template>

<script>
export default {
  name: 'RefillPriceTable',
  props: {
    prices: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.price-table {
  border-collapse: collapse;
  width: 100%;
  th {
    border-bottom: 1px solid #EAEAEA;
    padding: 0 8px 8px;

    &:nth-child(odd) {
      text-align: left;
    }
  }
  td {
    border-bottom: 1px solid #EAEAEA;
    padding: 4px 8px;
    &:nth-child(odd) {
      border-right: 1px solid #EAEAEA;
      text-align: left;
    }

    &:nth-child(even) {
      text-align: center;
    }
  }
}
</style>
