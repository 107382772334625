var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[_vm._v(" Logo "),_c('popper',{attrs:{"options":{
    placement: 'top',
    modifiers: {
      offset: {
        offset: '0,5px'
      },
    }
  }}},[_c('div',{staticClass:"popper pa-6 px-4",staticStyle:{"max-width":"200px"}},[_c('v-img',{attrs:{"src":_vm.logoUrl,"alt":"Logo preview","contain":"","min-height":"50","min-width":"50"}})],1),_c('div',{staticClass:"d-flex ml-1",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"18","height":"18"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }