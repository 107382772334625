<template>
  <div class="multiple-users-upload-options-card">
    <div class="multiple-users-upload-options-card__icon">
      <slot name="icon" />
    </div>

    <div class="multiple-users-upload-options-card__text">
      <slot name="text" />
    </div>

    <div v-if="showSubText" class="multiple-users-upload-options-card__sub-text">
      <slot name="sub-text" />
    </div>

    <common-button
      outlined
      :height="34"
      @click="$emit('onButtonClick')"
    >
      <slot name="button" />
    </common-button>
  </div>
</template>

<script>
export default {
  name: 'MultipleUsersUploadOptionsCard',
  props: {
    showSubText: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.multiple-users-upload-options-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  align-items: center;
  gap: 20px;
  padding: 30px 24px;
  border: 1px solid #F4F4F4;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);

  &__icon {
    svg > {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
    }
  }

  &__text {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    flex-grow: 1;
    line-height: 20.5px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #222325;
  }

  &__sub-text {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    flex-grow: 1;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    color: #42B77A;

    & > a {
      color: inherit !important;
    }

    &:hover {
      color: #219358;
    }
  }

  & > button {
    font-family: 'Lato-Bold', sans-serif;
    text-transform: unset;
    font-size: 15px;
    line-height: 18px;

    &::v-deep > span {
      font-family: inherit;
      line-height: inherit;
      font-size: inherit;
    }
  }
}
</style>
