export default () => ({
  users: [],
  selectedIds: [],
  currentPage: 1,
  loading: false,
  meta: {
    counts: {
      admin: null,
      subaccount: null,
    },
    currentPage: null,
    from: null,
    lastPage: null,
    perPage: null,
    to: null,
    total: null,
  },
  links: {
    first: null,
    last: null,
    next: null,
    prev: null,
  },
  filters: {
    search: null,
    sort: 'first_name',
  },
  show: {
    inactiveUsers: false,
    subAccounts: true,
    admins: true,
  },
  sortOptions: [
    { text: 'First Name', value: 'first_name' },
    { text: 'Last Name', value: 'last_name' },
    { text: 'Email', value: 'email' }
  ],
})
