export default {
  methods: {
    takeCurrentDate () {
      const date = new Date()
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      return date.toISOString()
    }
  }
}
