import Vue, { PropType } from 'vue'
import { ISendEgiftForm } from '../../types/ISendEgiftForm';

export default Vue.extend({
  props: {
    form: {
      type: Object as PropType<ISendEgiftForm>,
      required: false,
    },
  },
});
