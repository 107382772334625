<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <mask
      id="mask0_1252_5469"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="21"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10.5001C0 15.9954 4.51887 20.4501 10.0931 20.4501V20.4501C15.6677 20.4501 20.1866
          15.9954 20.1866 10.5001V10.5001C20.1866 5.00458 15.6677 0.549805 10.0931
          0.549805V0.549805C4.51887 0.549805 0 5.00458 0 10.5001V10.5001ZM0.960305 10.5001C0.960305
          5.52779 5.04944 1.49749 10.0924 1.49749V1.49749C15.1359 1.49749 19.2242 5.52779 19.2242
          10.5001V10.5001C19.2254 13.051 18.144 15.3499 16.4135 16.9877V16.9877C16.2296 16.7206
          16.0344 16.4528 15.8609 16.1857V16.1857C15.8609 16.1857 15.1034 14.9004 12.0158
          14.7643V14.7643C11.7058 14.7643 11.5509 14.5641 11.5509 14.2591V14.2591V13.8167C11.5509
          13.7631 11.5604 13.7158 11.562 13.6641V13.6641C11.9185 13.5012 12.3254 13.2737 12.7842
          12.932V12.932C12.7842 12.932 13.9379 12.2112 13.9379 11.4474V11.4474C13.9379 11.4474
          14.4185 9.00164 14.4185 8.13079V8.13079C14.4185 5.93005 13.4189 3.86662 10.0928
          3.86662V3.86662C6.72163 3.86662 5.76708 5.93005 5.76708 8.13079V8.13079C5.76708 9.02385
          6.24783 11.4474 6.24783 11.4474V11.4474C6.24783 12.2323 7.40131 12.932 7.40131
          12.932V12.932C7.86986 13.2543 8.27401 13.478 8.62156 13.6398V13.6398C8.62444 13.6991
          8.63461 13.7546 8.63461 13.8167V13.8167C8.63461 13.8167 8.63461 14.0119 8.63546
          14.2591V14.2591H8.63461C8.63461 14.5648 8.47973 14.7643 8.16979 14.7643V14.7643C5.12722
          14.8855 4.32485 16.1857 4.32485 16.1857V16.1857C4.15217 16.4528 3.95645 16.7206 3.7731
          16.9877V16.9877C2.04262 15.3499 0.960305 13.051 0.960305 10.5001V10.5001ZM4.5021
          17.6072C4.57801 17.4974 4.67274 17.3618 4.74916 17.2555V17.2555C4.88219 17.0684 5.01589
          16.8827 5.13688 16.6946V16.6946C5.13789 16.6946 5.82809 15.8062 8.21063
          15.7109V15.7109H8.21148C8.99826 15.7109 9.59729 15.1002 9.59729 14.2591V14.2591L9.61288
          13.8199C9.61271 13.8189 9.61271 13.8179 9.61254 13.8167V13.8167C9.61254 13.8049 9.61271
          13.799 9.61305 13.797V13.797V13.343L9.13247 12.8689C8.78407 12.7059 8.33162 12.4155
          7.95255 12.1546V12.1546L7.90629 12.125C7.59297 11.9317 7.23965 11.5811 7.20898
          11.4319V11.4319L7.1922 11.2652C7.01394 10.3631 6.72925 8.71381 6.72925
          8.1308V8.1308C6.72925 5.86841 7.79886 4.81414 10.0936 4.81414V4.81414C12.3579 4.81414
          13.4582 5.89881 13.4582 8.1308V8.1308C13.4582 8.69794 13.1745 10.3554 12.9952
          11.267V11.267L12.9771 11.4474C12.9405 11.5884 12.5499 11.9565 12.2699
          12.1314V12.1314L12.2052 12.1748C11.8522 12.4387 11.4062 12.7079 11.0539
          12.8689V12.8689L10.5735 13.343V13.8C10.5734 13.7992 10.5732 13.7989 10.5732
          13.7989V13.7989C10.5723 13.7989 10.5715 13.8086 10.571 13.8183V13.8183C10.57 13.8348
          10.57 13.8513 10.5735 13.8198V13.8198V13.8167V13.8C10.5737 13.802 10.5739 13.8072 10.5739
          13.8167V13.8167C10.5737 13.8177 10.5735 13.8188 10.5735 13.8198V13.8198L10.5893
          14.2591C10.5903 15.101 11.19 15.712 12.0153 15.712V15.712C14.3876 15.8175 15.0456 16.6884
          15.0519 16.6976V16.6976C15.171 16.8818 15.3032 17.0684 15.4369 17.2555V17.2555C15.5128
          17.3621 15.6084 17.4974 15.684 17.6072V17.6072C14.1384 18.7907 12.2028 19.5025 10.0931
          19.5025V19.5025C7.98475 19.5025 6.04838 18.7907 4.5021 17.6072V17.6072ZM9.61305
          13.797V13.8167L9.61288 13.8199C9.61576 13.8472 9.61576 13.8314 9.61508
          13.8154V13.8154C9.61457 13.8057 9.6139 13.796 9.61339 13.796V13.796C9.61322 13.796
          9.61305 13.7964 9.61305 13.797V13.797Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1252_5469)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.651123 21.0921H20.8378V-0.092041H-0.651123V21.0921Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Account extends Vue {
  @Prop({ default: 21 }) readonly width!: number | string;

  @Prop({ default: 21 }) readonly height!: number | string;
}
</script>
