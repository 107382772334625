<template>
  <v-navigation-drawer
    class="my-users-cart-panel"
    v-lock-scroll="show"
    v-model="show"
    width="650px"
    temporary
    right
    app
  >
    <div class="my-users-cart-panel__title">
      Items in cart

      <div class="my-users-cart-panel__title-icon" @click="show = false">
        <icons-close color="#62646A" :width="17" :height="17" />
      </div>
    </div>

    <div class="my-users-cart-panel__content">
      <template v-if="!loading">
        <div class="my-users-cart-panel__item" v-for="(item, index) in cartItems" :key="index">
          <v-img
            :alt="item.name"
            :src="getProductImage(item)"
            :min-width="120"
            :max-width="120"
            :min-height="120"
            :max-height="120"
            contain
          />

          <div class="cart-panel-item-info">
            <div class="cart-panel-item-info__name-customization">
              <div>
                <a :href="item.redirect_url" target="_blank">{{ item.name }}</a>
              </div>

              <ul v-if="productHasPersonalizationOrCustomization(item)">
                <li v-for="(personalization, j) in item.personalizations" :key="j">
                  {{ personalization.title }}
                </li>
                <li v-for="(configuration, k) in item.configurations" :key="k">
                  {{ configuration.label }}: {{ configuration.value }}
                </li>
              </ul>

              <a
                v-if="item.redirect_url &&
                  item.personalizations &&
                  item.personalizations.length"
                :href="item.redirect_url"
                target="_blank"
              >
                Customized
              </a>
            </div>
            <div class="cart-panel-item-info__qty">{{ item.qty }}</div>
            <div class="cart-panel-item-info__prices">
              <div>{{ item.row_total | priceFilter }}</div>

              <div class="price-per-item" v-if="item.qty > 1">
                ({{ item.price | priceFilter }} each)
              </div>
            </div>
          </div>
        </div>
        
        <div class="my-users-cart-panel__empty" v-if="!cartItems.length">
          <icons-empty-cart />

          No items in cart
        </div>
      </template>

      <common-loader v-else style="padding: 200px 0" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import panelVModel from '@/mixins/panelVModel'
export default {
  name: 'MyUsersCartPanel',
  mixins: [panelVModel],
  props: {
    selectedUserId: {
      type: [String, Number],
      required: false,
      default: null
    },
  },
  data: () => ({
    loading: false,
    cartItems: [],
  }),
  watch: {
    show: function (val) {
      if (val) {
        this.fetchUserCart()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  methods: {
    fetchUserCart () {
      this.loading = true
      Api.get(`/customer/subaccounts/cart/${this.selectedUserId}`)
        .then(({ items }) => {
          this.cartItems = items ?? []
        })
        .catch((e) => (console.error(e)))
        .finally(() => (this.loading = false))
    },
    productHasPersonalizationOrCustomization ({ configurations, personalizations }) {
      return !!(configurations?.length || personalizations?.length)
    },
    getProductImage({ image }) {
      if (!image) return '/images/empty-order.png'

      return image.includes('no_selection') ? '/images/empty-order.png' : image
    }
  }
}
</script>

<style lang="scss" scoped>
.my-users-cart-panel {
  padding: 50px;

  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 44px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    color: #000;
    font-family: 'Lato-Light', sans-serif;
    justify-content: space-between;
    font-size: 40px;
    line-height: 44px;
    gap: 12px;

    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 30px 0;
    gap: 15px;
    border-bottom: 1px solid #D3D2D2;

    & > div {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 40px;
      justify-content: space-between;
    }

    &:first-of-type {
      border-top: 1px solid #D3D2D2;
    }

    .cart-panel-item-info {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #000;

      &__name-customization {
        display: flex;
        flex-direction: column;
        max-width: 200px;
        min-width: 200px;
        gap: 12px;

        & > div {
          line-height: 20px;
        }

        & > ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          color: #979797;
        }

        & > a {
          color: #42B77A !important;
          text-decoration: none;
        }
      }

      &__qty {
        display: flex;
      }

      &__prices {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 100px;
        gap: 4px;

        & > div {
          &:first-of-type {
            font-family: 'Lato-Bold', sans-serif;
          }

          &.price-per-item {
            font-size: 14px;
            color: #95979D;
          }
        }
      }
    }
  }

  &__empty {
    height: calc(100vh - 100px - 44px - 44px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979d;
  }
}
</style>