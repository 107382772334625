<template>
  <div class="payment-module-cc-container">
    <div v-if="isSelectedAlreadySavedCard">
      <credit-card-item :data="paymentData" />

      <div
        class="payment-module-cc-container__link"
        @click="$emit('changeSavedPayment', 'gene_braintree_creditcard')"
      >
        Change card
      </div>
    </div>

    <add-credit-card-form
      v-else
      ref="creditCardForm"
      :show-add-card-button="false"
      :show-set-as-default="false"
      @update:paymentData="(val) => $emit('update:paymentData', val)"
    />
  </div>
</template>

<script>
import AddCreditCardForm from '@/components/payment/AddCreditCardForm.vue'
import CreditCardItem from '@/components/payment/CreditCardItem.vue'

export default {
  name: 'CreditCardMethod',
  components: {
    AddCreditCardForm,
    CreditCardItem,
  },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    isSelectedAlreadySavedCard () {
      const { paymentData } = this
      return paymentData
        && Object.hasOwn(paymentData, 'token')
        && Object.hasOwn(paymentData, 'cardLastFourDigits')
    },
  },
  methods: {
    validate () {
      const { isSelectedAlreadySavedCard, paymentData } = this

      if (isSelectedAlreadySavedCard) {
        return paymentData !== null
      } else {
        return this.$refs?.creditCardForm?.validate()
      }
    },
    refreshPaymentToken () {
      this.$refs?.creditCardForm?.refreshPaymentToken()
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-module-cc-container {
  &::v-deep .cg-loader {
    margin: 0 auto;
  }

  &__link {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin-top: 14px;
    line-height: 18px;
    color: #42B77A;
    cursor: pointer;
    width: fit-content;
  }

  &::v-deep .credit-card-item {
    border: 1px solid #D3D2D2;
    cursor: default;
    background-color: #fff;

    &__actions {
      display: none;
    }
  }
}
</style>
