<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 18"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.81969 16.4556L1.26624 8.56867L5.81969 0.681763H14.9266L19.4801 8.56867L14.9266
        16.4556H5.81969Z"
      stroke="currentColor"
      stroke-width="1.29545"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.64465 4.34851H9.60465V10.8936H12.7461V11.7009H8.64465V4.34851Z"
      fill="currentColor"
    />
    <path d="M11.905 4.92883H14.0454" stroke="currentColor" stroke-width="1.29545"/>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SignOut extends Vue {
  @Prop({ default: 21 }) readonly width!: number | string;

  @Prop({ default: 19 }) readonly height!: number | string;
}
</script>
