<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="20" cy="20" r="20" fill="#FAE3E3"/>
    <g clip-path="url(#clip0_4152_52638)">
      <path
        d="M26.6666 16.6667H24.4443V17.7779H26.6666V18.889H27.7777V17.7779C27.7777 17.4832 27.6606
          17.2006 27.4522 16.9922C27.2439 16.7838 26.9612 16.6667 26.6666 16.6667Z"
        fill="black"
      />
      <path d="M26.6667 20H27.7779V23.3333H26.6667V20Z" fill="black"/>
      <path
        d="M26.6668 26.6666H25.5557V27.7777H26.6668C26.9615 27.7777 27.2441 27.6606 27.4524
          27.4522C27.6608 27.2439 27.7779 26.9612 27.7779 26.6666V24.4443H26.6668V26.6666Z"
        fill="black"
      />
      <path
        d="M23.3333 22.2222V13.3333C23.3333 13.0386 23.2162 12.756 23.0078 12.5476C22.7995 12.3392
          22.5169 12.2222 22.2222 12.2222H13.3333C13.0386 12.2222 12.756 12.3392 12.5476
          12.5476C12.3392 12.756 12.2222 13.0386 12.2222 13.3333V22.2222C12.2222 22.5169 12.3392
          22.7995 12.5476 23.0078C12.756 23.2162 13.0386 23.3333 13.3333 23.3333H22.2222C22.5169
          23.3333 22.7995 23.2162 23.0078 23.0078C23.2162 22.7995 23.3333 22.5169 23.3333
          22.2222ZM13.3333 13.3333H22.2222V22.2222H13.3333V13.3333Z"
        fill="black"
      />
      <path d="M21.1111 26.6667H24.4444V27.7779H21.1111V26.6667Z" fill="black"/>
      <path
        d="M17.7779 24.4443H16.6667V26.6666C16.6667 26.9612 16.7838 27.2439 16.9922 27.4522C17.2006
          27.6606 17.4832 27.7777 17.7779 27.7777H20.0001V26.6666H17.7779V24.4443Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4152_52638">
        <rect width="20" height="20" fill="white" transform="translate(10 10)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CopyPaste extends Vue {
  @Prop({ required: false, default: 40 }) width!: number | string;

  @Prop({ required: false, default: 40 }) height!: number | string;
}
</script>
