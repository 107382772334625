<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0001 19.5221C5.02522 19.5221 0.977905 15.4746 0.977905 10.4999C0.977905 5.52509 5.02522
        1.47788 10.0001 1.47788C14.975 1.47788 19.0221 5.52509 19.0221 10.4999C19.0221 15.4746
        14.975 19.5221 10.0001 19.5221ZM10.0001 0.5C4.48618 0.5 0 4.98603 0 10.4999C0 16.0139
        4.48618 20.5 10.0001 20.5C15.5141 20.5 20 16.0139 20 10.4999C20 4.98603 15.5141 0.5
        10.0001 0.5Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99998 8.77588C9.80964 8.77588 9.65515 8.99577 9.65515 9.2667V15.1816C9.65515 15.4525
        9.80964 15.6724 9.99998 15.6724C10.1903 15.6724 10.3448 15.4525 10.3448
        15.1816V9.2667C10.3448 8.99577 10.1905 8.77588 9.99998 8.77588Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0024 6.70695C9.80753 6.70695 9.65523 6.55585 9.65523 6.36288C9.65523 6.16918 9.80792
        6.01729 10.0024 6.01729C10.1911 6.01729 10.3449 6.17227 10.3449 6.36288C10.3449 6.55237
        10.1911 6.70695 10.0024 6.70695ZM10.0021 5.32764C9.43026 5.32764 8.96558 5.79199 8.96558
        6.36276C8.96558 6.93292 9.43026 7.3966 10.0021 7.3966C10.5712 7.3966 11.0345 6.93292 11.0345
        6.36276C11.0345 5.79199 10.5712 5.32764 10.0021 5.32764Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Info',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 22
    },
    height: {
      type: [Number, String],
      required: false,
      default: 22
    },
    color: {
      type: String,
      required: false,
      default: '#4A4A4A'
    },
  }
}
</script>
