<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    stateless
    right
    width="900px"
  >
    <unsaved-changes-alert
      v-if="userHasUnsavedChanges"
      @saveChanges="userHasUnsavedChanges = false"
      @closePanel="show = false"
    >
      <template #default>
        You haven't saved your changes. Exit anyway?
      </template>

      <template #exit>
        Exit
      </template>

      <template #save>
        Stay
      </template>
    </unsaved-changes-alert>

    <close-panel-button
      @close="onCloseClick()"
      :text="closeButtonText"
    />

    <common-loader v-if="loading" style="max-height: 800px" />

    <template v-if="!loading && gift && show">
      <the-send-options
        v-if="steps.showSendOptions"
        :panelType.sync="panelType"
        @showRecipients="changeStep()"
      />

      <the-recipients-area
        v-model="recipients"
        v-show="steps.showSelectingApprovers"
        :panelType="panelType"
        :marketplaceUser="marketplaceUser"
        :linkType.sync="advancedForm.linkType"
        :numberOfRecipients.sync="numberOfRecipients"
        :methodOfEnteringRecipients.sync="methodOfEnteringRecipients"
        :shippingType="gift.shipping"
        ref="recipientsArea"
        @showPreview="changeStep(false, true)"
        @hideDefaultClose="(val) => hideDefaultClose = val"
      />

      <v-container class="px-9 py-0" v-if="steps.showEgiftInfo">
        <v-row>
          <v-col cols="12" class="send-egift-panel__title">
            {{ gift.name }}
          </v-col>
          <!-- description -->
          <v-col cols="12">
            <common-input
              v-model="form.description"
              placeholder="Add description (optional)"
              height="39"
            >
              <template #append>
                <popper
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0, 5px' } },
                  }"
                >
                  <div class="popper pa-4 text-left" style="min-width: 150px; max-width: 500px;">
                    {{ getTooltipTextBySlug('campaigns_gift_description') }}
                  </div>
                  <div slot="reference" class="d-flex">
                    <icons-question width="24" height="24" class="pointer" />
                  </div>
                </popper>
              </template>
            </common-input>
          </v-col>
          <!-- gift card info images and prices -->
          <v-col cols="12">
            <the-gift-card
              :gift="gift"
              :recipients="recipients"
              :numberOfRecipients="numberOfRecipients"
              @showRecipients="changeStep()"
              @updateGiftTotalCost="(val) => giftTotalCost = val"
            />
          </v-col>
          <!-- name -->
          <v-col cols="8">
            <label for="giver-name">
              Gift Giver Name:

              <popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0, 5px' } },
                }"
              >
                <div class="popper pa-4 text-left" style="max-width: unset">
                  <img
                    alt=""
                    :src="getProductImageBySlug(panelType === 'link' ? 'campaigns_gift_giver_name--link' : 'campaigns_gift_giver_name')"
                  />
                </div>
                <div slot="reference" class="d-flex">
                  <icons-question width="24" height="24" class="pointer" />
                </div>
              </popper>
            </label>
            <common-input
              v-model="form.giverName"
              id="giver-name"
              :rules="[v => !!v || '']"
              placeholder="Gift giver name"
              height="36"
              style="max-width: 483px"
            />
          </v-col>
          <!-- email -->
          <v-col cols="8">
            <label for="giver-email">
              Gift Giver Email:

              <popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0, 5px' } },
                }"
              >
                <div class="popper pa-4 text-left" style="min-width: 150px; max-width: 500px;">
                  {{ getTooltipTextBySlug('campaigns_gift_giver_email') }}
                </div>
                <div slot="reference" class="d-flex">
                  <icons-question width="24" height="24" class="pointer" />
                </div>
              </popper>
            </label>
            <common-input
              v-model="form.giverEmail"
              id="giver-email"
              :rules="[v => !!v || '']"
              placeholder="Gift giver email"
              height="36"
              style="max-width: 483px"
            />
          </v-col>
          <!-- Greeting -->
          <v-col cols="7" v-if="panelType === 'egift'">
            <the-greeting-message
              :message.sync="form.message"
              :logo.sync="form.logo"
              :default-logo="getGiftDefaultLogo"
              :previewLogo.sync="previewLogo"
              @showGreetingIdeas="showSelectGreetingsIdeasPanel = true"
              ref="greeting-area"
            />
          </v-col>

          <v-col v-if="panelType === 'link'" cols="7">
            <the-upload-logo
              v-model="form.logo"
              :previewLogo.sync="previewLogo"
            />
          </v-col>

          <v-col cols="12" class="pt-10 pb-8">
            <v-divider />
          </v-col>
          <!-- Payment -->
          <v-col v-if="isSpendLimitInsufficient" cols="12">
            <cg-alert type="error" style="max-width: fit-content">
              Order cannot be created. You exceed your personal spend limit
            </cg-alert>
          </v-col>

          <template v-else-if="giftTotalCost > 0 && !campaignNeedsApproval">
            <v-col cols="12" class="py-0">
              <payment-module
                :paymentMethod.sync="payment.method"
                :paymentData.sync="payment.paymentData"
                :defaultPaymentMethod="payment.method"
                :splitPaymentData.sync="payment.splitPaymentData"
                use-split-payment-if-available
                :amount="giftTotalCost"
                payable-type="campaign"
                :payable-id="gift.entity_id"
                ref="paymentModule"
              />
            </v-col>

            <v-col cols="12" class="py-4">
              <v-divider />
            </v-col>
          </template>

          <!-- Advanced -->
          <v-col cols="12" class="pt-12 pb-6">
            <the-advanced-options
              :panelType="panelType"
              :recipients="recipients"
              :isPreProduction="isPreProduction"
              :allowToExchange.sync="advancedCheckboxes.allowToExchange"
              :allowToReEgift.sync="advancedCheckboxes.allowToReEgift"
              :disableReminderEmails.sync="advancedCheckboxes.disableReminderEmails"
              :shippingDate.sync="advancedForm.shippingDate"
              :linkType.sync="advancedForm.linkType"
              :subjectLine.sync="advancedForm.subjectLine"
              :expirationDays.sync="advancedForm.expirationDays"
              :calendlyUrl.sync="advancedForm.calendlyUrl"
              :userLinkType.sync="advancedForm.userLinkType"
              :freeFormLink.sync="advancedForm.freeFormLink"
              :freeFormButton.sync="advancedForm.freeFormButton"
              :videoUrl.sync="advancedForm.videoUrl"
              :welcomeMessage.sync="advancedForm.welcomeMessage"
              :egiftSettings="egiftSettings"
              ref="advancedOptions"
            />
          </v-col>

          <v-col cols="12" class="pt-0 pb-4">
            <v-divider />
          </v-col>

          <v-col cols="12" class="f15 error--text" v-show="errors.length">
            <span>
              Errors:
            </span>
            <ul style="list-style: none" class="pl-0">
              <li v-for="error in errors" :key="error">
                - {{ error }}
              </li>
            </ul>
          </v-col>

          <v-col cols="12" class="py-9">
            <common-button
              outlined
              height="45"
              class="mr-3"
              @click="showPreview()"
            >
              Preview
            </common-button>

            <common-button
              height="45"
              width="200"
              v-if="!campaignNeedsApprovalBeforeSend"
              :disabled="isSpendLimitInsufficient"
              @click="sendEgift()"
            >
              {{ sendButtonText }}
            </common-button>

            <common-button
              height="45"
              width="200"
              v-else
              :disabled="isSpendLimitInsufficient"
              @click="showApprovalForm()"
            >
              Continue
            </common-button>
          </v-col>

          <v-col cols="12" class="f15 lato-italic gray1 pt-0 pb-10">
            By clicking “Send eGift” you agree to our
            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-4 text-left" style="min-width: 150px; max-width: 500px;">
                eGift Service Fee covers our unrecoverable eGift processing costs. Unclaimed
                balances will be converted to CG Credits automatically after 90 days from date of
                transaction. CG Credits must be used within 5 years of their issue date.
              </div>
              <a
                href="https://corporategift.com/terms-and-conditions/"
                target="_blank"
                class="f15 lato-italic green3"
                slot="reference"
              >
                Terms of Service.
              </a>
            </popper>
            At any time you can cancel your eGift for a refund.
          </v-col>
        </v-row>
      </v-container>

      <v-container class="px-9 py-0" v-if="steps.showSuccessArea">
        <the-success-area
          :response="response"
          :is-pre-production="isPreProduction"
          :panelType="panelType"
          :linkType="advancedForm.linkType"
          @showGiftPreview="showPreview()"
        />
      </v-container>
    </template>

    <the-greetings-ideas-panel
      v-model="showSelectGreetingsIdeasPanel"
      @changeGreetingMessage="(val) => form.message = val"
    />

    <the-approval-panel
      v-model="showApprovalPanel"
      :approval-from-budget-entity="budgetEntityRequiredApproval"
      :approval-form.sync="approvalForm"
      @sendForApproval="sendEgift()"
    />
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import createCookieForSameDomain from '@/utils/createCookieForSameDomain'
import getOrderSource from '@/utils/getOrderSource'
import getRecaptchaToken from '@/utils/getRecaptchaToken'
import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import getPaymentBody from '@/components/payment/paymentModule/utils/getPaymentBody'

import TheSendOptions from './sendEgiftPanel/TheSendOptions.vue'
import TheGiftCard from './sendEgiftPanel/TheGiftCard.vue'
import TheGreetingMessage from './sendEgiftPanel/TheGreetingMessage.vue'
import TheRecipientsArea from './sendEgiftPanel/TheRecipientsArea.vue'
import TheSuccessArea from './sendEgiftPanel/TheSuccessArea.vue'
import TheGreetingsIdeasPanel from './TheGreetingsIdeasPanel.vue'
import TheApprovalPanel from './TheApprovalPanel.vue'
import TheAdvancedOptions from './sendEgiftPanel/TheAdvancedOptions.vue'
import UnsavedChangesAlert from '@/components/myInventory/Panels/common/UnsavedChangesAlert'
import TheUploadLogo from './sendEgiftPanel/TheUploadLogo.vue'
import { CgAlert } from '@corporategift/design-system'
import Popper from 'vue-popperjs'
import PaymentModule from '@/components/payment/PaymentModule.vue'

import isPageInIframe from './mixins/isPageInIframe'
import takeCurrentDate from './mixins/takeCurrentDate'
import marketplaceUser from './mixins/marketplaceUser'
import campaignNeedsApproval from './mixins/campaignNeedsApproval'
import panelVModel from '@/mixins/panelVModel'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import getProductImageBySlug from '@/mixins/getProductImageBySlug'
import showCampaignPreview from '@/mixins/showCampaignPreview'

import { beginCheckout, purchase, destinationDeclined } from '@/plugins/googleTagManager'

export default {
  name: 'TheSendEgiftPanel',
  components: {
    TheGreetingsIdeasPanel,
    UnsavedChangesAlert,
    TheAdvancedOptions,
    TheGreetingMessage,
    TheRecipientsArea,
    TheApprovalPanel,
    TheSendOptions,
    TheSuccessArea,
    TheUploadLogo,
    PaymentModule,
    TheGiftCard,
    CgAlert,
    Popper,
  },
  mixins: [
    panelVModel,
    isPageInIframe,
    marketplaceUser,
    takeCurrentDate,
    showCampaignPreview,
    getTooltipTextBySlug,
    getProductImageBySlug,
    campaignNeedsApproval,
  ],
  props: {
    gift: {
      type: Object,
      required: false
    },
    sendFavoriteProduct: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    loading: false,
    panelType: 'egift',
    steps: {
      showSendOptions: true,
      showSelectingApprovers: false,
      showEgiftInfo: false,
      showSuccessArea: false
    },
    recipients: [],
    numberOfRecipients: null, // used only for unrestricted gift-link
    giftTotalCost: 0,
    beginCheckoutEventSend: false,
    methodOfEnteringRecipients: 'manually',
    hideDefaultClose: false,
    userHasUnsavedChanges: false,
    previewLogo: null,
    showSelectGreetingsIdeasPanel: false,
    showApprovalPanel: false,
    response: {},
    errors: [],
    form: {
      description: null,
      giverName: null,
      giverEmail: null,
      message: '',
      logo: null
    },
    advancedForm: {
      expirationDays: 90,
      shippingDate: null,
      subjectLine: null,
      userLinkType: null,
      freeFormLink: null,
      freeFormButton: 'Go!',
      calendlyUrl: null,
      videoUrl: null,
      linkType: 'standard_link',
      welcomeMessage: 'Enter your details to claim your gift'
    },
    advancedCheckboxes: {
      allowToExchange: false,
      allowToReEgift: false,
      disableReminderEmails: false
    },
    payment: {
      method: PaymentType.BE,
      paymentData: null,
      allowSplitPayment: true,
      splitPaymentData: null,
    },
    approvalForm: {}
  }),
  computed: {
    closeButtonText () {
      const { steps: { showEgiftInfo, showSendOptions, showSuccessArea }, hideDefaultClose, isPageInIframe } = this

      if (isPageInIframe) { return 'Close' }
      if (showSuccessArea) { return 'Close' }
      if (showEgiftInfo) { return 'Back to select recipients' }
      if (hideDefaultClose) { return 'Back to enter recipients' }
      if (!showSendOptions) { return 'Back to sending options' }

      return 'Close'
    },
    sendButtonText () {
      const { panelType, recipients: { length }, advancedForm: { linkType } } = this

      if (panelType === 'egift') return 'Send E-Gift'
      if (['single_link_restricted', 'single_link_unrestricted'].includes(linkType))
        return 'Create link'
      if (length > 1) return 'Create links'

      return 'Create link'
    },
    getGiftDefaultLogo () {
      const { logo_url, customer_group } = this.gift

      if (logo_url) { return logo_url }
      if (customer_group?.logo) {
        return `https://corporategift.com/media/companies_logo/${customer_group?.logo}`
      }

      return this.$store.state.header?.headerData?.logoUrl ?? null
    },
    budgetEntityRequiredApproval () {
      const { method, paymentData } = this.payment
      if (method === PaymentType.BE) { return paymentData?.approve ?? false }
      return false
    },
    campaignNeedsApprovalBeforeSend () {
      const { campaignNeedsApproval, budgetEntityRequiredApproval } = this
      if (budgetEntityRequiredApproval) return budgetEntityRequiredApproval
      return campaignNeedsApproval ?? false
    },
    isSpendLimitInsufficient () {
      const isSubAccount = this.$store.state?.header?.headerData?.isSubaccount ?? false
      const checkoutNeedsApproval = this.$store.state?.header?.headerData?.checkoutNeedsApproval ?? false
      const spendLimit = this.$store.state?.header?.headerData?.spendLimit ?? null
      const orderTotal = this.giftTotalCost ?? 0

      if (!isSubAccount) { return false }
      if (checkoutNeedsApproval) { return false }
      if (spendLimit === null) { return false }

      return Number(spendLimit) < orderTotal
    },
    userEmail () {
      return this.$store.state?.header?.headerData?.customerEmail ?? null
    },
    userId () {
      return this.$store.state?.header?.headerData?.customerId ?? null
    },
    egiftSettings () {
      return this.gift?.egift_settings ?? {}
    },
    isPreProduction () {
      return this.gift?.is_pre_production ?? false
    },
    unsavedChangesHelper () {
      const { form, recipients, advancedForm, advancedCheckboxes, numberOfRecipients } = this

      return {
        form,
        advanced: advancedForm,
        recipients,
        numberOfRecipients,
        checkboxes: advancedCheckboxes,
      }
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        const {
          gift_giver_name: senderName,
          greeting_message: message,
          email_subject_line: subjectLine,
          video_greeting: videoGreeting,
          calendly_link: calendlyUrl,
          use_calendly_link: useCalendly,
          use_free_form_url: useFreeForm,
          free_form_button: freeFormButton,
          expire_after: expireDays,
          free_form_url: freeFormUrl,
          egift_settings: {
            is_upgrade_enabled: isUpgradeEnabled,
            is_regift_enabled: isRegiftEnabled,
            disable_recipient_reminders: disableRecipientReminders
          }
        } = this.gift

        if (this.isPageInIframe) this.setDataForPanelInIframe()

        if (this.sendFavoriteProduct) this.loadFavoriteProductSettings()

        if (!this.isPageInIframe) {
          this.form.giverName = senderName
          this.form.giverEmail = this.userEmail
        }

        if (this.panelType === 'link') {
          this.form.logo = this.getGiftDefaultLogo
          this.form.previewLogo = this.getGiftDefaultLogo
        }

        if (useCalendly) { this.advancedForm.userLinkType = 'calendly' }
        if (useFreeForm) { this.advancedForm.userLinkType = 'free-form' }

        this.form.message = message
        this.advancedForm.subjectLine = subjectLine
        this.advancedForm.videoUrl = videoGreeting
        this.advancedForm.calendlyUrl = calendlyUrl
        this.advancedForm.freeFormLink = freeFormUrl || null
        this.advancedForm.freeFormButton = freeFormButton || 'Go!'
        this.advancedCheckboxes.allowToExchange = isUpgradeEnabled || false
        this.advancedCheckboxes.allowToReEgift = isRegiftEnabled || false
        this.advancedCheckboxes.disableReminderEmails = disableRecipientReminders || false
        this.advancedForm.expirationDays = expireDays || 90

        this.$nextTick(() => { this.storeFormData() })
      } else {
        this.clearData()
      }
    },
    steps: {
      deep: true,
      handler: function () {
        this.scrollPanelToTop()
        this.tryToSendBeginCheckoutEvent()
      }
    }
  },
  methods: {
    changeStep (
      showSelectingApprovers = true,
      showEgiftInfo = false,
      showSuccessArea = false,
      showSendOptions = false
    ) {
      this.steps = {
        showSelectingApprovers,
        showSendOptions,
        showEgiftInfo,
        showSuccessArea
      }
    },
    setDataForPanelInIframe () {
      const params = this.$route.query

      this.changeStep(false, true)
      this.form.description = 'Hubspot'
      this.form.giverName = `${params.firstname || ''} ${params.lastname || ''}`
      this.recipients.push({
        email: params.email ?? '',
        first_name: params.recipient_firstname ?? '',
        last_name: params.recipient_lastname ?? '',
        send_date: this.takeCurrentDate()
      })
    },
    loadFavoriteProductSettings () {
      this.loading = true
      Api.get(`/shared/products/${this.gift.entity_id}/egift`)
        .then((data) => {
          this.form.giverEmail = data?.giver_email ?? null
          this.$emit('updateShippingPrice', data?.shipping_price ?? 0)
        })
        .catch((e) => (console.error(e)))
        .finally(() => (this.loading = false))
    },
    clearData () {
      Object.assign(this.$data, this.$options.data())
      this.$refs?.advancedOptions?.clearData()
    },
    storeFormData () {
      const currentForm = structuredClone(this.unsavedChangesHelper)
      window.formsComparator.addItemToStore('old-send-campaign', currentForm)
    },
    onCloseClick () {
      const { hideDefaultClose, steps: { showSuccessArea, showSelectingApprovers, showSendOptions }, isPageInIframe } = this

      if (isPageInIframe) {
        this.tryClosePanel()
      } else if (showSelectingApprovers && !showSendOptions && !hideDefaultClose) {
        this.steps = {
          showSendOptions: true,
          showSelectingApprovers: false,
          showEgiftInfo: false,
          showSuccessArea: false,
        }
      } else if (!showSelectingApprovers && !showSuccessArea && !showSendOptions) {
        this.changeStep(true)
      } else if (hideDefaultClose) {
        this.$refs.recipientsArea.backToRecipientMethods(false)
        this.hideDefaultClose = false
      } else {
        this.tryClosePanel()
      }
    },
    tryClosePanel () {
      const {
        steps: {
          showSelectingApprovers,
          showSuccessArea
        },
        unsavedChangesHelper,
      } = this

      if (!showSelectingApprovers && showSuccessArea) { this.show = false }

      const currentForm = structuredClone(unsavedChangesHelper)
      const result = window.formsComparator.compare('old-send-campaign', currentForm)

      if (!result) {
        this.userHasUnsavedChanges = true
      } else {
        this.show = false
      }
    },
    tryToSendBeginCheckoutEvent () {
      const { steps: { showEgiftInfo }, beginCheckoutEventSend, gift, recipients } = this

      if (!beginCheckoutEventSend && showEgiftInfo) {
        setTimeout(() => {
          beginCheckout({
            value: (this.giftTotalCost || 0)?.toFixed(2),
            checkoutType: 'campaign',
            items: gift.collection?.items?.map((item) => ({
              'item_id': item?.product?.entity_id ?? undefined,
              'item_name': item?.product?.name ?? undefined,
              'item_category': 'egift_campaign',
              'price': (item?.product?.price ?? 0)?.toFixed(2),
              'quantity': recipients?.length || 1,
              'currency': 'USD',
            }))
          })

          this.beginCheckoutEventSend = true
        }, 100)
      }
    },
    showPreview () {
      const {
        gift: { entity_id: campaignId },
        form: { giverName, message },
        recipients,
        advancedForm: {
          calendlyUrl,
          videoUrl,
          subjectLine,
          userLinkType,
          freeFormLink,
          freeFormButton,
          linkType,
          welcomeMessage
        },
        advancedCheckboxes: { allowToExchange, allowToReEgift },
        getGiftDefaultLogo,
        previewLogo,
        panelType,
      } = this

      const calendlyLink = userLinkType === 'calendly' ? calendlyUrl : null

      this.showCampaignPreview(
        campaignId,
        getGiftDefaultLogo,
        recipients,
        giverName,
        subjectLine,
        message,
        videoUrl,
        calendlyLink,
        allowToReEgift,
        allowToExchange,
        previewLogo,
        panelType === 'link',
        calendlyLink ? null : freeFormButton,
        calendlyLink ? null : freeFormLink,
        panelType === 'link' ? linkType : null,
        panelType === 'link' ? welcomeMessage : null,
      )
    },
    scrollPanelToTop () {
      this.$el.querySelector('.v-navigation-drawer__content').scrollTo(
        {
          top: 0,
          behavior: 'smooth'
        }
      )
    },
    scrollPanelToBottom () {
      this.$el.querySelector('.v-navigation-drawer__content').scrollTo(
        {
          top: 2000,
          behavior: 'smooth'
        }
      )
    },
    isGreetingMessageFilled () {
      const { panelType, form: { message } } = this

      if (panelType !== 'egift') return true

      if (!message?.trim()) {
        this.loading = false
        this.errors = ['Greeting Message is required']
        setTimeout(() => {
          this.scrollPanelToBottom()
        }, 0)
        return false
      }

      return true
    },
    isWelcomeMessageFilled () {
      const { linkType, welcomeMessage } = this.advancedForm

      if (linkType !== 'single_link_restricted') return true

      const result = !!welcomeMessage

      if (!result) {
        this.errors = ['Welcome message is required']
        setTimeout(() => {
          this.scrollPanelToBottom()
        }, 0)
        return result
      }

      return true
    },
    showApprovalForm () {
      if (!this.isGreetingMessageFilled()) return

      this.errors = []
      this.showApprovalPanel = true
    },
    async sendEgift () {
      const {
        form,
        gift,
        panelType,
        recipients,
        advancedCheckboxes,
        advancedForm,
        payment,
        giftTotalCost,
        approvalForm,
        numberOfRecipients,
        campaignNeedsApproval,
        campaignNeedsApprovalBeforeSend,
        giftTotalShippingCost,
        isPageInIframe,
        sendFavoriteProduct,
      } = this
      const dataObject = {}

      if (giftTotalCost > 0 && !campaignNeedsApproval) {
        if (!this.$refs.paymentModule.validate()) return
      }

      if (!this.isGreetingMessageFilled()) return
      if (!this.isWelcomeMessageFilled()) return

      // #region recipients
      if (advancedForm.linkType === 'single_link_unrestricted') {
        dataObject.number_of_recipients = numberOfRecipients
      } else {
        dataObject.recipients = recipients.map((recipient) => ({
          ...recipient,
          send_date: recipient?.send_date ? recipient?.send_date : this.takeCurrentDate(),
        }))
      }
      // #endregion

      // #region checkboxes
      dataObject.allow_to_exchange = advancedCheckboxes.allowToExchange
      dataObject.allow_to_re_egift = advancedCheckboxes.allowToReEgift
      dataObject.disable_reminder_emails = advancedCheckboxes.disableReminderEmails
      // #endregion

      // #region advanced data
      dataObject.shipping_date = advancedForm.shippingDate
      dataObject.subject_line = advancedForm.subjectLine
      dataObject.video_url = advancedForm.videoUrl
      dataObject.expire_after = advancedForm.expirationDays || 90
      if (advancedForm.userLinkType === 'calendly') {
        dataObject.calendar_url = advancedForm.calendlyUrl ?? undefined
      } else {
        dataObject.free_form_url = advancedForm.freeFormLink ?? undefined
        dataObject.free_form_button = advancedForm.freeFormButton ?? 'Go!'
      }
      // if panel is set to send link
      if (panelType === 'link') dataObject.link_type = advancedForm.linkType

      if (advancedForm.linkType === 'single_link_restricted') {
        dataObject.welcome_message = advancedForm.welcomeMessage
      }
      // #endregion

      // #region form
      dataObject.description = form.description
      dataObject.giver_name = form.giverName
      dataObject.giver_email = form.giverEmail
      dataObject.message = form.message
      dataObject.platformSource = getOrderSource(this)
      // #endregion

      if (sendFavoriteProduct) {
        dataObject.price = gift.prices.items_price;
        dataObject.shipping_price = gift.prices.shipping_price;
        dataObject.product_id = gift.entity_id;
        if (panelType === 'link') dataObject.message = 'Favorites message'
      }
      // #region payment
      if (campaignNeedsApproval) {
        dataObject.payment = null
      } else if (giftTotalCost > 0 || campaignNeedsApprovalBeforeSend) {
        const paymentBody = getPaymentBody(payment)
        dataObject.payment = Array.isArray(paymentBody) ? paymentBody : [paymentBody]
      } else {
        dataObject.payment = [{
          method: 'free',
          billingAddress: {},
        }]
      }
      // #endregion

      // #region approval
      if (campaignNeedsApprovalBeforeSend) {
        const parsedApprovalForm = {}
        Object.keys(approvalForm).forEach((key) => {
          parsedApprovalForm[key] = Array.isArray(approvalForm[key]) ?
            approvalForm[key]?.map((item) => item.value) :
            approvalForm[key]
        })
        dataObject.additional_data = parsedApprovalForm

        if (dataObject.payment?.at(0)?.method !== PaymentType.BE) {
          dataObject.payment = null
        }
      }
      // #endregion

      // #region iFrame
      if (isPageInIframe) dataObject.source = this.$route?.query?.source ?? null
      // #endregion

      dataObject.recaptcha_token = await getRecaptchaToken()

      const apiLink = panelType === 'link' ? 'link' : 'email'
      const sendEgiftPath = sendFavoriteProduct
        ? '/shared/egift/send'
        : `/campaigns/send-egift/${gift.entity_id}/${apiLink}`
      this.loading = true
      this.errors = []

      Api.post(sendEgiftPath, dataObject)
        .then(({ success, data, errors = [] }) => {
          if (success) {
            this.response = data
            if (panelType === 'link')
              createCookieForSameDomain('last_created_gift_id', data.gift_id)

            this.changeStep(false, false, true)
            this.loading = false

            if (form.logo) {
              const formData = new FormData()
              formData.append('logo', form.logo, form.logo?.name)
              const sendLogoPath = sendFavoriteProduct
                ? '/shared/egift/set-logo/100054937'
                : `/campaigns/send-egift/set-picture/${data.order_number}`
              Api.post(sendLogoPath, formData)
                .then((data2) => {
                  if (!data2.success) console.error(data2)
                })
            }

            let GAPaymentMethod = payment.method

            if (payment.method === PaymentType.CC) GAPaymentMethod = 'credit card'
            if (payment.method === PaymentType.ACH) GAPaymentMethod = 'ach'
            if (payment.method === PaymentType.BE) GAPaymentMethod = 'cg credit'
            if (payment.method === PaymentType.PO) GAPaymentMethod = 'purchase order'

            purchase({
              transactionId: data.order_number,
              shipping: giftTotalShippingCost,
              value: giftTotalCost?.toFixed(2),
              paymentType: GAPaymentMethod,
              checkoutType: 'campaign',
              email: this.userEmail,
              userId: this.userId,
              parentAccount: null,
              items: gift.collection?.items?.map((item) => ({
                'item_id': data.order_number,
                'item_name': item.product.name,
                'item_category': 'egift_campaign',
                'price': item.product.price?.toFixed(2),
                'quantity': recipients?.length || 1,
                'currency': 'USD',
              }))
            })
          } else {
            this.errors = errors

            destinationDeclined({
              action: this.errors?.join(', ') || 'An unexpected error occurred, please try again later or contact our support'
            })
            this.loading = false
            setTimeout(() => {
              this.scrollPanelToBottom()
            }, 0)
          }
        }).catch((err) => {
          const { errors, message } = err?.response?.data

          if (errors) {
            this.errors = Object.values(errors)?.reduce((acc, error) => {
              if (Array.isArray(error)) {
                acc.push(...error)
              } else {
                acc.push(error)
              }
              return acc
            }, []) || []
          } else if(message) {
            this.errors = [message]
          } else {
            this.errors = ['An unexpected error occurred, please try again later or contact our support']
          }

          this.$refs.paymentModule?.refreshPaymentToken()
          this.loading = false

          destinationDeclined({
            action: this.errors?.join(', ') || 'An unexpected error occurred, please try again later or contact our support'
          })
          setTimeout(() => {
            this.scrollPanelToBottom()
          }, 0)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel {
  &__title {
    font-family: 'Lato-Light', sans-serif !important;
    font-size: 30px;
    line-height: 36px;
    color: #000;
  }
}

label {
  font-family: 'Lato-Bold', sans-serif !important;
  font-size: 15px;
  line-height: 22px;
  color: #000;
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  gap: 8px;
  align-items: flex-end;
}
</style>