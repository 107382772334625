var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popper',{attrs:{"options":{
    placement: 'right',
    modifiers: {
      offset: {
        offset: '0, 4px'
      },
      preventOverflow: {
        boundariesElement: 'offsetParent'
      }
    }
  }}},[_c('div',{staticClass:"popper pa-4 pl-7 text-left white customized-popper",staticStyle:{"max-width":"350px"}},[_c('v-simple-table',{staticClass:"customized-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.customized),function(item,i){return _c('tr',{key:i},[_c('td',[_c('span',{staticClass:"f14 text-capitalize"},[_vm._v(_vm._s(_vm.parseOptionLabel(item))+":")])]),(item.images)?_c('td',[_c('div',{staticClass:"cl-width cl-height customized-list__image-wrapper"},_vm._l((item.images),function(image,j){return _c('div',{key:j,staticClass:"customized-list__image"},[_c('img',{attrs:{"src":image,"alt":item.label}})])}),0)]):_vm._e(),(!item.images)?_c('td',{attrs:{"colspan":"2"}},[_c('span',{staticClass:"d-inline-block black1",domProps:{"innerHTML":_vm._s(item.value)}})]):_c('td',[_c('span',{staticClass:"d-inline-block black1",domProps:{"innerHTML":_vm._s(item.value)}})])])}),0)]},proxy:true}])})],1),_c('div',{staticClass:"orders-table__product-status green2 mt-auto",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Customized "),_c('img',{attrs:{"src":"/images/check-circle-icon.svg","alt":"Rounded yes"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }