<template>
  <div class="px-1 input_file-wrapper">
    <slot />
    <v-file-input
      v-model="image"
      v-bind="$attrs"
      v-on="$listeners"
      class="pointer pa-0 ma-0"
      :accept="accept"
      prepend-icon=""
      dense
      :clearable="false"
      hide-details
    />
  </div>
</template>

<script>
export default {
  name: 'ImportFileWrapper',
  props: {
    value: {
      type: [String, File],
      required: false,
      default: null
    },
    accept: {
      type: String,
      required: false,
      default: 'image/*'
    },
    // sync
    preview: {
      type: [String, File],
      required: false,
      default: null
    }
  },
  computed: {
    image: {
      get () {
        return this.value
      },
      set (val) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(val)
        fileReader.addEventListener('load', () => {
          const { result } = fileReader
          this.$emit('update:preview', result)
        })
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.input_file-wrapper {
  position: relative;
  display: inline-block;
  & .v-input {
    opacity: 0;
    position: absolute;
    inset: 0;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    height: 100%;
    &__prepend-outer {
      margin: 0 !important;
      height: 100%;
      .v-input__icon {
        height: 100%;
        button {
          height: 100%;
        }
      }
    }
    & .v-input__control {
      cursor: pointer;
      width: inherit;
      height: inherit;
      min-height: unset !important;
      & .v-input__slot {
        cursor: pointer;
        width: 100%;
        height: inherit;
        min-height: unset !important;
        .v-text-field__slot {
          height: inherit;
        }
      }
    }
  }
  & .v-file-input__text {
    width: 0 !important;
    display: none !important;
    align-items: unset !important;
    align-self: unset !important;
    flex-wrap: unset !important;
  }
}
</style>
