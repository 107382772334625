<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :height="height"
    :width="width"
  >
    <path d="M20,9V15H12V19.84L4.16,12L12,4.16V9H20Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowBack',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 24
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    },
  }
}
</script>
