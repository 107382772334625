<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="850px"
    class="share-favorites-panel"
  >
    <close-mobile-panel-button @click.native="show = false">
      Edit collection
    </close-mobile-panel-button>

    <v-container class="px-4" v-if="!loading">
      <v-row class="flex-column">
        <v-col cols="12" class="py-6">
          <label for="list-name">List name</label>
          <common-input
            v-model="newName"
            :rules="[v => !!v || '']"
            placeholder="Name"
            id="list-name"
            ref="list-name"
            height="40"
            :hide-details="!isNewWishlistNameExists"
            :error-messages="isNewWishlistNameExists ?
              ['You already have a collection with this name.'] :
              null"
            @keypress.enter="saveChanges()"
          />
        </v-col>
        <v-col cols="12">
          <common-button
            block
            height="40"
            class="favorites-button"
            :disabled="disabelCTAButton"
            @click="saveChanges()"
          >
            Save
          </common-button>
          <common-button
            class="mt-6 favorites-button"
            block
            outlined
            height="40"
            @click="show = false"
          >
            Cancel
          </common-button>
        </v-col>

        <v-col
          cols="12"
          class="delete-button"
          tabindex="0"
          @click="showDeleteModal = true"
          @keypress="showDeleteModal = true"
        >
          <icons-delete :width="14" :height="17" />

          Remove collection from favorites
        </v-col>
      </v-row>
    </v-container>

    <common-loader v-else />

    <the-my-favorites-remove-list-item-dialog
      v-model="showDeleteModal"
      :listId="selectedListId"
      mobile-version
      @removeListItem="(val) => removeListItem(val)"
    />

  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'

import TheMyFavoritesRemoveListItemDialog from '@/components/myFavorites/TheMyFavoritesRemoveListItemDialog.vue'

export default {
  name: 'TheMyFavoritesEditListPanel',
  mixins: [panelVModel],
  components: {
    TheMyFavoritesRemoveListItemDialog
  },
  props: {
    favoriteList: {
      type: Object,
      required: false,
      default: null,
    },
    currentWishlists: {
      type: Array,
      reqiored: false,
      default: () => ([])
    }
  },
  data: () => ({
    loading: false,
    newName: null,
    showDeleteModal: false,
  }),
  computed: {
    selectedListId () {
      return this.favoriteList?.id || 0
    },
    selectedListName () {
      return this.favoriteList?.name || null
    },
    disabelCTAButton () {
      const { newName, isNewWishlistNameExists } = this

      return !newName || newName.length < 2 || isNewWishlistNameExists
    },
    wishlistsNames () {
      const { currentWishlists, selectedListName } = this
      return currentWishlists?.map((item) => item.name)?.filter((name) => name !== selectedListName) ?? []
    },
    isNewWishlistNameExists () {
      const { wishlistsNames, newName } = this
      return wishlistsNames?.includes(newName?.trim()) ?? false
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.newName = this.favoriteList?.name ?? null
        setTimeout(() => (this.$refs['list-name'].$children[0]?.focus()), 0)
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    },
  },
  methods: {
    saveChanges () {
      const { newName, selectedListId, disabelCTAButton } = this

      if (disabelCTAButton) return

      this.loading = true
      Api.put(`/customer/wishlists/${selectedListId}`, { name: newName })
        .then((data) => {
          this.$emit('updateListName', newName)
          this.$cgToast.success('Successfully changed collection name')
          this.show = false
        })
        .catch((e) => { console.error(e) })
        .finally(() => (this.loading = false))
    },
    removeListItem (val) {
      this.$emit('removeListItem', val)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/favorites-button.scss";

label {
  font-size: 15px;
  color: #222325;
  line-height: 18px;
  font-family: 'Lato-Bold', sans-serif;
  display: block;
  padding-bottom: 8px;
}

.delete-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 23px;
  font-family: 'Lato-Regular', sans-serif;
  color: #FF5A60;
}
</style>
