<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    :stateless="shouldBeStateless"
    right
    width="800px"
  >
    <close-panel-button
      @close="() => show = false"
      text="My inventory"
    />

    <common-loader v-if="loading" style="max-height: 800px" />

    <v-container class="px-10 py-0" v-if="!loading && kit">
      <v-row>
        <v-col cols="12">
          <h2 class="f40 lato-light font-weight-light">
            {{ kit.product.name }}
          </h2>
        </v-col>

        <v-col v-if="superAdmin || masterAccount" cols="12" class="py-8">
          <common-checkbox v-model="disableKitIfProductIsOOS">
            Disable kit if any of its items are out of stock
          </common-checkbox>
        </v-col>

        <!-- for units in production change to 8 cols from 6 -->
        <v-col cols="8">
          <v-row no-gutters style="gap: 18px">
            <legend-dot color="green">Units in stock</legend-dot>
            <legend-dot color="red">Out of stock</legend-dot>
            <template v-if="superAdmin || masterAccount">
              <legend-dot color="yellow">Units in production</legend-dot>
              <legend-dot color="grey">Units on hold</legend-dot>
            </template>
            <template v-else>
              <legend-dot color="red" outlined>My balance</legend-dot>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="mt-2 mb-5 mr-10" />

      <v-row no-gutters>
        <template v-for="product in kit.product.kit.products">
          <v-col cols="12" :key="product.item_id">
            <panel-card
              :product="product"
              :variations="productVariations[product.item_id]"
              @onAutoRefill="autoRefill"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import LegendDot from '../LegendDot'
import PanelCard from './common/PanelCard'

import panelShow from './mixins/panelShow'
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

export default {
  name: 'KitPanel',
  components: {
    LegendDot,
    PanelCard
  },
  mixins: [
    panelShow,
    superAdmin,
    masterAccount,
  ],
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    kit: {
      type: Object,
      required: false
    },
    shouldBeStateless: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: () => ({
    loading: false,
    productVariations: []
  }),
  computed: {
    kitProductId () {
      return this.kit?.product?.item_id ?? undefined
    },
    disableKitIfProductIsOOS: {
      get () {
        return this.kit?.product?.kit?.flags?.disable_when_child_product_out_of_stock ?? false
      },
      set (val) {
        const message = val
          ? 'The kit will now be disabled if any of its items go out of stock'
          : 'The kit will remain enabled regardless of individual item availability'

        this.$cgToast.success(message)

        this.$store.dispatch('inventory/disableKitIfProductIsOOS', {
          itemId: this.kitProductId,
          value: val,
        })
      },
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loading = true
        this.productVariations = []
        Api.get(`/customer/purchased-inventory/${this.kit.product.item_id}/variations-in-kit`)
          .then(({ variations }) => {
            this.productVariations = variations
            this.loading = false
          })
      }
    }
  },
  methods: {
    findProductById (id) {
      return this.kit.product.kit.products.filter(({ product_id: productId }) => productId === id)[0]
    },
    autoRefill (id) {
      const product = this.findProductById(id)
      if (product.variations) product.variations.short = this.productVariations[product.item_id]
      this.$emit('setSelectedKitName', this.kit.product.name)
      this.$emit('showRefillPanel', product)
    }
  }
}
</script>
