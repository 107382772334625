import { Vue, Component, Prop } from 'vue-property-decorator'
import type { IGreetingHelper } from '../../types';

@Component
export default class GreetingHelperProp extends Vue {
  @Prop({
    required: false,
    default: { message: null, logoUrl: null }
  }) greetingHelper!: IGreetingHelper;
}
