<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 22"
    fill="none"
    :width="width"
    :height="height"
  >
    <mask
      id="mask0_1252_5484"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="22"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.770752 0.776367H17.1937V21.9476H0.770752V0.776367Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1252_5484)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.54151 12.0026C1.54151 11.4928 1.96235 11.0779 2.47953 11.0779H15.4826C15.9967 11.0779
          16.4176 11.4905 16.4203 11.9977L16.423 13.0527C16.423 13.9026 15.7185 14.5952 14.8521
          14.5952C13.9862 14.5952 13.2817 13.9026 13.2817 13.0516C13.2817 12.8415 13.1091 12.6717
          12.8963 12.6717C12.6836 12.6717 12.511 12.8415 12.511 13.0516C12.511 13.9026 11.8065
          14.5952 10.9402 14.5952C10.0742 14.5952 9.36935 13.9026 9.36935 13.0516C9.36935 12.8415
          9.19708 12.6717 8.98397 12.6717C8.77124 12.6717 8.59859 12.8415 8.59859 13.0516C8.59859
          13.9026 7.89411 14.5952 7.02817 14.5952C6.16183 14.5952 5.45736 13.9026 5.45736
          13.0516C5.45736 12.8415 5.28471 12.6717 5.07198 12.6717C4.85925 12.6717 4.6866 12.8415
          4.6866 13.0516C4.6866 13.9026 3.98212 14.5952 3.11579 14.5952C2.24984 14.5952 1.54537
          13.9026 1.54537 13.0516C1.54537 13.0333 1.54382 13.0155 1.54151 12.998V12.0026ZM3.46263
          15.3273C4.13396 15.2289 4.71357 14.8512 5.07198 14.3163C5.49089 14.9416 6.21155 15.355
          7.02817 15.355C7.84479 15.355 8.56506 14.9416 8.98397 14.3163C9.40326 14.9416 10.1235
          15.355 10.9402 15.355C11.7568 15.355 12.4771 14.9416 12.8963 14.3163C13.254 14.8501
          13.832 15.227 14.5015 15.3265L13.7677 21.1878H4.18946L3.46263 15.3273ZM14.8155
          10.3181H3.14893C3.29615 9.07502 4.33166 8.10775 5.58607 8.10775H12.3788C13.6328 8.10775
          14.6683 9.07502 14.8155 10.3181V10.3181ZM8.98243 5.49015C8.14268 5.49015 7.45979 4.81694
          7.45979 3.98911C7.45979 3.4295 8.30763 2.38017 8.98243 1.69253C9.65723 2.38017 10.5051
          3.4295 10.5051 3.98911C10.5051 4.81694 9.82178 5.49015 8.98243 5.49015V5.49015ZM0.770752
          12.0026V13.0516C0.770752 13.0713 0.772293 13.0907 0.775377 13.1097C0.803124 14.2088
          1.61782 15.1179 2.68455 15.3143L3.46571 21.6137C3.48922 21.8044 3.65339 21.9476 3.84801
          21.9476H14.1084C14.303 21.9476 14.4668 21.8048 14.4907 21.6141L15.2795 15.3147C16.3671
          15.1164 17.1937 14.178 17.1937 13.0516L17.191 11.9947C17.186 11.1068 16.4773 10.3857
          15.5917 10.3291C15.4449 8.66053 14.061 7.34793 12.3788 7.34793H9.36781V6.21578C10.4488
          6.03419 11.2758 5.10606 11.2758 3.98911C11.2758 2.82125 9.45567 1.07669 9.24795
          0.881793C9.09958 0.741225 8.86489 0.741225 8.71651 0.881793C8.5088 1.07669 6.68903
          2.82125 6.68903 3.98911C6.68903 5.10606 7.51567 6.03419 8.59705
          6.21578V7.34793H5.58607C3.90389 7.34793 2.51999 8.66053 2.37316 10.3287C1.48139 10.3842
          0.770752 11.1098 0.770752 12.0026V12.0026Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Occassions extends Vue {
  @Prop({ default: 19 }) readonly width!: number | string;

  @Prop({ default: 22 }) readonly height!: number | string;
}
</script>
