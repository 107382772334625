<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 18"
    :height="height"
    fill="none"
    :width="width"
  >
    <g clip-path="url(#clip0_3551_66897)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.1977 16.875L2.23598 5.625H4.57141V6.90581C4.23141 7.101 3.99998 7.45988 3.99998
          7.875C3.99998 8.496 4.51198 9 5.14284 9C5.77427 9 6.2857 8.496 6.2857 7.875C6.2857
          7.45988 6.05484 7.101 5.71427 6.90581V5.625H10.2857V6.90581C9.9457 7.101 9.71427 7.45988
          9.71427 7.875C9.71427 8.496 10.2263 9 10.8571 9C11.4886 9 12 8.496 12 7.875C12 7.45988
          11.7691 7.101 11.4286 6.90581V5.625H13.764L14.8028 16.875H1.1977ZM5.71427 3.375C5.71427
          2.13412 6.73998 1.125 7.99998 1.125C9.25998 1.125 10.2857 2.13412 10.2857
          3.375V4.5H5.71427V3.375ZM14.8548 5.01188C14.8286 4.72219 14.5817 4.5 14.2857
          4.5H11.4286V3.375C11.4286 1.51369 9.89084 0 7.99998 0C6.1097 0 4.57141 1.51369 4.57141
          3.375V4.5H1.71427C1.41884 4.5 1.17198 4.72219 1.14512 5.01188L0.00226705
          17.3869C-0.0120187 17.5438 0.0416956 17.7002 0.149696 17.8166C0.257696 17.9336 0.410839
          18 0.57141 18H15.4286C15.5897 18 15.7423 17.9336 15.8508 17.8166C15.9588 17.7002 16.0126
          17.5438 15.9977 17.3869L14.8548 5.01188Z
        "
        fill="#95979D"
      />
      <path
        d="M16 14C12.163 14 9 10.837 9 7C9 3.16296 12.163 0 16 0C19.837 0 23 3.16296 23 7C23 10.837
          19.8889 14 16 14Z
        "
        fill="#42B77A"
      />
      <path
        d="M19.8012 6.99999C19.8012 7.34104 19.5056 7.63946 19.1677 7.63946H16.6335V10.1973C16.6335
          10.5384 16.3379 10.8368 16 10.8368C15.6621 10.8368 15.3664 10.581 15.3664
          10.1973V7.63946H12.8323C12.4944 7.63946 12.1987 7.34104 12.1987 6.99999C12.1987 6.65894
          12.4944 6.36053 12.8323 6.36053H15.3664V3.80267C15.3664 3.46162 15.6621 3.16321
          16 3.16321C16.3379 3.16321 16.6335 3.46162 16.6335 3.80267V6.36053H19.1677C19.5478 6.36053
          19.8012 6.65894 19.8012 6.99999Z
        "
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3551_66897">
        <rect width="23" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AddToCartAlt',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 23
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    },
  }
}
</script>
