<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 19"
    fill="none"
    :width="width"
    :height="height"
  >
    <g clip-path="url(#clip0_2163_48464)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.1977 17.375L2.23598 6.125H4.57141V7.40581C4.23141 7.601 3.99998 7.95988 3.99998
          8.375C3.99998 8.996 4.51198 9.5 5.14284 9.5C5.77427 9.5 6.2857 8.996 6.2857 8.375C6.2857
          7.95988 6.05484 7.601 5.71427 7.40581V6.125H10.2857V7.40581C9.9457 7.601 9.71427 7.95988
          9.71427 8.375C9.71427 8.996 10.2263 9.5 10.8571 9.5C11.4886 9.5 12 8.996 12 8.375C12
          7.95988 11.7691 7.601 11.4286 7.40581V6.125H13.764L14.8028 17.375H1.1977ZM5.71427
          3.875C5.71427 2.63412 6.73998 1.625 7.99998 1.625C9.25998 1.625 10.2857 2.63412 10.2857
          3.875V5H5.71427V3.875ZM14.8548 5.51188C14.8286 5.22219 14.5817 5 14.2857
          5H11.4286V3.875C11.4286 2.01369 9.89084 0.5 7.99998 0.5C6.1097 0.5 4.57141 2.01369
          4.57141 3.875V5H1.71427C1.41884 5 1.17198 5.22219 1.14512 5.51188L0.00226705
          17.8869C-0.0120187 18.0438 0.0416956 18.2002 0.149696 18.3166C0.257696 18.4336 0.410839
          18.5 0.57141 18.5H15.4286C15.5897 18.5 15.7423 18.4336 15.8508 18.3166C15.9588 18.2002
          16.0126 18.0438 15.9977 17.8869L14.8548 5.51188Z"
        fill="#95979D"
      />
      <path
        d="M16 14C12.4391 14 9.5 11.0609 9.5 7.5C9.5 3.93911 12.4391 1 16 1C19.5609 1 22.5 3.93911
          22.5 7.5C22.5 11.0633 19.6103 14 16 14Z"
        fill="white"
        stroke="#95979D"
      />
      <path
        d="M19.8012 7.49999C19.8012 7.84104 19.5056 8.13946 19.1677 8.13946H16.6335V10.6973C16.6335
          11.0384 16.3379 11.3368 16 11.3368C15.6621 11.3368 15.3664 11.081 15.3664
          10.6973V8.13946H12.8323C12.4944 8.13946 12.1987 7.84104 12.1987 7.49999C12.1987 7.15894
          12.4944 6.86053 12.8323 6.86053H15.3664V4.30267C15.3664 3.96162 15.6621 3.66321 16
          3.66321C16.3379 3.66321 16.6335 3.96162 16.6335 4.30267V6.86053H19.1677C19.5478 6.86053
          19.8012 7.15894 19.8012 7.49999Z"
        fill="#95979D"
      />
    </g>
    <defs>
      <clipPath id="clip0_2163_48464">
       <rect width="23" height="18" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Close',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 23
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    },
  }
}
</script>
