<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 19"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9741 9.69017C12.6751 9.69017 12.4333 9.93278 12.4333 10.231C12.4333 10.5299 12.6751
        10.771 12.9741 10.771C14.8226 10.771 16.2172 12.3111 16.2172 14.1597C16.2172 14.2215
        16.2055 14.281 16.1804 14.3357C16.0599 14.6089 16.182 14.9282 16.4551 15.0495C16.5263
        15.0816 16.6007 15.0957 16.6743 15.0957C16.8817 15.0957 17.0789 14.9767 17.1689
        14.7748C17.255 14.5807 17.2988 14.3741 17.2988 14.1597C17.2988 11.7156 15.419 9.69017
        12.9741 9.69017M8.23383 1.9267C8.62122 1.69739 9.03365 1.58157 9.46017 1.58157C11.099
        1.58157 12.4333 3.30957 12.4333 5.43278C12.4333 5.81391 12.391 6.188 12.3073
        6.54487C12.2392 6.836 12.42 7.12635 12.7103 7.19443C12.7518 7.20461 12.7933 7.2093 12.834
        7.2093C13.0797 7.2093 13.3012 7.04104 13.3599 6.79217C13.4624 6.35391 13.5149 5.89687
        13.5149 5.43278C13.5149 2.71322 11.6961 0.5 9.46017 0.5C8.84583 0.5 8.23148 0.672174
        7.68365 0.996174C7.42696 1.148 7.34165 1.47983 7.49348 1.73652C7.64609 1.994 7.97791
        2.07852 8.23383 1.9267M11.2437 11.8533C10.9448 11.8533 10.703 12.0951 10.703 12.3933C10.703
        12.6923 10.9448 12.9341 11.2437 12.9341C13.0915 12.9341 14.5957 14.4375 14.5957
        16.286C14.5957 16.5239 14.4016 16.718 14.1629 16.718H1.51278C1.27487 16.718 1.08078 16.5239
        1.08078 16.286C1.08078 14.4375 2.58496 12.9341 4.43191 12.9341C4.73087 12.9341 4.9727
        12.6923 4.9727 12.3933C4.9727 12.0951 4.73087 11.8533 4.43191 11.8533C1.98783 11.8533 0
        13.8411 0 16.286C0 17.1203 0.679304 17.7996 1.51278 17.7996H14.1629C14.9971 17.7996 15.6772
        17.1203 15.6772 16.286C15.6772 13.8411 13.6886 11.8533 11.2437 11.8533M7.83861
        3.60852C9.47739 3.60852 10.8117 5.33652 10.8117 7.46052C10.8117 9.58374 9.47739 11.3125
        7.83861 11.3125C6.19904 11.3125 4.86548 9.58374 4.86391 7.46052C4.86391 5.33652 6.19826
        3.60852 7.83861 3.60852M7.83861 12.3933C10.0745 12.3933 11.8925 10.1809 11.8925
        7.46052C11.8925 4.74017 10.0745 2.52774 7.83861 2.52774C6.73278 2.52774 5.69974 3.056
        4.93122 4.01626C4.19087 4.94209 3.78391 6.16452 3.78391 7.46052C3.78391 10.1809 5.6027
        12.3933 7.83861 12.3933"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SubAccount extends Vue {
  @Prop({ default: 18 }) readonly width!: number | string;

  @Prop({ default: 19 }) readonly height!: number | string;

  @Prop({ default: 'currentColor', required: false }) readonly color!: string;
}
</script>
