<template>
  <popper
    :options="{
      placement: 'top-end',
      modifiers: { offset: { offset: '0, 10px' } }
    }">
    <div class="popper">
      <div
        v-if="downloadingCSV === undefined || downloadingCSV === true"
        class="d-flex py-2 mx-4"
      >
        <span class="text-left">
          Order history details including numbers, dates, totals, status, recipients and products
        </span>
      </div>
      <div
        v-else
        class="d-flex py-2"
      >
        <v-icon class="confirm-icon mx-2">mdi-check-circle</v-icon>
        <span class="text-left">CSV downloaded to your computer</span>
      </div>
    </div>
    <div
      slot="reference"
      class="download_wrapper"
    >
      <template v-if="!loading">
        <div class="d-flex" @click="downloadCSV" @mouseout="resetBtnState">
          <icons-download-csv />
        </div>
      </template>
      <template v-else>
        <v-progress-circular indeterminate color="#7F7F7F" :width="2" :size="22" />
      </template>
      <span>{{ loading ? 'Downloading CSV' : 'Download CSV' }}</span>
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs'
import { mapState } from 'vuex'

export default {
  name: 'button-download-csv',
  components: {
    popper: Popper
  },
  data: () => ({
  }),
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    resetBtnState () {
      this.$store.commit('downloadingCSV', undefined)
    },
    downloadCSV () {
      this.$emit('download')
    }
  },
  computed: {
    ...mapState([
      'downloadingCSV'
    ])
  }
}
</script>

<style lang="scss">
.download_wrapper {
  cursor: pointer;

  span {
    position: absolute;
    margin-top: 10px;
    margin-left: -44px;
    display: none;
    font-size: 12px;
    padding: 5px 13px 5px 16px;
    z-index: 9;
  }

  &:hover {
    span {
      display: block;
      border: 1px solid #b3cac9 !important;
      border-radius: 2.6px !important;
      background-color: #d2f5f3 !important;
    }
  }
}

.confirm-icon {
  color: #42b77a !important;
}
</style>
