var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popper',{attrs:{"options":{
    placement: 'top',
    modifiers: {
      offset: { offset: '0,6px', },
      preventOverflow: { enabled: false },
      hide: { enabled: false },
    },
  }}},[_c('tray-variants-tooltip',_vm._b({style:(!_vm.showVariantsTooltip && 'display: none')},'tray-variants-tooltip',_vm._props,false)),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }