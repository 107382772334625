<template>
  <div
    @click='close'
    class='panel_close-btn d-flex py-7 px-10'
  >
    <v-img
      src="/images/arrow_left.svg"
      class="mr-4"
      max-width="12"
      height="18"
      />
    <span class="f14 green2">
      <slot>
        {{ text }}
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'close-panel-button',
  props: {
    text: {
      default: 'Close',
      type: String
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
