<template>
  <v-tabs
    v-model="selectedTab"
    class="credits-tabs"
    color="#007E76"
  >
    <v-tabs-slider color="#007E76" />
    <v-tab v-show="!hideBudgetEntitiesTab" :ripple="false">
      Budget Entities
    </v-tab>
    <v-tab :ripple="false">
      On-Hold Credits
    </v-tab>
    <v-tab :ripple="false">
      Transactions History
    </v-tab>
  </v-tabs>
</template>

<script>
import { tabsVModel, hideBudgetEntitiesTab } from './mixins'

export default {
  name: 'TheCreditsTabs',
  mixins: [
    tabsVModel,
    hideBudgetEntitiesTab,
  ],
  computed: {
    selectedTab: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.credits-tabs {
  margin-bottom: 30px;
  border-bottom: 1px solid #D3D2D2;

  & .v-tabs-slider-wrapper .v-tabs-slider {
    margin-top: unset;
    margin-left: unset;
  }

  &::v-deep .v-tabs-bar__content {
    gap: 40px;
  }

  & .v-tab {
    text-transform: uppercase;
    font-family: 'Lato-Bold', sans-serif;
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    letter-spacing: unset;
    color: #62646A;
    gap: 8px;

    &:hover {
      color: #007E76 !important;
    }

    &::before {
      content: unset;
    }

    &--active {
      color: #007E76;
    }
  }
}
</style>
