<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="600px"
    class="payment-module-credit-card-panel"
  >
    <panel-header :show.sync="show" :step.sync="step">
      {{ panelTitle }}
    </panel-header>

    <cg-loader v-if="loading" />

    <div v-else class="payment-module-credit-card-panel__content-wrapper">
      <pay-with-credit-card
        v-if="step === 1"
        v-bind="{ ...$props }"
        v-on="$listeners"
        :availableCreditCards="availableCreditCards"
        @update:step="step = 2"
        @onCardSelect="setPaymentMethodAndData"
      />

      <add-new-credit-card
        v-else-if="step === 2"
        v-bind="{ ...$props }"
        v-on="$listeners"
        @update:step="step = 1"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

import { CgLoader } from '@corporategift/design-system'
import PanelHeader from '@/components/PanelHeader.vue'
import PayWithCreditCard from './panelSteps/PayWithCreditCard.vue'
import AddNewCreditCard from './panelSteps/AddNewCreditCard.vue'

export default {
  name: 'ThePaymentModuleCreditCardPanel',
  mixins: [panelVModel],
  components: {
    PayWithCreditCard,
    AddNewCreditCard,
    PanelHeader,
    CgLoader,
  },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    availableCreditCards: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    step: 1,
  }),
  computed: {
    panelTitle () {
      const { step } = this;
      return step === 1 ? 'Pay with Credit card' : 'Add new Credit card'
    },
  },
  watch: {
    show: function (val) {
      if (!val) { Object.assign(this.$data, this.$options.data()) }
    },
    lazyPaymentMethod: 'handlePaymentMethodChange',
  },
  methods: {
    setPaymentMethodAndData (method, data) {
      this.$emit('update:paymentMethod', method)
      this.$emit('update:paymentData', data)
      this.show = false
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-module-credit-card-panel {
  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 50px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > span {
      padding: 20px 0 10px;
      color: #95979D;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      line-height: normal;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 14px;
    }

    & button {
      border-radius: 100px;
    }
  }

  & .cg-loader {
    max-height: 200px;
    margin-block: auto;
  }
}
</style>
