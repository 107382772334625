<template>
  <svg
    :width="width"
    :height="height"
    fill="none"
    viewBox="0 0 118 91"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style="mix-blend-mode:multiply">
      <circle cx="37" cy="37" r="37" transform="matrix(-1 0 0 1 117.25 0)" fill="#ECDDFF"/>
      <circle cx="6.5" cy="6.5" r="6.5" transform="matrix(-1 0 0 1 34.75 6.5)" fill="#ECDDFF"/>
      <circle cx="9.5" cy="9.5" r="9.5" transform="matrix(-1 0 0 1 19.75 38.5)" fill="#ECDDFF"/>
    </g>
    <mask id="mask0_6433_53687" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="20" y="10" width="79" height="71">
      <path d="M20.75 10.3125H98.0749V80.6218H20.75V10.3125Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_6433_53687)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M67.1156 47.4684H84.3461V44.7125H67.1156V47.4684ZM67.1146 78.4995H81.0018V49.6054H67.1146V78.4995ZM57.0662 78.4995H64.8399V49.6054H57.0662V78.4995ZM40.9043 78.4995H54.7915V49.6054H40.9043V78.4995ZM37.5589 47.4693H54.7904V44.7135H37.5589V47.4693ZM43.9093 40.113C43.9093 37.7224 45.6357 35.7767 47.7563 35.7767C48.3201 35.7913 50.4003 36.8822 51.9199 37.6787C52.8374 38.1595 53.847 38.6889 54.8639 39.1824V42.5755H44.6024C44.1544 41.8528 43.9093 40.997 43.9093 40.113ZM57.0662 47.4693H64.8399V44.7135H57.0662V47.4693ZM57.1396 42.5765H64.7675V39.7439H57.1396V42.5765ZM69.9934 37.6758C71.5099 36.8802 73.5849 35.7923 74.1487 35.7767C76.2693 35.7767 77.9947 37.7214 77.9947 40.113C77.9947 40.997 77.7516 41.8528 77.3026 42.5755H67.0422V39.1834C68.0611 38.6889 69.0727 38.1576 69.9934 37.6758ZM85.4839 42.5765H79.8029C80.107 41.7993 80.2714 40.9639 80.2714 40.113C80.2714 36.5441 77.525 33.6406 74.1487 33.6406C73.0749 33.6406 71.5285 34.4245 68.8845 35.8097C67.7187 36.4227 66.5125 37.0512 65.3157 37.6088H56.5914C55.3945 37.0522 54.1925 36.4246 53.0278 35.8136C50.3807 34.4265 48.8301 33.6416 47.7574 33.6416C44.38 33.6416 41.6335 36.5441 41.6335 40.114C41.6335 40.9649 41.798 41.8003 42.1021 42.5774H36.4221C35.7932 42.5774 35.2832 43.0553 35.2832 43.645V48.5389C35.2832 49.1275 35.7932 49.6064 36.4221 49.6064H38.6285V79.569C38.6285 80.1586 39.1385 80.6375 39.7664 80.6375H55.9283H65.9777H82.1386C82.7675 80.6375 83.2765 80.1586 83.2765 79.569V49.6054H85.4839C86.1118 49.6054 86.6208 49.1275 86.6208 48.5369V43.644C86.6218 43.0553 86.1129 42.5765 85.4839 42.5765Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.8023 52.6426C45.5584 52.6426 46.1698 53.2176 46.1698 53.9277C46.1698 54.6368 45.5584 55.2119 44.8023 55.2119C44.0482 55.2119 43.4358 54.6368 43.4358 53.9277C43.4358 53.2176 44.0482 52.6426 44.8023 52.6426Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.211 52.6426C51.9651 52.6426 52.5785 53.2176 52.5785 53.9277C52.5785 54.6368 51.9651 55.2119 51.211 55.2119C50.4559 55.2119 49.8435 54.6368 49.8435 53.9277C49.8435 53.2176 50.4559 52.6426 51.211 52.6426Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.1834 56.7188C48.9386 56.7188 49.5509 57.2938 49.5509 58.002C49.5509 58.712 48.9386 59.2871 48.1834 59.2871C47.4293 59.2871 46.8159 58.712 46.8159 58.002C46.8159 57.2938 47.4293 56.7188 48.1834 56.7188Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.8023 61.2969C45.5574 61.2969 46.1698 61.8719 46.1698 62.5801C46.1698 63.2892 45.5574 63.8643 44.8023 63.8643C44.0482 63.8643 43.4358 63.2892 43.4358 62.5801C43.4358 61.8719 44.0482 61.2969 44.8023 61.2969Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.211 61.2969C51.9651 61.2969 52.5775 61.8719 52.5775 62.5801C52.5775 63.2892 51.9651 63.8643 51.211 63.8643C50.4559 63.8643 49.8435 63.2892 49.8435 62.5801C49.8435 61.8719 50.4559 61.2969 51.211 61.2969Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.1834 65.373C48.9375 65.373 49.5498 65.9481 49.5498 66.6563C49.5498 67.3654 48.9375 67.9404 48.1834 67.9404C47.4293 67.9404 46.8169 67.3654 46.8169 66.6563C46.8169 65.9481 47.4293 65.373 48.1834 65.373Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.8023 69.8242C45.5584 69.8242 46.1698 70.4003 46.1698 71.1084C46.1698 71.8185 45.5584 72.3926 44.8023 72.3926C44.0482 72.3926 43.4358 71.8185 43.4358 71.1084C43.4358 70.4003 44.0482 69.8242 44.8023 69.8242Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.2108 69.8242C51.9649 69.8242 52.5783 70.4003 52.5783 71.1084C52.5783 71.8185 51.9649 72.3926 51.2108 72.3926C50.4556 72.3926 49.8433 71.8185 49.8433 71.1084C49.8433 70.4003 50.4556 69.8242 51.2108 69.8242Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.1834 73.9004C48.9386 73.9004 49.5509 74.4755 49.5509 75.1846C49.5509 75.8947 48.9386 76.4697 48.1834 76.4697C47.4293 76.4697 46.8159 75.8947 46.8159 75.1846C46.8159 74.4755 47.4293 73.9004 48.1834 73.9004Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.3589 52.6426C72.114 52.6426 72.7264 53.2176 72.7264 53.9277C72.7264 54.6368 72.114 55.2119 71.3589 55.2119C70.6048 55.2119 69.9924 54.6368 69.9924 53.9277C69.9924 53.2176 70.6048 52.6426 71.3589 52.6426Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.7677 52.6426C78.5228 52.6426 79.1342 53.2176 79.1342 53.9277C79.1342 54.6368 78.5228 55.2119 77.7677 55.2119C77.0126 55.2119 76.3992 54.6368 76.3992 53.9277C76.3992 53.2176 77.0126 52.6426 77.7677 52.6426Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.7411 56.7188C75.4952 56.7188 76.1086 57.2938 76.1086 58.002C76.1086 58.712 75.4952 59.2871 74.7411 59.2871C73.9849 59.2871 73.3726 58.712 73.3726 58.002C73.3726 57.2938 73.9849 56.7188 74.7411 56.7188Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.3589 61.2969C72.114 61.2969 72.7264 61.8719 72.7264 62.5801C72.7264 63.2892 72.114 63.8643 71.3589 63.8643C70.6048 63.8643 69.9924 63.2892 69.9924 62.5801C69.9924 61.8719 70.6048 61.2969 71.3589 61.2969Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.7676 61.2969C78.5217 61.2969 79.1341 61.8719 79.1341 62.5801C79.1341 63.2892 78.5217 63.8643 77.7676 63.8643C77.0125 63.8643 76.4011 63.2892 76.4011 62.5801C76.4011 61.8719 77.0125 61.2969 77.7676 61.2969Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.741 65.373C75.4951 65.373 76.1065 65.9481 76.1065 66.6563C76.1065 67.3654 75.4951 67.9404 74.741 67.9404C73.9859 67.9404 73.3735 67.3654 73.3735 66.6563C73.3735 65.9481 73.9859 65.373 74.741 65.373Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.3589 69.8242C72.114 69.8242 72.7264 70.4003 72.7264 71.1084C72.7264 71.8185 72.114 72.3926 71.3589 72.3926C70.6048 72.3926 69.9924 71.8185 69.9924 71.1084C69.9924 70.4003 70.6048 69.8242 71.3589 69.8242Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.7677 69.8242C78.5228 69.8242 79.1342 70.4003 79.1342 71.1084C79.1342 71.8185 78.5228 72.3926 77.7677 72.3926C77.0126 72.3926 76.3992 71.8185 76.3992 71.1084C76.3992 70.4003 77.0126 69.8242 77.7677 69.8242Z"
        fill="#62646A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.7411 73.9004C75.4952 73.9004 76.1086 74.4755 76.1086 75.1846C76.1086 75.8947 75.4952 76.4697 74.7411 76.4697C73.9849 76.4697 73.3726 75.8947 73.3726 75.1846C73.3726 74.4755 73.9849 73.9004 74.7411 73.9004Z"
        fill="#62646A"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Inventory',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 118
    },
    height: {
      type: [Number, String],
      required: false,
      default: 91
    }
  }
}
</script>
