var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"review-field",class:{
    'review-field__column': (_vm.editMode && _vm.editModeAsColumn) || (!_vm.editMode && _vm.reviewModeAsColumn),
    'review-field--disable-hover-tooltip': _vm.disableHoverTooltip,
  },attrs:{"aria-disabled":_vm.disabled},on:{"mousemove":_vm.handleTooltip,"click":function($event){!_vm.hideHoverTooltip && _vm.onLabelClick()}}},[_c('div',{staticClass:"review-field__label",class:{
      'review-field__label--active': _vm.editMode,
      'review-field__label--allow-label-click': _vm.allowToClickLabelInEditMode && !_vm.disabled,
    },attrs:{"aria-disabled":_vm.disabled},on:{"click":function($event){!_vm.disableLabelClick && _vm.onLabelClick()}}},[_vm._t("label")],2),(_vm.editMode)?_c('div',[_vm._t("edit-mode")],2):_c('div',{staticClass:"review-field__review"},[_vm._t("review-mode")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideHoverTooltip),expression:"!hideHoverTooltip"}],staticClass:"review-field__tooltip"},[_vm._v("Click to edit")])])
}
var staticRenderFns = []

export { render, staticRenderFns }