<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M17.2969 5.41407L16.0469 2.91407C16.0076 2.83606 15.9473 2.77058 15.8728 2.72502C15.7982
        2.67947 15.7124 2.65565 15.6251 2.65626H4.37506C4.2877 2.65565 4.20191 2.67947 4.12737
        2.72502C4.05283 2.77058 3.9925 2.83606 3.95319 2.91407L2.70319 5.41407C2.67213 5.48005
        2.65612 5.55209 2.65631 5.62501V16.25C2.65631 16.5401 2.77155 16.8183 2.97666
        17.0234C3.18178 17.2285 3.45998 17.3438 3.75006 17.3438H16.2501C16.5401 17.3438 16.8183
        17.2285 17.0235 17.0234C17.2286 16.8183 17.3438 16.5401 17.3438 16.25V5.62501C17.344 5.55209
        17.328 5.48005 17.2969 5.41407ZM4.66413 3.59376H15.336L16.1173 5.15626H3.88288L4.66413
        3.59376ZM16.2501 16.4063H3.75006C3.70862 16.4063 3.66888 16.3898 3.63958 16.3605C3.61027
        16.3312 3.59381 16.2915 3.59381 16.25V6.09376H16.4063V16.25C16.4063 16.2915 16.3899 16.3312
        16.3605 16.3605C16.3312 16.3898 16.2915 16.4063 16.2501 16.4063ZM12.9844 11.3906C13.0708
        11.4796 13.1191 11.5987 13.1191 11.7227C13.1191 11.8467 13.0708 11.9658 12.9844 12.0547L10.3282
        14.7031C10.2859 14.7476 10.235 14.783 10.1786 14.8071C10.1222 14.8313 10.0614 14.8438 10.0001
        14.8438C9.9387 14.8438 9.87797 14.8313 9.82157 14.8071C9.76516 14.783 9.71426 14.7476 9.67194
        14.7031L7.01569 12.0547C6.92763 11.9666 6.87816 11.8472 6.87816 11.7227C6.87816 11.5981
        6.92763 11.4787 7.01569 11.3906C7.10375 11.3026 7.22318 11.2531 7.34772 11.2531C7.47226
        11.2531 7.59169 11.3026 7.67975 11.3906L9.53131 13.2422V8.12501C9.53131 8.00069 9.5807
        7.88146 9.66861 7.79356C9.75651 7.70565 9.87574 7.65626 10.0001 7.65626C10.1244 7.65626
        10.2436 7.70565 10.3315 7.79356C10.4194 7.88146 10.4688 8.00069 10.4688
        8.12501V13.2422L12.3204 11.3906C12.4093 11.3043 12.5284 11.2559 12.6524 11.2559C12.7764
        11.2559 12.8955 11.3043 12.9844 11.3906Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'UsersArchive',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 20
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    },
  },
}
</script>
