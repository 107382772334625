<template>
  <div
    class="the-inventory dashboard-card dashboard-card--small"
    :class="{
      'order-4': !$vuetify.breakpoint.lgAndUp && !(superAdmin || masterAccount) || (!$vuetify.breakpoint.lgAndUp && (superAdmin || masterAccount)),
      'order-2': ($vuetify.breakpoint.lgAndUp && !(superAdmin || masterAccount) && !hasApprovalOrders) ||
        ($vuetify.breakpoint.lgAndUp && (superAdmin || masterAccount) && !hasApprovalOrders),
      'order-6': $vuetify.breakpoint.lgAndUp && (superAdmin || masterAccount) && hasApprovalOrders ||
        ($vuetify.breakpoint.lgAndUp && !(superAdmin || masterAccount) && hasApprovalOrders)
    }"
  >
    <div class="the-inventory__title">
      <div>
        {{ oosItems.length ? 'Inventory items out of stock' : 'Inventory' }}

        <div class="the-inventory__oos-products-count" v-if="oosItems.length">
          {{ oosItemsLength }}
        </div>
      </div>

      <dashboard-card-pagination
        v-model="currentItemIndex"
        :max-length="oosItems.length"
        v-if="oosItems.length > 1"
      />
    </div>

    <v-divider />

    <template v-if="!loading">
      <div class="the-inventory__items" v-if="oosItems.length">
        <div>
          {{ activeItemData.name }}
        </div>
        <div class="d-flex justify-center">
          <v-img
            :src="getProductImage(activeItemData)"
            :alt="activeItemData.name"
            width="120"
            max-width="120"
            height="120"
            max-height="120"
            contain
          />
        </div>

        <div class="the-inventory__actions justify-end">
          <router-link :to="{ name: 'MyInventory' }">All products</router-link>

          <router-link :to="{ name: 'MyInventory' }" v-if="false">
            Approve
            <icons-check-circle :width="22" :height="22" filled :checkStrokeWidth="2" />
          </router-link>
        </div>
      </div>
      <div class="the-inventory__top-item" v-else-if="itemsAmount || kitsAmount">
        <div class="item__products-amount">
          <div class="product-amount product-amount--items">
            <span>{{ itemsAmount }}</span>
            <span>Items</span>
          </div>

          <v-divider vertical />

          <div class="product-amount product-amount--kits">
            <span>{{ kitsAmount }}</span>
            <span>Kits</span>
          </div>
        </div>

        <v-divider />

        <template v-if="topSellingItem">
          <div class="item__top-product">
            Top selling Inventory product this month:
          </div>

          <div class="item__product" v-if="topSellingItem">
            <v-img
              :src="getProductImage(topSellingItem)"
              :alt="topSellingItem.name"
              width="120"
              max-width="120"
              height="120"
              max-height="120"
              contain
            />

            <div>{{ topSellingItem.name }}</div>
          </div>
        </template>

        <div class="the-inventory__no-top-item" v-else>
          No inventory products sold this month
        </div>

        <div class="the-inventory__actions justify-end">
          <router-link :to="{ name: 'MyInventory' }">All products</router-link>
        </div>
      </div>
      <div class="the-inventory__empty" v-else>
        <icons-empty-inventory />
        <span>
          You have no items in your inventory.
          <a href="https://corporategift.com/services#warehouse" target="_blank">Click here</a> 
          to learn more about adding and managing inventory
        </span>
      </div>
    </template>

    <common-loader v-else />
  </div>
</template>

<script>
import Api from '@/axios/api'
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

import DashboardCardPagination from './common/DashboardCardPagination.vue'

export default {
  name: 'TheInventory',
  components: {
    DashboardCardPagination
  },
  mixins: [
    superAdmin,
    masterAccount,
  ],
  props: {
    hasApprovalOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    currentItemIndex: 0,
    oosItems: [],
    topSellingItem: null,
    itemsAmount: 0,
    kitsAmount: 0,
  }),
  computed: {
    activeItemData () {
      const { oosItems, currentItemIndex } = this
      return oosItems?.[currentItemIndex] || null
    },
    oosItemsLength () {
      return this.oosItems?.length ?? 0
    }
  },
  created () {
    this.loading = true
    Api.get('/customer/dashboard/inventory-summary')
      .then(({ data }) => {
        this.oosItems = data?.out_of_stock_items ?? []
        this.topSellingItem = data?.top_selling_item ?? null
        this.itemsAmount = data?.items_count ?? 0
        this.kitsAmount = data?.kits_count ?? 0
      })
      .finally(() => (this.loading = false))
  },
  methods: {
    getProductImage({ image }) {
      if (!image) return '/images/empty-order.png'

      return image.includes('no_selection') ? '/images/empty-order.png' : image
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/dashboard-card.scss";

.the-inventory {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > hr {
    border-color: #D3D2D2;
  }

  & a {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #42B77A !important;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #95979D;
    gap: 4px;
    text-transform: uppercase;

    & > div:first-child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      align-items: baseline;

      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 30px;

    & > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 8px;
      font-size: 15px;
      line-height: 18px;

      &:nth-child(1) {
        font-family: 'Lato-Bold', sans-serif;
        color: #000;
      }

      &:nth-child(2) {
        font-family: 'Lato-Regular', sans-serif;
        color: #222325;
      }
    }
  }

  &__oos-products-count {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 22px;
    height: 22px;
    padding: 4px 6px;
    color: #fff;
    background-color: #FF5A60;
    border-radius: 48px;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Lato-Bold', sans-serif;
  }

  &__top-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;

    .item {
      &__products-amount {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 40px;

        & > hr {
          min-height: 24px;
          max-height: 24px;
          margin: auto 0;
          border-color: #D3D2D2;
        }

        & .product-amount {
          display: flex;
          align-items: center;
          gap: 8px;
          font-family: 'Lato-Bold', sans-serif;

          & > span {
            font-family: inherit;
            color: inherit;
            font-size: inherit;
            line-height: inherit;


            &:nth-child(1) {
              font-size: 30px;
              line-height: 40px;
            }

            &:nth-child(2) {
              font-size: 12px;
              line-height: 14px;
              text-transform: uppercase;
            }
          }

          &--items {
            color: #38A39C;
          }

          &--kits {
            color: #C6B680;
          }
        }
      }

      &__top-product {
        font-family: 'Lato-Bold', sans-serif;
        color: #62646A;
        font-size: 15px;
        line-height: 18px;
      }

      &__product {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 12px;
        font-size: 15px;
        line-height: 18px;
        color: #62646A;;
      }
    }
  }

  &__no-top-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 60px 0;
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: auto;

    & > a {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 28px;
    align-items: center;
    justify-content: center;

    & > span {
      max-width: 256px;
      text-align: center;
      font-family: 'Lato-Italic', sans-serif;
      font-size: 15px;
      line-height: 18px;
      color: #95979D;
    }
  }
}
</style>
