<template>
  <div>
    <!-- FILTER BAR -->
    <v-row no-gutters justify="space-between">
      <v-col cols="7" class="d-flex align-center g10">
        <span class="f13 gray0 text-no-wrap">
          Filter By
        </span>
        <common-select
          v-model="filters.type"
          :items="transactionsItems"
          :menu-z-index="4"
          filter-style
          placeholder="Transaction Type"
          item-text="name"
          item-value="id"
          height="30"
          style="min-width: 216px; max-width: 216px"
        />
        <common-my-date-range-picker
          :dateRange="filters.dateRange"
          ref="date-picker"
          @setDateRange="(val) => setFilterDateRange(val)"
          class="ml-2"
        />
        <common-select
          v-model="filters.entityId"
          :items="budgetEntitesItems"
          v-if="!marketplaceUser"
          :menu-z-index="4"
          filter-style
          placeholder="Budget Entity"
          item-text="name"
          item-value="id"
          height="30"
          style="min-width: 216px; max-width: 216px"
        />
      </v-col>

      <v-col cols="3" class="d-flex align-center justify-end g10">
        <span class="f13 gray6 lato-bold text-no-wrap" v-if="totalOrdersLength > 0">
          {{ numberOfRowsMessage }}
        </span>

        <the-table-download-invoice
          :downloadMethod="downloadCSV"
        />
        <common-button
          text
          v-if="isFiltersSet"
          color="#219358"
          class="v-btn--clear text-capitalize"
          height="30"
          @click="clearFilters()"
        >
          Clear filters
        </common-button>
      </v-col>
    </v-row>
    <!-- LOADING -->
    <common-loader v-if="loading" style="padding: 70px 0" />
    <!-- TABLE -->
    <template v-else>
      <the-transactions-table
        class="pt-6"
        :items="transactions"
      />
      <load-more
        class="mt-6"
        :is-visible="showLoadMore"
        :loading="loadingMore"
        :size="nextPageSize"
        @more="loadNextPage()"
      />
    </template>
  </div>
</template>

<script>
import Api from '@/axios/api'

import LoadMore from '@/components/LoadMore'
import TheTransactionsTable from './TheTransactionsTable'
import TheTableDownloadInvoice from './TheTableDownloadInvoice'

import { marketplaceUser } from './mixins'

export default {
  name: 'TheTransactionsTableWrapper',
  components: {
    LoadMore,
    TheTransactionsTable,
    TheTableDownloadInvoice
  },
  mixins: [
    marketplaceUser
  ],
  data: () => ({
    loading: false,
    // load more btn
    loadingMore: false,
    transactionsMeta: {},
    transactionsLinks: {},
    // filters and request data
    filters: {
      type: null,
      entityId: null,
      dateRange: {
        start: null,
        end: null
      }
    },
    setTotalOrders: false,
    totalOrdersLength: 0,
    transactionTypes: [],
    budgetEntites: [],
    // items for table
    transactions: []
  }),
  computed: {
    showLoadMore () {
      return !!this.transactionsLinks?.next || false
    },
    nextPageSize () {
      return this.transactionsMeta?.per_page || 0
    },
    currentPage () {
      return this.transactionsMeta?.current_page || 1
    },
    transactionsItems () {
      const { filters: { type }, transactionTypes } = this
      const transactionObjects = transactionTypes?.map((item) => ({ id: item, name: item })) || []
      return type ? [{ id: null, name: 'All' }, ...transactionObjects] : transactionObjects
    },
    budgetEntitesItems () {
      const { filters: { entityId }, budgetEntites } = this
      return entityId ? [{ id: null, name: 'All' }, ...budgetEntites] : budgetEntites || []
    },
    isFiltersSet () {
      const { type, entityId, dateRange } = this.filters
      return ![type, entityId, ...Object.values(dateRange)].every((value) => value === null)
    },
    numberOfRowsMessage () {
      const { loading, totalOrdersLength, transactionsMeta } = this
      if (!loading) {
        if (totalOrdersLength === transactionsMeta?.total) {
          return `${totalOrdersLength} orders`
        } else {
          return `${transactionsMeta?.total} / ${totalOrdersLength} orders`
        }
      }
      return ''
    },
    params () {
      const { filters } = this
      return {
        type: filters.type,
        budget_entity_id: filters.entityId,
        date_to: filters.dateRange?.end || null,
        date_from: filters.dateRange?.start || null
      }
    }
  },
  watch: {
    filters: {
      handler: 'fetchFirstPage',
      immediate: true,
      deep: true
    }
  },
  created () {
    this.setTotalOrders = true
  },
  methods: {
    fetchFirstPage () {
      this.loading = true
      const { params } = this
      this.takeFiltersData()
      Api.get('/credit/transactions', { params: { ...params } })
        .then((response) => {
          this.transactions = response.data
          this.setTransactionsData(response)
          this.loading = false
        })
    },
    setTransactionsData ({ meta, links }) {
      this.transactionsMeta = meta
      this.transactionsLinks = links
      // set number of rows in transactions
      if (this.setTotalOrders) {
        this.totalOrdersLength = meta.total
        this.setTotalOrders = false
      }
    },
    takeFiltersData () {
      const { params } = this
      Api.get('/credit/transactions/filter/type', { params })
        .then(({ data }) => (this.transactionTypes = data))
      Api.get('/credit/transactions/filter/budget_entity', { params })
        .then(({ data }) => (this.budgetEntites = data))
    },
    loadNextPage () {
      this.loadingMore = true
      const { params, currentPage } = this
      Api.get('/credit/transactions', {
        params: {
          page: (currentPage + 1),
          ...params
        }
      }).then((response) => {
        this.transactions.push(...response.data)
        this.setTransactionsData(response)
        this.loadingMore = false
      })
    },
    downloadCSV () {
      const { params } = this
      return Api.get('/credit/transactions/download-report', { params })
        .then((response) => {
          this.$store.commit('downloadCSV', response)
        })
    },
    clearFilters () {
      Object.assign(this.$data.filters, this.$options.data().filters)
      this.$refs['date-picker'].clearSelectedDates()
    },
    setFilterDateRange (val) {
      if (!val) this.filters.dateRange = { start: null, end: null }
      if (val) this.filters.dateRange = val
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn--clear {
  letter-spacing: 0;
  font-weight: 400 !important;
}
</style>
