<template>
  <div
    class="table-scrollbar-helper"
    :style="{
      '--helper-bottom': `${bottom}px`,
      '--helper-left': `${left}px`,
      '--scrollbar-width': `${headerWidth}px`,
      '--scrollbar-max-width': `${scrollbarWidth}px`
    }"
    @scroll="onScroll"
    @mousedown="grabHelper = true"
    @mouseup="grabHelper = false"
  >
    <div />
  </div>
</template>

<script>
export default {
  name: 'TableScrollbarHelper',
  data: () => ({
    grabHelper: false,
  }),
  props: {
    scrollbarWidth: {
      type: Number,
      required: true,
    },
    headerWidth: {
      type: Number,
      required: true,
    },
    bottom: {
      type: Number,
      required: false,
      default: 16,
    },
    left: {
      type: Number,
      required: false,
      default: 128,
    },
  },
  methods: {
    onScroll (e) {
      if (this.grabHelper) {
        this.$emit('updateScroll', e.target.scrollLeft)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.table-scrollbar-helper {
  position: fixed;
  left: var(--helper-left, 128px);
  width: 100%;
  height: 9px;
  overflow-x: auto;
  max-width: var(--scrollbar-max-width);
  border: none !important;
  bottom: var(--helper-bottom, 16px);
  z-index: 4;

  & > div {
    width: var(--scrollbar-width);
  }
}
</style>
