<template>
  <div class="recipients-bulk-options-card">
    <div class="recipients-bulk-options-card__icon">
      <slot name="icon" />
    </div>

    <div class="recipients-bulk-options-card__text">
      <slot name="text" />
    </div>

    <common-button
      outlined
      :height="34"
      @click="$emit('onButtonClick')"
    >
      <slot name="button" />
    </common-button>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class RecipientsBulkOptionsCard extends Vue {}
</script>

<style lang="scss" scoped>
.recipients-bulk-options-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px 24px;
  min-width: 240px;
  max-width: 240px;
  flex: 1 1;
  border: 1px solid #F4F4F4;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);

  &__icon {
    svg > {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
    }
  }

  &__text {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    flex-grow: 1;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #222325;
  }

  & > button {
    font-family: 'Lato-Bold', sans-serif;
    text-transform: unset;
    font-size: 15px;
    line-height: 18px;

    &::v-deep > span {
      font-family: inherit;
      line-height: inherit;
      font-size: inherit;
    }
  }
}
</style>
