<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="none"
    :width="width"
    :height="height"
  >
    <path
      d="M0.367234 0.367234C0.483348 0.250826 0.621287 0.158468 0.77315 0.0954521C0.925013 0.032436
        1.08782 0 1.25223 0C1.41665 0 1.57946 0.032436 1.73132 0.0954521C1.88318 0.158468 2.02112
        0.250826 2.13723 0.367234L8.75223 6.98474L15.3672 0.367234C15.4835 0.251014 15.6214
        0.158823 15.7733 0.0959253C15.9251 0.0330276 16.0879 0.000654459 16.2522
        0.000654459C16.4166 0.000654459 16.5793 0.0330276 16.7312 0.0959253C16.883 0.158823 17.021
        0.251014 17.1372 0.367234C17.2535 0.483454 17.3456 0.621426 17.4085 0.773275C17.4714
        0.925124 17.5038 1.08787 17.5038 1.25223C17.5038 1.41659 17.4714 1.57934 17.4085
        1.73119C17.3456 1.88304 17.2535 2.02101 17.1372 2.13723L10.5197 8.75223L17.1372
        15.3672C17.2535 15.4835 17.3456 15.6214 17.4085 15.7733C17.4714 15.9251 17.5038 16.0879
        17.5038 16.2522C17.5038 16.4166 17.4714 16.5793 17.4085 16.7312C17.3456 16.883 17.2535
        17.021 17.1372 17.1372C17.021 17.2535 16.883 17.3456 16.7312 17.4085C16.5793 17.4714
        16.4166 17.5038 16.2522 17.5038C16.0879 17.5038 15.9251 17.4714 15.7733 17.4085C15.6214
        17.3456 15.4835 17.2535 15.3672 17.1372L8.75223 10.5197L2.13723 17.1372C2.02101 17.2535
        1.88304 17.3456 1.73119 17.4085C1.57934 17.4714 1.41659 17.5038 1.25223 17.5038C1.08787
        17.5038 0.925124 17.4714 0.773275 17.4085C0.621426 17.3456 0.483454 17.2535 0.367234
        17.1372C0.251014 17.021 0.158823 16.883 0.0959253 16.7312C0.0330276 16.5793 0.000654459
        16.4166 0.000654459 16.2522C0.000654459 16.0879 0.0330276 15.9251 0.0959253
        15.7733C0.158823 15.6214 0.251014 15.4835 0.367234 15.3672L6.98474 8.75223L0.367234
        2.13723C0.250826 2.02112 0.158468 1.88318 0.0954521 1.73132C0.032436 1.57946 0 1.41665 0
        1.25223C0 1.08782 0.032436 0.925013 0.0954521 0.77315C0.158468 0.621287 0.250826 0.483348
        0.367234 0.367234Z
      "
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Close',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 18
    },
    height: {
      type: [Number, String],
      required: false,
      default: 18
    },
    color: {
      type: String,
      required: false,
      default: '#95979D'
    }
  }
}
</script>
