<template>
  <common-button
    v-if="isVisible"
    class="load-more mb-12"
    outlined
    width="240"
    @click="$emit('more')"
    :loading="loading"
  >
    Load more ({{ size }})
  </common-button>
</template>

<script>
export default {
  name: 'LoadMore',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>

<style lang="scss">
.load-more {
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 0 transparent;
}
</style>
