var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',_vm._g(_vm._b({ref:"common-select",staticClass:"ma-0 thin-border rounded-0 common-select",class:{ 'common-select--filter' : _vm.showFilterStyle },attrs:{"items":_vm.items,"hide-details":"","dense":"","outlined":"","solo":"","flat":"","attach":!_vm.disableAttach && _vm.attachTo,"color":"black","item-color":"black","no-data-text":"No data","append-icon":"mdi-chevron-down","height":_vm.height,"multiple":_vm.multiple,"menu-props":{
    bottom: !_vm.top,
    top: _vm.top,
    offsetY: true,
    left: true,
    tile: true,
    zIndex: _vm.menuZIndex,
    contentClass: _vm.contentClass
  }},nativeOn:{"click":function($event){return _vm.onTimeFrameSelectClick.apply(null, arguments)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(props){return [_vm._t(slot,null,null,props)]}}})],null,true),model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},'v-select',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,slot){return [_c('template',{slot:slot},[_vm._t(slot)],2)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }