<template>
  <v-col cols="12" md="6" lg="4" xl="3">
    <v-card outlined tile class="campaign-card" height="444">
      <div style="height: 180px" class="pointer" @click="showGiftOptions()">
        <campaign-card-images
          :products="availableItemsInGift"
          :disabled="isCampaignDisabled"
        />
      </div>

      <v-card-text class="d-flex px-6 py-4 justify-space-between">
        <div class="campaign-card__labels">
          <update-status-sheet
            v-if="campaignCreator"
            color="#D2F5F3"
            text-color="#004642"
            v-tooltip="{
              content: `Created by: ${campaignCreator}`,
              ...standardTooltipProps,
            }"
          >
            BY: {{ campaignCreator.slice(0, 40) }}
          </update-status-sheet>

          <update-status-sheet
            v-if="isPreProduction"
            color="#ECD884"
            text-color="#222325"
            v-tooltip="{
              content: `Items will be ordered and produced once all eGift recipients have made their selections,
                or when the campaign ends. Track redemptions and details through the &quot;My eGift orders&quot;
                on your dashboard`,
              ...standardTooltipProps,
            }"
          >
            Zero waste
          </update-status-sheet>

          <update-status-sheet
            v-if="isAutomated"
            color="#E5DFFF"
            text-color="#4E3AAF"
            v-tooltip="{
              content: automationTooltip,
              ...standardTooltipProps,
            }"
          >
            Automated
          </update-status-sheet>
        </div>

        <the-campaign-card-settings
          :gift-options="giftOptions"
          :is-automated="isAutomated"
          :user-permissions="userPermissions"
          :campaign-permissions="campaignPermissions"
          @onGiftOptions="showGiftOptions()"
          @onCampaignSettings="showCampaignSettingsPanel()"
          @onEstimateCost="showEstCostPanel()"
          @onAddAutomation="showAutomationPanel()"
          @onPreview="previewGift()"
          @onEdit="editCampaign()"
        />
      </v-card-text>

      <v-card-text class="py-0 px-6">
        <h1 class="campaign-card__name">
          {{ item.name }}
        </h1>

        <v-row dense class="pt-2">
          <campaign-card-type
            :gift-type="campaignType"
            :price-range="item.prices_range"
            :gift-settings="item.egift_settings"
            :gift-products="item.collection.items || []"
          />

          <campaign-card-tags :tags="item.tags.map((tag) => tag.name)" />
        </v-row>
      </v-card-text>

      <v-card-actions class="px-6 pb-7">
        <campaign-card-button
          @showSendEGiftPanel="showSendEGift"
          @showSendDirectPanel="$emit('showSendDirectPanel')"
          :gift-type="campaignType"
          :items="item.collection.items"
          :disabled="
            !item.collection.items
              .map(item => item.product.qty.is_out_of_stock)
              .includes(false)
            || isCampaignDisabled
          "
        />
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import userIsCgElite from '@/mixins/userIsCgElite'
import standardTooltipProps from '@/mixins/standardTooltipProps'

import CampaignCardImages from './TheCampaignCardImages.vue'
import CampaignCardButton from './CampaignCardSendButton.vue'
import CampaignCardType from './TheCampaignCardType.vue'
import TheCampaignCardSettings from './TheCampaignCardSettings.vue'
import CampaignCardTags from './TheCampaignCardTags.vue'
import UpdateStatusSheet from '@/components/UpdateStatusSheet'

export default {
  name: 'CampaignCard',
  mixins: [
    userIsCgElite,
    standardTooltipProps,
  ],
  components: {
    TheCampaignCardSettings,
    CampaignCardTags,
    CampaignCardImages,
    CampaignCardButton,
    UpdateStatusSheet,
    CampaignCardType
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    userPermissions: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  computed: {
    campaignType () {
      return this.item.egift_settings.egift_type || 'single'
    },
    isPreProduction () {
      return this.item?.is_pre_production ?? false
    },
    isAutomated () {
      return this.item?.has_automation ?? false
    },
    campaignPermissions () {
      return this.item?.permissions ?? []
    },
    giftOptions () {
      const { item } = this
      const settings = item?.egift_settings || {}
      const items = item?.collection?.items || []
      if (settings?.egift_type === 'coin') {
        return items?.filter(item => item.product.price <= Number(settings?.coin_value || 0))?.length || 0
      }
      return items.length
    },
    availableItemsInGift () {
      const { egift_settings: settings, collection: { items } } = this.item
      let products = items

      if (settings?.egift_type === 'coin') {
        products =  items?.filter(item => item.product.price <= Number(settings?.coin_value || 0))
      }

      return products?.sort((itemA, itemB) => {
        const a = itemA?.product?.qty?.is_out_of_stock ?? false
        const b = itemB?.product?.qty?.is_out_of_stock ?? false

        return a - b
      }) ?? []
    },
    isCampaignDisabled () {
      return this.item?.is_disabled
    },
    campaignCreator () {
      return this.item?.created_by || ''
    },
    automationTooltip () {
      const { integration, source, type } = this.item?.automation ?? {}

      if (source === 'contacts') {
        return `This campaign is sent automatically to My Contacts group/s on their ${type}.
          <br>
          <br>
          Click <i>Edit automation</i> to see details
        `
      } else {
        return `This campaign is sent automatically to ${integration?.name} group/s on their ${type}.
          <br>
          <br>
          Click <i>Edit automation</i> to see details
        `
      }
    },
  },
  methods: {
    showGiftOptions () {
      this.$emit('showGiftOptionsPanel', this.item)
    },
    showEstCostPanel () {
      this.$emit('showEstCostPanel', this.item)
    },
    showAutomationPanel () {
      this.$emit('showAutomationPanel', this.item)
    },
    showCampaignSettingsPanel () {
      this.$emit('showCampaignSettingsPanel', this.item)
    },
    showSendEGift (type) {
      this.$emit('showEGiftPanel', this.item, type)
    },
    previewGift () {
      const {
        video_greeting: video,
        calendly_link: link,
        greeting_message: message,
        gift_giver_name: giverName,
        logo_url: campaignLogo,
        customer_group: { logo },
        egift_settings: {
          is_regift_enabled: canRegift,
          is_upgrade_enabled: canUpgrade
        },
        entity_id: campaignId
      } = this.item

      const logoUrl = campaignLogo || `https://corporategift.com/media/companies_logo/${logo}`

      const queryObject = {
        logo_url: logoUrl,
        can_regift: +canRegift,
        can_upgrade: +canUpgrade,
        sender: giverName
      }

      if (video) queryObject.video_greeting = video
      if (link) queryObject.calendly_link = link
      if (message) queryObject.greeting_message = message.replaceAll('\n', '<br />')

      const queryParams = new URLSearchParams(queryObject)
      const egiftLink = process.env.VUE_APP_EGIFT_URL || 'https://egift.staging.corporategift.com'
      window.open(`${egiftLink}/recipient/preview/c/${btoa(campaignId)}/gift?${queryParams}`, '_blank')
    },
    editCampaign () {
      const { entity_id: campaignId } = this.item

      if (campaignId) {
        this.$router.push({ name: 'CreateCampaignReview', params: { campaignId } })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.campaign-card {
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-color: #F4F4F4 !important;

  &__name {
    font-family: 'Lato-Regular', sans-serif;
    line-height: 32px;
    font-weight: normal;
    font-size: 26px;
    color: #000;
    cursor: default;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__labels {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 8px;
  }
}

.v-card__actions {
  bottom: 0;
  position: absolute;
  width: 100%;
}
</style>
