<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    max-height="450"
    class="rounded-0"
  >
    <div class="modal-content">
      <v-icon @click.stop="close" class="white--text close-icon">mdi-close</v-icon>
      <div class="px-10 py-10 d-flex flex-column">
        <span class="f30 lato-light mb-7">Generate new API Token.</span>
        <common-input
          v-model="name"
          v-show="!newToken"
          label="Token Name/Description"
          :hide-details="false"
          :error-messages="errors.name"
        />
        <common-textarea
          ref="newTokenInput"
          v-model="newToken"
          v-show="newToken"
          readonly
          rows="3"
          @focus="$event.target.select()"
        />
        <div class="button-save-container">
          <button @click="createToken" class="save-close" v-if="!newToken">Create</button>
          <button @click="copyToken" class="save-close" v-else>Copy</button>
          <button @click="close" class="cancel button">Close</button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('apiTokens')

export default {
  props: {
    value: Boolean
  },
  data: () => ({
    name: undefined,
    newToken: undefined,
    copied: false,
    errors: {}
  }),
  methods: {
    ...mapActions([
      'create'
    ]),
    createToken () {
      this.create(this.name)
        .then(token => {
          this.newToken = token
          this.errors = {}
        })
        .catch(error => {
          if (error.response && error.response.status === 422 && error.response.data) {
            this.errors = error.response.data.errors
          }
        })
    },
    copyToken () {
      this.$nextTick(() => {
        this.$refs.newTokenInput.focus()
        document.execCommand('copy')
        this.copied = 'Token copied to the clipboard.'
      })
    },
    close () {
      this.dialog = false
    },
    reset () {
      this.name = undefined
      this.newToken = undefined
      this.copied = undefined
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.reset()
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog {
  top: 50px;

  .modal-content {
    background: white;

    .button-save-container {
      margin: 10px 0;

      .save-close {
        height: 40px;
        width: 165.18px;
        background-color: #000;
        margin: 9px 0 0 0;
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        outline: none;
      }

      .cancel {
        height: 40px;
        width: 127px;
        border: 2px solid #000;
        background-color: #fff;
        font-size: 15px;
        color: #000;
        margin-left: 7px;
      }

      .button {
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
      }
    }
  }
}

.close-icon {
  position: absolute !important;
  font-size: 50px !important;
  font-weight: 600;
  right: -60px;
}
</style>
