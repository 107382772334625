<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 19"
    fill="none"
    :width="width"
    :height="height"
  >
    <mask
      id="mask0_1252_5515"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="19"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7957 0.181128L11.1075 1.47666H1.92132H1.92036C0.914354 1.47666 0.0986328 2.26749
          0.0986328 3.24295V3.24295V16.0477C0.0986328 17.2667 1.1178 18.2555 2.37591
          18.2555V18.2555H18.7717C20.0297 18.2555 21.049 17.2667 21.049
          16.0477V16.0477V5.89224C21.0495 4.91678 20.2336 4.1261 19.2278
          4.1261V4.1261V3.24295C19.2278 2.26749 18.412 1.47666 17.4059
          1.47666V1.47666H15.7622L15.3733 0.441262C15.307 0.26457 15.1344 0.154663 14.9502
          0.154663V0.154663C14.899 0.154663 14.8468 0.163225 14.7957 0.181128V0.181128ZM14.6806
          1.14865L15.1643 2.43967L15.7968 4.1261H6.20434L11.2315 2.35981H11.236L13.7473
          1.47666H13.7464L14.6806 1.14865ZM16.0929 2.35981H17.4059C17.9091 2.35981 18.3168 2.75538
          18.3168 3.24295V3.24295V4.1261H16.7557L16.0929 2.35981ZM1.0099 3.24295C1.0099 2.75538
          1.41808 2.35981 1.92084 2.35981V2.35981H8.59305L3.59226 4.11676C3.586 4.11894 3.58279
          4.12376 3.57637 4.1261V4.1261H1.92084C1.41808 4.1261 1.0099 3.73037 1.0099
          3.24295V3.24295ZM1.0099 16.0477V4.76406C1.2787 4.91537 1.58748 5.00894 1.92084
          5.00894V5.00894H19.2278C19.731 5.00894 20.1385 5.40466 20.1385
          5.89224V5.89224V9.42451H18.3168C17.3108 9.42451 16.4949 10.2153 16.4949
          11.1907V11.1907C16.4949 12.1661 17.3108 12.9569 18.3168
          12.9569V12.9569H20.1385V16.0477C20.1385 16.7794 19.5274 17.3722 18.7722
          17.3722V17.3722H2.37639C1.62217 17.3722 1.0099 16.7794 1.0099 16.0477V16.0477ZM17.4059
          11.1907C17.4059 10.7032 17.8139 10.3075 18.3168
          10.3075V10.3075H20.1385V12.074H18.3168C17.8139 12.074 17.4059 11.6781 17.4059
          11.1907V11.1907ZM18.3168 11.6321H19.2279V10.749H18.3168V11.6321Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1252_5515)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.704224 19.0338H21.8519V-0.623657H-0.704224V19.0338Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Payment extends Vue {
  @Prop({ default: 22 }) readonly width!: number | string;

  @Prop({ default: 19 }) readonly height!: number | string;
}
</script>
