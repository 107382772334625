<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    fill="none"
    :width="width"
    :height="height"
  >
    <circle cx="20" cy="20" r="20" fill="#E6EBF1"/>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.1862 21.2099C23.0285 21.2099 22.9009 21.3403 22.9009 21.5005C22.9009 21.6611 23.0285
        21.7906 23.1862 21.7906C24.1615 21.7906 24.8973 22.6182 24.8973 23.6114C24.8973 23.6446
        24.8911 23.6766 24.8779 23.706C24.8143 23.8528 24.8787 24.0243 25.0229 24.0895C25.0604
        24.1067 25.0997 24.1143 25.1385 24.1143C25.2479 24.1143 25.3519 24.0504 25.3994
        23.9419C25.4448 23.8376 25.468 23.7266 25.468 23.6114C25.468 22.2982 24.4762 21.2099 23.1862
        21.2099ZM20.6853 17.0385C20.8896 16.9153 21.1073 16.8531 21.3323 16.8531C22.1969 16.8531
        22.9009 17.7816 22.9009 18.9224C22.9009 19.1272 22.8786 19.3282 22.8344 19.5199C22.7985
        19.6763 22.8939 19.8324 23.0471 19.8689C23.069 19.8744 23.0909 19.8769 23.1123 19.8769C23.242
        19.8769 23.3588 19.7865 23.3898 19.6528C23.4439 19.4173 23.4716 19.1717 23.4716
        18.9224C23.4716 17.4611 22.512 16.272 21.3323 16.272C21.0082 16.272 20.684 16.3645 20.395
        16.5386C20.2596 16.6201 20.2145 16.7984 20.2946 16.9364C20.3752 17.0747 20.5502 17.1201
        20.6853 17.0385ZM22.2733 22.3722C22.1156 22.3722 21.988 22.5021 21.988 22.6623C21.988 22.823
        22.1156 22.9529 22.2733 22.9529C23.2482 22.9529 24.0418 23.7607 24.0418 24.7539C24.0418
        24.8817 23.9394 24.986 23.8134 24.986H17.1392C17.0137 24.986 16.9113 24.8817 16.9113
        24.7539C16.9113 23.7607 17.7049 22.9529 18.6794 22.9529C18.8371 22.9529 18.9647 22.823
        18.9647 22.6623C18.9647 22.5021 18.8371 22.3722 18.6794 22.3722C17.3898 22.3722 16.3411
        23.4403 16.3411 24.7539C16.3411 25.2022 16.6995 25.5671 17.1392 25.5671H23.8134C24.2536
        25.5671 24.6124 25.2022 24.6124 24.7539C24.6124 23.4403 23.5632 22.3722 22.2733
        22.3722ZM20.4767 17.9422C21.3414 17.9422 22.0454 18.8707 22.0454 20.0119C22.0454 21.1527
        21.3414 22.0816 20.4767 22.0816C19.6117 22.0816 18.9081 21.1527 18.9073 20.0119C18.9073
        18.8707 19.6113 17.9422 20.4767 17.9422ZM20.4767 22.6623C21.6564 22.6623 22.6156 21.4736
        22.6156 20.0119C22.6156 18.5502 21.6564 17.3615 20.4767 17.3615C19.8933 17.3615 19.3483
        17.6453 18.9428 18.1613C18.5522 18.6587 18.3375 19.3156 18.3375 20.0119C18.3375 21.4736
        19.2971 22.6623 20.4767 22.6623Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.4139 12.589C27.6253 12.589 27.7967 12.7635 27.7967 12.9788V28.5571C27.7967 28.772
        27.6253 28.9465 27.4139 28.9465H13.8375C13.6265 28.9465 13.4551 28.772 13.4551
        28.5571V26.0257H14.1897C14.5068 26.0257 14.7632 25.7637 14.7632 25.4416C14.7632 25.1191
        14.5068 24.8571 14.1897 24.8571H13.4551V21.3518H14.1897C14.5068 21.3518 14.7632 21.0903
        14.7632 20.7677C14.7632 20.4456 14.5068 20.1837 14.1897 20.1837H13.4551V16.6784H14.1897C14.5068
        16.6784 14.7632 16.4164 14.7632 16.0943C14.7632 15.7718 14.5068 15.5098 14.1897
        15.5098H13.4551V12.9788C13.4551 12.7635 13.6265 12.589 13.8375 12.589H27.4139ZM12.3081
        12.9788V15.5098H11.5735C11.2564 15.5098 11 15.7718 11 16.0943C11 16.4164 11.2564 16.6784
        11.5735 16.6784H12.3081V20.1837H11.5735C11.2564 20.1837 11 20.4456 11 20.7677C11 21.0903
        11.2564 21.3518 11.5735 21.3518H12.3081V24.8571H11.5735C11.2564 24.8571 11 25.1191 11
        25.4416C11 25.7637 11.2564 26.0257 11.5735 26.0257H12.3081V28.5571C12.3081 29.4174 12.9927
        30.115 13.8375 30.115H27.4139C28.2587 30.115 28.9437 29.4174 28.9437 28.5571V12.9788C28.9437
        12.118 28.2587 11.4204 27.4139 11.4204H13.8375C12.9927 11.4204 12.3081 12.118 12.3081 12.9788Z"
      fill="black"
    />
  </svg>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Contacts extends Vue {
  @Prop({ required: false, default: 40 }) width!: number | string;

  @Prop({ required: false, default: 40 }) height!: number | string;
}
</script>
