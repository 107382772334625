import axios from 'axios'
import getCookie from '@/utils/getCookie'

const ApiMPDF = axios.create({
  baseURL: process.env.VUE_APP_MAGENTO_URL,
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/pdf'
  }
})

/**
 * Use request interceptor to fetch token.
 * Token is fetched asynchronously and attached to request as Authorization header.
 */
ApiMPDF.interceptors.request.use(
  async config => {
    config.headers.Authorization = 'Bearer ' + getCookie('cgToken')
    return config
  },
  error => Promise.reject(error)
)

/**
 * Use response interceptor to catch 401 (Unauthorized) api response.
 * If caught, refresh the token and retry the request.
 */
ApiMPDF.interceptors.response.use(
  response => response.data,

  async error => {
    throw error
  }
)

export default ApiMPDF
