<template>
  <v-sheet class="search-form d-flex py-0 pl-3 mr-0">
    <v-img
      :max-width="iconMaxWidth"
      :height="iconHeight"
      src="/images/search-icon.svg"
      class="pre-icon"/>
    <v-text-field
      :placeholder="placeholder"
      class="rounded-0"
      color="black"
      outlined
      solo
      flat
      dense
      hide-details
      tile
      v-model="search"
      :height="height"
      :style="'max-width: ' + maxWidth + 'px;'"
      style="width: 100%"
      clearable
    />
  </v-sheet>
</template>

<script>
export default {
  name: 'search-form',
  props: {
    iconMaxWidth: Number,
    iconHeight: Number,
    placeholder: String,
    maxWidth: Number,
    height: Number,
    searchIdx: String
  },
  data: () => ({
    search: ''
  }),
  created () {
    this.search = this.searchIdx
  },
  methods: {
    clearSearch () {
      this.search = ''
      this.setSearch()
    }
  },
  watch: {
    search (val) {
      if (val !== undefined) {
        this.$emit('setSearchValue', val !== null ? val : '')
      }
    },
    searchIdx (val) {
      this.search = val
    }
  }
}
</script>

<style lang="scss">
.search-form {

  @media only screen and (max-width: 1280px) {
    max-width: 180px;
  }

  .pre-icon {
    margin-right: -24px;
    margin-top: 8px;
    z-index: 1;
  }

  .v-input__slot {
    padding-left: 33px!important;
    font-size: 14px!important;
    padding-top: 1px!important;
  }

  .v-icon {
    font-size: 14px;
  }
}
</style>
